import React from "react";
import styled from "styled-components";
import { Icon } from "~/components/atoms/Icon";
import { Text } from "~/components/atoms/texts/Text";
import { useTranslation } from "~/i18n";
import { unlockLevel } from "~/store/unlock/useUnlock";
import {
  dummyReadLogMessageFirstProps,
  dummyReadLogMessageSecondProps,
} from "./const";
import { ReadLogMessage } from "~/components/organisms/bookFriendMessage/BookFriendMessageListItem/ReadLogMessage";

export const BfLockPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <_Wrapper>
      <_BookFriendMessageList>
        <StyledReadLogMessage
          {...dummyReadLogMessageFirstProps}
          bookFriendLockTemplate
        />
        <StyledReadLogMessage
          {...dummyReadLogMessageSecondProps}
          bookFriendLockTemplate
        />
      </_BookFriendMessageList>
      <_BlurOverlay />
      <_CenteringCon>
        <_FlameWrapper>
          <_FlameTextWrapper>
            <Text
              fontSize="MD"
              lineHeight="MD"
              fontColor="tex.t500"
              bold
              style={{ paddingBottom: "12px" }}
            >
              <_HighlightWrapper>
                レベル{unlockLevel.bookFriend}
              </_HighlightWrapper>
              になったら、
              {t<TranslateKeys>("本の友の感想が見られるようになるよ")}
            </Text>

            <_TextWrapper>
              <Icon
                name="question"
                color="tex"
                style={{ width: "24px", height: "24px" }}
              ></Icon>

              <Text fontSize="SM" lineHeight="MD">
                <b>{t<TranslateKeys>("レベルを上げるには？")}</b>
                <br></br>
                {t<TranslateKeys>(
                  "ミニレッスンを受けたり、本の感想を出したりすると、レベルが上がるよ"
                )}
              </Text>
            </_TextWrapper>
          </_FlameTextWrapper>
        </_FlameWrapper>
      </_CenteringCon>
    </_Wrapper>
  );
};

const _Wrapper = styled.div`
  position: relative;
  height: 100%;
  padding-top: 16px;
  background: ${(props) => props.theme.colors.base.ashGray};
  overflow: hidden;
`;

const _TextWrapper = styled.div`
  display: flex;
  gap: 4px;
`;

const _HighlightWrapper = styled.span`
  color: ${({ theme }) => theme.colors.pri.p300};
`;

const _BookFriendMessageList = styled.div`
  padding: 0 20px;

  @media screen and (min-width: ${({ theme }) =>
      theme.screenSize.smartPhoneMax}) {
    padding: 0 10%;
  }
`;

const _FlameWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.base.white};
  border-radius: ${({ theme }) => theme.size.s2};
  position: relative;
  display: flex;
  margin: auto 16px;
`;

const _FlameTextWrapper = styled.div`
  padding: 16px;
`;

const _BlurOverlay = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50%;
  backdrop-filter: blur(3px);
  pointer-events: none;
`;

const _CenteringCon = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  justify-content: center;
  width: 100%;
  height: 50%;
  pointer-events: auto;
`;

const StyledReadLogMessage = styled(ReadLogMessage)`
  margin-bottom: 32px;
  &:last-child {
    margin-bottom: 0;
  }
`;
