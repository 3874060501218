import { trackEvent } from "./trackEvent";
import { clearUserProperties } from "./userProperties";
import {
  clearLoggedInAt,
  clearLoginPageViewedAt,
  getLoginPageViewedAt,
  setLoggedInAt,
} from "./localStorage";

export const trackLoginEventIfJustLoggedIn = (): void => {
  const loginPageViewedAt = getLoginPageViewedAt();

  if (!loginPageViewedAt) {
    return;
  }

  trackEvent({
    event: "login_completed",
    payload: {
      duration_ms: new Date().getTime() - new Date(loginPageViewedAt).getTime(),
    },
  });

  clearLoginPageViewedAt();
  setLoggedInAt(new Date());
};

export const handleLogoutForAnalytics = (): void => {
  clearLoggedInAt();
  clearUserProperties();
};
