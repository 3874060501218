import React, { useEffect, useState, VFC } from "react";
import { useNavigate } from "react-router-dom";
import { EditRecommendSettingTemplate } from "~/components/templates/EditRecommendSetting";
import { LoadingPage } from "~/components/templates/Loading/LoadingPage";
import {
  RecommendFrequency,
  useChangeRecommendFrequencyMutation,
  useGetCurrentRecommendFrequencyQuery,
} from "~/generated/graphql";
import { usePreference } from "~/store/preference/usePreference";

export const EditRecommendSetting: VFC = () => {
  const { preference, savePreference } = usePreference();

  const [recommendFrequency, setRecommendFrequency] = useState(
    "BASIC" as RecommendFrequency
  );
  const [searchWord, setSearchWord] = useState(preference?.calilSearchWord);

  useEffect(() => {
    preference && setSearchWord(preference.calilSearchWord);
  }, [preference]);

  const [openModal, setOpenModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const { loading, error, data, refetch } =
    useGetCurrentRecommendFrequencyQuery({
      onCompleted: () => {
        setRecommendFrequency(
          data?.me.recommendFrequency as RecommendFrequency
        );
      },
    });
  const [changeRecommendFrequency] = useChangeRecommendFrequencyMutation({
    onCompleted() {
      refetch();
      setModalMessage("おすすめ冊数が変更されました");
      setOpenModal(true);
    },
  });
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault();
    changeRecommendFrequency({
      variables: {
        frequency: recommendFrequency,
      },
    });
  };

  const handleSubmitSearchWord = (event: React.MouseEvent) => {
    event.preventDefault();
    savePreference({ calilSearchWord: searchWord ?? "" });
    setModalMessage("検索キーワードが設定されました");
    setOpenModal(true);
  };

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();
    setRecommendFrequency(event.target.value as RecommendFrequency);
  };

  const handleChangeSearchWord = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    setSearchWord(event.target.value);
  };

  if (loading || !data) return <LoadingPage />;
  if (error) return <>Error! {error.message}</>;
  return (
    <EditRecommendSettingTemplate
      recommendFrequency={recommendFrequency}
      searchWord={searchWord ?? ""}
      onClick={handleClick}
      onSubmitSearchWord={handleSubmitSearchWord}
      onChange={handleChange}
      onChangeSearchWord={handleChangeSearchWord}
      handleBack={() => navigate("/")}
      openModal={openModal}
      setOpenModal={setOpenModal}
      modalMessage={modalMessage}
    />
  );
};
