import { ApolloCache, ApolloClient, makeVar } from "@apollo/client";
import {
  TalkAllFragment,
  TalkUpdateAllFragment,
  TalkLogAllFragment,
  MessageCacheQuery,
  MessageCacheDocument,
} from "~/generated/graphql";

export function setLoadingMessageCache(
  client: ApolloClient<unknown>,
  init: boolean
): void {
  if (init) {
    const queryResult = {
      messageCache: {
        __typename: "TalkCache",
        state: 1,
        digAll: false,
        oldTalks: [],
        unreadTalks: null,
        count: 0,
      },
    };
    client.writeQuery({
      query: MessageCacheDocument,
      data: queryResult,
    });
  } else {
    const queryResult = client.readQuery<MessageCacheQuery>({
      query: MessageCacheDocument,
    });
    if (queryResult) {
      const newQueryResult: MessageCacheQuery = {
        messageCache: {
          ...queryResult.messageCache,
          state: 1,
        },
      };
      client.writeQuery({
        query: MessageCacheDocument,
        data: newQueryResult,
      });
    }
  }
}

export function initMessageCache(
  cache: ApolloCache<unknown>,
  count: number,
  talkLog: TalkLogAllFragment
): void {
  const queryResult = cache.readQuery<MessageCacheQuery>({
    query: MessageCacheDocument,
  });
  if (queryResult) {
    const newQueryResult: MessageCacheQuery = {
      messageCache: {
        ...queryResult.messageCache,
        state: 2,
        digAll: talkLog.old.length < count || talkLog.count === 0,
        oldTalks: talkLog.old,
        unreadTalks: talkLog.unread.length === 0 ? [] : talkLog.unread,
        count: talkLog.count,
      },
    };
    cache.writeQuery({
      query: MessageCacheDocument,
      data: newQueryResult,
    });
  }
}

export function updateMessageCache(
  cache: ApolloCache<unknown>,
  talkUpdate: TalkUpdateAllFragment
): void {
  const change = new Map<number, TalkAllFragment>();
  for (const t of talkUpdate.change) {
    change.set(t.id, t);
  }
  const del = new Set<number>();
  for (const i of talkUpdate.delete) {
    del.add(i);
  }

  const queryResult = cache.readQuery<MessageCacheQuery>({
    query: MessageCacheDocument,
  });

  if (queryResult) {
    const talks: TalkUpdateAllFragment["new"] = [];
    for (const t of queryResult.messageCache.oldTalks.concat(
      ...(queryResult.messageCache
        .unreadTalks as MessageCacheQuery["messageCache"]["oldTalks"])
    )) {
      if (!del.has(t.id)) {
        const rpt = change.get(t.id);
        if (rpt === undefined) {
          talks.push(t);
        } else {
          talks.push(rpt);
        }
      }
    }
    talks.push(...talkUpdate.new);

    const newQueryResult: MessageCacheQuery = {
      messageCache: {
        ...queryResult.messageCache,
        oldTalks: talks,
        unreadTalks: [],
      },
    };
    cache.writeQuery({
      query: MessageCacheDocument,
      data: newQueryResult,
    });
  }
}

export function appendOlderToMessageCache(
  cache: ApolloCache<unknown>,
  count: number,
  talks: TalkAllFragment[]
): void {
  const queryResult = cache.readQuery<MessageCacheQuery>({
    query: MessageCacheDocument,
  });
  if (queryResult) {
    const newQueryResult: MessageCacheQuery = {
      messageCache: {
        ...queryResult.messageCache,
        state: 2,
        digAll: talks.length < count,
        oldTalks: talks.concat(queryResult.messageCache.oldTalks),
      },
    };
    cache.writeQuery({
      query: MessageCacheDocument,
      data: newQueryResult,
    });
  }
}

export const lastAnsweredReactiveVar = makeVar<number>(0)
export const lastMessageSentReactiveVar = makeVar<number>(0)
