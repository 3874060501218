import { MissionTaskType } from "~/generated/graphql";
import { TranslateKeys } from "~/i18n";

type DisplayMaterial = {
  typeName: TranslateKeys;
  unit: TranslateKeys;
  inProgressVerb: TranslateKeys;
  completedVerb: TranslateKeys;
};

export const taskTypeToDisplayMaterial: Record<
  MissionTaskType,
  DisplayMaterial
> = {
  [MissionTaskType.ReadLog]: {
    typeName: "感想",
    unit: "回",
    inProgressVerb: "出そう",
    completedVerb: "出した",
  },
  [MissionTaskType.MiniLesson]: {
    typeName: "ミニレッスン",
    unit: "回",
    inProgressVerb: "やろう",
    completedVerb: "やった",
  },
  [MissionTaskType.ReadLogWithReview]: {
    typeName: "一言感想",
    unit: "回",
    inProgressVerb: "書こう",
    completedVerb: "書いた",
  },
  [MissionTaskType.ReadLogContinueDays]: {
    typeName: "感想連続日数",
    unit: "日連続",
    inProgressVerb: "で出そう",
    completedVerb: "で出した",
  },
  [MissionTaskType.SendLike]: {
    typeName: "いいね",
    unit: "回",
    inProgressVerb: "しよう",
    completedVerb: "した",
  },
  [MissionTaskType.ReceiveLike]: {
    typeName: "いいね",
    unit: "回",
    inProgressVerb: "もらおう",
    completedVerb: "もらった",
  },
};
