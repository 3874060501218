import React from "react";
import { BaseModal } from "~/components/atoms/wrappers/BaseModal";
import styled from "styled-components";
import { Text } from "~/components/atoms/texts/Text";
import { PrimaryButton } from "~/components/molecules/buttons/PrimaryButton";
import { Stack } from "~/components/atoms/layout/Stack";
import { Row } from "~/components/atoms/layout/Row";
import { useTranslation } from "react-i18next";

export type RecordBookDiffModalProps = {
  nickname: string;
  isModalOpen: boolean;
  termStartDate: Date;
  termEndDate: Date;
  readCharSumDiff: number;
  readBookSumDiff: number;
  miniLessonTotalNumDiff: number;
  onClose: () => void;
};

export const RecordBookDiffModal: React.FC<RecordBookDiffModalProps> = ({
  nickname,
  isModalOpen,
  termStartDate,
  termEndDate,
  readCharSumDiff,
  readBookSumDiff,
  miniLessonTotalNumDiff,
  onClose,
}) => {
  const { t } = useTranslation();

  const formattedStartDate =
    String(new Date(termStartDate).getMonth() + 1) +
    "/" +
    new Date(termStartDate).getDate();
  const formattedEndDate =
    String(new Date(termEndDate).getMonth() + 1) +
    "/" +
    new Date(termEndDate).getDate();

  return (
    <BaseModal open={isModalOpen}>
      <_Container>
        <_Wrapper>
          <_TopTextWrapper>
            <Text
              fontColor="tex.t700"
              fontSize="MD"
              lineHeight="EQ"
              bold={true}
            >
              おめでとう！
            </Text>
          </_TopTextWrapper>
          <Img src="/img/sensei/standup_2.svg" />
          <_RecordDiffWrapper>
            <Stack rowGap="16px" alignItems="flex-start">
              <Text
                fontColor="tex.t700"
                fontSize="MD"
                lineHeight="EQ"
                bold={false}
              >
                {t<TranslateKeys>("先週")}（{formattedStartDate}〜
                {formattedEndDate}
                ）の {nickname} さんの がんばりは...
              </Text>
              <Stack rowGap="16px">
                {readCharSumDiff !== 0 && (
                  <Stack rowGap="8px">
                    <Text fontSize="SM" fontColor="tex.t700" lineHeight="EQ">
                      {t<TranslateKeys>("読んだ文字数")}
                    </Text>
                    <Row columnGap="4px" alignItems="baseline">
                      <Text
                        fontSize="XXXXL"
                        fontColor="green.g500"
                        lineHeight="EQ"
                        bold={true}
                      >
                        {readCharSumDiff.toLocaleString()}
                      </Text>
                      <Text
                        fontSize="MD"
                        fontColor="tex.t700"
                        lineHeight="EQ"
                        bold={false}
                      >
                        字ふえた！
                      </Text>
                    </Row>
                  </Stack>
                )}
                {readBookSumDiff !== 0 && (
                  <Stack rowGap="8px">
                    <Text fontSize="SM" fontColor="tex.t700" lineHeight="EQ">
                      {t<TranslateKeys>("読んだ本")}
                    </Text>
                    <Row columnGap="4px" alignItems="baseline">
                      <Text
                        fontSize="XXXXL"
                        fontColor="green.g500"
                        lineHeight="EQ"
                        bold={true}
                      >
                        {readBookSumDiff.toLocaleString()}
                      </Text>
                      <Text
                        fontSize="MD"
                        fontColor="tex.t700"
                        lineHeight="EQ"
                        bold={false}
                      >
                        {t<TranslateKeys>("冊") + "ふえた！"}
                      </Text>
                    </Row>
                  </Stack>
                )}
                {miniLessonTotalNumDiff !== 0 && (
                  <Stack rowGap="8px">
                    <Text fontSize="SM" fontColor="tex.t700" lineHeight="EQ">
                      ミニレッスン
                    </Text>
                    <Row columnGap="4px" alignItems="baseline">
                      <Text
                        fontSize="XXXXL"
                        fontColor="green.g500"
                        lineHeight="EQ"
                        bold={true}
                      >
                        {miniLessonTotalNumDiff.toLocaleString()}
                      </Text>
                      <Text
                        fontSize="MD"
                        fontColor="tex.t700"
                        lineHeight="EQ"
                        bold={false}
                      >
                        {t<TranslateKeys>("回") + "ふえた！"}
                      </Text>
                    </Row>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </_RecordDiffWrapper>
          <PrimaryButton
            onClick={onClose}
            color="pri"
            text="とじる"
            fontSize="XL"
            lineHeight="EQ"
            bold={true}
            padding="16px 16px 20px 16px"
          />
        </_Wrapper>
      </_Container>
    </BaseModal>
  );
};

const _Container = styled.div`
  display: flex;
  justify-content: center;
`;

const _Wrapper = styled.div`
  max-width: 544px;
  background-color: ${(props) =>
    props.theme.colors.semantic.layout.backgroundBox};

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const _TopTextWrapper = styled.div`
  width: 100%;
`;

const Img = styled.img`
  width: 69.87px;
`;

const _RecordDiffWrapper = styled.div`
  width: 100%;
`;
