import React from "react";
import { DummyMessage, DummyMessageProps } from "../..";
import {
  ReactiveHistoryState,
  useAnswerReactiveMutation,
} from "~/generated/graphql";
import { lastAnsweredReactiveVar, updateMessageCache } from "~/store/message";
import { useBookshelfExpModal } from "~/components/organisms/Bookshelf/useBookshelfExpModal";

export type DummyQuestionProps = {
  state: ReactiveHistoryState;
} & Omit<DummyMessageProps, "value" | "onSubmit" | "buttonText">;

export const DummyQuestion: React.FC<DummyQuestionProps> = ({
  id,
  item,
  state,
  disabled = false,
  showAll,
  incrementCurrentIndex,
  speaker,
}) => {
  const { updateExperience, setIsOpenGetExpModal } = useBookshelfExpModal();
  const [answerReactive] = useAnswerReactiveMutation({
    update: (cache, result) => {
      result.data?.answerReactive &&
        updateMessageCache(cache, result.data.answerReactive.talkUpdate);
    },
    onCompleted: (res) => {
      lastAnsweredReactiveVar(new Date().getTime());
      if (
        res.answerReactive.userExperience &&
        res.answerReactive.userExperienceEvent
      ) {
        setIsOpenGetExpModal(true);
        updateExperience(res.answerReactive.userExperience, [
          res.answerReactive.userExperienceEvent,
        ]);
      }
    },
  });

  const disabledButton = () => {
    if (disabled) return disabled;
    return state === ReactiveHistoryState.Completed;
  };

  const onSubmit = () => {
    answerReactive({
      variables: {
        talkID: id,
        answer: {
          dummy: {
            dummy: "ok",
          },
        },
      },
    });
  };

  return (
    <DummyMessage
      id={id}
      item={item}
      buttonText={"OK"}
      onSubmit={onSubmit}
      disabled={disabledButton()}
      showAll={showAll}
      incrementCurrentIndex={incrementCurrentIndex}
      speaker={speaker}
    />
  );
};
