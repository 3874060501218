type ZIndex =
  | "base"
  | "shadow"
  | "el"
  | "navbar"
  | "blackBack"
  | "readOrUnreadLabel"
  | "floatingButton"
  | "modalAnimation"
  | "modal"
  | "select"
  | "popup";

export const defaultZIndex: Readonly<Record<ZIndex, number>> = {
  base: 0,
  shadow: -1,
  el: 1,
  navbar: 100,
  blackBack: 800,
  readOrUnreadLabel: 5,
  floatingButton: 10,
  modalAnimation: 900,
  modal: 1000,
  select: 1001,
  popup: 500,
};
