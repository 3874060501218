import * as React from "react";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import { useLocation, useNavigate } from "react-router-dom";
import { SwitchUserTemplate } from "~/components/templates/SwitchUser";

export const SwitchUser: React.FC = () => {
  const { userAvailabilities } = React.useContext(CurrentUserContext);
  const navigate = useNavigate();
  const location = useLocation();

  const onLogin = React.useCallback(
    (userId: number) => {
      navigate(`/set_user/${userId}?path=${location.pathname}`);
    },
    [navigate, location]
  );

  return (
    <SwitchUserTemplate
      userAvailabilities={userAvailabilities}
      onLogin={onLogin}
    />
  );
};
