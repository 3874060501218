import React from "react";
import styled from "styled-components";
import { useAudio } from "~/utils/hooks/useAudio";
import { openBoxAnimationImages } from "~/pages/InitialTutorial/constants";
import {
  CONTENT_WIDTH,
  CONTENT_HEIGHT,
  useScale,
} from "../../../../utils/hooks/useScale";

export type OpenBoxAnimationProps = {
  onNext: () => void;
};

export const OpenBoxAnimation: React.FC<OpenBoxAnimationProps> = ({
  onNext,
}) => {
  const { play } = useAudio({ preload: ["light"] });
  const scale = useScale();

  React.useEffect(() => {
    play("light", 1.1);
    setTimeout(() => {
      onNext();
    }, 3000);
  }, [play, onNext]);

  return (
    <_Wrapper>
      <_Container style={{ scale: `${scale}` }}>
        <_BoxAndSenseiWrapper imageSrc={openBoxAnimationImages.bookShelf}>
          <_PresentBoxWrapper>
            <_PresentBox />
          </_PresentBoxWrapper>
          <_SenseiImage src={openBoxAnimationImages.standUpNormal} />
        </_BoxAndSenseiWrapper>
        <_LightEffect src={openBoxAnimationImages.light} />
        <_WhiteEffect />
      </_Container>
    </_Wrapper>
  );
};

const _Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  max-height: 100svh;
  background-color: #292f24;

  display: flex;
  justify-content: center;
`;

const _Container = styled.div`
  margin: 0 auto;
  position: relative;
  width: ${CONTENT_WIDTH}px;
  height: ${CONTENT_HEIGHT}px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transform-origin: top center;
`;

const _BoxAndSenseiWrapper = styled.div<{ imageSrc: string }>`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  animation: 2s zoomIn ease-in-out forwards;
  background-image: url(${({ imageSrc }) => imageSrc});
  background-repeat: no-repeat;
  background-size: cover;

  @keyframes zoomIn {
    from {
      opacity: 1;
      transform: scale(1);
    }
    to {
      opacity: 1;
      transform: scale(1.7);
    }
  }
`;

const _SenseiImage = styled.img`
  position: absolute;
  width: 65%;
  right: -10%;
  z-index: 100;
  bottom: 30%;
  object-fit: contain;
`;

const _LightEffect = styled.img`
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 200;
  clip-path: circle(0 at 50% 50%);
  animation: 1s light 1s ease-in-out forwards;

  @keyframes light {
    from {
      clip-path: circle(0 at 50% 50%);
    }
    to {
      clip-path: circle(50% at 50% 50%);
    }
  }
`;

const _WhiteEffect = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.base.white};
  z-index: 300;
  opacity: 0;
  animation: 1s white 1.5s ease-in-out forwards;

  @keyframes white {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const _PresentBoxWrapper = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  bottom: 35%;
  z-index: 200;
`;

const _PresentBox: React.FC = () => (
  <svg
    width="197"
    height="171"
    viewBox="0 0 197 171"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="197" height="170.825" rx="12" fill="#4FA6D7" />
    <rect y="40.7939" width="197" height="20.397" fill="#EE9F17" />
    <rect x="67.5039" width="14.6702" height="170.825" fill="#EE9F17" />
  </svg>
);
