import React, { useState } from "react";
import styled from "styled-components";
import { Line } from "react-chartjs-2";
import {
  RecommendTerm,
  useGetReadcharForGraphQuery,
} from "~/generated/graphql";
import { theme } from "~/styles/theme";
import { TFunction, useTranslation } from "~/i18n";
import { Loading } from "~/components/atoms/Loading";
import { SectionTitle } from "~/components/molecules/texts/SectionTitle";

type RecommendTermData = {
  //読んだ本の文字数と読了タイミングを時系列順に配列で持っている
  book: {
    wordCount: number;
  };
  whenDone: any; //eslint-disable-line
}[];

const GetGraphData = (
  recommendTermData: RecommendTermData = [], //読んだ本の文字数と読了タイミングの配列
  term: RecommendTerm, //期間
  readCharSum = 0, //現在の読んだ文字数
  userCreatedAt: Date,
  t: TFunction
) => {
  const date = new Date();
  let dummyTermData: string;
  switch (term) {
    case RecommendTerm.Days_30:
      date.setDate(new Date().getDate() - 30); //現在の30日前
      dummyTermData = date.toISOString();
      break;
    case RecommendTerm.Days_90:
      date.setDate(new Date().getDate() - 90); //現在の90日前
      dummyTermData = date.toISOString();
      break;
    case RecommendTerm.All:
      dummyTermData = userCreatedAt.toISOString();
      break;
  }

  let copyofCharSum = readCharSum;

  const tempX = recommendTermData
    .map(({ whenDone }) => whenDone)
    .reverse() //時系列順に
    .filter((v) => v);

  const tempY = recommendTermData
    .slice() //recommendTermData自身を変更しないようにする
    .map(({ book, whenDone }) => {
      if (whenDone) {
        const tmpCharSum = copyofCharSum;
        copyofCharSum -= book.wordCount; //現在の文字数から遡って、本それぞれの文字数を引いていく
        return tmpCharSum || 0;
      }
    }) //文字数配列が返る
    .filter((v) => v || v === 0) //最初の時点でonnetohにない本を選んだ場合を考慮して0は弾かないようにする
    .reverse(); //時系列順に

  const xData = [
    dummyTermData, //スタートの日時
    ...tempX,
    new Date().toISOString(),
  ];

  const yData = [
    copyofCharSum,
    ...tempY,
    readCharSum, //現在の文字数
  ];

  return {
    labels: xData,
    datasets: [
      {
        label: t<TranslateKeys>("読んだ文字数"),
        data: yData,
        fill: false,
        backgroundColor: theme.colors.pri.p400,
        borderColor: theme.colors.pri.p400,
        pointRadius: 4,
        lineTension: 0,
      },
    ],
    minCharSum: copyofCharSum,
  };
};

export const ReadCharGraph: React.FC = () => {
  const [recommendTerm, setRecommendTerm] = useState<RecommendTerm>(
    RecommendTerm.All
  );
  const { t } = useTranslation();

  const { loading, error, data } = useGetReadcharForGraphQuery({
    variables: {
      recommendTerm,
      end: 100,
    },
  });

  if (loading) return <Loading />;
  if (error) return <>error!{error.message}</>;

  const { labels, datasets, minCharSum } = GetGraphData(
    data?.me.recommendTerm.get as RecommendTermData,
    recommendTerm,
    data?.me.general.readCharSum as number,
    new Date(data?.me.createdAt),
    t
  );

  return (
    <>
      <SectionTitle>{t<TranslateKeys>("読んだ文字数")}</SectionTitle>
      <SelectedPeriodWrapper>
        <SelectPeriod>
          <SelectPeriodButton
            onClick={() => setRecommendTerm(RecommendTerm.Days_30)}
            isSelected={recommendTerm === RecommendTerm.Days_30 ? true : false}
          >
            {t<TranslateKeys>("1ヶ月")}
          </SelectPeriodButton>
          <SelectPeriodButton
            onClick={() => setRecommendTerm(RecommendTerm.Days_90)}
            isSelected={recommendTerm === RecommendTerm.Days_90 ? true : false}
          >
            {t<TranslateKeys>("3ヶ月")}
          </SelectPeriodButton>
          <SelectPeriodButton
            onClick={() => setRecommendTerm(RecommendTerm.All)}
            isSelected={recommendTerm === RecommendTerm.All ? true : false}
          >
            すべて
          </SelectPeriodButton>
        </SelectPeriod>
      </SelectedPeriodWrapper>
      <div>
        <Line
          data={{
            labels,
            datasets,
          }}
          options={{
            scales: {
              yAxes: [
                {
                  ticks: {
                    suggestedMin: Math.max(minCharSum - 100, 0),
                    suggestedMax: (data?.me.general.readCharSum || 0) + 100,
                    maxTicksLimit: 5,
                  },
                },
              ],
              xAxes: [
                {
                  type: "time",
                  time: {
                    unit: "week",
                    displayFormats: {
                      week: "M月D日",
                    },
                  },
                },
              ],
            },
          }}
        />
      </div>
    </>
  );
};

type SelectPeriodButtonProps = {
  isSelected: boolean;
};
const SelectPeriodButton = styled.button<SelectPeriodButtonProps>`
  background: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.pri.p500 : theme.colors.base.lightGray};
  color: ${({ isSelected }) =>
    isSelected ? theme.colors.base.white : theme.colors.tex.t200};
  font-size: 13px;
  border-radius: 6px;
  border: none;
  width: 76px;
  height: 20px;
`;

const SelectedPeriodWrapper = styled.div`
  text-align: center;
  margin: 15px auto;
`;

const SelectPeriod = styled.div`
  border-radius: 6px;
  background: ${({ theme }) => theme.colors.base.lightGray};
  display: inline-flex;
`;
