import React from "react";
import styled from "styled-components";
import { EmojiButton } from "~/components/molecules/buttons/EmojiButton";
import { YlReassesmentEnqueteDifficulty } from "~/generated/graphql";
import {
  ScrolledReadingViewer,
  ScrolledReadingViewerProps,
} from "../ScrolledReadingViewer";
import { Stack } from "~/components/atoms/layout/Stack";
import { Text } from "~/components/atoms/texts/Text";
import { useTranslation } from "~/i18n";

type ButtonFeatures = {
  emoji: string;
  text: string;
};

const buttonFeaturesRecord: Record<
  YlReassesmentEnqueteDifficulty,
  ButtonFeatures
> = {
  VERY_EASY: {
    emoji: "😋",
    text: "かんたん",
  },
  EASY: {
    emoji: "😗",
    text: "ちょっとかんたん",
  },
  NORMAL: {
    emoji: "😉",
    text: "ぴったり！",
  },
  DIFFICULT: {
    emoji: "😅",
    text: "ちょっとむずかしい",
  },
  VERY_DIFFICULT: {
    emoji: "😣",
    text: "むずかしい",
  },
};

export type SelectDifficultyProps = {
  sentence: ScrolledReadingViewerProps;
  onSelect: (difficulty: YlReassesmentEnqueteDifficulty) => void;
};

export const SelectDifficulty: React.FC<SelectDifficultyProps> = ({
  sentence,
  onSelect,
}) => {
  const { t } = useTranslation();
  return (
    <_ContentWrapper>
      <_SurveyWrapper>
        <Stack rowGap="20px">
          <ScrolledReadingViewer {...sentence} />
          <SenseiCommentWrapper>
            <SenseiIcon src="/img/sensei/icon_smile.jpg" />
            <Text
              fontSize="MD"
              lineHeight="MD"
              fontColor="semantic.text.heading"
            >
              {t<TranslateKeys>("この文章は どうだった？")}
            </Text>
          </SenseiCommentWrapper>
          <Stack rowGap="16px">
            {Object.keys(buttonFeaturesRecord).map(
              (difficulty: YlReassesmentEnqueteDifficulty) => (
                <EmojiButton
                  key={difficulty}
                  onClick={() => {
                    onSelect(difficulty);
                  }}
                  fontSize="XL"
                  lineHeight="EQ"
                  text={buttonFeaturesRecord[difficulty].text}
                  emoji={buttonFeaturesRecord[difficulty].emoji}
                  color="pri"
                  bold
                />
              )
            )}
          </Stack>
        </Stack>
      </_SurveyWrapper>
    </_ContentWrapper>
  );
};

const SenseiCommentWrapper = styled.div`
  width: 100%;
  padding: 4px 16px;

  border-radius: ${({ theme }) => theme.deprecatedBorderRadius.S};
  background-color: ${({ theme }) => theme.colors.base.white};

  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

export const SenseiIcon = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
`;

const _SurveyWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 0 6%;
  @media screen and (${({ theme }) =>
      theme.breakPoints.minWidthSmallSmartPhone}) {
    padding: 0 10%;
  }
  @media screen and (${({ theme }) => theme.breakPoints.minWidthSmallPC}) {
    padding: 0 20%;
  }
`;

const _ContentWrapper = styled.div`
  width: 100vw;
  max-width: 640px;
  max-height: 100svh;
  margin: 0 auto;
  height: 100%;

  overflow-y: scroll;
  padding-top: 60px;
  padding-bottom: 32px;
  position: relative;
`;
