import * as React from "react";
import styled, { css } from "styled-components";
import { Text, TextProps } from "~/components/atoms/texts/Text";
import { useTranslation } from "~/i18n";
import { RecommendStatus } from "~/generated/graphql";

type BookStatusProps = {
  /**
   * 本を読んだステータス
   */
  status: RecommendStatus;
};

export const BookStatus: React.VFC<BookStatusProps> = ({ status }) => {
  const { t } = useTranslation();
  const baseTextProps: TextProps = {
    fontSize: "XS",
    lineHeight: "EQ",
    bold: true,
    keepAll: true,
  };

  switch (status) {
    //MEMO: RecommendStatus.Discarded は本棚に表示しない
    case RecommendStatus.Reading:
      return (
        <SkyBlueOutlineText>
          <Text {...baseTextProps} fontColor="sky.s400">
            {t<TranslateKeys>("途中")}
          </Text>
        </SkyBlueOutlineText>
      );
    case RecommendStatus.Ended:
      return (
        <SkyBlueText>
          <Text {...baseTextProps} fontColor="base.white">
            よみきった
          </Text>
        </SkyBlueText>
      );
    case RecommendStatus.Default:
      return (
        <GrayOutlineText>
          <Text {...baseTextProps} fontColor="tex.t400">
            これから
          </Text>
        </GrayOutlineText>
      );
    case RecommendStatus.Quit:
      return (
        <GrayText>
          <Text {...baseTextProps} fontColor="tex.t400">
            やめた
          </Text>
        </GrayText>
      );
    default:
      return <></>;
  }
};

const commonStyle = css`
  display: flex;
  justify-content: center;
  padding: 8px;
  border-radius: 6px;
  border-width: 2px;
  border-style: solid;
`;

const SkyBlueText = styled.div`
  ${commonStyle}
  background: ${(props) => props.theme.colors.sky.s400};
  border-color: ${(props) => props.theme.colors.sky.s400};
`;

const SkyBlueOutlineText = styled.div`
  ${commonStyle}
  border-color: ${(props) => props.theme.colors.sky.s400};
  background: ${(props) => props.theme.colors.base.white};
`;

const GrayText = styled.div`
  ${commonStyle}
  border-color: ${(props) => props.theme.colors.base.lightGray};
  background: ${(props) => props.theme.colors.base.lightGray};
`;

const GrayOutlineText = styled.div`
  ${commonStyle}
  border-color: ${(props) => props.theme.colors.base.gray};
  background: ${(props) => props.theme.colors.base.white};
`;
