import React from "react";
import styled, { css } from "styled-components";

import { Row } from "~/components/atoms/layout/Row";
import { Icon } from "~/components/atoms/Icon";
import { Text } from "~/components/atoms/texts/Text";
import { UserIconType } from "~/generated/graphql";
import { userIconImage } from "~/components/organisms/adminMessage/AdminChatMessage";

export type UserAvailabilityRowProps = {
  isInvalid: boolean;
  selected: boolean;
  iconType: UserIconType;
  userName: string;
  onClick: () => void;
};

export const UserAvailabilityRow: React.FC<UserAvailabilityRowProps> = ({
  isInvalid,
  selected,
  iconType,
  userName,
  onClick,
}) => {
  return (
    <_RowButton onClick={onClick} disabled={isInvalid}>
      <Row columnGap="12px" justifyContent="space-between">
        <_CheckMark name="check" visible={selected} />
        <_UserIcon disabled={isInvalid} src={userIconImage[iconType]} />
        <_Name lineHeight="EQ" fontSize="XS" disabled={isInvalid}>
          {userName}
        </_Name>
        <_RecessingBox displayed={isInvalid}>
          <Text
            fontSize="XS"
            lineHeight="EQ"
            bold
            fontColor="primitive.red.r400"
          >
            休会中
          </Text>
        </_RecessingBox>
      </Row>
    </_RowButton>
  );
};

const _RowButton = styled.button<{ disabled: boolean }>`
  width: 100%;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "inherit")};

  //reset
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
`;

const _RecessingBox = styled.div<{ displayed: boolean }>`
  padding: ${({ theme }) => theme.size.s1} ${({ theme }) => theme.size.s1half};
  background-color: ${({ theme }) => theme.colors.primitive.red.r100};
  border-radius: ${({ theme }) => theme.borderRadius.s1half};
  visibility: ${({ displayed }) => (displayed ? "visible" : "hidden")};
`;

const _UserIcon = styled.img<{ disabled: boolean }>`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

const _Name = styled(Text)<{ disabled: boolean }>`
  flex: 1;
  color: ${({ theme }) => theme.colors.semantic.text.unselected};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  text-align: start;

  // NOTE: 1行で収まらない場合は省略
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${_RowButton}:hover &,
  ${_RowButton}:active &,
  ${_RowButton}:focus & {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.semantic.text.body};
  }
`;

const _CheckMark = styled(Icon)<{ visible: boolean }>`
  width: 16px;
  height: 16px;
  color: ${({ theme }) => theme.colors.semantic.primary.main};
  font-weight: bold;
  ${({ visible }) =>
    !visible &&
    css`
      visibility: hidden;
    `}
`;
