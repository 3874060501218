import React, { useContext, useEffect, useMemo, useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import { Text } from "~/components/atoms/texts/Text";
import {
  BaseTutorialModal,
  TutorialPageButton,
} from "~/components/molecules/modals/BaseTutorialModal";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import { TutorialFlagKey } from "~/generated/graphql";
import { TranslateKeys } from "~/i18n";

type TutorialPostReadLogModalContent = {
  content: React.ReactElement;
  buttons: TutorialPageButton[];
};

export type TutorialPostReadLogModalProps = {
  nickname: string;
};

export const TutorialPostReadLogModal: VFC<TutorialPostReadLogModalProps> = ({
  nickname,
}) => {
  const [tutorialPage, setTutorialPage] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();

  const { tutorialFlags, getTutorialFlag, saveTutorialFlags } =
    useContext(CurrentUserContext);

  useEffect(() => {
    if (
      tutorialFlags &&
      !getTutorialFlag(TutorialFlagKey.FirstImplessionReadLog)
    ) {
      saveTutorialFlags(TutorialFlagKey.FirstImplessionReadLog);
      setOpen(true);
    }
  }, [saveTutorialFlags]);

  const pages = useMemo(() => {
    return [
      {
        content: (
          <Text fontSize={"MD"} lineHeight={"MD"}>
            {t<TranslateKeys>("感想を 書く前に、")}
            <br />
            {t<TranslateKeys>(
              "ヨンデミー先生から {{ nickname }}さんに お願いがあるんだ！",
              { nickname }
            )}
          </Text>
        ),
        buttons: [
          {
            text: t<TranslateKeys>("なになに👀"),
            onClick: () => setTutorialPage(1),
          },
        ],
      },
      {
        content: (
          <Text fontSize={"MD"} lineHeight={"MD"}>
            {t<TranslateKeys>(
              "{{ nickname }}さんが 本を 読んで、思ったことは そのまま 教えてね👍",
              { nickname }
            )}
            <br />
            {t<TranslateKeys>("「難しい」とか「苦手」も ステキな 感想です😊")}
          </Text>
        ),
        buttons: [{ text: "まかせて！", onClick: () => setTutorialPage(2) }],
      },
      {
        content: (
          <Text fontSize={"MD"} lineHeight={"MD"}>
            {t<TranslateKeys>("{{ nickname }}さんが 感想を 教えてくれると、", {
              nickname,
            })}
            <br />
            {t<TranslateKeys>(
              "ヨンデミー先生は もっとピッタリの 本をおすすめできるようになるよ😎✨"
            )}
          </Text>
        ),
        buttons: [
          { text: "そうなんだ！", onClick: () => setTutorialPage(3) },
          { text: "ほうほう！", onClick: () => setTutorialPage(3) },
        ],
      },
      {
        content: (
          <Text fontSize={"MD"} lineHeight={"MD"}>
            {t<TranslateKeys>("本たちも、")}
            <br />
            {t<TranslateKeys>("{{ nickname }}さんの 感想を 待っているよ📚💞", {
              nickname,
            })}
          </Text>
        ),
        buttons: [
          { text: "そうだった！😊", onClick: () => setTutorialPage(4) },
          { text: "うんうん！", onClick: () => setTutorialPage(4) },
        ],
      },
      {
        content: (
          <Text fontSize={"MD"} lineHeight={"MD"}>
            {t<TranslateKeys>("それじゃあ、さっそく 感想を 書いてみよう！")}
          </Text>
        ),
        buttons: [
          {
            text: t<TranslateKeys>("感想を 書く！"),
            onClick: () => setOpen(false),
          },
        ],
      },
    ] as TutorialPostReadLogModalContent[];
  }, [tutorialPage]);

  const page = useMemo(() => {
    return pages[tutorialPage];
  }, [pages, tutorialPage]);

  const imgs = [
    "/img/sensei/standup_1.svg",
    "/img/sensei/standup_3.svg",
    "/img/sensei/standup_2.svg",
    "/img/sensei/standup_5.svg",
    "/img/sensei/standup_2.svg",
  ];

  return (
    <BaseTutorialModal
      isOpen={isOpen}
      buttons={page.buttons}
      onClose={() => setOpen(false)}
      imgSrc={imgs[tutorialPage]}
    >
      {page.content}
    </BaseTutorialModal>
  );
};
