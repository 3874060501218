import { getBrowser } from "~/utils/detectBrowser";
import { config } from "../../../config";
import { PwaTutorialCardItemProps } from "~/components/organisms/PwaTutorial/PwsTutorialList";
import { detect } from "detect-browser";
import { IMAGE_PATHS } from "~/helpers/constants/imagePath";
import React from "react";
import { A2HSContext } from "~/contexts/A2HSContext";

export type BrowserType =
  | "safari"
  | "safari_webview"
  | "android"
  | "android_webview";

/**
 * iosとandroidとMacOSの場合のみPWAチュートリアルが開く
 * MacOSはタッチパネルがある場合のみ開く
 */
const browserType = (installable: boolean): BrowserType | null => {
  const browserName = getBrowser();
  const browser = detect();
  console.info(`browser: ${browserName}`); //デバッグ用
  console.info(`installable: ${installable}`);
  const isChromium =
    window.navigator.userAgent.toLowerCase().indexOf("chrome") > -1;
  switch (browser?.os) {
    case "iOS":
      if (browserName === "ios") {
        return "safari";
      } else {
        return "safari_webview";
      }
    case "Android OS":
      // NOTE: Edgeなどがデフォルトのブラウザの時にも対応できるように、Chromiumかつinstallableならインストール可能にする
      if (isChromium && installable) {
        return "android";
      } else {
        return "android_webview";
      }
    case "Mac OS":
      if (browserName === "safari") {
        return "safari";
      } else {
        return null;
      }
    default:
      return null;
  }
};

export const useGetBrowserContent = (): {
  content: PwaTutorialCardItemProps[];
  popupIsOpen: boolean;
  popupText: string;
  setIsOpen: (popupIsOpen: boolean) => void;
} => {
  const { installApp, installable } = React.useContext(A2HSContext);
  const [popupIsOpen, setPopupIsOpen] = React.useState<boolean>(false);
  const [popupText, setPopupText] = React.useState<string>("");

  const setIsOpen = (isOpen: boolean) => {
    setPopupIsOpen(isOpen);
  };

  const ADD_HOME_BUTTON = {
    title: "下のボタンを押して、ホーム画面にヨンデミーを追加してください",
    buttonProps: {
      text: "ホーム画面に追加",
      onClick: () => installApp(),
    },
  };

  const OPEN_HOME = {
    title: "ホーム画面から開いてください",
    imageUrl: IMAGE_PATHS.PWA_RESULT,
    disabled: { content: false },
  };

  const CONFIRM_SAFARI = {
    title: "これでホーム画面からすぐにアプリを開けるようになりました！",
    imageUrl: IMAGE_PATHS.PWA_RESULT,
  };

  const CONFIRM_ANDROID = {
    title: "これでホーム画面からすぐにアプリを開けるようになりました！",
    imageUrl: IMAGE_PATHS.PWA_RESULT_ANDROID,
  };

  const SAFARI_IMAGE_IMAGE = {
    title: "「ホーム画面に追加」をしてください",
    imageUrl: IMAGE_PATHS.PWA_SAFARI,
  };

  const contents: Record<BrowserType, PwaTutorialCardItemProps[]> = {
    safari_webview: [
      {
        title: "ヨンデミーを Safariで開いてください",
        buttonProps: {
          text: "リンクをコピー",
          onClick: () => {
            if (!navigator.clipboard) {
              alert("クリップボードにコピーできませんでした");
              return false;
            }
            navigator.clipboard
              .writeText(`${config.USER_APP}pwa_tutorial`)
              .then(() => {
                setPopupText("リンクがコピーできました");
                setPopupIsOpen(true);
              });
          },
        },
        disabled: { next: false },
      },
      { ...SAFARI_IMAGE_IMAGE, disabled: { content: true, next: true } },
      { ...OPEN_HOME, disabled: { content: true, next: true } },
    ],
    android_webview: [
      {
        title: "ヨンデミーを Chromeで開いてください",
        buttonProps: {
          text: "リンクをコピー",
          onClick: () => {
            if (!navigator.clipboard) {
              alert("クリップボードにコピーできませんでした");
              return false;
            }
            navigator.clipboard
              .writeText(`${config.USER_APP}pwa_tutorial`)
              .then(() => {
                setPopupText("リンクがコピーできました");
                setPopupIsOpen(true);
              });
          },
        },
        disabled: { next: false },
      },
      { ...ADD_HOME_BUTTON, disabled: { content: true, next: true } },
      { ...CONFIRM_ANDROID, disabled: { content: true, next: true } },
    ],
    safari: [SAFARI_IMAGE_IMAGE, CONFIRM_SAFARI],
    android: [ADD_HOME_BUTTON, CONFIRM_ANDROID],
  };

  const browser = browserType(installable);

  return {
    content: browser === null ? [] : contents[browser],
    popupIsOpen,
    popupText,
    setIsOpen,
  };
};
