import { MissionStorySetting } from "../type";

const baseDir = "/img/mission/complete_story/story_5";

export const CompleteStory5: MissionStorySetting[] = [
  {
    image: `${baseDir}/scene_1.jpeg`,
    sound: "complete5_scene1",
    text: "「しつもんする」のワザの本をゆっくり開くと……。",
    ruby: [
      ...Array(12).fill(""),
      "ほん",
      ...Array(5).fill(""),
      "ひら",
      ...Array(5).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_2.jpeg`,
    sound: "complete5_scene2",
    text: "気がつくと、あたりは大自然でした。\nおや？　奥には、大きな山が見えます。",
    ruby: [
      "き",
      ...Array(9).fill(""),
      "だい",
      "し",
      "ぜん",
      ...Array(9).fill(""),
      "おく",
      ...Array(3).fill(""),
      "おお",
      ...Array(2).fill(""),
      "やま",
      "",
      "み",
      ...Array(4).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_3.jpeg`,
    sound: "complete5_scene3",
    text: "「もしかしてぇ……あれに登るの〜⁉」\n「シャクシャク、ワタシに任せなさい！どくしょかのワザ『しつもんする』のアイテム、『フック』を使うのよ！」",
    ruby: [
      ...Array(12).fill(""),
      "のぼ",
      ...Array(18).fill(""),
      "まか",
      ...Array(33).fill(""),
      "つか",
      ...Array(5).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_4.jpeg`,
    sound: "complete5_scene4",
    text: "「でもよぉ、フックなんてここには……」",
    ruby: [...Array(19).fill("")],
  },
  {
    image: `${baseDir}/scene_5.jpeg`,
    sound: "complete5_scene5",
    text: "「あっ……、えっとぉ……うち、フック持ってますぅ……」\n「持ってるんかーーーい‼」",
    ruby: [
      ...Array(18).fill(""),
      "も",
      ...Array(10).fill(""),
      "も",
      ...Array(11).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_6.jpeg`,
    sound: "complete5_scene6",
    text: "そんなとき、ガサガサ、ガサガサ。\n何やら後ろから音が聞こえるような……。\nモコひつじがおそるおそる振り返ると……。",
    ruby: [
      ...Array(17).fill(""),
      "なに",
      ...Array(2).fill(""),
      "うし",
      ...Array(3).fill(""),
      "おと",
      "",
      "き",
      ...Array(22).fill(""),
      "ふ",
      "",
      "かえ",
      ...Array(5).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_7.jpeg`,
    sound: "complete5_scene7",
    text: "「え〜〜‼　待ってなにこれ！このモコモコ、ちょーキュート‼　しかも、ちょーー上質じゃん‼」「モ、モコ〜〜〜〜〜⁉⁉⁉」",
    ruby: [
      ...Array(6).fill(""),
      "ま",
      ...Array(31).fill(""),
      "じょう",
      "しつ",
      ...Array(20).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_8.jpeg`,
    sound: "complete5_scene8",
    text: "「え、この色もこの色もキュート‼　やば、みんなキュートキュートキュート‼　アイデアが溢れてきちゃった‼」",
    ruby: [
      ...Array(5).fill(""),
      "いろ",
      ...Array(3).fill(""),
      "いろ",
      ...Array(32).fill(""),
      "あふ",
      ...Array(9).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_9.jpeg`,
    sound: "complete5_scene9",
    text: "「え、えっとぉ……あなたは……誰ですか……？」",
    ruby: [...Array(15).fill(""), "だれ", ...Array(7).fill("")],
  },
  {
    image: `${baseDir}/scene_10.jpeg`,
    sound: "complete5_scene10_1",
    text: "「ボク？\n世界一のファッションデザイナーになるスペシャルキュートなキリン、よみキリン！\nチャオ〜！」",
    ruby: [...Array(5).fill(""), "せ", "かい", "いち", ...Array(43).fill("")],
  },
  {
    image: `${baseDir}/scene_10.jpeg`,
    sound: "complete5_scene10_2",
    text: "不思議なピンク色のキリンとの出会い。\n何やら、またおもしろいことが起きそうです\nね。",
    ruby: [
      "ふ",
      "し",
      "ぎ",
      ...Array(4).fill(""),
      "いろ",
      ...Array(6).fill(""),
      "で",
      "あ",
      ...Array(3).fill(""),
      "なに",
      ...Array(13).fill(""),
      "お",
      ...Array(8).fill(""),
    ],
  },
];
