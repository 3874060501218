import { MissionStorySetting } from "../type";

const baseDir = "/img/mission/complete_story/story_7";

export const CompleteStory7: MissionStorySetting[] = [
  {
    image: `${baseDir}/scene_1.jpeg`,
    sound: "complete7_scene1",
    text: "気がつくと図書館に戻って来ていたみんな。そしてなんと、あのオオカミまで一緒に図書館に来てしまっていたのでした。",
    ruby: [
      "き",
      ...Array(4).fill(""),
      "と",
      "しょ",
      "かん",
      "",
      "もど",
      ...Array(2).fill(""),
      "き",
      ...Array(22).fill(""),
      "いっ",
      "しょ",
      "",
      "と",
      "しょ",
      "かん",
      "",
      "き",
      ...Array(12).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_2.jpeg`,
    sound: "complete7_scene2",
    text: "「とりあえずさぁ、一回みんなで冒険の宿に帰ろうよ〜」",
    ruby: [
      ...Array(9).fill(""),
      "いっ",
      "かい",
      ...Array(4).fill(""),
      "ぼう",
      "けん",
      "",
      "やど",
      "",
      "かえ",
      ...Array(5).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_3.jpeg`,
    sound: "complete7_scene3_1",
    text: "どくしょかのしょから冒険の宿を出すと……。「スッゲー‼︎　なんか宿が進化してる‼︎」",
    ruby: [
      ...Array(10).fill(""),
      "ぼう",
      "けん",
      "",
      "やど",
      "",
      "だ",
      ...Array(15).fill(""),
      "やど",
      "",
      "しん",
      "か",
      ...Array(5).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_3.jpeg`,
    sound: "complete7_scene3_2",
    text: "「スターが集まってきて、どくしょかのしょが力を得たんだね！」",
    ruby: [
      ...Array(5).fill(""),
      "あつ",
      ...Array(15).fill(""),
      "ちから",
      "",
      "え",
      ...Array(8).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_4.jpeg`,
    sound: "complete7_scene4",
    text: "冒険の宿を初めて見たよみキリンは大喜びです。「さあ、みんなで美味しいごはんをたらふく食べようぜ！」",
    ruby: [
      "ぼう",
      "けん",
      "",
      "やど",
      "",
      "はじ",
      ...Array(2).fill(""),
      "み",
      ...Array(7).fill(""),
      "おお",
      "よろこ",
      ...Array(12).fill(""),
      "お",
      "い",
      ...Array(10).fill(""),
      "た",
      ...Array(6).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_5.jpeg`,
    sound: "complete7_scene5",
    text: "ペンギンせんぱいコックの腕の見せどころ。お部屋は美味しそうな匂いに包まれています。",
    ruby: [
      ...Array(12).fill(""),
      "うで",
      "",
      "み",
      ...Array(6).fill(""),
      "へ",
      "や",
      "",
      "お",
      "い",
      ...Array(4).fill(""),
      "にお",
      ...Array(2).fill(""),
      "つつ",
      ...Array(7).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_6.jpeg`,
    sound: "complete7_scene6",
    text: "いっただっきまーす！",
    ruby: [...Array(10).fill("")],
  },
  {
    image: `${baseDir}/scene_7.jpeg`,
    sound: "complete7_scene7",
    text: "みんなで楽しく食べて、笑って、踊って。冒険の疲れも吹き飛んでしまいそう。",
    ruby: [
      ...Array(4).fill(""),
      "たの",
      ...Array(2).fill(""),
      "た",
      ...Array(3).fill(""),
      "わら",
      ...Array(3).fill(""),
      "おど",
      ...Array(3).fill(""),
      "ぼう",
      "けん",
      "",
      "つか",
      ...Array(2).fill(""),
      "ふ",
      "",
      "と",
      ...Array(8).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_8.jpeg`,
    sound: "complete7_scene8",
    text: "「あのオオカミさん、ひとりで何をしていらっしゃるんでしょうか……」「しらねぇ！　こんな美味しいご馳走があるのにな！」",
    ruby: [
      ...Array(14).fill(""),
      "なに",
      ...Array(28).fill(""),
      "お",
      "い",
      ...Array(3).fill(""),
      "ち",
      "そう",
      ...Array(8).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_9.jpeg`,
    sound: "complete7_scene9_1",
    text: "楽しいご飯の時間がおわり、みんなが眠りにつくころ、大はしゃぎして暑くなってしまった太古のきょうりゅうは、図書館の窓辺で涼んでいました。",
    ruby: [
      "たの",
      ...Array(3).fill(""),
      "はん",
      "",
      "じ",
      "かん",
      ...Array(9).fill(""),
      "ねむ",
      ...Array(7).fill(""),
      "おお",
      ...Array(6).fill(""),
      "あつ",
      ...Array(8).fill(""),
      "たい",
      "こ",
      ...Array(9).fill(""),
      "と",
      "しょ",
      "かん",
      "",
      "まど",
      "べ",
      "",
      "すず",
      ...Array(25).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_9.jpeg`,
    sound: "complete7_scene9_2",
    text: "「それにしてもあのオオカミ、いったい何者なんだろうな」",
    ruby: [...Array(18).fill(""), "なに", "もの", ...Array(14).fill("")],
  },
  {
    image: `${baseDir}/scene_10.jpeg`,
    sound: "complete7_scene10",
    text: "「まぁいっか、オイラも寝よ〜っと！」綺麗なお月さまの光が、雲の隙間から見えていました。",
    ruby: [
      ...Array(11).fill(""),
      "ね",
      ...Array(6).fill(""),
      "き",
      "れい",
      ...Array(2).fill(""),
      "つき",
      ...Array(3).fill(""),
      "ひかり",
      ...Array(2).fill(""),
      "くも",
      "",
      "すき",
      "ま",
      ...Array(2).fill(""),
      "み",
      ...Array(7).fill(""),
    ],
  },
];
