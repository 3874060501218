import React from "react";
import { NotifyButtonTextToast } from "~/components/organisms/toasts/NotifyButtonTextToast";
import { useTranslation } from "~/i18n";

export type GotoYlReassesmentEnqueteToastProps = {
  isOpen: boolean;
  onClose: () => void;
  onButtonClick: () => void;
};

export const GotoYlReassesmentEnqueteToast: React.FC<
  GotoYlReassesmentEnqueteToastProps
> = ({ isOpen, onClose, onButtonClick }) => {
  const { t } = useTranslation();
  return (
    <NotifyButtonTextToast
      text={t<TranslateKeys>(
        "本を削除しました。YLが合わないときは、\nYL相談をおすすめします。"
      )}
      buttonText={t<TranslateKeys>("相談する")}
      isOpen={isOpen}
      onClose={onClose}
      onButtonClick={onButtonClick}
    />
  );
};
