import React from "react";
import styled from "styled-components";
import { MissionTaskType } from "~/generated/graphql";
import { Text } from "~/components/atoms/texts/Text";
import { Row } from "~/components/atoms/layout/Row";
import { useTranslation } from "react-i18next";
import { taskTypeToDisplayMaterial } from "./const";
import { TranslateKeys } from "~/i18n";

type MissionTaskTextProps = {
  missionTaskType: MissionTaskType;
  goal: number;
};

export const MissionTaskText: React.FC<MissionTaskTextProps> = ({
  missionTaskType,
  goal,
}: MissionTaskTextProps) => {
  const displayMaterial = taskTypeToDisplayMaterial[missionTaskType];
  const { t } = useTranslation();
  return (
    <Row columnGap="4px">
      <Text lineHeight="MD" fontSize="SM" bold={false}>
        <_BoldSpan>{t<TranslateKeys>(displayMaterial.typeName)}</_BoldSpan>を
        <_GoalSpan>
          {goal}
          {t<TranslateKeys>(displayMaterial.unit)}
        </_GoalSpan>
        {t<TranslateKeys>(displayMaterial.verb)}
      </Text>
    </Row>
  );
};

const _BoldSpan = styled.span`
  font-weight: bold;
`;

const _GoalSpan = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.semantic.layout.backgroundBox};

  padding: 2px 4px;
  margin: 0px 4px;
  background-color: ${({ theme }) => theme.colors.semantic.primary.minus1};
  border-radius: 4px;
`;
