import React, { useContext, FC } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useBookFriendReactions } from "~/components/organisms/BookFriendReactions/useBookFriendReactions";
import { useStampCard } from "~/components/organisms/Bookshelf/MissionAndStampCarousel/StampCard/useStampCard";
import { BookshlefTemplate } from "~/components/templates/Bookshelf";
import { A2HSContext } from "~/contexts/A2HSContext";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import { entryPageOfRemoveRecommendVar } from "../RemoveRecommend";
import { TopPageModals } from "./modalsContainer";
import { useGetMissionProgressQuery, MissionState } from "~/generated/graphql";
import { LoadingPage } from "~/components/templates/Loading/LoadingPage";
import { StartMissionFlowContainer } from "~/components/templates/StartMissionFlow/container";
import { CompleteMissionFlowContainer } from "~/components/templates/CompleteMissionFlow/container";
import { useReactiveVar } from "@apollo/client";
import { isShowGotoYlReassesmentToastVar } from "../YLReassesmentEnquete";
import { ylReassesmentEnqueteQueryStringRecord } from "~/pages/YLReassesmentEnquete";
import { GotoYlReassesmentEnqueteToast } from "~/components/organisms/BookSelectionAdjustment/ylReassesmentEnquete/GotoYlReassesmentEnqueteToast";
import { StoryReplayModal } from "~/components/organisms/modals/QuestLevel/StoryReplayModal";
import { STORY_PAGE_SESSION_STORAGE_KEY } from "~/components/organisms/modals/QuestLevel/MissionStory";
import { CURRENT_TAB_KEY } from "~/components/organisms/Bookshelf/Bookshelf/useHistoryBookshelf";

export const Bookshelf: FC = () => {
  const navigate = useNavigate();
  const search = useLocation().search;
  const params = new URLSearchParams(search);
  const { standalone } = useContext(A2HSContext);
  const [isOpenStoryReplayModal, setIsOpenStoryReplayModal] =
    React.useState<boolean>(false);

  const { refetch: refetchCurrentUser } = useContext(CurrentUserContext);

  const { stampCardProps, loading: loadingStampCard } = useStampCard();

  const useBookFriendReactionsValues = useBookFriendReactions();
  const { reactionsModal, reactionsButton } = useBookFriendReactionsValues;

  const {
    data: queryData,
    loading,
    error,
    refetch,
  } = useGetMissionProgressQuery({
    fetchPolicy: "network-only",
  });

  const isShowGotoYlReassesmentToast = useReactiveVar(
    isShowGotoYlReassesmentToastVar
  );

  React.useEffect(() => {
    if (standalone != undefined) {
      window.gtag("event", "isPWA", {
        event_category: "book",
        isPWA: standalone,
        value: 1,
      });
    }

    entryPageOfRemoveRecommendVar("bookShelf");
  }, [standalone]);

  const handleChangeTab = (page: number): void => {
    // タブ切り替え時に未読インジケーターを更新する
    refetchCurrentUser();
    switch (page) {
      case 0:
        navigate("/bf");
        break;
      case 1:
        navigate("/");
        break;
      case 2:
        navigate("/message");
        break;
      default:
        return;
    }
  };

  const handleClickReviewButton = () => {
    navigate("/postreadlog/init?flow=selectBook");
  };

  const goToBookshelf = () => {
    navigate("/");
    refetch();
  };

  const handleOpenStory = () => {
    if (queryData?.getMissionProgress.missionOrder) {
      setIsOpenStoryReplayModal(true);
    }
  };

  if (loading || !queryData) return <LoadingPage />;
  if (error) return <>error!{error.message}</>;

  switch (queryData?.getMissionProgress.missionState) {
    case MissionState.NeedStart: {
      if (!queryData.getMissionProgress.missionOrder) {
        return <LoadingPage />;
      }
      return (
        <StartMissionFlowContainer
          missionOrder={queryData.getMissionProgress.missionOrder}
          goToBookshelf={goToBookshelf}
        />
      );
    }
    case MissionState.NeedComplete:
      if (!queryData.getMissionProgress.missionOrder) {
        return <LoadingPage />;
      }
      return (
        <CompleteMissionFlowContainer
          missionOrder={queryData.getMissionProgress.missionOrder}
          goToBookshelf={goToBookshelf}
        />
      );
    default:
      return (
        <>
          {reactionsModal}
          {isOpenStoryReplayModal &&
            queryData?.getMissionProgress.missionOrder &&
            queryData?.getMissionProgress.missionState !==
              MissionState.AllOver && (
              <StoryReplayModal
                missionOrder={queryData.getMissionProgress.missionOrder}
                onCompleted={() => {
                  setIsOpenStoryReplayModal(false);
                  try {
                    sessionStorage.removeItem(STORY_PAGE_SESSION_STORAGE_KEY);
                  } catch (e) {
                    console.error(e);
                  }
                }}
              />
            )}
          <TopPageModals
            isOpen={true}
            stampCardProps={stampCardProps}
            loadingStampCard={loadingStampCard}
          />
          <GotoYlReassesmentEnqueteToast
            isOpen={isShowGotoYlReassesmentToast}
            onClose={() => {
              isShowGotoYlReassesmentToastVar(false);
            }}
            onButtonClick={() => {
              isShowGotoYlReassesmentToastVar(false);
              navigate(
                `/yl_reassessment_enquete?entry_page=${ylReassesmentEnqueteQueryStringRecord.REMOVE_RECOMMEND}`
              );
            }}
          />
          <BookshlefTemplate
            onChangeTab={handleChangeTab}
            onClickReviewButton={handleClickReviewButton}
            reactionsButton={reactionsButton}
            tab={sessionStorage.getItem(CURRENT_TAB_KEY) || undefined}
            hash={params.get("hash") || undefined}
            missionAndStampCarouselProps={{
              isShowMissionProgress:
                !!queryData?.getMissionProgress.missionOrder &&
                queryData?.getMissionProgress.missionState !==
                  MissionState.BeforeFirstMission &&
                queryData?.getMissionProgress.missionState !==
                  MissionState.WaitingForNextMission &&
                queryData?.getMissionProgress.missionState !==
                  MissionState.AllOver,
              userMissionTasks: queryData?.getMissionProgress.userMissionTasks,
              stampCardProps: stampCardProps,
              openStory: handleOpenStory,
            }}
          />
        </>
      );
  }
};
