import React, { FC, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingPage } from "~/components/templates/Loading/LoadingPage";
import {
  RecommendStatus,
  TutorialFlagKey,
  useGetWantReadBookByIdQuery,
  useRemoveWantReadBookMutation,
} from "../../generated/graphql";
import { useCalil } from "../../store/bookViewer/useCalil";
import { BookDetailInfoTemplate } from "~/components/templates/BookDetailInfo";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import { ConfirmRemoveYomimashokaModal } from "../../components/organisms/Bookshelf/YomimashokaBookshelf/ConfirmRemoveYomimashokaModal";

export const ViewWantReadBook: FC = (): React.ReactElement => {
  const navigate = useNavigate();
  const { calilSearchWordModal, handleClickGoToCalil } = useCalil();
  const { tutorialFlags, getTutorialFlag, saveTutorialFlags } =
    React.useContext(CurrentUserContext);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const { id = "" } = useParams<{ id: string }>();

  const { data, loading, error } = useGetWantReadBookByIdQuery({
    variables: { id: Number(id) },
    fetchPolicy: "network-only",
  });

  const wantReadBook = data?.getWantReadBookByID;

  const handlePostReadLog = useCallback(() => {
    if (!wantReadBook) {
      return;
    }

    const searchParams = new URLSearchParams({
      title: wantReadBook.book.title ? wantReadBook.book.title : "",
      ISBN: wantReadBook.book.isbn,
    });

    searchParams.append("flow", "confirmReadStatus");
    navigate(`/postreadlog/init?${searchParams.toString()}`);
  }, [navigate, wantReadBook]);

  const [removeWantReadBook, { loading: removeLoading }] =
    useRemoveWantReadBookMutation({
      onCompleted: () => {
        navigate("/?tab=yomimashoka");
      },
    });

  const openRemoveModal = () => {
    setIsOpen(true);
  };

  const handleRemove = () => {
    return new Promise<boolean>((resolve) => {
      removeWantReadBook({ variables: { id: Number(id) } })
        .then(() => resolve(true))
        .catch(() => resolve(false));
      resolve(false);
    });
  };

  if (loading || !wantReadBook) return <LoadingPage />;
  if (error) return <>Error! {error.message}</>;

  return (
    <>
      {calilSearchWordModal}
      <BookDetailInfoTemplate
        book={wantReadBook.book}
        isRecommend={false}
        recommendStatus={wantReadBook.recommendStatus}
        handleBack={() => navigate(-1)}
        handleAlertClose={
          tutorialFlags &&
          !getTutorialFlag(TutorialFlagKey.HowToGetNextRecommendAlertClosed) &&
          wantReadBook.recommendStatus === RecommendStatus.Default
            ? () =>
                saveTutorialFlags(
                  TutorialFlagKey.HowToGetNextRecommendAlertClosed
                )
            : undefined
        }
        handleClickGoToCalil={handleClickGoToCalil}
        footerProps={{
          handlePostReadLog,
          handleRemoveRecommend: openRemoveModal,
        }}
      />
      <ConfirmRemoveYomimashokaModal
        onClickRemove={handleRemove}
        onClickReturn={() => {
          setIsOpen(false);
        }}
        isOpen={isOpen}
        loading={removeLoading}
      />
    </>
  );
};
