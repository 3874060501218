import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { passageDict, YLAndSentenceOrder, PassageContent } from "./const";
import { PassageYL } from "./types";
import { ReadingViewerProps } from "./ReadingViewer";
import { YlReassesmentEnqueteDifficulty } from "~/generated/graphql";
import { ReadAllSentence } from "./ReadAllSentence";
import { SelectDifficulty } from "./SelectDifficulty";
import { ThanksForAnswering } from "./ThanksForAnswering";
import { YLEnqueteBackNavbar } from "../../ui/YLEnqueteBackNavbar";
import { HeartLine } from "../../ui/HeartLine";

type PageType = "readAll" | "selectDifficulty" | "thanksForAnswering";

export type YLReassementEnqueteProps = {
  ylAndSentenceOrderArr: YLAndSentenceOrder[];
  updateEnqueteResult: (
    passageYL: PassageYL,
    difficulty: YlReassesmentEnqueteDifficulty
  ) => void;
  onNext: () => void;
  handleBack: (enqueteIndex: number) => void;
  onQuit: () => void;
  nickname: string;
};

export const YLReassesmentEnquete: React.FC<YLReassementEnqueteProps> = ({
  ylAndSentenceOrderArr,
  updateEnqueteResult,
  onNext,
  handleBack,
  onQuit,
  nickname,
}) => {
  const [enqueteIndex, setEnqueteIndex] = useState(0);
  const [pageType, setPageType] = useState<PageType>("readAll");

  const sentence: ReadingViewerProps = useMemo(() => {
    const { passageYL, sentenceOrder } = ylAndSentenceOrderArr[enqueteIndex];
    const sentence: PassageContent = passageDict[passageYL];
    const { textAndCaption, ...props } = sentence;
    return {
      text: textAndCaption[sentenceOrder].text,
      caption: textAndCaption[sentenceOrder].caption,
      ...props,
    };
  }, [enqueteIndex, ylAndSentenceOrderArr]);

  const onSelectDifficulty = React.useCallback(
    (difficulty: YlReassesmentEnqueteDifficulty) => {
      updateEnqueteResult(
        ylAndSentenceOrderArr[enqueteIndex].passageYL,
        difficulty
      );
      if (enqueteIndex == ylAndSentenceOrderArr.length - 1) {
        setPageType("thanksForAnswering");
      } else {
        setEnqueteIndex((index) => index + 1);
        setPageType("readAll");
      }
    },
    [updateEnqueteResult, enqueteIndex, ylAndSentenceOrderArr]
  );

  const onBack = React.useCallback(() => {
    if (pageType === "readAll") {
      handleBack(enqueteIndex);
      setPageType("selectDifficulty");
      setEnqueteIndex((index) => index - 1);
      return;
    }
    if (pageType === "selectDifficulty") {
      setPageType("readAll");
      return;
    }
  }, [enqueteIndex, handleBack, pageType]);

  const content = React.useMemo<React.ReactNode>(() => {
    switch (pageType) {
      case "readAll":
        return (
          <_LibraryWrapper>
            <YLEnqueteBackNavbar
              withBlur={true}
              onBackButton={onBack}
              onQuitButton={onQuit}
            />
            <_HeartLineWrapper>
              <HeartLine filledHearts={enqueteIndex + 1} />
            </_HeartLineWrapper>
            <ReadAllSentence
              sentence={sentence}
              onNext={() => {
                setPageType("selectDifficulty");
              }}
            />
          </_LibraryWrapper>
        );
      case "selectDifficulty":
        return (
          <_LibraryWrapper>
            <YLEnqueteBackNavbar
              withBlur={true}
              onQuitButton={onQuit}
              onBackButton={onBack}
            />
            <SelectDifficulty
              sentence={sentence}
              onSelect={onSelectDifficulty}
            />
          </_LibraryWrapper>
        );
      case "thanksForAnswering":
        return (
          <>
            <YLEnqueteBackNavbar withBlur={true} />
            <ThanksForAnswering
              onNext={() => {
                onNext();
              }}
              nickname={nickname}
            />
          </>
        );
    }
  }, [
    onBack,
    onQuit,
    sentence,
    onSelectDifficulty,
    pageType,
    enqueteIndex,
    nickname,
    onNext,
  ]);

  return <>{content}</>;
};

const _LibraryWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  max-height: 100svh;
  overflow: hidden;
  background-image: url("/img/initialize_modal/bookshelf.png");

  background-repeat: repeat-x;
  background-size: contain;

  position: relative;

  ::before {
    content: "";
    background-color: rgba(0, 0, 0, 0.45);

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
  }
`;

const _HeartLineWrapper = styled.div`
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
`;
