import React from "react";
import styled, { css } from "styled-components";
import { BaseModal } from "~/components/atoms/wrappers/modal";
import { PrimaryButton } from "~/components/molecules/buttons/PrimaryButton";
import { TextButton } from "~/components/molecules/buttons/TextButton";
import { Text } from "~/components/atoms/texts/Text";
import { userIconImage } from "~/components/organisms/adminMessage/AdminChatMessage";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import { UserIconType, UserPlan } from "~/generated/graphql";
import { Stack } from "~/components/atoms/layout/Stack";
import { Selectbox } from "~/components/atoms/input/Selectbox";
import { useNavigate } from "react-router-dom";

type SkipButtonProps = {
  label: string;
  onSkip: () => void;
};

export type LoginTipsButton = {
  text: string;
  onClick: () => void;
};

export type CommonStyleProps = {
  loginTipsButtonProps?: LoginTipsButton;
  children: React.ReactNode;
  skipButtonProps?: SkipButtonProps;
};

export const CommonStyle: React.FC<CommonStyleProps> = ({
  loginTipsButtonProps,
  children,
  skipButtonProps,
}) => {
  const navigate = useNavigate();
  const { currentUser, userAvailabilities } =
    React.useContext(CurrentUserContext);
  const [showChangeUser, setShowChangeUser] = React.useState<boolean>(false);

  return (
    <BaseModal isOpen={true}>
      <_Wrapper>
        <_BallonCon>
          <_Ballon>
            <_Group>{children}</_Group>
            {loginTipsButtonProps && (
              <_ButtonWrapper>
                <PrimaryButton
                  onClick={loginTipsButtonProps.onClick}
                  disabled={false}
                  color={"pri"}
                  text={loginTipsButtonProps.text}
                  withArrow={false}
                  fontSize={"XL"}
                  lineHeight={"EQ"}
                  bold
                  padding="16px 0"
                />
              </_ButtonWrapper>
            )}

            {skipButtonProps && (
              <_AnnotCon>
                <TextButton
                  onClick={skipButtonProps.onSkip}
                  text={skipButtonProps.label}
                  fontSize={"XS"}
                  lineHeight={"MD"}
                  fontColor={"tex.t500"}
                />
              </_AnnotCon>
            )}
          </_Ballon>
          <div
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
          >
            <svg
              width="17"
              height="12"
              viewBox="0 0 17 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <_TrianglePath d="M11.1101 10.394C9.9609 12.4219 7.0391 12.4219 5.88993 10.394L-2.19324e-07 -6.5958e-07L17 -2.14577e-06L11.1101 10.394Z" />
            </svg>
          </div>
        </_BallonCon>
        <_Img src={"/img/sensei/standup_2.svg"} alt={"ヨンデミー先生です"} />
      </_Wrapper>
      {userAvailabilities.length > 1 && (
        <_Stack alignItems="flex-end" rowGap="8px">
          <_UserIcon
            src={
              userIconImage[
                currentUser?.general.userIconType || UserIconType.Worm
              ]
            }
            onClick={() => {
              setShowChangeUser((prev) => !prev);
            }}
          />
          {showChangeUser && (
            <_ChangeUserWrapper>
              <Stack rowGap="16px">
                <Text
                  lineHeight="EQ"
                  fontSize="SM"
                  fontColor="semantic.text.heading"
                  bold
                >
                  アカウント切り替え
                </Text>
                <Selectbox
                  options={userAvailabilities.map((account) => {
                    return {
                      value: String(account.userID),
                      node: <p>{account.nickname}</p>,
                      isDisabled: account.userPlan === UserPlan.Invalidation,
                    };
                  })}
                  value={String(currentUser?.general.id)}
                  onChange={(userID) => {
                    navigate(`/set_user/${userID}`);
                  }}
                />
              </Stack>
            </_ChangeUserWrapper>
          )}
        </_Stack>
      )}
    </BaseModal>
  );
};

const _Stack = styled(Stack)`
  position: absolute;
  right: 20px;
`;

const _ChangeUserWrapper = styled.div`
  width: 218px;
  padding: 16px;
  border-radius: 16px 0px 16px 16px;
  background-color: ${({ theme }) =>
    theme.colors.semantic.layout.backgroundBox};
`;

const _UserIcon = styled.img`
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

const _Wrapper = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 2vh;
  max-height: 95dvh;
  overflow: scroll;

  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
`;

const _ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.size.S};
`;

const _AnnotCon = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const _Group = styled.div`
  white-space: pre-wrap;
`;

const _Ballon = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.size.M};
  ${({ theme }) => {
    return css`
      background-color: ${theme.colors.base.white};
      border-radius: ${theme.deprecatedBorderRadius.MD};
      padding: ${theme.size.XXL} ${theme.size.L} ${theme.size.L} ${theme.size.L};
    `;
  }}
`;

const _BallonCon = styled.div`
  @media screen and ${({ theme }) => theme.breakPoints.maxWidthSmallPC} {
    width: 50vw;
  }
  @media screen and ${({ theme }) => theme.breakPoints.maxWidthSmartPhone} {
    width: 70vw;
  }
  @media screen and ${({ theme }) =>
      theme.breakPoints.maxWidthSmallSmartPhone} {
    width: 90vw;
  }
  @media screen and ${({ theme }) => theme.breakPoints.minWidthSmallPC} {
    width: 400px;
  }
`;

const _TrianglePath = styled.path`
  fill: ${({ theme }) => theme.colors.base.white};
  transform: translateY(-1px);
`;

const _Img = styled.img`
  @media screen and ${({ theme }) => theme.breakPoints.maxWidthSmallPC} {
    width: 12vw;
  }
  @media screen and ${({ theme }) => theme.breakPoints.maxWidthSmartPhone} {
    width: 16vw;
  }
  @media screen and ${({ theme }) =>
      theme.breakPoints.maxWidthSmallSmartPhone} {
    width: 20vw;
  }
  @media screen and ${({ theme }) => theme.breakPoints.minWidthSmallPC} {
    width: 90px;
  }
`;
