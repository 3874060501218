import React from "react";
import styled from "styled-components";
import BarcodeScanner from "./barcode_scanner";
import { BaseModal } from "~/components/atoms/wrappers/BaseModal";
import { LargeButton } from "~/components/atoms/buttons/Button";
import { Stack } from "~/components/atoms/layout/Stack";
import { LoadingSection } from "~/components/templates/Loading/LoadingSection";

type BarcodeScannerModalProps = {
  isOpen: boolean;
  onDetected: (result: string) => boolean;
  onError: () => void;
  isError: boolean;
  onQuit: () => void;
  loading: boolean;
};

export const BarcodeScannerModal: React.VFC<BarcodeScannerModalProps> = ({
  isOpen,
  onDetected,
  onError,
  isError,
  onQuit,
  loading,
}) => {
  return (
    <BaseModal open={isOpen} closeOnOverlayClick={false} onClose={onQuit}>
      <_Stack rowGap="16px" alignItems="center">
        {loading ? (
          <LoadingSection />
        ) : (
          <>
            {isError ? (
              <div style={{ width: "280px", height: "200px" }}>
                カメラの使用が許可されていません。ブラウザの設定をご確認ください。
              </div>
            ) : (
              <ScannerVideo>
                <BarcodeScanner onDetected={onDetected} onError={onError} />
              </ScannerVideo>
            )}
            <BarcodeImg src="/img/barcode.png" alt="barcode" />
            <LargeButton color="primary" variant="blank" onClick={onQuit}>
              とじる
            </LargeButton>
          </>
        )}
      </_Stack>
    </BaseModal>
  );
};

const ScannerVideo = styled.div`
  width: 100%;
  max-height: 300px;
  margin-top: 25px;
  #interactive.viewport {
    position: relative;
    overflow: hidden;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    video,
    canvas {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
`;

const BarcodeImg = styled.img`
  width: 90px;
  margin: 0 auto;
`;

const _Stack = styled(Stack)`
  width: calc(100svw - 104px);
  max-width: 480px;
`;
