import React, { FC } from "react";
import styled from "styled-components";
import { Stack } from "~/components/atoms/layout/Stack";
import { Text } from "~/components/atoms/texts/Text";
import { PrimaryAnchor } from "~/components/molecules/buttons/PrimaryButton";
import { Dialog } from "~/components/molecules/modals/Dialog";
import { RecommendTutorialModalList } from "~/components/organisms/modals/RecommendTutorialModalList";
import { CurrentUserFragment } from "~/generated/graphql";

type RecommendTutorialTemplateProps = {
  handleClose: () => void;
  parentPagePath: string;
  showFinishingDialog: boolean;
  handleOpenFinishingDialog: () => void;
  currentUser?: CurrentUserFragment;
};

export const RecommendTutorialTemplate: FC<RecommendTutorialTemplateProps> = ({
  handleClose,
  showFinishingDialog,
  handleOpenFinishingDialog,
  parentPagePath,
  currentUser,
}) => (
  <>
    <Dialog
      body={
        <Text fontSize={"MD"} lineHeight={"MD"}>
          おすすめの本の入手は、保護者アプリから行えます！
          <br />
          図書館のオンライン予約・取り寄せが使えて便利です。
        </Text>
      }
      isOpen={showFinishingDialog}
    >
      <_Stack rowGap="20px">
        <PrimaryAnchor
          color={"pri"}
          href={parentPagePath}
          disabled={false}
          text="保護者アプリを見る"
          fontSize={"MD"}
          lineHeight={"MD"}
          onClick={handleClose}
        />
        <_CloseButton onClick={handleClose}>
          <Text fontColor="tex.t200" fontSize="MD" lineHeight="EQ">
            とじる
          </Text>
        </_CloseButton>
      </_Stack>
    </Dialog>
    <RecommendTutorialModalList
      currentUser={currentUser}
      handleClose={handleOpenFinishingDialog}
    />
  </>
);

const _CloseButton = styled.button`
  margin: 0 auto;
  padding: 0;
  background-color: inherit;
  border: none;
  cursor: pointer;

  &:hover {
    border-bottom: 1px solid ${({ theme }) => theme.colors.tex.t200};
    margin-bottom: -1px;
  }
`;

const _Stack = styled(Stack)`
  width: 100%;
`;
