import React, { VFC } from "react";
import { useNavigate } from "react-router-dom";
import { LoadingPage } from "~/components/templates/Loading/LoadingPage";
import { MyRecordTemplate } from "~/components/templates/MyRecord";
import { TimeRange, useGetMyRecordQuery } from "~/generated/graphql";

export const MyRecord: VFC = () => {
  const { loading, error, data } = useGetMyRecordQuery({
    variables: { timeRange: TimeRange.All },
  });
  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/");
  };

  if (loading || !data) return <LoadingPage />;
  if (error) return <>error!{error.message}</>;
  return <MyRecordTemplate data={data} handleBack={handleBack} />;
};
