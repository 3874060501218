import React, { useCallback } from "react";
import { RemoveRecommendTemplate } from "~/components/templates/RemoveRecommend";
import { RemoveReason, useRemoveRecommendMutation } from "~/generated/graphql";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { ReadStatusForPostReadLog } from "~/generated/graphql";
import { makeVar, useReactiveVar } from "@apollo/client";
import { isShowGotoYlReassesmentToastVar } from "../YLReassesmentEnquete";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import { TutorialFlagKey } from "~/generated/graphql";

export const ARRIVE_CHARACTER_ASSESSMENT_KEY = "arriveCharacterAssessment";
export type EntryPageOfRemoveRecommend = "bookShelf" | "bookListViewer";
const linkRecord: Record<EntryPageOfRemoveRecommend, string> = {
  bookListViewer: "/",
  bookShelf: "/",
};

export const entryPageOfRemoveRecommendVar =
  makeVar<EntryPageOfRemoveRecommend>("bookShelf");

export const RemoveRecommend: React.FC = () => {
  const entryPage = useReactiveVar(entryPageOfRemoveRecommendVar);
  const [removeRecommend, { loading }] = useRemoveRecommendMutation();

  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const ISBN = searchParams.get("ISBN");
  const title = searchParams.get("title");

  const { recommendId } = useParams<{
    recommendId: string;
  }>();

  const navigate = useNavigate();

  const handleBack = useCallback(() => {
    navigate(-1);
  }, []);

  const { getTutorialFlag, saveTutorialFlags } =
    React.useContext(CurrentUserContext);

  const onClickCloseModal = useCallback(() => {
    saveTutorialFlags(TutorialFlagKey.FirstDeleteBook);
    navigate(linkRecord[entryPage]);
  }, [saveTutorialFlags, entryPage, navigate]);

  const onClickToCharacterAssessment = useCallback(() => {
    try {
      sessionStorage.setItem(ARRIVE_CHARACTER_ASSESSMENT_KEY, "true");
    } catch (e) {
      console.error(e);
    }
    saveTutorialFlags(TutorialFlagKey.FirstDeleteBook);
    navigate("/character_assessment/enquete");
  }, [saveTutorialFlags, navigate]);

  const handleRemoveRecommend = useCallback(
    (removeReason: RemoveReason): Promise<boolean> => {
      return new Promise((resolve) => {
        removeRecommend({
          variables: {
            recommendID: Number(recommendId),
            removeReason: removeReason,
          },
        })
          .then((res) => {
            if (res.errors || !res.data) {
              resolve(false);
            }
            if (
              removeReason === "TOO_EASY" ||
              removeReason === "TOO_DIFFICULT"
            ) {
              isShowGotoYlReassesmentToastVar(true);
            }
            resolve(true);
            if (getTutorialFlag(TutorialFlagKey.FirstDeleteBook)) {
              navigate(linkRecord[entryPage]);
            }
          })
          .catch(() => {
            resolve(false);
          });
      });
    },
    [recommendId]
  );

  React.useEffect(() => {
    if (sessionStorage.getItem(ARRIVE_CHARACTER_ASSESSMENT_KEY) === "true") {
      navigate("/");
    }
  }, [navigate]);

  if (!ISBN || !recommendId) {
    navigate(entryPage);
    return null;
  }

  const handleGotoPostReadLog = useCallback(() => {
    const searchParams = new URLSearchParams({
      recommendID: recommendId,
      title: title ? title : "",
      ISBN: ISBN,
      readStatus: ReadStatusForPostReadLog.Reading,
      flow: "confirmLike",
    });
    navigate(`/postreadlog/init?${searchParams.toString()}`);
  }, [recommendId, title, ISBN]);

  return (
    <RemoveRecommendTemplate
      handleBack={handleBack}
      handleRemoveRecommend={handleRemoveRecommend}
      handleGotoPostReadLog={handleGotoPostReadLog}
      loading={loading}
      openCharacterAssessmentFlowModal={
        !getTutorialFlag(TutorialFlagKey.FirstDeleteBook)
      }
      onClickCloseModal={onClickCloseModal}
      onClickToCharacterAssessment={onClickToCharacterAssessment}
    />
  );
};
