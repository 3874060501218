import React, { FC } from "react";
import styled, { css } from "styled-components";
import { PrimaryButton } from "../../buttons/PrimaryButton";
import { BottomModal } from "~/components/atoms/wrappers/BottomModal";
import { IconTextButton } from "../../buttons/IconTextButton";
import { TextButton } from "../../buttons/TextButton";

export type TutorialPageButton = {
  text: string;
  onClick: () => void;
};

export type BaseTutorialModalProps = {
  /**
   * ボタンのテキストとイベント
   */
  buttons: TutorialPageButton[];
  /**
   * モーダルが開いているかどうか
   */
  isOpen: boolean;
  /**
   * スキップボタンの文字列
   */
  skipText?: string;
  /**
   * スキップボタンを中央に配置するかどうか
   */
  isSkipCenter?: boolean;
  /**
   * モーダルを閉じる関数
   */
  onClose: () => void;
  /**
   * スキップの際の関数
   */
  onSkip?: () => void;
  /**
   * ヨンデミー先生の画像
   */
  imgSrc: string;
  children?: React.ReactNode;
};

export const BaseTutorialModal: FC<BaseTutorialModalProps> = ({
  buttons,
  isOpen,
  skipText,
  onClose,
  onSkip = onClose,
  isSkipCenter = false,
  imgSrc,
  children,
}) => {
  return (
    <BottomModal open={isOpen}>
      <_Wrapper>
        <BallonCon>
          <Ballon>
            <Group>{children}</Group>
            <ButtonWrapper>
              {buttons.map((el: TutorialPageButton, i: number) => (
                <PrimaryButton
                  key={i}
                  onClick={el.onClick}
                  disabled={false}
                  color={"pri"}
                  text={el.text}
                  withArrow={false}
                  fontSize={"XL"}
                  lineHeight={"EQ"}
                  bold
                  padding="16px 0"
                />
              ))}
            </ButtonWrapper>
            <_AnnotCon center={isSkipCenter}>
              {skipText &&
                (isSkipCenter ? (
                  <TextButton
                    fontSize="MD"
                    lineHeight="EQ"
                    onClick={onSkip}
                    text={skipText}
                    fontColor={"tex.t200"}
                  />
                ) : (
                  <IconTextButton
                    onClick={onSkip}
                    text={skipText}
                    name={"chevronRight"}
                    iconPlace={"sub"}
                    fontSize={"SM"}
                    lineHeight={"EQ"}
                    color={"gray"}
                  />
                ))}
            </_AnnotCon>
          </Ballon>
          <div
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
          >
            <svg
              width="17"
              height="12"
              viewBox="0 0 17 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <TrianglePath d="M11.1101 10.394C9.9609 12.4219 7.0391 12.4219 5.88993 10.394L-2.19324e-07 -6.5958e-07L17 -2.14577e-06L11.1101 10.394Z" />
            </svg>
          </div>
        </BallonCon>
        <ImgCon>
          <img
            src={imgSrc}
            alt={"ヨンデミー先生です"}
            style={{ height: "100%" }}
          />
        </ImgCon>
      </_Wrapper>
    </BottomModal>
  );
};

const _Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  row-gap: 16px;
  padding-bottom: 20px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.size.S};
`;

const _AnnotCon = styled.div<{ center?: boolean }>`
  margin-top: ${({ center, theme }) =>
    center ? theme.size.s2 : theme.size.s1half};
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: ${({ center }) => center && "center"};
`;

const Group = styled.div`
  margin-bottom: ${({ theme }) => theme.size.M};
`;

const Ballon = styled.div`
  ${({ theme }) => {
    return css`
      background-color: ${theme.colors.base.white};
      border-radius: ${theme.deprecatedBorderRadius.MD};
      padding: ${theme.size.XXL} ${theme.size.L} ${theme.size.L} ${theme.size.L};
    `;
  }}
`;

const BallonCon = styled.div`
  //レスポンシブ対応

  @media screen and ${({ theme }) => theme.breakPoints.maxWidthSmallPC} {
    width: 50vw;
    bottom: 26vh;
  }
  @media screen and ${({ theme }) => theme.breakPoints.maxWidthSmartPhone} {
    width: 70vw;
    bottom: 24vh;
  }
  @media screen and ${({ theme }) =>
      theme.breakPoints.maxWidthSmallSmartPhone} {
    width: 90vw;
    bottom: 20vh;
  }
  @media screen and ${({ theme }) => theme.breakPoints.minWidthSmallPC} {
    width: 400px;
    bottom: 28vh;
  }
`;

const TrianglePath = styled.path`
  fill: ${({ theme }) => theme.colors.base.white};
  transform: translateY(-1px);
`;

const ImgCon = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 0;
  @media screen and ${({ theme }) => theme.breakPoints.maxWidthSmallPC} {
    height: 22vh;
    bottom: 2vh;
  }
  @media screen and ${({ theme }) => theme.breakPoints.maxWidthSmartPhone} {
    height: 20vh;
    bottom: 2vh;
  }
  @media screen and ${({ theme }) =>
      theme.breakPoints.maxWidthSmallSmartPhone} {
    height: 16vh;
    bottom: 2vh;
  }
  @media screen and ${({ theme }) => theme.breakPoints.minWidthSmallPC} {
    height: 24vh;
    bottom: 2vh;
  }
`;
