import React from "react";
import styled from "styled-components";

export type MaintenanceTemplateProps = {
  endTime: string;
};

export const MaintenanceTemplate: React.FC<MaintenanceTemplateProps> = ({
  endTime,
}) => {
  return (
    <_Wrapper>
      <_Container>
        <_ContentWrapper>
          <_MessageWrapper>
            <_Title>
              {endTime ? `${endTime}まで` : "現在"}
              <br />
              ヨンデミー先生はお休み中です
            </_Title>
            <_GrayLine />
            <_Message>アプリのメンテナンスをしています</_Message>
          </_MessageWrapper>
          <img src="./img/sensei/sleeping.svg" />
          <_Inquires>
            ご不明点は
            <br />
            cs@yondemy.com
            <br />
            までお問い合わせください
          </_Inquires>
        </_ContentWrapper>
      </_Container>
    </_Wrapper>
  );
};

const _Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.base.lightGray};
  padding: 0 20px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const _Container = styled.div`
  width: 100%;
  max-width: 820px;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: ${({ theme }) => theme.colors.base.white};
  border-radius: 16px;
`;

const _ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 40px;

  background-color: ${({ theme }) => theme.colors.base.white};
  border-radius: 16px;
`;

const _MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 8px;
`;

const _Title = styled.h1`
  font-weight: 700;
  font-size: ${({ theme }) => theme.fontSize.XL};
  line-height: 160%;
  text-align: center;
  color: ${({ theme }) => theme.colors.tex.t700};
`;

const _GrayLine = styled.hr`
  width: 100%;
  border: 2px solid;
  border-color: ${({ theme }) => theme.colors.base.lightGray};
  border-radius: 3px;
  margin: 0;
`;

const _Message = styled.p`
  color: ${({ theme }) => theme.colors.tex.t400};
  font-weight: 700;
  font-size: ${({ theme }) => theme.fontSize.SM};
  line-height: 160%;
  text-align: center;
`;

const _Inquires = styled.p`
  color: ${({ theme }) => theme.colors.tex.t200};
  font-weight: 700;
  font-size: ${({ theme }) => theme.fontSize.XS};
  line-height: 170%;
  text-align: center;
`;
