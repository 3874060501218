import React from "react";
import styled from "styled-components";
import { Slider } from "~/components/atoms/forms/Slider";
import { IconButton } from "~/components/molecules/buttons/IconButton";
import { Wrapper } from "~/components/templates/Common";
import { RecommendTutorialProps } from "..";
import { TutorialStyle as s } from "../style";

export type MakeOnlineReservationProps = RecommendTutorialProps;

export const MakeOnlineReservation: React.FC<MakeOnlineReservationProps> = ({
  handleChangeStep,
  handleGoNext,
  handleGoBack,
  step,
  STEP_MAX,
}) => {
  return (
    <Wrapper>
      <SliderWrapper>
        <IconButton
          name="arrowLeft"
          fontSize="XXXL"
          onClick={handleGoBack}
          color="sec"
        />
        <Slider
          min={0}
          max={STEP_MAX}
          type="range"
          value={step}
          onChange={(event) => handleChangeStep(Number(event.target.value))}
        />
      </SliderWrapper>
      <s.ContentContainer>
        <s.ImageContainer>
          <Img src="/img/recommend_tutorial/make_online_reservation.gif" />
        </s.ImageContainer>
        <s.Stack>
          <s.WordingContainer>
            <s.Text fontSize={"XXL"} lineHeight={"MD"} bold>
              図書館の
            </s.Text>
            <s.Text fontSize={"XXL"} lineHeight={"MD"} bold>
              オンライン予約が
            </s.Text>
            <s.Text fontSize={"XXL"} lineHeight={"MD"} bold>
              すぐにできます
            </s.Text>
          </s.WordingContainer>
        </s.Stack>
      </s.ContentContainer>
      <s.BottomButton onClick={handleGoNext}>次へ</s.BottomButton>
    </Wrapper>
  );
};

const SliderWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
  padding-top: 32px;
`;

const Img = styled.img`
  height: 100%;
`;
