import React, { useContext } from "react";
import styled from "styled-components";
import {
  SecondaryFlatAnchor,
  SecondaryFlatButton,
} from "~/components/molecules/buttons/SecondaryFlatButton";
import { PinnedAlert } from "~/components/organisms/pinnedAlerts/PinnedAlert";
import { A2HSContext } from "~/contexts/A2HSContext";
import { BookmarkGuideLink } from "../../../../../../util/constant";

type ProfileBookmarkPinnedAlertProps = {
  className?: string;
};

export const ProfileBookmarkPinnedAlert: React.VFC<
  ProfileBookmarkPinnedAlertProps
> = ({ className }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const { installable, installed, installApp, standalone, supported } =
    useContext(A2HSContext);

  // ホーム画面から起動した場合はインストールの案内を表示しない
  if (standalone) {
    return null;
  }

  if (supported) {
    if (installable) {
      return (
        <div className={className}>
          <PinnedAlert
            preferenceKey={"showPinnedAlertOnMypage"}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          >
            <PinnedAlertContent>
              {installed ? (
                <Text>インストールしました</Text>
              ) : (
                <>
                  <Text>
                    スマホ・タブレットのホーム画面からワンタップでヨンデミーを開けるように設定します
                  </Text>
                  <ButtonContainer>
                    <SecondaryFlatButton
                      text={"設定する"}
                      color={"pri"}
                      disabled={false}
                      onClick={() => installApp()}
                      withArrow
                      fontSize={"MD"}
                      lineHeight={"MD"}
                    />
                  </ButtonContainer>
                </>
              )}
            </PinnedAlertContent>
          </PinnedAlert>
        </div>
      );
    } else {
      return null;
    }
  }

  return (
    <div className={className}>
      <PinnedAlert
        preferenceKey={"showPinnedAlertOnMypage"}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      >
        <PinnedAlertContent>
          <Text>
            スマホ・タブレットのホーム画面からワンタップでヨンデミーを開けるように設定します
          </Text>
          <ButtonContainer>
            <SecondaryFlatAnchor
              text={"設定する"}
              color={"pri"}
              disabled={false}
              href={BookmarkGuideLink}
              target={"_blank"}
              rel={"noreferrer noopener"}
              withArrow
              fontSize={"MD"}
              lineHeight={"MD"}
            />
          </ButtonContainer>
        </PinnedAlertContent>
      </PinnedAlert>
    </div>
  );
};

const PinnedAlertContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const Text = styled.p`
  margin-bottom: 11px;
  line-height: 1.5;
`;

const ButtonContainer = styled.div`
  width: 100%;
  max-width: 140px;
`;
