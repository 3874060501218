type UserProperty = {
  logged_in_at: string;
  user_id: string;
};

export const setUserProperties = (
  userProperty: Partial<UserProperty>
): void => {
  if (process.env.NODE_ENV === "development") {
    console.log("[GA] setUserProperty", userProperty);
  }

  window.gtag("set", "user_properties", userProperty);
};

export const clearUserProperties = (): void => {
  setUserProperties({ logged_in_at: "", user_id: "" });
};
