import React from "react";
import styled, { css } from "styled-components";
import { Interpolation } from "~/styles/interpolation";
import { useSnd } from "~/utils/hooks/useSnd";
import { MiniLessonContext } from "~/contexts/MiniLessonContext";
import { defaultAdminMessageRecord } from "../AdminMessageText";

export type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

export const AdminMessageInput: React.FC<InputProps> = (props) => {
  const { onChange, ...inputProps } = props;
  const { play } = useSnd();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event);
    play("TYPE");
  };

  const { fontSize } = React.useContext(MiniLessonContext);

  return (
    <Wrapper>
      <StyledInput
        {...inputProps}
        onChange={handleChange}
        fontSize={defaultAdminMessageRecord.input.fontSize[fontSize]}
      />
    </Wrapper>
  );
};

const StyledInput = styled.input<{ fontSize: string }>`
  ${({ theme, fontSize }) => {
    const { colors, deprecatedBorderRadius: borderRadius, lineHeight } = theme;
    return css`
      padding: 16px;
      font-size: ${fontSize};
      line-height: ${lineHeight.EQ};
      border-radius: ${borderRadius.MD};
      color: ${colors.tex.t800};
      width: inherit;
      ${Interpolation.Form}
      &:active, &:focus {
        outline: none;
      }
      &::placeholder {
        color: ${colors.tex.t200};
      }
    `;
  }}
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.size.S};
  padding: 0;
`;
