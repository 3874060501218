import { FontType } from "./ReadingViewer";
import { PassageYL, Size } from "./types";

export const sentenceOrders = ["first", "second"] as const;
export type SentenceOrder = (typeof sentenceOrders)[number];

export type YLAndSentenceOrder = {
  passageYL: PassageYL;
  sentenceOrder: SentenceOrder;
};

type SentenceAndCaption = {
  text: string;
  caption: string;
};

export type PassageContent = {
  textAndCaption: Record<SentenceOrder, SentenceAndCaption>;
  size: Size;
  characterSrc: string;
  fontType: FontType;
  lineHeight: string;
  letterSpacing: string;
};

type CharacterList = "apple" | "penguin" | "bookWarm" | "lion";
const characterSrcList: Record<CharacterList, string> = {
  apple: "/img/yl_reassesment_enquete/apple.png",
  penguin: "/img/yl_reassesment_enquete/penguin.png",
  bookWarm: "/img/yl_reassesment_enquete/bookWarm.png",
  lion: "/img/yl_reassesment_enquete/lion.png",
};

/* eslint-disable no-irregular-whitespace */
export const passageDict: Record<PassageYL, PassageContent> = {
  15: {
    textAndCaption: {
      first: {
        text: `そこで、かあさんぐまは、
くまくんに、いいものを
こしらえてやりました。
「ほうら、いいものが
できましたよ。
かぶってごらん。」`,
        caption: "E・H・ミナリック『こぐまのくまくん』",
      },
      second: {
        text: `おだんごぱんは、じいっと
じいっと しているうちに、
さびしくなってきて、
ついつい ころがりだしました。
まどから ころんと、
いすの うえ。
いすから ころんと、
ゆかの うえ。`,
        caption: "せた　ていじ『おだんごぱん』",
      },
    },
    size: "large",
    characterSrc: characterSrcList["bookWarm"],
    fontType: "Kyokasho",
    lineHeight: "38px",
    letterSpacing: "0.2em",
  },
  20: {
    // start yl check with this passage
    textAndCaption: {
      first: {
        text: `パパが　アイスの　ふくろを
うらがえしてみると、そこには
「アイスおうこく」と、
かいてありました。
そして、『ききたいことや
ごしつもんの　ある　ひとは、
おうちの　れいとうこの
ひだりおくを　3かい　ノック
して、いりぐちから　
おはいりください』
と、せつめいが　あります。`,
        caption: "きたじま　ごうき『あたりかも』",
      },
      second: {
        text: `「あげないよ。だって この
ひこうきは、ぼくの たから
ものだもの」と、ゆうじが
 いうと、きつねは、
「それじゃあ、ぼくの
たからものと、とりかえて」と、
ぽけっとから そらいろのたねを
ひとつ だしました。
ゆうじは ひこうきと たねを
とりかえました。`,
        caption: "なかがわ　りえこ『そらいろのたね』",
      },
    },
    size: "medium",
    characterSrc: characterSrcList["penguin"],
    fontType: "Kyokasho",
    lineHeight: "34px",
    letterSpacing: "0.1em",
  },
  25: {
    textAndCaption: {
      first: {
        text: `「やれやれ、あれは きつねの子
だったのかい、とんと
わからなかったよ。そういえば
みたことのない子だったなぁ。」
先生は めがねを ふきふきいいました。
そして、コッペパンを 次郎の
手にのせて いいました。
「これをな、きつねの子に
もっていって おやり。」
次郎と たかしは うれしくて 
たまりません。コンがパンをみたら、
どんなによろこぶでしょう。あんまり
うれしくて、ふたりは おおごえで
うたいながら、はしっていきました。`,
        caption: "松谷みよ子『ジャムねこさん』",
      },
      second: {
        text: `つぎの日、ラルフは セイラのパーティを
めちゃめちゃにしました。
テーブルにでている クッキーを、
どれも ひと口ずつ かじって、
あじみをしたのです。
「ラルフ、ときどき あんたを
かわいいと おもえなくなるわ」と、
セイラは いいました。
ある日、セイラのおとうさんが
いつもより はやく しごとから
かえってきてみると、ラルフは
おとうさんの スリッパをはき、
おとうさんの いすに こしかけ、
おとうさんが いちばんだいじにしている
パイプで、しゃぼん玉をふいていました。`,
        caption: `ジャック・ガントス『あくたれラルフ』`,
      },
    },
    size: "medium",
    characterSrc: characterSrcList["lion"],
    fontType: "Kyokasho",
    lineHeight: "160%",
    letterSpacing: "0.075em",
  },
  30: {
    textAndCaption: {
      first: {
        text: `ヤギのヤギマロ先生は、
山のてっぺんにひとりでくらしている、
お習字の先生です。
村の動物たちからすると、先生は、
まるでえらくて、ちょっとこわいかんじ
のするひとなのでした。
そんなわけで、お習字を習いに、
先生の家に行く生徒たちは、みな、
きんちょうして、げんかんを入ります。
先生の家は、古いけれど、
きちんと手入れがしてあり、
ろうかなんか、黒く、ぴかぴかです。
そこをしずしず通って、板の間の教室に
入ると、長いつくえが三つあるので、
きまったつくえにつき、
すみやかに練習をするのです。`,
        caption: "たかどの　ほうこ『へろりのだいふく』",
      },
      second: {
        text: `毎日、夕やみがせまると、
ねずみの だんなさんやとうさんたちは、
パリへつうじる大通りを
自転車ではしります。家族のために
たべものを さがしにいくのです。
パリにつくと、ねずみたちは、
ねずみたちだけが知っている
ひみつの通路をとおって、
人間の家にはいりこみます。
アナトールは、たいてい ガストンと
いっしょです。
ある夜、ふたりが、とある家の台所で、
のこりものを さがしていると、
となりの部屋から 声がきこえました。`,
        caption: "イブ・タイタス『ねずみのとうさんアナトール』",
      },
    },
    size: "medium",
    characterSrc: characterSrcList["apple"],
    fontType: "Kyokasho",
    lineHeight: "160%",
    letterSpacing: "0.06em",
  },
  35: {
    textAndCaption: {
      first: {
        text: `トムは、だれの飼いネコでもありません
でした。だから、さむい夜に暖炉のまえで
ねたり、ミルクをお皿でもらうようなこと
は、いちどだってありませんでした。
トムは、（だれかぼくのことを飼ってくれるひとがいたら、もうさむい夜にそとでねたりしなくてすむのに）と思いました。
「よい飼い主をみつけることが、ネコにとっていちばんたいせつなことなのよ。
そうすれば、ひもじい思いや、さむい思いをしなくてすむのだから」
母親は、トムがまだオッパイをのんでいるころから、そういっていました。
トムはいま、ほんとうにそうなんだなと思いました。
トムは、二匹の兄弟たちと、港の倉庫で生まれました。
お兄さんはとても大きくて、つよくて、
トラのようにりっぱでした。`,
        caption: "南部和也『ネコのタクシー』",
      },
      second: {
        text: `ご主人に教えられたとおりに、ライオネルはダンスタンの森を進んでいきました。
小枝一本折る音もたてないで、かるがるとぴょんぴょんとびながら進んでいきました。木々の中でこそこそっと音がするたびに、何かがちらりとでも見えたらすぐにとびつけるように、さっと首をそっちへ向けました。
一羽のふとったウズラが目の前の道をちょろちょろっと横切りました。ライオネルは目をひからせ、鼻をひょとつかせました。けれども、四つんばいになって追いかけるのだけはじっとがまんしました。
ライオネルは道をいそぎ、午前もなかばをすぎたころ、森のはずれにたどりつきました。
ライオネルは、ブライトフォード街道にちがいない、幅がひろくてかたくふみかためた地面をどんどん走っていきました。`,
        caption: "ロイド・アリグザンダー『人間になりたがった猫』",
      },
    },
    size: "medium",
    characterSrc: characterSrcList["bookWarm"],
    fontType: "Kyokasho",
    lineHeight: "155%",
    letterSpacing: "0.03em",
  },
  40: {
    textAndCaption: {
      first: {
        text: `と、そのとたん、ぎょっとした。マジヒコ氏の席に、白い服を着た人が背中を向けて、プカンと浮いたような感じですわっていたのだ。マジヒコ氏は、びっくりしたあとはたいへん不愉快なきもちになった。こういうずうずうしい悪ふざけは、マジヒコ氏のとりわけきらいなことだった。
そこで、せきばらいしてからいった。
「オホン！ いったい、どなたですかな。人の家にあがりこみ、わたしの席にかってにすわっておられるのは」
その人はびくっとひとつうごくと、ゆっくりふりむいた。その顔を見たとき、マジヒコ氏はまたまたどきっとして、手に持ったお盆をおとしそうになった。なぜなら、その顔は、自分にそっくりだったからだ。その人は、ひゅうひゅうしたような声でいった。
「わたしは、あなたのオバケですぞう〜」
このひとことで、マジヒコ氏は
かんぜんによゆうをなくした。`,
        caption: "たかどの　ほうこ『紳士とオバケ氏』",
      },
      second: {
        text: `ウォートンとサイは、二列にずらりとならんだネズミたちの先頭に立ち、ストックを押しました。百ぴきのシロアシネズミと一ぴきのヒキガエルは、谷間の木々のあいだを、リボンが流れるように、すいすいすべっていきました。
森の動物たちは、一行が通りすぎるのを、びっくりしてながめていました。 ウサギもリスも小鳥たちも、口をぽかんとあけ、目をまんまるくしました。みんな、こんな変わった行列を見るのは、はじめてでした。
いきおいよくスキーをすべらせながら、ウォートンが言いました。「こんなにたくさん弟がいるなんて、思ってもみなかったよ、サイ」
「これでぜんぶじゃないんだ」と、サイが言いました。「事故があってね」「事故?」ウォートンが聞きました。
「きのう、はじめてスキーに乗ってみた んだ。そしたら、十四ひきがしょうとつしちゃって」と、サイは言いました。`,
        caption: "ラッセル・E・エリクソン『火曜日のごちそうはヒキガエル』",
      },
    },
    size: "medium",
    characterSrc: characterSrcList["penguin"],
    fontType: "Kyokasho",
    lineHeight: "155%",
    letterSpacing: "0.02em",
  },
  45: {
    textAndCaption: {
      first: {
        text: `もしパトカーのサイレンがあれほどけたたましくなかったら、おそらく切手偽造犯は、セメントミキサーのなかにずっとかくれていたにちがいありません。
しかしくろて団が目の当たりに見た光景は、しまのネクタイのミスターXが、金属製のアタッシュケースをしっかりさげたままミキサーからとびだし、水平飛行で壁をこえ、そのあと消えたというものでした。
「あいつ、ぜったいにけがしたわよ！」
と、アデーレがさけびました。
ところが、ミスターXはまったくけがをしませんでした。堆肥の山の上に胴体着陸したからです。
「あいつ、ぜったいにけがしたな。」
「なんてすさまじいにおいだろ!」
と、クルツバッハ巡査部長は、三台のパトカー警官隊の先頭に立って、その農家にかけつけるなり、さけびました。
それから巡査部長は、ミスターXを
逮捕しました。
ミスターXがこれに抗議していいました。`,
        caption: "ハンス・ユルゲン・ブレス『くろて団は名探偵』",
      },
      second: {
        text: `学校で、創也と話すことは少ない。創也は図書室にいることが多いし、教室にいてもひとりで本を読んでいる。そして、ぼくはぼくで、達夫たちとテレビやマンガの話。
で、砦にいるときの創也はどうかというと、基本的に学校にいるときと同じ。
創也から話しかけてくることは、ほとんどない。たいてい、コンピュータにむかってカタカタとキーボードをたたいてるか、ソファーにすわって本を読んでいる。
だからぼくは、かってに本を読んだり、持ってきたCDをかけたりして時間をすごす。
その日、創也はソファーにすわって本を読んでいた。
ぼくも、もう一つのソファーに寝ころんで本をひらいた。だけど、すぐに持ってきた本にあきたので、かべぎわに積まれている雑誌やマンガに手をのばした。
そのとき、ふと気になって、ソファーにすわってる創也にきいた。
「創也は、本を読むとき、いつもきちんとすわってるの?」`,
        caption: "はやみねかおる『都会のトムソーヤ』",
      },
    },
    size: "medium",
    characterSrc: characterSrcList["lion"],
    fontType: "Mincho",
    lineHeight: "149%",
    letterSpacing: "auto",
  },
  50: {
    // 今はYL50の文章には到達しないが，YL判定の文章の表示順について検討中なため残しておく
    textAndCaption: {
      first: {
        text: `夜明け、メイ・タックが馬にのってトゥリーギャップ村のはずれにある森にむかった。息子のマイルズとジェシィに会うために、十年に一回だけそうしている。
正午、その森の持ち主であるフォスター家のひとり娘ウィニー・フォスターは、とうとうがまんできなくて家出を考えることにした。
暮れがた、ひとりの旅人がフォスター家の門にあらわれた。だれかをさがしていたが、その相手についてはなにも語らなかった。
この三つの出来事には、なんのつながりもない、あなたもそう思うでしょう。けれど、出来事はふしぎな方法でピタリと結びつくことがある。
この三つをつないだのは森である。車輪でいえば、森はその軸のようなはたらきをしていた。まわる車輪にはみんな軸がある。月日が太陽を軸にしてまわっているように、観覧車だって軸をもっているからまわる。だから、軸はぐらぐらうごいてはならないし、いちばんいい状態で、そっとしておかれなければならない。そうでなかったら、すべてはバラバラになってしまう。`,
        caption: "ナタリー・バビット『時をさまようタック』",
      },
      second: {
        text: `「だけど悟、すぐにもどってくるんだぞ。二分間以内だ。いいな。」
悟はふりむいてにっこりわらうと、
「はい。」
元気な声で返事をした。
体育館と校舎は、二十メートルほどのアスファルトの道でむすばれていた。両側にはちょっとした木立がある。だが、体育館から保健室へいくには、運動場をななめによこぎるほうがはやかった。
アスファルトの道をそれた悟は、とげぬきをかるく投げあげては受けとめながら、木立を通りぬけ、秋の日ざしがあかるいだれもいない運動場へ出ようとした。そのとき、ふいに声がきこえた。
―おまえ、いいものもってるじゃないか。
衙はぎくりとした。とつぜんの声ということ以上に、そのきこえかたにおどろいた。ひしゃげた男の声は、耳からではなく、直接頭のなかでひびいた感じだった。
あたりを見まわしても、だれもいなかった。ただ、いっぴきの黒ネコが、クスノキの根もとにひくくうずくまり、まっすぐ悟を見あげていた。`,
        caption: "岡田 淳『二分間の冒険』",
      },
    },
    size: "medium",
    characterSrc: characterSrcList["apple"],
    fontType: "Mincho",
    lineHeight: "150%",
    letterSpacing: "auto",
  },
};
/* eslint-enable no-irregular-whitespace */
