import React, { useState } from "react";
import styled from "styled-components";
import { IconButton } from "~/components/molecules/buttons/IconButton";
import { Wrapper } from "../Common";
import { Heading1 } from "~/components/atoms/texts/Heading1";
import { Stack } from "~/components/atoms/layout/Stack";
import { RemoveReason } from "~/generated/graphql";
import { EmojiButton } from "~/components/molecules/buttons/EmojiButton";
import { Icon } from "~/components/atoms/Icon";
import { Text } from "~/components/atoms/texts/Text";
import { ConfirmRemoveRecommendModal } from "./ConfirmRemoveRecommendModal";
import { ErrorMessage } from "~/components/molecules/texts/ErrorMessage";
import { useTranslation } from "~/i18n";
import { SecondaryButton } from "~/components/molecules/buttons/SecondaryButton";
import { CharacterAssessmentFlowModal } from "~/components/organisms/modals/CharacterAssessmentFlowModal";

export type RemoveRecommendTemplateProps = {
  handleBack: (e: React.MouseEvent) => void;
  handleRemoveRecommend: (removeReason: RemoveReason) => Promise<boolean>;
  handleGotoPostReadLog: () => void;
  loading: boolean;
  openCharacterAssessmentFlowModal: boolean;
  onClickCloseModal: () => void;
  onClickToCharacterAssessment: () => void;
};

type ButtonFeatures = {
  emoji: string;
  text: string;
};

const buttonFeatureRecord: Record<RemoveReason, ButtonFeatures> = {
  TOO_DIFFICULT: { emoji: "😣", text: "むずかしそうだから" },
  TOO_EASY: { emoji: "😗", text: "かんたんそうだから" },
  NOT_INTERESTING: { emoji: "😒", text: "おもしろくなさそうだから" },
  CANNOT_GET: { emoji: "😔", text: "てにはいらなかったから" },
  HAVE_READ: { emoji: "😏", text: "よんだことあるから" },
};

export const RemoveRecommendTemplate: React.FC<
  RemoveRecommendTemplateProps
> = ({
  handleBack,
  handleRemoveRecommend,
  handleGotoPostReadLog,
  loading,
  openCharacterAssessmentFlowModal,
  onClickCloseModal,
  onClickToCharacterAssessment,
}) => {
  const { t } = useTranslation();

  type modalFlow = "confirmDelete" | "characterAssessmentFlow";
  const [flow, setFlow] = useState<modalFlow>("confirmDelete");

  const [removeReason, setRemoveReason] = useState<RemoveReason | undefined>(
    undefined
  );

  const [isErrorMessageDisplayed, setIsErrorMessageDisplayed] =
    useState<boolean>(false);

  const onClickReturn = (e: React.MouseEvent) => {
    e.preventDefault();
    setRemoveReason(undefined);
  };

  return (
    <>
      <_NavigationWrapper>
        <IconButton
          name={"arrowLeft"}
          onClick={handleBack}
          color={"sec"}
          fontSize={"XXXL"}
        />
      </_NavigationWrapper>
      <Wrapper>
        <_StackWrapper rowGap="32px">
          <_RemoveRecommendBodyWrapper>
            <_Heading fontSize={"LG"} lineHeight={"MD"} bold>
              {t<TranslateKeys>("削除する理由を")}
              <br />
              {t<TranslateKeys>("教えてください")}
            </_Heading>
          </_RemoveRecommendBodyWrapper>
          <_ButtonContainer rowGap="16px">
            {Object.keys(buttonFeatureRecord).map(
              (reason: RemoveReason, index) => (
                <EmojiButton
                  key={index}
                  fontSize="LG"
                  lineHeight="MD"
                  onClick={(e: React.MouseEvent) => {
                    e.preventDefault();
                    setRemoveReason(reason);
                    setIsErrorMessageDisplayed(false);
                  }}
                  color="pri"
                  emoji={buttonFeatureRecord[reason].emoji}
                  text={buttonFeatureRecord[reason].text}
                  bold
                  disabled={loading || flow == "characterAssessmentFlow"}
                />
              )
            )}
          </_ButtonContainer>
          <_PostReadLogCardWrapper rowGap="12px">
            <_QuestionIconTextWrapper>
              <Icon
                name="question"
                color="tex"
                style={{ width: `24px`, height: `24px` }}
              />
              <Text color="tex.t700" fontSize="SM" lineHeight="EQ">
                {t<TranslateKeys>("途中まで読んだときは")}
              </Text>
            </_QuestionIconTextWrapper>
            <SecondaryButton
              color="lightGray"
              onClick={handleGotoPostReadLog}
              text={t<TranslateKeys>("まずは感想を送る")}
              disabled={false}
              withArrow={true}
              fontSize="MD"
              lineHeight="EQ"
              fontColor="pri.p400"
              bold
            />
          </_PostReadLogCardWrapper>
          {isErrorMessageDisplayed && (
            <ErrorMessage>
              {t<TranslateKeys>(
                "本を削除できなかったよ！もう一度試してみてね！"
              )}
            </ErrorMessage>
          )}
        </_StackWrapper>
      </Wrapper>
      {removeReason && flow == "confirmDelete" && (
        <ConfirmRemoveRecommendModal
          onClickRemove={(e: React.MouseEvent) => {
            e.preventDefault();
            handleRemoveRecommend(removeReason).then((res) => {
              if (!res) {
                setRemoveReason(undefined);
                setIsErrorMessageDisplayed(true);
              }
            });
            setFlow("characterAssessmentFlow");
          }}
          onClickReturn={onClickReturn}
          isOpen={!!removeReason}
          disabled={loading}
        />
      )}
      {openCharacterAssessmentFlowModal &&
        flow == "characterAssessmentFlow" && (
          <CharacterAssessmentFlowModal
            sentence={"本を消したよ\n"}
            onClickCloseModal={onClickCloseModal}
            onClickToCharacterAssessment={onClickToCharacterAssessment}
          ></CharacterAssessmentFlowModal>
        )}
    </>
  );
};

const _NavigationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  left: 5%;
  top: 20px;
  background-color: ${({ theme }) => theme.colors.base.ashGray};
`;

const _StackWrapper = styled(Stack)`
  align-items: center;
`;
export const _RemoveRecommendBodyWrapper = styled.div`
  padding-top: 60px;
`;

const _Heading = styled(Heading1)`
  color: ${({ theme }) => theme.colors.tex.t700};
  text-align: center;
`;

const _ButtonContainer = styled(Stack)`
  width: 100%;

  align-items: center;
`;

const _PostReadLogCardWrapper = styled(Stack)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 12px;

  border: 2px dashed ${({ theme }) => theme.colors.base.gray};
  border-radius: ${({ theme }) => theme.deprecatedBorderRadius.MD};
`;

const _QuestionIconTextWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 4px;
`;
