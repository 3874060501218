import React from "react";
import styled from "styled-components";
import { LargeButton } from "~/components/atoms/buttons/Button";
import { Text } from "~/components/atoms/texts/Text";
import { useTranslation } from "react-i18next";

export type SelectCoverPageProps = {
  onClicks: ((event: React.MouseEvent) => void)[];
  imageUrl?: string;
  title?: string;
};

export const SelectCoverPage: React.VFC<SelectCoverPageProps> = ({
  onClicks,
  imageUrl,
  title,
}) => {
  const { t } = useTranslation<TranslateKeys>();
  return (
    <_Wrapper>
      <_BookWrapper>
        <_BookImg src={imageUrl} alt={title} />
      </_BookWrapper>
      <_ButtonWrapper>
        <_Button onClick={onClicks[0]} color="primary">
          <Text
            fontSize="XL"
            lineHeight="EQ"
            fontColor="semantic.layout.backgroundBox"
            bold
          >
            {t<TranslateKeys>("😆 読みたい！")}
          </Text>
        </_Button>
        <_Button onClick={onClicks[1]} color="primary">
          <Text
            fontSize="XL"
            lineHeight="EQ"
            fontColor="semantic.layout.backgroundBox"
            bold
          >
            {t<TranslateKeys>("🙂 読んでもいいよ")}
          </Text>
        </_Button>
        <_Button onClick={onClicks[2]} color="primary">
          <Text
            fontSize="XL"
            lineHeight="EQ"
            fontColor="semantic.layout.backgroundBox"
            bold
          >
            {t<TranslateKeys>("🤨 びみょうかなぁ")}
          </Text>
        </_Button>
      </_ButtonWrapper>
    </_Wrapper>
  );
};

const _Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  @supports (height: 100dvh) {
    height: 100dvh;
  }
  background-color: ${({ theme }) =>
    theme.colors.semantic.layout.backgroundMain};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  overflow: auto;
`;

const _BookWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 235px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 42px 20px;
  @media screen and (max-height: 667px) {
    padding: 12px 55px;
  }
  @media screen and (min-width: ${({ theme }) =>
      theme.screenSize.smartPhoneMax}) {
    height: calc(100dvh - 263px);
  }
  @supports (height: 100dvh) {
    height: calc(100dvh - 235px);
    @media screen and (min-width: ${({ theme }) =>
        theme.screenSize.smartPhoneMax}) {
      height: calc(100dvh - 263px);
    }
  }
`;

const _BookImg = styled.img`
  width: 100%;
  height: 100%;
  max-width: 560px;
  max-height: 560px;
  object-fit: contain;
`;

const _ButtonWrapper = styled.div`
  padding: 16px 20px;
  border-radius: 16px 16px 0px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  align-items: center;
  background-color: ${({ theme }) =>
    theme.colors.semantic.layout.backgroundBox};

  @media screen and (min-width: ${({ theme }) =>
      theme.screenSize.smartPhoneMax}) {
    padding: 16px 20px 48px 20px;
  }
`;

const _Button = styled(LargeButton)`
  height: 57px;

  &:active {
    margin-top: 0px;
  }
`;
