import React from "react";
import {
  ReactiveHistoryAllFragment,
  TalkItemSpeaker,
} from "~/generated/graphql";
import { RemarkMessage } from "../../messageListItems/RemarkMessage";
import styled from "styled-components";

export type AdminRemarksProps = {
  id: number;
  item: ReactiveHistoryAllFragment;
  className?: string;
  showAll: boolean;
  incrementCurrentIndex: () => void;
  speaker: TalkItemSpeaker;
};

export const AdminRemarks: React.VFC<AdminRemarksProps> = ({
  id,
  item,
  className,
  showAll,
  incrementCurrentIndex,
  speaker,
}) => {
  const { message, response, sentTime, responseTime } = item;
  const messages = (message || []).map((text) => {
    return { text, time: new Date(sentTime) };
  });
  const responses = (response || []).map((text) => {
    return { text, time: new Date(responseTime) };
  });

  return (
    <AdminRemarkListItem className={className}>
      {messages.map((item, index) => {
        return (
          <RemarkMessage
            id={id}
            key={index}
            item={item}
            userType="admin"
            showAll={showAll}
            incrementCurrentIndex={incrementCurrentIndex}
            speaker={speaker}
          />
        );
      })}
      {(responses || []).map((item, index) => {
        return (
          <RemarkMessage
            id={id}
            key={index}
            item={item}
            userType="admin"
            showAll={showAll}
            incrementCurrentIndex={incrementCurrentIndex}
            speaker={speaker}
          />
        );
      })}
    </AdminRemarkListItem>
  );
};

const AdminRemarkListItem = styled.div``;
