import React, { useMemo } from "react";
import styled from "styled-components";
import { Text } from "~/components/atoms/texts/Text";
import {
  ListItemProps,
  MessageList,
} from "~/components/molecules/lists/MessageList";
import { AdminMessageListItem } from "../AdminMessageListItem";
import { TalkAllFragment, TalkCacheAllFragment } from "~/generated/graphql";
import { flattenItems } from "./flattenItems";

export type AdminMessageListProps = {
  data: TalkCacheAllFragment;
  myId: number;
  fetchAll: boolean;
  onPrevLoading: (item: TalkAllFragment) => Promise<void>;
  className?: string;
  currentIndex: number;
  incrementCurrentIndex: () => void;
  firstItemIndex: number;
  setIsScrolling: ((isScrolling: boolean) => void) | undefined;
};

export type TalkItem = TalkAllFragment & { firstUnreadItem?: boolean };

export const AdminMessageList: React.FC<AdminMessageListProps> = ({
  data,
  myId,
  fetchAll,
  onPrevLoading,
  className,
  currentIndex,
  incrementCurrentIndex,
  firstItemIndex,
  setIsScrolling,
}) => {
  const items: TalkItem[] = useMemo(() => {
    const unreadTalks = data?.unreadTalks || [];
    const oldTalks = data?.oldTalks || [];
    const _items = flattenItems(
      unreadTalks.length === 0
        ? [...(oldTalks || []), ...unreadTalks]
        : [
            ...(oldTalks || []),
            { ...unreadTalks[0], firstUnreadItem: true },
            ...unreadTalks.slice(1),
          ]
    );

    return _items.filter(
      (_, index) => index + firstItemIndex <= currentIndex
    ) as TalkItem[];
  }, [data, currentIndex, firstItemIndex]);

  return (
    <MessageList
      className={className}
      items={items}
      fetchAll={fetchAll}
      onPrevLoading={onPrevLoading}
      firstItemIndex={firstItemIndex}
      isScrolling={setIsScrolling}
      renderListItem={({ item, index }: ListItemProps<TalkItem>) => {
        return (
          <>
            {item.firstUnreadItem && (
              <UnReadBorderText fontSize={"SM"} lineHeight={"EQ"}>
                未読のメッセージ
              </UnReadBorderText>
            )}
            <_MessageListItemWrapper>
              <AdminMessageListItem
                key={item.id}
                myId={myId}
                talk={item}
                index={index}
                currentIndex={currentIndex}
                incrementCurrentIndex={() => {
                  setTimeout(() => {
                    incrementCurrentIndex();
                  }, 10);
                }}
              />
            </_MessageListItemWrapper>
          </>
        );
      }}
    />
  );
};

const UnReadBorderText = styled(Text)`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.pri.p400};
  margin: 20px 10px;
  &::before,
  ::after {
    border-top: 1.5px solid ${(props) => props.theme.colors.pri.p400};
    content: "";
    display: inline;
    flex-grow: 1;
  }
  &::before {
    margin: 0 0.5em;
  }
  &::after {
    margin: 0 0.5em;
  }
`;

const _MessageListItemWrapper = styled.div`
  padding: 5px 20px;
  @media screen and (min-width: 600px) {
    padding: 5px 60px;
  }
`;
