import * as React from "react";
import { ProgressNavigation } from "~/components/organisms/ProgressNavigation";
import { NavContainer, Wrapper } from "~/components/templates/Common";
import { PostReadLogBarWrapper } from "../Common/style";
import { CharacterAssessmentFlowModal } from "~/components/organisms/modals/CharacterAssessmentFlowModal";
type Props = {
  modalProps: Parameters<typeof CharacterAssessmentFlowModal>[0];
};

export const FirstDislikeBookModalTemplate: React.FC<Props> = ({
  modalProps,
}) => {
  return (
    <>
      <NavContainer>
        <PostReadLogBarWrapper>
          <ProgressNavigation
            progress={100}
            handleBack={() => {
              return;
            }}
            buttonsPattern={"single"}
          />
        </PostReadLogBarWrapper>
      </NavContainer>
      <Wrapper>
        <CharacterAssessmentFlowModal {...modalProps} />
      </Wrapper>
    </>
  );
};
