import { TranslateKeys } from "~/i18n";
import { sentences } from "./const";

type getNewPraiseSentenceReturnType = TranslateKeys | undefined;

export const getNewPraiseSentence = (
  oldContinueReadLogDays: number | undefined,
  newContinueReadLogDays: number
): getNewPraiseSentenceReturnType => {
  if (oldContinueReadLogDays === undefined) {
    const newSentence = getSentenceFromReadLogDays(newContinueReadLogDays);
    return newSentence;
  } else {
    const newSentence = getSentenceFromReadLogDays(newContinueReadLogDays);
    const oldSentence = getSentenceFromReadLogDays(oldContinueReadLogDays);
    const diff = newSentence !== oldSentence;
    return diff ? newSentence : undefined;
  }
};

const getSentenceFromReadLogDays = (
  continueReadLogDays: number
): TranslateKeys | undefined => {
  return continueReadLogDays in sentences
    ? sentences[continueReadLogDays]
    : undefined;
};
