import * as React from "react";

import {
  Auth0IDPasswordLoginConnectionRealm,
  auth0RedirectUri,
  auth0WebAuth,
} from "~/utils/auth0";
import { Auth0Error } from "auth0-js";
import { handleLogoutForAnalytics } from "~/utils/googleAnalytics/authentication";
import { makeVar } from "@apollo/client";

const stub = (): never => {
  throw new Error("You forgot to wrap your component in <Auth0Provider>.");
};

type Auth0ContextValue = {
  isAuthenticated: boolean;
  isLoading: boolean;
  getIdToken: () => Promise<IdToken | null>;
  getAccessToken: () => Promise<AccessToken | null>;
  changePassword: (email: string) => Promise<boolean>;
  logout: () => void;
  login: (
    email: string,
    password: string,
    onLoginError?: (err: Auth0Error) => void
  ) => ReturnType<typeof auth0WebAuth.login>;
};

const initialContext: Auth0ContextValue = {
  isAuthenticated: false,
  isLoading: true,
  getIdToken: stub,
  getAccessToken: stub,
  changePassword: stub,
  logout: stub,
  login: stub,
};

export const Auth0Context =
  React.createContext<Auth0ContextValue>(initialContext);

type Auth0ProviderProps = { children: React.ReactNode };

type IdToken = {
  token: string;
  cacheExpireAt: Date;
  email: string;
};

type AccessToken = {
  token: string;
  cacheExpireAt: Date;
};

type CheckSessionResult = {
  idToken: string;
  idTokenPayload: {
    exp: number;
    iat: number;
    email: string;
  };
  accessToken: string;
  expiresIn: 7200;
};

const parseIdToken = (res: CheckSessionResult): IdToken => {
  const cacheLifetime = res.idTokenPayload.exp - res.idTokenPayload.iat; // トークン有効期間（秒）＝キャッシュの有効期間
  const cacheExpireAt = new Date();
  cacheExpireAt.setSeconds(new Date().getSeconds() + cacheLifetime);
  console.log("IdToken cache expired at", cacheExpireAt);

  return { token: res.idToken, cacheExpireAt, email: res.idTokenPayload.email };
};

const parseAccessToken = (res: CheckSessionResult): AccessToken => {
  const cacheExpireAt = new Date();
  cacheExpireAt.setSeconds(new Date().getSeconds() + res.expiresIn);
  console.log("AccessToken cache expired at", cacheExpireAt);

  return { token: res.accessToken, cacheExpireAt };
};

const idTokenVar = makeVar<IdToken | null>(null);

const accessTokenVar = makeVar<AccessToken | null>(null);

export const getAccessToken = (): Promise<AccessToken | null> => {
  return new Promise((resolve) => {
    const accessToken = accessTokenVar();

    // キャッシュの有効期限内はキャッシュされているトークンを返す
    const now = new Date();
    if (accessToken && accessToken.cacheExpireAt > now) {
      resolve(accessToken);
      return;
    }

    console.log("token expired");
    auth0WebAuth.checkSession({}, (err, res) => {
      if (res) {
        const _accessToken = parseAccessToken(res);
        accessTokenVar(_accessToken);
        resolve(_accessToken);
      } else if (err) {
        resolve(null);
      }
    });
  });
};

const getIdToken = (): Promise<IdToken | null> => {
  return new Promise((resolve) => {
    // キャッシュの有効期限内はキャッシュされているトークンを返す
    const now = new Date();
    const idToken = idTokenVar();
    if (idToken && idToken.cacheExpireAt > now) {
      resolve(idToken);
      return;
    }

    console.log("token expired");
    auth0WebAuth.checkSession({}, (err, res) => {
      if (res) {
        const _idToken = parseIdToken(res);
        idTokenVar(_idToken);
        resolve(_idToken);
      } else if (err) {
        resolve(null);
      }
    });
  });
};

const changePassword = (email: string): Promise<boolean> => {
  return new Promise((resolve) => {
    auth0WebAuth.changePassword(
      { email, connection: Auth0IDPasswordLoginConnectionRealm },
      (err, res) => {
        if (res) {
          console.log("change password", res);
          resolve(true);
        } else if (err) {
          resolve(false);
        }
      }
    );
  });
};

export const Auth0Provider: React.FC<Auth0ProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    setIsLoading(true);
    auth0WebAuth.checkSession({}, (err, res: CheckSessionResult) => {
      console.log("checkSession", err, res);
      if (res) {
        setIsAuthenticated(true);
        idTokenVar(parseIdToken(res));
        accessTokenVar(parseAccessToken(res));
        setIsLoading(false);
      } else if (err) {
        setIsAuthenticated(false);
        setIsLoading(false);
      }
    });
  }, []);

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        isLoading,
        getIdToken,
        getAccessToken,
        changePassword,
        logout: () => {
          auth0WebAuth.logout({ returnTo: auth0RedirectUri });
          handleLogoutForAnalytics();
        },
        login: (email, password, onLoginError) =>
          auth0WebAuth.login(
            {
              email,
              password,
              realm: Auth0IDPasswordLoginConnectionRealm,
            },
            (err) => {
              if (err) {
                onLoginError && onLoginError(err);
              }
            }
          ),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
