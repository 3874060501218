import { makeVar, useReactiveVar } from "@apollo/client";
import { useCallback, useEffect } from "react";

const gifsVar = makeVar<Record<string, string>>({});

const genUniqueUrl = (baseUrl: string) => `${baseUrl}?${performance.now()}`;

// eslint-disable-next-line
export const useReplayableGIF = (initGifs: string[] = []) => {
  const gifs = useReactiveVar(gifsVar);

  const setGif = useCallback((gifUrl: string) => {
    const uniqueUrl = genUniqueUrl(gifUrl);

    // preload
    const img = document.createElement("img");
    img.src = uniqueUrl;

    gifsVar({ ...gifsVar(), [gifUrl]: uniqueUrl });
  }, []);

  useEffect(() => {
    initGifs.forEach((g) => setGif(g));
  }, []);

  const getGif = useCallback(
    (gifUrl: string) => {
      // gifsにurlがなかったらその場で新しいユニークなURLを生成する(preloadは効かないが初めから再生される)
      return gifs[gifUrl] || genUniqueUrl(gifUrl);
    },
    [gifs]
  );

  return { setGif, getGif };
};
