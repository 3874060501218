import React from "react";
import { usePostReadLogState } from "../usePostReadLogState";
import styled from "styled-components";
import { PostReadLogAnim } from "~/components/organisms/animation/PostReadLogAnim";

export const PostReadLogAnimation: React.FC = () => {
  const { dispatch } = usePostReadLogState();
  const handleNext = () => {
    dispatch({
      type: "setFlow",
      payload: "indicateExperiencePoint",
    });
  };

  return (
    <_Wrapper>
      <PostReadLogAnim onNext={handleNext} isGoNextByClick={true}/>
    </_Wrapper>
  );
};

const _Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
`;
