import { KanziLevel } from "~/generated/graphql";

type Target =
  | "中学生向け"
  | "6年生向け"
  | "5年生向け"
  | "4年生向け"
  | "3年生向け"
  | "2年生むけ"
  | "1年生むけ（ひらがなのみ）";

export const convertToWord = (k: KanziLevel): Target => {
  switch (k) {
    case KanziLevel.L7:
      return "中学生向け";
    case KanziLevel.L6:
      return "6年生向け";
    case KanziLevel.L5:
      return "5年生向け";
    case KanziLevel.L4:
      return "4年生向け";
    case KanziLevel.L3:
      return "3年生向け";
    case KanziLevel.L2:
      return "2年生むけ";
    case KanziLevel.L1:
      return "1年生むけ（ひらがなのみ）";
  }
};
