import React from "react";
import styled from "styled-components";
import Linkify from "react-linkify";
import {
  AdminMessageText,
  AdminMessageTextProps,
} from "~/components/organisms/adminMessage/AdminMessageText";

export type LinkifyTextProps = AdminMessageTextProps & {
  children: React.ReactNode;
};

export const LinkifyText: React.FC<LinkifyTextProps> = ({
  children,
  ...textProps
}) => {
  return (
    <AdminMessageText {...textProps} lang="ja">
      <Linkify componentDecorator={componentDecorator}>{children}</Linkify>
    </AdminMessageText>
  );
};

const componentDecorator = (
  href: string,
  text: string,
  key: string
): JSX.Element => (
  <Anchor href={href} key={key} target="_blank" rel="noreferrer noopener">
    {text}
  </Anchor>
);

const Anchor = styled.a`
  color: ${({ theme }) => theme.colors.sec.s500};
  text-decoration: underline;
  word-wrap: break-word;
  &:hover,
  :active,
  :focus {
    text-decoration: none;
  }
`;
