import React from "react";
import styled from "styled-components";
import { Text, TextProps } from "~/components/atoms/texts/Text";
import { useTranslation } from "~/i18n";
import { Row } from "~/components/atoms/layout/Row";
import { Stack } from "~/components/atoms/layout/Stack";

type MiniLessonSummaryProps = {
  miniLessonContinueDays: number;
};

export const MiniLessonSummary: React.FC<MiniLessonSummaryProps> = ({
  miniLessonContinueDays,
}) => {
  const { t } = useTranslation();
  const baseTextProps: Pick<TextProps, "lineHeight" | "fontColor"> = {
    lineHeight: "MD",
    fontColor: "sec.s400",
  };

  return (
    <BookSumContent>
      <ImageWrap>
        <img src="/img/icon/clock.svg" width="22px" height="22px" />
      </ImageWrap>
      <Stack rowGap="0px">
        <Text {...baseTextProps} fontSize="XXXS" fontColor="tex.t200" bold>
          ミニレッスン
        </Text>
        <Row columnGap="0px" alignItems="baseline">
          <Text
            {...baseTextProps}
            fontSize="LG"
            fontColor="tex.t700"
            bold
            keepAll
          >
            {miniLessonContinueDays}
          </Text>
          <Text
            {...baseTextProps}
            fontSize="XS"
            fontColor="tex.t700"
            bold
            keepAll
          >
            {t<TranslateKeys>("日") + t<TranslateKeys>("連続")}
          </Text>
        </Row>
      </Stack>
    </BookSumContent>
  );
};

const ImageWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
`;

const BookSumContent = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;
