import * as React from "react";
import { Modal as ResponsiveModal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import styled from "styled-components";

type Props = {
  open: boolean;
  children: React.ReactNode;
  closeOnOverlayClick?: boolean;
  onClose?: () => void;
};

export const BottomModal: React.FC<Props> = ({
  open,
  children,
  closeOnOverlayClick = true,
  onClose,
}) => {
  return (
    <ResponsiveModal
      center
      open={open}
      onClose={() => onClose && onClose()}
      closeOnOverlayClick={closeOnOverlayClick}
      showCloseIcon={false}
      styles={{
        modalContainer: {
          margin: 0,
        },
        modal: {
          maxWidth: "100vw",
          boxSizing: "border-box",
          margin: 0,
          padding: 0,
          maxHeight: "80vh",
          position: "relative",
          verticalAlign: "bottom",
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
    >
      <_Container>{children}</_Container>
    </ResponsiveModal>
  );
};

const _Container = styled.div`
  position: relative;
`;
