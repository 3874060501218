import React from "react";
import styled from "styled-components";
import { useSnd } from "~/utils/hooks/useSnd";

type Size = "SM" | "LG";

export type CheckboxProps = {
  onClick: (event: React.MouseEvent) => void;
  label: string;
  isChecked: boolean;
  bold?: boolean;
  size: Size;
};

export const CheckBoxWithLabel: React.FC<CheckboxProps> = ({
  onClick,
  label,
  isChecked,
  bold = false,
  size,
}) => {
  const { play } = useSnd();
  const handleClick = (event: React.MouseEvent) => {
    if (isChecked) {
      play("TOGGLE_OFF");
    } else {
      play("TOGGLE_ON");
    }
    onClick(event);
  };
  return (
    <_Item onClick={handleClick}>
      <_Box size={size}>{isChecked && <_CheckedBox size={size} />}</_Box>
      <_Label bold={bold} size={size}>
        {label}
      </_Label>
    </_Item>
  );
};

const _Item = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
`;

const _Box = styled.div<{ size: Size }>`
  width: ${({ size }) => (size === "LG" ? "30px" : "20px")};
  height: ${({ size }) => (size === "LG" ? "30px" : "20px")};

  border: 2px solid;
  border-color: ${({ theme }) => theme.colors.base.gray};
  border-radius: ${({ size }) => (size === "LG" ? "8px" : "5px")};
  background-color: ${({ theme }) => theme.colors.base.white};

  display: flex;
  justify-content: center;
  align-items: center;
`;

const _CheckedBox = styled.div<{ size: Size }>`
  width: ${({ size }) => (size === "LG" ? "18px" : "12px")};
  height: ${({ size }) => (size === "LG" ? "18px" : "12px")};

  padding: auto;
  border-radius: 2px;

  background-color: ${({ theme }) => theme.colors.pri.p600};
`;

const _Label = styled.p<{ bold: boolean; size: Size }>`
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
  font-size: ${({ theme, size }) =>
    size === "LG" ? theme.fontSize.MD : theme.fontSize.XS};
  color: ${({ theme }) => theme.colors.tex.t500};
`;
