import React, { useCallback, useState } from "react";
import styled, { css } from "styled-components";
import { BookFriendChatMessage, UserType } from "../../BookFriendChatMessage";
import {
  DeletedUserOnTalkFragment,
  BfReadLogFragment,
  ReadLogReactionType,
  UserOnTalkFragment,
} from "~/generated/graphql";
import { Text } from "~/components/atoms/texts/Text";
import { WhitedLinkifyTextProps } from "~/components/molecules/texts/WhitedLinkifyText";
import { IconTextButton } from "~/components/molecules/buttons/IconTextButton";
import { Stack } from "~/components/atoms/layout/Stack";
import { Row } from "~/components/atoms/layout/Row";
import { ReactionButton } from "./ReactionButton";
import { useTranslation } from "~/i18n";
import { IMAGE_PATHS } from "~/helpers/constants/imagePath";
import { WantReadBookButtonLockWrapper } from "~/components/organisms/Unlock/LockWrapper/wantReadBookButtonLockWrapper";
import { WantReadBookButton } from "./WantReadBookButton";
import { SenseiMessage } from "./SenseiMessage";
import { StarButton } from "./StarButton";
import { SimplifiedReadLogMessageProps } from "./SendStarModal/SimplifiedReadLogMessage";
import { SendStarMessage, SendStarMessageProps } from "./SendStarMessage";
import { BfStarLockWrapper } from "~/components/organisms/Unlock/LockWrapper/bfStarLockWrapper";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";

export type ReadLogMessageProps = {
  item: BfReadLogFragment;
  user?: UserOnTalkFragment | DeletedUserOnTalkFragment;
  userType: UserType;
  className?: string;
  isInWantReadBookShelf?: boolean;
  isShowSenseiMessage?: boolean;
  onReaction: (
    id: string,
    readLogId: number,
    reaction: ReadLogReactionType
  ) => Promise<boolean>;
  onWantReadBook: (readLog: BfReadLogFragment) => Promise<boolean>;
  onCompleteShowSenseiMessage: (readLogId: number) => void;
  onOpenSendStarModal: (item: SimplifiedReadLogMessageProps) => void;
  canSendStar: boolean;
  isShowSendStarMessage: boolean;
  sendStarMessageProps: SendStarMessageProps;
  isVisibleStarButton?: boolean;
  bookFriendLockTemplate?: boolean;
  isTutorial?: boolean;
};

export const ReadLogMessage: React.FC<ReadLogMessageProps> = ({
  user,
  userType,
  item,
  className,
  isInWantReadBookShelf,
  isShowSenseiMessage,
  onReaction,
  onWantReadBook,
  onCompleteShowSenseiMessage,
  onOpenSendStarModal,
  canSendStar,
  isShowSendStarMessage,
  sendStarMessageProps,
  isVisibleStarButton = true,
  bookFriendLockTemplate,
  isTutorial = false,
}) => {
  const isShowReadLog =
    item !== undefined && item.readLogInfo.like !== undefined
      ? item.readLogInfo.like > 2
      : false;
  const isWhiteText = userType === "me";
  const bookImageUrl = item.readLogInfo.book.imageUrl
    ? item.readLogInfo.book.imageUrl
    : IMAGE_PATHS.NO_IMAGE;
  const [isReviewExpanded, setIsReviewExpanded] = useState(false);
  const { t } = useTranslation();
  const { currentUser } = React.useContext(CurrentUserContext);
  const nickname = currentUser?.general.nickname;

  const extendMessage = (event: React.MouseEvent) => {
    event.preventDefault();
    setIsReviewExpanded(true);
    window.gtag("event", "extend-bf-message", {
      event_category: "bookfriend",
      event_label: "extend-bf-message",
      value: 1,
    });
  };

  const [reactionUpdating, setReactionUpdating] = useState<boolean>(false);
  const myReactions = item.myReaction.reactionType;
  const onClickReaction = useCallback(
    (reaction: ReadLogReactionType) => {
      setReactionUpdating(true);
      onReaction(item.id, item.readLogInfo.readLogId, reaction).then(() => {
        setReactionUpdating(false);
      });
    },
    [onReaction, item]
  );

  const [wantReadBookUpdating, setWantReadBookUpdating] =
    useState<boolean>(false);
  const onClickWantReadBook = useCallback(() => {
    setWantReadBookUpdating(true);
    onWantReadBook(item).then(() => {
      setWantReadBookUpdating(false);
    });
  }, [onWantReadBook, item]);

  const onCompleted = React.useCallback(() => {
    onCompleteShowSenseiMessage(item.readLogInfo.readLogId);
  }, [item]);

  return (
    <BookFriendChatMessage
      userType={userType}
      date={new Date(item.createdAt)}
      user={user}
      className={className}
    >
      <BookInfo isWhite={isWhiteText}>
        <_ButtonAndTitleContainer>
          <BookTitleContainer>
            <BookTitle fontSize="SM" lineHeight="MD" isWhite={isWhiteText}>
              {item.readLogInfo.book.title}
            </BookTitle>
            <BookAuthor fontSize="XS" lineHeight="MD" isWhite={isWhiteText}>
              {item.readLogInfo.book.author}
            </BookAuthor>
          </BookTitleContainer>
          {userType === "user" &&
            item.readLogInfo.book.isbn !== "0" &&
            !bookFriendLockTemplate && (
              <WantReadBookButtonLockWrapper>
                <_WantReadBookButtonWrapper>
                  {isShowSenseiMessage && (
                    <_SenseiMessageWrapper>
                      <SenseiMessage
                        readLogId={item.readLogInfo.readLogId}
                        translationParams={{ user: nickname || "" }}
                        onCompleted={onCompleted}
                      />
                    </_SenseiMessageWrapper>
                  )}
                  <WantReadBookButton
                    onClick={onClickWantReadBook}
                    selected={!!isInWantReadBookShelf}
                    disabled={wantReadBookUpdating || !!isInWantReadBookShelf}
                  />
                </_WantReadBookButtonWrapper>
              </WantReadBookButtonLockWrapper>
            )}
        </_ButtonAndTitleContainer>
        <BookCoverContainer>
          <Image src={bookImageUrl} alt={item.readLogInfo.book.title} />
        </BookCoverContainer>
      </BookInfo>
      {isShowReadLog && item.readLogInfo.review && (
        <ReasonContainer>
          {item.readLogInfo.review.length > 50 && !isReviewExpanded ? (
            <>
              <ReviewText
                fontSize={"SM"}
                lineHeight={"MD"}
                isWhite={isWhiteText}
              >
                {item.readLogInfo.review.slice(0, 50) + "……"}
              </ReviewText>
              <ExpandButtonContainer>
                <IconTextButton
                  text={"つづきをみる"}
                  iconPlace={"pre"}
                  name={"chevronArrowDown"}
                  color={isWhiteText ? "white" : "sec"}
                  onClick={extendMessage}
                  fontSize={"SM"}
                  lineHeight={"MD"}
                />
              </ExpandButtonContainer>
            </>
          ) : (
            <ReviewText fontSize={"SM"} lineHeight={"MD"} isWhite={isWhiteText}>
              {item.readLogInfo.review}
            </ReviewText>
          )}
        </ReasonContainer>
      )}
      {userType === "user" &&
        (bookFriendLockTemplate ? (
          <ReactionWrapper>
            <Stack rowGap="8px">
              <ReactionLabel fontSize="XS" lineHeight="EQ">
                {t<TranslateKeys>("いいねを送る")}
              </ReactionLabel>
              <Row style={{ width: "100%" }}>
                <ReactionButton
                  reaction={ReadLogReactionType.Read}
                  disabled={true}
                  onClick={() => {}}
                  selected={false}
                  bookFriendLockTemplate={true}
                />
                <ReactionButton
                  reaction={ReadLogReactionType.Like}
                  disabled={true}
                  onClick={onClickReaction}
                  selected={false}
                  bookFriendLockTemplate={true}
                />
                <StarButton
                  onClick={() => {
                    if (!item.myReaction.starType) {
                      onOpenSendStarModal({ ...item });
                    }
                  }}
                  disabled={true}
                  selected={false}
                />
              </Row>
            </Stack>
          </ReactionWrapper>
        ) : (
          <ReactionWrapper>
            <Stack rowGap="8px">
              <ReactionLabel fontSize="XS" lineHeight="EQ">
                {t<TranslateKeys>("いいねを送る")}
              </ReactionLabel>
              <Row style={{ width: "100%", position: "relative" }}>
                <ReactionButton
                  reaction={ReadLogReactionType.Read}
                  disabled={
                    reactionUpdating ||
                    myReactions.includes(ReadLogReactionType.Read)
                  }
                  onClick={onClickReaction}
                  selected={myReactions.includes(ReadLogReactionType.Read)}
                />
                <ReactionButton
                  reaction={ReadLogReactionType.Like}
                  disabled={
                    reactionUpdating ||
                    myReactions.includes(ReadLogReactionType.Like)
                  }
                  onClick={onClickReaction}
                  selected={myReactions.includes(ReadLogReactionType.Like)}
                />
                {isVisibleStarButton && (
                  <BfStarLockWrapper>
                    <_SendStarButtonWrapper>
                      {isShowSendStarMessage && (
                        <_SendStarMessageWrapper>
                          <SendStarMessage {...sendStarMessageProps} />
                        </_SendStarMessageWrapper>
                      )}
                      <StarButton
                        onClick={() => {
                          if (!item.myReaction.starType) {
                            onOpenSendStarModal({ ...item });
                          }
                        }}
                        disabled={!canSendStar}
                        selected={Boolean(item.myReaction.starType)}
                      />
                    </_SendStarButtonWrapper>
                  </BfStarLockWrapper>
                )}
                {isTutorial && (
                  <_PointerImg src="/img/recommend/pointer.svg" />
                )}
              </Row>
            </Stack>
          </ReactionWrapper>
        ))}
    </BookFriendChatMessage>
  );
};

type WhiteStyleProps = {
  isWhite: boolean;
};

const ReactionLabel = styled(Text)`
  color: ${({ theme }) => theme.colors.tex.t400};
`;

const ReactionWrapper = styled.div`
  margin-top: 15px;
  padding-top: 15px;
  border-top: 2px solid ${({ theme }) => theme.colors.base.lightGray};
`;

const ReviewText = styled(Text)<WhitedLinkifyTextProps>`
  ${({ isWhite, theme }) => {
    return css`
      color: ${isWhite ? theme.colors.base.white : theme.colors.tex.t500};
    `;
  }}
`;
const BookTitle = styled(Text)<WhiteStyleProps>`
  color: ${({ theme, isWhite }) =>
    isWhite ? theme.colors.base.white : theme.colors.tex.t800};
`;
const BookAuthor = styled(Text)<WhiteStyleProps>`
  color: ${({ theme, isWhite }) =>
    isWhite ? theme.colors.base.white : theme.colors.tex.t300};
`;

const BookInfo = styled.div<WhiteStyleProps>`
  position: relative;
  display: flex;
  width: 100%;
  margin-bottom: 15px;
  border-radius: 8px;
  gap: 5%;
  padding: 8px 12px;
  background: ${({ theme, isWhite }) =>
    isWhite ? theme.colors.pri.p500 : theme.colors.base.background};
`;

const _ButtonAndTitleContainer = styled.div`
  width: 65%;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

const BookTitleContainer = styled.div`
  width: 100%;
`;

const BookCoverContainer = styled.div`
  width: 30%;
  max-width: 160px;
`;

const Image = styled.img`
  width: 100%;
  border-radius: ${({ theme }) => theme.deprecatedBorderRadius.SM};
`;

const ReasonContainer = styled.div`
  width: 100%;
`;

const ExpandButtonContainer = styled.div`
  margin-top: 5px;
`;

const _SenseiMessageWrapper = styled.div`
  position: absolute;
  bottom: 55px;
  /* MEMO: 中央揃え */
  left: calc(50% - 109px);
`;

const _WantReadBookButtonWrapper = styled.div`
  position: relative;
`;

const _SendStarButtonWrapper = styled.div`
  position: relative;
`;

const _SendStarMessageWrapper = styled.div`
  position: absolute;
  bottom: 36px;
  left: -28px;
`;

const _PointerImg = styled.img`
  position: absolute;
  width: 28px;
  height: 28px;
  top: 20px;
  left: 132px;
  animation: diagonalMove 0.6s infinite alternate ease-in-out;
  @keyframes diagonalMove {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(6px, 6px); /* 斜めに6px動かす */
    }
  }
`;
