import React, { VFC } from "react";
import styled, { css } from "styled-components";
import { ColorTheme } from "~/styles/design_token/color";
import { useSnd } from "~/utils/hooks/useSnd";
import { RadioButtonProps } from "../type";

export const FlatRadioButton: VFC<RadioButtonProps> = ({
  selected,
  disabled = false,
  onClick,
  shape,
  color,
  children,
}) => {
  const { play } = useSnd();
  const handleClick = (event: React.MouseEvent) => {
    play("TAP");
    onClick(event);
  };
  return (
    <ButtonCon disabled={disabled}>
      <StyledButton
        selected={selected}
        shape={shape}
        disabled={disabled}
        color={color}
        onClick={handleClick}
      >
        {children}
      </StyledButton>
    </ButtonCon>
  );
};

const StyledButton = styled.button<
  Pick<RadioButtonProps, "selected" | "shape" | "disabled" | "color">
>`
  ${({ theme, selected, disabled, color }) => {
    const { colors, deprecatedBorderRadius: borderRadius, lineHeight } = theme;
    const { base, pri, sec, violet, green, red, sky } = colors;
    return css`
      width: 100%;
      background-color: ${base.white};
      padding: 4px 16px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      cursor: ${!disabled && "pointer"};
      border-radius: ${borderRadius.MD};
      font-family: inherit;
      text-decoration: none;
      line-height: ${lineHeight.MD};
      &:focus {
        outline: none;
      }
      &:active {
        outline: none;
        ${!disabled && SelectedInterpolation(color)}
      }
      border: 1px solid
        ${() => {
          switch (color) {
            case "pri":
              return pri.p400;
            case "sec":
              return sec.s400;
            case "violet":
              return violet.v400;
            case "green":
              return green.g400;
            case "red":
              return red.r400;
            case "sky":
              return sky.s400;
            case "gray":
              return base.gray;
            default:
              return pri.p400;
          }
        }};
      ${selected && SelectedInterpolation(color)}
    `;
  }}
`;

const ButtonCon = styled.div<Pick<RadioButtonProps, "disabled">>`
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
`;

const SelectedInterpolation = (color: ColorTheme) => css`
  background-color: ${({ theme }) => {
    switch (color) {
      case "pri":
        return theme.colors.pri.p400;
      case "sec":
        return theme.colors.sec.s400;
      case "violet":
        return theme.colors.violet.v400;
      case "green":
        return theme.colors.green.g400;
      case "red":
        return theme.colors.red.r400;
      case "sky":
        return theme.colors.sky.s400;
      case "gray":
        return theme.colors.base.gray;
      default:
        return theme.colors.pri.p400;
    }
  }};
  & > p {
    color: ${({ theme }) => theme.colors.base.white} !important;
  }
`;
