import { env } from "../src/utils/env";
import { ConfigType } from "./type";

// MEMO: developmentとなっているが、
// dev環境（https://user.alnair.test.gotoloop.dev/）ではなく、local環境のみで利用
export const devConf: ConfigType = {
  ENV: "development",

  PAGE_PREFIX: "/",
  API_HOST: "http://localhost:8082/",
  USER_APP: "http://localhost:3002/",
  PARENT_APP: "http://localhost:3003/",

  CSRFP_TOKEN_HEADER: "X-CSRFP-Token",
  RAP_TOKEN_HEADER: "X-RAP-Token",

  RAP_TOKEN_LENGTH: 5,
  REQUEST_REPEAT: 2,

  LIST_LENGTH: 10,
  MID_LIST_LENGTH: 30,
  BIG_LIST_LENGTH: 100,

  TALK_FETCH_NUMBER: 10,

  ACCESS_TOKEN_HEADER: null,

  GA_MEASUREMENT_ID: env("GA_MEASUREMENT_ID"),
  GOOGLE_TAG_MANAGER_ID: env("GOOGLE_TAG_MANAGER_ID"),

  REPLY_GODOWN: 20,

  SENTRY_DSN:
    "https://4313fb30d1eb4faea12a217cf6ea83c5@o967000.ingest.sentry.io/5918055",

  AUTH0_DOMAIN: env("AUTH0_DOMAIN") || "yondemy-local.jp.auth0.com",
  AUTH0_CLIENT_ID: env("AUTH0_CLIENT_ID") || "OaMN85A3YGdIxIbw0W4BeTJlesD5kP5R",
  AUTH0_AUDIENCE: env("AUTH0_AUDIENCE") || "http://localhost:8082",
};
