import React from "react";
import { BookshelfCurrentRecommendFragment } from "~/generated/graphql";
import styled from "styled-components";
import { OpenBoxAnimation } from "~/components/organisms/animation/OpenBoxAnimation";
import { OnboardingBaseModal } from "~/components/organisms/modals/TutorialInitializeModal/OnboardingBaseModal";
import { WriteReadLog } from "~/components/organisms/modals/TutorialInitializeModal/WriteReadLog";
import { EndingConversation } from "~/components/organisms/modals/TutorialInitializeModal/EndingConversation";
import { NewRecommendItemModal } from "~/components/organisms/modals/NewRecommendItemModal";
import { PostReadLogAnim } from "~/components/organisms/animation/PostReadLogAnim";
import { useAudio } from "~/utils/hooks/useAudio";

const flows = [
  "openBox",
  "wakuwaku",
  "endWakuwaku",
  "writeReadLog",
  "postReadLog",
  "endingTutorial",
];

type Flow = (typeof flows)[number];

export type TutorialFlowProps = {
  recommends: BookshelfCurrentRecommendFragment[];
  onClickToMinilesson: (e: React.MouseEvent) => void;
  onConfirmAllRecommend: () => void;
};

export const TutorialFlow: React.VFC<TutorialFlowProps> = ({
  recommends,
  onClickToMinilesson,
  onConfirmAllRecommend,
}) => {
  const [flow, setFlow] = React.useState<Flow>(flows[0]);
  const current = flows.indexOf(flow);
  const { play } = useAudio({ preload: ["initializeAudio"] });

  const handleNext = React.useCallback(() => {
    setFlow(flows[current + 1]);
    window.scrollTo({ top: 0 });
  }, [current, setFlow]);

  return (
    <>
      <FixedModal>
        {flow === "openBox" && <OpenBoxAnimation onNext={handleNext} />}
        {flow === "wakuwaku" && (
          <_Wrapper>
            <NewRecommendItemModal
              isOpen={true}
              recommends={recommends}
              onClose={() => {
                handleNext();
                onConfirmAllRecommend();
              }}
              onCancel={() => {
                return;
              }}
              showCancelButton={false}
            />
          </_Wrapper>
        )}
        {flow === "endWakuwaku" && (
          <OnboardingBaseModal
            onClick={handleNext}
            imgSrc="/img/sensei/standup_1.svg"
            message={`このほんを\nとしょかんで かりるか、\nほんやさんで かって\nよんでみてね！`}
          />
        )}
        {flow === "writeReadLog" && (
          <WriteReadLog
            onNext={(e: React.MouseEvent) => {
              e.preventDefault();
              handleNext();
              play("initializeAudio");
            }}
          />
        )}
        {flow === "postReadLog" && (
          <PostReadLogAnim onNext={handleNext} isGoNextByClick={false} />
        )}
        {flow === "endingTutorial" && (
          <EndingConversation onClickToMinilesson={onClickToMinilesson} />
        )}
      </FixedModal>
    </>
  );
};

const FixedModal = styled.div`
  z-index: ${({ theme }) => theme.zIndex.modal};
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
`;

const _Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url("/img/initialize_modal/bookshelf.png");
  background-repeat: repeat-x;
  background-size: contain;
`;
