import React, { useCallback } from "react";
import styled from "styled-components";
import { MasonryPagiableListProps } from "./types";
import BaseMasonry from "react-masonry-component";
import { Loading } from "~/components/atoms/Loading";
import { PaginationButton } from "../../buttons/PaginationButton";

export const MasonryPagiableList: React.FC<MasonryPagiableListProps> = ({
  onPageChange,
  renderHeader,
  className,
  items,
  isLoading,
  scrollToOffset,
  listProps,
  renderListItem,
  renderEmptyComponent,
  disablePaging,
  currentCount,
  itemsPerPage,
  itemTotal,
}) => {
  const ref = React.createRef<HTMLDivElement>();
  const scrollToTopOfList = React.useCallback(() => {
    ref.current &&
      scrollToOffset &&
      window.scrollTo({
        top: ref.current.offsetTop - scrollToOffset,
      });
  }, [ref]);

  const onChangePage = useCallback(
    (num: number) => {
      onPageChange(num);
      scrollToTopOfList();
    },
    [onPageChange, scrollToTopOfList]
  );

  return (
    <Container className={className} ref={ref}>
      {renderHeader && renderHeader()}
      {isLoading ? (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      ) : items.length > 0 ? (
        <Masonry {...listProps}>
          {items.map((item, index) => {
            return renderListItem({
              index,
              item,
            });
          })}
        </Masonry>
      ) : (
        <EmptyContainer>
          {renderEmptyComponent && renderEmptyComponent()}
        </EmptyContainer>
      )}
      {!disablePaging && (
        <PaginationContainer>
          <PaginationButton
            currentCount={currentCount || 0}
            onClick={onChangePage}
            itemsPerPage={itemsPerPage || 0}
            totalItemsCount={itemTotal || 0}
          />
          {/* <Paging_NextAndPrevButton
            begin={currentCount || 0}
            setBegin={onChangePage}
            listLength={itemsPerPage || 0}
            count={itemTotal || 0}
            inNotNeed_Delete={true}
          /> */}
        </PaginationContainer>
      )}
    </Container>
  );
};

MasonryPagiableList.defaultProps = {
  itemsPerPage: 10,
  scrollToOffset: 130, // スクロールした時のナビゲーション部分のTopの高さ
  listProps: {
    width: "100%",
  },
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const EmptyContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
`;

const Masonry = styled(BaseMasonry)`
  width: 100%;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
`;

const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
