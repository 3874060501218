import { ConfigType } from "./type";

export const prodConf: ConfigType = {
  ENV: "production",

  PAGE_PREFIX: "/",
  API_HOST: "https://api.app.yondemy.com/",
  USER_APP: "https://app.yondemy.com/",
  PARENT_APP: "https://portal.yondemy.com/",

  CSRFP_TOKEN_HEADER: "X-CSRFP-Token",
  RAP_TOKEN_HEADER: "X-RAP-Token",

  RAP_TOKEN_LENGTH: 5,
  REQUEST_REPEAT: 4,

  LIST_LENGTH: 10,
  MID_LIST_LENGTH: 30,
  BIG_LIST_LENGTH: 100,

  TALK_FETCH_NUMBER: 10,

  ACCESS_TOKEN_HEADER: null,

  GA_MEASUREMENT_ID: "G-3PYR64559Z",
  GOOGLE_TAG_MANAGER_ID: "GTM-5XZ2TNH",

  REPLY_GODOWN: 20,

  SENTRY_DSN:
    "https://4313fb30d1eb4faea12a217cf6ea83c5@o967000.ingest.sentry.io/5918055",

  AUTH0_DOMAIN: "auth.yondemy.com",
  AUTH0_CLIENT_ID: "UMTFEpCPMKSNv1wO3Ssnm8d9ZSxcU2XF",
  AUTH0_AUDIENCE: "https://api.app.yondemy.com",
};
