export type Sentences = Record<number, TranslateKeys>;
export const sentences: Sentences = {
  2: "これは1000人中500人だけです！\n明日も読めば1000人中300人に入れるよ",
  3: "1000人中300人！\n5日連続で1000人中200人に入れるよ！",
  5: "1000人中200人だ！\n1週間連続で1000人中100人だよ",
  7: "1週間連続で読んだのは、1000人中100人だけです！",
  10: "1000人中50人まで来たよ！\nもうすぐ2週間連続だ",
  14: "2週間連続！？\nすごい！\n1000人中30人だよ",
  21: "1000人中20人しか3週連続で読めた人はいないよ！",
  28: "ここまで来たのは1000人に10人だけ！\nとうとうトップ10だ！",
  35: "なんと、10000人に50人まで来ちゃったよ！\n次は何人に入れるかな？",
  42: "6週連続で読んだのは10000人に20人だけだよ！",
  50: "すごすぎる！\n10000人に10人だけだよ！",
  60: "10000人に5人だよ！！\n片手の指で数えられちゃうね！",
  70: "10000人に2人！\nもうちょっとで10000人のトップになれるよ！",
  80: "すごい！\n10000人に1人の読書家だ！",
  90: "頑張った{{user}}さんに四葉のクローバーをプレゼント！",
  100: "砂漠のオアシス発見級のすごさ！",
  120: "{{user}}さんのために流れ星が降り注ぐよ！",
  150: "ティラノサウルスの化石を掘りあてたくらいすごい！",
  200: "読書オリンピックで金メダルだね😉",
  250: "エベレスト級の本の山のてっぺんだ！",
  300: " {{user}}さんの読んだ本でピラミッドが作れちゃう！",
  365: "本を読み続けて1年！！\nすっごく成長したね！",
  400: "読書星人も{{user}}さんとお友達になりたがるよ！",
  500: "あまりのすごさにモナ・リザも笑顔になっちゃった！",
  600: "びっくりしてアリが象を持ち上げちゃった！",
  700: "ピサの斜塔がびっくりしてまっすぐになっちゃった！",
  800: "クジラも一緒に本を読みたくて本を噴き上げちゃったよ！",
  900: "モアイ像がおめでとうの歌を歌い出したみたいだよ！",
  1000: "ここまで辿り着けるのは、選ばれた伝説の読書家だけです！",
};
