import { makeVar, useReactiveVar } from "@apollo/client";
import { useCallback, useEffect } from "react";

const baseDir = "/audio/mission_story/yondemy_story";

const storyLevel = 0.8;

const soundMap = {
  pageTurn: { src: `${baseDir}/pageTurn.mp3`, level: 0.3 }, // https://dova-s.jp/se/play1191.html,
  story1: { src: `${baseDir}/story1.mp3`, level: storyLevel },
  story2: { src: `${baseDir}/story2.mp3`, level: storyLevel },
  story3: { src: `${baseDir}/story3.mp3`, level: storyLevel },
  story4: { src: `${baseDir}/story4.mp3`, level: storyLevel },
  story5: { src: `${baseDir}/story5.mp3`, level: storyLevel },
  story6: { src: `${baseDir}/story6.mp3`, level: storyLevel },
  story7: { src: `${baseDir}/story7.mp3`, level: storyLevel },
  story8: { src: `${baseDir}/story8.mp3`, level: storyLevel },
  story9: { src: `${baseDir}/story9.mp3`, level: storyLevel },
  story10: { src: `${baseDir}/story10.mp3`, level: storyLevel },
  story11: { src: `${baseDir}/story11.mp3`, level: storyLevel },
  toBeContinued: { src: `${baseDir}/toBeContinued.mp3`, level: storyLevel },
};

export type SoundKey = keyof typeof soundMap;

type UseAudioParams = {
  preload?: SoundKey[];
};

type LoadingStatus = "loading" | "loaded";

const _buffers = makeVar<Record<string, AudioBuffer>>({});
const _loadingStatus = makeVar<Record<string, LoadingStatus>>({});

const AudioContext = window.AudioContext || window.webkitAudioContext;
const ctx = new AudioContext();

export const useAudio = ({
  preload = Object.keys(soundMap) as SoundKey[],
}: UseAudioParams): {
  play: (key: SoundKey, delay?: number) => (() => void) | undefined;
} => {
  const loadingStatus = useReactiveVar(_loadingStatus);
  const buffers = useReactiveVar(_buffers);

  useEffect(() => {
    preload.forEach((p) => {
      if (loadingStatus[p] || !soundMap[p]) {
        return;
      }
      loadingStatus[p] = "loading";

      const src = soundMap[p].src;
      const request = new XMLHttpRequest();
      request.open("GET", src, true);
      request.responseType = "arraybuffer";
      request.onload = () => {
        const res = request.response;
        ctx.decodeAudioData(res, (buf) => {
          buffers[p] = buf;
          loadingStatus[p] = "loaded";
        });
      };

      request.send();
    });
  }, [preload]);

  const play = useCallback(
    (key: SoundKey, delay = 0) => {
      if (buffers[key]) {
        const source = ctx.createBufferSource();
        source.buffer = buffers[key];

        const gainNode = ctx.createGain();
        const level = soundMap[key]?.level || 1.0;
        const volume = level;
        gainNode.gain.value = volume;
        gainNode.connect(ctx.destination);

        source.connect(gainNode);
        if (typeof ctx.currentTime !== "number" || !isFinite(ctx.currentTime)) {
          return () => {
            console.error("fail to start audio device");
          };
        }
        source.start(ctx.currentTime + delay);

        return () => {
          source.stop(ctx.currentTime);
        };
      }
    },
    [buffers]
  );

  return { play };
};
