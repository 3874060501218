import React from "react";
import { countAllBadgeNumber } from "~/components/templates/RecordBook/BadgeList/hook";
import { ArchievementRecordTemplate } from "~/components/templates/RecordBook/Archievement";
import {
  useGetFootprintQuery,
  useGetRecordBookDiffQuery,
  useUpsertLatestRecordBookViewTimeMutation,
} from "~/generated/graphql";
import { LoadingSection } from "~/components/templates/Loading/LoadingSection";

export const ArchievementRecord: React.FC = () => {
  const {
    data: footPrintData,
    loading: footPrintLoading,
    error: footPrintError,
  } = useGetFootprintQuery({
    fetchPolicy: "network-only",
  });
  const {
    data: recordDiffData,
    loading: recordDiffLoading,
    error: recordDiffError,
  } = useGetRecordBookDiffQuery({
    fetchPolicy: "network-only",
  });

  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  const [upsertLatestRecordBookViewTime] =
    useUpsertLatestRecordBookViewTimeMutation();

  const handleCloseModal = () => {
    setIsModalOpen(false);
    upsertLatestRecordBookViewTime();
  };

  React.useEffect(() => {
    if (recordDiffData?.getRecordBookDiff.IsFirstTimeViewInWeek) {
      setIsModalOpen(true);
    }
  }, [recordDiffData]);

  if (
    footPrintLoading ||
    recordDiffLoading ||
    !footPrintData ||
    !recordDiffData
  ) {
    return <LoadingSection />;
  }

  if (footPrintError || recordDiffError) {
    return <>Error!</>;
  }

  return (
    <ArchievementRecordTemplate
      nickname={footPrintData.me.general.nickname}
      footprintProps={{
        readLogTotalNum: footPrintData.me.general.readLogTotalNum,
        readCharSum: footPrintData.me.general.readCharSum,
        readBookSum: footPrintData.me.general.readBookSum,
        minilessonTotalNum: footPrintData.me.general.miniLessonTotalNum,
        totalBadgeNum: countAllBadgeNumber(
          footPrintData.me.general.readCharSum,
          footPrintData.me.general.maxContinueReadLogDays,
          footPrintData.me.general.readBookSum
        ),
        receivedTotalBfReadLogReactionNum:
          footPrintData.me.general.receivedTotalBfReadLogReactionNum,
        receivedTotalBfReadLogStarNum:
          footPrintData.me.general.receivedBfReadLogStar.reduce(
            (sum, technique) => sum + technique.num,
            0
          ),
      }}
      receivedStarProps={{
        receivedBfReadLogStar: footPrintData.me.general.receivedBfReadLogStar,
      }}
      recordBookDiff={recordDiffData.getRecordBookDiff}
      isModalOpen={isModalOpen}
      handleCloseModal={handleCloseModal}
    />
  );
};
