import { MissionStorySetting } from "../type";

const baseDir = "/img/mission/start_story/story_4";

export const StartStory4: MissionStorySetting[] = [
  {
    image: `${baseDir}/scene_1.jpeg`,
    sound: "start4_scene1",
    text: "太古のきょうりゅうが目を開けると︙︙、\nそこには夢に出てきたひつじが。",
    ruby: [
      "たい",
      "こ",
      ...Array(8).fill(""),
      "め",
      "",
      "あ",
      ...Array(11).fill(""),
      "ゆめ",
      "",
      "で",
      ...Array(8).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_2.jpeg`,
    sound: "start4_scene2",
    text: "「︙︙︙︙︙ん︙︙？」",
    ruby: [...Array(10).fill("")],
  },
  {
    image: `${baseDir}/scene_3.jpeg`,
    sound: "start4_scene3",
    text: "「モ、モコ〜〜〜〜〜〜⁉⁉」",
    ruby: [...Array(14).fill("")],
  },
  {
    image: `${baseDir}/scene_4.jpeg`,
    sound: "start4_scene4",
    text: "ひつじの大きな悲鳴で、みんなもびっくりして目を覚ましました。\n太古のきょうりゅうも大慌て。",
    ruby: [
      ...Array(4).fill(""),
      "おお",
      ...Array(2).fill(""),
      "ひ",
      "めい",
      ...Array(12).fill(""),
      "め",
      "",
      "さ",
      ...Array(7).fill(""),
      "たい",
      "こ",
      ...Array(8).fill(""),
      "おお",
      "あわ",
      ...Array(2).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_5.jpeg`,
    sound: "start4_scene5_1",
    text: "「き、きみは誰⁉　どうしてここにいるの⁉」",
    ruby: [...Array(6).fill(""), "だれ", ...Array(14).fill("")],
  },
  {
    image: `${baseDir}/scene_5.jpeg`,
    sound: "start4_scene5_2",
    text: "「え、えっとぉ︙︙あのぉ︙︙う、うちは、モコひつじと申します︙︙うちは、よくいろんな方の夢にお邪魔しているんですけど、",
    ruby: [
      ...Array(26).fill(""),
      "もう",
      ...Array(15).fill(""),
      "かた",
      "",
      "ゆめ",
      ...Array(2).fill(""),
      "じゃ",
      "ま",
      ...Array(15).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_5.jpeg`,
    sound: "start4_scene5_3",
    text: "みなさんの夢が特別にすごく心地よくて︙︙たくさん飛びはねていたら、まちがえて夢の中から飛び出してしまったみたいです︙︙」",
    ruby: [
      ...Array(5).fill(""),
      "ゆめ",
      "",
      "とく",
      "べつ",
      ...Array(4).fill(""),
      "ここ",
      "ち",
      ...Array(9).fill(""),
      "と",
      ...Array(13).fill(""),
      "ゆめ",
      "",
      "なか",
      ...Array(2).fill(""),
      "と",
      "",
      "だ",
      ...Array(14).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_6.jpeg`,
    sound: "start4_scene6_1",
    text: "「スッゲーー！そんなことあるんだな！\nそういうことならさ、オイラたちと一緒に図書館を救う冒険に行こうよ！\nきっとオイラたち、運命だぜ！」",
    ruby: [
      ...Array(35).fill(""),
      "いっ",
      "しょ",
      "",
      "と",
      "しょ",
      "かん",
      "",
      "すく",
      "",
      "ぼう",
      "けん",
      "",
      "い",
      ...Array(14).fill(""),
      "うん",
      "めい",
      ...Array(4).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_6.jpeg`,
    sound: "start4_scene6_2",
    text: "「モ、モコ⁉⁉」\nモコひつじはびっくり。でも、みんなももうその気満々のようです。",
    ruby: [...Array(31).fill(""), "き", "まん", "まん", ...Array(7).fill("")],
  },
  {
    image: `${baseDir}/scene_7.jpeg`,
    sound: "start4_scene7",
    text: "さて、冒険の宿をどくしょかのしょの中にしまって、冒険の準備です。",
    ruby: [
      ...Array(3).fill(""),
      "ぼう",
      "けん",
      "",
      "やど",
      ...Array(10).fill(""),
      "なか",
      ...Array(6).fill(""),
      "ぼう",
      "けん",
      "",
      "じゅん",
      "び",
      ...Array(3).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_8.jpeg`,
    sound: "start4_scene8_1",
    text: "次の階へいくために、「おもいえがく」のスターの力を得たどくしょかのしょで、エレベーターの扉を開きます。",
    ruby: [
      "つぎ",
      "",
      "かい",
      ...Array(20).fill(""),
      "ちから",
      "",
      "え",
      ...Array(18).fill(""),
      "とびら",
      "",
      "ひら",
      ...Array(13).fill(""),
      "かがや",
      ...Array(8).fill(""),
      "とびら",
      "",
      "ひら",
      ...Array(7).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_8.jpeg`,
    sound: "start4_scene8_2",
    text: "どくしょかのしょが輝いて、ゆっくりと扉が開いていきます。",
    ruby: [
      ...Array(9).fill(""),
      "かがや",
      ...Array(8).fill(""),
      "とびら",
      "",
      "ひら",
      ...Array(7).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_9.jpeg`,
    sound: "start4_scene9",
    text: "みんなでエレベーターに乗りました。あっという間に、次の階についたようです。",
    ruby: [
      ...Array(11).fill(""),
      "の",
      ...Array(10).fill(""),
      "ま",
      ...Array(2).fill(""),
      "つぎ",
      "",
      "かい",
      ...Array(9).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_10.jpeg`,
    sound: "start4_scene10",
    text: "「ここが新しい階の部屋︙︙。」\nそこにはまた、一冊の本がありました。",
    ruby: [
      ...Array(4).fill(""),
      "あたら",
      ...Array(2).fill(""),
      "かい",
      "",
      "へ",
      "や",
      ...Array(12).fill(""),
      "いっ",
      "さつ",
      "",
      "ほん",
      ...Array(7).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_11.jpeg`,
    sound: "start4_scene11",
    text: "「『つなげる』って書いてあるよ」\n「次は『つなげる』のワザのスターを手に入れる冒険ってことだな！」",
    ruby: [
      ...Array(9).fill(""),
      "か",
      ...Array(8).fill(""),
      "つぎ",
      ...Array(15).fill(""),
      "て",
      "",
      "い",
      ...Array(2).fill(""),
      "ぼう",
      "けん",
      ...Array(8).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_12.jpeg`,
    sound: "start4_scene12",
    text: "ゆっくりと、「つなげる」のワザの本を開きます。",
    ruby: [...Array(16).fill(""), "ほん", "", "ひら", ...Array(4).fill("")],
  },
  {
    image: `${baseDir}/scene_13.jpeg`,
    sound: "start4_scene13_1",
    text: "気がつくと、みんな大きな本の船の上に乗っていました。あたりを見ると、",
    ruby: [
      "き",
      ...Array(8).fill(""),
      "おお",
      ...Array(2).fill(""),
      "ほん",
      "",
      "ふね",
      "",
      "うえ",
      "",
      "の",
      ...Array(11).fill(""),
      "み",
      ...Array(7).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_13.jpeg`,
    sound: "start4_scene13_2",
    text: "たくさんのシャボン玉のようなものが、ぷかぷか浮かんでいます。\nさて、「つなげる」のワザのスターを手に入れる冒険の始まりです！",
    ruby: [
      ...Array(9).fill(""),
      "だま",
      ...Array(12).fill(""),
      "う",
      ...Array(25).fill(""),
      "て",
      "",
      "い",
      ...Array(2).fill(""),
      "ぼう",
      "けん",
      "",
      "はじ",
      ...Array(5).fill(""),
    ],
  },
];
