import React from "react";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import { useGetMyCurrentRecommendQuery } from "~/generated/graphql";
import { config } from "../../../../../../config";

const MINILESSON_RECOMMEND_MESSAGE_LOCAL_STORAGE_KEY =
  "minilesson_recommend_message_";

export const replaceTextToRecommend = (
  message: string,
  recommendTitle: string
): string => {
  const beginIndex = message.indexOf("${recommend");
  const endIndex = message.indexOf("}", beginIndex);
  const defaultTitle =
    beginIndex !== -1 && message.slice(beginIndex + 13, endIndex)
      ? "『" + message.slice(beginIndex + 13, endIndex) + "』"
      : "本";
  return message.replace(
    message.slice(beginIndex, endIndex + 1),
    recommendTitle ? "『" + recommendTitle + "』" : defaultTitle
  );
};

export const useReplaceMessageToRecommend = (): {
  replaceMessageToRecommend: (message: string, id: number) => string;
} => {
  const { data } = useGetMyCurrentRecommendQuery({
    variables: {
      begin: 0,
      end: config.MID_LIST_LENGTH,
    },
    fetchPolicy: "cache-first",
  });

  const { currentUser } = React.useContext(CurrentUserContext);
  const userId = currentUser?.general.id;

  // NOTE: 3週間前よりも最近のレコメンドがあれば、そのうち最も古い回のレコメンドからランダムで表示する
  // なかった場合、最も最近のレコメンドからランダムで表示する
  const recommendTitle = React.useMemo<string>(() => {
    if (!data?.me?.recommends?.get || data.me.recommends.get.length === 0) {
      return "";
    }
    const today = new Date();

    const recentRecommends = data.me.recommends.get.filter((recommend) => {
      const whenRecommend = new Date(recommend.whenRecommend);
      const diff = today.getTime() - whenRecommend.getTime();
      const diffDays = diff / (1000 * 3600 * 24);
      return diffDays <= 21;
    });

    const targetRecommendDate =
      recentRecommends.length === 0
        ? data.me.recommends.get.reduce((prev, current) => {
            const prevDate = new Date(prev.whenRecommend);
            const currentDate = new Date(current.whenRecommend);
            return prevDate < currentDate ? prev : current;
          })
        : recentRecommends.reduce((prev, current) => {
            const prevDate = new Date(prev.whenRecommend);
            const currentDate = new Date(current.whenRecommend);
            return prevDate > currentDate ? prev : current;
          });

    const targetRecommends = data.me.recommends.get.filter((recommend) => {
      const whenRecommend = new Date(recommend.whenRecommend);
      return (
        whenRecommend.getDate() ===
        new Date(targetRecommendDate.whenRecommend).getDate()
      );
    });

    const random = Math.floor(Math.random() * targetRecommends.length);
    return targetRecommends[random].book.title || "";
  }, [data]);

  const replaceMessageToRecommend = (message: string, talkId: number) => {
    if (!message.includes("${recommend")) {
      return message;
    }
    const localStorageData = JSON.parse(
      localStorage.getItem(
        MINILESSON_RECOMMEND_MESSAGE_LOCAL_STORAGE_KEY + String(userId)
      ) || "{}"
    );
    if (localStorageData && localStorageData[talkId]) {
      return replaceTextToRecommend(message, localStorageData[talkId]);
    }
    if (data) {
      try {
        localStorage.setItem(
          MINILESSON_RECOMMEND_MESSAGE_LOCAL_STORAGE_KEY + String(userId),
          JSON.stringify({
            ...localStorageData,
            [talkId]: recommendTitle,
          })
        );
      } catch (e) {
        console.error(e);
      }

      return replaceTextToRecommend(message, recommendTitle);
    } else {
      return replaceTextToRecommend(message, "");
    }
  };
  return { replaceMessageToRecommend };
};
