import React, { FC } from "react";
import styled from "styled-components";
import { LargeButton } from "~/components/atoms/buttons/Button";
import { Icon } from "~/components/atoms/Icon";
import { useTranslation } from "~/i18n";

export type PostReadLogFooterProps = {
  handlePostReadLog: () => void;
  handleRemoveRecommend: () => void;
};

export const PostReadLogFooter: FC<PostReadLogFooterProps> = ({
  handlePostReadLog,
  handleRemoveRecommend,
}: PostReadLogFooterProps) => {
  const { t } = useTranslation();
  return (
    <_FooterWrapper>
      <_Container>
        <_FitButton onClick={handleRemoveRecommend} color="red" variant="blank">
          <Icon name="trash" color="red" />
        </_FitButton>
        <LargeButton onClick={handlePostReadLog}>
          {t<TranslateKeys>("感想を書く")}
        </LargeButton>
      </_Container>
    </_FooterWrapper>
  );
};

const _FooterWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;

  border-top: 1px solid ${({ theme }) => theme.colors.base.lightGray};

  padding: 16px 22px;

  background-color: ${({ theme }) => theme.colors.base.white};
`;

const _Container = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  column-gap: 16px;
  justify-content: center;
  margin: 0 auto;
`;

const _FitButton = styled(LargeButton)`
  width: 56px;
  min-width: 56px;
  padding: 16px 8px;
  display: flex;
  justify-content: center;
  div {
    width: fit-content;
    padding: 0px;
  }
  &:active {
    padding-bottom: 14px;
  }
`;
