import React from "react";
import { useUnlock } from "~/store/unlock/useUnlock";

type GetStarEnergyModalLockWrapperProps = {
  children: React.ReactNode;
};

export const GetStarEnergyModalLockWrapper: React.FC<
  GetStarEnergyModalLockWrapperProps
> = ({ children }) => {
  const { isLocked } = useUnlock();

  if (isLocked("bfStar")) {
    return <></>;
  } else {
    return <>{children}</>;
  }
};
