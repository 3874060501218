import React, { VFC } from "react";
import styled, { css } from "styled-components";
import { ColorTheme } from "~/styles/design_token/color";

/**
 * svg画像の色を変えたい場合などinlineのsvgでないと設定できないため、こちらに記載する
 */

type InlineSvgIconProps = {
  fill?: ColorTheme;
  style?: React.CSSProperties;
  className?: string;
};

export const RibbonIcon: VFC<InlineSvgIconProps> = (props) => {
  return (
    <StyledSvg
      {...props}
      width="22"
      height="12"
      viewBox="0 0 22 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15.9322 0.486119L12.95 2.20666C12.3514 1.87526 11.6784 1.70141 10.9943 1.70141C10.3101 1.70141 9.63716 1.87526 9.03861 2.20666L6.05633 0.486119C5.51217 0.169254 4.89403 0.00156934 4.26434 1.0962e-05C3.63465 -0.00154742 3.01569 0.163078 2.46997 0.477245C1.92425 0.791413 1.47109 1.24399 1.15623 1.78932C0.841382 2.33464 0.675993 2.9534 0.67676 3.58309V8.41209C0.677472 9.04139 0.843576 9.65945 1.15844 10.2043C1.4733 10.7492 1.92586 11.2017 2.47074 11.5166C3.01563 11.8314 3.6337 11.9975 4.26301 11.9982C4.89232 11.9989 5.51075 11.8342 6.05633 11.5205L9.49741 9.53617C10.4638 9.93324 11.5477 9.93324 12.5141 9.53617L15.9552 11.5205C16.4999 11.835 17.1178 12.0004 17.7468 12C18.3758 11.9996 18.9936 11.8334 19.5379 11.5182C20.0822 11.203 20.5338 10.75 20.8472 10.2047C21.1607 9.65936 21.3249 9.04106 21.3233 8.41209V3.58309C21.3229 2.95509 21.1572 2.33825 20.8429 1.79454C20.5287 1.25082 20.0769 0.799369 19.5329 0.485541C18.9889 0.171714 18.372 0.00656112 17.744 0.00666182C17.1159 0.00676252 16.4991 0.172117 15.9552 0.486119" />
    </StyledSvg>
  );
};

const ColorInterpolation = {
  Sub: css<{ fill?: ColorTheme }>`
    fill: ${({ theme, fill }) => {
      switch (fill) {
        case "pri":
          return theme.colors.pri.p500;
        case "sec":
          return theme.colors.sec.s500;
        case "violet":
          return theme.colors.violet.v500;
        case "green":
          return theme.colors.green.g500;
        case "red":
          return theme.colors.red.r500;
        case "sky":
          return theme.colors.sky.s500;
        case "gray":
          return theme.colors.tex.t200;
        case "tex":
          return theme.colors.tex.t500;
        case "white":
          return theme.colors.base.white;
        default:
          return theme.colors.pri.p500;
      }
    }};
  `,
};

const StyledSvg = styled.svg`
  ${ColorInterpolation.Sub}
`;
