import React, { useContext } from "react";
import {
  MissionStory,
  STORY_PAGE_SESSION_STORAGE_KEY,
} from "~/components/organisms/modals/QuestLevel/MissionStory";
import { MissionPresentationModal } from "~/components/organisms/modals/QuestLevel/MissionModal/MissionPresentationModal";
import { SenseiEncourageModal } from "~/components/organisms/modals/QuestLevel/MissionModal/SenseiEncourageModal";
import { PlayMissionStoryModal } from "~/components/organisms/modals/QuestLevel/MissionModal/PlayMissionStoryModal";
import {
  MissionOrder,
  MissionProgress,
  UserMissionTask,
} from "~/generated/graphql";
import { initAudioContext } from "~/utils/hooks/useAudio";
import { RecoverModal } from "~/components/organisms/modals/QuestLevel/MissionModal/StoryRecoverModal";
import { UnlockUserIconModal } from "~/components/organisms/modals/QuestLevel/MissionModal/UnlockUserIconModal";
import { getUnlockIcon } from "~/components/templates/CompleteMissionFlow/const";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import { LoadingPage } from "~/components/templates/Loading/LoadingPage";

type StartMissionFlowProps = {
  missionOrder: MissionOrder;
  handleStartMission: () => Promise<MissionProgress | undefined>;
  goToBookshelf: () => void;
  loading: boolean;
};

type StartMissionFlowState =
  | "PlayMissionStoryModal"
  | "MissionStory"
  | "SenseiEncourageModal"
  | "MissionPresentationModal"
  | "UnlockUserIcon";

export const StartMissionFlow: React.FC<StartMissionFlowProps> = ({
  missionOrder,
  handleStartMission,
  goToBookshelf,
  loading,
}: StartMissionFlowProps) => {
  const [flowState, setFlowState] = React.useState<StartMissionFlowState>(
    "PlayMissionStoryModal"
  );
  const [isError, setIsError] = React.useState<boolean>(false);
  const { currentUser } = useContext(CurrentUserContext);
  const nickname = currentUser?.general.nickname;

  const [userMissionTasks, setUserMissionTasks] = React.useState<
    UserMissionTask[] | undefined
  >(undefined);

  const [isOpenRecoverModal, setIsOpenRecoverModal] = React.useState<boolean>(
    Boolean(sessionStorage.getItem(STORY_PAGE_SESSION_STORAGE_KEY))
  );
  const [restartPageNumber, setRestartPageNumber] = React.useState<number>(0);

  const onClickToStartStory = React.useCallback((e: React.MouseEvent) => {
    try {
      initAudioContext(e);
    } catch {
      console.error("Failed to initialize audio context");
    }
    setFlowState("MissionStory");
  }, []);

  const onStoryCompleted = React.useCallback(() => {
    handleStartMission().then((res) => {
      if (!res) {
        setIsError(true);
      } else {
        setUserMissionTasks(res.userMissionTasks);
        setFlowState("SenseiEncourageModal");
        try {
          sessionStorage.removeItem(STORY_PAGE_SESSION_STORAGE_KEY);
        } catch (e) {
          console.log;
        }
      }
    });
  }, [handleStartMission]);

  const unlockedIcons = getUnlockIcon(missionOrder, "START");
  const pageNumber = Number(
    sessionStorage.getItem(STORY_PAGE_SESSION_STORAGE_KEY)
  );

  if (isOpenRecoverModal) {
    return (
      <RecoverModal
        onRestore={(event: React.MouseEvent) => {
          initAudioContext(event);
          setRestartPageNumber(isNaN(pageNumber) ? 0 : pageNumber);
          setIsOpenRecoverModal(false);
          setFlowState("MissionStory");
        }}
        onClose={(event: React.MouseEvent) => {
          initAudioContext(event);
          try {
            sessionStorage.removeItem(STORY_PAGE_SESSION_STORAGE_KEY);
          } catch (e) {
            console.error(e);
          }
          setIsOpenRecoverModal(false);
        }}
      />
    );
  }

  if (loading) return <LoadingPage />;

  switch (flowState) {
    case "PlayMissionStoryModal":
      return (
        <PlayMissionStoryModal
          missionOrder={missionOrder}
          storyType="Start"
          onClick={onClickToStartStory}
          isError={isError}
        />
      );
    case "MissionStory":
      return (
        <MissionStory
          missionOrder={missionOrder}
          storyType="Start"
          onCompleted={onStoryCompleted}
          startPage={restartPageNumber}
        />
      );
    case "SenseiEncourageModal":
      return (
        <SenseiEncourageModal
          onClick={() => setFlowState("MissionPresentationModal")}
          isFirstMission={missionOrder === MissionOrder.Mission_1}
        />
      );
    case "MissionPresentationModal":
      return (
        <MissionPresentationModal
          missionOrder={missionOrder}
          userMissionTasks={userMissionTasks}
          goToNext={() =>
            unlockedIcons.length > 0
              ? setFlowState("UnlockUserIcon")
              : goToBookshelf()
          }
        />
      );
    case "UnlockUserIcon":
      return (
        <UnlockUserIconModal
          unlockedIcons={unlockedIcons}
          nickname={nickname}
          onNext={goToBookshelf}
        />
      );
  }
};
