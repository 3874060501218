import React from "react";
import styled, { css } from "styled-components";
import { TextButton as _AtomTextButton } from "~/components/atoms/buttons/deprecatedTextButton";
import { Text, TextProps } from "~/components/atoms/texts/Text";
import {
  Anchorize,
  AnchorizeProps,
} from "~/components/atoms/buttons/tag_changer";

export type TextButtonProps = TextProps & {
  text: string;
  onClick: (event: React.MouseEvent) => void;
  disabled?: boolean;
};

export const TextButton: React.FC<TextButtonProps> = ({
  onClick,
  disabled,
  text,
  ...textProps
}) => {
  const { fontSize } = textProps;
  return (
    <_AtomTextButton fontSize={fontSize} onClick={onClick}>
      <StyledText {...textProps} disabled={disabled}>
        {text}
      </StyledText>
    </_AtomTextButton>
  );
};

const StyledText = styled(Text)<Pick<TextButtonProps, "disabled">>`
  ${({ disabled }) => {
    return css`
      text-align: left;
      /* max-width: 140px; */
      opacity: ${disabled ? 0.3 : 1};
      &:hover,
      &:active,
      &:focus {
        text-decoration: ${!disabled && "underline"};
      }
    `;
  }}
`;

export type TextAnchorProps = Omit<TextButtonProps, "onClick"> &
  Omit<AnchorizeProps, keyof TextButtonProps>;

export const TextAnchor: React.FC<TextAnchorProps> = ({
  text,
  disabled,
  ...anchorProps
}) => (
  <Anchorize {...anchorProps} disabled={disabled}>
    <TextButton
      text={text}
      disabled={disabled}
      onClick={() => {
        return;
      }}
      {...anchorProps}
    />
  </Anchorize>
);
