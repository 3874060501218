import React from "react";
import styled from "styled-components";
import { Message } from "~/components/molecules/Message";
import { LibraryModalWrapper } from "../../../ui/LibraryModalWrapper";
import { useTranslation } from "~/i18n";

export type ThanksForAnsweringProps = {
  onNext: () => void;
  nickname: string;
};

export const ThanksForAnswering: React.FC<ThanksForAnsweringProps> = ({
  onNext,
  nickname,
}) => {
  const { t } = useTranslation();

  return (
    <LibraryModalWrapper
      isBright={true}
      showSensei={true}
      senseiImageType="HEART"
      heartNum={7}
    >
      <_MessageWrapper>
        <Message
          onNext={onNext}
          message={t<TranslateKeys>(
            "たくさん質問に答えてくれてありがとう！\n{{ nickname }}さんのこと、たくさん知れて嬉しいな！",
            { nickname }
          )}
          speaker={t<TranslateKeys>("ヨンデミー先生")}
        />
      </_MessageWrapper>
    </LibraryModalWrapper>
  );
};

const _MessageWrapper = styled.div`
  position: absolute;
  bottom: 15%;
  width: 100%;

  display: flex;
  justify-content: center;
`;
