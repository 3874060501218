import React from "react";
import styled from "styled-components";
import { Text } from "~/components/atoms/texts/Text";
import { useTranslation } from "~/i18n";

export type BookCoverProps = {
  username: string;
  onClick?: () => void;
};

export const BookCover: React.FC<BookCoverProps> = ({ username, onClick }) => {
  const { t } = useTranslation();
  return (
    <_Wrapper onClick={onClick}>
      <_NameContainer>
        <_Text
          lineHeight="MD"
          fontSize="XL"
          bold
          fontColor="primitive.wood.w500"
        >
          {t<TranslateKeys>("{{ userName }}の書", { userName: username })}
        </_Text>
      </_NameContainer>
    </_Wrapper>
  );
};

const _Wrapper = styled.div`
  width: calc(56vh + 26px);
  max-width: 100vw;
  height: 100%;
  background-image: url("/img/record_book/book_cover.png");
  background-size: cover;
  background-position: center center;

  display: flex;
  align-items: center;
`;

const _NameContainer = styled.div`
  width: 100%;
  padding: 8px 12px;
  background-image: url("/img/record_book/book_cover_paper.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  row-gap: 8px;
  align-items: center;

  ::before,
  ::after {
    content: "";
    width: 100%;
    height: 0;
    border: 1px solid ${({ theme }) => theme.colors.primitive.wood.w500};
  }
`;

const _Text = styled(Text)`
  text-align: center;
  width: 192px;
`;
