import React, { VFC } from "react";
import styled, { useTheme } from "styled-components";
import { Text } from "~/components/atoms/texts/Text";
import { Icon } from "~/components/atoms/Icon";
import { Linkerize } from "~/components/atoms/buttons/tag_changer";

export type MypageListItemProps = {
  title: string;
  description?: string;
  subtitle?: string;
  subtitleDescription?: string;
  to: string;
  isFirst: boolean;
  isLast: boolean;
};

export const MypageListItem: VFC<MypageListItemProps> = ({
  title,
  description,
  subtitle,
  subtitleDescription,
  isFirst,
  isLast,
  to,
}) => {
  const theme = useTheme();
  const hasDescription = !!description;
  const hasSubtitle = !!subtitle;

  return (
    <Linkerize to={to}>
      <ListItemButton
        isFirst={isFirst}
        isLast={isLast}
        hasDescription={hasDescription}
      >
        <ListItem hasDescription={hasDescription} hasSubtitle={hasSubtitle}>
          <ListItemIitleWrapper>
            <ListItemIitle
              fontSize={hasSubtitle ? "XS" : "SM"}
              lineHeight="MD"
              hasSubtitle={hasSubtitle}
            >
              {title}
            </ListItemIitle>
          </ListItemIitleWrapper>
          {subtitle && (
            <ListItemSubtitleWrapper>
              <ListItemSubtitle fontSize="SM" lineHeight="MD">
                {subtitle}
              </ListItemSubtitle>
              {subtitleDescription && (
                <ListItemSubtitleDescription fontSize="XS" lineHeight="MD">
                  {subtitleDescription}
                </ListItemSubtitleDescription>
              )}
            </ListItemSubtitleWrapper>
          )}
          <Icon
            name={"chevronRight"}
            style={{
              color: theme.colors.sec.s400,
              height: theme.fontSize["MD"],
              width: "auto",
              alignSelf: "center",
            }}
          />
        </ListItem>
        {description && (
          <ListItemDescription fontSize="XS" lineHeight="MD">
            {description}
          </ListItemDescription>
        )}
      </ListItemButton>
    </Linkerize>
  );
};

type ListItemButtonProps = {
  isFirst: boolean;
  isLast: boolean;
  hasDescription: boolean;
};

type ListItemProps = {
  hasDescription: boolean;
  hasSubtitle: boolean;
};

const ListItemButton = styled.button<ListItemButtonProps>`
  border: none;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.base.white};
  border-radius: ${({
    theme: {
      deprecatedBorderRadius: { MD },
    },
    isFirst,
    isLast,
  }) => {
    if (isFirst && isLast) {
      return `${MD} ${MD} ${MD} ${MD}`;
    } else if (isFirst && !isLast) {
      return `${MD} ${MD} 0 0`;
    } else if (!isFirst && isLast) {
      return `0 0 ${MD} ${MD}`;
    } else {
      return "none";
    }
  }};
  padding-bottom: ${({ hasDescription }) => hasDescription && "16px"};
`;

const ListItem = styled.div<ListItemProps>`
  display: grid;
  grid-template-columns: ${({ hasSubtitle }) =>
    hasSubtitle ? "120px 1fr 24px" : "1fr 24px"};
  justify-items: flex-end;
  background-color: ${({ theme }) => theme.colors.base.white};
  padding: ${({ hasDescription }) => (hasDescription ? "16px 16px 0" : "16px")};
  border-radius: ${({ theme }) => theme.deprecatedBorderRadius.MD};
`;

const ListItemIitleWrapper = styled.div`
  justify-self: flex-start;
  align-self: center;
  text-align: left;
`;

const ListItemIitle = styled(Text)<Pick<ListItemProps, "hasSubtitle">>`
  color: ${({ theme, hasSubtitle }) =>
    hasSubtitle ? theme.colors.tex.t300 : theme.colors.tex.t500};
  font-weight: ${({ hasSubtitle }) => (hasSubtitle ? "normal" : "bold")};
`;

const ListItemDescription = styled(Text)`
  color: ${({ theme }) => theme.colors.tex.t300};
  margin: 4px 48px 0 16px;
  text-align: left;
`;

const ListItemSubtitleDescription = styled(Text)`
  color: ${({ theme }) => theme.colors.tex.t300};
  margin-top: 4px;
  text-align: left;
`;

const ListItemSubtitleWrapper = styled.div`
  justify-self: flex-start;
`;

const ListItemSubtitle = styled(Text)`
  color: ${({ theme }) => theme.colors.tex.t500};
  justify-self: flex-start;
  font-weight: bold;
`;
