import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { MissionStory } from "~/components/organisms/modals/QuestLevel/MissionStory";
import { YondemyStory } from "~/components/organisms/modals/QuestLevel/YondemyStory";
import { LoadingSection } from "~/components/templates/Loading/LoadingSection";
import { VideoArchiveRecordTemplate } from "~/components/templates/RecordBook/VideoArchive";
import {
  useGetMissionProgressQuery,
  MissionOrder,
  MissionState,
  MissionProgress,
} from "~/generated/graphql";
import { initAudioContext } from "~/utils/hooks/useAudio";

type ScreenType = "default" | "missionStory" | "yondemyStory";

const missionList = (missionProgress: MissionProgress): MissionOrder[] => {
  const _missionOrderList: MissionOrder[] = Object.keys(MissionOrder).map(
    (missionOrder) => {
      return MissionOrder[missionOrder];
    }
  );
  const index = missionProgress.missionOrder
    ? _missionOrderList.indexOf(missionProgress.missionOrder)
    : -1;

  console.log(missionProgress);

  switch (missionProgress.missionState) {
    case MissionState.BeforeFirstMission:
      return [];
    case MissionState.WaitingForNextMission:
      if (index === -1) {
        return [];
      } else {
        return _missionOrderList.slice(0, index);
      }
    case MissionState.NeedStart:
      if (index === -1) {
        return [];
      } else {
        return _missionOrderList.slice(0, index);
      }
    case MissionState.InProgress:
      if (index === -1) {
        return [];
      } else {
        return _missionOrderList.slice(0, index);
      }
    case MissionState.NeedComplete:
      if (index === -1) {
        return [];
      } else {
        return _missionOrderList.slice(0, index + 1);
      }
    case MissionState.AllOver:
      if (index === -1) {
        return [];
      } else {
        return _missionOrderList.slice(0, index + 1);
      }
    default:
      return [];
  }
};

export const VideoArchiveRecord: React.FC = () => {
  const { data, loading, error } = useGetMissionProgressQuery({});
  const navigate = useNavigate();
  const [screenType, setScreenType] = React.useState<ScreenType>("default");
  const [missionOrder, setMissionOrder] = React.useState<
    MissionOrder | undefined
  >(undefined);

  const onClickToStory = (e: React.MouseEvent, missionOrder: MissionOrder) => {
    setScreenType("missionStory");
    setMissionOrder(missionOrder);
    initAudioContext(e);
  };

  if (loading || !data) {
    return <LoadingSection />;
  }
  if (error) return <>error!{error.message}</>;

  if (screenType === "missionStory" && missionOrder) {
    return (
      <_ModalWrapper>
        <MissionStory
          missionOrder={missionOrder}
          storyType="All"
          onCompleted={() => {
            setScreenType("default");
            setMissionOrder(undefined);
          }}
        />
      </_ModalWrapper>
    );
  } else if (screenType === "yondemyStory") {
    return (
      <_ModalWrapper>
        <YondemyStory onCompleted={() => setScreenType("default")} />
      </_ModalWrapper>
    );
  }

  return (
    <VideoArchiveRecordTemplate
      missionList={missionList(data.getMissionProgress)}
      onClickToStory={onClickToStory}
      onClickToYondemyStory={() => setScreenType("yondemyStory")}
      onBack={() => navigate("/")}
    />
  );
};

const _ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zIndex.modal};
`;
