import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Message } from "~/components/molecules/Message";
import { TranslateKeys } from "~/i18n";
import {
  CONTENT_WIDTH,
  CONTENT_HEIGHT,
  useScale,
} from "~/utils/hooks/useScale";

export type SenseiEncourageModalProps = {
  onClick: () => void;
  isFirstMission: boolean;
};

export const SenseiEncourageModal: React.FC<SenseiEncourageModalProps> = ({
  onClick,
  isFirstMission,
}) => {
  const scale = useScale();
  const { t } = useTranslation();

  const message = isFirstMission
    ? "それじゃあ、さっそく" +
      t<TranslateKeys>("伝説の読書家") +
      "を" +
      t<TranslateKeys>("目指して") +
      "、まずはこのヨンデミッションに" +
      t<TranslateKeys>("挑戦") +
      "だ！"
    : "それじゃあ、" +
      t<TranslateKeys>("伝説の読書家") +
      "を" +
      t<TranslateKeys>("目指して") +
      "、つぎはこのヨンデミッションに" +
      t<TranslateKeys>("挑戦") +
      "だ！";

  return (
    <_Wrapper>
      <_ConversationWrapper>
        <_Container style={{ scale: `${scale}` }}>
          <_Images overlay={false}>
            <_SenseiImage src="/img/sensei/standup_4.svg" height="auto" />
            <_TableImage src="/img/initialize_modal/bright_table.png" />
          </_Images>
          <_MessageWrapper>
            <Message
              message={message}
              speaker="ヨンデミー先生"
              onNext={onClick}
            />
          </_MessageWrapper>
        </_Container>
      </_ConversationWrapper>
    </_Wrapper>
  );
};

const _Wrapper = styled.div`
  :hover {
    cursor: pointer;
  }
`;

const _MessageWrapper = styled.div`
  position: absolute;
  bottom: 15%;
  width: 100%;

  display: flex;
  justify-content: center;
`;

const _ConversationWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  max-height: 100svh;
  background-image: url("/img/initialize_modal/bookshelf.png");

  background-repeat: repeat-x;
  background-size: contain;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const _Container = styled.div`
  position: relative;

  transform-origin: top 50%;

  width: ${CONTENT_WIDTH}px;
  height: ${CONTENT_HEIGHT}px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`;

const _Images = styled.div<{ overlay?: boolean }>`
  filter: ${({ overlay }) => (overlay ? "brightness(0.5)" : "none")};

  text-align: center;
`;

const _SenseiImage = styled.img`
  width: calc(${CONTENT_WIDTH}px * 0.66);
  margin-bottom: -30%;
`;

const _TableImage = styled.img`
  width: ${CONTENT_WIDTH}px;
`;
