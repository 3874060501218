import React, { FC } from "react";
import styled from "styled-components";
import { ConfirmReadStatusTemplateProps } from "./type";
import { EmojiButton } from "~/components/molecules/buttons/EmojiButton";
import { ProgressNavigation } from "~/components/organisms/ProgressNavigation";
import { NavContainer, Wrapper } from "../../Common";
import {
  postReadLogEmojiButtonStyle,
  PostReadLogHeading,
  PostReadLogBodyWrapper,
  PostReadLogMarginInterpolation,
  PostReadLogCharacterImg,
  PostReadLogBarWrapper,
} from "../Common/style";
import { useTranslation } from "~/i18n";
import { CheckBoxWithLabel } from "~/components/molecules/checkboxWithLabel";

export const ConfirmReadStatusTemplate: FC<ConfirmReadStatusTemplateProps> = ({
  progress,
  handleBack,
  onClicks,
  onClickYomikikase,
  yomikikase,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <NavContainer>
        <PostReadLogBarWrapper>
          <ProgressNavigation
            progress={progress}
            handleBack={handleBack}
            buttonsPattern={"single"}
          />
        </PostReadLogBarWrapper>
      </NavContainer>
      <Wrapper>
        <PostReadLogBodyWrapper>
          <HeadingCon>
            <PostReadLogHeading fontSize={"LG"} lineHeight={"MD"} bold>
              {t<TranslateKeys>("どれくらいまで読んだ？")}
            </PostReadLogHeading>
          </HeadingCon>
          <CharacterCon>
            <PostReadLogCharacterImg src="/img/sensei/standup_1.svg" />
          </CharacterCon>
          <ButtonCon>
            <EmojiButton
              {...postReadLogEmojiButtonStyle}
              emoji={"🔖"}
              text={t<TranslateKeys>("とちゅうまで")}
              onClick={onClicks[0]}
            />
            <EmojiButton
              {...postReadLogEmojiButtonStyle}
              emoji={"📘"}
              text={t<TranslateKeys>("最後まで")}
              onClick={onClicks[1]}
            />
          </ButtonCon>
          <YomikikaseCon>
            <CheckBoxWrapper>
              <CheckBoxWithLabel
                label={t<TranslateKeys>("読み聞かせで読んだ")}
                onClick={onClickYomikikase}
                isChecked={yomikikase}
                bold
                size="LG"
              />
            </CheckBoxWrapper>
          </YomikikaseCon>
        </PostReadLogBodyWrapper>
      </Wrapper>
    </>
  );
};

const CharacterCon = styled.div`
  ${PostReadLogMarginInterpolation.Wide}
  display: flex;
  justify-content: center;
`;

const HeadingCon = styled.div``;

const ButtonCon = styled.div`
  ${PostReadLogMarginInterpolation.Wide}
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & > * {
    margin: ${({ theme }) => theme.size.XS} 0;
  }
`;

const YomikikaseCon = styled.div`
  ${PostReadLogMarginInterpolation.Wide}
`;

const CheckBoxWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
