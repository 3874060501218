import { DefaultTheme } from "styled-components";

export type ButtonColorValue = {
  background: string;
  border: string;
  text: string;

  focusBackground: string;
  focusBorder: string;
  focusText?: string;

  activeBackground: string;
  activeBorder?: string;
  activeText?: string;

  blankBackground: string;
  blankBorder: string;
  blankText?: string;
  blankFocusBorder: string;
  blankFocusText?: string;
  blankActiveBorder: string;
  blankActiveText?: string;
};

export type ButtonColor =
  | "primary"
  | "secondary"
  | "tertiary"
  | "green"
  | "red"
  | "sky"
  | "violet"
  | "gray"
  | "line";

export const buttonColors = (
  theme: DefaultTheme
): Record<ButtonColor, ButtonColorValue> => {
  const { primitive, semantic } = theme.colors;

  return {
    primary: {
      background: semantic.primary.minus1,
      border: semantic.primary.plus1,
      text: semantic.layout.backgroundBox,
      focusBackground: semantic.primary.main,
      focusBorder: semantic.primary.plus1,
      activeBackground: semantic.primary.plus1,
      blankBackground: semantic.layout.backgroundBox,
      blankBorder: semantic.primary.minus1,
      blankFocusBorder: semantic.primary.main,
      blankActiveBorder: semantic.primary.main,
    },

    // FIXME: 埋める
    secondary: {
      background: semantic.secondary.main,
      border: semantic.secondary.plus2,
      text: semantic.layout.backgroundBox,
      focusBackground: semantic.secondary.plus1,
      focusBorder: semantic.secondary.plus2,
      activeBackground: semantic.secondary.plus2,
      blankBackground: semantic.layout.backgroundBox,
      blankBorder: semantic.secondary.main,
      blankFocusBorder: semantic.secondary.plus1,
      blankActiveBorder: semantic.secondary.plus1,
    },
    tertiary: {
      background: primitive.monotone.m0,
      border: semantic.layout.borderPlus1,
      text: semantic.text.body,
      focusBackground: primitive.monotone.m20,
      focusBorder: semantic.layout.borderPlus1,
      focusText: semantic.text.heading,
      activeBackground: primitive.monotone.m200,
      activeBorder: semantic.layout.borderPlus1,
      activeText: semantic.text.heading,
      blankBackground: primitive.monotone.m0,
      blankBorder: semantic.layout.borderPlus1,
      blankFocusBorder: semantic.layout.borderPlus1,
      blankActiveBorder: semantic.layout.borderPlus1,
    },
    green: {
      background: primitive.green.g400,
      border: primitive.green.g600,
      text: semantic.layout.backgroundBox,
      focusBackground: primitive.green.g500,
      focusBorder: primitive.green.g600,
      activeBackground: primitive.green.g600,
      blankBackground: semantic.layout.backgroundBox,
      blankBorder: primitive.green.g400,
      blankFocusBorder: primitive.green.g500,
      blankActiveBorder: primitive.green.g500,
    },
    red: {
      background: primitive.red.r400,
      border: primitive.red.r600,
      text: semantic.layout.backgroundBox,
      focusBackground: primitive.red.r500,
      focusBorder: primitive.red.r600,
      activeBackground: primitive.red.r600,
      blankBackground: semantic.layout.backgroundBox,
      blankBorder: primitive.red.r400,
      blankFocusBorder: primitive.red.r500,
      blankActiveBorder: primitive.red.r500,
    },
    sky: {
      background: primitive.sky.s400,
      border: primitive.sky.s600,
      text: semantic.layout.backgroundBox,
      focusBackground: primitive.sky.s500,
      focusBorder: primitive.sky.s600,
      activeBackground: primitive.sky.s600,
      blankBackground: semantic.layout.backgroundBox,
      blankBorder: primitive.sky.s400,
      blankFocusBorder: primitive.sky.s500,
      blankActiveBorder: primitive.sky.s500,
    },
    violet: {
      background: primitive.violet.v400,
      border: primitive.violet.v600,
      text: semantic.layout.backgroundBox,
      focusBackground: primitive.violet.v500,
      focusBorder: primitive.violet.v600,
      activeBackground: primitive.violet.v600,
      blankBackground: semantic.layout.backgroundBox,
      blankBorder: primitive.violet.v400,
      blankFocusBorder: primitive.violet.v500,
      blankActiveBorder: primitive.violet.v500,
    },
    gray: {
      background: semantic.layout.border,
      border: semantic.layout.borderPlus1,
      text: semantic.primary.minus1,
      focusBackground: semantic.layout.border,
      focusBorder: semantic.layout.borderPlus1,
      activeBackground: semantic.layout.borderPlus1,
      blankBackground: semantic.layout.backgroundBox,
      blankBorder: semantic.layout.border,
      blankText: semantic.primary.main,
      blankFocusBorder: semantic.layout.borderPlus1,
      blankFocusText: semantic.primary.main,
      blankActiveBorder: semantic.layout.borderPlus1,
      blankActiveText: semantic.primary.main,
    },
    line: {
      background: primitive.line.line400,
      border: primitive.line.line600,
      text: semantic.layout.backgroundBox,
      focusBackground: primitive.line.line500,
      focusBorder: primitive.line.line600,
      activeBackground: primitive.line.line600,
      blankBackground: semantic.layout.backgroundBox,
      blankBorder: primitive.line.line400,
      blankFocusBorder: primitive.line.line500,
      blankActiveBorder: primitive.line.line500,
    },
  };
};
