import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Loading } from "~/components/atoms/Loading";

export type ImagePreloadProps = {
  images: readonly string[];
  onCompleted: () => void;
  children?: React.ReactNode;
};

export const ImagePreload: React.FC<ImagePreloadProps> = ({
  images,
  onCompleted,
  children,
}) => {
  const [loaded, setLoaded] = useState<Record<string, boolean>>({});
  const preloadRef = useRef<HTMLDivElement>(null);
  const [, setAllImagesLoaded] = React.useState<boolean>(false);

  React.useEffect(() => {
    const _keys = Object.keys(loaded);
    const _allImagesLoaded =
      _keys.length > 0 &&
      _keys.length === Object.values(loaded).filter((v) => v).length;

    setAllImagesLoaded((_prev) => {
      if (!_prev && _allImagesLoaded) {
        onCompleted();
      }
      return _allImagesLoaded;
    });
  }, [loaded, onCompleted]);

  useEffect(() => {
    [...images].forEach((src) => {
      const img = document.createElement("img");
      img.onload = () => {
        setLoaded((l) => ({ ...l, [src]: true }));
      };
      img.src = src;
      setLoaded((l) => ({ ...l, [src]: false }));
      preloadRef.current && preloadRef.current.appendChild(img);
    });
  }, []);

  return (
    <>
      {children || (
        <_LoadingContainer>
          <Loading />
        </_LoadingContainer>
      )}
      <_Preload ref={preloadRef} />
    </>
  );
};

const _LoadingContainer = styled.div`
  height: 100vh;
  width: 100vw;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const _Preload = styled.div`
  display: none;
`;
