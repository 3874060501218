import React from "react";
import { useNavigate } from "react-router-dom";
import { Result } from "~/components/organisms/BookSelectionAdjustment/CharacterAssessment/Result";
import { SuitableYlNotFound } from "~/components/organisms/BookSelectionAdjustment/ui/SuitableYlNotFound";
import {
  CharacterType,
  useGetSuitableYlResultQuery,
} from "~/generated/graphql";

export const CharacterAssessmentResult: React.FC = () => {
  const navigate = useNavigate();

  const characterType = sessionStorage.getItem(
    "characterType"
  ) as CharacterType;

  const { data: queryData } = useGetSuitableYlResultQuery({
    fetchPolicy: "network-only",
  });

  const suitableYL =
    queryData?.getSuitableYL.__typename === "SuitableYL"
      ? queryData.getSuitableYL.value
      : 25;

  const isValidCharacterType =
    Object.values(CharacterType).includes(characterType);

  if (queryData?.getSuitableYL.__typename === "ErrorSuitableYLNotFound") {
    return (
      <SuitableYlNotFound
        enqueteType="character_assessment"
        onQuit={() => navigate("/")}
      />
    );
  }

  return (
    <>
      {isValidCharacterType ? (
        <Result
          characterType={characterType}
          suitableYL={suitableYL}
          onClose={() => navigate("/")}
        />
      ) : (
        navigate("/")
      )}
    </>
  );
};
