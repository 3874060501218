export const ALL_BUDGES = {
  maxContinueReadLogDays: {
    IMAGE_FILE_NAME_PREFIX: "day",
    THRESHOLDS: [
      2, 3, 5, 7, 10, 14, 20, 25, 30, 35, 40, 50, 60, 70, 80, 90, 100, 120, 150,
      200, 300, 400, 500,
    ],
  },
  readBookSum: {
    IMAGE_FILE_NAME_PREFIX: "book",
    THRESHOLDS: [
      1, 3, 6, 10, 15, 20, 25, 30, 35, 40, 50, 60, 70, 80, 90, 100, 110, 120,
      150, 200, 300, 400, 500,
    ],
  },
  readCharSum: {
    IMAGE_FILE_NAME_PREFIX: "char",
    THRESHOLDS: [
      1500, 5000, 10000, 20000, 30000, 50000, 75000, 100000, 200000, 300000,
      400000, 500000, 600000, 700000, 800000, 900000, 1000000, 1500000, 2000000,
      3000000, 4000000, 5000000,
    ],
  },
};

export type AllBadgeType = keyof typeof ALL_BUDGES;

export type BadgeItem = {
  /**
   * イメージpath
   */
  imgSrc: string;
  /**
   * バッジを取得しているかどうか
   */
  isAcquired: boolean;
};
