import React from "react";
import styled from "styled-components";
import { Text } from "~/components/atoms/texts/Text";
import { IconTextButton } from "~/components/molecules/buttons/IconTextButton";

type GetNextRecommendAlertProps = {
  onClose: () => void;
};

export const GetNextRecommendAlert: React.FC<GetNextRecommendAlertProps> = ({
  onClose,
}: GetNextRecommendAlertProps) => {
  return (
    <_Wrapper>
      <Text
        lineHeight="MD"
        fontSize="SM"
        bold={false}
        fontColor="semantic.text.body"
      >
        <b>
          ・難しさや内容が明らかに合っていない
          <br />
          ・手に入らなかった
          <br />
          ・以前読んだことがある
        </b>
        <br />
        という場合は、本をおすすめの本リストから削除すると、次のおすすめ本がもらえます。
      </Text>
      <IconTextButton
        name="close"
        iconPlace="pre"
        color="tex"
        text="次回から表示しない"
        fontSize="XS"
        lineHeight="MD"
        onClick={onClose}
        bold
      />
    </_Wrapper>
  );
};

const _Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 8px;

  border: 2px dashed ${({ theme }) => theme.colors.base.gray};
  border-radius: ${({ theme }) => theme.deprecatedBorderRadius.MD};
  background-color: ${({ theme }) =>
    theme.colors.semantic.layout.backgroundBox};
`;
