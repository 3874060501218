import * as React from "react";
import { Stack } from "~/components/atoms/layout/Stack";
import styled from "styled-components";
import { UserAvailability } from "~/generated/graphql";
import { Wrapper } from "~/components/templates/Common";
import { Heading2 } from "~/components/atoms/texts/Heading2";
import { UserAvailabilityRow } from "./UserAccountRow";
import { PrimaryButton } from "~/components/molecules/buttons/PrimaryButton";
import { TextAnchor } from "~/components/molecules/buttons/TextButton";
import { config } from "~/../config";
import { OfficialLineLink } from "../../../../../util/constant";

export type SwitchUserTemplateProps = {
  userAvailabilities: UserAvailability[];
  onLogin: (userId: number) => void;
};
export const SwitchUserTemplate: React.FC<SwitchUserTemplateProps> = ({
  userAvailabilities,
  onLogin,
}) => {
  const [selectedUserId, setSelectedUserId] = React.useState<
    number | undefined
  >(undefined);

  const canSelectUser = React.useMemo(
    () =>
      userAvailabilities.some((account) => account.userPlan !== "INVALIDATION"),
    [userAvailabilities]
  );

  // FIXME: 仮実装
  return (
    <_Container>
      <_AllWrapper rowGap="40px" alignItems="center">
        <_HeaderAndBoxWrapper rowGap="24px" alignItems="flex-start">
          <Heading2
            fontSize="XL"
            lineHeight="EQ"
            color="semantic.text.heading"
            bold
          >
            {canSelectUser
              ? "アカウントを選んでログイン"
              : "休会中のアカウントです"}
          </Heading2>
          <_Box>
            <Stack rowGap="8px">
              {userAvailabilities?.map((account, index) => {
                return (
                  <>
                    <UserAvailabilityRow
                      isInvalid={account.userPlan === "INVALIDATION"}
                      selected={account.userID === selectedUserId}
                      iconType={account.userIconType}
                      userName={account.nickname}
                      onClick={() => {
                        setSelectedUserId(account.userID);
                      }}
                    />
                    {index !== userAvailabilities.length - 1 && <_Border />}
                  </>
                );
              })}
            </Stack>
          </_Box>
          {canSelectUser && (
            <PrimaryButton
              fontSize="MD"
              lineHeight="LG"
              onClick={() => onLogin(selectedUserId as number)}
              color="pri"
              text="選んだアカウントでログインする"
              disabled={selectedUserId === undefined}
              bold
            />
          )}
        </_HeaderAndBoxWrapper>
        <_LinkWrapper alignItems="center" rowGap="12px">
          <TextAnchor
            href={`${config.PARENT_APP}`}
            fontSize="SM"
            lineHeight="EQ"
            text="休会中のアカウントを復会したい方はこちら"
            fontColor="semantic.secondary.main"
          />
          {!canSelectUser && (
            <TextAnchor
              href={`${OfficialLineLink}`}
              fontSize="SM"
              lineHeight="EQ"
              text="その他お問い合わせはこちらから"
              fontColor="semantic.secondary.main"
            />
          )}
        </_LinkWrapper>
      </_AllWrapper>
    </_Container>
  );
};

const _Box = styled.div`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.s2};
  padding: ${({ theme }) => theme.size.s2};
  background-color: ${({ theme }) =>
    theme.colors.semantic.layout.backgroundBox};
`;

const _Border = styled.hr`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.semantic.layout.border};
  margin: 0;
  height: 2px;
  border: 0;
`;

const _AllWrapper = styled(Stack)`
  width: 100%;
  max-width: 600px;
`;

const _HeaderAndBoxWrapper = styled(Stack)`
  width: 100%;
`;

const _Container = styled(Wrapper)`
  padding-top: ${({ theme }) => theme.size.s4};
`;

const _LinkWrapper = styled(Stack)`
  width: 100%;
`;
