import React from "react";
import styled from "styled-components";
import { Stack } from "~/components/atoms/layout/Stack";
import { Text } from "~/components/atoms/texts/Text";
import { BottomModal } from "~/components/atoms/wrappers/BottomModal";
import { PrimaryButton } from "~/components/molecules/buttons/PrimaryButton";

export type SenseiRemarkWithTwoButtonsModalProps = {
  isOpen: boolean;
  message: string;
  firstButtonText: string;
  secondButtonText: string;
  onClickFirstButton: () => void;
  onClickSecondButton: () => void;
  imgSrc: string;
};

export const SenseiRemarkWithTwoButtonsModal: React.FC<
  SenseiRemarkWithTwoButtonsModalProps
> = ({
  isOpen,
  message,
  firstButtonText,
  secondButtonText,
  onClickFirstButton,
  onClickSecondButton,
  imgSrc,
}) => {
  return (
    <BottomModal open={isOpen}>
      <_ContainerWrapper>
        <_Container>
          <_Wrapper>
            <_TextWrapper>
              <Text
                lineHeight="MD"
                fontSize="MD"
                bold={false}
                whiteSpace="pre-wrap"
              >
                {message}
              </Text>
            </_TextWrapper>
            <_ButtonStack rowGap="16px">
              <PrimaryButton
                onClick={onClickFirstButton}
                color="pri"
                text={firstButtonText}
                fontSize="MD"
                lineHeight="EQ"
                bold={true}
                padding="14px 16px"
              />
              <PrimaryButton
                onClick={onClickSecondButton}
                color="pri"
                text={secondButtonText}
                fontSize="MD"
                lineHeight="EQ"
                bold={true}
                padding="14px 16px"
              />
            </_ButtonStack>
          </_Wrapper>
          <div
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
          >
            <svg
              width="17"
              height="12"
              viewBox="0 0 17 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <TrianglePath d="M11.1101 10.394C9.9609 12.4219 7.0391 12.4219 5.88993 10.394L-2.19324e-07 -6.5958e-07L17 -2.14577e-06L11.1101 10.394Z" />
            </svg>
          </div>
        </_Container>
        <_ImgWrapper>
          <img
            src={imgSrc}
            alt={"ヨンデミー先生です"}
            style={{ height: "100%" }}
          />
        </_ImgWrapper>
      </_ContainerWrapper>
    </BottomModal>
  );
};

const _ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  padding-bottom: 16px;
`;

const _Container = styled.div`
  @media screen and ${({ theme }) => theme.breakPoints.maxWidthSmallPC} {
    width: 50vw;
    bottom: 26vh;
  }
  @media screen and ${({ theme }) => theme.breakPoints.maxWidthSmartPhone} {
    width: 70vw;
    bottom: 24vh;
  }
  @media screen and ${({ theme }) =>
      theme.breakPoints.maxWidthSmallSmartPhone} {
    width: 90vw;
    bottom: 20vh;
  }
  @media screen and ${({ theme }) => theme.breakPoints.minWidthSmallPC} {
    width: 400px;
    bottom: 28vh;
  }
`;

const _Wrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) =>
    theme.colors.semantic.layout.backgroundBox};
  border-radius: 16px;
  padding: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

const _TextWrapper = styled.div`
  width: 100%;
`;

const _ButtonStack = styled(Stack)`
  width: 100%;
`;

const TrianglePath = styled.path`
  fill: ${({ theme }) => theme.colors.semantic.layout.backgroundBox};
  transform: translateY(-1px);
`;

const _ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  @media screen and ${({ theme }) => theme.breakPoints.maxWidthSmallPC} {
    height: 22vh;
    bottom: 2vh;
  }
  @media screen and ${({ theme }) => theme.breakPoints.maxWidthSmartPhone} {
    height: 20vh;
    bottom: 2vh;
  }
  @media screen and ${({ theme }) =>
      theme.breakPoints.maxWidthSmallSmartPhone} {
    height: 16vh;
    bottom: 2vh;
  }
  @media screen and ${({ theme }) => theme.breakPoints.minWidthSmallPC} {
    height: 24vh;
    bottom: 2vh;
  }
`;
