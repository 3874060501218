import React from "react";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import { OnboardingBaseModal } from "../OnboardingBaseModal";
import { endingTutorialImages } from "~/pages/InitialTutorial/constants";

export type EndingConversationProps = {
  onClickToMinilesson: (e: React.MouseEvent) => void;
};

type Flow = {
  message: string;
  imgSrc: string;
};

const getFlows = (nickname: string): Flow[] => [
  {
    message: "いのちが ふきこまれて、\nほんを たすけられるよ！",
    imgSrc: endingTutorialImages.standUpNormal,
  },
  {
    message: "そういえばひとつ、\nだいじなことを いいわすれてた！",
    imgSrc: endingTutorialImages.standUpNotice,
  },
  {
    message:
      "じつは、\nとしょかんにつたわる\n「どくしょのひみつ」\nがあるんだ……🤫",
    imgSrc: endingTutorialImages.standUpNotice,
  },
  {
    message: `《どくしょかのしょ》に\nえらばれた、\n${nickname}さんには\nとくべつにおしえちゃうね！`,
    imgSrc: endingTutorialImages.standUpSmile,
  },
  {
    message:
      "でもここだと\nだれかにきかれちゃうから、\nヨンデミー先生のへやに\nきてね！",
    imgSrc: endingTutorialImages.standUpSmile,
  },
];

export const EndingConversation: React.FC<EndingConversationProps> = ({
  onClickToMinilesson,
}) => {
  const [index, setIndex] = React.useState<number>(0);

  const { currentUser } = React.useContext(CurrentUserContext);
  const nickname = currentUser?.general.nickname || "";

  const flows: Flow[] = React.useMemo(() => {
    return getFlows(nickname);
  }, [nickname]);
  const flow = flows[index];

  const toNext = React.useCallback(
    (e: React.MouseEvent) => {
      if (index === flows.length - 1) {
        onClickToMinilesson(e);
      } else {
        setIndex((i) => i + 1);
      }
    },
    [index, flows]
  );

  return (
    <>
      <OnboardingBaseModal
        onClick={toNext}
        imgSrc={flow.imgSrc}
        message={flow.message}
      />
    </>
  );
};
