import React from "react";
import { characterIdentifyEnquete } from "./const";
import { CharacterEnqueteUI } from "./ui";
import {
  AnswerCharacterEnqueteInput,
  CharacterEnqueteChoice,
} from "~/generated/graphql";
import { TypeQuestionAndAnswer } from "./const";
import { calculateResultOfChoices, choiceAndCharacterRecord } from "./const";
import { LoadingPage } from "~/components/templates/Loading/LoadingPage";
export type CharacterEnqueteProps = {
  typeQuestionAndAnswers: TypeQuestionAndAnswer[];
  onSubmit: (input: AnswerCharacterEnqueteInput) => Promise<boolean>;
  onQuit: () => void;
  onBack: () => void;
  loading: boolean;
};

export const CharacterEnquete: React.FC<CharacterEnqueteProps> = ({
  typeQuestionAndAnswers,
  onSubmit,
  onQuit,
  onBack,
  loading,
}) => {
  const [typeChoices, setTypeChoices] = React.useState<
    CharacterEnqueteChoice[]
  >([]);

  const [resultOfChoices, setResultOfChoices] =
    React.useState<CharacterEnqueteChoice>(CharacterEnqueteChoice.Choice_1);

  const onSelectEnquete = React.useCallback(
    (buttonIndex: number) => {
      const newChoice = Object.values(CharacterEnqueteChoice)[buttonIndex - 1];
      setTypeChoices([...typeChoices, newChoice]);
      if (typeChoices.length === 4) {
        const newResult = calculateResultOfChoices([...typeChoices, newChoice]);
        setResultOfChoices(newResult);
      }
      if (typeChoices.length !== 5) {
        return;
      } else {
        onSubmit({
          answer1: typeChoices[0],
          answer2: typeChoices[1],
          answer3: typeChoices[2],
          answer4: typeChoices[3],
          answer5: typeChoices[4],
          answer6: newChoice,
          characterType:
            buttonIndex <= 2
              ? choiceAndCharacterRecord[resultOfChoices].former
              : choiceAndCharacterRecord[resultOfChoices].latter,
        });
      }
    },
    [typeChoices, onSubmit, resultOfChoices]
  );

  const handleBack = React.useCallback(() => {
    if (typeChoices.length === 0) {
      onBack();
    } else {
      setTypeChoices(typeChoices.slice(0, typeChoices.length - 1));
    }
  }, [onBack, typeChoices]);

  const random2 = Math.floor(Math.random() * characterIdentifyEnquete.length);

  const questionAndAnswer = React.useMemo(() => {
    if (typeChoices.length <= 4) {
      return typeQuestionAndAnswers[typeChoices.length];
    } else {
      return {
        question: characterIdentifyEnquete[random2].question,
        answers: characterIdentifyEnquete[random2].answers[resultOfChoices],
      };
    }
  }, [random2, resultOfChoices, typeChoices.length, typeQuestionAndAnswers]);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <CharacterEnqueteUI
      questionAndAnswer={questionAndAnswer}
      onQuit={onQuit}
      onBack={handleBack}
      onNext={onSelectEnquete}
    />
  );
};
