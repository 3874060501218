import React, { Suspense, useContext } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { LoadingPage } from "~/components/templates/Loading/LoadingPage";
import { KanziLevel, TutorialFlagKey } from "~/generated/graphql";
import { useTranslation } from "~/i18n";
import { BookFriendMessageListPage } from "~/pages/BookFriendMessage";
import { Bookshelf } from "~/pages/Bookshelf";
import { EditKanziLevel } from "~/pages/EditKanziLevel";
import { EditProfile } from "~/pages/EditProfile";
import { EditReadLog } from "~/pages/EditReadLog";
import { EditRecommendFrequency } from "~/pages/EditRecommendFrequency";
import { EditRecommendSetting } from "~/pages/EditRecommendSetting";
import { EditSearchKeyword } from "~/pages/EditSearchKeyword";
import { AdminMessageListPage } from "~/pages/Message";
import { Mypage } from "~/pages/mypage";
import { NotFound } from "~/pages/not_found";
import { PostReadLogMain } from "~/pages/PostReadLog";
import { PwaTutorial } from "~/pages/PwaTutorial";
import { RecommendTutorial } from "~/pages/RecommendTutorial";
import { ViewRecommendStatus } from "~/pages/ViewRecommendStatus";
import { BackToMiniLessonModal } from "./components/organisms/BackToMiniLessonModal";
import { CurrentUserContext } from "./contexts/CurrentUserContext";
import { googleAnalyticsMove } from "./google_analytics";
import { InitialTutorial } from "./pages/InitialTutorial/container";
import { MyRecord } from "./pages/MyRecord/index";
import { SnsSharingImage } from "./pages/SnsSharingImage";
import { ViewWantReadBook } from "./pages/ViewWantReadBook";
import { RemoveRecommend } from "./pages/RemoveRecommend";
import { YLReassesmentEnquetePage } from "./pages/YLReassesmentEnquete";
import { RecordBook } from "./pages/RecordBook";
import { ArchievementRecord } from "./pages/RecordBook/Archievement";
import { BookshelfRecord } from "./pages/RecordBook/Bookshelf";
import { BadgeListRecord } from "./pages/RecordBook/BadgeList";
import { VideoArchiveRecord } from "./pages/RecordBook/VideoArchive";
import { SetUserContainer } from "./pages/SetUser/container";
import { SwitchUserContext } from "./contexts/SwitchUserContext";
import { SwitchUser } from "./pages/SwitchUser";
import { CharacterIntroduction } from "./pages/CharacterIntroduction";
import { BookSelectionAdjustment } from "./pages/BookSelectionAdjustment";
import { CharacterAssessmentResult } from "./pages/CharacterAssessment/Result";
import { CharacterAssessmentEnquete } from "./pages/CharacterAssessment/Enquete";

const KanziLevelContext = React.createContext(KanziLevel.L4);

export const App: React.FC = () => {
  const location = useLocation();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const {
    getTutorialFlag,
    currentUser,
    loading,
    error,
    isDoneFirstMiniLesson,
    refetch,
  } = React.useContext(CurrentUserContext);
  const { shouldSwitchUser } = useContext(SwitchUserContext);

  React.useEffect(() => {
    googleAnalyticsMove(location.pathname + location.search);
  }, [location]);

  const kanziLevel: string | undefined = currentUser?.kanziLevel.toLowerCase();

  React.useEffect(() => {
    i18n.changeLanguage(kanziLevel);
  }, [i18n, kanziLevel]);

  React.useEffect(() => {
    window.gtag("set", {
      createdAt: String(currentUser?.createdAt),
    });
  }, []);

  React.useEffect(() => {
    refetch();
  }, [location]);

  if (shouldSwitchUser) {
    return (
      <Routes>
        <Route path="/set_user/:user_id" element={<SetUserContainer />} />
        <Route path="/set_user" element={<SetUserContainer />} />
        <Route path="*" element={<SwitchUser />} />
      </Routes>
    );
  }

  if (loading) {
    return <LoadingPage />;
  }
  if (error) {
    return <>Error! {error.message}</>;
  }

  return (
    <KanziLevelContext.Provider
      value={currentUser?.kanziLevel ?? (null as unknown as KanziLevel)}
    >
      <Suspense fallback={<LoadingPage />}>
        {getTutorialFlag(TutorialFlagKey.FirstLogin) &&
        !isDoneFirstMiniLesson ? (
          <Routes>
            <Route path="/set_user/:user_id" element={<SetUserContainer />} />
            <Route path="/set_user" element={<SetUserContainer />} />
            <Route path="/message" element={<AdminMessageListPage />} />
            <Route
              path="*"
              element={
                <BackToMiniLessonModal
                  onClickToMiniLesson={() => navigate("/message")}
                  nickname={currentUser?.general.nickname || ""}
                />
              }
            />
          </Routes>
        ) : (
          <Routes>
            <Route path="/" element={<Bookshelf />} />

            <Route path="/set_user/:user_id" element={<SetUserContainer />} />
            <Route path="/set_user" element={<SetUserContainer />} />

            <Route path="/recommend/:id" element={<ViewRecommendStatus />} />
            <Route path="/yomimashoka/:id" element={<ViewWantReadBook />} />
            <Route path="/message" element={<AdminMessageListPage />} />
            <Route path="/bf" element={<BookFriendMessageListPage />} />
            <Route path="/mypage" element={<Mypage />} />
            <Route path="/mypage/edit" element={<EditProfile />} />
            <Route
              path="/mypage/kanziLevel/edit"
              element={<EditKanziLevel />}
            />

            <Route
              path="/editRecommendFrequency"
              element={<EditRecommendFrequency />}
            />
            <Route path="/editSearchKeyword" element={<EditSearchKeyword />} />
            <Route path="/myrecord" element={<MyRecord />} />
            {/* /postreadlog/initも含む  */}
            <Route path="/postreadlog/*" element={<PostReadLogMain />} />
            <Route path="/editreadlog/:readlogid" element={<EditReadLog />} />
            <Route
              path="/editRecommendSetting"
              element={<EditRecommendSetting />}
            />
            <Route path="/pwa_tutorial" element={<PwaTutorial />} />
            <Route path="/recommend_tutorial" element={<RecommendTutorial />} />
            <Route path="/initial_tutorial" element={<InitialTutorial />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/sharing_image" element={<SnsSharingImage />} />
            <Route
              path="/remove_recommend/:recommendId"
              element={<RemoveRecommend />}
            />
            <Route
              path="/book_select_adjustment"
              element={<BookSelectionAdjustment />}
            />
            <Route
              path="/yl_reassessment_enquete"
              element={<YLReassesmentEnquetePage />}
            />
            <Route path="/character_assessment">
              <Route path="enquete" element={<CharacterAssessmentEnquete />} />
              <Route path="result" element={<CharacterAssessmentResult />} />
            </Route>
            <Route
              path="/character_introduction"
              element={<CharacterIntroduction />}
            />
            <Route path="/record_book" element={<RecordBook />}>
              <Route path="archievement" element={<ArchievementRecord />} />
              <Route path="bookshelf" element={<BookshelfRecord />} />
              <Route path="badge_list" element={<BadgeListRecord />} />
              <Route path="video_archive" element={<VideoArchiveRecord />} />
            </Route>
            <Route path="/pwa_tutorial" element={<PwaTutorial />} />
          </Routes>
        )}
      </Suspense>
    </KanziLevelContext.Provider>
  );
};
