import React from "react";
import styled from "styled-components";
import { LargeButton } from "~/components/atoms/buttons/Button";
import { Selectbox } from "~/components/atoms/input/Selectbox";
import { Stack } from "~/components/atoms/layout/Stack";
import { Text } from "~/components/atoms/texts/Text";
import { BaseModal } from "~/components/atoms/wrappers/BaseModal";
import { readingTechniqueTypeStr } from "~/components/organisms/bookFriendMessage/BookFriendMessageListItem/ReadLogMessage/SendStarModal/const";
import { ReadingTechniqueType } from "~/generated/graphql";

export type SelectReadingTechniqueModalProps = {
  isOpen: boolean;
  onNext: () => void;
  onBack: () => void;
  readingTechniqueType: ReadingTechniqueType;
  setReadingTechniqueType: (readingTechniqueType: ReadingTechniqueType) => void;
};

// TODO: アンロック対応
export const SelectReadingTechniqueModal: React.FC<
  SelectReadingTechniqueModalProps
> = ({
  isOpen,
  onNext,
  onBack,
  readingTechniqueType,
  setReadingTechniqueType,
}) => {
  return (
    <BaseModal open={isOpen} onClose={onBack}>
      <Stack rowGap="16px">
        <Text
          lineHeight="MD"
          fontSize="LG"
          bold
          fontColor="semantic.text.heading"
        >
          ヒントガチャ
        </Text>
        <Text lineHeight="MD" fontSize="MD" fontColor="semantic.text.body">
          つかいたいどくしょかのワザはどれかな？
        </Text>
        <Selectbox
          value={readingTechniqueType}
          onChange={(value) =>
            setReadingTechniqueType(value as ReadingTechniqueType)
          }
          options={Object.values(ReadingTechniqueType).map((type) => ({
            node: readingTechniqueTypeStr[type],
            value: type,
          }))}
        />
        <LargeButton onClick={onNext}>これにする</LargeButton>
        <_BackButton onClick={onBack}>
          <Text
            lineHeight="EQ"
            fontSize="SM"
            fontColor="semantic.secondary.main"
          >
            ひとことかんそうにもどる
          </Text>
        </_BackButton>
      </Stack>
    </BaseModal>
  );
};

const _BackButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 12px 0;
  appearance: none;
  width: 100%;
`;
