import { MissionStorySetting } from "../type";

const baseDir = "/img/mission/start_story/story_7";

export const StartStory7: MissionStorySetting[] = [
  {
    image: `${baseDir}/scene_1.jpeg`,
    sound: "start7_scene1",
    text: "どくしょかのワザ「しつもんする」の力で、山の山頂に辿り着きました。みんな、喜びと達成感でいっぱいです。",
    ruby: [
      ...Array(17).fill(""),
      "ちから",
      ...Array(2).fill(""),
      "やま",
      "",
      "さん",
      "ちょう",
      "",
      "たど",
      "",
      "つ",
      ...Array(9).fill(""),
      "よろこ",
      ...Array(2).fill(""),
      "たっ",
      "せい",
      "かん",
      ...Array(8).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_2.jpeg`,
    sound: "start7_scene2",
    text: "そんなとき……。「ん？　向こうから、何か音が聴こえてこねぇか？」",
    ruby: [
      ...Array(12).fill(""),
      "む",
      ...Array(5).fill(""),
      "なに",
      "",
      "おと",
      "",
      "き",
      ...Array(9).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_3.jpeg`,
    sound: "start7_scene3",
    text: "木々の間を進んで、みんなで恐る恐る見に行ってみます。",
    ruby: [
      "き",
      "ぎ",
      "",
      "あいだ",
      "",
      "すす",
      ...Array(7).fill(""),
      "おそ",
      "",
      "おそ",
      "",
      "み",
      "",
      "い",
      ...Array(6).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_4.jpeg`,
    sound: "start7_scene4",
    text: "そこには、一匹のオオカミが佇んでいました。",
    ruby: [
      ...Array(5).fill(""),
      "いっ",
      "ぴき",
      ...Array(6).fill(""),
      "たたず",
      ...Array(7).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_5.jpeg`,
    sound: "start7_scene5",
    text: "「……‼︎」",
    ruby: [...Array(6).fill("")],
  },
  {
    image: `${baseDir}/scene_6.jpeg`,
    sound: "start7_scene6",
    text: "「あなたは誰……？」「……うるせぇ」「ここで何をしているの……？」「……うるせぇ」オオカミは、何を聞いても「うるせぇ」の一点張り。",
    ruby: [
      ...Array(5).fill(""),
      "だれ",
      ...Array(16).fill(""),
      "なに",
      ...Array(24).fill(""),
      "なに",
      "",
      "き",
      ...Array(10).fill(""),
      "いっ",
      "てん",
      "ばり",
      ...Array(3).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_7.jpeg`,
    sound: "start7_scene7",
    text: "「ふんっ！　こんなやつ放っておいて、いこうぜ！」",
    ruby: [...Array(11).fill(""), "ほお", ...Array(12).fill("")],
  },
  {
    image: `${baseDir}/scene_8.jpeg`,
    sound: "start7_scene8",
    text: "と、そのとき。空が、みるみるうちに光に包まれていきます。そう！　これは……。",
    ruby: [
      ...Array(7).fill(""),
      "そら",
      ...Array(9).fill(""),
      "ひかり",
      "",
      "つつ",
      ...Array(18).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_9.jpeg`,
    sound: "start7_scene9",
    text: "「しつもんする」のスターが手に入りました。ゆっくりと、黄色の星が降りてきます。",
    ruby: [
      ...Array(13).fill(""),
      "て",
      "",
      "はい",
      ...Array(11).fill(""),
      "き",
      "いろ",
      "",
      "ほし",
      "",
      "お",
      ...Array(6).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_10.jpeg`,
    sound: "start7_scene10",
    text: "そして、気がつくと図書館の中に戻ってきていました。……あれ⁉︎　なんであのオオカミまでここにいるの⁉︎",
    ruby: [
      ...Array(4).fill(""),
      "き",
      ...Array(4).fill(""),
      "と",
      "しょ",
      "かん",
      "",
      "なか",
      "",
      "もど",
      ...Array(35).fill(""),
    ],
  },
];
