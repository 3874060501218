import React, { useState } from "react";
import { InputMessage, InputMessageProps } from "../..";
import {
  ReactiveHistoryState,
  OpenEndedAllFragment,
  useAnswerReactiveMutation,
} from "~/generated/graphql";
import { lastAnsweredReactiveVar, updateMessageCache } from "~/store/message";
import { RemarkMessage } from "~/components/organisms/adminMessage/AdminMessageListItem/messageListItems/RemarkMessage";
import { Stack } from "~/components/atoms/layout/Stack";
import { useBookshelfExpModal } from "~/components/organisms/Bookshelf/useBookshelfExpModal";

/**
 * テキスト入力のミニレッスン
 */

export type OpenEndedProps = {
  state: ReactiveHistoryState;
  question: OpenEndedAllFragment;
  defaultValue: string;
  showAll?: boolean;
  incrementCurrentIndex: () => void;
} & Omit<InputMessageProps, "onChange" | "value" | "onSubmit" | "buttonText">;

export const OpenEnded: React.FC<OpenEndedProps> = ({
  id,
  state,
  item,
  defaultValue,
  formDisabled = false,
  buttonDisabled = false,
  showAll = true,
  incrementCurrentIndex,
  speaker,
}) => {
  const [value, setValue] = useState<string>(defaultValue);
  const { setIsOpenGetExpModal, updateExperience } = useBookshelfExpModal();

  const disabledButton = () => {
    if (buttonDisabled) return buttonDisabled;
    if (state === ReactiveHistoryState.Wait) return !value;
    return true;
  };

  const disabledInput = () => {
    if (formDisabled) return formDisabled;
    return !(state === ReactiveHistoryState.Wait);
  };

  const onChange = (event) => {
    setValue(event.target.value);
  };

  const [answerReactive, { loading: answering }] = useAnswerReactiveMutation({
    update: (cache, result) => {
      result.data?.answerReactive &&
        updateMessageCache(cache, result.data.answerReactive.talkUpdate);
    },
    onCompleted: (res) => {
      lastAnsweredReactiveVar(new Date().getTime());
      if (
        res.answerReactive.userExperience &&
        res.answerReactive.userExperienceEvent
      ) {
        setIsOpenGetExpModal(true);
        updateExperience(res.answerReactive.userExperience, [
          res.answerReactive.userExperienceEvent,
        ]);
      }
    },
  });

  const onSubmit = () => {
    answerReactive({
      variables: {
        talkID: id,
        answer: {
          openEnded: {
            answer: value,
          },
        },
      },
    });
  };

  return (
    <Stack rowGap="10px">
      <InputMessage
        id={id}
        item={item}
        buttonText={"OK"}
        onSubmit={onSubmit}
        value={value}
        onChange={onChange}
        formDisabled={disabledInput() || answering}
        buttonDisabled={disabledButton() || answering}
        showAll={showAll}
        incrementCurrentIndex={incrementCurrentIndex}
        speaker={speaker}
      />
      {state === ReactiveHistoryState.Completed && (
        <RemarkMessage
          id={id}
          item={{ text: defaultValue, time: new Date(item.time) }}
          userType={"me"}
          showAll={true}
          incrementCurrentIndex={() => {
            return;
          }}
          speaker={speaker}
        />
      )}
    </Stack>
  );
};
