export const IMAGE_PATHS = {
  PWA_SAFARI: "/img/pwa/pwa-safari.png",
  PWA_RESULT: "/img/pwa/pwa-result.png",
  PWA_RESULT_ANDROID: "/img/pwa/pwa-result-android.png",
  NO_IMAGE: "/img/read_log/noimage.jpg",
  PRESENT_BOX: "/img/recommend/present.svg",
  ICON: {
    USER: "/img/icon/user.svg",
    RECORD: "/img/icon/record.svg",
    SETTING: "/img/icon/setting.svg",
    HOME: "/img/icon/home.svg",
    BOOK: "/img/icon/book.svg",
    SHARE: "/img/icon/share.svg",
    RECORD_BOOK: "/img/icon/record-book.svg",
    USER_FRIENDS: "/img/icon/user-friends.svg",
    THREE_USERS: "/img/icon/three-users.svg",
  },
};

const STAND_UP_IMAGE = {
  NORMAL: 1,
  SMILE: 2,
  TEACH: 3,
  FIRE: 4,
  CRY: 5,
  HEART: 6,
  READ: 7,
  SURPRISE: 8,
} as const;

type StandUpImageEnum = typeof STAND_UP_IMAGE;
export type StandUpImageType = keyof typeof STAND_UP_IMAGE;

export const standUpImageValue = (
  type: keyof StandUpImageEnum
): StandUpImageEnum[keyof StandUpImageEnum] => {
  const value = STAND_UP_IMAGE[type];
  return value ? value : 1;
};
