import * as React from "react";
import { ConfirmLengthTemplate } from "~/components/templates/PostReadLog/ConfirmLength";
import { usePostReadLogState } from "../usePostReadLogState";

export const ConfirmLength: React.FC = () => {
  const {
    state: { questionNumber, lastProgressNumber },
    dispatch,
  } = usePostReadLogState();

  const handleNext = (): void => {
    dispatch({
      type: "setQuestionNumber",
      payload: currectQuestionNumber + 1,
    });

    dispatch({
      type: "setFlow",
      payload: "confirmEmotions",
    });
  };

  const handleBack = (): void => {
    dispatch({
      type: "setQuestionNumber",
      payload: currectQuestionNumber - 1,
    });
    dispatch({
      type: "setFlow",
      payload: "confirmDifficulty",
    });
  };

  const onClicks = [
    //短い
    (event: React.MouseEvent) => {
      event.preventDefault();
      dispatch({
        type: "setLength",
        payload: 1,
      });
      handleNext();
    },
    //ちょっと短い
    (event: React.MouseEvent) => {
      event.preventDefault();
      dispatch({
        type: "setLength",
        payload: 2,
      });
      handleNext();
    },
    //ぴったり！
    (event: React.MouseEvent) => {
      event.preventDefault();
      dispatch({
        type: "setLength",
        payload: 3,
      });
      handleNext();
    },
    //ちょっと長い
    (event: React.MouseEvent) => {
      event.preventDefault();
      dispatch({
        type: "setLength",
        payload: 4,
      });
      handleNext();
    },
    //長い
    (event: React.MouseEvent) => {
      event.preventDefault();
      dispatch({
        type: "setLength",
        payload: 5,
      });
      handleNext();
    },
  ];

  const currectQuestionNumber = questionNumber ? questionNumber : 1;
  const progress = Math.floor(
    (currectQuestionNumber / lastProgressNumber) * 100
  );

  return (
    <ConfirmLengthTemplate
      progress={progress}
      handleBack={handleBack}
      onClicks={onClicks}
    />
  );
};
