import React, { VFC } from "react";
import styled, { css } from "styled-components";
import { Interpolation } from "~/styles/interpolation";
import { useSnd } from "~/utils/hooks/useSnd";
import { Text } from "../../texts/Text";

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  unit?: string;
  error?: boolean;
};

/**
 * @deprecated atoms/input/Inputを使用してください
 */
export const Input: VFC<InputProps> = (props) => {
  const { unit, error, onChange, ...inputProps } = props;
  const { play } = useSnd();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event);
    play("TYPE");
  };
  return (
    <Wrapper>
      <StyledInput {...inputProps} error={error} onChange={handleChange} />
      {unit && (
        <UnitText fontSize={"SM"} lineHeight={"EQ"}>
          {unit}
        </UnitText>
      )}
    </Wrapper>
  );
};

const StyledInput = styled.input<Pick<InputProps, "error">>`
  ${({ theme }) => {
    const {
      colors,
      deprecatedBorderRadius: borderRadius,
      fontSize,
      lineHeight,
    } = theme;
    return css<Pick<InputProps, "disabled" | "error">>`
      padding: 16px;
      font-size: ${fontSize.MD};
      line-height: ${lineHeight.EQ};
      border-radius: ${borderRadius.MD};
      color: ${colors.tex.t800};
      width: inherit;
      ${Interpolation.Form}
      &:active, &:focus {
        outline: none;
      }
      &::placeholder {
        color: ${colors.tex.t200};
      }
    `;
  }}
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.size.S};
  padding: 0;
`;

const UnitText = styled(Text)`
  width: 50px;
  text-align: center;
  color: ${({ theme }) => theme.colors.tex.t700};
`;
