import React, { ReactNode, VFC } from "react";
import styled, { css } from "styled-components";
import { ColorTheme } from "~/styles/design_token/color";
import { ButtonInterpolation } from "~/styles/interpolation";
import { useSnd } from "~/utils/hooks/useSnd";
import {
  PaddingProps,
  paddingStyle,
  expandPaddingProps,
} from "~/styles/design_token/padding";
import { useClickTracking } from "~/utils/googleAnalytics/useButtonClickTracking";

export type SolidButtonProps = {
  /**
   * ボタンの役割を示す.`Outline`はサブで用いることが多い.
   */
  variant: "Normal" | "Outline";
  /**
   * ボタンの形状
   */
  shape: "Normal" | "Square" | "RoundedCorner";
  /**
   * 配色
   */
  color: ColorTheme;
  /**
   * moleculesで内側の要素は制御
   */
  children: React.ReactNode;
  style?: React.CSSProperties;
  onClick?: (event: React.MouseEvent) => void;
  className?: string;
  disabled?: boolean;
  trackingLabel?: ReactNode;
} & PaddingProps;

/**
 * @deprecated LargeButton, SmallButton, TinyButtonを使ってください
 */
export const SolidButton: VFC<SolidButtonProps> = ({
  variant,
  shape,
  color,
  children,
  style,
  onClick,
  className,
  disabled = false,
  trackingLabel,
  ...restProps
}) => {
  const { play } = useSnd();
  const handleClick = (event: React.MouseEvent) => {
    play("BUTTON");
    onClick?.(event);
  };

  const { onClickWithTracking } = useClickTracking({
    onClick: handleClick,
    label: trackingLabel || children,
  });

  return (
    <ButtonCon disabled={disabled}>
      <StyledButton
        className={className}
        variant={variant}
        shape={shape}
        color={color}
        style={style}
        onClick={onClickWithTracking}
        disabled={disabled}
        {...expandPaddingProps(restProps)}
      >
        {children}
      </StyledButton>
    </ButtonCon>
  );
};

const ButtonCon = styled.div<Pick<SolidButtonProps, "disabled">>`
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.base};
  width: 100%;
`;

const StyledButton = styled.button<
  Pick<SolidButtonProps, "variant" | "shape" | "color" | "disabled">
>`
  ${({ theme, variant, shape, color, disabled }) => {
    const {
      colors,
      deprecatedBorderRadius: borderRadius,
      lineHeight,
      size,
    } = theme;
    const { base, pri, sec, violet, green, red, sky, line } = colors;

    //outlineの場合の見た目の調整
    let MOTION_RANGE = size.BUTTON_MOTION_RANGE;
    if (variant === "Outline") {
      MOTION_RANGE = size.BUTTON_MOTION_RANGE_SUB;
    }

    return css`
      width: 100%;
      box-sizing: border-box;
      box-shadow: 0 ${MOTION_RANGE} 0
        ${() => {
          switch (color) {
            case "pri":
              return pri.p600;
            case "sec":
              return sec.s600;
            case "violet":
              return violet.v600;
            case "green":
              return green.g600;
            case "red":
              return red.r600;
            case "sky":
              return sky.s600;
            case "gray":
              return base.gray;
            case "lightGray":
              return base.lightGray;
            case "white":
              return "rgba(255,255,255,0.5)";
            case "line":
              return line.line600;
            default:
              return pri.p600;
          }
        }};

      ${shape === "Square" && ButtonInterpolation.Square};
      ${shape === "RoundedCorner" && ButtonInterpolation.RoundedCorner};
      padding: ${size.XS} 0;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      cursor: ${!disabled && "pointer"};
      border-radius: ${borderRadius.MD};
      font-family: inherit;
      font-weight: bold;
      text-align: center;
      text-decoration: none;
      line-height: ${lineHeight.MD};
      margin-bottom: ${MOTION_RANGE};
      background-color: ${() => {
        if (variant === "Outline") {
          return base.white;
        } else {
          switch (color) {
            case "pri":
              return pri.p400;
            case "sec":
              return sec.s400;
            case "violet":
              return violet.v400;
            case "green":
              return green.g400;
            case "red":
              return red.r400;
            case "sky":
              return sky.s400;
            case "gray":
              return base.lightGray;
            case "lightGray":
              return base.lightGray;
            case "white":
              return base.white;
            case "line":
              return line.line400;
            default:
              return pri.p400;
          }
        }
      }};
      color: ${() => {
        if (color == "gray") {
          return pri.p400;
        } else if (color == "white") {
          return pri.p400;
        } else {
          return base.white;
        }
      }};
      ${paddingStyle}
      border-width: ${variant === "Outline" ? "3px" : "0"};
      border-style: solid;
      border-color: ${() => {
        switch (color) {
          case "pri":
            return pri.p600;
          case "sec":
            return sec.s600;
          case "violet":
            return violet.v600;
          case "green":
            return green.g600;
          case "red":
            return red.r600;
          case "sky":
            return sky.s600;
          case "lightGray":
            return base.lightGray;
          case "gray":
            return base.gray;
          case "line":
            return line.line600;
          default:
            return pri.p600;
        }
      }};

      &:hover,
      &:focus {
        outline: none;
        background-color: ${() => {
          if (variant === "Outline") {
            return base.white;
          } else {
            switch (color) {
              case "pri":
                return disabled ? pri.p400 : pri.p500;
              case "sec":
                return disabled ? sec.s400 : sec.s500;
              case "violet":
                return disabled ? violet.v400 : violet.v500;
              case "green":
                return disabled ? green.g400 : green.g500;
              case "red":
                return disabled ? red.r400 : red.r500;
              case "sky":
                return disabled ? sky.s400 : sky.s500;
              case "gray":
                return disabled ? base.lightGray : base.gray;
              case "lightGray":
                return disabled ? base.background : base.lightGray;
              case "white":
                return base.white;
              case "line":
                return disabled ? line.line400 : line.line500;
              default:
                return disabled ? pri.p400 : pri.p500;
            }
          }
        }};
      }

      &:active {
        outline: none;
        box-shadow: ${!disabled && "none"};
        transform: ${!disabled && `translateY(${MOTION_RANGE})`};
      }
    `;
  }}
`;
