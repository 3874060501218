import { MissionStorySetting } from "../type";

const baseDir = "/img/mission/complete_story/story_6";

export const CompleteStory6: MissionStorySetting[] = [
  {
    image: `${baseDir}/scene_1.jpeg`,
    sound: "complete6_scene1",
    text: "立ちはだかる、大きな岩の山。\n「みんなの力を合わせれば登れるわ！\nワタシについてきなさい！」",
    ruby: [
      "た",
      ...Array(6).fill(""),
      "おお",
      ...Array(2).fill(""),
      "いわ",
      "",
      "やま",
      ...Array(7).fill(""),
      "ちから",
      "",
      "あ",
      ...Array(4).fill(""),
      "のぼ",
      ...Array(18).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_2.jpeg`,
    sound: "complete6_scene2_1",
    endDelay: 3000,
    text: "「この本には『山登りの喜びは、自然と一つになっているように感じることだ』って書いてあるけど、そんな余裕ねぇよ〜！　どんな気持ちなんだ〜？」",
    ruby: [
      ...Array(3).fill(""),
      "ほん",
      ...Array(3).fill(""),
      "やま",
      "のぼ",
      ...Array(2).fill(""),
      "よろこ",
      ...Array(3).fill(""),
      "し",
      "ぜん",
      "",
      "ひと",
      ...Array(10).fill(""),
      "かん",
      ...Array(8).fill(""),
      "か",
      ...Array(10).fill(""),
      "よ",
      "ゆう",
      ...Array(9).fill(""),
      "き",
      "も",
      ...Array(7).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_2.jpeg`,
    sound: "complete6_scene2_2",
    text: "「とっても大きなしつもんね！　問いながら登っていきましょ！」",
    ruby: [
      ...Array(5).fill(""),
      "おお",
      ...Array(9).fill(""),
      "と",
      ...Array(4).fill(""),
      "のぼ",
      ...Array(9).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_3.jpeg`,
    sound: "complete6_scene3",
    text: "「りんごちゃん、なんでずっとオイラの肩にのってんだよぉ！」\n「ワ、ワタシは司令塔なのよ！　肉体労働はしないの！」",
    ruby: [
      ...Array(18).fill(""),
      "かた",
      ...Array(18).fill(""),
      "し",
      "れい",
      "とう",
      ...Array(5).fill(""),
      "にく",
      "たい",
      "ろう",
      "どう",
      ...Array(7).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_4.jpeg`,
    sound: "complete6_scene4",
    text: "そのとき！　「きゃあああああ‼︎」\nりんごちゃんが転がり落ちていくではありませんか！　「りんごちゃーーん‼︎」",
    ruby: [
      ...Array(24).fill(""),
      "ころ",
      ...Array(2).fill(""),
      "お",
      ...Array(25).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_5.jpeg`,
    sound: "complete6_scene5",
    text: "……モコッ！",
    ruby: [...Array(6).fill("")],
  },
  {
    image: `${baseDir}/scene_6.jpeg`,
    sound: "complete6_scene6_1",
    text: "モ、モコひつじのモコモコのお陰で助かりました！　\n「あ、ありがとう……。」",
    ruby: [...Array(14).fill(""), "かげ", "", "たす", ...Array(21).fill("")],
  },
  {
    image: `${baseDir}/scene_6.jpeg`,
    sound: "complete6_scene6_2",
    text: "りんごちゃん、本当は高いところがとってもこわいのでした。",
    ruby: [
      ...Array(7).fill(""),
      "ほん",
      "とう",
      "",
      "たか",
      ...Array(17).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_7.jpeg`,
    sound: "complete6_scene7",
    text: "「さぁ、気をとりなおして、どんどん行くわよ‼︎」　りんごちゃん、得意の頭脳を使って、指示を出していきます。　本当はこわいけど。",
    ruby: [
      ...Array(4).fill(""),
      "き",
      ...Array(12).fill(""),
      "い",
      ...Array(13).fill(""),
      "とく",
      "い",
      "",
      "ず",
      "のう",
      "",
      "つか",
      ...Array(3).fill(""),
      "し",
      "じ",
      "",
      "だ",
      ...Array(8).fill(""),
      "ほん",
      "とう",
      ...Array(7).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_8.jpeg`,
    sound: "complete6_scene8_1",
    text: "背の高〜いよみキリンは大活躍！\nかと思いきや、実はよみキリンも身体を動かすのは苦手なのでした。",
    ruby: [
      "せ",
      "",
      "たか",
      ...Array(8).fill(""),
      "だい",
      "かつ",
      "やく",
      ...Array(4).fill(""),
      "おも",
      ...Array(4).fill(""),
      "じつ",
      ...Array(7).fill(""),
      "から",
      "だ",
      "",
      "うご",
      ...Array(4).fill(""),
      "にが",
      "て",
      ...Array(6).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_8.jpeg`,
    sound: "complete6_scene8_2",
    text: "「あんたも一緒なのね、シャクシャク」\n「キリッ」",
    ruby: [...Array(5).fill(""), "いっ", "しょ", ...Array(17).fill("")],
  },
  {
    image: `${baseDir}/scene_9.jpeg`,
    sound: "complete6_scene9",
    text: "「う、うぉぉぉ！　きょうりゅうパワーーーー‼︎」\n太古のきょうりゅうが手をのばして︙︙。",
    ruby: [
      ...Array(24).fill(""),
      "たい",
      "こ",
      ...Array(8).fill(""),
      "て",
      ...Array(8).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_10.jpeg`,
    sound: "complete6_scene10_1",
    text: "ようやく、頂上につきました！\nなんて雄大な景色でしょう。",
    ruby: [
      ...Array(5).fill(""),
      "ちょう",
      "じょう",
      ...Array(11).fill(""),
      "ゆう",
      "だい",
      "",
      "け",
      "しき",
      ...Array(5).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_10.jpeg`,
    sound: "complete6_scene10_2",
    text: "「『自然と一つになる』って、こういう気持ちなのか！」\n「問いの答えが、見つかった気がするわね！」",
    ruby: [
      ...Array(2).fill(""),
      "し",
      "ぜん",
      "",
      "ひと",
      ...Array(12).fill(""),
      "き",
      "も",
      ...Array(8).fill(""),
      "と",
      ...Array(2).fill(""),
      "こた",
      ...Array(3).fill(""),
      "み",
      ...Array(4).fill(""),
      "き",
      ...Array(7).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_11.jpeg`,
    sound: "complete6_scene11",
    text: "「は〜疲れたぁ！」「しつもんをしながら登ったおかげで、たくさんのことに気づけたね！」\nみんなみんな、達成感と喜びに包まれていました。",
    ruby: [
      ...Array(3).fill(""),
      "つか",
      ...Array(15).fill(""),
      "のぼ",
      ...Array(15).fill(""),
      "き",
      ...Array(14).fill(""),
      "たっ",
      "せい",
      "かん",
      "",
      "よろこ",
      ...Array(2).fill(""),
      "つつ",
      ...Array(8).fill(""),
    ],
  },
];
