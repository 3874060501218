import { theme } from "~/styles/theme";

const TO_RADIANS = Math.PI / 180;

type ImageSetting = {
  src: string;
  x: number;
  y: number;
  width: number;
  height: number;
  angle?: number;
};

const images: Record<string, ImageSetting> = {
  lion: {
    src: "/img/character/lion.svg",
    x: 1060,
    y: 1438,
    width: 332,
    height: 374,
    angle: -3.29,
  },
  apple: {
    src: "/img/character/apple.svg",
    x: 850,
    y: 1460,
    width: 165,
    height: 220,
    angle: 15,
  },
  bookWarm: {
    src: "/img/character/book_warm.svg",
    x: 395,
    y: 1440,
    width: 250,
    height: 238,
    angle: -9.74,
  },
  penguin: {
    src: "/img/character/penguin.svg",
    x: 150,
    y: 1460,
    width: 329,
    height: 377,
    angle: 11.44,
  },
  sensei: {
    src: "/img/sensei/standup_2.svg",
    x: 473,
    y: 1219,
    width: 332,
    height: 497,
  },
  logo: {
    src: "/img/yondemy_logo_icon.svg",
    x: 124,
    y: 140,
    width: 424,
    height: 259,
    angle: -27.71,
  },
};

export const snsSharingImage = (
  imgSrcList: string[],
  setUri: (uri: string) => void
): void => {
  const canvas = document.createElement("canvas");
  canvas.width = 1200;
  canvas.height = 1600;

  const ctx = canvas.getContext("2d");

  const columnNumber = Math.ceil(Math.sqrt(imgSrcList.length));
  const rowNumber = Math.ceil(imgSrcList.length / columnNumber);
  const columnGap =
    columnNumber > 1
      ? Math.max(4, Math.ceil((24 * 2) / (columnNumber - 1)))
      : 0;
  const imgSize =
    columnNumber > 1
      ? (1008 - columnGap * (columnNumber - 1)) / columnNumber
      : 1008;
  const rowGap =
    rowNumber > 1 ? (1123 - imgSize * rowNumber) / (rowNumber - 1) : 0;

  if (ctx) {
    //背景のオレンジの描画
    ctx.beginPath();
    ctx.rect(0, 0, 1200, 1600);
    ctx.fillStyle = theme.colors.pri.p300;
    ctx.fill();

    //角丸の灰色背景描画
    roundedRect(ctx, 60, 60, 1084, 1480, 20, theme.colors.base.background);

    //点線描画
    ctx.beginPath();
    for (let i = 0; i < 46; i++) {
      ctx.moveTo(60, 77 + i * 32);
      ctx.lineTo(1144, 77 + i * 32);
    }
    ctx.strokeStyle = "#E0DEDC";
    ctx.lineWidth = 2;
    ctx.setLineDash([5, 5]);
    ctx.stroke();

    //オレンジの横線描画
    if (rowNumber < 6) {
      ctx.beginPath();
      ctx.fillStyle = "#F3AE36";
      for (let i = 0; i < rowNumber; i++) {
        ctx.rect(0, 106 + i * (rowGap + imgSize) - 21 + imgSize, 1200, 36);
      }
      ctx.fill();
    }

    //ヨンデミーのアイコン描画
    drawImage(ctx, images.logo).then(() => {
      Promise.all(
        //本描画
        drawBookImages(
          imgSrcList,
          ctx,
          columnNumber,
          columnGap,
          imgSize,
          rowGap
        )
      ).then(() => {
        //ヨンデミーキャラクター描画
        Promise.all([
          drawImage(ctx, images.lion),
          drawImage(ctx, images.apple),
          drawImage(ctx, images.bookWarm),
          drawImage(ctx, images.penguin),
          drawImage(ctx, images.sensei),
        ]).then(() => {
          setUri(canvas.toDataURL("image/jpeg"));
        });
      });
    });
  }
};

const roundedRect = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  radius: number,
  color: string
) => {
  ctx.beginPath();
  ctx.moveTo(x, y + radius);
  ctx.arcTo(x, y + height, x + radius, y + height, radius);
  ctx.arcTo(x + width, y + height, x + width, y + height - radius, radius);
  ctx.arcTo(x + width, y, x + width - radius, y, radius);
  ctx.arcTo(x, y, x, y + radius, radius);
  ctx.fillStyle = color;
  ctx.fill();
};

const drawBookImage = (
  src: string,
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  imgSize: number
) => {
  return new Promise<void>((resolve) => {
    const _img = new Image();
    _img.src = src;
    _img.crossOrigin = "anonymous";
    _img.onload = () => {
      const { width, height } = calcFitWidthAndHeight(_img, imgSize);
      ctx.drawImage(
        _img,
        0,
        0,
        _img.width,
        _img.height,
        x - width / 2,
        y - height,
        width,
        height
      );
      resolve();
    };
    _img.onerror = () => {
      resolve();
    };
  });
};

const drawBookImages = (
  imgSrcList: string[],
  ctx: CanvasRenderingContext2D,
  columnNumber: number,
  columnGap: number,
  imgSize: number,
  rowGap: number
) => {
  return imgSrcList
    .map((imgSrc, index) => {
      return drawBookImage(
        imgSrc,
        ctx,
        96 + (columnGap + imgSize) * (index % columnNumber) + imgSize / 2,
        106 + (rowGap + imgSize) * Math.floor(index / columnNumber) + imgSize,
        imgSize
      );
    })
    .filter((v) => v);
};

const drawImage = (ctx: CanvasRenderingContext2D, image: ImageSetting) => {
  const { src, x, y, width, height, angle } = image;
  if (angle !== undefined) {
    return drawRotatedImage(ctx, image);
  }
  return new Promise<void>((resolve) => {
    const _img = new Image();
    _img.src = src;
    _img.onload = () => {
      ctx && ctx.drawImage(_img, x, y, width, height);
      resolve();
    };
    _img.onerror = () => {
      resolve();
    };
  });
};

const drawRotatedImage = (
  ctx: CanvasRenderingContext2D,
  { src, x, y, width, height, angle = 0 }: ImageSetting
) => {
  return new Promise<void>((resolve) => {
    const _img = new Image();
    _img.src = src;
    _img.onload = () => {
      ctx.save();
      ctx.translate(x, y);
      ctx.rotate(angle * TO_RADIANS);
      ctx.drawImage(_img, -(width / 2), -(height / 2), width, height);
      ctx.restore();
      resolve();
    };
    _img.onerror = () => {
      resolve();
    };
  });
};

const calcFitWidthAndHeight = (img: HTMLImageElement, imgSize: number) => {
  const ratio = imgSize / Math.max(img.width, img.height);
  return {
    width: img.width * ratio,
    height: img.height * ratio,
  };
};
