import * as React from "react";
import styled, { css } from "styled-components";
import { Size } from "../types";

const fontSizes: Record<Size, string> = {
  large: "20px",
  medium: "16px",
};

export type FontType = "Kyokasho" | "Mincho";

const Container = styled.div<{
  size?: Size;
  fontType?: FontType;
  lineHeight: string;
  letterSpacing: string;
}>`
  position: relative;
  background-color: white;
  border-radius: 16px;

  padding: 16px 16px 64px 16px;

  display: flex;
  flex-direction: column;
  row-gap: 12px;

  .sentence {
    color: ${({ theme }) => theme.colors.tex.t700};

    ${({ fontType }) => {
      if (fontType === "Mincho") {
        return css`
          font-family: YuMincho, "Yu Mincho", sans-serif;
        `;
      } else {
        return css`
          font-family: uddigikyokasho-pro, sans-serif;
        `;
      }
    }};

    font-weight: 400;
    font-style: normal;
    font-size: ${({ size = "medium" }) => fontSizes[size]};
    line-height: ${({ lineHeight }) => lineHeight};
    letter-spacing: ${({ letterSpacing }) => letterSpacing};

    white-space: pre-wrap;
    word-wrap: break-word;

    position: relative;
  }

  .caption {
    color: ${({ theme }) => theme.colors.tex.t300};

    font-family: uddigikyokasho-pro, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
  }
`;

export type ReadingViewerProps = {
  text: string;
  caption: string;
  size: Size;
  characterSrc: string;
  fontType: FontType;
  lineHeight: string;
  letterSpacing: string;
};

export const ReadingViewer: React.FC<ReadingViewerProps> = ({
  text,
  caption,
  size,
  characterSrc,
  fontType,
  lineHeight,
  letterSpacing,
}) => {
  return (
    <>
      <Container
        size={size}
        fontType={fontType}
        lineHeight={lineHeight}
        letterSpacing={letterSpacing}
      >
        <_CharacterImg src={characterSrc} />
        <p className="sentence">
          <_TopLeftImg src="/img/yl_reassesment_enquete/reading_viewer_top_left.png" />
          <_TopRightImg src="/img/yl_reassesment_enquete/reading_viewer_top_right.png" />
          {text}
        </p>
        <p className="caption">{caption}</p>
      </Container>
    </>
  );
};

const _TopLeftImg = styled.img`
  width: 100px;
  z-index: 10;

  position: absolute;

  top: -40px;
  left: -35px;
`;

const _TopRightImg = styled.img`
  width: 100px;
  z-index: 10;

  position: absolute;

  right: 0;
  top: -40px;
  right: -40px;
`;

const _CharacterImg = styled.img`
  position: absolute;

  right: 0;
  bottom: 0;
`;
