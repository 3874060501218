import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useAudio } from "./useAudio";
import { Splash } from "./Splash";
import { StoryMessage } from "./StoryMessage";
import { TapHint } from "./TapHint";
import {
  endOfBookImage,
  lineImages,
  yondemyStories,
  YondemyStorySetting,
} from "./yondemyStory";
import { useScale } from "~/utils/hooks/useScale";

const CONTENT_WIDTH = 376;
const CONTENT_HEIGHT = 812;

type Props = {
  onCompleted: () => void;
};

const allImages = yondemyStories.reduce<string[]>((prev, current) => {
  if (!prev.includes(current.image)) {
    return [...prev, current.image];
  }
  return [...prev];
}, []);

export const YondemyStory: React.FC<Props> = ({ onCompleted }) => {
  const [loaded, setLoaded] = useState<Record<string, boolean>>({});
  const [page, setPage] = useState<number>(0);
  const [closed, setClosed] = useState<boolean>(false);
  const [showSplash, setShowSplash] = useState<boolean>(true);

  const { play } = useAudio({});

  const allImagesLoaded =
    Object.keys(loaded).length ===
    Object.values(loaded).filter((v) => v).length;

  console.log("loaded", allImagesLoaded);

  const previousPage = yondemyStories[page - 1];
  const currentPage = yondemyStories[page];
  const nextPage = yondemyStories[page + 1];

  const ref = useRef<HTMLDivElement>(null);

  const scale = useScale();

  useEffect(() => {
    [...lineImages, ...allImages, endOfBookImage].forEach((src) => {
      const img = document.createElement("img");
      img.onload = () => {
        setLoaded((l) => ({ ...l, [src]: true }));
      };
      img.src = src;
      setLoaded((l) => ({ ...l, [src]: false }));
      ref.current && ref.current.appendChild(img);
    });
  }, []);

  const endPageAudioStop = useRef<() => void>();

  useEffect(() => {
    return () => {
      endPageAudioStop.current && endPageAudioStop.current();
    };
  }, []);

  return (
    <_BG>
      <_YondemyStoryWrapper style={{ scale: `${scale}` }}>
        <_Preload ref={ref} />

        {!showSplash && (
          <Page
            key={`p_${page}`}
            stopped={closed}
            story={currentPage}
            onClick={() => {
              if (page >= yondemyStories.length - 1) {
                play("pageTurn");
                setClosed(true);
                endPageAudioStop.current = play("toBeContinued", 0.5);
                return;
              }

              if (currentPage.image !== nextPage?.image) {
                play("pageTurn");
              }

              setPage((p) => {
                return p + 1;
              });
            }}
          />
        )}
        {page === 0 && !showSplash && (
          <_TapHintWrapper>
            <TapHint />
          </_TapHintWrapper>
        )}

        {previousPage &&
          currentPage.image !== previousPage.image &&
          !closed && (
            <PreviousPage key={`pp_${page}`} image={previousPage.image} />
          )}
        {/* 最後のページでクリックしたら背表紙が←にワイプインする */}
        {closed && (
          <>
            <EndPage onClick={onCompleted} />
            <_TapHintWrapper>
              <TapHint />
            </_TapHintWrapper>
          </>
        )}
        {showSplash && <Splash onClick={() => setShowSplash(false)} />}
      </_YondemyStoryWrapper>
    </_BG>
  );
};

const _BG = styled.div`
  width: 100%;
  background-color: black;
  height: 100vh;
  max-height: 100dvh;
  display: flex;
  justify-content: center;
`;

const _TapHintWrapper = styled.div`
  position: absolute;
  bottom: 105px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const _Preload = styled.div`
  display: none;
`;

const _YondemyStoryWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  transform-origin: 50% top;
  width: ${CONTENT_WIDTH}px;
  height: ${CONTENT_HEIGHT}px;

  position: relative;
`;

type PageProps = {
  story: YondemyStorySetting;
  onClick: () => void;
  stopped?: boolean;
};

const Page: FC<PageProps> = ({
  story: { image, text, lineImage, sound },
  onClick,
  stopped = false,
}) => {
  const { play } = useAudio({ preload: [] });
  const stopPlaying = useRef<() => void>();

  const [messageFinished, setMessageFinished] = useState<boolean>(
    !text // textが空の場合はメッセージ送りが完了したものとして扱う
  );

  useEffect(() => {
    if (stopped && stopPlaying.current) {
      stopPlaying.current();
    }
  }, [stopped]);

  useEffect(() => {
    if (!sound) {
      return;
    }

    const stop = play(sound, 0.5);
    stopPlaying.current = stop;

    // unmoutするとき音を止める
    return () => {
      stop && stop();
    };
  }, []);

  const handleOnClick = useCallback(() => {
    if (!messageFinished) {
      setMessageFinished(true);
      return;
    }

    messageFinished && onClick();
  }, [onClick, messageFinished]);

  return (
    <_PageContainer image={image} onClick={handleOnClick}>
      {text && (
        <_MessageContainer>
          {!stopped && (
            <StoryMessage
              message={text}
              lineImage={lineImage}
              messageFinished={messageFinished}
              onFinished={() => setMessageFinished(true)}
            />
          )}
        </_MessageContainer>
      )}
    </_PageContainer>
  );
};

const _PageContainer = styled.div<{ image: string }>`
  cursor: pointer;
  width: ${CONTENT_WIDTH}px;
  height: ${CONTENT_HEIGHT}px;
  background-image: url(${({ image }) => image});
  background-repeat: no-repeat;
  background-size: cover;

  position: relative;
`;

const _MessageContainer = styled.div`
  position: fixed;
  bottom: 0;
`;

type PreviousPageProps = {
  image: string;
};

const PreviousPage: FC<PreviousPageProps> = ({ image }) => {
  return (
    <_PreviousPageWrapper>
      <_PageContainer image={image} />
    </_PreviousPageWrapper>
  );
};

const _PreviousPageWrapper = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;

  animation: wipe 0.5s ease-out;
  animation-fill-mode: forwards;
  transform-origin: center left;

  @keyframes wipe {
    from {
      transform: perspective(2000px) rotateY(0);
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.9);
      filter: brightness(1);
    }
    to {
      transform: perspective(2000px) rotateY(-90deg);
      box-shadow: 100px 0px 100px 100px rgba(0, 0, 0, 0);
      filter: brightness(0.5);
    }
  }
`;

type EndPageProps = {
  onClick: () => void;
};

const EndPage: FC<EndPageProps> = ({ onClick }) => {
  return (
    <_EndPageWrapper onClick={onClick}>
      <_PageContainer image={endOfBookImage} />
    </_EndPageWrapper>
  );
};

const _EndPageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  animation: end_wipe 0.5s ease-out;
  animation-fill-mode: forwards;
  transform-origin: center right;

  @keyframes end_wipe {
    from {
      transform: perspective(2000px) rotateY(90deg);
      box-shadow: 100px 0px 100px 100px rgba(0, 0, 0, 0);
      filter: brightness(0.5);
    }
    to {
      transform: perspective(2000px) rotateY(0deg);
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.9);
      filter: brightness(1);
    }
  }
`;
