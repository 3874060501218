import React, { useContext, FC } from "react";
import { PwaTutorialTemplate } from "~/components/templates/PwaTutorial";
import { A2HSContext } from "~/contexts/A2HSContext";
import { NotifyTextToast } from "~/components/organisms/toasts/NotifyTextToast";
import { useNavigate } from "react-router-dom";
import { useGetBrowserContent } from "./const";

export const PwaTutorial: FC = () => {
  const { showPwaTutorial } = useContext(A2HSContext);
  const { content, popupIsOpen, popupText, setIsOpen } = useGetBrowserContent();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!showPwaTutorial) {
      navigate("/");
    }
  }, [showPwaTutorial, navigate]);

  React.useEffect(() => {
    if (content === null) {
      navigate("/");
    }
  }, [navigate, content]);

  return (
    <>
      <PwaTutorialTemplate items={content} />
      <NotifyTextToast
        text={popupText}
        isOpen={popupIsOpen}
        setIsOpen={setIsOpen}
      />
    </>
  );
};
