import React, { VFC } from "react";
import styled, { useTheme } from "styled-components";
import { FlatButton } from "~/components/atoms/buttons/FlatButton";
import { Icon } from "~/components/atoms/Icon";
import { Text, TextProps } from "~/components/atoms/texts/Text";
import {
  Anchorize,
  AnchorizeProps,
  Linkerize,
  LinkerizeProps,
} from "~/components/atoms/buttons/tag_changer";
import { ColorTheme } from "~/styles/design_token/color";
import { ColorInterpolation } from "~/styles/interpolation";
import { SecondaryButtonColorTheme } from "../SecondaryButton";

export type SecondaryFlatButtonProps = TextProps & {
  onClick: (event: React.MouseEvent) => void;
  disabled?: boolean;
  color: SecondaryButtonColorTheme;
  text: string;
  withArrow?: boolean;
};

const getInnerColor = (color: SecondaryButtonColorTheme): ColorTheme => {
  if (
    /* colorがColorThemeに存在する色であればそれをそのまま返す */
    color === "pri" ||
    color === "sec" ||
    color === "violet" ||
    color === "green" ||
    color === "red" ||
    color === "sky" ||
    color === "gray" ||
    color === "white"
  ) {
    return color as ColorTheme;
  } else {
    switch (color) {
      case "gray-orange":
        return "pri";
      default:
        return "pri";
    }
  }
};

const getButtonColor = (color: SecondaryButtonColorTheme): ColorTheme => {
  if (
    color === "pri" ||
    color === "sec" ||
    color === "violet" ||
    color === "green" ||
    color === "red" ||
    color === "sky" ||
    color === "gray" ||
    color === "white"
  ) {
    return color as ColorTheme;
  } else {
    switch (color) {
      case "gray-orange":
        return "gray";
      default:
        return "pri";
    }
  }
};

export const SecondaryFlatButton: VFC<SecondaryFlatButtonProps> = ({
  onClick,
  disabled = false,
  color,
  text,
  withArrow = false,
  ...textProps
}) => {
  const theme = useTheme();
  const { fontSize } = textProps;
  return (
    <FlatButton
      color={getButtonColor(color)}
      onClick={onClick}
      shape={"Normal"}
      variant={"Outline"}
      disabled={disabled}
    >
      <LeftSide></LeftSide>
      <Center>
        <InnerText {...textProps} color={getInnerColor(color)}>
          {text}
        </InnerText>
      </Center>
      <RightSide>
        {withArrow && (
          <Icon
            color={getInnerColor(color)}
            name={"arrowRight"}
            style={{ height: theme.fontSize[fontSize], width: "auto" }}
          />
        )}
      </RightSide>
    </FlatButton>
  );
};

export type SecondaryFlatLinkProps = Omit<SecondaryFlatButtonProps, "onClick"> &
  Omit<LinkerizeProps, keyof SecondaryFlatButtonProps>;

export const SecondaryFlatLink: VFC<SecondaryFlatLinkProps> = ({
  color,
  text,
  disabled = false,
  withArrow,
  fontSize,
  bold,
  lineHeight,
  ...linkProps
}) => (
  <Linkerize {...linkProps} disabled={disabled}>
    <SecondaryFlatButton
      color={color}
      fontSize={fontSize}
      lineHeight={lineHeight}
      bold={bold}
      onClick={() => {
        return;
      }}
      disabled={disabled}
      text={text}
      withArrow={withArrow}
    />
  </Linkerize>
);

export type SecondaryFlatAnchorProps = Omit<
  SecondaryFlatButtonProps,
  "onClick"
> &
  Partial<Pick<SecondaryFlatButtonProps, "onClick">> &
  Omit<AnchorizeProps, keyof SecondaryFlatButtonProps>;

export const SecondaryFlatAnchor: VFC<SecondaryFlatAnchorProps> = ({
  color,
  text,
  disabled = false,
  withArrow,
  fontSize,
  bold,
  lineHeight,
  onClick,
  ...anchorProps
}) => (
  <Anchorize {...anchorProps} disabled={disabled}>
    <SecondaryFlatButton
      color={color}
      fontSize={fontSize}
      lineHeight={lineHeight}
      bold={bold}
      onClick={(event) => {
        onClick && onClick(event);
      }}
      disabled={disabled}
      text={text}
      withArrow={withArrow}
    />
  </Anchorize>
);

const LeftSide = styled.div`
  width: 10%;
`;

const Center = styled.div`
  width: 80%;
`;

const RightSide = styled.div`
  width: 10%;
`;

const InnerText = styled(Text)<{ color: ColorTheme }>`
  ${ColorInterpolation.Sub}
  text-align: center;
`;
