import React, { VFC } from "react";
import styled from "styled-components";
import { Heading3 } from "~/components/atoms/texts/Heading3";

type SectionTitleProps = {
  children: React.ReactNode;
};

export const SectionTitle: VFC<SectionTitleProps> = ({ children }) => {
  return (
    <TitleStyle fontSize={"LG"} lineHeight={"MD"} bold>
      {children}
    </TitleStyle>
  );
};

const TitleStyle = styled(Heading3)`
  color: ${({ theme }) => theme.colors.tex.t800};
`;
