import React, { useEffect, VFC } from "react";
import styled from "styled-components";
import { Slider } from "~/components/atoms/forms/Slider";
import { IconButton } from "~/components/molecules/buttons/IconButton";
import { Wrapper } from "~/components/templates/Common";
import { RecommendTutorialProps } from "..";
import { images } from "../images";
import { TutorialStyle as s } from "../style";
import { useReplayableGIF } from "../useReplayableGIF";

export type BeUnderTwoBooksProps = RecommendTutorialProps & { border?: number };

export const BeUnderTwoBooks: VFC<BeUnderTwoBooksProps> = ({
  handleChangeStep,
  handleGoNext,
  handleGoBack,
  step,
  STEP_MAX,
  border = 2,
}) => {
  const { getGif, setGif } = useReplayableGIF();

  useEffect(() => {
    return () => {
      setGif(images.BeUnderTwoBooks);
    };
  }, []);

  return (
    <Wrapper>
      <SliderWrapper>
        <IconButton
          name="arrowLeft"
          fontSize="XXXL"
          onClick={handleGoBack}
          color="sec"
        />
        <Slider
          min={0}
          max={STEP_MAX}
          type="range"
          value={step}
          onChange={(event) => handleChangeStep(Number(event.target.value))}
        />
      </SliderWrapper>
      <s.ContentContainer>
        <s.ImageContainer>
          <Img src={getGif(images.BeUnderTwoBooks)} />
        </s.ImageContainer>
        <s.WordingContainer>
          <s.Text fontSize={"XXL"} lineHeight={"MD"} bold>
            おすすめリストの残りが
          </s.Text>
          <s.Text fontSize={"XXL"} lineHeight={"MD"} bold>
            {border}冊以下になったら、
          </s.Text>
        </s.WordingContainer>
      </s.ContentContainer>
      <s.BottomButton onClick={handleGoNext}>次へ</s.BottomButton>
    </Wrapper>
  );
};

const SliderWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
  padding-top: 32px;
`;

const Img = styled.img`
  height: 100%;
`;
