import React, { VFC } from "react";
import { EditProfileTemplate } from "~/components/templates/EditProfile";
import { ProfileFormState } from "~/components/templates/EditProfile/type";
import { LoadingPage } from "~/components/templates/Loading/LoadingPage";
import {
  KanziLevel,
  MissionOrder,
  MissionState,
  useGetMissionProgressQuery,
  useGetMyProfileQuery,
  UserIconType,
  useUpdateProfileMutation,
} from "~/generated/graphql";

export const EditProfile: VFC = () => {
  const [profileForm, setProfileForm] = React.useState<ProfileFormState>({
    nickname: "",
    userIconType: UserIconType.Worm,
  });
  const onChangeProfile = (
    key: keyof typeof profileForm,
    value: string | KanziLevel | number
  ): void => {
    setProfileForm({
      ...profileForm,
      [key]: value,
    });
  };

  const {
    data: missionProgressData,
    loading: missionProgressLoading,
    error: missionProgressError,
  } = useGetMissionProgressQuery();

  const { loading, error, data, refetch } = useGetMyProfileQuery({
    onCompleted: () => {
      setProfileForm({
        nickname: data?.me.general.nickname ?? (null as unknown as string),
        userIconType:
          data?.me.general.userIconType ?? (null as unknown as UserIconType),
      });
    },
  });

  const [updateProfile] = useUpdateProfileMutation({
    update(cache, result) {
      result.data?.updateAccount &&
        cache.modify({
          fields: {
            me(currentMe) {
              return Object.assign(
                {},
                currentMe,
                result.data?.updateAccount || {}
              ); //何も分からん
            },
          },
        });
    },
  });

  const handleUpdate = ({
    nickname,
    userIconType,
  }): void => {
    updateProfile({
      variables: {
        accountInfo: {
          nickname: nickname,
          userIconType: userIconType,
        },
      },
    }).then(() => refetch());
  };

   const handleUpdateIcon = (iconType: UserIconType): void => {
    updateProfile({
      variables: {
        accountInfo: {
          nickname: data?.me.general.nickname,
          userIconType: iconType,
        },
      },
    })
  };

  if (loading || missionProgressLoading || !data || !missionProgressData)
    return <LoadingPage />;
  if (error) return <>Error! {error.message}</>;
  if (missionProgressError) return <>Error! {missionProgressError.message}</>;
  return (
    <>
      <EditProfileTemplate
        user={data.me.general}
        onChangeProfile={onChangeProfile}
        handleUpdate={handleUpdate}
        handleUpdateIcon={handleUpdateIcon}
        missionOrder={
          missionProgressData.getMissionProgress.missionOrder ||
          MissionOrder.Mission_1
        }
        missionState={
          missionProgressData.getMissionProgress.missionState ||
          MissionState.NeedStart
        }
      />
    </>
  );
};
