import React from "react";
import { IndicateExperiencePointTemplate } from "~/components/templates/PostReadLog/IndicateExperiencePoint";
import { usePostReadLogState } from "../usePostReadLogState";

export const IndicateExperiencePoint: React.FC = () => {
  const { dispatch } = usePostReadLogState();

  const handleNext = () => {
    dispatch({ type: "setFlow", payload: "indicateUnlock" });
  };

  return <IndicateExperiencePointTemplate handleNext={handleNext} />;
};
