import { ReadingTechniqueType } from "~/generated/graphql";
import { theme } from "~/styles/theme";

export const techniqueLabel: Record<ReadingTechniqueType, string> = {
  IMAGINE: "おもいえがく",
  CONNECT: "つなげる",
  QUESTION: "しつもんする",
  PREDICT: "よそうする",
  ACKNOWLEDGE: "みとめる",
  JUDGE: "みきわめる",
  INTERPRET: "かいしゃくする",
};

export const ColorOfReadingTechnique: Record<ReadingTechniqueType, string> = {
  IMAGINE: theme.colors.primitive.red.r400,
  CONNECT: theme.colors.primitive.orange.o400,
  QUESTION: theme.colors.primitive.yellow.y400,
  PREDICT: theme.colors.primitive.green.g400,
  ACKNOWLEDGE: theme.colors.primitive.sky.s400,
  JUDGE: theme.colors.primitive.blue.b400,
  INTERPRET: theme.colors.primitive.violet.v400,
};
