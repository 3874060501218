import { useApolloClient } from "@apollo/client";
import * as React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { LoadingPage } from "~/components/templates/Loading/LoadingPage";
import { SwitchUserContext } from "~/contexts/SwitchUserContext";
import { useGetUserAvailabilitiesForVerificationQuery } from "~/generated/graphql";
import { SwitchUserTemplate } from "~/components/templates/SwitchUser";

export const SetUserContainer: React.FC = () => {
  const { user_id } = useParams<{ user_id: string }>();
  const client = useApolloClient();
  const { switchUser } = React.useContext(SwitchUserContext);
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const path = params.get("path");

  const { data, loading } = useGetUserAvailabilitiesForVerificationQuery({
    onCompleted: (data) => {
      if (!user_id) return;

      client.clearStore();
      if (data.getUsers.some((user) => user.userID === parseInt(user_id, 10))) {
        // user_idが有効な値ならContextのuser_idを更新する
        switchUser(parseInt(user_id, 10));
      } else {
        // user_idが無効な値ならContextのuser_idをundefinedにする
        switchUser(undefined);
      }

      // NOTE: navigateを少し遅延させないとqueryの結果を正しく画面に表示できない
      setTimeout(() =>
        navigate(path ? decodeURI(path) : "/", { replace: true })
      );
    },
    fetchPolicy: "network-only",
  });

  if (loading) {
    return <LoadingPage />;
  }

  if (!user_id) {
    return (
      <SwitchUserTemplate
        userAvailabilities={data?.getUsers || []}
        onLogin={(userId: number) => {
          client.clearStore();
          switchUser(userId);
          navigate(path ? decodeURI(path) : "/", { replace: true });
        }}
      />
    );
  }

  return null;
};
