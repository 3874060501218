import React from "react";
import { ReadingTechniqueType } from "~/generated/graphql";
import {
  SimplifiedReadLogMessage,
  SimplifiedReadLogMessageProps,
} from "./SimplifiedReadLogMessage";
import { ConfirmStarModal } from "./ConfirmStarModal";
import { SelectStarModalFooter } from "./SelectStarModalFooter";
import { TechniqueDescriptionModal } from "../../../../modals/TechniqueDescriptionModal";
import { BottomModal } from "~/components/atoms/wrappers/BottomModal";
import { Stack } from "~/components/atoms/layout/Stack";

export type SendStarModalProps = {
  onSubmit: (readingTechniquType: ReadingTechniqueType) => Promise<boolean>;
  isOpen: boolean;
  onClose: () => void;
  readLogMessageProps: SimplifiedReadLogMessageProps;
};

export const SendStarModal: React.FC<SendStarModalProps> = ({
  onSubmit,
  isOpen,
  onClose,
  readLogMessageProps,
}) => {
  const [isOpenDescriptionModal, setIsOpenDescriptionModal] =
    React.useState<boolean>(false);
  const [techniqueType, setTechniqueType] = React.useState<
    ReadingTechniqueType | undefined
  >(undefined);
  return (
    <>
      {techniqueType === undefined ? (
        <>
          <BottomModal
            open={isOpen}
            onClose={onClose}
            closeOnOverlayClick={false}
          >
            <Stack rowGap="16px" alignItems="center">
              <SimplifiedReadLogMessage {...readLogMessageProps} />
              <SelectStarModalFooter
                handleNext={(readingTechniqueType: ReadingTechniqueType) => {
                  setTechniqueType(readingTechniqueType);
                }}
                onClose={onClose}
                onOpenDescriptionModal={() => setIsOpenDescriptionModal(true)}
                isOpenDescriptionModal={isOpenDescriptionModal}
              />
            </Stack>
            <TechniqueDescriptionModal
              onClose={() => setIsOpenDescriptionModal(false)}
              isOpen={isOpenDescriptionModal}
            />
          </BottomModal>
        </>
      ) : (
        <ConfirmStarModal
          onBack={() => setTechniqueType(undefined)}
          onSubmit={() => onSubmit(techniqueType)}
          readingTechniqueType={techniqueType}
          isOpen={Boolean(techniqueType)}
        />
      )}
    </>
  );
};
