import React from "react";
import styled from "styled-components";
import { useUnlock } from "~/store/unlock/useUnlock";

const bookWormKeyUrl = "/img/unlock/book_worm_key.svg";

type WantReadBookButtonLockWrapperProps = {
  children: React.ReactNode;
};

export const WantReadBookButtonLockWrapper: React.FC<
  WantReadBookButtonLockWrapperProps
> = ({ children }) => {
  const { isLocked } = useUnlock();
  const isWantReadBookLocked = isLocked("wantReadBook");
  const isBookFriendLocked = isLocked("bookFriend");
  if (!isWantReadBookLocked) {
    return <>{children}</>;
  }
  if (!isBookFriendLocked) {
    return (
      <_Wrapper>
        <_BookWarmKey src={bookWormKeyUrl} />
        <_LockComment>レベル25になると？</_LockComment>
      </_Wrapper>
    );
  }
  return <_InvisibleWrapper>{children}</_InvisibleWrapper>;
};

const _Wrapper = styled.div`
  border-radius: ${({ theme }) => theme.deprecatedBorderRadius.S};
  background-color: ${({ theme }) => theme.colors.base.white};
  padding: 6px 12px;

  display: flex;
  justify-content: center;
  gap: 2px;
  align-items: center;

  width: 100%;
`;

const _InvisibleWrapper = styled.div`
  visibility: hidden;
`;

const _LockComment = styled.p`
  color: ${({ theme }) => theme.colors.pri.p500};
  font-weight: normal;
  font-size: ${({ theme }) => theme.fontSize.XS};
`;

const _BookWarmKey = styled.img`
  width: 18px;
  height: 21px;
  object-fit: contain;
`;
