import * as React from "react";
import {
  MinilessonFontSizeType,
  MinilessonFontSizeTypeList,
} from "~/components/organisms/adminMessage/AdminMessageListItem/messageListItems/constant";
import { usePreference } from "~/store/preference/usePreference";

type MiniLessonContextValue = {
  fontSize: MinilessonFontSizeType;
  updateFontSize: (v: number) => void;
};

export const MiniLessonContext = React.createContext<MiniLessonContextValue>({
  fontSize: "medium",
  updateFontSize: () => {
    throw new Error("MiniLessonContextProvider is not provided.");
  },
});

type MiniLessonContextProviderProps = { children: React.ReactNode };

export const MiniLessonContextProvider: React.FC<
  MiniLessonContextProviderProps
> = ({ children }) => {
  const { preference, savePreference } = usePreference();

  const minilessonFontSize = React.useMemo<MinilessonFontSizeType>(() => {
    return MinilessonFontSizeTypeList[
      typeof preference?.minilessonFontSize === "number"
        ? preference.minilessonFontSize
        : 1
    ];
  }, [preference?.minilessonFontSize]);

  const updateFontSize = React.useCallback(
    (v: number) => {
      savePreference({
        minilessonFontSize: v,
      });
    },
    [savePreference]
  );

  return (
    <MiniLessonContext.Provider
      value={{ fontSize: minilessonFontSize, updateFontSize: updateFontSize }}
    >
      {children}
    </MiniLessonContext.Provider>
  );
};
