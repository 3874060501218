import React from "react";
import { ReadingTechniqueType } from "~/generated/graphql";
import { theme } from "~/styles/theme";

export const ColorOfReadingTechnique: Record<ReadingTechniqueType, string> = {
  IMAGINE: theme.colors.primitive.red.r400,
  CONNECT: theme.colors.primitive.orange.o400,
  QUESTION: theme.colors.primitive.yellow.y400,
  PREDICT: theme.colors.primitive.green.g400,
  ACKNOWLEDGE: theme.colors.primitive.sky.s400,
  JUDGE: theme.colors.primitive.blue.b400,
  INTERPRET: theme.colors.primitive.violet.v400,
};

type StarIconProps = {
  readingTechniqueType: ReadingTechniqueType;
  size?: number; //px
  checked: boolean;
};

export const StarIcon: React.FC<StarIconProps> = ({
  readingTechniqueType,
  size,
  checked,
}) => {
  return (
    <>
      {checked ? (
        <svg
          width={size || "20"}
          height={size || "20"}
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.65742 2.35748L8.65733 2.35767L6.73931 6.24658L2.44833 6.87217C2.44821 6.87219 2.44809 6.8722 2.44797 6.87222C1.22145 7.0504 0.731323 8.56076 1.61975 9.42602C1.6198 9.42607 1.61984 9.42611 1.61989 9.42616L4.72421 12.4512L3.98993 16.7247L3.98988 16.725C3.77925 17.956 5.07462 18.8766 6.16133 18.3015C6.16169 18.3013 6.16204 18.3011 6.1624 18.3009L9.99984 16.2839L13.8388 18.3017C14.9222 18.8715 16.221 17.9594 16.0098 16.725L16.0097 16.7247L15.2755 12.4512L18.3798 9.42616C18.3798 9.42611 18.3799 9.42607 18.3799 9.42602C19.2684 8.56076 18.7782 7.0504 17.5517 6.87222C17.5517 6.87222 17.5517 6.87221 17.5516 6.87221C17.5515 6.8722 17.5514 6.87218 17.5513 6.87217L13.2604 6.24658L11.3423 2.35767L11.3419 2.35675C10.7963 1.25619 9.21102 1.23622 8.65742 2.35748Z"
            fill={ColorOfReadingTechnique[readingTechniqueType]}
            stroke={ColorOfReadingTechnique[readingTechniqueType]}
            strokeLinejoin="round"
          />
        </svg>
      ) : (
        <svg
          width={size || "20"}
          height={size || "20"}
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.65742 2.35748L8.65733 2.35767L6.73931 6.24658L2.44833 6.87217C2.44821 6.87219 2.44809 6.8722 2.44797 6.87222C1.22145 7.0504 0.731323 8.56076 1.61975 9.42602C1.6198 9.42607 1.61984 9.42611 1.61989 9.42616L4.72421 12.4512L3.98993 16.7247L3.98988 16.725C3.77925 17.956 5.07462 18.8766 6.16133 18.3015C6.16169 18.3013 6.16204 18.3011 6.1624 18.3009L9.99984 16.2839L13.8388 18.3017C14.9222 18.8715 16.221 17.9594 16.0098 16.725L16.0097 16.7247L15.2755 12.4512L18.3798 9.42616C18.3798 9.42611 18.3799 9.42607 18.3799 9.42602C19.2684 8.56076 18.7782 7.0504 17.5517 6.87222C17.5517 6.87222 17.5517 6.87221 17.5516 6.87221C17.5515 6.8722 17.5514 6.87218 17.5513 6.87217L13.2604 6.24658L11.3423 2.35767L11.3419 2.35675C10.7963 1.25619 9.21102 1.23622 8.65742 2.35748Z"
            stroke={ColorOfReadingTechnique[readingTechniqueType]}
            strokeLinejoin="round"
          />
        </svg>
      )}
    </>
  );
};
