import React from "react";
import styled from "styled-components";
import { PrimaryButton } from "~/components/molecules/buttons/PrimaryButton";
import { Stack } from "~/components/atoms/layout/Stack";
import { ColorTheme } from "~/styles/design_token/color";
import { NotificationLineLink } from "../../../../../../../util/constant";
import { IconTextAnchor } from "~/components/molecules/buttons/IconTextButton";
import { CSMailAnchorLink } from "../../../../../../../util/constant";

export type SuitableYlNotFoundProps = {
  enqueteType: EnqueteType;
  onQuit: () => void;
};

type ButtonProps = {
  text: string;
  color: ColorTheme;
  onClick: () => void;
};

type EnqueteType = "yl_reassessment_enquete" | "character_assessment";

type SentenceList = {
  title: string;
  message: string;
};

const suitableYlNotFoundSentence: Record<EnqueteType, SentenceList> = {
  yl_reassessment_enquete: {
    title: "YL相談所",
    message:
      "復会直後のため、適切なYL相談を受けられません。\n以下からお問い合わせください。",
  },
  character_assessment: {
    title: "ジャンル心理テスト",
    message: "復会直後のため、適切な本をおすすめできません。\n以下からお問い合わせください。",
  },
};

export const SuitableYlNotFound: React.FC<SuitableYlNotFoundProps> = ({
  enqueteType,
  onQuit,
}) => {
  const buttons: ButtonProps[] = React.useMemo(() => {
    return [
      {
        color: "line",
        text: "LINEで問い合わせる",
        onClick: () => {
          window.location.href = NotificationLineLink;
        },
      },
      {
        color: "gray",
        text: "今はやめる",
        onClick: onQuit,
      },
    ];
  }, [onQuit]);

  return (
    <_Wrapper>
      <Stack rowGap="32px">
        <_Box>
          <Stack rowGap="32px">
            <_Title>{suitableYlNotFoundSentence[enqueteType].title}</_Title>
            <_SenseiImage src="/img/sensei/standup_5.svg" />
            <Stack rowGap="16px">
              <_Text>{suitableYlNotFoundSentence[enqueteType].message}</_Text>
            </Stack>
          </Stack>
        </_Box>
        <Stack rowGap="16px" alignItems="center">
          {buttons.map((button, index) => {
            return (
              <PrimaryButton
                key={`$${index}`}
                color={button.color}
                fontSize="XL"
                lineHeight="LG"
                text={button.text}
                onClick={button.onClick}
                bold
              />
            );
          })}
          <_MailWrapper>
            <IconTextAnchor
              fontSize="SM"
              lineHeight="EQ"
              text="メールの方はこちら"
              name="envelope"
              color="sec"
              iconPlace="pre"
              href={CSMailAnchorLink}
            />
          </_MailWrapper>
        </Stack>
      </Stack>
    </_Wrapper>
  );
};

const _Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  padding: 80px 6% 0;
  @media screen and (min-width: 600px) {
    padding: 80px 10% 0;
  }
  @media screen and (min-width: 1024px) {
    padding: 80px 20% 0;
  }
  background-color: ${({ theme }) => theme.colors.base.background};
`;

const _Box = styled.div`
  padding: 32px 16px;
  background-color: ${({ theme }) => theme.colors.base.white};
  border-radius: 16px;

  color: ${({ theme }) => theme.colors.tex.t700};
  font-size: 16px;
  line-height: 160%;
`;

const _SenseiImage = styled.img`
  max-width: 120px;
  width: 30%;
  margin: 0 auto;
`;

const _Text = styled.p`
  font-size: ${({ theme }) => theme.fontSize.MD};
  color: ${({ theme }) => theme.colors.tex.t800};
  line-height: 160%;
  font-weight: 700;
  text-align: justify;
  white-space: pre-wrap;
`;

const _Title = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.XL};
  color: ${({ theme }) => theme.colors.tex.t800};
  line-height: 160%;
  font-weight: 700;
  text-align: start;
  white-space: pre-wrap;
`;

const _MailWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 8px;
`;
