import React from "react";
import { Toast } from "../Toast";
import { ToastProps } from "../Toast";

export type ClosableToastProps = {
  isOpen: boolean;
  onClose: () => void;
  closeAfter: number;
  closeOnClick?: boolean;
} & Pick<
  ToastProps,
  | "color"
  | "buttonLabel"
  | "children"
  | "icon"
  | "stickPlace"
  | "underBoxChildren"
>;

export const ClosableToast: React.FC<ClosableToastProps> = ({
  isOpen,
  onClose,
  closeAfter,
  closeOnClick = false,
  children,
  ...toastProps
}) => {
  React.useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        onClose();
      }, closeAfter);
    }
  }, [isOpen, closeAfter, onClose]);

  const handleClick = React.useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      onClose();
    },
    [onClose]
  );

  if (!isOpen) {
    return null;
  }

  return (
    <Toast onClick={closeOnClick ? handleClick : undefined} {...toastProps}>
      {children}
    </Toast>
  );
};
