import React from "react";
import styled from "styled-components";
import { Icon } from "~/components/atoms/Icon";
import { Row } from "~/components/atoms/layout/Row";
import { Stack } from "~/components/atoms/layout/Stack";
import { Heading4 } from "~/components/atoms/texts/Heading4";
import { Text } from "~/components/atoms/texts/Text";
import { RecommendStatus } from "~/generated/graphql";
import { IMAGE_PATHS } from "~/helpers/constants/imagePath";
import { theme } from "~/styles/theme";
import { TimeStamp } from "~/components/molecules/texts/TimeStamp";
import { useLikeChoices } from "~/components/templates/PostReadLog/ConfirmLike";
import { useTranslation } from "~/i18n";

export type BookInfoCardProps = {
  title: string;
  imageUrl?: string;
  recommendStatus?: RecommendStatus;
  isRecommend?: boolean;
  isInYomimashoka: boolean;
  yl?: number;
  onClick?: () => void;
  review?: string;
  date?: Date;
  like?: number;
};

type RecommendStatusInfo = {
  backgroundColor: string;
  fontColor: string;
  status: string;
};

const recommendStatusInfo: Record<
  Exclude<RecommendStatus, "QUIT" | "DISCARDED" | "DEFAULT">,
  RecommendStatusInfo
> = {
  "READING": {
    backgroundColor: theme.colors.primitive.red.r0,
    fontColor: theme.colors.primitive.red.r400,
    status: "よんでいる",
  },
  "ENDED": {
    backgroundColor: theme.colors.primitive.green.g100,
    fontColor: theme.colors.primitive.green.g600,
    status: "よんだ",
  },
};

export const BookInfoCard: React.FC<BookInfoCardProps> = ({
  title,
  imageUrl,
  recommendStatus,
  isRecommend,
  isInYomimashoka,
  yl,
  onClick,
  review,
  date,
  like,
}) => {
  const showRecommendStatus =
    recommendStatus === RecommendStatus.Reading ||
    recommendStatus === RecommendStatus.Ended;

  const { t } = useTranslation();
  const likeChoices = useLikeChoices(t);

  return (
    <_Wrapper
      onClick={onClick}
      style={{ cursor: onClick ? "pointer" : "default" }}
    >
      <_BookImg src={imageUrl || IMAGE_PATHS.NO_IMAGE} alt={title} />
      <_InfoContainer>
        {(isRecommend || showRecommendStatus) && (
          <Row columnGap="4px">
            {isRecommend && <_RecommendMarker>おすすめ</_RecommendMarker>}
            {showRecommendStatus && (
              <_RecommendStatus recommendStatus={recommendStatus}>
                {recommendStatusInfo[recommendStatus].status}
              </_RecommendStatus>
            )}
          </Row>
        )}
        <Stack rowGap="4px">
          <Row columnGap="4px">
            {yl && (
              <_OrangeMarker>
                <Text
                  fontColor="semantic.primary.plus1"
                  fontSize="XS"
                  bold
                  lineHeight="MD"
                >
                  YL.{Math.floor(yl / 100)}
                </Text>
              </_OrangeMarker>
            )}
            {like && (
              <_OrangeMarker>
                <Text fontSize="XS" lineHeight="MD">
                  {likeChoices[like - 1].emoji}
                </Text>
              </_OrangeMarker>
            )}
          </Row>
          <Heading4
            color="semantic.text.heading"
            fontSize="MD"
            bold
            lineHeight="MD"
          >
            {title}
          </Heading4>
          {date && (
            <TimeStamp
              time={date}
              textAlign="left"
              fontSize="XS"
              showYear={true}
            />
          )}
        </Stack>
        {review && (
          <_Text fontSize="XS" lineHeight="MD">
            {review}
          </_Text>
        )}
        {isInYomimashoka && (
          <_YomimashokaMarkerWrapper>
            <Icon
              name="clipboardCheck"
              color="gray"
              style={{ width: "12px", height: "12px" }}
            />
            <Text
              fontSize="XS"
              fontColor="semantic.text.placeholder"
              lineHeight="EQ"
            >
              よみま書架にいれた
            </Text>
          </_YomimashokaMarkerWrapper>
        )}
      </_InfoContainer>
    </_Wrapper>
  );
};

const _Wrapper = styled.div`
  width: 100%;
  max-width: 600px;
  padding: 12px;
  margin: 0 auto;

  border: 1px solid ${({ theme }) => theme.colors.primitive.monotone.m40};
  border-radius: 12px;
  background-color: ${({ theme }) =>
    theme.colors.semantic.layout.backgroundBox};
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 12px;
`;

const _BookImg = styled.img`
  width: 80px;
  min-height: 100px;
  object-fit: cover;
  border-radius: 8px;
`;

const _InfoContainer = styled.div`
  width: calc(100% - 80px - 12px);
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

const _OrangeMarker = styled.div`
  width: fit-content;
  padding: 0px 5px;
  background-color: ${({ theme }) => theme.colors.primitive.orange.o100};
  border-radius: 4px;
`;

const _RecommendStatus = styled.div<{ recommendStatus: RecommendStatus }>`
  width: fit-content;
  padding: 2px 8px;
  background-color: ${({ recommendStatus }) =>
    recommendStatusInfo[recommendStatus].backgroundColor};
  color: ${({ recommendStatus }) =>
    recommendStatusInfo[recommendStatus].fontColor};
  border-radius: 12px;
  font-size: ${({ theme }) => theme.fontSize.XS};
  line-height: ${({ theme }) => theme.lineHeight.MD};
  font-weight: bold;
`;

const _RecommendMarker = styled.div`
  width: fit-content;
  padding: 2px 8px;
  background-color: ${({ theme }) => theme.colors.semantic.primary.main};
  color: ${({ theme }) => theme.colors.primitive.monotone.m0};
  border-radius: 12px;
  font-size: ${({ theme }) => theme.fontSize.XS};
  line-height: ${({ theme }) => theme.lineHeight.MD};
  font-weight: bold;

  display: flex;
  column-gap: 4px;
  align-items: center;

  ::before {
    width: 16px;
    height: 16px;
    content: "";
    background-image: url("/img/sensei/icon_smile.jpg");
    background-size: contain;
    border-radius: 50%;
  }
`;

const _YomimashokaMarkerWrapper = styled.div`
  width: 100%;
  padding: 8px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 3px;
`;

const _Text = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
