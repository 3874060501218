import React, { VFC } from "react";
import styled from "styled-components";
import { Loading } from "~/components/atoms/Loading";

export const LoadingPage: VFC = () => {
  return (
    <Wrapper>
      <Loading size="m" type="primary" />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;