import { ApolloError } from "@apollo/client";
import React, { createContext, FC, ReactNode, useContext } from "react";
import {
  CurrentUserFragment,
  CurrentUserFragmentDoc,
  TutorialFlag,
  TutorialFlagKey,
  useGetCurrentUserQuery,
  UserExperience,
  useGetUserAvailabilitiesQuery,
  UserPlan,
  UserAvailability,
  UserExperienceEvent,
} from "~/generated/graphql";
import { setUser } from "@sentry/react";
import { config } from "../../../config";
import { useTutorialFlags } from "~/store/tutorialFlags/useTutorialFlags";
import {
  UpdatedUserExperience,
  useExperiencePoint,
  UserExperienceSummary,
} from "~/store/experiencePoint/useExperiencePoint";
import { SwitchUserContext } from "../SwitchUserContext";
import { setUserProperties } from "~/utils/googleAnalytics/userProperties";

type CurrentUser = CurrentUserFragment;

type CurrentUserContextValue = {
  currentUser?: CurrentUser;
  loading: boolean;
  refetch: () => void;
  error?: ApolloError;
  updateCahce: (updateFn: (cached: CurrentUser) => CurrentUser) => void;
  tutorialFlags?: TutorialFlag[];
  getTutorialFlag: (value: TutorialFlagKey) => boolean;
  saveTutorialFlags: (value: TutorialFlagKey) => void;
  userExperience?: UserExperienceSummary;
  updatedUserExperience?: UpdatedUserExperience;
  updateExperience: (
    userExperience: UserExperience,
    achieveExperienceEvents: UserExperienceEvent[]
  ) => void;
  userAvailabilities: UserAvailability[];
  isDoneFirstMiniLesson: boolean;
};

const defaultValue: CurrentUserContextValue = {
  loading: false,
  refetch: () => {
    return;
  },
  updateCahce: () => {
    return;
  },
  getTutorialFlag: () => true,
  saveTutorialFlags: () => {
    return;
  },
  updateExperience: () => {
    return;
  },
  userAvailabilities: [],
  isDoneFirstMiniLesson: true,
};

export const CurrentUserContext =
  createContext<CurrentUserContextValue>(defaultValue);

type Props = {
  children: ReactNode;
};

export const CurrentUserProvider: FC<Props> = ({ children }) => {
  const {
    userExperience,
    updatedUserExperience,
    updateExperience,
    setCurrentUserExperienceSummary,
  } = useExperiencePoint();

  const { userId, switchUser, setShouldSwitchUser } =
    useContext(SwitchUserContext);

  const { loading: userAvailabilitiesLoading, data: userAvailabilitiesData } =
    useGetUserAvailabilitiesQuery({
      onCompleted: (res) => {
        if (res.getUsers) {
          // 複数ユーザーが存在する場合は初回ログイン時にユーザーを選択させる
          if (userId === undefined && res.getUsers.length > 1) {
            setShouldSwitchUser(true);
            return;
          }

          // 現在選択されているユーザー(userIdがundefinedの場合は0番目のユーザー)
          const currentUserAvailability =
            userId === undefined
              ? res.getUsers[0]
              : res.getUsers.find((u) => u.userID === userId);

          // 指定されたuserAccuontが存在する場合
          if (currentUserAvailability) {
            // 指定されたuserAvailabilityが休会中の場合
            if (currentUserAvailability.userPlan === UserPlan.Invalidation) {
              setShouldSwitchUser(true);
            }
          } else {
            // userAvailabilitiesに存在しないuserIdが指定されていた場合はデフォルト値にし、ユーザー選択画面に飛ばす
            console.log("invalid な userId in getUserAvailCompleted");
            switchUser(undefined);
            setShouldSwitchUser(true);
          }
        }
      },
    });

  const { refetch, data, loading, client, error } = useGetCurrentUserQuery({
    onCompleted: (res) => {
      setUser({
        id: `${res.me.general.id}`,
      });

      setUserProperties({
        user_id: `${res.me.general.id}`,
      });

      setCurrentUserExperienceSummary(res.me.general.userExperience);

      window.setTimeout(() => {
        if (typeof window?.clarity === "function") {
          window.clarity("identify", `${res.me.general.id}`);
          window.clarity("set", "env", config.ENV);
          window.clarity("set", "user_id", `${res.me.general.id}`);
        }
      }, 3000);
    },
  });

  const { tutorialFlags, getTutorialFlag, saveTutorialFlags } =
    useTutorialFlags({ userId: data?.me.general.id });

  return (
    <CurrentUserContext.Provider
      value={{
        currentUser: data?.me,
        userAvailabilities: userAvailabilitiesData?.getUsers || [],
        loading: loading && userAvailabilitiesLoading,
        refetch: () => {
          refetch();
        },
        error,
        updateCahce: (updateFn: (cached: CurrentUser) => CurrentUser) => {
          if (!data?.me) {
            return;
          }
          const cachedUser = client.readFragment<CurrentUser>({
            fragmentName: "CurrentUser",
            fragment: CurrentUserFragmentDoc,
            id: client.cache.identify({
              ...data.me,
            }),
          });
          if (cachedUser) {
            client.writeFragment<CurrentUser>({
              fragmentName: "CurrentUser",
              fragment: CurrentUserFragmentDoc,
              data: {
                ...updateFn(cachedUser),
              },
            });
          }
        },
        tutorialFlags,
        getTutorialFlag,
        saveTutorialFlags,
        userExperience,
        updatedUserExperience,
        updateExperience,
        isDoneFirstMiniLesson: data ? data.isDoneFirstMiniLesson : true,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};
