import * as React from "react";
import styled from "styled-components";
import { Slider } from "~/components/atoms/forms/Slider";
import { Icon } from "~/components/atoms/Icon";
import { useSnd } from "~/utils/hooks/useSnd";

const VolumeIcon = styled(Icon)`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.base.gray};
`;

export const SoundSetting: React.VFC = () => {
  const { play, setMuted, setVolume, volume } = useSnd();

  return (
    <_Container>
      <VolumeIcon name="volumeLow" />
      <Slider
        value={volume}
        type="range"
        max={10}
        min={0}
        onChange={(event) => {
          const v = parseInt(event.target.value);
          play("TAP", { volume: v, forcePlay: true });
          setVolume(v);
          setMuted(v === 0);
        }}
      />
      <VolumeIcon name="volumeHigh" />
    </_Container>
  );
};

const _Container = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  justify-content: space-between;
  align-items: center;
`;
