import React, { useState } from "react";
import styled from "styled-components";
import { Message } from "~/components/molecules/Message";
import { LibraryModalWrapper } from "../../ui/LibraryModalWrapper";
import { LargeButton } from "~/components/atoms/buttons/Button";
import { ErrorToast } from "~/components/organisms/toasts/ErrorToast";
import { TFunction, useTranslation } from "~/i18n";

export type SubmitResponse =
  | "ok"
  | "mutationError"
  | "errorSuitableYLNotFound"
  | "invalidEnquete";

const errorMessageRecord = (t: TFunction): Record<SubmitResponse, string> => {
  return {
    ok: "",
    mutationError: t<TranslateKeys>(
      "うまく受け取れませんでした\nもう一度、ボタンを押してね"
    ),
    errorSuitableYLNotFound: "",
    invalidEnquete: "",
  };
};

export type AfterEnqueteProps = {
  onNext: () => Promise<SubmitResponse>;
  loading?: boolean;
};

export const AfterEnquete: React.FC<AfterEnqueteProps> = ({
  onNext,
  loading,
}) => {
  const { t } = useTranslation();

  const [isShowButton, setIsShowButton] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleNext = React.useCallback(() => {
    onNext().then((res) => {
      setErrorMessage(errorMessageRecord(t)[res]);
    });
  }, [onNext, t]);

  return (
    <LibraryModalWrapper
      onClick={() => {
        return;
      }}
      showSensei={true}
      isBright={true}
      senseiImageType="HEART"
      heartNum={errorMessage ? undefined : 7}
    >
      <ErrorToast
        text={errorMessage}
        isOpen={!!errorMessage}
        onClose={() => setErrorMessage("")}
      />
      {isShowButton && (
        <_ButtonWrapper>
          <LargeButton color="primary" onClick={handleNext} disabled={loading}>
            はーい
          </LargeButton>
        </_ButtonWrapper>
      )}
      <_MessageWrapper>
        <Message
          message={t<TranslateKeys>(
            "パワーアップしたおすすめの本、きっと面白いものがたくさんあるから、ぜひ読んでみてね！"
          )}
          speaker={t<TranslateKeys>("ヨンデミー先生")}
          onEnded={() => {
            setTimeout(() => {
              setIsShowButton(true);
            }, 300);
          }}
          showTriangle={false}
          boxHeight="middle"
        />
      </_MessageWrapper>
    </LibraryModalWrapper>
  );
};

const _MessageWrapper = styled.div`
  position: absolute;
  bottom: 15%;
  width: 100%;

  display: flex;
  justify-content: center;
`;

const _ButtonWrapper = styled.div`
  position: absolute;
  bottom: 6%;
  width: 100%;
  max-width: 600px;

  padding: 0px 20px;

  display: flex;
  justify-content: center;
`;
