import React, { useCallback } from "react";
import styled from "styled-components";
import { CircleArrowPagiableListProps } from "./types";
import { Loading } from "~/components/atoms/Loading";
import { CircleArrowPaginationButton } from "~/components/molecules/buttons/CircleArrowPaginationButton";

export const CircleArrowPagiableList: React.FC<
  CircleArrowPagiableListProps
> = ({
  //propsは使い回しでいいか？
  renderHeader,
  className,
  items,
  isLoading,
  scrollToOffset,
  renderListItem,
  renderEmptyComponent,
  disablePaging,
  hasPrev,
  hasNext,
  onChangePageToPrev,
  onChangePageToNext,
}) => {
  const ref = React.createRef<HTMLDivElement>();
  const scrollToTopOfList = React.useCallback(() => {
    ref.current &&
      scrollToOffset &&
      window.scrollTo({
        top: ref.current.offsetTop - scrollToOffset,
      });
  }, [ref]);

  const onClickPrev = useCallback(() => {
    onChangePageToPrev();
    scrollToTopOfList();
  }, [onChangePageToPrev, scrollToTopOfList]);

  const onClickNext = useCallback(() => {
    onChangePageToNext();
    scrollToTopOfList();
  }, [onChangePageToNext, scrollToTopOfList]);

  return (
    <Container className={className} ref={ref}>
      {!disablePaging && (
        <CircleArrowPaginationButton
          dir="prev"
          color="sec"
          onClick={onClickPrev}
          disabled={!hasPrev || isLoading}
        />
      )}
      {renderHeader && renderHeader()}
      {isLoading ? (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      ) : items.length > 0 ? (
        <BookListContainer>
          {items.map((item, index) => {
            return renderListItem({
              index,
              item,
            });
          })}
        </BookListContainer>
      ) : (
        <EmptyContainer>
          {renderEmptyComponent && renderEmptyComponent()}
        </EmptyContainer>
      )}
      {!disablePaging && (
        <CircleArrowPaginationButton
          dir="next"
          color="sec"
          onClick={onClickNext}
          disabled={!hasNext || isLoading}
        />
      )}
    </Container>
  );
};

CircleArrowPagiableList.defaultProps = {
  scrollToOffset: 130, // スクロールした時のナビゲーション部分のTopの高さ
  listProps: {
    width: "100%",
  },
};

const BookListContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  align-items: start;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;
  margin: 0 auto;
  //justify-content: space-between;
`;

const EmptyContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
`;
