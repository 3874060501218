import * as React from "react";
import { ReadStatusForPostReadLog } from "~/generated/graphql";
import { ConfirmReadStatusTemplate } from "~/components/templates/PostReadLog/ConfirmReadStatus";
import { usePostReadLogState } from "../usePostReadLogState";

export const ConfirmReadStatus: React.FC = () => {
  const {
    state: { questionNumber, lastProgressNumber, yomikikase, bookImgItemtype },
    dispatch,
  } = usePostReadLogState();
  const handleBack = (): void => {
    dispatch({
      type: "setQuestionNumber",
      payload: currectQuestionNumber - 1,
    });
    dispatch({
      type: "setWakuwaku",
      payload: false,
    });
    dispatch({
      type: "setSikusiku",
      payload: false,
    });
    dispatch({
      type: "setDokidoki",
      payload: false,
    });
    dispatch({
      type: "setNikoniko",
      payload: false,
    });
    dispatch({
      type: "setNattoku",
      payload: false,
    });
    dispatch({
      type: "setWaraeru",
      payload: false,
    });
    dispatch({
      type: "setBikkuri",
      payload: false,
    });
    dispatch({
      type: "setPunpun",
      payload: false,
    });
    dispatch({
      type: "setKowai",
      payload: false,
    });
    dispatch({
      type: "setDousite",
      payload: false,
    });

    if (bookImgItemtype === "OTHER") {
      dispatch({
        type: "setFlow",
        payload: "enterUnregisteredBookIsbn",
      });
      dispatch({
        type: "setBookImgItemType",
        payload: null,
      });
    } else {
      dispatch({
        type: "setFlow",
        payload: "selectBook",
      });
      dispatch({
        type: "setBookImgItemType",
        payload: null,
      });
    }
  };

  const onClicks = [
    //とちゅうまで
    (event: React.MouseEvent) => {
      event.preventDefault();
      dispatch({
        type: "setQuestionNumber",
        payload: currectQuestionNumber + 1,
      });
      dispatch({
        type: "setReadStatus",
        payload: ReadStatusForPostReadLog.Reading,
      });
      dispatch({
        type: "setFlow",
        payload: "confirmLike",
      });
    },
    //さいごまで
    (event: React.MouseEvent) => {
      event.preventDefault();
      dispatch({
        type: "setQuestionNumber",
        payload: currectQuestionNumber + 1,
      });
      dispatch({
        type: "setReadStatus",
        payload: ReadStatusForPostReadLog.End,
      });
      dispatch({
        type: "setFlow",
        payload: "confirmLike",
      });
    },
  ];

  const onClickYomikikase = () => {
    dispatch({
      type: "setYomikikase",
    });
  };

  const currectQuestionNumber = questionNumber ? questionNumber : 0;
  const progress = Math.floor(
    (currectQuestionNumber / lastProgressNumber) * 100
  );

  return (
    <ConfirmReadStatusTemplate
      progress={progress}
      handleBack={handleBack}
      onClicks={onClicks}
      onClickYomikikase={onClickYomikikase}
      yomikikase={yomikikase}
    />
  );
};
