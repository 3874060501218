import { MissionStorySetting } from "../type";

const baseDir = "/img/mission/start_story/story_6";

export const StartStory6: MissionStorySetting[] = [
  {
    image: `${baseDir}/scene_1.jpeg`,
    sound: "start6_scene1",
    text: "ピンクのキリン、よみキリンとの出会い。\nみんな、びっくり仰天していると……。",
    ruby: [
      ...Array(15).fill(""),
      "で",
      "あ",
      ...Array(11).fill(""),
      "ぎょう",
      "てん",
      ...Array(8).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_2.jpeg`,
    sound: "start6_scene2_1",
    text: "「世界一のファッションデザイナー！\nすっげーーー‼︎　オイラたち、これからあの山に登るんだ！　一緒に行こうぜ！」",
    ruby: [
      "",
      "せ",
      "かい",
      "いち",
      ...Array(34).fill(""),
      "やま",
      "",
      "のぼ",
      ...Array(5).fill(""),
      "いっ",
      "しょ",
      "",
      "い",
      ...Array(8).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_2.jpeg`,
    sound: "start6_scene2_2",
    text: "「へ〜山登り！　キュートな景色がたくさん見られそう〜行く〜！」",
    ruby: [
      ...Array(3).fill(""),
      "やま",
      "のぼ",
      ...Array(8).fill(""),
      "け",
      "しき",
      ...Array(5).fill(""),
      "み",
      ...Array(5).fill(""),
      "い",
      ...Array(4).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_3.jpeg`,
    sound: "start6_scene3",
    text: "そんなこんなで、みんなでテクテクと山へと歩いていくことになりました。",
    ruby: [
      ...Array(17).fill(""),
      "やま",
      ...Array(2).fill(""),
      "ある",
      ...Array(13).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_4.jpeg`,
    sound: "start6_scene4_1",
    text: "「いよいよ山登りだ！」「今回の本は登山家のお話のようね。",
    ruby: [
      ...Array(5).fill(""),
      "やま",
      "のぼ",
      ...Array(5).fill(""),
      "こん",
      "かい",
      "",
      "ほん",
      "",
      "と",
      "ざん",
      "か",
      ...Array(2).fill(""),
      "はなし",
    ],
  },
  {
    image: `${baseDir}/scene_4.jpeg`,
    sound: "start6_scene4_2",
    text: "それじゃあ、どくしょかのワザ\n『しつもんする』を使って、フックで登っていくわよ！」  「お〜‼︎」",
    ruby: [
      ...Array(24).fill(""),
      "つか",
      ...Array(7).fill(""),
      "のぼ",
      ...Array(14).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_5.jpeg`,
    sound: "start6_scene5_1",
    text: "「ん？『山ではバランスにも気をつけて』って書いてあるけど、どういうことだー？」",
    ruby: [
      ...Array(4).fill(""),
      "やま",
      ...Array(8).fill(""),
      "き",
      ...Array(7).fill(""),
      "か",
    ],
  },
  {
    image: `${baseDir}/scene_5.jpeg`,
    sound: "start6_scene5_2",
    text: "「うーん　どうしてかしら？」フックをかけて登ると……。",
    ruby: [
      ...Array(21).fill(""),
      "のぼ",
      ...Array(5).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_6.jpeg`,
    sound: "start6_scene6",
    text: "あ！　渓流に辿り着いたようです。\n「こ、これはたしかに、バランスが大事だ〜！　みんな気をつけろ！」「わ、わ〜〜‼︎」",
    ruby: [
      ...Array(3).fill(""),
      "けい",
      "りゅう",
      "",
      "たど",
      "",
      "つ",
      ...Array(24).fill(""),
      "だい",
      "じ",
      ...Array(7).fill(""),
      "き",
      ...Array(15).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_7.jpeg`,
    sound: "start6_scene7",
    text: "「『遠回りをしてみるのもいい』\nって書いてあるけど、どうしてかしら？」",
    ruby: [
      ...Array(2).fill(""),
      "とお",
      "まわ",
      ...Array(14).fill(""),
      "か",
      ...Array(16).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_8.jpeg`,
    sound: "start6_scene8",
    text: "「うわぁー！こんなところに　こんな素敵な場所があったのね！」\n「きっもちぇ〜〜‼︎」「キュート！」",
    ruby: [
      ...Array(17).fill(""),
      "す",
      "てき",
      "",
      "ば",
      "しょ",
      ...Array(26).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_9.jpeg`,
    sound: "start6_scene9",
    text: "「『空の様子をよくみる』\nって書いてあるね、どうして？」\nどんどん、フックをかけます。",
    ruby: [
      ...Array(2).fill(""),
      "そら",
      "",
      "よう",
      "す",
      ...Array(9).fill(""),
      "か",
      ...Array(26).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_10.jpeg`,
    sound: "start6_scene10",
    text: "すると……ザァァァ！　雨が降ってきました！\n「山は天気が変わりやすいから、\n空をよぉく見るのかぁ」",
    ruby: [
      ...Array(11).fill(""),
      "あめ",
      "",
      "ふ",
      ...Array(9).fill(""),
      "やま",
      "",
      "てん",
      "き",
      "",
      "か",
      ...Array(9).fill(""),
      "そら",
      ...Array(4).fill(""),
      "み",
      ...Array(5).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_11.jpeg`,
    sound: "start6_scene11_1",
    text: "雨が上がって外に出ると、\nそこには岩の絶壁が立ちはだかっています。",
    ruby: [
      "あめ",
      "",
      "あ",
      ...Array(3).fill(""),
      "そと",
      "",
      "で",
      ...Array(8).fill(""),
      "いわ",
      "",
      "ぜっ",
      "ぺき",
      "",
      "た",
    ],
  },
  {
    image: `${baseDir}/scene_11.jpeg`,
    sound: "start6_scene11_2",
    text: "「こ、これに……登る……⁉︎」さて、みんなはこの山を登り切れるのでしょうか！",
    ruby: [
      ...Array(8).fill(""),
      "のぼ",
      ...Array(14).fill(""),
      "やま",
      "",
      "のぼ",
      "",
      "き",
      ...Array(9).fill(""),
    ],
  },
];