import React from "react";
import styled from "styled-components";
import { Text } from "~/components/atoms/texts/Text";
import { ClosableToast } from "../ClosableToast";

export type NotifyTextToastProps = {
  text: string;
  isOpen: boolean;
  setIsOpen: (b: boolean) => void;
};

//TODO: setIsOpenではなくonCloseを渡すようにする
export const NotifyTextToast: React.FC<NotifyTextToastProps> = ({
  isOpen,
  setIsOpen,
  text,
}) => {
  return (
    <ClosableToast
      icon="bell"
      stickPlace={"top"}
      onClose={() => setIsOpen(false)}
      closeOnClick={true}
      buttonLabel={"とじる"}
      isOpen={isOpen}
      closeAfter={4000}
    >
      <NotifyToastMessage fontSize={"XS"} lineHeight={"MD"}>
        {text}
      </NotifyToastMessage>
    </ClosableToast>
  );
};

const NotifyToastMessage = styled(Text)`
  color: ${({ theme }) => theme.colors.pri.p500};
  @media screen and (min-width: 768px) {
    font-size: ${({ theme }) => theme.fontSize.SM};
  }
`;
