import * as React from "react";
import { ReadLogReactionType } from "~/generated/graphql";
import { useSnd } from "~/utils/hooks/useSnd";
import { Text } from "~/components/atoms/texts/Text";
import { FlatButton } from "~/components/atoms/buttons/FlatButton";
import styled from "styled-components";

type Props = {
  onClick: (reaction: ReadLogReactionType) => void;
  reaction: ReadLogReactionType;
  disabled?: boolean;
  selected?: boolean;
  bookFriendLockTemplate?: boolean;
};

const labels: Record<ReadLogReactionType, string> = {
  LIKE: "いいね",
  READ: "よんだ",
};

const emojis: Record<ReadLogReactionType, string> = {
  LIKE: "❤️",
  READ: "✨",
};

export const ReactionButton: React.FC<Props> = ({
  onClick,
  reaction,
  disabled,
  selected,
  bookFriendLockTemplate,
}) => {
  const { play } = useSnd();

  const handleClick = React.useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      // MEMO: ボタンが押せない場合も押せる場合と同じデザインにする暫定対応のためclick時に発火しないようにする
      if (disabled) return;
      play("TAP");
      onClick(reaction);
    },
    [onClick, play, reaction, disabled]
  );

  return selected ? (
    <FlatButton
      color="pri"
      variant="Normal"
      shape="Normal"
      onClick={handleClick}
      padding="8px"
    >
      <Text fontSize="XS" lineHeight="EQ" marginRight="2px">
        {emojis[reaction]}
      </Text>

      <Text fontSize="XS" lineHeight="EQ" keepAll style={{ color: "white" }}>
        {labels[reaction]}
      </Text>
    </FlatButton>
  ) : bookFriendLockTemplate ? (
    <FlatButton
      color="gray"
      variant="Outline"
      borderWidth="1px"
      shape="Normal"
      onClick={handleClick}
      padding="8px"
      disabled
    >
      <Text fontSize="XS" lineHeight="EQ" marginRight="2px">
        {emojis[reaction]}
      </Text>

      <Text
        fontSize="XS"
        keepAll
        lineHeight="EQ"
        fontColor="semantic.layout.borderPlus1"
      >
        {labels[reaction]}
      </Text>
    </FlatButton>
  ) : (
    <FlatButton
      color="pri"
      variant="Outline"
      borderWidth="1px"
      shape="Normal"
      onClick={handleClick}
      padding="8px"
    >
      <PrimaryText fontSize="XS" lineHeight="EQ" marginRight="2px">
        {emojis[reaction]}
      </PrimaryText>

      <PrimaryText fontSize="XS" keepAll lineHeight="EQ">
        {labels[reaction]}
      </PrimaryText>
    </FlatButton>
  );
};

const PrimaryText = styled(Text)`
  color: ${({ theme }) => theme.colors.pri.p400};
`;
