import React, { useRef, useEffect } from "react";
import { SectionTitle } from "~/components/molecules/texts/SectionTitle";
import { DailyReadingHabitAchievement } from "~/generated/graphql";
import { ReadingHabitCalendar } from "./ReadingHabitCalendar";
import { LoadingSection } from "~/components/templates/Loading/LoadingSection";
import { Stack } from "~/components/atoms/layout/Stack";
import { CircleArrowPaginationButton } from "~/components/molecules/buttons/CircleArrowPaginationButton";
import { Text } from "~/components/atoms/texts/Text";
import { Row } from "~/components/atoms/layout/Row";
import { ReadingHabitExplanation } from "./ReadingHabitExplanation";
import { useTranslation } from "~/i18n";

type ReadingHabitProps = {
  year: number;
  month: number;
  canGoToPrevMonth: boolean;
  canGoToNextMonth: boolean;
  onNextMonth: () => void;
  onPrevMonth: () => void;
  achievements: DailyReadingHabitAchievement[];
  loading?: boolean;
};

export const ReadingHabit: React.FC<ReadingHabitProps> = ({
  year,
  month,
  canGoToPrevMonth,
  canGoToNextMonth,
  onPrevMonth,
  onNextMonth,
  achievements,
  loading,
}) => {
  const { t } = useTranslation();
  const carouselRef = useRef<HTMLDivElement>(null);
  const touchStartX = useRef(0);
  const touchEndX = useRef(0);

  useEffect(() => {
    const carouselElement = carouselRef.current;
    const handleTouchStart = (event: TouchEvent) => {
      touchStartX.current = event.changedTouches[0].screenX;
    };

    const handleTouchEnd = (event: TouchEvent) => {
      touchEndX.current = event.changedTouches[0].screenX;
      handleSwipe();
    };

    const handleSwipe = () => {
      if (touchStartX.current - touchEndX.current > 50 && canGoToNextMonth) {
        onNextMonth();
      }

      if (touchStartX.current - touchEndX.current < -50 && canGoToPrevMonth) {
        onPrevMonth();
      }
    };

    if (carouselElement) {
      carouselElement.addEventListener("touchstart", handleTouchStart);
      carouselElement.addEventListener("touchend", handleTouchEnd);
    }

    return () => {
      if (carouselElement) {
        carouselElement.removeEventListener("touchstart", handleTouchStart);
        carouselElement.removeEventListener("touchend", handleTouchEnd);
      }
    };
  }, [onNextMonth, onPrevMonth, canGoToNextMonth, canGoToPrevMonth]);

  return (
    <Stack ref={carouselRef} rowGap="16px">
      <SectionTitle>{t<TranslateKeys>("読書習慣")}</SectionTitle>
      <Row justifyContent="space-between">
        <CircleArrowPaginationButton
          dir="prev"
          color="pri"
          onClick={onPrevMonth}
          disabled={!canGoToPrevMonth}
        />
        <Text
          lineHeight="EQ"
          fontSize="MD"
          fontColor="semantic.text.heading"
          bold
        >
          {t<TranslateKeys>("{{ year }}年{{ month }}月", {
            year: year,
            month: month,
          })}
        </Text>
        <CircleArrowPaginationButton
          dir="next"
          color="pri"
          onClick={onNextMonth}
          disabled={!canGoToNextMonth}
        />
      </Row>

      <Row columnGap="24px" justifyContent="center" alignItems="flex-start">
        <div style={{ width: "164px", height: "164px" }}>
          {loading ? (
            <LoadingSection />
          ) : (
            <ReadingHabitCalendar
              year={year}
              month={month}
              dailyAchievements={achievements}
            />
          )}
        </div>
        <ReadingHabitExplanation />
      </Row>
    </Stack>
  );
};
