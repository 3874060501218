import React, { useState, FC } from "react";
import { StartTutorial } from "./StartTutorial";
import { TutorialAtBookFriend } from "./TutorialAtBookFriend";
import { TutorialAtTopPage } from "./TutorialAtTopPage";
import { useGetRecentReadlogQuery } from "~/generated/graphql";
import { Loading } from "~/components/atoms/Loading";

type Flow = "startTutorial" | "tutorialAtBookFriend" | "tutorialAtTopPage";

export type TutorialBookFriendModalProps = {
  onEnd: () => void;
};

export const TutorialBookFriendModal: FC<TutorialBookFriendModalProps> = ({
  onEnd,
}) => {
  const [flow, setFlow] = useState<Flow>("startTutorial");
  const [isOpen, setIsOpen] = useState(true);

  const recentReadLog = useGetRecentReadlogQuery({
    variables: { begin: 0, end: 1 },
    fetchPolicy: "no-cache",
  });

  if (recentReadLog.loading) return <Loading />;
  if (recentReadLog.error) return <>Error! {recentReadLog.error.message}</>;
  if (!recentReadLog.data?.me.readLog.get[0]) return null;

  return (
    <>
      {isOpen && (
        <>
          {flow === "startTutorial" && (
            <StartTutorial
              setIsOpen={setIsOpen}
              onNext={() => setFlow("tutorialAtBookFriend")}
            />
          )}
          {flow === "tutorialAtBookFriend" && (
            <TutorialAtBookFriend onNext={() => setFlow("tutorialAtTopPage")} />
          )}
          {flow === "tutorialAtTopPage" && (
            <TutorialAtTopPage
              onNext={onEnd}
              recentReadLog={recentReadLog.data.me.readLog.get[0]}
            />
          )}
        </>
      )}
    </>
  );
};
