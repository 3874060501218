import React, { ReactNode, useState } from "react";
import styled, { css } from "styled-components";
import { BaseNavbar } from "~/components/atoms/wrappers/BaseNavbar";
import { Interpolation } from "~/styles/interpolation";
import { Text } from "~/components/atoms/texts/Text";
import { useSnd } from "~/utils/hooks/useSnd";
import { FlatButton } from "~/components/atoms/buttons/FlatButton";
import { IconText } from "../../texts/IconText";
import { MenuSideBar } from "~/components/organisms/MenuSideBar";
import { ExpProgressBar } from "../../ExpProgressBar";
import { useAudio } from "~/utils/hooks/useAudio";
import { sendButtonClickEvent } from "~/utils/googleAnalytics/useButtonClickTracking";

export type TopNavbarProps = {
  /**
   * 現在表示しているタブ
   */
  currentTab: number;
  /**
   * タブに表示するItem
   */
  tabItems: {
    page: number;
    title: ReactNode;
    hasNotification: boolean;
    iconImageSrc?: string;
  }[];
  /**
   * タブ変更時のイベント
   */
  onChangeTab: (page: number) => void;
};

export const TopNavbar: React.FC<TopNavbarProps> = ({
  tabItems,
  currentTab,
  onChangeTab,
}) => {
  const { play, volume } = useSnd();
  const { play: _play } = useAudio({ preload: ["initializeAudio"] });
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <BaseNavbar>
      <MenuSideBar
        isOpen={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
      />
      <UpperPart>
        <ExpProgressBar />

        <FlatButton
          color="pri"
          variant="Outline"
          shape="Normal"
          padding="8px 16px"
          onClick={() => {
            setIsOpen(true);
          }}
        >
          <IconText
            iconPlace="pre"
            fontSize="SM"
            lineHeight="EQ"
            color="pri"
            icon="bars"
            text="メニュー"
            keepAll
          />
        </FlatButton>
      </UpperPart>
      <DownerPart>
        {tabItems.map((tab) => {
          return (
            <Tab
              key={tab.page}
              selected={currentTab === tab.page}
              onClick={() => {
                // 現在のタブをクリックした場合は抜ける
                if (currentTab === tab.page) {
                  return;
                }
                play("SWIPE", { volume: Math.floor(volume * 0.2) });
                _play("initializeAudio");
                onChangeTab(tab.page);
                sendButtonClickEvent("TopNavbar " + tab.title);
              }}
              tabNumber={tabItems.length}
            >
              <TextContainer>
                <RedMark withNotification={tab.hasNotification} />
                <TabIcon imageSrc={tab.iconImageSrc} />
                <TabText fontSize={"SM"} lineHeight={"EQ"}>
                  {tab.title}
                </TabText>
              </TextContainer>
            </Tab>
          );
        })}
      </DownerPart>
    </BaseNavbar>
  );
};

const UpperPart = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  @media screen and (max-width: 600px) {
    padding: ${({ theme }) => theme.size.S} 3% 0 3%;
  }
`;

const DownerPart = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
`;

type TagProps = {
  selected: boolean;
  onClick: () => void;
  tabNumber: number;
};
const Tab = styled.div<TagProps>`
  ${({ theme, selected, tabNumber }) => {
    return css`
      width: ${100 / tabNumber}%;
      cursor: pointer;
      flex-grow: 1;
      height: 100%;
      display: block;
      font-weight: ${selected ? "bold" : "normal"};
      color: ${selected ? theme.colors.pri.p400 : theme.colors.tex.t300};
      ${Interpolation.BorderBottom}
      ${selected && SelectedInteroplation}
    `;
  }}
`;

const SelectedInteroplation = css`
  border-bottom: 3px solid ${({ theme }) => theme.colors.pri.p400};
`;

const TextContainer = styled.div`
  padding: ${({ theme }) => theme.size.S} 0;
  position: relative;

  display: flex;
  flex-direction: row;
  column-gap: 0px;

  justify-content: center;
  align-items: center;
`;

const TabIcon = styled.div<{ imageSrc?: string }>`
  height: 18px;
  width: 18px;
  background-color: ${({ theme }) => theme.colors.base.ashGray};
  background-image: url(${({ imageSrc }) => imageSrc});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 9px;
  margin-right: 4px;
`;

const TabText = styled(Text)`
  font-family: inherit;
  font-weight: inherit;
  color: inherit;
  text-align: center;
`;

const RedMark = styled.div<{ withNotification: boolean }>`
  border-radius: 50%;
  width: 5px;
  height: 5px;
  background-color: ${({ theme }) => theme.colors.red.r400};
  animation: 1s ease-in 0.5s infinite alternate forwards running Ellipse;
  top: 0%;
  left: 100%;
  @keyframes Ellipse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.4);
    }
    100% {
      transform: scale(1);
    }
  }
  display: ${({ withNotification }) => !withNotification && "none"};
`;
