import React from "react";
import styled, { css } from "styled-components";
import { MissionOrder, UserMissionTask } from "~/generated/graphql";
import { BaseModal } from "~/components/atoms/wrappers/modal";
import { Text } from "~/components/atoms/texts/Text";
import { Interpolation } from "~/styles/interpolation";
import { PrimaryButton } from "~/components/molecules/buttons/PrimaryButton";
import { MissionTaskText } from "./MissionTaskText";
import { missionOrderToDisplayMaterial } from "..";
import { useTranslation } from "react-i18next";

type MissionPresentationModalProps = {
  missionOrder: MissionOrder | undefined;
  userMissionTasks: UserMissionTask[] | undefined;
  goToNext: () => void;
};

export const MissionPresentationModal: React.FC<
  MissionPresentationModalProps
> = ({
  missionOrder,
  userMissionTasks,
  goToNext,
}: MissionPresentationModalProps) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(true);

  const { t } = useTranslation();

  if (!missionOrder || !userMissionTasks) {
    goToNext();
    return null;
  }
  const displayMaterial = missionOrderToDisplayMaterial[missionOrder];

  const onClick = () => {
    setIsOpen(false);
    goToNext();
  };

  return (
    <BaseModal isOpen={isOpen}>
      <BallonCon>
        <Ballon>
          <_TitleWrapper>
            <Text lineHeight="EQ" fontSize="XL" bold={true} marginBottom="8px">
              ヨンデミッション その{displayMaterial.number}
            </Text>
          </_TitleWrapper>
          <_Img src={displayMaterial.missionImgUrl} />
          <_TextWrapper>
            <Text lineHeight="MD" fontSize="SM" bold={false}>
              さっそく やってみよう
            </Text>
            {userMissionTasks.map((task, index) => {
              return (
                <MissionTaskText
                  key={index}
                  missionTaskType={task.missionTaskType}
                  goal={task.goal}
                />
              );
            })}
            <Text lineHeight="MD" fontSize="SM" bold={false}>
              {t<TranslateKeys>("図書館に力を送るんだ")}
            </Text>
          </_TextWrapper>
        </Ballon>
        <_ButtonWrapper>
          <PrimaryButton
            onClick={onClick}
            color="pri"
            text="まかせて！"
            lineHeight="EQ"
            fontSize="XL"
            bold={true}
            padding="16px"
          />
        </_ButtonWrapper>
      </BallonCon>
    </BaseModal>
  );
};

const Ballon = styled.div`
  ${({ theme }) => {
    return css`
      display: flex;
      flex-direction: column;
      gap: 16px;

      background-color: ${theme.colors.base.white};
      border-radius: ${theme.deprecatedBorderRadius.MD};

      width: 100%;
      padding: 16px 53px;
      @media screen and ${theme.breakPoints.maxWidthSmallSmartPhone} {
        padding: 16px 40px;
      }
    `;
  }}
`;

const BallonCon = styled.div`
  ${({ theme }) => {
    return css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: 96px;
      margin: 0 auto;

      width: 335px;
      height: 100%;
      @media screen and ${theme.breakPoints.maxWidthSmallSmartPhone} {
        width: 90vw;
      }
    `;
  }}
`;

const _TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${Interpolation.BorderBottom}
`;

const _Img = styled.img`
  width: 100%;
`;

const _TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
`;

const _ButtonWrapper = styled.div`
  width: 100%;
`;
