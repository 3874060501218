import React from "react";
import { ReadingTechniqueType } from "~/generated/graphql";
import { hintWithReadingTechnique } from "../const";
import { BaseModal } from "~/components/atoms/wrappers/BaseModal";
import { Stack } from "~/components/atoms/layout/Stack";
import { Text } from "~/components/atoms/texts/Text";
import { readingTechniqueTypeStr } from "~/components/organisms/bookFriendMessage/BookFriendMessageListItem/ReadLogMessage/SendStarModal/const";
import { LargeButton } from "~/components/atoms/buttons/Button";
import { useTranslation } from "react-i18next";

export type ConfirmTechniqueModalProps = {
  isOpen: boolean;
  readingTechniqueType: ReadingTechniqueType;
  hintNum: number;
  onNext: () => void;
  onBack: () => void;
  onClose: () => void;
};

export const ConfirmTechniqueModal: React.FC<ConfirmTechniqueModalProps> = ({
  isOpen,
  readingTechniqueType,
  hintNum,
  onNext,
  onBack,
  onClose,
}) => {
  const { t } = useTranslation();
  if (
    hintNum < 0 ||
    hintNum >= hintWithReadingTechnique[readingTechniqueType].length
  ) {
    onBack();
    return <></>;
  }

  return (
    <BaseModal open={isOpen} onClose={onClose}>
      <Stack rowGap="32px">
        <Stack rowGap="12px">
          <Text
            lineHeight="EQ"
            fontSize="XXL"
            bold
            fontColor="semantic.primary.main"
            style={{
              margin: "0 auto",
            }}
          >
            {readingTechniqueTypeStr[readingTechniqueType]}
          </Text>
          <Text lineHeight="MD" fontSize="MD" whiteSpace="pre-wrap">
            <span
              style={{
                fontFamily: "apple color emoji",
              }}
            >
              {t<TranslateKeys>(
                hintWithReadingTechnique[readingTechniqueType][hintNum]
              )}
            </span>
          </Text>
        </Stack>
        <Stack rowGap="16px" alignItems="center">
          <LargeButton onClick={onNext}>このワザを使う</LargeButton>
          <LargeButton onClick={onBack} variant="blank">
            {t<TranslateKeys>("ほかのヒントを選ぶ")}
          </LargeButton>
        </Stack>
      </Stack>
    </BaseModal>
  );
};
