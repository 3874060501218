import * as React from "react";
import { useSnd } from "~/utils/hooks/useSnd";
import { Text } from "~/components/atoms/texts/Text";
import { FlatButton } from "~/components/atoms/buttons/FlatButton";
import styled from "styled-components";

type Props = {
  onClick: () => void;
  disabled?: boolean;
  selected?: boolean;
};

export const StarButton: React.FC<Props> = ({
  onClick,
  disabled,
  selected,
}) => {
  const { play } = useSnd();

  const handleClick = React.useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      // MEMO: ボタンが押せない場合も押せる場合と同じデザインにする暫定対応のためclick時に発火しないようにする
      if (disabled) return;
      play("TAP");
      onClick();
    },
    [onClick, play, disabled]
  );

  return selected ? (
    <FlatButton
      color="pri"
      variant="Normal"
      shape="Normal"
      onClick={handleClick}
      padding="8px"
    >
      <_Wrapper disabled={false}>
        <Text fontSize="XS" lineHeight="EQ" marginRight="2px">
          ⭐️
        </Text>
        <Text fontSize="XS" lineHeight="EQ" keepAll style={{ color: "white" }}>
          スター
        </Text>
      </_Wrapper>
    </FlatButton>
  ) : disabled ? (
    <FlatButton
      color="gray"
      variant="Outline"
      borderWidth="1px"
      shape="Normal"
      onClick={handleClick}
      padding="8px"
      disabled
    >
      <_Wrapper disabled={true}>
        <Text fontSize="XS" lineHeight="EQ" marginRight="2px">
          ⭐️
        </Text>
        <Text
          fontSize="XS"
          lineHeight="EQ"
          keepAll
          fontColor="semantic.layout.borderPlus1"
        >
          スター
        </Text>
      </_Wrapper>
    </FlatButton>
  ) : (
    <FlatButton
      color="pri"
      variant="Outline"
      borderWidth="1px"
      shape="Normal"
      onClick={handleClick}
      padding="8px"
      disabled={false}
    >
      <_Wrapper disabled={false}>
        <PrimaryText fontSize="XS" lineHeight="EQ" marginRight="2px">
          ⭐️
        </PrimaryText>
        <PrimaryText fontSize="XS" keepAll lineHeight="EQ">
          スター
        </PrimaryText>
      </_Wrapper>
    </FlatButton>
  );
};

const _Wrapper = styled.div<{ disabled: boolean }>`
  position: relative;
  display: flex;
  column-gap: 4px;
  align-items: center;

  ::before {
    content: "";
    position: absolute;
    left: -14px;
    top: -12px;
    width: 12px;
    height: 12px;

    background-image: ${({ disabled }) =>
      disabled ? `url("/img/icon/grayStar.svg")` : `url("/img/icon/star.svg")`};
  }

  ::after {
    content: "";
    position: absolute;
    right: -14px;
    bottom: -12px;
    width: 12px;
    height: 12px;
    background-image: ${({ disabled }) =>
      disabled ? `url("/img/icon/grayStar.svg")` : `url("/img/icon/star.svg")`};
  }
`;

const PrimaryText = styled(Text)`
  color: ${({ theme }) => theme.colors.pri.p400};
`;
