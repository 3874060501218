import * as React from "react";
import Modal from "react-modal";
import { theme } from "~/styles/theme";

const overlayStyle: React.CSSProperties = {
  backgroundColor: theme.colors.base.overlay,
  zIndex: theme.zIndex.modal,
};

export const modalStyle: Modal.Styles = {
  overlay: overlayStyle,
  content: {
    width: "100vw",
    height: "100vh",
    inset: "50% auto auto 50%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    WebkitTransform: "translate(-50%, -50%)",
    border: "none",
    background: "none",
    zIndex: theme.zIndex.blackBack,
  },
};

/**
 * MEMO: 上記のmodalStyleではフルスクリーンになってしまい、
 * モーダルの外の判定がないためonRequestCloseが設定されたときにとじるようにするstyle
 * https://reactcommunity.org/react-modal/examples/on_request_close/
 */

const onOverlayClickCloseStyle = (props: {
  width?: `${number}px`;
}): Modal.Styles => {
  return {
    overlay: overlayStyle,
    content: {
      zIndex: theme.zIndex.blackBack,
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      border: "none",
      background: "none",
      width: `${props.width || "auto"}`,
      maxWidth: "90%",
      padding: "0",
    },
  };
};

type BaseModalProps = Omit<Modal.Props, "ariaHideApp"> & {
  children: React.ReactNode;
  width?: `${number}px`;
};

/**
 * @deprecated src/components/atoms/wrappers/BaseModalを使用してください
 */
export const BaseModal: React.VFC<BaseModalProps> = ({
  children,
  style,
  width,
  ...modalProps
}) => {
  return (
    <Modal
      {...modalProps}
      style={
        style
          ? style
          : modalProps.onRequestClose
          ? onOverlayClickCloseStyle({ width })
          : modalStyle
      }
      ariaHideApp={false}
    >
      {children}
    </Modal>
  );
};
