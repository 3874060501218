import * as React from "react";
import styled from "styled-components";
import { initAudioContext } from "~/utils/hooks/useAudio";
import { useSnd } from "~/utils/hooks/useSnd";

export type CircleArrowPaginationButtonProps = {
  dir: "next" | "prev";
  disabled?: boolean;
  onClick: () => void;
  color: "pri" | "sec" | "black";
};

export const CircleArrowPaginationButton: React.FC<
  CircleArrowPaginationButtonProps
> = ({ dir, disabled = false, onClick, color }) => {
  //Todo: ページネーションの機能整備(その中でtotalItemsCountも組み込む)
  const { play } = useSnd();
  const onClickWithSnd = (e: React.MouseEvent) => {
    initAudioContext(e);
    play("BUTTON");
    onClick();
  };

  return (
    <Button onClick={onClickWithSnd} disabled={disabled}>
      <Svg dir={dir} width={30} height={30} viewBox="0 0 30 30" fill="none">
        <Circle
          cx={15}
          cy={15}
          r={15}
          transform="rotate(180 15 15)"
          color={color}
          disabled={disabled}
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.7684 15.3217L19.0717 20.625L16.42 23.2767L8.4651 15.3217L16.42 7.36677L19.0717 10.0184L13.7684 15.3217Z"
        />
      </Svg>
    </Button>
  );
};

const Button = styled.button`
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  &:active {
    outline: none;
  }
`;

const Svg = styled.svg<Pick<CircleArrowPaginationButtonProps, "dir">>`
  transform: ${(props) => (props.dir === "next" ? "rotate(180deg)" : "none")};
`;

const Circle = styled.circle<
  Pick<CircleArrowPaginationButtonProps, "color" | "disabled">
>`
  opacity: ${({ disabled }) => {
    return disabled ? 0.3 : 1;
  }};
  fill: ${({ theme, color }) => {
    const { pri, sec, base } = theme.colors;
    switch (color) {
      case "pri":
        return pri.p500;
      case "sec":
        return sec.s500;
      case "black":
        return base.darkGray;
      default:
        return sec.s500;
    }
  }};
`;

const Path = styled.path`
  fill: ${({ theme }) => theme.colors.base.white};
`;
