import * as React from "react";
import styled from "styled-components";
import { PrimaryButton } from "~/components/molecules/buttons/PrimaryButton";

export type EnqueteFooterButtonProps = {
  onClick: () => void;
  text: string;
};

export const EnqueteFooterButton: React.FC<EnqueteFooterButtonProps> = ({
  onClick,
  text,
}) => (
  <_FooterWrapper>
    <_ButtonWrapper>
      <PrimaryButton
        fontSize="XL"
        lineHeight="MD"
        onClick={onClick}
        text={text}
        color="pri"
        bold
      />
    </_ButtonWrapper>
  </_FooterWrapper>
);

const _FooterWrapper = styled.div`
  position: absolute;
  bottom: 0;
  border-radius: ${({ theme }) => theme.deprecatedBorderRadius.MD}
    ${({ theme }) => theme.deprecatedBorderRadius.MD} 0px 0px;
  background-color: ${({ theme }) => theme.colors.base.white};
  padding: 16px 20px;
  width: 100vw;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const _ButtonWrapper = styled.div`
  width: 100%;
  max-width: 400px;
`;
