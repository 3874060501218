import React from "react";
import styled from "styled-components";
import { Text } from "~/components/atoms/texts/Text";

export type DailyAchievementProps = {
  miniLesson: boolean;
  readLog: boolean;
  day: number;
};
export const DailyAchievement: React.FC<DailyAchievementProps> = ({
  miniLesson,
  readLog,
  day,
}) => {
  return (
    <_Day upperLeft={miniLesson} lowerRight={readLog}>
      <Text
        lineHeight="EQ"
        fontSize="XS"
        fontColor="semantic.layout.backgroundBox"
      >
        {day}
      </Text>
    </_Day>
  );
};

const _Day = styled.div<{ upperLeft: boolean; lowerRight: boolean }>`
  border-radius: ${({ theme }) => theme.borderRadius.half};
  width: 20px;
  height: 20px;

  background: linear-gradient(
    -45deg,
    ${({ lowerRight, theme }) =>
        lowerRight
          ? theme.colors.semantic.primary.main
          : theme.colors.semantic.layout.borderPlus1}
      50%,
    ${({ upperLeft, theme }) =>
        upperLeft
          ? theme.colors.semantic.primary.main
          : theme.colors.semantic.layout.borderPlus1}
      50%
  );

  display: flex;
  justify-content: center;
  align-items: center;
`;
