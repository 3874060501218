import { MissionStorySetting } from "../type";

const baseDir = "/img/mission/complete_story/story_3";

export const CompleteStory3: MissionStorySetting[] = [
  {
    image: `${baseDir}/scene_1.jpeg`,
    sound: "complete3_scene1",
    text: "ペンギンせんぱいシェフ、なんて見事な手さばきでしょう。クンクン、わ〜いいにおい！",
    ruby: [
      ...Array(15).fill(""),
      "み",
      "ごと",
      "",
      "て",
      ...Array(21).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_2.jpeg`,
    sound: "complete3_scene2",
    text: "ジャジャーン！\nとっても美味しそうなご馳走のできあがりです。",
    ruby: [
      ...Array(12).fill(""),
      "お",
      "い",
      ...Array(5).fill(""),
      "ち",
      "そう",
      ...Array(9).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_3.jpeg`,
    sound: "complete3_scene3_1",
    text: "「スッゲーーー‼うっめぇ！」\n「︙︙えっぺん」\nペンギンせんぱいはすこし照れくさそう。",
    ruby: [...Array(36).fill(""), "て", ...Array(6).fill("")],
  },
  {
    image: `${baseDir}/scene_3.jpeg`,
    sound: "complete3_scene3_2",
    text: "「スッゲーよ！オイラたちの冒険の仲間になってくれよ‼」\n「ぐへへ、いいよぉ」",
    ruby: [
      ...Array(13).fill(""),
      "ぼう",
      "けん",
      "",
      "なか",
      "ま",
      ...Array(20).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_4.jpeg`,
    sound: "complete3_scene4_1",
    text: "「君たちはどうして冒険をしているの？」\n「読書家として成長して、元気がなくなっちまったこの図書館を助けるためさ！",
    ruby: [
      "",
      "きみ",
      ...Array(7).fill(""),
      "ぼう",
      "けん",
      ...Array(10).fill(""),
      "どく",
      "しょ",
      "か",
      ...Array(3).fill(""),
      "せい",
      "ちょう",
      ...Array(3).fill(""),
      "げん",
      "き",
      ...Array(11).fill(""),
      "と",
      "しょ",
      "かん",
      "",
      "たす",
      ...Array(6).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_4.jpeg`,
    sound: "complete3_scene4_2",
    text: "オイラは強くなりてぇから、一緒に冒険することにしたんだ！」",
    ruby: [
      ...Array(4).fill(""),
      "つよ",
      ...Array(8).fill(""),
      "いっ",
      "しょ",
      "",
      "ぼう",
      "けん",
      ...Array(11).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_5.jpeg`,
    sound: "complete3_scene5_1",
    text: "「そういうことだったのね、ワタシもついていってあげるわ。\nワタシがいないと困るでしょう！",
    ruby: [...Array(37).fill(""), "こま", ...Array(6).fill("")],
  },
  {
    image: `${baseDir}/scene_5.jpeg`,
    sound: "complete3_scene5_2",
    text: "それにワタシは世界征服が夢なの、つまんない世界を征服したっておもしろくないわ！」",
    ruby: [
      ...Array(7).fill(""),
      "せ",
      "かい",
      "せい",
      "ふく",
      "",
      "ゆめ",
      ...Array(8).fill(""),
      "せ",
      "かい",
      "",
      "せい",
      "ふく",
      ...Array(14).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_6.jpeg`,
    sound: "complete3_scene6_1",
    text: "「ぐへへ、みんな愉快だね〜。ねぇペンちゃん一緒に行こうよ☺️」",
    ruby: [
      ...Array(8).fill(""),
      "ゆ",
      "かい",
      ...Array(11).fill(""),
      "いっ",
      "しょ",
      "",
      "い",
      ...Array(6).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_6.jpeg`,
    sound: "complete3_scene6_2",
    text: "「ベジがそういうなら、いいよ。\nさっきの景色、すごく綺麗だったし」\nやったね！仲間がたくさん増えました。",
    ruby: [
      ...Array(20).fill(""),
      "け",
      "しき",
      ...Array(4).fill(""),
      "き",
      "れい",
      ...Array(11).fill(""),
      "なか",
      "ま",
      ...Array(5).fill(""),
      "ふ",
      ...Array(5).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_7.jpeg`,
    sound: "complete3_scene7",
    text: "と、思いきや、、みんなお腹がいっぱいになって、眠ってしまいました。\n冒険で、疲れていたんだね。",
    ruby: [
      ...Array(2).fill(""),
      "おも",
      ...Array(9).fill(""),
      "なか",
      ...Array(10).fill(""),
      "ねむ",
      ...Array(10).fill(""),
      "ぼう",
      "けん",
      ...Array(2).fill(""),
      "つか",
      ...Array(8).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_8.jpeg`,
    sound: "complete3_scene8",
    text: "みんな、夢をみています。\nひつじが一匹、ひつじが二匹︙︙",
    ruby: [
      ...Array(4).fill(""),
      "ゆめ",
      ...Array(12).fill(""),
      "いっ",
      "ぴき",
      ...Array(5).fill(""),
      "に",
      "ひき",
      ...Array(2).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_9.jpeg`,
    sound: "complete3_scene9",
    text: "ん、んん︙︙あれ、朝︙︙？\nほんの少し目を開けると︙︙",
    ruby: [
      ...Array(9).fill(""),
      "あさ",
      ...Array(7).fill(""),
      "すこ",
      "",
      "め",
      "",
      "あ",
      ...Array(5).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_10.jpeg`,
    sound: "complete3_scene10",
    text: "あれ⁉この子は、夢に出てきた、ひつじ︙︙⁉\nど、どういうこと〜⁉⁉",
    ruby: [
      ...Array(5).fill(""),
      "こ",
      ...Array(2).fill(""),
      "ゆめ",
      "",
      "で",
      ...Array(24).fill(""),
    ],
  },
];
