import { ReadingTechniqueType } from "~/generated/graphql";
import { TranslateKeys } from "~/i18n";

export const hintWithReadingTechnique: Record<
  ReadingTechniqueType,
  TranslateKeys[]
> = {
  IMAGINE: [
    "お話の中で、音が聞こえてきそうなところはあったかな？👂\n\nトントン、ザーザー、ぴょこぴょこ……いろんな音がなっているかも🤔\n\nよーく読んで、思い描いてみよう✨",
    "どんな見た目のものが出てきたかな？\n\n綺麗なもの、へんてこなもの、おいしそうなもの……本には いろいろなものが 出てくるよね🙌\n\n細かいところまで、思い描いてみよう✨",
    "この本のどこか1ページに潜り込めるとしたらどのページに潜り込みたいかな？\n\n潜り込んで何をやってみたい？\n\n自分が本の世界に入り込んだつもりで思い描いてみよう✨",
    "登場人物とお話しできるとしたら、誰と話してみたい？\n\nどんな話をしようかな？ワクワクするね\n\n登場人物がなんていうのか思い描いてみよう✨",
    "好きなページはあった？ そのページで、登場人物はどんな気持ちになったのかな？\n\n楽しい、嬉しい、悲しい……いろんな感情がありそうだよね☺\n\n登場人物になりきって思い描いてみよう✨",
  ],
  CONNECT: [
    "もし自分が 登場人物だったら 何をしただろう？🤔\n\n同じことをする？それとも、違うことをする？ ヨンデミー先生に教えてほしいなぁ☺\n\n本の中と自分のすることをつなげてみよう✨",
    "自分が 登場人物だったら どんな気持ちになったかな？\n\n自分だったら 同じ気持ちになった？ それとも 違う気持ちになった？いろんな気持ちに なりそうだよね😉\n\n自分の気持ちと本の人物の気持ちをつなげてみよう✨",
    "これまでに読んだおはなしで似ているお話はあったかな？\n\nどんなお話だったか、どんな ものや ひとが 出てきたか、いろんなポイントがありそうだね☺\n\n前に読んだお話とこのお話をつなげてみよう✨",
    "このお話に出てくる登場人物と、にている人は身近にいるかな？\n\nどんなところが似ているか考えてヨンデミー先生に教えてね☺\n\n身近な人物とお話の人物をつなげてみよう✨",
    "すでに知っていること、やったことのあることはでてきたかな？\n\nどんなことを知っていたのか思い出して書いてみてね\n\n自分の知識と本の知識をつなげてみよう✨",
  ],
  QUESTION: [
    "不思議だな、わからないなと思ったことはあったかな？🤔\n\nどんなところで思ったかも教えてね\n\n本のわからないところに質問してみよう✨",
    "この本の登場人物に一つ質問ができるとしたら、どんな質問をしてみたい？\n\n戦っていたときの気持ち、ご飯の味の感想など、登場人物の体験をもっと詳しく知りたいね。\n\n登場人物の気持ちを質問してみよう✨",
    "このお話の中で、もっと知りたい！と思ったところはあった？\n\n本に書かれていることでもいいし、書かれていないことだって聞いてみてもいいかもね😉\n\nお話の中で気になったことを質問してみよう✨",
    "言葉の意味がわからないところはあったかな？\n\n漢字や言葉遣いなど、文の意味を読み取るのに難しいところもあるよね🤔\n\n言葉の意味を質問してみよう✨",
    "登場人物が どうしてそうしたのか わからないところがあったかな？\n\nその人が何をどう思ったのか、考えてみてもおもしろそうだね。\n\n登場人物の 行動の理由を質問してみよう✨",
  ],
  PREDICT: [
    "お話を読みながら 思ったことはあったかな？それはお話の続きと 同じだった？それとも、違った？\n\n先のお話を考えながら 読んだところがあれば教えてね😉\n\nお話の展開を予想しよう✨",
    "意味を知らない言葉や文はあったかな？そこはどういう意味だと思う？\n\nわかる文をヒントにして考えてみてね。\n\nわからないところの意味を予想しよう✨",
    "文章に書かれていないけれど、実はこうじゃないかなと思ったことはあったかな？\n\nどんなことを感じたか、ヨンデミー先生に教えてね☺\n\n隠されている 本当のことを予想しよう✨",
    "もしこのお話に続きがあるとしたら、どんなお話になると思う？\n\n幸せなお話、悲しいお話、どんなお話でも大丈夫！\n\nお話の先を予想しよう✨",
    "このお話を読みながら思っていたことと、全然違ったことはあったかな？\n\n思っていたことと全然違ったらびっくりするね！どこが違ったのか教えてね☺\n\nお話を読みながら先の展開を予想しよう✨",
  ],
  ACKNOWLEDGE: [
    "自分に一番似ていると思うキャラクターは誰だろう？\n\nどういうところでそう思ったのかも、ヨンデミー先生に聞かせてほしいな☺\n\n自分とキャラクターの似ているところを認めよう✨",
    "自分に 一番似ていないと思うキャラクターは誰かな？\n\nその人とどういうところが似ていないかな？その人がどんな気持ちだったか、考えてみよう🤔\n\nその人の気持ちやものの見方を認めよう✨",
    "すごく印象に残ったところはどこですか？\n\nどうして印象に残ったのか、考えてみよう\n\n自分にとって大事なことを認めよう✨",
    "これからはこうしよう、または、こうしないようにしよう、と思ったことはあるかな？\n\nどうしてそう思ったのかも、ヨンデミー先生は気になるよ！\n\n自分の良かったところ、もっと良くできるところを認めよう✨",
    "自分の思っていたことと、違ったところはあったかな？\n\n違う考えや気持ちに対してどう感じたかな？\n\n自分と本の内容との違いを認めよう✨",
  ],
  JUDGE: [
    "心に残っているセリフはあったかな？\n\nそのセリフは、どうして心に残ったんだろう？🤔感動したから、かっこいいから、かわいいから……いろんな理由がありそうだね☺\n\n自分にとって大事なセリフを見極めよう✨",
    "好きなところ、面白いところはどういうところだったかな？どうして好きだと思ったかも教えてくれると嬉しいな☺自分にとって面白いところを見極めよう✨",
    "新しく知ったことはあったかな？どんなことを新しく知ったのか、ヨンデミー先生も知りたいな！\n\n新しい知識を見極めよう✨",
    "他の人に教えたい！と思ったことはあったかな？\n\n教えたいところを書いて、ヨンデミー先生に見せてほしいなぁ☺\n\nおすすめポイントを見極めよう✨",
    "作者が伝えたかったことはなんだと思う？\n\n本から感じ取れるメッセージを書いて、ヨンデミー先生に教えてね😉\n\n作者の伝えたいことを見極めよう✨",
  ],
  INTERPRET: [
    "物語の最後で主人公はどんな気持ちだったかな？\n\n嬉しい？悲しい？そう思ったのはどうしてだろう？🤔\n\n主人公の気持ちを解釈しよう✨",
    "作者はどうしてこの本を書いたんだろう？\n\nいったいどんなメッセージを伝えたかったのかな🤔\n\n作者の意図を解釈しよう✨",
    "この本をおすすめするとしたら、どう説明するかな？\n\n何が面白かった？どんな人におすすめ？考えて書いて、ヨンデミー先生にもおすすめしてね！\n\nおすすめの理由をまとめて、解釈しよう✨",
    "自分にとって大切だと思うことはあったかな？\n\nどうしてそれが大切なんだろう？\n\n大切だと思うことを考えて、解釈しよう✨",
    "この本はどんなお話だったかな？簡単に説明してみよう\n\n話の内容を 短く説明するとしたら どうなるかな？ヨンデミー先生に説明してくれると嬉しいな☺\n\nお話をまとめて、内容を解釈してみよう✨",
  ],
};
