import React from "react";
import styled, { css } from "styled-components";
import { Interpolation } from "~/styles/interpolation";

export type ChatBubbleProps = {
  children: React.ReactNode;
  variant: "white" | "orange";
  className?: string;
};

export const ChatBubble: React.VFC<ChatBubbleProps> = ({
  children,
  variant,
  className,
}) => (
  <StyledBubble variant={variant} className={className}>
    {children}
  </StyledBubble>
);

const StyledBubble = styled.div<Pick<ChatBubbleProps, "variant">>`
  ${({ theme }) => {
    return css`
      padding: ${theme.size.S};
      font-size: ${theme.fontSize.MD};
      line-height: ${theme.lineHeight.LG};
      white-space: break-spaces;
    `;
  }}
  ${({ variant, theme }) => {
    if (variant === "white") {
      return css`
        ${Interpolation.Border}
        background-color: ${theme.colors.base.white};
        border-radius: 0 ${theme.deprecatedBorderRadius.MD}
          ${theme.deprecatedBorderRadius.MD} ${theme.deprecatedBorderRadius.MD};
        color: ${theme.colors.tex.t500};
      `;
    } else {
      return css`
        background-color: ${theme.colors.pri.p400};
        border: 2px solid ${theme.colors.pri.p400};
        color: ${theme.colors.base.white};
        border-radius: ${theme.deprecatedBorderRadius.MD}
          ${theme.deprecatedBorderRadius.MD} 0
          ${theme.deprecatedBorderRadius.MD};
      `;
    }
  }}
`;
