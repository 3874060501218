import React from "react";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";

export const lockedFeatureArr = [
  "enterFreeReview",
  "bookFriend",
  "bfStar",
  "wantReadBook",
] as const;

export type LockedFeatures = (typeof lockedFeatureArr)[number];

export const unlockLevel: Record<LockedFeatures, number> = {
  enterFreeReview: 5,
  bookFriend: 11,
  bfStar: 21,
  wantReadBook: 25,
};

type UseUnlockProps = {
  isLocked: (lockedFeature: LockedFeatures) => boolean;
};

export const useUnlock = (): UseUnlockProps => {
  const { userExperience } = React.useContext(CurrentUserContext);
  const userLevel = userExperience?.userLevel || 0;
  const isLocked = React.useCallback(
    (feature: LockedFeatures) => {
      return userLevel < unlockLevel[feature];
    },
    [userLevel]
  );

  return { isLocked };
};
