import React, { VFC, Fragment } from "react";
import styled from "styled-components";
import {
  MypageExternalListItemProps,
  MypageExternalListItem,
} from "../../listItems/MypageExternalListItem";
import { Text } from "~/components/atoms/texts/Text";

type MypageProps = {
  title?: string;
  items: Omit<MypageExternalListItemProps, "isFirst" | "isLast">[];
};

export const MypageExternalList: VFC<MypageProps> = ({ title, items }) => {
  return (
    <div>
      {title && (
        <ListIitle fontSize="MD" lineHeight="MD">
          {title}
        </ListIitle>
      )}
      <div>
        {items.map((item, index) => (
          <Fragment key={item.title}>
            <MypageExternalListItem
              {...item}
              isFirst={index === 0}
              isLast={index === items.length - 1}
            />
            {index !== items.length - 1 && (
              <DividerWrapper>
                <Divider />
              </DividerWrapper>
            )}
          </Fragment>
        ))}
      </div>
    </div>
  );
};

const ListIitle = styled(Text)`
  color: ${({ theme }) => theme.colors.tex.t300};
  font-size: 12px;
  margin-bottom: 8px;
`;

const DividerWrapper = styled.div`
  height: 2px;
  background-color: ${({ theme }) => theme.colors.base.white};
`;

const Divider = styled.div`
  background-color: ${({ theme }) => theme.colors.base.ashGray};
  width: 98%;
  height: 2px;
  margin: 0 auto;
`;
