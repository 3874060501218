import React from "react";
import { TransparentBackNavbar } from "~/components/molecules/navbars/TransparentBackNavbar";
import { NavContainer } from "~/components/templates/Common";

export type YLEnqueteBackNavbarProps = {
  withBlur: boolean;
  onBackButton?: () => void;
  onQuitButton?: () => void;
};

export const YLEnqueteBackNavbar: React.FC<YLEnqueteBackNavbarProps> = ({
  withBlur,
  onBackButton,
  onQuitButton,
}) => {
  return (
    <NavContainer>
      <TransparentBackNavbar
        withBlur={withBlur}
        leftBlock={
          onBackButton
            ? { arrowColor: "pri", onClick: onBackButton }
            : undefined
        }
        rightBlock={
          onQuitButton
            ? {
                textProps: { text: "やめる", textColor: "pri.p500" },
                onClick: onQuitButton,
              }
            : undefined
        }
      />
    </NavContainer>
  );
};
