import { MissionStorySetting } from "../type";

const baseDir = "/img/mission/complete_story/story_1";

export const CompleteStory1: MissionStorySetting[] = [
  {
    image: `${baseDir}/scene_1.jpeg`,
    sound: "complete1_scene1",
    text: "風が吹きました。次々と灯りがともっていきます。",
    ruby: [
      "かぜ",
      "",
      "ふ",
      ...Array(5).fill(""),
      "つぎ",
      "つぎ",
      "",
      "あか",
      ...Array(11).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_2.jpeg`,
    sound: "complete1_scene2",
    endDelay: 3000,
    startDelay: 1000,
    text: "「わ、わ〜〜〜〜〜〜‼」",
    ruby: [...Array(12).fill("")],
  },
  {
    image: `${baseDir}/scene_3.jpeg`,
    sound: "complete1_scene3",
    endDelay: 3000,
    startDelay: 1000,
    text: "「すごい！君が、本たちを助けてくれたおかげで、この部屋が蘇ったよ‼」",
    ruby: [
      ...Array(5).fill(""),
      "きみ",
      ...Array(2).fill(""),
      "ほん",
      ...Array(3).fill(""),
      "たす",
      ...Array(12).fill(""),
      "へ",
      "や",
      "",
      "よみがえ",
      ...Array(5).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_4.jpeg`,
    sound: "complete1_scene4_1",
    text: "この図書館を全部蘇らせるには、図書館の一番上まで辿り着かないといけないんだ。",
    ruby: [
      ...Array(2).fill(""),
      "と",
      "しょ",
      "かん",
      "",
      "ぜん",
      "ぶ",
      "よみがえ",
      ...Array(6).fill(""),
      "と",
      "しょ",
      "かん",
      "",
      "いち",
      "ばん",
      "うえ",
      ...Array(2).fill(""),
      "たど",
      "",
      "つ",
      ...Array(11).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_4.jpeg`,
    sound: "complete1_scene4_2",
    endDelay: 1500,
    text: "さぁ、エレベーターに乗ってどんどん上に進んでいこう。",
    ruby: [
      ...Array(10).fill(""),
      "の",
      ...Array(6).fill(""),
      "うえ",
      "",
      "すす",
      ...Array(6).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_5.jpeg`,
    sound: "complete1_scene5",
    text: "どくしょかのワザによって手に入るスターを集めると、どくしょかのしょが力を得て、次のエレベーターの扉を開く鍵になるよ！",
    ruby: [
      ...Array(12).fill(""),
      "て",
      "",
      "はい",
      ...Array(5).fill(""),
      "あつ",
      ...Array(13).fill(""),
      "ちから",
      "",
      "え",
      ...Array(2).fill(""),
      "つぎ",
      ...Array(8).fill(""),
      "とびら",
      "",
      "ひら",
      "",
      "かぎ",
      ...Array(5).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_6.jpeg`,
    sound: "complete1_scene6",
    text: "「すごい大冒険だね︙︙！」\n「うんうん、君なら大丈夫！冒険の中できっと、素敵な本生物たちにも出会えるよ！」",
    ruby: [
      ...Array(4).fill(""),
      "だい",
      "ぼう",
      "けん",
      ...Array(13).fill(""),
      "きみ",
      ...Array(2).fill(""),
      "だい",
      "じょう",
      "ぶ",
      "",
      "ぼう",
      "けん",
      "",
      "なか",
      ...Array(5).fill(""),
      "す",
      "てき",
      "",
      "ほん",
      "せい",
      "ぶつ",
      ...Array(4).fill(""),
      "で",
      "あ",
      ...Array(5).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_7.jpeg`,
    sound: "complete1_scene7",
    text: "「おもしろそう︙︙！」\n「一緒にこの巨大図書館を助けていこう！」",
    ruby: [
      ...Array(13).fill(""),
      "いっ",
      "しょ",
      ...Array(3).fill(""),
      "きょ",
      "だい",
      "と",
      "しょ",
      "かん",
      "",
      "たす",
      ...Array(7).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_8.jpeg`,
    sound: "complete1_scene8",
    text: "「あ‼本の整理をしている途中なんだった！行ってくるね‼」\nヨンデミー先生は行ってしまいました。",
    ruby: [
      ...Array(3).fill(""),
      "ほん",
      "",
      "せい",
      "り",
      ...Array(5).fill(""),
      "と",
      "ちゅう",
      ...Array(6).fill(""),
      "い",
      ...Array(13).fill(""),
      "せん",
      "せい",
      "",
      "い",
      ...Array(9).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_9.jpeg`,
    sound: "complete1_scene9",
    text: "「伝説の読書家かぁ」\n冒険の始まりに、なんだかワクワクしてきました。本の虫さん、よろしくね！",
    ruby: [
      "",
      "でん",
      "せつ",
      "",
      "どく",
      "しょ",
      "か",
      ...Array(4).fill(""),
      "ぼう",
      "けん",
      "",
      "はじ",
      ...Array(19).fill(""),
      "ほん",
      "",
      "むし",
      ...Array(9).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_10.jpeg`,
    sound: "complete1_scene10",
    text: "「ぶうぇ、ぶうぇ、ぶうぇぇぇえっくしょん！！！」",
    ruby: [...Array(24).fill("")],
  },
  {
    image: `${baseDir}/scene_11.jpeg`,
    sound: "complete1_scene11",
    text: "「だ、だれ⁉」\nあれ、誰かがこちらをのぞいています。",
    ruby: [...Array(11).fill(""), "だれ", ...Array(14).fill("")],
  },
  {
    image: `${baseDir}/scene_12.jpeg`,
    sound: "complete1_scene12",
    text: "「オレは、太古のきょうりゅう！　この図書館を救いにきた！」\n「え、え〜〜〜？」",
    ruby: [
      ...Array(5).fill(""),
      "たい",
      "こ",
      ...Array(11).fill(""),
      "と",
      "しょ",
      "かん",
      "",
      "すく",
      ...Array(16).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_13.jpeg`,
    sound: "complete1_scene13",
    text: "「びっくりしないでくれよぉ。実はさっきの話、聞こえてきてさ。ずうっと君たちの話を聞いていたんだ」",
    ruby: [
      ...Array(14).fill(""),
      "じつ",
      ...Array(5).fill(""),
      "はなし",
      "",
      "き",
      ...Array(11).fill(""),
      "きみ",
      ...Array(3).fill(""),
      "はなし",
      "",
      "き",
      ...Array(7).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_14.jpeg`,
    sound: "complete1_scene14",
    text: "「オレも冒険の仲間に入れてくれよ！\nたった二人じゃ心細いだろ。早速エレベーターに乗ってみようぜ！」",
    ruby: [
      ...Array(4).fill(""),
      "ぼう",
      "けん",
      "",
      "なか",
      "ま",
      "",
      "い",
      ...Array(10).fill(""),
      "ふた",
      "り",
      ...Array(2).fill(""),
      "こころ",
      "ぼそ",
      ...Array(4).fill(""),
      "さっ",
      "そく",
      ...Array(7).fill(""),
      "の",
      ...Array(8).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_15.jpeg`,
    sound: "complete1_scene15",
    text: "どくしょかのしょが輝き出して、扉が開きました。さて、ここからどんな冒険が始まるんでしょう！",
    ruby: [
      ...Array(9).fill(""),
      "かがや",
      "",
      "だ",
      ...Array(3).fill(""),
      "とびら",
      "",
      "ひら",
      ...Array(15).fill(""),
      "ぼう",
      "けん",
      "",
      "はじ",
      ...Array(8).fill(""),
    ],
  },
];
