import React, { useState } from "react";
import { EditReadLogTemplate } from "~/components/templates/EditReadLog";
import {
  useUpdateOneReadlogMutation,
  useGetOneReadlogQuery,
} from "~/generated/graphql";
import { useNavigate, useParams } from "react-router-dom";
import { Loading } from "~/components/atoms/Loading";

export const EditReadLog: React.FC = () => {
  const { readlogid = "" } = useParams<{ readlogid: string }>();
  const navigate = useNavigate();
  const readlogID = Number(readlogid);
  const oneReadLog = useGetOneReadlogQuery({
    variables: { readlogID },
    fetchPolicy: "no-cache",
  });
  const [hasError, setHasError] = useState(false);

  const [editReadLog, { loading }] = useUpdateOneReadlogMutation({
    onCompleted() {
      navigate(-1);
    },
    onError() {
      setHasError(true);
    },
  });
  const onSubmit = (review: string): void => {
    if (loading === true) return;
    editReadLog({
      variables: {
        readLogUpdate: {
          readLogID: readlogID,
          review: review || "",
        },
      },
    });
  };

  if (oneReadLog.loading) return <Loading />;
  if (oneReadLog.error) return <>Error! {oneReadLog.error.message}</>;
  if (!oneReadLog.data) return null;

  return (
    <EditReadLogTemplate
      onBack={() => navigate(-1)}
      editReadLogForm={{
        data: oneReadLog.data,
        onSubmit: onSubmit,
      }}
      error={{
        isOpen: hasError,
        setIsOpen: setHasError,
      }}
    />
  );
};
