import React, { VFC } from "react";
import { useNavigate } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import { PrimaryButton } from "~/components/molecules/buttons/PrimaryButton";
import { LabeledSelect } from "~/components/molecules/forms/LabeledSelect";
import { BackNavbar } from "~/components/molecules/navbars/BackNavbar";
import { Interpolation } from "~/styles/interpolation";
import { KanziLevel } from "~/generated/graphql";
import { NavContainer, Wrapper } from "../Common";
import { EditKanziLevelTemplateProps } from "./type";
import { convertToWord } from "~/helpers/constants/kanziLevel";
import { TranslateKeys, useTranslation } from "~/i18n";
import { Text } from "~/components/atoms/texts/Text";
import { Icon } from "~/components/atoms/Icon";

export const EditKanziLevelTemplate: VFC<EditKanziLevelTemplateProps> = ({
  kanziLevelForm,
  onChangeKanziLevel,
  handleUpdate,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <>
      <NavContainer>
        <BackNavbar titleName="プロフィール変更" onClick={() => navigate(-1)} />
      </NavContainer>
      <Wrapper>
        <TopWrapper>
          <Container>
            <LabeledSelect
              label={"漢字レベル"}
              value={kanziLevelForm.kanziLevel}
              onChange={(event) =>
                onChangeKanziLevel(
                  "kanziLevel",
                  event.target.value as KanziLevel
                )
              }
            >
              {Object.values(KanziLevel).map((kanziLevel) => (
                <option key={kanziLevel} value={kanziLevel}>
                  {t<TranslateKeys>(convertToWord(kanziLevel))}
                </option>
              ))}
            </LabeledSelect>
            <Tips>
              <IconWrapper>
                <Icon
                  name={"question"}
                  style={{
                    color: theme.colors.tex.t300,
                    height: theme.fontSize["MD"],
                    width: "auto",
                  }}
                />
                <P fontSize={"MD"} lineHeight={"MD"}>
                  漢字レベルとは
                </P>
              </IconWrapper>
              <P fontSize={"MD"} lineHeight={"MD"}>
                ミニレッスンのヨンデミー先生のセリフや、アプリ上の言葉などを、お子さんの漢字レベルに合わせて変更できます。
              </P>
            </Tips>
          </Container>
          <PrimaryButton
            text={"変更して保存"}
            onClick={(event) => {
              event.preventDefault();
              handleUpdate();
            }}
            color={"pri"}
            fontSize={"XL"}
            lineHeight={"MD"}
            bold
          />
        </TopWrapper>
      </Wrapper>
    </>
  );
};

const TopWrapper = styled.div`
  padding: 74px 0 0 0;
`;

const Container = styled.div`
  ${Interpolation.Border}
  width: 100%;
  border-radius: ${({ theme }) => theme.deprecatedBorderRadius.MD};
  background-color: ${({ theme }) => theme.colors.base.white};
  padding: ${({ theme }) => theme.size.XL};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.size.XXL};
  margin-bottom: 32px;
`;

const Tips = styled.div`
  display: flex;
  flex-direction: column;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const P = styled(Text)`
  color: ${({ theme }) => theme.colors.tex.t700};
`;
