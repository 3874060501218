import React, { VFC } from "react";
import styled from "styled-components";
import { Text } from "~/components/atoms/texts/Text";
import { Heading2 } from "~/components/atoms/texts/Heading2";
import { Dialog, DialogProps } from "~/components/molecules/modals/Dialog";
import { Input, InputProps } from "~/components/atoms/forms/Input";
import { PrimaryButton } from "~/components/molecules/buttons/PrimaryButton";

type SearchWordModalProps = {
  /**
   * 閉じるボタンを押したときの挙動
   */
  onClose: (event: React.MouseEvent) => void;
} & Pick<DialogProps, "isOpen"> &
  Pick<InputProps, "onChange" | "value">;

export const SearchWordModal: VFC<SearchWordModalProps> = ({
  onClose,
  isOpen,
  onChange,
  value,
}) => {
  return (
    <Dialog
      isOpen={isOpen}
      body={
        <>
          <Title fontSize={"LG"} lineHeight={"MD"} bold>
            蔵書検索キーワードの設定
          </Title>
          <P fontSize={"MD"} lineHeight={"MD"}>
            市区町村名を登録してください。
            <br />
            左上の設定ボタンからいつでも変更できます。
          </P>
          <InputCon>
            <Input
              onChange={onChange}
              value={value}
              autoFocus
              placeholder={"例：港区、横浜市など"}
            />
          </InputCon>
        </>
      }
    >
      <PrimaryButton
        color={"pri"}
        onClick={onClose}
        disabled={false}
        text={"登録"}
        fontSize={"MD"}
        lineHeight={"MD"}
      />
    </Dialog>
  );
};

const Title = styled(Heading2)`
  color: ${({ theme }) => theme.colors.tex.t800};
`;
const P = styled(Text)`
  color: ${({ theme }) => theme.colors.tex.t700};
`;
const InputCon = styled.div`
  padding: ${({ theme }) => theme.size.XS} 0;
`;
