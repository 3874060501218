import React from "react";
import { useTranslation } from "react-i18next";
import { TinyButton } from "~/components/atoms/buttons/Button";
import { sendButtonClickEvent } from "~/utils/googleAnalytics/useButtonClickTracking";
import { CurrentUserContext } from "../../../../contexts/CurrentUserContext/index";
import { config } from "../../../../../config";

export const ReserveBookButton: React.FC = () => {
  const { t } = useTranslation();
  const { currentUser } = React.useContext(CurrentUserContext);

  return (
    <TinyButton
      onClick={() => {
        sendButtonClickEvent(
          "reserve_book_button",
          String(currentUser?.general.id)
        );
        window.open(
          config.PARENT_APP + "booklist/" + currentUser?.general.id,
          "_blank",
          "noreferrer"
        );
      }}
      variant="blank"
      style={{ marginLeft: "auto", marginRight: "auto" }} // 上下のmarginを設定するとボタンがぴょこつく
    >
      {t<TranslateKeys>("本を予約・管理する")}
    </TinyButton>
  );
};
