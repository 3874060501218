import React from "react";
import { MissionOrder, useCompleteMissionMutation } from "~/generated/graphql";
import { CompleteMissionFlow } from ".";

type CompleteMissionFlowContainerProps = {
  missionOrder: MissionOrder;
  goToBookshelf: () => void;
};

export const CompleteMissionFlowContainer: React.FC<
  CompleteMissionFlowContainerProps
> = ({ missionOrder, goToBookshelf }: CompleteMissionFlowContainerProps) => {
  const [completeMissionMutation, { loading }] = useCompleteMissionMutation();

  const handleCompleteMission = React.useCallback((): Promise<
    MissionOrder | undefined
  > => {
    return new Promise<MissionOrder | undefined>((resolve) => {
      completeMissionMutation()
        .then((res) => {
          if (res.errors) {
            alert("エラーが発生しました");
            resolve(undefined);
          } else {
            resolve(res.data?.completeMission);
          }
        })
        .catch((e) => {
          console.log(e);
          alert("エラーが発生しました");
          resolve(undefined);
        });
    });
  }, [completeMissionMutation]);

  return (
    <CompleteMissionFlow
      missionOrder={missionOrder}
      handleCompleteMission={handleCompleteMission}
      goToBookshelf={goToBookshelf}
      loading={loading}
    />
  );
};
