import React, { VFC } from "react";
import styled, { useTheme } from "styled-components";
import { Select } from "~/components/atoms/forms/Select";
import { Heading2 } from "~/components/atoms/texts/Heading2";
import { BackNavbar } from "~/components/molecules/navbars/BackNavbar";
import { Interpolation } from "~/styles/interpolation";
import { NavContainer, Wrapper } from "../Common";
import { EditRecommendSettingTemplateProps } from "./type";
import frequencyList from "./const";
import { Text } from "~/components/atoms/texts/Text";
import { PrimaryButton } from "~/components/molecules/buttons/PrimaryButton";
import { RecommendFrequency } from "~/generated/graphql";
import { NotifyTextToast } from "~/components/organisms/toasts/NotifyTextToast";
import { Input } from "~/components/atoms/forms/Input";
import { useTranslation } from "~/i18n";

export const EditRecommendSettingTemplate: VFC<
  EditRecommendSettingTemplateProps
> = ({
  recommendFrequency,
  searchWord,
  onClick,
  handleBack,
  onChange,
  onChangeSearchWord,
  onSubmitSearchWord,
  openModal,
  setOpenModal,
  modalMessage,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <>
      <NavContainer>
        <BackNavbar
          titleName={t<TranslateKeys>("おすすめの本") + "設定"}
          onClick={handleBack}
        />
      </NavContainer>
      <Wrapper>
        <TopWrapper>
          <Container>
            <Heading fontSize={"MD"} lineHeight={"EQ"} bold>
              おすすめ冊数の設定
            </Heading>
            <Select value={recommendFrequency} onChange={onChange}>
              {Object.keys(frequencyList).map((key, _) => (
                <option value={key as RecommendFrequency} key={_}>
                  {frequencyList[key].name}
                </option>
              ))}
            </Select>
            <div>
              <ContentsBox>
                <TitleContainer>
                  <Emoji fontSize={"MD"} lineHeight={"EQ"} bold>
                    {frequencyList[recommendFrequency].emoji}
                  </Emoji>
                  <TitleText fontSize={"MD"} lineHeight={"EQ"} bold>
                    {frequencyList[recommendFrequency].name}
                  </TitleText>
                </TitleContainer>
                <Upper>
                  <LeadText fontSize={"MD"} lineHeight={"MD"} bold>
                    {frequencyList[recommendFrequency].lead}
                  </LeadText>
                  <SubTextWrapper>
                    {frequencyList[recommendFrequency].sub && (
                      <Text
                        fontSize={"SM"}
                        lineHeight={"MD"}
                        style={{ color: theme.colors.tex.t500 }}
                      >
                        {frequencyList[recommendFrequency].sub}
                      </Text>
                    )}
                  </SubTextWrapper>
                </Upper>
                <Border />
                <Bottom>
                  <ListItem>
                    <ListKey fontSize={"SM"} lineHeight={"MD"}>
                      おすすめ本上限冊数
                    </ListKey>
                    <ListValue
                      fontSize={"MD"}
                      lineHeight={"EQ"}
                      bold
                    >{`${frequencyList[recommendFrequency].bookListMax}冊`}</ListValue>
                  </ListItem>
                  <ListItem>
                    <ListKey fontSize={"SM"} lineHeight={"MD"}>
                      おすすめの本リストが何冊になったら更新されるか
                    </ListKey>
                    <ListValue
                      fontSize={"MD"}
                      lineHeight={"EQ"}
                      bold
                    >{`${frequencyList[recommendFrequency].border}冊`}</ListValue>
                  </ListItem>
                </Bottom>
              </ContentsBox>
              <PrimaryButton
                text={"保存して完了"}
                onClick={onClick}
                color={"pri"}
                fontSize={"XL"}
                lineHeight={"MD"}
                bold
              />
            </div>
          </Container>
          <Container>
            <div>
              <Heading fontSize={"MD"} lineHeight={"EQ"} bold>
                蔵書検索キーワードの設定
              </Heading>
              <P fontSize={"SM"} lineHeight={"MD"}>
                市区町村名を登録してください。
              </P>
            </div>
            <Input
              onChange={onChangeSearchWord}
              value={searchWord}
              placeholder={"例：港区、横浜市など"}
            />
            <PrimaryButton
              text={"登録する"}
              onClick={onSubmitSearchWord}
              color={"pri"}
              fontSize={"XL"}
              lineHeight={"MD"}
              bold
            />
          </Container>
        </TopWrapper>
      </Wrapper>
      <NotifyTextToast
        isOpen={openModal}
        setIsOpen={setOpenModal}
        text={modalMessage}
      />
    </>
  );
};

const TopWrapper = styled.div`
  padding: 74px 0 24px 0;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.size.L};
`;

const Container = styled.div`
  ${Interpolation.Border}
  width: 100%;
  border-radius: ${({ theme }) => theme.deprecatedBorderRadius.MD};
  background-color: ${({ theme }) => theme.colors.base.white};
  padding: ${({ theme }) => theme.size.XL};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.size.XXXL};
  margin-bottom: 12px;
`;

const Heading = styled(Heading2)`
  color: ${({ theme }) => theme.colors.tex.t800};
  margin-bottom: ${({ theme }) => theme.size.XXS};
`;

const ContentsBox = styled.div`
  ${Interpolation.Border}
  border-radius: ${({ theme }) => theme.deprecatedBorderRadius.MD};
  padding: 20px;
  margin: 8px 0;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 12px 0;
`;

const TitleContainer = styled.div`
  position: absolute;
  width: 50%;
  height: 20px;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${({ theme }) => theme.colors.base.white};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TitleText = styled(Text)`
  color: ${({ theme }) => theme.colors.tex.t800};
`;

const P = styled(Text)`
  color: ${({ theme }) => theme.colors.tex.t700};
`;

const Emoji = styled(Text)`
  margin-right: 4px;
  color: ${({ theme }) => theme.colors.tex.t800};
`;

const Upper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Border = styled.div`
  height: 0;
  width: 100%;
  ${Interpolation.BorderBottom}
`;

const Bottom = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.size.S};
`;

const LeadText = styled(Text)`
  color: ${({ theme }) => theme.colors.pri.p600};
  margin-bottom: ${({ theme }) => theme.size.XS};
`;

const SubTextWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 4px;
`;

const ListItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ListKey = styled(Text)`
  color: ${({ theme }) => theme.colors.tex.t300};
  width: 80%;
`;

const ListValue = styled(Text)`
  color: ${({ theme }) => theme.colors.tex.t800};
`;
