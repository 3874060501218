import React from "react";
import { ReadingTechniqueType } from "~/generated/graphql";
import { SelectReadingTechniqueModal } from "./SelectReadingTechniqueModal";
import { ConfirmTechniqueModal } from "./ConfirmTechniqueModal";
import { hintWithReadingTechnique } from "./const";
import { TranslateKeys } from "~/i18n";

type Flow = "select" | "confirm";

type HintModalProps = {
  onClose: () => void;
};

export const useHintModal = ({
  onClose,
}: HintModalProps): {
  HintModal: React.ReactElement;
  placeHolder?: TranslateKeys;
} => {
  const [readingTechniqueType, setReadingTechniqueType] =
    React.useState<ReadingTechniqueType>(ReadingTechniqueType.Imagine);
  const [flow, setFlow] = React.useState<Flow>("select");
  const [hintNum, setHintNum] = React.useState<number>(0);
  const [placeHolder, setPlaceHolder] = React.useState<
    TranslateKeys | undefined
  >(undefined);

  const handleNextSelectModal = React.useCallback(() => {
    setFlow("confirm");
    const _hintNum = Math.floor(
      Math.random() * hintWithReadingTechnique[readingTechniqueType].length
    );
    setHintNum(_hintNum);
  }, [readingTechniqueType]);

  return {
    HintModal:
      flow === "select" ? (
        <SelectReadingTechniqueModal
          isOpen={flow === "select"}
          onNext={handleNextSelectModal}
          onBack={onClose}
          readingTechniqueType={readingTechniqueType}
          setReadingTechniqueType={setReadingTechniqueType}
        />
      ) : (
        <ConfirmTechniqueModal
          isOpen={flow === "confirm"}
          readingTechniqueType={readingTechniqueType}
          hintNum={hintNum}
          onNext={() => {
            setPlaceHolder(
              hintWithReadingTechnique[readingTechniqueType][hintNum]
            );
            onClose();
          }}
          onBack={() => setFlow("select")}
          onClose={onClose}
        />
      ),
    placeHolder: placeHolder,
  };
};
