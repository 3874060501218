import React from "react";
import { useUnlock } from "~/store/unlock/useUnlock";
import styled from "styled-components";
import { Text } from "~/components/atoms/texts/Text";

type BfStarWrapperProps = {
  children: React.ReactNode;
};

const bookWormKeyUrl = "/img/unlock/book_worm_key.svg";

export const BfStarLockWrapper: React.FC<BfStarWrapperProps> = ({
  children,
}) => {
  const { isLocked } = useUnlock();

  if (isLocked("bfStar")) {
    return (
      <_BookWormWrapper>
        <_Wrapper>
          <Text fontSize="XS" lineHeight="EQ" marginRight="2px">
            ⭐️
          </Text>
          <Text fontSize="XS" lineHeight="EQ" keepAll>
            スター
          </Text>
        </_Wrapper>
        <_BookWormKey src={bookWormKeyUrl} />
      </_BookWormWrapper>
    );
  } else {
    return <>{children}</>;
  }
};

const _BookWormWrapper = styled.div`
  margin-top: 1px;
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 3px;

  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.semantic.layout.borderPlus1};
  background: ${({ theme }) => theme.colors.semantic.layout.backgroundBox};
`;

const _BookWormKey = styled.img`
  position: absolute;
  width: 19px;
`;

const _Wrapper = styled.div`
  position: relative;
  display: flex;
  column-gap: 4px;
  align-items: center;
  visibility: hidden;
`;
