import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowLeft,
  faArrowRight,
  faBars,
  faBell,
  faBook,
  faBookmark,
  faCamera,
  faCaretDown,
  faChartColumn,
  faCaretUp,
  faCheck,
  faChevronDown,
  faChevronRight,
  faClipboardCheck,
  faClipboardList,
  faClone,
  faCloudArrowDown,
  faCog,
  faComment,
  faCommentDots,
  faCopy,
  faCrown,
  faEllipsisH,
  faEnvelope,
  faExclamation,
  faExclamationTriangle,
  faExclamationCircle,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFile,
  faFilm,
  faForwardFast,
  faGear,
  faGrinWink,
  faHeart,
  faInfoCircle,
  faMedal,
  faMicrophone,
  faPencilAlt,
  faPlus,
  faPrint,
  faQuestionCircle,
  faSearch,
  faShareNodes,
  faSkullCrossbones,
  faStar,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faTrash,
  faVolumeDown,
  faVolumeUp,
} from "@fortawesome/free-solid-svg-icons";
export const initFontAwesomeIcons = (): void => {
  library.add(
    faPencilAlt,
    faArrowLeft,
    faArrowRight,
    faClipboardList,
    faClipboardCheck,
    faSkullCrossbones,
    faClone,
    faCheck,
    faPlus,
    faBookmark,
    faBell,
    faTimesCircle,
    faCrown,
    faBook,
    faSearch,
    faQuestionCircle,
    faMicrophone,
    faFile,
    faCamera,
    faCopy,
    faTimes,
    faChevronRight,
    faChevronDown,
    faMedal,
    faEllipsisH,
    faCog,
    faHeart,
    faInfoCircle,
    faStar,
    faTrash,
    faExclamation,
    faExclamationCircle,
    faExternalLinkAlt,
    faEnvelope,
    faVolumeDown,
    faVolumeUp,
    faBars,
    faComment,
    faExclamationTriangle,
    faCloudArrowDown,
    faPrint,
    faShareNodes,
    faCommentDots,
    faThumbsUp,
    faGear,
    faCaretDown,
    faCaretUp,
    faChartColumn,
    faFilm,
    faForwardFast,
    faEye,
    faEyeSlash,
    faGrinWink
  );
};
