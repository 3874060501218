import { AllBadgeType, ALL_BUDGES } from "~/helpers/constants/badge";

const countEachBadgeNumber = (
  type: AllBadgeType,
  threshold: number
): number => {
  const BADGE = ALL_BUDGES[type];

  return BADGE.THRESHOLDS.map((THRESHOLD) => {
    return threshold >= THRESHOLD;
  }).filter((v) => v).length;
};

export const countAllBadgeNumber = (
  readCharSum: number,
  continueReadLogDays: number,
  readBookSum: number
): number => {
  return (
    countEachBadgeNumber("readCharSum", readCharSum) +
    countEachBadgeNumber("maxContinueReadLogDays", continueReadLogDays) +
    countEachBadgeNumber("readBookSum", readBookSum)
  );
};

// /**
//  * @param type バッジの種類
//  * @param threshold その時のユーザーの記録
//  * @returns `type`で指定した種類の全バッジの取得/未取得状況とバッジ画像データのsrcをオブジェクトで返す
//  */
// export const transformBadge = (
//   type: AllBadgeType,
//   threshold: number
// ): BadgeItem[] => {
//   const BADGE = ALL_BUDGES[type];
//   return BADGE.THRESHOLDS.map((THRESHOLD) => {
//     const isAcquired = threshold >= THRESHOLD;
//     const fileName = `${BADGE.IMAGE_FILE_NAME_PREFIX}_${THRESHOLD}.png`;
//     const imgSrc = isAcquired
//       ? `img/badge/3x/${fileName}`
//       : `img/badge/1x/m/${fileName}`;

//     return { imgSrc, isAcquired };
//   });
// };

// /**
//  * @param badges 全バッジの取得/未取得状況
//  * @returns 一番最近取得したバッジの画像のsrc
//  */
// export const getRecentlyAquiredBadge = (badges: BadgeItem[]): string => {
//   let recentAquired = null as unknown as number;
//   for (let i = badges.length - 1; i >= 0; --i) {
//     if (badges[i].isAcquired) {
//       recentAquired = i;
//       break;
//     }
//   }
//   return recentAquired === null
//     ? "img/badge/3x/char_initial.png"
//     : badges[recentAquired].imgSrc;
// };
