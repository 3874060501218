import React from "react";
import styled from "styled-components";
import { Text } from "~/components/atoms/texts/Text";
import { PrimaryButton } from "~/components/molecules/buttons/PrimaryButton";
import { Dialog } from "~/components/molecules/modals/Dialog";
import { LockedFeatures, unlockLevel } from "~/store/unlock/useUnlock";
import { useCongratulateUnLockModalContents } from "./const";

export type CongratulateUnlockModalProps = {
  isOpen: boolean;
  onClose: (event: React.MouseEvent) => void;
  nickname: string;
  lockedFeature: LockedFeatures;
};

export const CongratulateUnlockModal: React.FC<
  CongratulateUnlockModalProps
> = ({ isOpen, onClose, nickname, lockedFeature }) => {
  const contents = useCongratulateUnLockModalContents(lockedFeature);

  return (
    <Dialog
      isOpen={isOpen}
      body={
        <>
          <ModalTextLight fontSize={"SM"} lineHeight={"MD"}>
            {nickname + "さん、"}
            <br />
            {"レベル" + unlockLevel[lockedFeature] + "のカギが開きました🙌"}
          </ModalTextLight>
          <Text
            fontSize={"MD"}
            lineHeight={"MD"}
            bold
            style={{ marginTop: "5px", marginBottom: "5px" }}
          >
            {contents.title}
          </Text>
          <img
            src={contents.image}
            style={{
              width: "100%",
              maxWidth: "100%",
              maxHeight: "50vh",
              marginTop: "15px",
              marginBottom: "15px",
              objectFit: "contain",
            }}
          />
          {contents.advice && (
            <ModalTextLight fontSize={"SM"} lineHeight={"MD"}>
              {contents.advice}
            </ModalTextLight>
          )}
        </>
      }
    >
      <PrimaryButton
        color={"pri"}
        onClick={onClose}
        disabled={false}
        withArrow={false}
        text={"すすむ"}
        fontSize={"XL"}
        lineHeight={"EQ"}
        bold
        padding="16px 0"
      />
    </Dialog>
  );
};

const ModalTextLight = styled(Text)`
  color: ${({ theme }) => theme.colors.tex.t300};
  white-space: pre-wrap;
`;
