import React from "react";
import { MasonryPagiableList } from "~/components/molecules/lists/MasonryPagiableList";
import { ListItemProps } from "~/components/molecules/lists/MasonryPagiableList/types";
import { RecommendBookImgButtonListItem } from "~/components/molecules/ListItem/RecommendBookImgButtonListItem";
import { OtherBookImgButtonListItem } from "~/components/molecules/ListItem/OtherBookImgButtonListItem";
import {
  GetNowRecommendQuery,
  GetWantReadBooksQuery,
} from "~/generated/graphql";
import { BookImgButtonListItemProps } from "./type";
import { WantReadBookImgButtonListItem } from "~/components/molecules/ListItem/WantReadBookImgButtonListItem";

export type SelectBookImgListProps = {
  currentCount: number;
  recommendData: GetNowRecommendQuery;
  wantReadBookData: GetWantReadBooksQuery;
  itemTotal: number;
  itemsPerPage: number;
  items: BookImgButtonListItemProps[];
  onPageChange: (page: number) => void;
  renderEmptyComponent: () => JSX.Element;
  onClickRecommend: (
    event: React.MouseEvent,
    recommendId: number,
    isbn: string,
    title: string
  ) => void;
  onClickWantReadBook: (
    event: React.MouseEvent,
    isbn: string,
    title: string
  ) => void;
  onClickOtherBooks: (event: React.MouseEvent) => void;
  columnsNumber: number;
};

export const SelectBookImgList: React.FC<SelectBookImgListProps> = ({
  currentCount,
  itemTotal,
  itemsPerPage,
  items,
  onPageChange,
  renderEmptyComponent,
  onClickRecommend,
  onClickWantReadBook,
  onClickOtherBooks,
}) => {
  return (
    <MasonryPagiableList
      currentCount={currentCount}
      items={items}
      itemTotal={itemTotal}
      itemsPerPage={itemsPerPage}
      onPageChange={onPageChange}
      renderListItem={({ item }: ListItemProps<BookImgButtonListItemProps>) => {
        const {
          recommendId,
          isbn,
          title,
          imageUrl,
          columnsNumber,
          bookImgItemType,
        } = item;

        switch (bookImgItemType) {
          case "OTHER": {
            return (
              <OtherBookImgButtonListItem
                key={"isOtherItem"}
                onClick={(event) => onClickOtherBooks(event)}
                columnsNumber={columnsNumber}
              />
            );
          }
          case "RECOMMEND": {
            return (
              <RecommendBookImgButtonListItem
                key={`${isbn}-${recommendId}`}
                title={title}
                imageUrl={imageUrl}
                columnsNumber={columnsNumber}
                onClick={(event) =>
                  onClickRecommend(event, recommendId, isbn, title)
                }
              />
            );
          }
          case "WANT_READ_BOOK": {
            return (
              <WantReadBookImgButtonListItem
                key={`${isbn}`}
                title={title}
                imageUrl={imageUrl}
                columnsNumber={columnsNumber}
                onClick={(event) => onClickWantReadBook(event, isbn, title)}
              />
            );
          }
          default: {
            return <></>;
          }
        }
      }}
      renderEmptyComponent={renderEmptyComponent}
    />
  );
};
