import styled, {
  css,
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from "styled-components";
import { AllColorTheme } from "~/styles/design_token/color";
import { FontLineHeight } from "~/styles/design_token/font";
import { MinilessonFontSizeType } from "../AdminMessageListItem/messageListItems/constant";
import get from "lodash/get";

type AdminMessageTextType =
  | "plain"
  | "flatButton"
  | "primaryButton"
  | "plain_me"
  | "input";

type AdminMessageTextSettings = {
  fontSize: Record<MinilessonFontSizeType, string>;
  lineHeight: FontLineHeight;
  bold: boolean;
  color: AllColorTheme;
};

export const defaultAdminMessageRecord: Readonly<
  Record<AdminMessageTextType, AdminMessageTextSettings>
> = {
  plain: {
    fontSize: {
      xLarge: "1.75rem",
      large: "1.5rem",
      medium: "1.25rem",
      small: "1rem",
    },
    lineHeight: "LG",
    bold: false,
    color: "semantic.text.heading",
  },
  flatButton: {
    fontSize: {
      xLarge: "1.625rem",
      large: "1.375rem",
      medium: "1.125rem",
      small: "0.875rem",
    },
    lineHeight: "MD",
    bold: false,
    color: "semantic.primary.minus1",
  },
  primaryButton: {
    fontSize: {
      xLarge: "1.75rem",
      large: "1.5rem",
      medium: "1.25rem",
      small: "1rem",
    },
    lineHeight: "MD",
    bold: true,
    color: "semantic.layout.backgroundBox",
  },
  plain_me: {
    fontSize: {
      xLarge: "1.75rem",
      large: "1.5rem",
      medium: "1.25rem",
      small: "1rem",
    },
    lineHeight: "LG",
    bold: false,
    color: "semantic.layout.backgroundBox",
  },
  input: {
    fontSize: {
      xLarge: "1.5rem",
      large: "1.375rem",
      medium: "1.125rem",
      small: "1rem",
    },
    lineHeight: "EQ",
    bold: false,
    color: "semantic.text.heading",
  },
};

export type AdminMessageTextProps = {
  fontSize: MinilessonFontSizeType;
  textType: AdminMessageTextType;
};

const TextInterpolation = (
  props: AdminMessageTextProps
): FlattenInterpolation<ThemeProps<DefaultTheme>> => {
  return css`
    font-family: inherit;
    font-weight: ${defaultAdminMessageRecord[props.textType].bold
      ? "bold"
      : "normal"};
    word-wrap: break-word;
    ${({ theme }) => {
      return css`
        color: ${get(
          theme.colors,
          defaultAdminMessageRecord[props.textType].color
        )};
        font-size: ${defaultAdminMessageRecord[props.textType].fontSize[
          props.fontSize
        ]};
        line-height: ${defaultAdminMessageRecord[props.textType].lineHeight};
      `;
    }}
  `;
};

export const AdminMessageText = styled.p<AdminMessageTextProps>`
  ${TextInterpolation}
  @supports (word-break: auto-phrase) {
    word-break: auto-phrase;
  }
`;
