import React from "react";
import styled from "styled-components";

export type CarouselDotsProps = {
  pageTotal: number;
  nowPageIndex: number;
};

export const CarouselDots: React.VFC<CarouselDotsProps> = ({
  pageTotal,
  nowPageIndex,
}) => {
  const dots = [...Array(pageTotal)].map((_, i) => i);
  return (
    <DotContainer>
      {dots.map((page) => {
        return page === nowPageIndex ? (
          <DotStyle key={page} selected />
        ) : (
          <DotStyle key={page} />
        );
      })}
    </DotContainer>
  );
};

type DotStyle = {
  selected?: boolean;
};

const DotContainer = styled.div`
  display: flex;
  & :first-child {
    margin-left: 0px;
  }
`;

const DotStyle = styled.div<DotStyle>`
  width: 7px;
  height: 7px;
  margin-left: 5px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.selected
      ? props.theme.colors.pri.p400
      : props.theme.colors.base.gray};
`;
