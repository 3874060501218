import React from "react";
import { IndicateBadgeTemplateProps } from "./type";
import { ProgressNavigation } from "~/components/organisms/ProgressNavigation";
import { NavContainer, Wrapper } from "../../Common";
import { GetBadgeModal } from "~/components/organisms/modals/GetBadgeModal";
import { PostReadLogBarWrapper } from "../Common/style";

export const IndicateBadgeTemplate: React.FC<IndicateBadgeTemplateProps> = ({
  myOldRecords,
  myNewRecords,
  modalOpen,
  onClose,
}) => (
  <>
    <NavContainer>
      <PostReadLogBarWrapper>
        <ProgressNavigation
          progress={100}
          handleBack={() => {
            return;
          }}
          buttonsPattern={"single"}
        />
      </PostReadLogBarWrapper>
    </NavContainer>
    <Wrapper>
      {modalOpen && (
        <GetBadgeModal
          onClose={onClose}
          myOldRecords={myOldRecords}
          myNewRecords={myNewRecords}
        />
      )}
    </Wrapper>
  </>
);
