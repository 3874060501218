import React from "react";
import { ReadEndExpTutorialModal } from "~/components/organisms/modals/ExperiencePoint/ReadEndExpTutorialModal";
import { useExperiencePointModal } from "~/components/organisms/modals/ExperiencePoint/hook";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import { ReadStatusForPostReadLog, TutorialFlagKey } from "~/generated/graphql";
import { usePostReadLogState } from "~/pages/PostReadLog/usePostReadLogState";

export type IndicateExperiencePointTemplateProps = {
  handleNext: () => void;
};

export const IndicateExperiencePointTemplate: React.FC<
  IndicateExperiencePointTemplateProps
> = ({ handleNext }) => {
  const { state } = usePostReadLogState();
  const { updatedUserExperience, getTutorialFlag, saveTutorialFlags, loading } =
    React.useContext(CurrentUserContext);

  const onCloseTutorialModal = () => {
    saveTutorialFlags(TutorialFlagKey.ReachedMaxBookCountForExpModal);
    handleNext();
  };

  const openTutorialModal =
    updatedUserExperience?.achieveExperienceEvents.length === 0 &&
    state.readStatus === ReadStatusForPostReadLog.End &&
    !getTutorialFlag(TutorialFlagKey.ReachedMaxBookCountForExpModal);

  // NOTE: チュートリアルが表示される時だけはスキップされないようにする
  const experienceModal = useExperiencePointModal(
    openTutorialModal
      ? () => {
          return;
        }
      : handleNext
  );

  if (openTutorialModal) {
    return (
      <ReadEndExpTutorialModal
        isOpen={true}
        onClose={onCloseTutorialModal}
        loading={loading}
      />
    );
  }

  return experienceModal;
};
