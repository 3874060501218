import React from "react";
import styled from "styled-components";
import { ProgressBar } from "~/components/atoms/ProgressBar";
import { Text } from "~/components/atoms/texts/Text";
import {
  UserExperienceEvent,
  UserExperienceEventName,
} from "~/generated/graphql";
import { useAudio } from "~/utils/hooks/useAudio";
import { BaseModal } from "~/components/atoms/wrappers/BaseModal";
import { LargeButton } from "~/components/atoms/buttons/Button";
import { Stack } from "~/components/atoms/layout/Stack";
import { Row } from "~/components/atoms/layout/Row";

export type GetExperiencePointModalProps = {
  currentLevel: number;
  previousLevel: number;
  achieveEvents: UserExperienceEvent[];
  currentProgress: number;
  previousProgress: number;
  isMaxLevel?: boolean;
  expToNextLevelUp: number;
  isOpen: boolean;
  onClose: () => void;
};

const FLAME_NUMBER = 240;

export const GetExperiencePointModal: React.FC<
  GetExperiencePointModalProps
> = ({
  currentLevel,
  previousLevel,
  achieveEvents,
  currentProgress,
  previousProgress,
  isMaxLevel = false,
  expToNextLevelUp,
  isOpen,
  onClose,
}) => {
  const [progress, setProgress] = React.useState<number>(previousProgress);
  const [count, setCount] = React.useState<number>(0);
  const { play } = useAudio({ preload: ["drumRoll"] });

  const totalAchivedPoint = achieveEvents
    .map((event) => event.point)
    .reduce((a, b) => a + b, 0);

  React.useEffect(() => {
    if (!isOpen) {
      setCount(0);
    } else {
      play("drumRoll");
    }
  }, [isOpen, play]);

  React.useEffect(() => {
    // NOTE: レベルが異なる場合progressが100を超えるようにする
    // 例えばレベル1、progress50%からレベル2、progress30%になった場合progressは50から130まで動くが、progressBarには100で割った余りを渡す
    // レベルが最大になった場合は0で動かないようにする
    const initialProgress = !isMaxLevel
      ? previousProgress
      : currentLevel === previousLevel
      ? 0
      : previousProgress;

    const finalProgress = isMaxLevel
      ? 100 * (currentLevel - previousLevel)
      : currentProgress + 100 * (currentLevel - previousLevel);

    const changeProgress = () => {
      // NOTE: 増加を${FLAME_NUMBER}段階に分けてアニメーションっぽく動かす
      if (count <= FLAME_NUMBER && isOpen) {
        setProgress(
          initialProgress +
            (finalProgress - initialProgress) * (count / FLAME_NUMBER)
        );
        setCount(count + 1);
      }
    };

    if (count <= FLAME_NUMBER) {
      const IntervalID = setTimeout(changeProgress, 10);
      return () => clearTimeout(IntervalID);
    }
  }, [
    count,
    isMaxLevel,
    previousLevel,
    previousProgress,
    currentProgress,
    currentLevel,
    isOpen,
    achieveEvents,
  ]);

  return (
    <BaseModal open={isOpen} onClose={onClose}>
      <_Wrapper>
        <Text bold={true} fontSize="MD" lineHeight="MD" fontColor="tex.t700">
          けいけんちをかくとくしたよ！✨
        </Text>
        <Stack rowGap="8px">
          {achieveEvents.map((event, index) => {
            return (
              <Row
                key={index}
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Text
                  bold={false}
                  fontSize="MD"
                  lineHeight="MD"
                  fontColor="tex.t700"
                >
                  {displayNameByEventName[event.eventName]}
                </Text>
                <Text
                  bold={true}
                  fontSize="MD"
                  lineHeight="MD"
                  fontColor="sky.s400"
                >
                  +{event.point}
                </Text>
              </Row>
            );
          })}
        </Stack>
        <_ExperiencePointWrapper>
          <Row justifyContent="space-between" alignItems="center" width="100%">
            <Row columnGap="4px" alignItems="center">
              <img src="/img/icon/candy.svg" />
              <Text
                bold={true}
                fontSize="MD"
                lineHeight="MD"
                fontColor="tex.t700"
              >
                けいけんち
              </Text>
            </Row>
            <Text
              bold={true}
              fontSize="MD"
              lineHeight="MD"
              fontColor="sky.s400"
            >
              +{totalAchivedPoint}
            </Text>
          </Row>
          <_ProgressBarWrapper>
            <Text
              bold={true}
              fontSize="SM"
              lineHeight="EQ"
              fontColor="tex.t700"
            >
              Lv
              {isMaxLevel &&
              previousLevel + Math.floor(progress / 100) === currentLevel
                ? "MAX"
                : previousLevel + Math.floor(progress / 100)}
            </Text>
            <ProgressBar
              progress={progress % 100}
              height="6px"
              progressColor="sky.s400"
              backgroundColor="base.lightGray"
            />
          </_ProgressBarWrapper>
          {!isMaxLevel && (
            <_NextLevelWrapper>
              <Text
                bold={false}
                fontSize="XS"
                lineHeight="EQ"
                fontColor="tex.t500"
              >
                あと{expToNextLevelUp}でLv.{currentLevel + 1}
              </Text>
            </_NextLevelWrapper>
          )}
        </_ExperiencePointWrapper>
        <_ButtonWrapper>
          <LargeButton color="primary" onClick={onClose}>
            すすむ
          </LargeButton>
        </_ButtonWrapper>
      </_Wrapper>
    </BaseModal>
  );
};

const displayNameByEventName: Record<UserExperienceEventName, string> = {
  [UserExperienceEventName.PostFirstReadLogOfTheDay]: "きょうはじめてボーナス",
  [UserExperienceEventName.PostReadLogEnded]: "どくはボーナス",
  [UserExperienceEventName.TakeMiniLesson]: "ミニレッスンボーナス",
};

const _Wrapper = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  align-items: center;
  background-color: ${({ theme }) =>
    theme.colors.semantic.layout.backgroundBox};
  border-radius: 24px;
`;

const _ExperiencePointWrapper = styled.div`
  width: 250px;
  padding: 16px;
  border: 3px solid;
  border-color: ${({ theme }) => theme.colors.semantic.layout.border};
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  align-items: center;
`;

const _ProgressBarWrapper = styled.div`
  width: 100%;
  display: flex;
  column-gap: 8px;
  align-items: center;
`;

const _ButtonWrapper = styled.div`
  width: 100%;
  max-width: 250px;
`;

const _NextLevelWrapper = styled.div`
  width: 100%;
  text-align: right;
`;
