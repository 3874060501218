const LOGIN_PAGE_VIEWED_AT_STORAGE_KEY = "login_page_viewed_at";

export const getLoginPageViewedAt = (): string | null => {
  try {
    return localStorage.getItem(LOGIN_PAGE_VIEWED_AT_STORAGE_KEY);
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const setLoginPageViewedAt = (date: Date): void => {
  try {
    localStorage.setItem(LOGIN_PAGE_VIEWED_AT_STORAGE_KEY, date.toISOString());
  } catch (e) {
    console.error(e);
  }
};

export const clearLoginPageViewedAt = (): void => {
  try {
    localStorage.removeItem(LOGIN_PAGE_VIEWED_AT_STORAGE_KEY);
  } catch (e) {
    console.error(e);
  }
};

const LOGGED_IN_AT_STORAGE_KEY = "logged_in_at";

export const getLoggedInAt = (): string | null => {
  try {
    return localStorage.getItem(LOGGED_IN_AT_STORAGE_KEY);
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const setLoggedInAt = (date: Date): void => {
  try {
    localStorage.setItem(LOGGED_IN_AT_STORAGE_KEY, date.toISOString());
  } catch (e) {
    console.error(e);
  }
};

export const clearLoggedInAt = (): void => {
  try {
    localStorage.removeItem(LOGGED_IN_AT_STORAGE_KEY);
  } catch (e) {
    console.error(e);
  }
};
