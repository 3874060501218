import React, { useCallback, useEffect, useMemo, useState, FC } from "react";
import styled from "styled-components";
import { Text } from "~/components/atoms/texts/Text";
import { PrimaryButton } from "~/components/molecules/buttons/PrimaryButton";
import { useTranslation } from "~/i18n";
import { PromiseStatus, ReadLogPromise } from "../types";
import dictionary from "./locales/l6";

type TranslationKey = keyof typeof dictionary;

type PromiseWithoutNextBook = Omit<ReadLogPromise, "chooseNext" | "nextISBN">;

type TranslationParams = {
  user: string;
  title: string;
  dueDateMonth: number;
  dueDateDay: number;
};

type Props = {
  promise?: PromiseWithoutNextBook;
  wasWantReadBook: boolean;
  translationParams: TranslationParams;
  onNext: () => void;
};

const _MultilineText = styled(Text)`
  white-space: pre-wrap;
`;

type GetPromiseMessageParams = {
  wasWantReadBook: boolean;
  promiseStatus?: PromiseStatus;
};
const getMessage = ({
  wasWantReadBook,
  promiseStatus,
}: GetPromiseMessageParams): TranslationKey[] => {
  if (wasWantReadBook) {
    const randomMsg =
      wantReadBookMsgs[Math.floor(Math.random() * wantReadBookMsgs.length)];
    return [randomMsg];
  }

  if (
    promiseStatus === PromiseStatus.Fulfill ||
    promiseStatus === PromiseStatus.Earlyfulfill
  ) {
    return [
      "{{ user }}さん、\n約束通りに 感想をとどけてくれてありがとう！さすがだね😆✨",
    ];
  }

  return ["{{ user }}さん、\n感想をとどけてくれてありがとう！"];
};

export const FeedbackMessage: FC<Props> = ({
  translationParams,
  onNext,
  promise,
  wasWantReadBook,
}) => {
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(0);

  const messages = useMemo(() => {
    const m: string[] = getMessage({
      wasWantReadBook,
      promiseStatus: promise?.status,
    });

    return m.map((key) => t(key, translationParams));
  }, [t, translationParams, promise?.status, wasWantReadBook]);

  useEffect(() => {
    setPage(0);
  }, [messages]);

  const handleNext = useCallback(() => {
    const nextPage = page + 1;
    if (nextPage >= messages.length) {
      onNext();
    } else {
      setPage(page + 1);
    }
  }, [messages, page, onNext, setPage]);

  return (
    <_Wrapper>
      <_MultilineText fontSize="MD" lineHeight="MD">
        {messages[page]}
      </_MultilineText>
      <PrimaryButton
        text="はーい"
        fontSize="MD"
        lineHeight="MD"
        color="pri"
        onClick={() => handleNext()}
      />
    </_Wrapper>
  );
};

const _Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

const wantReadBookMsgs: TranslationKey[] = [
  "感想を 教えてくれて、ありがとう🤗\nヨンデミー先生も、その本を 読んでみたく なっちゃった👀✨",
  "すてきな 本を 教えてくれて、うれしいな🥰\nこれからも 感想を 聞かせてね🙌",
  "さすが {{ user }}さん！\nワクワクする とっても すてきな 感想だったよ😆✨",
  "新しい 本を 見つけると ワクワクするよね😊\nこれからも {{ user }}さんの 感想が 楽しみだ〜！",
];
