import { CharacterEnqueteChoice, CharacterType } from "~/generated/graphql";

export const calculateResultOfChoices = (
  choices: CharacterEnqueteChoice[]
): CharacterEnqueteChoice => {
  const countBox: {
    characterEnqueteChoice: CharacterEnqueteChoice;
    count: number;
  }[] = [];
  Object.values(CharacterEnqueteChoice).forEach(
    (choice: CharacterEnqueteChoice) => {
      countBox.push({
        characterEnqueteChoice: choice,
        count: choices.filter((c) => c === choice).length,
      });
    }
  );
  const maxCount = Math.max(...countBox.map((element) => element.count));
  const maxElements = countBox.filter((element) => element.count === maxCount);
  if (maxElements.length === 1) {
    return maxElements[0].characterEnqueteChoice;
  } else if (maxElements.length >= 2) {
    const random = Math.floor(Math.random() * maxElements.length);
    return maxElements[random].characterEnqueteChoice;
  } else {
    return CharacterEnqueteChoice.Choice_1;
  }
};

export const choiceAndCharacterRecord: Record<
  CharacterEnqueteChoice,
  { former: CharacterType; latter: CharacterType }
> = {
  CHOICE_1: {
    former: CharacterType.Dinosaur,
    latter: CharacterType.SchoolPrincipal,
  },
  CHOICE_2: {
    former: CharacterType.Sheep,
    latter: CharacterType.Wolf,
  },
  CHOICE_3: {
    former: CharacterType.Lion,
    latter: CharacterType.Penguin,
  },
  CHOICE_4: {
    former: CharacterType.Apple,
    latter: CharacterType.Giraffe,
  },
};

export type TypeQuestionAndAnswer = {
  question: TranslateKeys;
  answers: {
    emoji: string;
    answer: TranslateKeys;
  }[];
};

export type CharacterQuestionAndAnswer = {
  question: TranslateKeys;
  answers: Record<
    CharacterEnqueteChoice,
    { emoji: string; answer: TranslateKeys }[]
  >;
};

export const typeIdentifyEnquete: TypeQuestionAndAnswer[] = [
  {
    question: "今食べたいのは？",
    answers: [
      { emoji: "🍔", answer: "ハンバーグ" },
      { emoji: "🍛", answer: "野菜カレー" },
      { emoji: "🥪", answer: "フルーツサンド" },
      { emoji: "🥞", answer: "パンケーキ" },
    ],
  },
  {
    question: "好きなことは何？",
    answers: [
      { emoji: "💪", answer: "体を動かすこと" },
      { emoji: "🎵", answer: "音楽を聴くこと" },
      { emoji: "🖼️", answer: "お絵描きや工作" },
      { emoji: "👚", answer: "ファッション" },
    ],
  },
  {
    question: "空いた時間は何したい？",
    answers: [
      { emoji: "👦", answer: "友だちと遊ぶ" },
      { emoji: "🏠", answer: "おうちでまったり" },
      { emoji: "🧺", answer: "のんびりおでかけ" },
      { emoji: "🧘‍♀️", answer: "好きなことをとことん" },
    ],
  },
  {
    question: "この中で欲しい力は？",
    answers: [
      { emoji: "🫴", answer: "なかまをすくう力" },
      { emoji: "🗣️", answer: "人とはなす力" },
      { emoji: "💪", answer: "つづける力" },
      { emoji: "🏃‍♀️", answer: "すばやくうごく力" },
    ],
  },
  {
    question: "自分はどういう性格だと思う？",
    answers: [
      { emoji: "💪", answer: "パワフル" },
      { emoji: "🆒", answer: "クール" },
      { emoji: "🌿", answer: "リラックス" },
      { emoji: "😍", answer: "ラブリー" },
    ],
  },
  {
    question: "自分を表してる音は何？",
    answers: [
      { emoji: "🌳", answer: "モリモリ" },
      { emoji: "🌨️", answer: "しんしん" },
      { emoji: "🐏", answer: "ほわほわ" },
      { emoji: "🥕", answer: "シャキシャキ" },
    ],
  },
  {
    question: "好きな場所は？",
    answers: [
      { emoji: "🌊", answer: "海" },
      { emoji: "⛰️", answer: "山" },
      { emoji: "🌱", answer: "草原" },
      { emoji: "🌇", answer: "街" },
    ],
  },
  {
    question: "好きな時間帯は？",
    answers: [
      { emoji: "🌅", answer: "朝" },
      { emoji: "🌙", answer: "夜" },
      { emoji: "🌆", answer: "夕方" },
      { emoji: "🌞", answer: "昼" },
    ],
  },
  {
    question: "好きな季節は？",
    answers: [
      { emoji: "🌻", answer: "夏" },
      { emoji: "⛄️", answer: "冬" },
      { emoji: "🌷", answer: "春" },
      { emoji: "🍁", answer: "秋" },
    ],
  },
];

export const characterIdentifyEnquete: CharacterQuestionAndAnswer[] = [
  {
    question: "お金を無限に使えたら何をする？",
    answers: {
      CHOICE_1: [
        { emoji: "🏠", answer: "大きい家をつくる" },
        { emoji: "⚽️", answer: "体育館を作る" },
        { emoji: "🕺", answer: "面白い芸を見る" },
        { emoji: "💊", answer: "健康グッズを買う" },
      ],
      CHOICE_2: [
        { emoji: "🎧", answer: "音楽を聴きまくる" },
        { emoji: "🎁", answer: "家族にプレゼント" },
        { emoji: "📗", answer: "本を作る" },
        { emoji: "🚶", answer: "一人旅に出る" },
      ],
      CHOICE_3: [
        { emoji: "😌", answer: "特に使わない" },
        { emoji: "💰", answer: "みんなにあげる" },
        { emoji: "🎨", answer: "工作しまくる" },
        { emoji: "🪩", answer: "みんなでパーティー" },
      ],
      CHOICE_4: [
        { emoji: "📖", answer: "勉強に使う" },
        { emoji: "😁", answer: "一人で豪遊" },
        { emoji: "👔", answer: "服を買う" },
        { emoji: "🛩️", answer: "旅行に行く" },
      ],
    },
  },

  {
    question: "好きな香りは何？",
    answers: {
      CHOICE_1: [
        { emoji: "😋", answer: "食べ物の香り" },
        { emoji: "🪨", answer: "岩の香り" },
        { emoji: "🌳", answer: "木の香り" },
        { emoji: "📗", answer: "古本の香り" },
      ],
      CHOICE_2: [
        { emoji: "🛏️", answer: "布団の香り" },
        { emoji: "💐", answer: "花の香り" },
        { emoji: "📃", answer: "紙の香り" },
        { emoji: "🖋️", answer: "インクの香り" },
      ],
      CHOICE_3: [
        { emoji: "🌿", answer: "草の香り" },
        { emoji: "⛰️", answer: "土の香り" },
        { emoji: "🎨", answer: "絵の具の香り" },
        { emoji: "🌊", answer: "海の香り" },
      ],
      CHOICE_4: [
        { emoji: "🍏", answer: "フルーツの香り" },
        { emoji: "🌧️", answer: "雨の香り" },
        { emoji: "💄", answer: "化粧品の香り" },
        { emoji: "🪴", answer: "ハーブの香り" },
      ],
    },
  },
];
