import React, { useEffect, useState, VFC } from "react";
import { useNavigate } from "react-router-dom";
import { EditSearchKeywordTemplate } from "~/components/templates/EditSearchKeyword";
import { usePreference } from "~/store/preference/usePreference";

export const EditSearchKeyword: VFC = () => {
  const { preference, savePreference } = usePreference();

  const [searchWord, setSearchWord] = useState(preference?.calilSearchWord);

  useEffect(() => {
    preference && setSearchWord(preference.calilSearchWord);
  }, [preference, setSearchWord]);

  const [openModal, setOpenModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const navigate = useNavigate();

  const handleSubmitSearchWord = (event: React.MouseEvent) => {
    event.preventDefault();
    savePreference({ calilSearchWord: searchWord ?? "" });
    setModalMessage("検索キーワードが設定されました");
    setOpenModal(true);
  };

  const handleChangeSearchWord = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    setSearchWord(event.target.value);
  };

  return (
    <EditSearchKeywordTemplate
      searchWord={searchWord ?? ""}
      onSubmitSearchWord={handleSubmitSearchWord}
      onChangeSearchWord={handleChangeSearchWord}
      handleBack={() => navigate("/mypage?view=profile")}
      openModal={openModal}
      setOpenModal={setOpenModal}
      modalMessage={modalMessage}
    />
  );
};
