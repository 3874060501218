import React from "react";
import styled from "styled-components";
import { ProgressBar } from "~/components/atoms/ProgressBar";
import { Stack } from "~/components/atoms/layout/Stack";
import { Text } from "~/components/atoms/texts/Text";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";

type ExperienceSummaryProps = {
  isTopView?: boolean;
};

export const ExperienceSummary: React.FC<ExperienceSummaryProps> = ({
  isTopView = false,
}) => {
  const { userExperience, currentUser } = React.useContext(CurrentUserContext);

  const days = React.useMemo(() => {
    const createDate = new Date(currentUser?.createdAt);
    const createDateStart = new Date(
      createDate.getFullYear(),
      createDate.getMonth(),
      createDate.getDate()
    );
    const today = new Date();
    const todayStart: Date = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );

    return (
      Math.floor(
        (todayStart.getTime() - createDateStart.getTime()) /
          (1000 * 60 * 60 * 24)
      ) + 1
    );
  }, [currentUser]);

  if (!userExperience || !currentUser) {
    return null;
  }

  return (
    <Stack rowGap="16px">
      {!isTopView && (
        <Text fontColor="tex.t300" fontSize="XS" lineHeight="EQ">
          ヨンデミーをはじめて{days}にち
        </Text>
      )}
      <_UserExperienceWrapper>
        <_LevelWrapper>
          <Text fontSize="XXS" lineHeight="EQ" fontColor="base.white" bold>
            レベル
          </Text>
          <Text fontSize="XXXXL" lineHeight="EQ" fontColor="base.white" bold>
            {userExperience.isMaxLevel ? "MAX" : userExperience.userLevel}
          </Text>
        </_LevelWrapper>
        <_ExpWrapper>
          <_ExpTextWrapper>
            <Text fontSize="XXS" lineHeight="EQ" fontColor="tex.t200" bold>
              けいけんち
            </Text>
            <img src="/img/icon/candy.svg" />
            <Text fontSize="XL" lineHeight="EQ" fontColor="sky.s400" bold>
              {userExperience.expAfterLastLevelUp}
            </Text>
          </_ExpTextWrapper>
          <_ExpBarWrapper>
            <Text fontSize="XXS" lineHeight="EQ" fontColor="tex.t400">
              次のレベルまで あと{userExperience.expToNextLevelUp}
            </Text>
            <ProgressBar
              progress={userExperience.isMaxLevel ? 0 : userExperience.progress}
              progressColor="sky.s400"
              backgroundColor="base.lightGray"
            />
          </_ExpBarWrapper>
        </_ExpWrapper>
      </_UserExperienceWrapper>
    </Stack>
  );
};

const _UserExperienceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 16px;
`;

const _LevelWrapper = styled.div`
  min-width: 48px;
  background-color: ${({ theme }) => theme.colors.sky.s400};
  border-radius: 4px;
  padding: 4px 6px;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  justify-content: center;
  align-items: center;
`;

const _ExpWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 8px;
`;

const _ExpTextWrapper = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
`;

const _ExpBarWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  align-items: flex-start;
`;
