import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Text } from "~/components/atoms/texts/Text";
import { BaseModal } from "~/components/atoms/wrappers/BaseModal";
import { TranslateKeys } from "~/i18n";
import { GetStarEnergyModalLockWrapper } from "../../Unlock/LockWrapper/getStarEnergyModalLockWrapper";

export type GetStarEnergyModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const GetStarEnergyModal: React.FC<GetStarEnergyModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();
  return (
    <GetStarEnergyModalLockWrapper>
      <BaseModal open={isOpen} onClose={onClose}>
        <_Wrapper>
          <Text fontColor="semantic.text.body" fontSize="MD" lineHeight="MD">
            {t<TranslateKeys>("感想") + "の" + t<TranslateKeys>("力") + "で"}
            <br />
            {"スターエナジーが" + t<TranslateKeys>("満ちた") + "！"}
          </Text>
          <img src="/img/icon/rainbow_blur_star.svg" width={96} />
          <Text fontColor="semantic.text.body" fontSize="MD" lineHeight="MD">
            {"みんなにスターを" + t<TranslateKeys>("届けよう")}
          </Text>
        </_Wrapper>
      </BaseModal>
    </GetStarEnergyModalLockWrapper>
  );
};

const _Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;
