import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CharacterEnquete } from "~/components/organisms/BookSelectionAdjustment/CharacterAssessment/Enquete";
import { ThanksForAnswering } from "~/components/organisms/BookSelectionAdjustment/CharacterAssessment/ThanksForAnswering";
import { Welcome } from "~/components/organisms/BookSelectionAdjustment/CharacterAssessment/Welcome";
import { EnqueteQuitModal } from "~/components/organisms/BookSelectionAdjustment/ui/EnqueteQuitModal";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import { getRandomQuestions } from "./getRandomQuestions";
import {
  AnswerCharacterEnqueteInput,
  OkState,
  useAnswerCharacterEnqueteMutation,
  useGetSuitableYlResultQuery,
} from "~/generated/graphql";
import { LoadingPage } from "~/components/templates/Loading/LoadingPage";
import { ErrorToast } from "~/components/organisms/toasts/ErrorToast";
import { SuitableYlNotFound } from "~/components/organisms/BookSelectionAdjustment/ui/SuitableYlNotFound";
import { useTranslation } from "~/i18n";

type Flow = "Welcome" | "CharacterEnquete" | "ThanksForAnswering";

export const CharacterAssessmentEnquete: React.FC = () => {
  const navigate = useNavigate();

  const { currentUser } = useContext(CurrentUserContext);
  const nickname = currentUser?.general.nickname || "";

  const [flow, setFlow] = React.useState<Flow>("Welcome");
  const [isLastIndex, setIsLastIndex] = React.useState<boolean>(false);
  const [isOpenQuitModal, setIsOpenQuitModal] = React.useState<boolean>(false);
  const [isOpenErrorToast, setIsOpenErrorToast] =
    React.useState<boolean>(false);
  const { t } = useTranslation();

  const typeQuestionAndAnswers = getRandomQuestions();

  const {
    data: queryData,
    loading: queryLoading,
    error: queryError,
  } = useGetSuitableYlResultQuery({
    fetchPolicy: "network-only",
  });

  const [answerCharacterEnqueteMutation, { loading: mutationLoading }] =
    useAnswerCharacterEnqueteMutation();

  const content: React.ReactNode = React.useMemo(() => {
    const handleSubmit = (
      input: AnswerCharacterEnqueteInput
    ): Promise<boolean> => {
      return answerCharacterEnqueteMutation({
        variables: { input },
      })
        .then((res) => {
          if (res.data?.answerCharacterEnquete.state === OkState.Success) {
            try {
              sessionStorage.setItem("characterType", input.characterType);
            } catch (e) {
              console.error(e);
            }
            setFlow("ThanksForAnswering");
            return true;
          } else {
            setIsOpenErrorToast(true);
            return false;
          }
        })
        .catch(() => {
          setIsOpenErrorToast(true);
          return false;
        });
    };

    return (
      <>
        {flow === "Welcome" && (
          <Welcome
            onNext={() => setFlow("CharacterEnquete")}
            onQuit={() => setIsOpenQuitModal(true)}
            nickname={nickname}
            isLastIndex={isLastIndex}
            setIsLastIndex={setIsLastIndex}
          />
        )}
        {flow === "CharacterEnquete" && (
          <CharacterEnquete
            typeQuestionAndAnswers={typeQuestionAndAnswers}
            onSubmit={handleSubmit}
            onQuit={() => setIsOpenQuitModal(true)}
            onBack={() => (setIsLastIndex(true), setFlow("Welcome"))}
            loading={mutationLoading}
          />
        )}
        {flow === "ThanksForAnswering" && (
          <ThanksForAnswering
            onNext={() => navigate("/character_assessment/result")}
            nickname={nickname}
            onQuit={() => setIsOpenQuitModal(true)}
          />
        )}
      </>
    );
  }, [
    flow,
    navigate,
    nickname,
    isLastIndex,
    setIsLastIndex,
    setIsOpenQuitModal,
    typeQuestionAndAnswers,
    answerCharacterEnqueteMutation,
    mutationLoading,
  ]);

  if (queryLoading) {
    return <LoadingPage />;
  }

  if (queryError) {
    return <>Error! {queryError.message}</>;
  }

  if (!queryData || !currentUser) {
    return null;
  }

  if (queryData?.getSuitableYL.__typename === "ErrorSuitableYLNotFound") {
    return (
      <SuitableYlNotFound
        enqueteType="character_assessment"
        onQuit={() => navigate("/")}
      />
    );
  }

  return (
    <>
      <ErrorToast
        text={t<TranslateKeys>("もう一度ボタンを押してください。")}
        isOpen={isOpenErrorToast}
        onClose={() => setIsOpenErrorToast(false)}
      />
      <EnqueteQuitModal
        enqueteType="character_assessment"
        isOpen={isOpenQuitModal}
        onQuitEnquete={() => navigate("/")}
        onResume={() => setIsOpenQuitModal(false)}
      />
      {content}
    </>
  );
};
