import React, {
  useEffect,
  useState,
  useRef,
  MouseEvent,
  TouchEvent,
} from "react";
import styled from "styled-components";
import { SectionTitle } from "~/components/molecules/texts/SectionTitle";
import { Text } from "~/components/atoms/texts/Text";
import { CarouselDots } from "~/components/atoms/CarouselDots";
import { BadgeItem } from "~/helpers/constants/badge";

export type BadgesSquareCollectionProps = {
  /**
   * バッジの見出し
   */
  title: string;
  /**
   * バッジの説明
   */
  about: string;
  /**
   * バッジのデータ
   */
  badges: BadgeItem[];
  /**
   * 一辺に表示するバッジの個数
   */
  sideCount?: number;
  /**
   * 表示する画像のwidth
   */
  imageWidth?: number;
  /**
   * class名
   */
  className?: string;
};

export const BadgesSquareCollection: React.VFC<BadgesSquareCollectionProps> = ({
  title,
  about,
  badges,
  sideCount = 2,
  imageWidth = 90,
  className,
}) => {
  const perPage = sideCount * sideCount;
  const ARROW_WIDTH = 30;
  const IMAGE_MARGIN = 5;
  const [currentPage, setCurrentPage] = useState(0);
  const carouselRef = useRef<HTMLDivElement>(null);
  const touchStartX = useRef(0);
  const touchEndX = useRef(0);

  useEffect(() => {
    /**
     * 取得できているバッチのページをデフォルトで表示
     */
    const lastBadgeCount = badges.reduce((result, badge, index) => {
      return badge.isAcquired ? index : result;
    }, 0);
    setCurrentPage(Math.floor(lastBadgeCount / perPage));
  }, [badges, perPage]);

  const pageTotal = Math.floor((badges.length + perPage - 1) / perPage);
  const totalItemCount = pageTotal * perPage;
  const leftItemCount = totalItemCount - badges.length;
  /**
   * 最終ページperPageより数が少ない場合レイアウトが崩れるため、dummyのundefinedの配列を追加
   */
  const badgeWithDummy = [...badges, ...new Array(leftItemCount)];

  const pages: {
    [page: number]: (BadgeItem | undefined)[];
  } = badgeWithDummy.reduce((result, badge, index) => {
    const page = Math.floor(index / perPage);
    result[page] ? result[page].push(badge) : (result[page] = [badge]);
    return result;
  }, {});

  //ページ切替動作
  const handleGoForward = (event?: MouseEvent) => {
    event?.preventDefault();
    setCurrentPage((currentPage + 1) % pageTotal);
  };
  const handleGoBack = (event?: MouseEvent) => {
    event?.preventDefault();
    const surplus = (currentPage - 1) % pageTotal;
    if (surplus >= 0) {
      setCurrentPage(surplus);
      return;
    }
    setCurrentPage(surplus + pageTotal);
  };

  const handleTouchStart = (event: TouchEvent) => {
    touchStartX.current = event.changedTouches[0].screenX;
  };

  const handleTouchEnd = (event: TouchEvent) => {
    touchEndX.current = event.changedTouches[0].screenX;
    handleSwipe();
  };

  const handleSwipe = () => {
    if (touchStartX.current - touchEndX.current > 50) {
      handleGoForward();
    }

    if (touchStartX.current - touchEndX.current < -50) {
      handleGoBack();
    }
  };

  return (
    <Container className={className}>
      <Title>
        <SectionTitle>{title}</SectionTitle>
        <AboutText fontSize={"SM"} lineHeight={"MD"}>
          {about}
        </AboutText>
      </Title>
      <MainContainer>
        <Main>
          <Button onClick={handleGoBack} width={ARROW_WIDTH}>
            <GoBackButton />
          </Button>
          <BadgeWrapper
            ref={carouselRef}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
          >
            {pages[currentPage].map((badge, index) => {
              return (
                <ImgContainer key={index} margin={IMAGE_MARGIN}>
                  <Img
                    width={imageWidth}
                    src={badge?.imgSrc || "/img/badge/empty.png"}
                    isAcquired={badge?.isAcquired || false}
                  />
                </ImgContainer>
              );
            })}
          </BadgeWrapper>
          <Button onClick={handleGoForward} width={ARROW_WIDTH}>
            <GoForwardButton />
          </Button>
        </Main>
        <Bottom>
          <CarouselDots pageTotal={pageTotal} nowPageIndex={currentPage} />
        </Bottom>
      </MainContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const Title = styled.div`
  margin: 0 22px 10px 0;
`;

const MainContainer = styled.div`
  max-width: 280px;
  margin: 0 auto;
`;

const Main = styled.div`
  display: flex;
  align-items: center;
`;

const Bottom = styled.div`
  margin: 10px auto;
  display: flex;
  justify-content: center;
`;

const BadgeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const ImgContainer = styled.div<{ margin: number }>`
  margin: ${(props) => `${props.margin}px`};
`;

type BadgeImg = { isAcquired: boolean; width: number };

const Img = styled.img<BadgeImg>`
  width: ${(props) => `${props.width}px`};
  /* 獲得したかどうかをスタイルに反映 */
  opacity: ${(props) => (props.isAcquired ? 1 : 0.6)};
`;

const Button = styled.button<{ width: number }>`
  width: ${(props) => `${props.width}px`};
  background: none;
  border: none;
  padding: 0;
  margin: 0 5px;
`;

const GoBackButton = styled.div`
  cursor: pointer;
  border-top: 20px solid transparent;
  border-right: 20px solid ${(props) => props.theme.paleOrange};
  border-bottom: 20px solid transparent;
  &:active {
    border-right: 20px solid ${(props) => props.theme.orange};
  }
`;

const GoForwardButton = styled.div`
  cursor: pointer;
  border-top: 20px solid transparent;
  border-left: 20px solid ${(props) => props.theme.paleOrange};
  border-bottom: 20px solid transparent;
  &:active {
    border-left: 20px solid ${(props) => props.theme.orange};
  }
`;

const AboutText = styled(Text)`
  color: ${({ theme }) => theme.colors.tex.t500};
`;
