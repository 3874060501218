import React from "react";
import { useNavigate } from "react-router-dom";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import {
  TutorialFlagKey,
  useGetFirstBookRecommendsQuery,
} from "~/generated/graphql";
import { TutorialFlow } from "./Tutorial";
import { WaitBookAnim } from "../../components/organisms/modals/TutorialInitializeModal/WaitBookAnim";
import { BookSelectionError } from "~/components/templates/BookSelectionError";
import { config } from "../../../config";
import { useAudio } from "~/utils/hooks/useAudio";
import { usePreference } from "~/store/preference/usePreference";
import { ImagePreload } from "./imagePreload";
import { allImages } from "./constants";
import { CoverDiagnosis } from "./CoverDiagnosis";
import {
  useGetPostPreBookRecommendLogQuery,
  useAnswerPreBookRecommendEnqueteMutation,
  PreBookRecommendEnqueteInput,
} from "~/generated/graphql";

type Flow =
  | "waitBook"
  | "selectionError"
  | "coverDiagnosis"
  | "doneCoverDiagnosis"
  | "tutorial";

export const InitialTutorial: React.FC = () => {
  const [flow, setFlow] = React.useState<Flow>("waitBook");
  const navigate = useNavigate();
  useAudio({
    preload: ["light", "initializeAudio"],
  });
  const { data: preBookRecommendLogData } = useGetPostPreBookRecommendLogQuery({
    pollInterval: flow === "waitBook" || flow === "selectionError" ? 5000 : 0,
    fetchPolicy: "network-only",
  });

  const { data: firstBookRecommendData, refetch: refetch } =
    useGetFirstBookRecommendsQuery({
      variables: {
        begin: 0,
        end: config.LIST_LENGTH,
      },
      fetchPolicy: "network-only",
    });

  const [answerPreBookRecommendEnquete, { loading }] =
    useAnswerPreBookRecommendEnqueteMutation();

  const { saveTutorialFlags } = React.useContext(CurrentUserContext);
  const { savePreference } = usePreference();

  React.useEffect(() => {
    if (
      firstBookRecommendData?.getFirstBookRecommends &&
      firstBookRecommendData.getFirstBookRecommends.length > 0
    ) {
      setFlow("tutorial");
    } else if (
      preBookRecommendLogData?.getPostPreBookRecommendLog &&
      preBookRecommendLogData.getPostPreBookRecommendLog.preBookRecommends
        .length > 0
    ) {
      setFlow("coverDiagnosis");
    } else {
      const TimeoutID = setTimeout(() => {
        setFlow("selectionError");
      }, 30000);

      return () => clearTimeout(TimeoutID);
    }
  }, [preBookRecommendLogData, firstBookRecommendData]);

  const onClickToMinilesson = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      saveTutorialFlags(TutorialFlagKey.FirstLogin);
      navigate("/message");
    },
    [saveTutorialFlags, navigate]
  );

  const onConfirmAllRecommend = React.useCallback(() => {
    if (
      firstBookRecommendData &&
      firstBookRecommendData.getFirstBookRecommends.length > 0
    ) {
      const ids = firstBookRecommendData.getFirstBookRecommends.map(
        (recommend) => recommend.id
      );
      const latestRecommendId = Math.max(...ids);
      savePreference({
        recommendListLatestRecommendId: latestRecommendId,
      });
    }
  }, [firstBookRecommendData, savePreference]);

  const handlePostPreBookRecommendLog = async (
    preBookRecommendEnquetes: PreBookRecommendEnqueteInput[]
  ) => {
    try {
      if (
        preBookRecommendLogData === undefined ||
        preBookRecommendLogData.getPostPreBookRecommendLog === undefined
      ) {
        return <>errorです</>;
      }

      await answerPreBookRecommendEnquete({
        variables: {
          input: {
            postPreBookRecommendLogID:
              preBookRecommendLogData.getPostPreBookRecommendLog.id,
            preBookRecommendEnquetes: preBookRecommendEnquetes,
          },
        },
      });
      setFlow("doneCoverDiagnosis");
      refetch();
    } catch (error) {
      console.error("Error submitting survey:", error);
    }
  };

  const [allImagesLoaded, setAllImagesLoaded] = React.useState<boolean>(false);

  if (!allImagesLoaded) {
    return (
      <ImagePreload
        images={[...Object.values(allImages)]}
        onCompleted={() => setAllImagesLoaded(true)}
      />
    );
  }

  return (
    <>
      {flow === "waitBook" && <WaitBookAnim />}
      {flow === "selectionError" && <BookSelectionError />}
      {flow === "tutorial" && (
        <TutorialFlow
          recommends={firstBookRecommendData?.getFirstBookRecommends || []}
          onClickToMinilesson={onClickToMinilesson}
          onConfirmAllRecommend={onConfirmAllRecommend}
        />
      )}
      {flow === "coverDiagnosis" && (
        <CoverDiagnosis
          postPreBookRecommendLog={
            preBookRecommendLogData?.getPostPreBookRecommendLog
          }
          onPostPreBookRecommendLog={handlePostPreBookRecommendLog}
          loading={loading}
        />
      )}
    </>
  );
};
