import React from "react";
import { usePostReadLogState } from "../usePostReadLogState";
import { UnlockModalController } from "~/components/organisms/Unlock/UnlockModalController";

export const IndicateUnlock: React.FC = () => {
  const { dispatch } = usePostReadLogState();

  const handleNext = () => {
    dispatch({ type: "setFlow", payload: "indicatePraiseReadingStreak" });
  };

  return <UnlockModalController handleNext={handleNext} />;
};
