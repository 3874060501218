import styled from "styled-components";
import { FormControl, FormControlProps } from "react-bootstrap";

export type SliderProps = FormControlProps & {
  min: number;
  max: number;
  step: number;
};

export const Slider = styled(FormControl)`
  -webkit-appearance: none;
  appearance: none;
  background-color: ${({ theme }) => theme.colors.base.gray};
  height: 12px;
  width: 100%;
  border-radius: 6px;
  &:focus,
  &:active {
    outline: none;
  }
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    position: relative;
    width: 32px;
    height: 32px;
    display: block;
    background-color: ${({ theme }) => theme.colors.pri.p400};
    border-radius: 50%;
    -webkit-border-radius: 50%;
  }
`;
