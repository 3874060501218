import React from "react";
import { Recommend, useGetNowRecommendLazyQuery } from "~/generated/graphql";
import { SelectBookTips } from ".";

type SelectBookTipsContainerProps = {
  recommendList: Pick<Recommend, "id" | "book">[];
  count: number;
  onNext: () => void;
};

const NEXT_BOOK_LIST_LENGTH = 4;

export const SelectBookTipsContainer: React.FC<
  SelectBookTipsContainerProps
> = ({ recommendList: _recommendList, onNext, count }) => {
  const [begin, setBegin] = React.useState<number>(0);
  const [recommendList, setRecommendList] =
    React.useState<Pick<Recommend, "id" | "book">[]>(_recommendList);

  const [getNowRecommend, { loading }] = useGetNowRecommendLazyQuery({
    onCompleted: (data) => {
      setRecommendList((recommendList) => [
        ...recommendList,
        ...data.me.recommends.get,
      ]);
    },
  });

  return (
    <SelectBookTips
      selectNextBookImgListProps={{
        data: recommendList.slice(
          begin,
          begin === 0
            ? NEXT_BOOK_LIST_LENGTH - 1
            : Math.min(begin + NEXT_BOOK_LIST_LENGTH, count)
        ),
        onClickOtherBooks: onNext,
        hasOtherBook: begin === 0,
        loading: loading,
        hasNext:
          (count > NEXT_BOOK_LIST_LENGTH - 1 && begin === 0) ||
          count > begin + NEXT_BOOK_LIST_LENGTH,
        hasPrev: begin > 0,
        onChangePageToPrev: () =>
          setBegin((b) =>
            b === NEXT_BOOK_LIST_LENGTH - 1 ? 0 : b - NEXT_BOOK_LIST_LENGTH
          ),
        onChangePageToNext: () => {
          if (begin + NEXT_BOOK_LIST_LENGTH * 2 >= recommendList.length) {
            getNowRecommend({
              variables: {
                begin: recommendList.length,
                end: recommendList.length + NEXT_BOOK_LIST_LENGTH,
              },
            });
          }
          setBegin((b) =>
            b === 0 ? NEXT_BOOK_LIST_LENGTH - 1 : b + NEXT_BOOK_LIST_LENGTH
          );
        },
        renderEmptyComponent: EmptyComponent,
      }}
      onNext={onNext}
    />
  );
};

const EmptyComponent = (): JSX.Element => {
  return <></>;
};
