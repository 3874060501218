import * as React from "react";
import { EnterIsbn } from "~/components/templates/PostReadLog/EnterUnregisteredBookIsbn/EnterIsbn";
import {
  checkDegit,
  normalizeIsbn,
} from "~/components/templates/PostReadLog/EnterUnregisteredBookIsbn/EnterIsbn/hook";
import { usePostReadLogState } from "../usePostReadLogState";
import { SelectEnterMethod } from "~/components/templates/PostReadLog/EnterUnregisteredBookIsbn/SelectEnterMethod";
import { BarcodeScannerModal } from "~/components/templates/PostReadLog/EnterUnregisteredBookIsbn/BarcodeScannerModal";
import { useGetBookByIsbnMutation } from "~/generated/graphql";
import { ConfirmEnterBook } from "~/components/templates/PostReadLog/EnterUnregisteredBookIsbn/ConfirmEnterBook";

type Flow = "selectEnterMethod" | "enterIsbn" | "confirmEnterBook";

export const EnterUnregisteredBookIsbn: React.FC = () => {
  const [flow, setFlow] = React.useState<Flow>("selectEnterMethod");
  const [isOpenBarcodeScannerModal, setIsOpenBarcodeScannerModal] =
    React.useState<boolean>(false);
  const [errorMessageIsbn, setErrorMessageIsbn] = React.useState("");
  const [errorMessageTitle, setErrorMessageTitle] = React.useState("");
  const [getBookByIsbn, { loading }] = useGetBookByIsbnMutation({});
  const [imgUrl, setImgUrl] = React.useState<string>("");
  const [runMutation, setRunMutation] = React.useState<boolean>(false);
  const [isBarcodeScannerError, setIsBarcodeScannerError] =
    React.useState<boolean>(false);

  const {
    state: { bookISBN, title },
    dispatch,
  } = usePostReadLogState();

  const handleBack = (): void => {
    dispatch({
      type: "setFlow",
      payload: "selectBook",
    });
  };

  const setBookISBN = (isbn: string) => {
    if (errorMessageIsbn) {
      //エラーをクリアする
      setErrorMessageIsbn("");
    }
    dispatch({
      type: "setBookISBN",
      payload: isbn,
    });
  };

  const setTitle = (title: string) => {
    if (errorMessageTitle) {
      //エラーをクリアする
      setErrorMessageTitle("");
    }
    dispatch({
      type: "setTitle",
      payload: title,
    });
  };

  const onBarcodeScannerError = () => {
    setIsBarcodeScannerError(true);
  };

  const onDetected = (isbn: string) => {
    if (checkDegit(normalizeIsbn(isbn)) === "") {
      return false;
    }
    setBookISBN(isbn);
    if (runMutation) {
      return true;
    }
    setRunMutation(true);
    return true;
  };

  React.useEffect(() => {
    if (runMutation && bookISBN !== "") {
      getBookByIsbn({ variables: { isbn: bookISBN } })
        .then((res) => {
          setTitle(res.data?.searchBookWithPaapi.title || "");
          setImgUrl(res.data?.searchBookWithPaapi.imageUrl || "");
          setFlow("confirmEnterBook");
          setIsOpenBarcodeScannerModal(false);
        })
        .catch(() => {
          setErrorMessageIsbn(
            "本のじょうほうがなかったよ！自分でタイトルを入力してね！"
          );
          setFlow("enterIsbn");
          setIsOpenBarcodeScannerModal(false);
        });
    }
  }, [runMutation]);

  const handleNext = () => {
    dispatch({
      type: "setRecommendID",
      payload: null,
    });

    dispatch({
      type: "setQuestionNumber",
      payload: 0,
    });
    dispatch({
      type: "setFlow",
      payload: "confirmReadStatus",
    });
  };

  return (
    <>
      {flow === "selectEnterMethod" && (
        <>
          <SelectEnterMethod
            onClickScan={() => {
              setIsOpenBarcodeScannerModal(true);
              setRunMutation(false);
            }}
            onClickManual={() => setFlow("enterIsbn")}
            onBack={handleBack}
          />
          {isOpenBarcodeScannerModal && (
            <BarcodeScannerModal
              isOpen={isOpenBarcodeScannerModal}
              onQuit={() => setIsOpenBarcodeScannerModal(false)}
              onError={onBarcodeScannerError}
              isError={isBarcodeScannerError}
              onDetected={onDetected}
              loading={loading}
            />
          )}
        </>
      )}
      {flow === "enterIsbn" && (
        <EnterIsbn
          handleBack={() => setFlow("selectEnterMethod")}
          onClickGoNext={() => {
            const tmp = checkDegit(normalizeIsbn(bookISBN));
            if (tmp === "") {
              setErrorMessageIsbn(
                "正しいISBNを入力してね！　本のうらがわに書いてある、13ケタの数字だよ！"
              );
              return;
            }
            if (title === "") {
              setErrorMessageTitle("タイトルを入力してね！");
              return;
            }
            dispatch({
              type: "setBookISBN",
              payload: tmp,
            });
            setFlow("confirmEnterBook");
          }}
          bookISBN={bookISBN}
          title={title}
          setBookISBN={setBookISBN}
          setTitle={setTitle}
          errorMessageIsbn={errorMessageIsbn}
          setErrorMessageIsbn={setErrorMessageIsbn}
          errorMessageTitle={errorMessageTitle}
          setErrorMessageTitle={setErrorMessageTitle}
          disabled={Boolean(errorMessageIsbn || errorMessageTitle)}
          getBookByIsbn={(isbn: string) =>
            new Promise((resolve) => {
              getBookByIsbn({ variables: { isbn } })
                .then((result) => {
                  setBookISBN(isbn);
                  setTitle(result.data?.searchBookWithPaapi.title || "");
                  setImgUrl(result.data?.searchBookWithPaapi.imageUrl || "");
                  setFlow("confirmEnterBook");
                  resolve(true);
                })
                .catch(() => {
                  setErrorMessageIsbn(
                    "本のじょうほうがなかったよ！自分でタイトルを入力してね！"
                  );
                  resolve(false);
                });
            })
          }
          loadingBookByIsbn={loading}
        />
      )}
      {flow === "confirmEnterBook" && (
        <ConfirmEnterBook
          title={title}
          imgUrl={imgUrl}
          handleBack={() => setFlow("enterIsbn")}
          handleNext={handleNext}
        />
      )}
    </>
  );
};
