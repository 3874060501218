import React, { useEffect } from "react";
import styled from "styled-components";
import { Text } from "~/components/atoms/texts/Text";
import { useTranslation } from "~/i18n";

const senseiMessageUrl = "/img/sensei/want_read_book_message.svg";

export type SenseiMessageProps = {
  readLogId: number;
  translationParams: TranslationParams;
  onCompleted: () => void;
};

type TranslationParams = {
  user: string;
};

export const SenseiMessage: React.FC<SenseiMessageProps> = ({
  readLogId,
  translationParams,
  onCompleted,
}) => {
  const { t } = useTranslation();
  const randomMessage = _SenseiMessages[readLogId % _SenseiMessages.length];

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      onCompleted();
    }, 5000);

    return () => {
      onCompleted();
      clearTimeout(timeOutId);
    };
  }, [onCompleted]);

  return (
    <_Wrapper>
      <_SenseiImg src={senseiMessageUrl} />
      <Text fontSize="XS" lineHeight="MD">
        {t(randomMessage, translationParams)}
      </Text>
    </_Wrapper>
  );
};

const _Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.base.white};
  border: 1px solid ${({ theme }) => theme.colors.base.lightGray};
  border-radius: 16px;
  width: 218px;

  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  opacity: 0;

  animation: 5s fadeUpAnime 0s ease-in-out;

  @keyframes fadeUpAnime {
    from {
      transform: translateY(10px);
    }
    10% {
      opacity: 1;
      transform: translateY(0);
    }
    90% {
      opacity: 1;
      transform: translateY(0);
    }

    to {
      opacity: 0;
      transform: translateY(-10px);
    }
  }
`;

const _SenseiImg = styled.img`
  height: 100%;
  object-fit: contain;
`;

const _SenseiMessages: string[] = [
  "読みたい 本を 自分で 見つけられて すごいね！",
  "この本も {{ user }}さんに 選んでもらえて うれしそう🥰",
  "とっても 面白そうな 本！ 気になるなぁ👀",
  "どんな 本 なんだろう？読んでみたいな〜！",
  "すてきな 本だね！感想が 楽しみ😆✨",
  "この 本のこと、ヨンデミー先生にも 教えてね🤗",
];
