type EventPayload =
  | {
      event: "login_page_opened";
    }
  | {
      event: "login_completed";
      payload: {
        duration_ms: number;
      };
    };

const assertCase = (_: never) => {
  return;
};

const eventPayloadToParams = (
  payload: EventPayload
): Record<string, unknown> => {
  switch (payload.event) {
    case "login_page_opened":
      return {
        event_category: "authentication",
      };
    case "login_completed":
      return {
        event_category: "authentication",
        duration_ms: payload.payload.duration_ms,
      };
    default: {
      assertCase(payload);
      return {};
    }
  }
};

export const trackEvent = (payload: EventPayload): void => {
  if (typeof window === "undefined" || !window.gtag) return;

  const params = eventPayloadToParams(payload);

  if (process.env.NODE_ENV === "development") {
    console.log(`[GA] event: ${payload.event}`, params);
  }

  window.gtag("event", payload.event, params);
};
