import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "~/i18n";
import { GetOneReadlogQuery } from "~/generated/graphql";
import { Text } from "~/components/atoms/texts/Text";
import { TextArea as BaseTextArea } from "~/components/atoms/forms/TextArea";
import { PrimaryButton } from "~/components/molecules/buttons/PrimaryButton";

export type EditReadLogFormProps = {
  data: GetOneReadlogQuery;
  onSubmit: (review: string) => void;
};

export const EditReadLogForm: React.VFC<EditReadLogFormProps> = ({
  data,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const [review, setReview] = useState(data.me.readLog.get[0].review ?? "");

  const onChangeReview = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setReview(event.target?.value);
  };

  return (
    <Container>
      <TitleContainer>
        <Text fontSize="LG" lineHeight="MD" bold={true}>
          {t<TranslateKeys>(
            "すでに ヨンデミー先生に 提出した 感想を 書き直すことが できます！"
          )}
        </Text>
      </TitleContainer>
      <div>
        <InputLabel fontSize="SM" lineHeight="MD">
          {t<TranslateKeys>("感想")}
        </InputLabel>
        <TextArea value={review} onChange={onChangeReview} />
      </div>
      <SubmitButton
        fontSize="LG"
        lineHeight="MD"
        bold
        color="pri"
        disabled={false}
        text={t<TranslateKeys>("これでOK")}
        onClick={() => onSubmit(review)}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.size.XL};
`;

const TitleContainer = styled.div`
  margin: 50px 0 0 0;
`;

const InputLabel = styled(Text)`
  margin-bottom: 5px;
`;

const SubmitButton = styled(PrimaryButton)`
  padding: 4px 0;
`;

const TextArea = styled(BaseTextArea)`
  height: 120px;
`;
