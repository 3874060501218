import * as React from "react";
import { Virtuoso } from "react-virtuoso";
import styled, { useTheme } from "styled-components";
import { IconButton } from "~/components/molecules/buttons/IconButton";
import { ReactionItem } from "./ReactionItem";
import { BfReadLogStarNoticeItem, ReadLogReactionNoticeItem } from "./types";
import ReactModal from "react-modal";
import { theme } from "~/styles/theme";
import { useTranslation } from "~/i18n";
import { ReceiveStarModal } from "./ReceiveStarModal";
import { ReceiveStarModalLockWrapper } from "../Unlock/LockWrapper/receiveStarModalLockWrapper";
import { LargeButton } from "~/components/atoms/buttons/Button";
import { Text } from "~/components/atoms/texts/Text";
import { Stack } from "~/components/atoms/layout/Stack";
import { useNavigate } from "react-router-dom";
import { sendButtonClickEvent } from "~/utils/googleAnalytics/useButtonClickTracking";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import { useUnlock } from "~/store/unlock/useUnlock";

const headerHeight = "50px";
const modalHeight = CSS.supports("height", "80dvh")
  ? "calc(80dvh - 74px)"
  : "calc(80vh - 74px)"; // 74pxはボタンの高さ50pxとgap24pxを足した値

type Props = {
  open?: boolean;
  onClose: () => void;
  items: ReadLogReactionNoticeItem[];
  onEndReached?: () => void;

  unreadBorderTime?: Date;
  hasUnread: boolean;
  openReceiveStarModal: boolean;
  onCloseReceiveStarModal: () => void;
};

const groupItems = (
  items: ReadLogReactionNoticeItem[],
  isLocked: (key: string) => boolean
) => {
  const groupedItems: ReadLogReactionNoticeItem[][] = [];
  const groupMap = new Map<string, ReadLogReactionNoticeItem[]>();
  const keyOrder: string[] = [];

  items
    .filter(
      (item) =>
        !(isLocked("bfStar") && item.__typename === "BfReadLogStarNoticeItem")
    )
    .forEach((item, index) => {
      const dateKey = new Date(item.createdAt).toISOString().split("T")[0];
      const key =
        item.__typename === "BfReadLogReactionNoticeItem"
          ? `${dateKey}-${item.readLog.id}-${item.reactionType}`
          : `unique-${dateKey}-${index}`;

      if (!groupMap.has(key)) {
        groupMap.set(key, []);
        keyOrder.push(key);
      }

      groupMap.get(key)?.push(item);
    });

  keyOrder.forEach((key) => {
    const group = groupMap.get(key);
    if (group) {
      groupedItems.push(group);
    }
  });

  return groupedItems;
};

export const ReactionsModal: React.FC<Props> = ({
  open = false,
  onClose,
  items,
  onEndReached,
  unreadBorderTime,
  hasUnread,
  openReceiveStarModal,
  onCloseReceiveStarModal,
}) => {
  const { colors } = useTheme();
  const { currentUser } = React.useContext(CurrentUserContext);
  const userId = currentUser?.general.id;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isLocked } = useUnlock();

  const unreadStarItems = items
    .filter(
      (item) =>
        item.__typename === "BfReadLogStarNoticeItem" &&
        (!unreadBorderTime ? true : unreadBorderTime < new Date(item.createdAt))
    )
    .map((item) => item as BfReadLogStarNoticeItem);

  const groupedItems = groupItems(items, isLocked);

  return (
    <>
      <ReceiveStarModalLockWrapper>
        <ReceiveStarModal
          isOpen={openReceiveStarModal}
          items={unreadStarItems}
          onClickCloseModal={onCloseReceiveStarModal}
        />
      </ReceiveStarModalLockWrapper>
      <ReactModal
        className="reactions-modal"
        isOpen={open}
        onRequestClose={onClose}
        style={{
          overlay: {
            zIndex: theme.zIndex.modal,
          },
          content: {
            position: "absolute",
            boxSizing: "border-box",
            top: "50vh",
            left: "50vw",
            transform: "translate(-50%, -50%)",
            width: "50vw",
            minWidth: "320px",
            backgroundColor: "white",
            height: modalHeight,
            borderRadius: "16px",
            border: `solid ${colors.base.lightGray}`,
          },
        }}
      >
        <Stack rowGap="24px">
          <div>
            <_Header>
              リアクション
              <_RightHeaderWrapper>
                <IconButton
                  name="cancel"
                  onClick={onClose}
                  fontSize="MD"
                  color="gray"
                />
              </_RightHeaderWrapper>
            </_Header>
            <_ListWrapper>
              <Virtuoso
                components={{
                  EmptyPlaceholder: NoResponses,
                }}
                totalCount={groupedItems.length}
                data={groupedItems}
                style={{
                  height: `calc(${modalHeight} - ${headerHeight} - 6px)`,
                }}
                itemContent={(i) => (
                  <ReactionItem
                    items={groupedItems[i]}
                    unread={
                      unreadBorderTime && hasUnread
                        ? groupedItems[i].some(
                            (item) =>
                              unreadBorderTime < new Date(item.createdAt)
                          )
                        : false
                    }
                  />
                )}
                endReached={onEndReached}
              />
            </_ListWrapper>
          </div>
          <_Buttonwrapper>
            <LargeButton
              color="primary"
              onClick={() => {
                navigate("/bf");
                sendButtonClickEvent("bf_from_reaction_modal", `${userId}`);
              }}
            >
              <Text
                lineHeight="EQ"
                fontSize="LG"
                bold={true}
                fontColor="semantic.layout.backgroundBox"
              >
                {t<TranslateKeys>("みんなの感想を見る")}
              </Text>
            </LargeButton>
          </_Buttonwrapper>
        </Stack>
      </ReactModal>
    </>
  );
};

const _Header = styled.div`
  color: ${({ theme }) => theme.colors.tex.t700};
  font-size: 16px;
  font-weight: bold;

  height: ${headerHeight};
  border-bottom: solid ${({ theme }) => theme.colors.base.lightGray};

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 0px 16px;
`;

const _ListWrapper = styled.div`
  max-height: calc(${modalHeight} - ${headerHeight} - 3px);
  overflow: scroll;
  border-radius: 8px;
`;

const NoResponses = () => {
  const { t } = useTranslation();

  return (
    <_NoResponsesWrapper>
      <div className="label">
        {t<TranslateKeys>("まだいいねはありません！")}
      </div>
      <div className="message">
        {t<TranslateKeys>("感想を書くと、\nみんながいいねをくれるかも……!?")}
      </div>
    </_NoResponsesWrapper>
  );
};

const _NoResponsesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  justify-content: center;
  align-items: center;

  height: 100%;
  padding: 16px;

  .label {
    color: ${({ theme }) => theme.colors.tex.t500};
    font-size: 16px;
    font-weight: bold;
  }

  .message {
    line-height: 160%;
    white-space: pre-wrap;
    padding: 8px;
    color: ${({ theme }) => theme.colors.pri.p500};
    font-size: 14px;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.base.background};
  }
`;

const _RightHeaderWrapper = styled.div`
  gap: 8px;
  display: flex;
`;

const _Buttonwrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
