import React from "react";
import styled from "styled-components";
import { Text } from "~/components/atoms/texts/Text";
import { SmallButton } from "~/components/atoms/buttons/Button";

export type PaginationButtonProps = {
  currentCount: number;
  onClick: (n: number) => void;
  itemsPerPage: number;
  totalItemsCount: number;
};

export const PaginationButton = ({
  currentCount,
  onClick,
  itemsPerPage,
  totalItemsCount,
}: PaginationButtonProps): React.ReactElement => {
  const onClickNext = () => {
    onClick(currentCount + itemsPerPage);
  };
  const onClickPrev = () => {
    onClick(currentCount - itemsPerPage);
  };

  const currentPageNum = currentCount / itemsPerPage + 1;
  const totalPageNum =
    totalItemsCount % itemsPerPage === 0
      ? totalItemsCount / itemsPerPage
      : Math.floor(totalItemsCount / itemsPerPage) + 1;

  if (totalItemsCount <= itemsPerPage) {
    return <></>;
  }

  return (
    <Wrapper>
      <div style={{ width: "64px" }}>
        {!(currentCount < itemsPerPage) ? (
          <_SmallButton color="gray" variant="blank" onClick={onClickPrev}>
            前へ
          </_SmallButton>
        ) : (
          <></>
        )}
      </div>
      <div style={{ flex: 1 }}>
        <PaginationCon>
          <_Text fontSize={"SM"} lineHeight={"MD"}>
            {`${currentPageNum}/${totalPageNum}ページ`}
          </_Text>
        </PaginationCon>
      </div>
      <div style={{ width: "64px" }}>
        {!(totalItemsCount <= currentCount + itemsPerPage) ? (
          <_SmallButton color="gray" variant="blank" onClick={onClickNext}>
            次へ
          </_SmallButton>
        ) : (
          <></>
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 280px;
`;

const PaginationCon = styled.div`
  padding: 0 36px;
  flex: 1;
`;

const _Text = styled(Text)`
  text-align: center;
`;

const _SmallButton = styled(SmallButton)`
  width: 70px;
  min-width: 70px;
`;
