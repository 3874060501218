import React from "react";
import { makeVar, useReactiveVar } from "@apollo/client";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import { UserExperience, UserExperienceEvent } from "~/generated/graphql";
import { UnlockModalController } from "../Unlock/UnlockModalController";
import { useExperiencePointModal } from "../modals/ExperiencePoint/hook";

const isOpenGetExpModalVar = makeVar<boolean>(false);

type Flow = "getExp" | "unlock";

export const useBookshelfExpModal = (): {
  isOpenGetExpModal: boolean;
  setIsOpenGetExpModal: (isOpenGetExpModal: boolean) => void;
  updateExperience: (
    userExperience: UserExperience,
    achieveExperienceEvents: UserExperienceEvent[]
  ) => void;
  getExpModal: React.ReactNode;
} => {
  const { updateExperience } = React.useContext(CurrentUserContext);
  const isOpenGetExpModal = useReactiveVar(isOpenGetExpModalVar);
  const [flow, setFlow] = React.useState<Flow>("getExp");
  const experiencePointModal = useExperiencePointModal(() => {
    setFlow("unlock");
  });

  return {
    isOpenGetExpModal: isOpenGetExpModal,
    setIsOpenGetExpModal: (isOpenGetExpModal: boolean) => {
      isOpenGetExpModalVar(isOpenGetExpModal);
    },
    updateExperience,
    getExpModal:
      flow === "getExp"
        ? experiencePointModal
        : UnlockModalController({
            handleNext: () => isOpenGetExpModalVar(false),
          }),
  };
};
