import React, { VFC } from "react";
import styled from "styled-components";
import { SolidRadioButton } from "~/components/atoms/forms/RadioButton/SolidRadioButton";
import { RadioButtonProps } from "~/components/atoms/forms/RadioButton/type";
import { Text } from "~/components/atoms/texts/Text";

export type EmojiSolidRadioButtonProps = Pick<RadioButtonProps, "selected" | "onClick" | "color"> & {
  text: React.ReactNode;
  emoji: string;
}

export const EmojiSolidRadioButton: VFC<EmojiSolidRadioButtonProps> = ({ selected, onClick, color, text, emoji }) => {
  return (
    <SolidRadioButton
      selected={selected}
      onClick={onClick}
      color={color}
      shape={"Normal"}
    >
      <Container>
        <Text
          fontSize={"XXL"}
          lineHeight={"EQ"}
        >
          {emoji}
        </Text>
        <Text
          fontSize={"MD"}
          lineHeight={"EQ"}
          bold
        >
          {text}
        </Text>
      </Container>
    </SolidRadioButton>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.size.S};
`;