import React from "react";
import styled from "styled-components";
import { Text } from "~/components/atoms/texts/Text";
import { FontSize } from "~/styles/design_token/font";

export type TimeStampProps = {
  time: Date;
  textAlign?: "left" | "center" | "right";
  fontSize?: FontSize;
  showYear?: boolean;
  inline?: boolean;
};

const zeroPadding = (num: number, len: number): string =>
  (Array(len).join("0") + num).slice(-len);

export const TimeStamp: React.FC<TimeStampProps> = ({
  time,
  textAlign = "left",
  fontSize = "XXS",
  showYear = false,
  inline = false,
}: TimeStampProps) => {
  const year = time.getFullYear();
  const month = time.getMonth() + 1;
  const date = time.getDate();
  const hours = zeroPadding(time.getHours(), 2);
  const minutes = zeroPadding(time.getMinutes(), 2);

  const content = showYear
    ? `${year}年${month}月${date}日`
    : `${month}月${date}日${hours}:${minutes}`;

  return inline ? (
    <span>{content}</span>
  ) : (
    <_TimeStampStyle
      textAlign={textAlign}
      fontSize={fontSize}
      lineHeight={"EQ"}
      bold={false}
    >
      {content}
    </_TimeStampStyle>
  );
};

const _TimeStampStyle = styled(Text)<{
  textAlign: "left" | "center" | "right";
}>`
  color: ${({ theme }) => theme.colors.tex.t300};
  text-align: ${(props) => props.textAlign};
`;
