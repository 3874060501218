export default {
  "ミニレッスンを再開する": "ミニレッスンを再開する",
  "図書館で予約": "図書館で予約",
  "本の詳細": "本のしょうさい",
  "本の友の感想が見られるようになるよ": "本の友の感想が見られるようになるよ",
  "レベルを上げるには？": "レベルを上げるには？",
  "ミニレッスンを受けたり、本の感想を出したりすると、レベルが上がるよ": "ミニレッスンを受けたり、本の感想を出したりすると、レベルが上がるよ",
  "しばらくすると、他の子の感想を この画面から 見られるようになるよ！": "しばらくすると、ほかの子の感想を この画面から 見られるようになるよ！",
  "本の友": "本の友",
  "本棚": "ほんだな",
  "ヨンデミー先生": "ヨンデミー先生",
  "おすすめの本": "おすすめの本",
  "おすすめ冊数": "おすすめさっすう",
  "冊": "さつ",
  "次の選書まであと": "次の選書まであと",
  "冊読もう": "さつ読もう",
  "本が大好きなAIのせんせい。\n図書館の司書をしていて、読書家のみんなに、ぴったりの本をおすすめすることがとくい。\nあたまに本を乗せているので、水がにがて。": "本が大好きなAIのせんせい。\n図書館の司書をしていて、読書家のみんなに、ぴったりの本をおすすめすることがとくい。\nあたまに本を乗せているので、水がにがて。",
  "さいしょは本を食べてばかりいたが、ヨンデミーで本を読むことをおぼえたようだ。": "さいしょは本を食べてばかりいたが、ヨンデミーで本を読むことをおぼえたようだ。",
  "ながいねむりから目が覚めたあと、ヨンデミーのおすすめ本で自分の仲間がぜつめつしたことを知った。": "ながいねむりから目が覚めたあと、ヨンデミーのおすすめ本で自分の仲間がぜつめつしたことを知った。",
  "その昔、人間にちしきをさずけてくれた、伝説のりんご。\n実は、どくりんごである。": "その昔、人間にちしきをさずけてくれた、伝説のりんご。\n実は、どくりんごである。",
  "ふだんは、なんきょくに住んでいる。冬にはむれで、おんどく会をするらしい。": "ふだんは、なんきょくに住んでいる。冬にはむれで、おんどく会をするらしい。",
  "本をよんで、なぜ自分はほかの動物をたべるのかがわからなくなり、草食になった。": "本をよんで、なぜ自分はほかの動物をたべるのかがわからなくなり、草食になった。",
  "本を読んで想像力を膨らませるとモコモコが膨らむ。眠れないときに数えるひつじの中に混ざっているらしい。": "本を読んで想像力をふくらませるとモコモコがふくらむ。ねむれないときに数えるひつじの中に混ざっているらしい。",
  "紙職人の家に生まれたが、家出した一匹オオカミ。オオカミがお話の中でワルモノになりがちなことを実は少し気にしている。": "紙職人の家に生まれたが、家出した一ぴきオオカミ。オオカミがお話の中でワルモノになりがちなことを実は少し気にしている。",
  "生まれつきピンクのキリン。長い首を伸ばして長い本を読みきるのが得意なようだ。": "生まれつきピンクのキリン。長い首をのばして長い本を読みきるのが得意なようだ。",
  "じゅれいは3000年を超える。世界ではじめてしゅっぱんされた本は、校長先生のえだからつくられたという。": "じゅれいは3000年をこえる。世界ではじめてしゅっぱんされた本は、校長先生のえだからつくられたという。",
  "校長先生": "校長先生",
  "本の虫": "本の虫",
  "太古のきょうりゅう": "太古のきょうりゅう",
  "りんごちゃん": "りんごちゃん",
  "ペンギンせんぱい": "ペンギンせんぱい",
  "ベジタライオン": "ベジタライオン",
  "モコひつじ": "モコひつじ",
  "オオカミスミス": "オオカミスミス",
  "よみキリン": "よみキリン",
  "その昔、人間に 知恵をさずけてくれた、伝説のりんご。": "その昔、人間に ちえをさずけてくれた、伝説のりんご。",
  "ふだんは、南極に住んでいる。冬にはむれで、音読会を するらしい。": "ふだんは、南極に住んでいる。冬にはむれで、音読会を するらしい。",
  "さいしょは 本を食べてばかりいたが、ヨンデミーで 本を読むことを おぼえた。": "さいしょは 本を食べてばかりいたが、ヨンデミーで 本を読むことを おぼえた。",
  "本を読んで、なぜ自分は 肉を食べるのか わからなくなり、ベジタリアンになった。": "本を読んで、なぜ自分は 肉を食べるのか わからなくなり、ベジタリアンになった。",
  "？？？": "？？？",
  "ヨンデミッションをクリアしてヨンデミーストーリーを進めると、新しいアイコンがもらえるよ！次は誰がもらえるかな？": "ヨンデミッションをクリアしてヨンデミーストーリーを進めると、新しいアイコンがもらえるよ！次はだれがもらえるかな？",
  "保存して完了": "ほぞんしてかんりょう",
  "アイコン変更": "アイコンへんこう",
  "ニックネームが書かれていないよ。": "ニックネームが書かれていないよ。",
  "きみの名前を教えてね！": "きみの名前を教えてね！",
  "すてきな名前があふれちゃう！": "すてきな名前があふれちゃう！",
  "{{ max }}文字まで使えるよ！": "{{ max }}文字まで使えるよ！",
  "{{ max }}文字までで決めてね": "{{ max }}文字までで決めてね",
  "プロフィール変更": "プロフィールへんこう",
  "これに決める": "これに決める",
  "感想編集": "感想編集",
  "ごめんなさい！送ることができませんでした。もう一度試してみてね": "ごめんなさい！送ることができませんでした。もう一度ためしてみてね",
  "読書記録": "読書記録",
  "感想提出": "感想提出",
  "連続中": "連続中",
  "読んだ本": "読んだ本",
  "読んだ文字数": "読んだ文字数",
  "字": "字",
  "設定": "設定",
  "当てはまるものをえらんでね！": "当てはまるものをえらんでね！",
  "この本を読んだときの、": "この本を読んだときの、",
  "さんの気持ちに近いものは？": "さんの気持ちに近いものは？",
  "短すぎる": "短すぎる",
  "短い": "短い",
  "ちょうどいい": "ちょうどいい",
  "長い": "長い",
  "長すぎる": "長すぎる",
  "ちょっと長い": "ちょっと長い",
  "ちょっと短い": "ちょっと短い",
  "この本はどうだった？": "この本はどうだった？",
  "にがて": "にがて",
  "あまり好きではない": "あまり好きではない",
  "好き": "好き",
  "とても好き": "とても好き",
  "もういちど読みたい": "もういちど読みたい",
  "ちょっと好き": "ちょっと好き",
  "とちゅうまで": "とちゅうまで",
  "最後まで": "最後まで",
  "ほんだなから消す": "ほんだなから消す",
  "どれくらいまで読んだ？": "どれくらいまで読んだ？",
  "読み聞かせで読んだ": "読み聞かせで読んだ",
  "まだ読んだことがないお友達におすすめしたい、": "まだ読んだことがないおともだちにおすすめしたい、",
  "1番お気に入りのシーン・セリフ・フレーズを教えてね！": "1番お気に入りのシーン・セリフ・フレーズを教えてね！",
  "思いつかないときは、空欄のままにしてね。": "思いつかないときは、くうらんのままにしてね。",
  "お話の中で、音が聞こえてきそうなところはあったかな？👂\n\nトントン、ザーザー、ぴょこぴょこ……いろんな音がなっているかも🤔\n\nよーく読んで、思い描いてみよう✨": "お話の中で、音が聞こえてきそうなところはあったかな？👂\n\nトントン、ザーザー、ぴょこぴょこ……いろんな音がなっているかも🤔\n\nよーく読んで、おもいえがいてみよう✨",
  "どんな見た目のものが出てきたかな？\n\n綺麗なもの、へんてこなもの、おいしそうなもの……本には いろいろなものが 出てくるよね🙌\n\n細かいところまで、思い描いてみよう✨": "どんな見た目のものが出てきたかな？\n\nきれいなもの、へんてこなもの、おいしそうなもの……本には いろいろなものが 出てくるよね🙌\n\n細かいところまで、おもいえがいてみよう✨",
  "この本のどこか1ページに潜り込めるとしたらどのページに潜り込みたいかな？\n\n潜り込んで何をやってみたい？\n\n自分が本の世界に入り込んだつもりで思い描いてみよう✨": "この本のどこか1ページにもぐりこめるとしたらどのページにもぐりこみたいかな？\n\nもぐりこんで何をやってみたい？\n\n自分が本の世界にはいりこんだつもりでおもいえがいてみよう✨",
  "登場人物とお話しできるとしたら、誰と話してみたい？\n\nどんな話をしようかな？ワクワクするね\n\n登場人物がなんていうのか思い描いてみよう✨": "登場人物とお話しできるとしたら、だれと話してみたい？\n\nどんな話をしようかな？ワクワクするね\n\n登場人物がなんていうのかおもいえがいてみよう✨",
  "好きなページはあった？ そのページで、登場人物はどんな気持ちになったのかな？\n\n楽しい、嬉しい、悲しい……いろんな感情がありそうだよね☺\n\n登場人物になりきって思い描いてみよう✨": "好きなページはあった？ そのページで、登場人物はどんな気持ちになったのかな？\n\n楽しい、うれしい、悲しい……いろんな感情がありそうだよね☺\n\n登場人物になりきっておもいえがいてみよう✨",
  "もし自分が 登場人物だったら 何をしただろう？🤔\n\n同じことをする？それとも、違うことをする？ ヨンデミー先生に教えてほしいなぁ☺\n\n本の中と自分のすることをつなげてみよう✨": "もし自分が 登場人物だったら 何をしただろう？🤔\n\n同じことをする？それとも、ちがうことをする？ ヨンデミー先生に教えてほしいなぁ☺\n\n本の中と自分のすることをつなげてみよう✨",
  "自分が 登場人物だったら どんな気持ちになったかな？\n\n自分だったら 同じ気持ちになった？ それとも 違う気持ちになった？いろんな気持ちに なりそうだよね😉\n\n自分の気持ちと本の人物の気持ちをつなげてみよう✨": "自分が 登場人物だったら どんな気持ちになったかな？\n\n自分だったら 同じ気持ちになった？ それとも ちがう気持ちになった？いろんな気持ちに なりそうだよね😉\n\n自分の気持ちと本の人物の気持ちをつなげてみよう✨",
  "これまでに読んだおはなしで似ているお話はあったかな？\n\nどんなお話だったか、どんな ものや ひとが 出てきたか、いろんなポイントがありそうだね☺\n\n前に読んだお話とこのお話をつなげてみよう✨": "これまでに読んだおはなしで似ているお話はあったかな？\n\nどんなお話だったか、どんな ものや ひとが 出てきたか、いろんなポイントがありそうだね☺\n\n前に読んだお話とこのお話をつなげてみよう✨",
  "このお話に出てくる登場人物と、にている人は身近にいるかな？\n\nどんなところが似ているか考えてヨンデミー先生に教えてね☺\n\n身近な人物とお話の人物をつなげてみよう✨": "このお話に出てくる登場人物と、にている人は身近にいるかな？\n\nどんなところが似ているか考えてヨンデミー先生に教えてね☺\n\n身近な人物とお話の人物をつなげてみよう✨",
  "すでに知っていること、やったことのあることはでてきたかな？\n\nどんなことを知っていたのか思い出して書いてみてね\n\n自分の知識と本の知識をつなげてみよう✨": "すでに知っていること、やったことのあることはでてきたかな？\n\nどんなことを知っていたのか思い出して書いてみてね\n\n自分の知識と本の知識をつなげてみよう✨",
  "不思議だな、わからないなと思ったことはあったかな？🤔\n\nどんなところで思ったかも教えてね\n\n本のわからないところに質問してみよう✨": "不思議だな、わからないなと思ったことはあったかな？🤔\n\nどんなところで思ったかも教えてね\n\n本のわからないところに質問してみよう✨",
  "この本の登場人物に一つ質問ができるとしたら、どんな質問をしてみたい？\n\n戦っていたときの気持ち、ご飯の味の感想など、登場人物の体験をもっと詳しく知りたいね。\n\n登場人物の気持ちを質問してみよう✨": "この本の登場人物に一つ質問ができるとしたら、どんな質問をしてみたい？\n\n戦っていたときの気持ち、ご飯の味の感想など、登場人物の体験をもっとくわしく知りたいね。\n\n登場人物の気持ちを質問してみよう✨",
  "このお話の中で、もっと知りたい！と思ったところはあった？\n\n本に書かれていることでもいいし、書かれていないことだって聞いてみてもいいかもね😉\n\nお話の中で気になったことを質問してみよう✨": "このお話の中で、もっと知りたい！と思ったところはあった？\n\n本に書かれていることでもいいし、書かれていないことだって聞いてみてもいいかもね😉\n\nお話の中で気になったことを質問してみよう✨",
  "言葉の意味がわからないところはあったかな？\n\n漢字や言葉遣いなど、文の意味を読み取るのに難しいところもあるよね🤔\n\n言葉の意味を質問してみよう✨": "言葉の意味がわからないところはあったかな？\n\n漢字やことばづかいなど、文の意味を読み取るのにむずかしいところもあるよね🤔\n\n言葉の意味を質問してみよう✨",
  "登場人物が どうしてそうしたのか わからないところがあったかな？\n\nその人が何をどう思ったのか、考えてみてもおもしろそうだね。\n\n登場人物の 行動の理由を質問してみよう✨": "登場人物が どうしてそうしたのか わからないところがあったかな？\n\nその人が何をどう思ったのか、考えてみてもおもしろそうだね。\n\n登場人物の 行動の理由を質問してみよう✨",
  "お話を読みながら 思ったことはあったかな？それはお話の続きと 同じだった？それとも、違った？\n\n先のお話を考えながら 読んだところがあれば教えてね😉\n\nお話の展開を予想しよう✨": "お話を読みながら 思ったことはあったかな？それはお話の続きと 同じだった？それとも、ちがった？\n\n先のお話を考えながら 読んだところがあれば教えてね😉\n\nお話のてんかいを予想しよう✨",
  "意味を知らない言葉や文はあったかな？そこはどういう意味だと思う？\n\nわかる文をヒントにして考えてみてね。\n\nわからないところの意味を予想しよう✨": "意味を知らない言葉や文はあったかな？そこはどういう意味だと思う？\n\nわかる文をヒントにして考えてみてね。\n\nわからないところの意味を予想しよう✨",
  "文章に書かれていないけれど、実はこうじゃないかなと思ったことはあったかな？\n\nどんなことを感じたか、ヨンデミー先生に教えてね☺\n\n隠されている 本当のことを予想しよう✨": "文章に書かれていないけれど、実はこうじゃないかなと思ったことはあったかな？\n\nどんなことを感じたか、ヨンデミー先生に教えてね☺\n\nかくされている 本当のことを予想しよう✨",
  "もしこのお話に続きがあるとしたら、どんなお話になると思う？\n\n幸せなお話、悲しいお話、どんなお話でも大丈夫！\n\nお話の先を予想しよう✨": "もしこのお話に続きがあるとしたら、どんなお話になると思う？\n\n幸せなお話、悲しいお話、どんなお話でもだいじょうぶ！\n\nお話の先を予想しよう✨",
  "このお話を読みながら思っていたことと、全然違ったことはあったかな？\n\n思っていたことと全然違ったらびっくりするね！どこが違ったのか教えてね☺\n\nお話を読みながら先の展開を予想しよう✨": "このお話を読みながら思っていたことと、全然ちがったことはあったかな？\n\n思っていたことと全然ちがったらびっくりするね！どこがちがったのか教えてね☺\n\nお話を読みながら先のてんかいを予想しよう✨",
  "自分に一番似ていると思うキャラクターは誰だろう？\n\nどういうところでそう思ったのかも、ヨンデミー先生に聞かせてほしいな☺\n\n自分とキャラクターの似ているところを認めよう✨": "自分に一番似ていると思うキャラクターはだれだろう？\n\nどういうところでそう思ったのかも、ヨンデミー先生に聞かせてほしいな☺\n\n自分とキャラクターの似ているところをみとめよう✨",
  "自分に 一番似ていないと思うキャラクターは誰かな？\n\nその人とどういうところが似ていないかな？その人がどんな気持ちだったか、考えてみよう🤔\n\nその人の気持ちやものの見方を認めよう✨": "自分に 一番似ていないと思うキャラクターはだれかな？\n\nその人とどういうところが似ていないかな？その人がどんな気持ちだったか、考えてみよう🤔\n\nその人の気持ちやものの見方をみとめよう✨",
  "すごく印象に残ったところはどこですか？\n\nどうして印象に残ったのか、考えてみよう\n\n自分にとって大事なことを認めよう✨": "すごく印象に残ったところはどこですか？\n\nどうして印象に残ったのか、考えてみよう\n\n自分にとって大事なことをみとめよう✨",
  "これからはこうしよう、または、こうしないようにしよう、と思ったことはあるかな？\n\nどうしてそう思ったのかも、ヨンデミー先生は気になるよ！\n\n自分の良かったところ、もっと良くできるところを認めよう✨": "これからはこうしよう、または、こうしないようにしよう、と思ったことはあるかな？\n\nどうしてそう思ったのかも、ヨンデミー先生は気になるよ！\n\n自分の良かったところ、もっと良くできるところをみとめよう✨",
  "自分の思っていたことと、違ったところはあったかな？\n\n違う考えや気持ちに対してどう感じたかな？\n\n自分と本の内容との違いを認めよう✨": "自分の思っていたことと、ちがったところはあったかな？\n\nちがう考えや気持ちに対してどう感じたかな？\n\n自分と本の内容とのちがいをみとめよう✨",
  "心に残っているセリフはあったかな？\n\nそのセリフは、どうして心に残ったんだろう？🤔感動したから、かっこいいから、かわいいから……いろんな理由がありそうだね☺\n\n自分にとって大事なセリフを見極めよう✨": "心に残っているセリフはあったかな？\n\nそのセリフは、どうして心に残ったんだろう？🤔感動したから、かっこいいから、かわいいから……いろんな理由がありそうだね☺\n\n自分にとって大事なセリフをみきわめよう✨",
  "好きなところ、面白いところはどういうところだったかな？どうして好きだと思ったかも教えてくれると嬉しいな☺自分にとって面白いところを見極めよう✨": "好きなところ、おもしろいところはどういうところだったかな？どうして好きだと思ったかも教えてくれるとうれしいな☺自分にとっておもしろいところをみきわめよう✨",
  "新しく知ったことはあったかな？どんなことを新しく知ったのか、ヨンデミー先生も知りたいな！\n\n新しい知識を見極めよう✨": "新しく知ったことはあったかな？どんなことを新しく知ったのか、ヨンデミー先生も知りたいな！\n\n新しい知識をみきわめよう✨",
  "他の人に教えたい！と思ったことはあったかな？\n\n教えたいところを書いて、ヨンデミー先生に見せてほしいなぁ☺\n\nおすすめポイントを見極めよう✨": "ほかの人に教えたい！と思ったことはあったかな？\n\n教えたいところを書いて、ヨンデミー先生に見せてほしいなぁ☺\n\nおすすめポイントをみきわめよう✨",
  "作者が伝えたかったことはなんだと思う？\n\n本から感じ取れるメッセージを書いて、ヨンデミー先生に教えてね😉\n\n作者の伝えたいことを見極めよう✨": "作者が伝えたかったことはなんだと思う？\n\n本から感じ取れるメッセージを書いて、ヨンデミー先生に教えてね😉\n\n作者の伝えたいことをみきわめよう✨",
  "物語の最後で主人公はどんな気持ちだったかな？\n\n嬉しい？悲しい？そう思ったのはどうしてだろう？🤔\n\n主人公の気持ちを解釈しよう✨": "物語の最後で主人公はどんな気持ちだったかな？\n\nうれしい？悲しい？そう思ったのはどうしてだろう？🤔\n\n主人公の気持ちをかいしゃくしよう✨",
  "作者はどうしてこの本を書いたんだろう？\n\nいったいどんなメッセージを伝えたかったのかな🤔\n\n作者の意図を解釈しよう✨": "作者はどうしてこの本を書いたんだろう？\n\nいったいどんなメッセージを伝えたかったのかな🤔\n\n作者の意図をかいしゃくしよう✨",
  "この本をおすすめするとしたら、どう説明するかな？\n\n何が面白かった？どんな人におすすめ？考えて書いて、ヨンデミー先生にもおすすめしてね！\n\nおすすめの理由をまとめて、解釈しよう✨": "この本をおすすめするとしたら、どう説明するかな？\n\n何がおもしろかった？どんな人におすすめ？考えて書いて、ヨンデミー先生にもおすすめしてね！\n\nおすすめの理由をまとめて、かいしゃくしよう✨",
  "自分にとって大切だと思うことはあったかな？\n\nどうしてそれが大切なんだろう？\n\n大切だと思うことを考えて、解釈しよう✨": "自分にとって大切だと思うことはあったかな？\n\nどうしてそれが大切なんだろう？\n\n大切だと思うことを考えて、かいしゃくしよう✨",
  "この本はどんなお話だったかな？簡単に説明してみよう\n\n話の内容を 短く説明するとしたら どうなるかな？ヨンデミー先生に説明してくれると嬉しいな☺\n\nお話をまとめて、内容を解釈してみよう✨": "この本はどんなお話だったかな？かんたんに説明してみよう\n\n話の内容を 短く説明するとしたら どうなるかな？ヨンデミー先生に説明してくれるとうれしいな☺\n\nお話をまとめて、内容をかいしゃくしてみよう✨",
  "『{{bookTitle}}』の あまり好きではなかったところは どこだろう？": "『{{bookTitle}}』の あまり好きではなかったところは どこだろう？",
  "あまり好きではなかった本の 感想は「本の友」には 流れません！": "あまり好きではなかった本の 感想は「本の友」には 流れません！",
  "ヨンデミー先生 にだけ こっそり教えてね": "ヨンデミー先生 にだけ こっそり教えてね",
  "『{{bookTitle}}』の": "『{{bookTitle}}』の",
  "一言感想を教えてください！": "一言感想を教えてください！",
  "こわいシーンが 多くて、夢に でできそうで いやだった。": "こわいシーンが 多くて、夢に でできそうで いやだった。",
  "思いつかないときは、ヒントガチャを引いてみよう。": "思いつかないときは、ヒントガチャを引いてみよう。",
  "ヒントガチャを引く": "ヒントガチャを引く",
  "声で入力するには": "声で入力するには",
  "今回は書かない": "今回は書かない",
  "紙で書くには": "紙で書くには",
  "この内容ですすむ": "この内容ですすむ",
  "ほかのヒントを選ぶ": "ほかのヒントを選ぶ",
  "好きな感想を書いてみてね": "好きな感想を書いてみてね",
  "1024文字以内で入力してください": "1024文字以内で入力してください",
  "空白の行があるよ！改行は1度に1行までにしてね。": "空白の行があるよ！改行は1度に1行までにしてね。",
  "絵文字が文字より多くなっているよ！文字を使って感想を書いてみよう": "絵文字が文字より多くなっているよ！文字を使って感想を書いてみよう",
  "ISBNとタイトルを入力してください": "ISBNとタイトルを入力してください",
  "※ISBNは本のうらがわに書いてある、13けたの数字のことです。": "※ISBNは本のうらがわに書いてある、13けたの数字のことです。",
  "これは1000人中500人だけです！\n明日も読めば1000人中300人に入れるよ": "これは1000人中500人だけです！\nあしたも読めば1000人中300人に入れるよ",
  "1000人中300人！\n5日連続で1000人中200人に入れるよ！": "1000人中300人！\n5日連続で1000人中200人に入れるよ！",
  "1000人中200人だ！\n1週間連続で1000人中100人だよ": "1000人中200人だ！\n1週間連続で1000人中100人だよ",
  "1週間連続で読んだのは、1000人中100人だけです！": "1週間連続で読んだのは、1000人中100人だけです！",
  "1000人中50人まで来たよ！\nもうすぐ2週間連続だ": "1000人中50人まできたよ！\nもうすぐ2週間連続だ",
  "2週間連続！？\nすごい！\n1000人中30人だよ": "2週間連続！？\nすごい！\n1000人中30人だよ",
  "1000人中20人しか3週連続で読めた人はいないよ！": "1000人中20人しか3週連続で読めた人はいないよ！",
  "ここまで来たのは1000人に10人だけ！\nとうとうトップ10だ！": "ここまできたのは1000人に10人だけ！\nとうとうトップ10だ！",
  "なんと、10000人に50人まで来ちゃったよ！\n次は何人に入れるかな？": "なんと、10000人に50人まできちゃったよ！\n次は何人に入れるかな？",
  "6週連続で読んだのは10000人に20人だけだよ！": "6週連続で読んだのは10000人に20人だけだよ！",
  "すごすぎる！\n10000人に10人だけだよ！": "すごすぎる！\n10000人に10人だけだよ！",
  "10000人に5人だよ！！\n片手の指で数えられちゃうね！": "10000人に5人だよ！！\nかたての指で数えられちゃうね！",
  "10000人に2人！\nもうちょっとで10000人のトップになれるよ！": "10000人に2人！\nもうちょっとで10000人のトップになれるよ！",
  "すごい！\n10000人に1人の読書家だ！": "すごい！\n10000人に1人の読書家だ！",
  "頑張った{{user}}さんに四葉のクローバーをプレゼント！": "がんばった{{user}}さんによつばのクローバーをプレゼント！",
  "砂漠のオアシス発見級のすごさ！": "さばくのオアシス発見級のすごさ！",
  "{{user}}さんのために流れ星が降り注ぐよ！": "{{user}}さんのために流れ星がふりそそぐよ！",
  "ティラノサウルスの化石を掘りあてたくらいすごい！": "ティラノサウルスの化石をほりあてたくらいすごい！",
  "読書オリンピックで金メダルだね😉": "読書オリンピックで金メダルだね😉",
  "エベレスト級の本の山のてっぺんだ！": "エベレスト級の本の山のてっぺんだ！",
  " {{user}}さんの読んだ本でピラミッドが作れちゃう！": " {{user}}さんの読んだ本でピラミッドが作れちゃう！",
  "本を読み続けて1年！！\nすっごく成長したね！": "本を読み続けて1年！！\nすっごく成長したね！",
  "読書星人も{{user}}さんとお友達になりたがるよ！": "読書星人も{{user}}さんとおともだちになりたがるよ！",
  "あまりのすごさにモナ・リザも笑顔になっちゃった！": "あまりのすごさにモナ・リザもえがおになっちゃった！",
  "びっくりしてアリが象を持ち上げちゃった！": "びっくりしてアリが象を持ち上げちゃった！",
  "ピサの斜塔がびっくりしてまっすぐになっちゃった！": "ピサのしゃとうがびっくりしてまっすぐになっちゃった！",
  "クジラも一緒に本を読みたくて本を噴き上げちゃったよ！": "クジラもいっしょに本を読みたくて本をふきあげちゃったよ！",
  "モアイ像がおめでとうの歌を歌い出したみたいだよ！": "モアイ像がおめでとうの歌を歌い出したみたいだよ！",
  "ここまで辿り着けるのは、選ばれた伝説の読書家だけです！": "ここまでたどりつけるのは、選ばれた伝説の読書家だけです！",
  "日連続": "日連続",
  "どの本の感想をかきますか？": "どの本の感想をかきますか？",
  "自分で\n選んだ本の\n感想を送る": "自分で\n選んだ本の\n感想を送る",
  "感想を とどけて いいですか？": "感想を とどけて いいですか？",
  "難しかった？": "むずかしかった？",
  "長さは？": "長さは？",
  "好きだった？": "好きだった？",
  "気持ち": "気持ち",
  "書きなおす": "書きなおす",
  "とどける": "とどける",
  "どこまで読んだ？": "どこまで読んだ？",
  "読み切っていない場合、おすすめの本リストからは削除されません": "読み切っていない場合、おすすめの本リストからはさくじょされません",
  "一言感想": "一言感想",
  "中学生向け": "中学生向け",
  "6年生向け": "6年生向け",
  "5年生向け": "5年生向け",
  "4年生向け": "4年生向け",
  "3年生向け": "3年生向け",
  "2年生むけ": "2年生むけ",
  "1年生むけ（ひらがなのみ）": "1年生むけ（ひらがなのみ）",
  "1ヶ月": "1ヶ月",
  "2ヶ月": "2ヶ月",
  "3ヶ月": "3ヶ月",
  "読書習慣": "読書習慣",
  "感想": "感想",
  "一緒": "いっしょ",
  "本": "本",
  "読んで": "読んで",
  "楽しんで": "楽しんで",
  "全部": "全部",
  "回": "回",
  "先週": "先週",
  "{{ year }}年{{ month }}月": "{{ year }}年{{ month }}月",
  "読んだ本の冊数": "読んだ本のさっすう",
  "読書時間": "読書時間",
  "連続読書日数": "連続読書日数",
  "初めての読者": "初めての読者",
  "これまでに{{nickname}}さんが読んだ本の文字数の合計です。": "これまでに{{nickname}}さんが読んだ本の文字数の合計です。",
  "これまでに{{nickname}}さんが読んだ本の数です。": "これまでに{{nickname}}さんが読んだ本の数です。",
  "これまでに{{nickname}}さんが本を読んだ時間の合計です。": "これまでに{{nickname}}さんが本を読んだ時間の合計です。",
  "{{nickname}}さんの感想を出した日数の連続記録です。": "{{nickname}}さんの感想を出した日数の連続記録です。",
  "ヨンデミーの生徒の中で{{nickname}}さんがはじめて読んだ本の数です。": "ヨンデミーの生徒の中で{{nickname}}さんがはじめて読んだ本の数です。",
  "読書家の書": "読書家の書",
  "{{ userName }}の書": "{{ userName }}の書",
  "削除する理由を": "さくじょする理由を",
  "教えてください": "教えてください",
  "途中まで読んだときは": "とちゅうまで読んだときは",
  "まずは感想を送る": "まずは感想を送る",
  "本を おすすめ本リストから 削除します": "本を おすすめ本リストから さくじょします",
  "戻る": "もどる",
  "本を削除できなかったよ！もう一度試してみてね！": "本をさくじょできなかったよ！もう一度ためしてみてね！",
  "いいねを送る": "いいねを送る",
  "を読み終わりました！": "を読み終わりました！",
  "を読んでいます！": "を読んでいます！",
  "を読むのをやめました！": "を読むのをやめました！",
  "読みたい 本を 自分で 見つけられて すごいね！": "読みたい 本を 自分で 見つけられて すごいね！",
  "この本も {{ user }}さんに 選んでもらえて うれしそう🥰": "この本も {{ user }}さんに 選んでもらえて うれしそう🥰",
  "とっても 面白そうな 本！ 気になるなぁ👀": "とっても おもしろそうな 本！ 気になるなぁ👀",
  "どんな 本 なんだろう？読んでみたいな〜！": "どんな 本 なんだろう？読んでみたいな～！",
  "すてきな 本だね！感想が 楽しみ😆✨": "すてきな 本だね！感想が 楽しみ😆✨",
  "この 本のこと、ヨンデミー先生にも 教えてね🤗": "この 本のこと、ヨンデミー先生にも 教えてね🤗",
  "答えを書いてね": "答えを書いてね",
  "使って": "使って",
  "送る": "送る",
  "選び直す": "選び直す",
  "体の 感覚を 使って 頭の中に イメージを 描く": "体の 感覚を 使って 頭の中に イメージを えがく",
  "自分の 知っている事と つなげて 読む": "自分の 知っている事と つなげて 読む",
  "質問して 本の意味を 深く 理解する": "質問して 本の意味を 深く 理解する",
  "実際には 書かれていない 行間の意味を 読み取る": "実際には 書かれていない 行間の意味を 読み取る",
  "自分の 正しさや 間違いを 見つけて 考えを深める": "自分の 正しさや まちがいを 見つけて 考えを深める",
  "本の 大事な所を 見つけながら 読む": "本の 大事な所を 見つけながら 読む",
  "本の内容を まとめたり、自分の考えを 付け加えたりする": "本の内容を まとめたり、自分の考えを 付け加えたりする",
  "使った": "使った",
  "力": "力",
  "満ちた": "満ちた",
  "届けよう": "とどけよう",
  "本の友は準備中です！": "本の友は準備中です！",
  "明日までお待ちください。\n{{nickname}}さんと気が合いそうな読書仲間の感想を見られるように準備しています。": "あしたまでお待ちください。\n{{nickname}}さんと気が合いそうな読書仲間の感想を見られるように準備しています。",
  "明日": "あした",
  "来てね": "きてね",
  "1日1回": "1日1回",
  "押せるよ": "おせるよ",
  "みんなのいいね": "みんなのいいね",
  "まだいいねはありません！": "まだいいねはありません！",
  "感想を書くと、\nみんながいいねをくれるかも……!?": "感想を書くと、\nみんながいいねをくれるかも……!?",
  "みんなの感想を見る": "みんなの感想を見る",
  "他": "他",
  "受け取った": "受け取った",
  "行く": "行く",
  "閉じる": "とじる",
  "作者": "作者",
  "作画者": "作画者",
  "出版社": "出版社",
  "文字数": "文字数",
  "ページ数": "ページ数",
  "出版年月日": "出版年月日",
  "詳しい情報": "くわしい情報",
  "今食べたいのは？": "今食べたいのは？",
  "ハンバーグ": "ハンバーグ",
  "パンケーキ": "パンケーキ",
  "野菜カレー": "野菜カレー",
  "フルーツサンド": "フルーツサンド",
  "好きなことは何？": "好きなことは何？",
  "体を動かすこと": "体を動かすこと",
  "音楽を聴くこと": "音楽をきくこと",
  "お絵描きや工作": "おえかきや工作",
  "ファッション": "ファッション",
  "空いた時間は何したい？": "空いた時間は何したい？",
  "友だちと遊ぶ": "友だちと遊ぶ",
  "おうちでまったり": "おうちでまったり",
  "のんびりおでかけ": "のんびりおでかけ",
  "好きなことをとことん": "好きなことをとことん",
  "この中で欲しい力は？": "この中でほしい力は？",
  "なかまをすくう力": "なかまをすくう力",
  "人とはなす力": "人とはなす力",
  "つづける力": "つづける力",
  "すばやくうごく力": "すばやくうごく力",
  "自分はどういう性格だと思う？": "自分はどういう性格だと思う？",
  "パワフル": "パワフル",
  "クール": "クール",
  "リラックス": "リラックス",
  "ラブリー": "ラブリー",
  "自分を表してる音は何？": "自分を表してる音は何？",
  "モリモリ": "モリモリ",
  "しんしん": "しんしん",
  "ほわほわ": "ほわほわ",
  "シャキシャキ": "シャキシャキ",
  "好きな場所は？": "好きな場所は？",
  "海": "海",
  "山": "山",
  "草原": "草原",
  "街": "街",
  "好きな時間帯は？": "好きな時間帯は？",
  "朝": "朝",
  "夜": "夜",
  "夕方": "夕方",
  "昼": "昼",
  "好きな季節は？": "好きな季節は？",
  "夏": "夏",
  "冬": "冬",
  "春": "春",
  "秋": "秋",
  "お金を無限に使えたら何をする？": "お金を無限に使えたら何をする？",
  "大きい家をつくる": "大きい家をつくる",
  "体育館を作る": "体育館を作る",
  "面白い芸を見る": "おもしろい芸を見る",
  "健康グッズを買う": "健康グッズを買う",
  "音楽を聴きまくる": "音楽をききまくる",
  "家族にプレゼント": "家族にプレゼント",
  "本を作る": "本を作る",
  "一人旅に出る": "ひとりたびに出る",
  "特に使わない": "特に使わない",
  "みんなにあげる": "みんなにあげる",
  "工作しまくる": "工作しまくる",
  "みんなでパーティー": "みんなでパーティー",
  "勉強に使う": "勉強に使う",
  "一人で豪遊": "ひとりでごうゆう",
  "服を買う": "服を買う",
  "旅行に行く": "旅行に行く",
  "好きな香りは何？": "好きなかおりは何？",
  "食べ物の香り": "食べ物のかおり",
  "岩の香り": "岩のかおり",
  "木の香り": "木のかおり",
  "古本の香り": "古本のかおり",
  "布団の香り": "ふとんのかおり",
  "花の香り": "花のかおり",
  "紙の香り": "紙のかおり",
  "インクの香り": "インクのかおり",
  "草の香り": "草のかおり",
  "土の香り": "土のかおり",
  "絵の具の香り": "絵の具のかおり",
  "海の香り": "海のかおり",
  "フルーツの香り": "フルーツのかおり",
  "雨の香り": "雨のかおり",
  "化粧品の香り": "けしょうひんのかおり",
  "ハーブの香り": "ハーブのかおり",
  "{{nickname}}さんと\nおんなじ！ワクワクするぞー！": "{{nickname}}さんと\nおんなじ！ワクワクするぞー！",
  "冒険　ワクワク　勇気": "ぼうけん　ワクワク　勇気",
  "明るくて 元気な ムードメーカー": "明るくて 元気な ムードメーカー",
  "元気いっぱいの": "元気いっぱいの",
  "ドキドキハラハラする 冒険の本": "ドキドキハラハラする ぼうけんの本",
  "オイラのおすすめの本、ヨンデミー！": "オイラのおすすめの本、ヨンデミー！",
  "ワタシと同じなんて\n{{nickname}}さんも なかなかやるわね": "ワタシと同じなんて\n{{nickname}}さんも なかなかやるわね",
  "知識　ノンフィクション　説明文": "知識　ノンフィクション　説明文",
  "口が悪いけど 自分に甘えない 努力家": "口が悪いけど 自分にあまえない 努力家",
  "いつも 一生懸命な": "いつも いっしょうけんめいな",
  "新しいことが 学べる本": "新しいことが 学べる本",
  "ワタシのおすすめの本、ヨンデミー！": "ワタシのおすすめの本、ヨンデミー！",
  "{{nickname}}さんと おそろいだ！\nいっしょに 音読がしたいな": "{{nickname}}さんと おそろいだ！\nいっしょに 音読がしたいな",
  "ファンタジー　芸術　不思議": "ファンタジー　芸術　不思議",
  "不思議なことが 大好きな 芸術家": "不思議なことが 大好きな 芸術家",
  "想像力あふれる": "想像力あふれる",
  "不思議な魅力が いっぱいの本": "不思議なみりょくが いっぱいの本",
  "ぼくのおすすめの本、ヨンデミー！": "ぼくのおすすめの本、ヨンデミー！",
  "おやぁ？{{nickname}}さんと 自分、似てるんだねぇ😊": "おやぁ？{{nickname}}さんと 自分、似てるんだねぇ😊",
  "哲学　自然　動物": "てつがく　自然　動物",
  "優しくて 哲学好きの のんびり屋さん": "やさしくて てつがく好きの のんびり屋さん",
  "マイペースな": "マイペースな",
  "自然の動物たちに 触れられる本や、哲学の本": "自然の動物たちに ふれられる本や、てつがくの本",
  "自分のおすすめの本、ヨンデミー！": "自分のおすすめの本、ヨンデミー！",
  "モコォ/// {{nickname}}さんと 同じで 嬉しいです": "モコォ/// {{nickname}}さんと 同じで うれしいです",
  "ほっこり　カワイイ　日常モノ": "ほっこり　カワイイ　日常モノ",
  "おとなしくて かわいいもの好きの 気遣い屋さん": "おとなしくて かわいいもの好きの きづかい屋さん",
  "心優しい": "心やさしい",
  "ほっこりできる 日常の本": "ほっこりできる 日常の本",
  "うちのおすすめの本、ヨンデミー！": "うちのおすすめの本、ヨンデミー！",
  "オレと {{nickname}}さんが 同じ……悪くないな": "オレと {{nickname}}さんが 同じ……悪くないな",
  "詩・ポエム　愛　孤独": "詩・ポエム　愛　こどく",
  "気高くて 優しい 一匹狼": "気高くて やさしい いっぴきおおかみ",
  "クールな": "クールな",
  "外の世界との 関わりについて 考えられる本": "外の世界との かかわりについて 考えられる本",
  "オレのおすすめの本、ヨンデミー！": "オレのおすすめの本、ヨンデミー！",
  "ボクと {{nickname}}さん、気が合いそうじゃん👍": "ボクと {{nickname}}さん、気が合いそうじゃん👍",
  "友達　エッセイ　多様性": "ともだち　エッセイ　多様性",
  "自分も 友達も 大切にする しっかりさん": "自分も ともだちも 大切にする しっかりさん",
  "やりたいことに 真っ直ぐな": "やりたいことに まっすぐな",
  "いろんな生き方を えがいた本": "いろんな生き方を えがいた本",
  "ホッホッホッ、\nワシと {{nickname}}さんは ズッ友じゃな！": "ホッホッホッ、\nワシと {{nickname}}さんは ズッ友じゃな！",
  "笑える　昔話　ユーモア": "笑える　昔話　ユーモア",
  "面白いことが 大好きな おじいちゃん": "おもしろいことが 大好きな おじいちゃん",
  "お茶目な": "お茶目な",
  "つい笑顔になれちゃう ユーモアたっぷりの本": "ついえがおになれちゃう ユーモアたっぷりの本",
  "ワシのおすすめの本、ヨンデミー！": "ワシのおすすめの本、ヨンデミー！",
  "本生物にたとえるなら......": "本生物にたとえるなら......",
  "おすすめ理由": "おすすめ理由",
  "と 仲良くなれそう！": "と 仲良くなれそう！",
  "こんな本たちを おすすめするよ。": "こんな本たちを おすすめするよ。",
  "他にも いろんな本を おすすめするので、楽しみにしていてね😉": "ほかにも いろんな本を おすすめするので、楽しみにしていてね😉",
  "診断結果をとじる": "しんだん結果をとじる",
  "答えてくれてありがとう！\nふむふむ……{{ nickname }}さんのキャラクタータイプは…？": "答えてくれてありがとう！\nふむふむ……{{ nickname }}さんのキャラクタータイプは…？",
  "こんにちは！\nおすすめジャンル診断へようこそ！": "こんにちは！\nおすすめジャンルしんだんへようこそ！",
  "これからヨンデミー先生が{{nickname}}さんに質問をするよ！\n自分に一番近いものを選んで答えてね😉": "これからヨンデミー先生が{{nickname}}さんに質問をするよ！\n自分に一番近いものを選んで答えてね😉",
  "すると、自分に近いヨンデミーキャラクターがわかるんだ！\nそのキャラクターのおすすめも、おすすめの本に入るようになるよ": "すると、自分に近いヨンデミーキャラクターがわかるんだ！\nそのキャラクターのおすすめも、おすすめの本に入るようになるよ",
  "誰と近いタイプなのか、ワクワクするね！\nそれじゃあ早速始めるよ！": "だれと近いタイプなのか、ワクワクするね！\nそれじゃあさっそく始めるよ！",
  "YL相談をやめる": "YL相談をやめる",
  "相談を続ける": "相談を続ける",
  "相談をやめますか？": "相談をやめますか？",
  "ジャンル診断をやめますか？": "ジャンルしんだんをやめますか？",
  "診断をやめる": "しんだんをやめる",
  "診断を続ける": "しんだんを続ける",
  "YL相談所へ ようこそ！\nヨンデミー先生と 一緒に\n{{ nickname }}さんに\nぴったりの YLを 探してみよう": "YL相談所へ ようこそ！\nヨンデミー先生と いっしょに\n{{ nickname }}さんに\nぴったりの YLを さがしてみよう",
  "今の おすすめの 本は\nかんたん？\nそれとも、むずかしい？": "今の おすすめの 本は\nかんたん？\nそれとも、むずかしい？",
  "パッと 文章を 見て、\nかんたんか むずかしいか\n教えてね！": "パッと 文章を 見て、\nかんたんか むずかしいか\n教えてね！",
  "ふむふむ、なるほど……\n{{ nickname }}さんに\nぴったりの YLを見つけたよ\nおすすめの本を 探しておくね": "ふむふむ、なるほど……\n{{ nickname }}さんに\nぴったりの YLを見つけたよ\nおすすめの本を さがしておくね",
  "うまく受け取れませんでした\nもう一度、ボタンを押してね": "うまく受け取れませんでした\nもう一度、ボタンをおしてね",
  "本を削除しました。YLが合わないときは、\nYL相談をおすすめします。": "本をさくじょしました。YLが合わないときは、\nYL相談をおすすめします。",
  "相談する": "相談する",
  "答えてくれてありがとう！": "答えてくれてありがとう！",
  "この文章は どうだった？": "この文章は どうだった？",
  "YL相談所へようこそ！\nヨンデミー先生と一緒に{{ nickname }}さんが読む本を探していこう！": "YL相談所へようこそ！\nヨンデミー先生といっしょに{{ nickname }}さんが読む本をさがしていこう！",
  "ヨンデミー先生は、{{ nickname }}さんのことを知ることで、よりパワーアップするんだ！": "ヨンデミー先生は、{{ nickname }}さんのことを知ることで、よりパワーアップするんだ！",
  "ヨンデミー先生とお話ししてくれるかな？": "ヨンデミー先生とお話ししてくれるかな？",
  "ありがとう！\nそれじゃあ、これから出す質問に答えてね。": "ありがとう！\nそれじゃあ、これから出す質問に答えてね。",
  "たくさん質問に答えてくれてありがとう！\n{{ nickname }}さんのこと、たくさん知れて嬉しいな！": "たくさん質問に答えてくれてありがとう！\n{{ nickname }}さんのこと、たくさん知れてうれしいな！",
  "パワーアップしたおすすめの本、きっと面白いものがたくさんあるから、ぜひ読んでみてね！": "パワーアップしたおすすめの本、きっとおもしろいものがたくさんあるから、ぜひ読んでみてね！",
  "まだおすすめ・よみま書架の本がないよ。ヨンデミー先生のおすすめを待っててね。": "まだおすすめ・よみま書架の本がないよ。ヨンデミー先生のおすすめを待っててね。",
  "お手紙が届いているよ👀✨": "お手紙がとどいているよ👀✨",
  "開けてみる": "開けてみる",
  "読書家の{{nickname}}さんへ": "読書家の{{nickname}}さんへ",
  "{{age}}才の": "{{age}}才の",
  "お誕生日、おめでとう🎊": "おたんじょう日、おめでとう🎊",
  "お話しするのは 照れちゃうから、": "お話しするのは 照れちゃうから、",
  "お手紙で 気持ちを 伝えるよ😳": "お手紙で 気持ちを 伝えるよ😳",
  "{{nickname}}さんと 出会って": "{{nickname}}さんと 出会って",
  "もう{{period}}日だね✨": "もう{{period}}日だね✨",
  "まだ 出会ったばかり だけど": "まだ 出会ったばかり だけど",
  "{{nickname}}さんの": "{{nickname}}さんの",
  "素敵なところ たくさん 見つけたよ": "すてきなところ たくさん 見つけたよ",
  "とっても 楽しかったよ！": "とっても 楽しかったよ！",
  "これからも、": "これからも、",
  "{{nickname}}さんと一緒に": "{{nickname}}さんといっしょに",
  "たくさん 本を 読みたいな🥰": "たくさん 本を 読みたいな🥰",
  "感想を": "感想を",
  "楽しみに 待っているね👀✨": "楽しみに 待っているね👀✨",
  "{{nickname}}さんのことが大好きな": "{{nickname}}さんのことが大好きな",
  "ヨンデミー先生より": "ヨンデミー先生より",
  "読んだ本はどんどん感想をかいて、自分専用の本だなを作ろう！": "読んだ本はどんどん感想をかいて、自分せんようの本だなを作ろう！",
  "自分で選んだ本": "自分で選んだ本",
  "すべての本": "すべての本",
  "ヨンデミー先生から お手紙が 来ています！": "ヨンデミー先生から お手紙が きています！",
  "{{user}}さん、こんにちは！\n会えて 嬉しいな😊\n今日の お話は これ！": "{{user}}さん、こんにちは！\n会えて うれしいな😊\nきょうの お話は これ！",
  "こんにちは！": "こんにちは！",
  "お話できて 楽しかったよ！\nこのまま ミニレッスンも 受けちゃう？": "お話できて 楽しかったよ！\nこのまま ミニレッスンも 受けちゃう？",
  "ミニレッスンを 受ける": "ミニレッスンを 受ける",
  "あとにする": "あとにする",
  "それじゃあ、今日も 本の世界を 楽しんでね😊": "それじゃあ、きょうも 本の世界を 楽しんでね😊",
  "はーい！": "はーい！",
  "また後で！待ってるね！": "また後で！待ってるね！",
  "ヨンデミーの心得　その1": "ヨンデミーの心得　その1",
  "読まなくてもよし！": "読まなくてもよし！",
  "読みたい 本を 読みたい ときに 楽しんで 読もう✨": "読みたい 本を 読みたい ときに 楽しんで 読もう✨",
  "読みたいときに読む！": "読みたいときに読む！",
  "ヨンデミーの心得　その2": "ヨンデミーの心得　その2",
  "自分で選んでもよし！": "自分で選んでもよし！",
  "{{user}}さんが 選んだ 本を ヨンデミー先生に 教えてね。": "{{user}}さんが 選んだ 本を ヨンデミー先生に 教えてね。",
  "自分でも選ぶ！": "自分でも選ぶ！",
  "ヨンデミーの心得　その3": "ヨンデミーの心得　その3",
  "簡単な 本も 楽しんでよし！": "かんたんな 本も 楽しんでよし！",
  "簡単な 本を 深く 楽しむのも 立派な 読書家だよ": "かんたんな 本を 深く 楽しむのも りっぱな 読書家だよ",
  "簡単な本も 楽しむ！": "かんたんな本も 楽しむ！",
  "ヨンデミーの心得　その4": "ヨンデミーの心得　その4",
  "途中で 感想を 出してもよし！": "とちゅうで 感想を 出してもよし！",
  "1日で 読み切らなくても、途中までの 感想を 教えてくれると 嬉しいな": "1日で 読み切らなくても、とちゅうまでの 感想を 教えてくれると うれしいな",
  "途中でも 出していい！": "とちゅうでも 出していい！",
  "{{user}}さんは、いつも どんなときに 本を 読んでいるかな?\n夜 寝る前、ご飯の後 など、好きな タイミングで": "{{user}}さんは、いつも どんなときに 本を 読んでいるかな?\n夜 ねる前、ご飯の後 など、好きな タイミングで",
  "毎日の 読書時間": "毎日の 読書時間",
  "を 作ってみよう✨": "を 作ってみよう✨",
  "ふむふむ": "ふむふむ",
  "レベル": "レベル",
  "は {{user}}さんの 読書家としての 頑張りが 積み重なって いくもの！\nレベル100まで いけるかな？": "は {{user}}さんの 読書家としての がんばりが 積み重なって いくもの！\nレベル100まで いけるかな？",
  "いくぞー！": "いくぞー！",
  "ヨンデミーレベル（YL）": "ヨンデミーレベル（YL）",
  "っていうのは、本の 難しさ のことだよ！\n{{user}}さんに ピッタリの ヨンデミーレベルの 本を 見つけてくるから、楽しみに していてね": "っていうのは、本の むずかしさ のことだよ！\n{{user}}さんに ピッタリの ヨンデミーレベルの 本を 見つけてくるから、楽しみに していてね",
  "なるほど！": "なるほど！",
  "ヨンデミー先生は いつも、": "ヨンデミー先生は いつも、",
  "{{user}}さんの 感想をもとに": "{{user}}さんの 感想をもとに",
  "、次の おすすめの 本を 探しているよ✨": "、次の おすすめの 本を さがしているよ✨",
  "そうなんだ": "そうなんだ",
  "{{user}}さんに ぴったりの 本を おすすめするために、": "{{user}}さんに ぴったりの 本を おすすめするために、",
  "自分の 気持ちに 合った 感想を 選ぶ": "自分の 気持ちに 合った 感想を 選ぶ",
  "ようにしてね😉": "ようにしてね😉",
  "はーい🙌": "はーい🙌",
  "今日は『": "きょうは『",
  "』を 読むって 言っていたね😊": "』を 読むって 言っていたね😊",
  "感想を 楽しみに 待ってるよ🙌": "感想を 楽しみに 待ってるよ🙌",
  "読む💪": "読む💪",
  "やったー😆": "やったー😆",
  "昨日 読んでいた『{{title}}』の {{user}}さんの 感想を 見ました！\nとっても {{review}}よ！\n今日も、本の 感想を 聞かせてくれると 嬉しいな😊": "きのう 読んでいた『{{title}}』の {{user}}さんの 感想を 見ました！\nとっても {{review}}よ！\nきょうも、本の 感想を 聞かせてくれると うれしいな😊",
  "素敵だった": "すてきだった",
  "面白かった": "おもしろかった",
  "楽しかった": "楽しかった",
  "共感できた": "共感できた",
  "やったー😀": "やったー😀",
  "今日は どの本を 読みたい？": "きょうは どの本を 読みたい？",
  "好きな 本を 選んで 読んでみよう👀": "好きな 本を 選んで 読んでみよう👀",
  "{{user}}さんが 選んだ本、おもしろそう！": "{{user}}さんが 選んだ本、おもしろそう！",
  "ぜひ、感想を 聞かせてね😉": "ぜひ、感想を 聞かせてね😉",
  "君の力で、図書館を助けよう！": "君の力で、図書館を助けよう！",
  "ストーリーをもう一度見る": "ストーリーをもう一度見る",
  "出そう": "出そう",
  "出した": "出した",
  "やろう": "やろう",
  "やった": "やった",
  "書こう": "書こう",
  "書いた": "書いた",
  "感想連続日数": "感想連続日数",
  "で出そう": "で出そう",
  "で出した": "で出した",
  "いいね": "いいね",
  "しよう": "しよう",
  "した": "した",
  "もらおう": "もらおう",
  "もらった": "もらった",
  "ミニレッスンを受けると、スタンプがたまるよ！": "ミニレッスンを受けると、スタンプがたまるよ！",
  "今日のミニレッスンを受ける": "きょうのミニレッスンを受ける",
  "スタンプを押すね！": "スタンプをおすね！",
  "やった😆": "やった😆",
  "わーい😊": "わーい😊",
  "明日も ミニレッスンを 受けると スタンプが 貯まっていくよ📚✨": "あしたも ミニレッスンを 受けると スタンプが たまっていくよ📚✨",
  "貯めたい！✨": "ためたい！✨",
  "がんばるぞ💪": "がんばるぞ💪",
  "{{nickname}}さん、このまま本も読んじゃう？": "{{nickname}}さん、このまま本も読んじゃう？",
  "読む！": "読む！",
  "後でね！": "後でね！",
  "それじゃあ、本の世界へ出発📚✨": "それじゃあ、本の世界へ出発📚✨",
  "出発！": "出発！",
  "それじゃあ、本の世界で待ってるよ👋": "それじゃあ、本の世界で待ってるよ👋",
  "わかった！": "わかった！",
  "おすすめの本にない本の 感想も 送ることができるよ！": "おすすめの本にない本の 感想も 送ることができるよ！",
  "右下の えんぴつのボタンを 押してみよう": "右下の えんぴつのボタンを おしてみよう",
  "ヨンデミー先生から 本のおすすめが 届くのを 待ってね！": "ヨンデミー先生から 本のおすすめが とどくのを 待ってね！",
  "選んだ本をよみま書架から消しますか？": "選んだ本をよみま書架から消しますか？",
  "消す": "消す",
  "「本の友」で他の人の感想を読んで、よみま書架に入れた本だよ": "「本の友」でほかの人の感想を読んで、よみま書架に入れた本だよ",
  "まだよみま書架に本がないよ": "まだよみま書架に本がないよ",
  "本の友に行って": "本の友に行って",
  "他の人の感想を見てみよう": "ほかの人の感想を見てみよう",
  "本の友を見る": "本の友を見る",
  "感想を書く": "感想を書く",
  "{{user}}さんのために本を探してきたよ！\n読んでもいいか、教えてね！": "{{user}}さんのために本をさがしてきたよ！\n読んでもいいか、教えてね！",
  "😆 読みたい！": "😆 読みたい！",
  "🙂 読んでもいいよ": "🙂 読んでもいいよ",
  "🤨 びみょうかなぁ": "🤨 びみょうかなぁ",
  "ぶんしょうで書いてみる": "ぶんしょうで書いてみる",
  "ももたろうが動物たちにきびだんごをあげるところ": "ももたろうが動物たちにきびだんごをあげるところ",
  "すでに ヨンデミー先生に 提出した 感想を 書き直すことが できます！": "すでに ヨンデミー先生に 提出した 感想を 書き直すことが できます！",
  "お気に入りのシーン": "お気に入りのシーン",
  "これでOK": "これでOK",
  "あと{{rest}}冊": "あと{{rest}}さつ",
  "もうすぐ次のおすすめが届くよ。待っててね！": "もうすぐ次のおすすめがとどくよ。待っててね！",
  "おすすめの本の": "おすすめの本の",
  "残りが{{border}}冊": "残りが{{border}}さつ",
  "になったら、次のおすすめが届くよ。": "になったら、次のおすすめがとどくよ。",
  "おすすめの本が届いたよ": "おすすめの本がとどいたよ",
  "届いた本を開ける": "とどいた本を開ける",
  "読んでいるとちゅう": "読んでいるとちゅう",
  "まだ読んでない": "まだ読んでない",
  "あと{{rest}}冊だよ！！": "あと{{rest}}さつだよ！！",
  "もうすぐ おすすめの本がとどきます。🎉": "もうすぐ おすすめの本がとどきます。🎉",
  "しばらくすると 新しい本が届きます！": "しばらくすると 新しい本がとどきます！",
  "少々お待ちください！": "少々お待ちください！",
  "おすすめの本の残りが{{border}}冊になったら、次のおすすめが届きます！": "おすすめの本の残りが{{border}}さつになったら、次のおすすめがとどきます！",
  "くわしく見る": "くわしく見る",
  "新着": "新着",
  "ヨンデミー先生からおすすめされた本だよ！": "ヨンデミー先生からおすすめされた本だよ！",
  "本を予約・管理する": "本を予約・管理する",
  "YL相談所": "YL相談所",
  "本を消したよ！\n": "本を消したよ！\n",
  "あ、それからもう1つ！\n": "あ、それからもう1つ！\n",
  "\n好みが合わないときは、": "\n好みが合わないときは、",
  "ジャンル心理テスト": "ジャンル心理テスト",
  "を受けてみよう👀✨": "を受けてみよう👀✨",
  "ジャンル心理テストを受ける": "ジャンル心理テストを受ける",
  "メニューの「選書を調整」から受けることもできるよ😉": "メニューの「選書を調整」から受けることもできるよ😉",
  "レベルアップ！": "レベルアップ！",
  "この調子！": "この調子！",
  "{{ nickname }}さんに、\nぜひ この 本たちを 助けてほしい！🤝✨": "{{ nickname }}さんに、\nぜひ この 本たちを 助けてほしい！🤝✨",
  "図書館で 予約するか、本屋さんで 買ってみてね👀✨": "図書館で 予約するか、本屋さんで 買ってみてね👀✨",
  "次の本も、楽しんで 読んでね！🤝": "次の本も、楽しんで 読んでね！🤝",
  "{{nickname}}さん、お待たせしました！\n\n新しくおすすめをえらんだよ😆": "{{nickname}}さん、お待たせしました！\n\n新しくおすすめをえらんだよ😆",
  "ヨンデミー先生は まだまだたくさんの本を 知っているよ💪\n\n{{nickname}}さんに ピッタリの本を、たっぷり考えてみました😊": "ヨンデミー先生は まだまだたくさんの本を 知っているよ💪\n\n{{nickname}}さんに ピッタリの本を、たっぷり考えてみました😊",
  "ヨンデミー先生は、「きっと{{nickname}}さんにピッタリだ！」って 思ってたんだ😊\n\n「{{like}}」って 教えてくれて、とてもうれしかった！": "ヨンデミー先生は、「きっと{{nickname}}さんにピッタリだ！」って 思ってたんだ😊\n\n「{{like}}」って 教えてくれて、とてもうれしかった！",
  "この本が「{{like}}」ってことは……。\n\n次も とっておきの本を 思いついちゃったよ😎✨": "この本が「{{like}}」ってことは……。\n\n次も とっておきの本を 思いついちゃったよ😎✨",
  "この本は、{{nickname}}さんにとって 「{{feeling}}」な本だったんだね😊": "この本は、{{nickname}}さんにとって 「{{feeling}}」な本だったんだね😊",
  "{{ nickname }}さんにいのちを吹き込まれて とってもうれしそうだったよ💞": "{{ nickname }}さんにいのちをふきこまれて とってもうれしそうだったよ💞",
  "{{nickname}}さん、{{day}}読んだ『{{title}}』のことは 覚えている？👀✨": "{{nickname}}さん、{{day}}読んだ『{{title}}』のことは 覚えている？👀✨",
  "きっと {{ nickname }}さんに ピッタリだと 思っていたから、\n{{ like }}って 教えてくれて、とてもうれしかったよ！🥰": "きっと {{ nickname }}さんに ピッタリだと 思っていたから、\n{{ like }}って 教えてくれて、とてもうれしかったよ！🥰",
  "{{nickname}}さん、前回のおすすめには「好き」って思える本がなかったみたいだね🤔": "{{nickname}}さん、前回のおすすめには「好き」って思える本がなかったみたいだね🤔",
  "でも 大丈夫😊\n\nもう一度、{{nickname}}さんにピッタリの本を 考えてみました😃": "でも だいじょうぶ😊\n\nもう一度、{{nickname}}さんにピッタリの本を 考えてみました😃",
  "でもね！\n{{ nickname }}さんの おかげで、たくさんの 本たちが 元気を取り戻したんだ📚✨": "でもね！\n{{ nickname }}さんの おかげで、たくさんの 本たちが 元気をとりもどしたんだ📚✨",
  "さて、次のおすすめは……😏": "さて、次のおすすめは……😏",
  "さて、そんな{{nickname}}さんへおすすめする本は……😏": "さて、そんな{{nickname}}さんへおすすめする本は……😏",
  "{{nickname}}さん、お待たせしました！\n\n{{nickname}}さんに とっておきの本を おすすめするよ😆": "{{nickname}}さん、お待たせしました！\n\n{{nickname}}さんに とっておきの本を おすすめするよ😆",
  "さっきはヨンデミー先生に いろいろなことを 教えてくれて、ありがとう！\n\nおかげで、{{nickname}}さんには どんな本がぴったりかなー？と、楽しく考えることが できました😌": "さっきはヨンデミー先生に いろいろなことを 教えてくれて、ありがとう！\n\nおかげで、{{nickname}}さんには どんな本がぴったりかなー？と、楽しく考えることが できました😌",
  "苦手": "苦手",
  "好きではない": "好きではない",
  "もう一度読みたい": "もう一度読みたい",
  "今日": "きょう",
  "昨日": "きのう",
  "一昨日": "一昨日",
  "{{num}}日前に": "{{num}}日前に",
  "冒険のはじまり": "ぼうけんのはじまり",
  "おもいえがくのワザ": "おもいえがくのワザ",
  "新たな仲間たち": "新たな仲間たち",
  "つなげるのワザ": "つなげるのワザ",
  "ピンクのキリン": "ピンクのキリン",
  "しつもんするのワザ": "しつもんするのワザ",
  "一匹オオカミ": "一ぴきオオカミ",
  "図書館に力を送るんだ": "図書館に力を送るんだ",
  "出して、": "出して、",
  "ミニレッスン": "ミニレッスン",
  "受けて、": "受けて、",
  "書いて、": "書いて、",
  "で出して、": "で出して、",
  "して、": "して、",
  "もらって、": "もらって、",
  "音が流れるから": "音が流れるから",
  "音声をオンにしてね！": "音声をオンにしてね！",
  "伝説の読書家": "伝説の読書家",
  "目指して": "目指して",
  "挑戦": "ちょうせん",
  "それじゃあ、明日から\n次のヨンデミッションがはじまるよ！\nお楽しみに〜": "それじゃあ、あしたから\n次のヨンデミッションがはじまるよ！\nお楽しみに～",
  "のアイコンが使えるようになったよ🎉": "のアイコンが使えるようになったよ🎉",
  "音声の 再生が 許可されて いないようです。": "音声の 再生が 許可されて いないようです。",
  "音声を 出して もう一度 見てみてね。": "音声を 出して もう一度 見てみてね。",
  "保護者のかたと 一緒に見てね！": "保護者のかたと いっしょに見てね！",
  "本の友にいく！": "本の友にいく！",
  "新しく 使えるようになった「本の友」に 行ってみる？": "新しく 使えるようになった「本の友」に 行ってみる？",
  "なにやら 本の虫が 本の友で 感想を 出しているみたい……": "なにやら 本の虫が 本の友で 感想を 出しているみたい……",
  "本の友に 一緒に 行ってみよう！": "本の友に いっしょに 行ってみよう！",
  "本の虫の 感想があるね！": "本の虫の 感想があるね！",
  "❤️ いいねを 送ってみよう！": "❤️ いいねを 送ってみよう！",
  "いいねが 送れたよ！": "いいねが 送れたよ！",
  "本の虫に 気持ちが 伝わったね！": "本の虫に 気持ちが 伝わったね！",
  "本の虫からも {{user}}さんの 感想に いいねが 届いているみたい……。": "本の虫からも {{user}}さんの 感想に いいねが とどいているみたい……。",
  "通知を 開いてみよう！": "通知を 開いてみよう！",
  "本の友へ ようこそ！": "本の友へ ようこそ！",
  "この調子で、他の友達の 感想にも いいねを 送ってみよう！": "この調子で、ほかのともだちの 感想にも いいねを 送ってみよう！",
  "きっと 喜んでもらえるよ✨": "きっと 喜んでもらえるよ✨",
  "本の友にいく": "本の友にいく",
  "「感想」のヒミツを教えます😎": "「感想」のヒミツを教えます😎",
  "{{ nickname }}さんが 感想を たくさん送ってくれるほど、": "{{ nickname }}さんが 感想を たくさん送ってくれるほど、",
  "{{ nickname }}さんに ピッタリの本を おすすめできるように なります😊": "{{ nickname }}さんに ピッタリの本を おすすめできるように なります😊",
  "「苦手だなぁ」と思った 本の感想も、そのまま 教えてください！": "「苦手だなぁ」と思った 本の感想も、そのまま 教えてください！",
  "「苦手」もステキな{{ nickname }}さんの感想です😊": "「苦手」もステキな{{ nickname }}さんの感想です😊",
  "途中までしか 読めなかった日も 出して大丈夫！": "とちゅうまでしか 読めなかった日も 出してだいじょうぶ！",
  "毎日 感想を出すと、バッジがもらえるかも…？": "毎日 感想を出すと、バッジがもらえるかも…？",
  "それじゃあ、さっそく 感想を 書いてみよう！": "それじゃあ、さっそく 感想を 書いてみよう！",
  "{{ nickname }}さんの 感想、とっても 楽しみに しているよ😊": "{{ nickname }}さんの 感想、とっても 楽しみに しているよ😊",
  "感想を 書く前に、": "感想を 書く前に、",
  "ヨンデミー先生から {{ nickname }}さんに お願いがあるんだ！": "ヨンデミー先生から {{ nickname }}さんに お願いがあるんだ！",
  "なになに👀": "なになに👀",
  "{{ nickname }}さんが 本を 読んで、思ったことは そのまま 教えてね👍": "{{ nickname }}さんが 本を 読んで、思ったことは そのまま 教えてね👍",
  "「難しい」とか「苦手」も ステキな 感想です😊": "「むずかしい」とか「苦手」も ステキな 感想です😊",
  "まかせて！": "まかせて！",
  "{{ nickname }}さんが 感想を 教えてくれると、": "{{ nickname }}さんが 感想を 教えてくれると、",
  "ヨンデミー先生は もっとピッタリの 本をおすすめできるようになるよ😎✨": "ヨンデミー先生は もっとピッタリの 本をおすすめできるようになるよ😎✨",
  "本たちも、": "本たちも、",
  "{{ nickname }}さんの 感想を 待っているよ📚💞": "{{ nickname }}さんの 感想を 待っているよ📚💞",
  "それじゃあ、さっそく 感想を 書いてみよう💪": "それじゃあ、さっそく 感想を 書いてみよう💪",
  "感想を 書く": "感想を 書く",
  "感想を 書く！": "感想を 書く！",
  "記録": "記録",
  "記録を見る": "記録を見る",
  "連続": "連続",
  "個": "個",
  "日": "日",
  "提出": "提出",
  "編集する": "編集する",
  "ここは　カギが　かかっていて\n使えないよ。": "ここは　カギが　かかっていて\n使えないよ。",
  "{{count}}回": "{{count}}回",
  "出したら": "出したら",
  "カギがあくみたい……": "カギがあくみたい……",
  "{{ user }}さん、\n約束通りに 感想をとどけてくれてありがとう！さすがだね😆✨": "{{ user }}さん、\n約束通りに 感想をとどけてくれてありがとう！さすがだね😆✨",
  "{{ user }}さん、\n感想をとどけてくれてありがとう！": "{{ user }}さん、\n感想をとどけてくれてありがとう！",
  "{{ user }}さん、『{{ title }}』はほかの子に 託したんだね！✨": "{{ user }}さん、『{{ title }}』はほかの子に たくしたんだね！✨",
  "感想を 教えてくれて、ありがとう🤗\nヨンデミー先生も、その本を 読んでみたく なっちゃった👀✨": "感想を 教えてくれて、ありがとう🤗\nヨンデミー先生も、その本を 読んでみたく なっちゃった👀✨",
  "すてきな 本を 教えてくれて、うれしいな🥰\nこれからも 感想を 聞かせてね🙌": "すてきな 本を 教えてくれて、うれしいな🥰\nこれからも 感想を 聞かせてね🙌",
  "さすが {{ user }}さん！\nワクワクする とっても すてきな 感想だったよ😆✨": "さすが {{ user }}さん！\nワクワクする とっても すてきな 感想だったよ😆✨",
  "新しい 本を 見つけると ワクワクするよね😊\nこれからも {{ user }}さんの 感想が 楽しみだ〜！": "新しい 本を 見つけると ワクワクするよね😊\nこれからも {{ user }}さんの 感想が 楽しみだ～！",
  "次は どの 本を たすけてくれるかな？✨": "次は どの 本を たすけてくれるかな？✨",
  "スキップする": "スキップする",
  "{{ user }}さんがどんな本を選ぶのか、楽しみです！\nいつ感想をとどけてくれるかな👀✨\n約束の時間を一緒に決めよう": "{{ user }}さんがどんな本を選ぶのか、楽しみです！\nいつ感想をとどけてくれるかな👀✨\n約束の時間をいっしょに決めよう",
  "『{{ nextbook }}』を読むんだね！\nいつ感想をとどけてくれるかな👀✨\n約束の時間を一緒に決めよう": "『{{ nextbook }}』を読むんだね！\nいつ感想をとどけてくれるかな👀✨\n約束の時間をいっしょに決めよう",
  "次はいつ感想をとどけてくれるかな👀✨\n約束の時間を一緒に決めよう": "次はいつ感想をとどけてくれるかな👀✨\n約束の時間をいっしょに決めよう",
  "なんと！\n読書家パワー全開だね!\n感想を楽しみに待ってるよ👀✨": "なんと！\n読書家パワー全開だね!\n感想を楽しみに待ってるよ👀✨",
  "{{dueDateMessage}}": "{{dueDateMessage}}",
  "も感想を届けてくれるんだね\n\nヨンデミー先生、楽しみにしているね😊\nまたね〜👋": "も感想をとどけてくれるんだね\n\nヨンデミー先生、楽しみにしているね😊\nまたね～👋",
  "わかった！\n教えてくれて ありがとう😊\n\n{{ user }}さんの次の感想も 楽しみにしてるよ📚✨": "わかった！\n教えてくれて ありがとう😊\n\n{{ user }}さんの次の感想も 楽しみにしてるよ📚✨",
  "わかった👍": "わかった👍",
  "今はしない！": "今はしない！",
  "本当に約束しなくてもいい？": "本当に約束しなくてもいい？",
  "今回はしない！": "今回はしない！",
  "やっぱり約束する💪": "やっぱり約束する💪",
  "今日はもっと読む": "きょうはもっと読む",
  "明後日": "あさって",
  "明々後日": "しあさって",
  "他の子の 感想を送ります！　ぜひ読んでみてね♪": "ほかの子の 感想を送ります！　ぜひ読んでみてね♪",
  "自分の感想": "自分の感想",
  "感想を追加する": "感想を追加する",
  "次は": "次は",
  "目指そう！": "目指そう！",
  "何が見られるようになるかな......？": "何が見られるようになるかな......？",
  "一言感想が書けるようになったよ🎉": "一言感想が書けるようになったよ🎉",
  "みんなの感想が見られるようになったよ🎉": "みんなの感想が見られるようになったよ🎉",
  "みんなの感想にいいねができるようになったよ🎉": "みんなの感想にいいねができるようになったよ🎉",
  "記録をシェアできるよ🎉": "記録をシェアできるよ🎉",
  "スターを送れるよ🎉": "スターを送れるよ🎉",
  "君の 読みたい本 専用の 本棚だよ": "君の 読みたい本 せんようの ほんだなだよ",
  "よみま書架": "よみま書架",
  "使えるよ": "使えるよ",
  "表示": "表示",
  "隠す": "かくす",
  "読まなかった": "読まなかった",
  "途中": "とちゅう",
  "もう一度ボタンを押してください。": "もう一度ボタンをおしてください。"
};