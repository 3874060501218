import { MissionStorySetting } from "../type";

const baseDir = "/img/mission/start_story/story_2";

export const StartStory2: MissionStorySetting[] = [
  {
    image: `${baseDir}/scene_1.jpg`,
    sound: "start2_scene1",
    text: "エレベーターが開きました。ギギギギギ。\nドキドキワクワクしながらエレベーターの中に入っていきます。",
    ruby: [
      ...Array(7).fill(""),
      "ひら",
      ...Array(31).fill(""),
      "なか",
      "",
      "はい",
      ...Array(7).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_2.jpg`,
    sound: "start2_scene2",
    text: "どんどんどんどん上へ。エレベーターは上がっていきます。",
    ruby: [
      ...Array(8).fill(""),
      "うえ",
      ...Array(9).fill(""),
      "あ",
      ...Array(8).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_3.jpg`,
    sound: "start2_scene3",
    text: "次の階についたようです。",
    ruby: ["つぎ", "", "かい", ...Array(9).fill("")],
  },
  {
    image: `${baseDir}/scene_4.jpg`,
    sound: "start2_scene4",
    text: "エレベーターを出ると︙︙\nその不思議な部屋には一冊の本が。",
    ruby: [
      ...Array(7).fill(""),
      "で",
      ...Array(7).fill(""),
      "ふ",
      "し",
      "ぎ",
      "",
      "へ",
      "や",
      ...Array(2).fill(""),
      "いっ",
      "さつ",
      "",
      "ほん",
      ...Array(2).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_5.jpg`,
    sound: "start2_scene5",
    text: "ゆっくり本に近づいてみると、表紙には「おもいえがく」と書かれています。",
    ruby: [
      ...Array(4).fill(""),
      "ほん",
      "",
      "ちか",
      ...Array(7).fill(""),
      "ひょう",
      "し",
      ...Array(11).fill(""),
      "か",
      ...Array(7).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_6.jpg`,
    sound: "start2_scene6_1",
    text: "「これが、どくしょかのワザの本︙︙？\nでもどうやったらスターが手に入るんだ？」",
    ruby: [
      ...Array(14).fill(""),
      "ほん",
      ...Array(16).fill(""),
      "て",
      "",
      "はい",
      ...Array(5).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_6.jpg`,
    sound: "start2_scene6_2",
    text: "すると本の虫がぴょんぴょん、この本を開こうとしています。",
    ruby: [
      ...Array(3).fill(""),
      "ほん",
      "",
      "むし",
      ...Array(10).fill(""),
      "ほん",
      "",
      "ひら",
      ...Array(9).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_7.jpg`,
    sound: "start2_scene7",
    text: "本を開いてみると︙︙？\nぶわわわわわ〜ん！",
    ruby: ["ほん", "", "ひら", ...Array(17).fill("")],
  },
  {
    image: `${baseDir}/scene_8.jpg`,
    sound: "start2_scene8",
    text: "なんと、目の前が真っ白なキャンバスの世界になりました！　辺り一面真っ白です。\nしばらくすると、いろいろな文字が浮かんできました。",
    ruby: [
      ...Array(4).fill(""),
      "め",
      "",
      "まえ",
      "",
      "ま",
      "",
      "しろ",
      ...Array(7).fill(""),
      "せ",
      "かい",
      ...Array(8).fill(""),
      "あた",
      "",
      "いち",
      "めん",
      "ま",
      "",
      "しろ",
      ...Array(17).fill(""),
      "も",
      "じ",
      "",
      "う",
      ...Array(8).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_9.jpg`,
    sound: "start2_scene9_1",
    text: "どくしょかのしょから、一本の筆が出てきました！\n「なるほど！この本を読んで、イメージを浮かべるんだね！」",
    ruby: [
      ...Array(11).fill(""),
      "いっ",
      "ぽん",
      "",
      "ふで",
      "",
      "で",
      ...Array(15).fill(""),
      "ほん",
      "",
      "よ",
      ...Array(8).fill(""),
      "う",
      ...Array(8).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_9.jpg`,
    sound: "start2_scene9_2",
    text: "「そんで、この筆でそのイメージを描けばいいってことだな！\nよぉし、任せとけ‼」",
    ruby: [
      ...Array(7).fill(""),
      "ふで",
      ...Array(8).fill(""),
      "か",
      ...Array(16).fill(""),
      "まか",
      ...Array(5).fill(""),
    ],
  },
];
