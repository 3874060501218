import React, { useCallback } from "react";
import styled from "styled-components";
import { TextButton } from "~/components/atoms/buttons/deprecatedTextButton";
import { Text } from "~/components/atoms/texts/Text";

export type WantReadBookButtonProps = {
  onClick: () => void;
  selected: boolean;
  disabled?: boolean;
};

export const WantReadBookButton: React.FC<WantReadBookButtonProps> = ({
  onClick,
  selected,
  disabled,
}) => {
  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      // MEMO: ボタンが押せない場合も押せる場合と同じデザインにする暫定対応のためclick時に発火しないようにする
      if (disabled) return;
      onClick();
    },
    [disabled]
  );

  return selected ? (
    <>
      <_WantReadBookTextButton fontSize="XS" onClick={handleClick}>
        <_IconAndTextWrapper>
          <_ShiningBook />
          <_TextAfterAdding fontSize="XS" lineHeight="EQ">
            よみま<_TextWithHurigana furigana="しょか">書架</_TextWithHurigana>
            の本
          </_TextAfterAdding>
        </_IconAndTextWrapper>
      </_WantReadBookTextButton>
    </>
  ) : (
    <_WantReadBookTextButton fontSize="XS" onClick={handleClick}>
      <_IconAndTextWrapper>
        <_PlusBook />
        <_TextBeforeAdding fontSize="XS" lineHeight="EQ">
          よみま<_TextWithHurigana furigana="しょか">書架</_TextWithHurigana>
          に入れる
        </_TextBeforeAdding>
      </_IconAndTextWrapper>
    </_WantReadBookTextButton>
  );
};

const _WantReadBookTextButton = styled(TextButton)``;

const _IconAndTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
`;

const _TextAfterAdding = styled(Text)`
  color: ${({ theme }) => theme.colors.pri.p500};
  font-weight: bold;
`;

const _TextBeforeAdding = styled(Text)`
  color: ${({ theme }) => theme.colors.tex.t300};
  font-weight: normal;
`;

const _TextWithHurigana = styled.span<{ furigana: string }>`
  position: relative;
  :before {
    content: "${({ furigana }) => furigana}";
    position: absolute;
    top: -9px;
    left: 50%;
    transform: translateX(-50%) scale(0.7);
    transform-origin: center bottom;
    text-align: center;
    font-size: 10px;
    line-height: 100%;
    white-space: nowrap;
  }
`;

const _PlusBook: React.FC = () => (
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1112_11670)">
      <path
        d="M13.3229 9.81359L7.52567 11.8374C7.43118 11.8704 7.32842 11.8704 7.23392 11.8374L1.43466 9.81359C1.20245 9.7326 0.938488 9.31363 0.938488 8.86668C0.938488 8.6467 1.00497 8.50071 1.07047 8.45372C1.07952 8.44674 1.09005 8.44195 1.10123 8.43972C1.12387 8.43752 1.1467 8.44095 1.16772 8.44972L7.04835 10.5015C7.15215 10.5374 7.26114 10.5556 7.37086 10.5555C7.48134 10.5558 7.59106 10.5372 7.69536 10.5005L13.575 8.44872C13.653 8.42152 13.7206 8.3705 13.7685 8.30275C13.8164 8.23501 13.8421 8.15392 13.8421 8.07076C13.8421 7.98759 13.8164 7.9065 13.7685 7.83875C13.7206 7.77101 13.653 7.71999 13.575 7.69279L7.33216 5.513L7.31232 5.507H7.3014L7.27064 5.5H7.26568H7.22995H7.17835H7.15751H7.14163H7.1198H7.10492L7.08209 5.506H7.07217L0.935511 7.64779C0.816494 7.6728 0.703951 7.72252 0.605058 7.79378C0.307355 8.00576 0.139648 8.39373 0.139648 8.85868C0.139648 9.62561 0.581242 10.3585 1.16573 10.5585L6.97194 12.5933C7.23483 12.6853 7.5208 12.6853 7.78368 12.5933L13.5819 10.5695C13.6312 10.5523 13.6766 10.5255 13.7156 10.4906C13.7546 10.4557 13.7864 10.4135 13.8091 10.3662C13.8319 10.3189 13.8452 10.2676 13.8483 10.2152C13.8513 10.1627 13.8441 10.1102 13.8271 10.0606C13.81 10.0109 13.7834 9.96517 13.7488 9.9259C13.7141 9.88662 13.6722 9.85461 13.6253 9.83167C13.5784 9.80874 13.5274 9.79534 13.4754 9.79224C13.4234 9.78913 13.3712 9.79639 13.322 9.81359H13.3229Z"
        fill="#828282"
      />
      <path
        d="M13.5849 2.38077H11.6518V0.43296C11.6518 0.318133 11.6066 0.208008 11.526 0.126813C11.4454 0.0456174 11.3361 0 11.2221 0H10.7925C10.6785 0 10.5692 0.0456174 10.4886 0.126813C10.408 0.208008 10.3628 0.318133 10.3628 0.43296V2.38077H8.42968C8.31572 2.38077 8.20643 2.42639 8.12585 2.50759C8.04527 2.58878 8 2.69891 8 2.81373V3.24669C8 3.36152 8.04527 3.47165 8.12585 3.55284C8.20643 3.63404 8.31572 3.67965 8.42968 3.67965H10.3628V5.62747C10.3628 5.68432 10.3739 5.74062 10.3955 5.79315C10.4171 5.84568 10.4487 5.89341 10.4886 5.93361C10.5285 5.97382 10.5759 6.00571 10.628 6.02747C10.6802 6.04922 10.736 6.06042 10.7925 6.06042H11.2221C11.2786 6.06042 11.3344 6.04922 11.3866 6.02747C11.4387 6.00571 11.4861 5.97382 11.526 5.93361C11.5659 5.89341 11.5975 5.84568 11.6191 5.79315C11.6407 5.74062 11.6518 5.68432 11.6518 5.62747V3.67565H13.5849C13.6989 3.67565 13.8082 3.63003 13.8887 3.54884C13.9693 3.46764 14.0146 3.35752 14.0146 3.24269V2.80974C14.0136 2.6956 13.9678 2.5865 13.8874 2.50617C13.8069 2.42584 13.6982 2.38077 13.5849 2.38077Z"
        fill="#828282"
      />
    </g>
    <defs>
      <clipPath id="clip0_1112_11670">
        <rect width="14" height="13" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const _ShiningBook: React.FC = () => (
  <svg
    width="19"
    height="15"
    viewBox="0 0 19 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1112_11673)">
      <path
        d="M14.6306 9.50995L9.79556 11.3897C9.71522 11.4214 9.62765 11.4214 9.54731 11.3897L4.71637 9.50995C4.52191 9.43472 4.30262 9.04559 4.30262 8.63045C4.30262 8.42613 4.35724 8.29054 4.41268 8.24689C4.4198 8.24019 4.42836 8.2357 4.43751 8.23388C4.45637 8.23241 4.47529 8.23559 4.49295 8.24317L9.4025 10.1508C9.48906 10.1841 9.57993 10.201 9.67143 10.2009C9.76378 10.2006 9.85547 10.1834 9.94285 10.1498L14.8458 8.24317C14.9108 8.21791 14.9672 8.17052 15.0071 8.1076C15.0471 8.04468 15.0685 7.96936 15.0685 7.89212C15.0685 7.81487 15.0471 7.73955 15.0071 7.67663C14.9672 7.61371 14.9108 7.56633 14.8458 7.54106L13.5549 7.03955L9.58289 8.58122L5.51077 7.03955L4.31007 7.50391C4.20844 7.52704 4.11246 7.57456 4.02872 7.64322C3.78047 7.84011 3.64062 8.20045 3.64062 8.63231C3.64062 9.34371 4.00804 10.0254 4.49626 10.2111L9.33133 12.0909C9.55055 12.1763 9.78901 12.1763 10.0082 12.0909L14.8441 10.2111C14.9271 10.1789 14.9952 10.1109 15.0336 10.0223C15.0719 9.93363 15.0773 9.83152 15.0485 9.73841C15.0198 9.64531 14.9592 9.56883 14.8802 9.52581C14.8013 9.48279 14.7103 9.47675 14.6273 9.50902L14.6306 9.50995Z"
        fill="#EE9F17"
      />
      <path
        d="M14.6306 6.42847L9.79556 8.3082C9.71522 8.33992 9.62765 8.33992 9.54731 8.3082L4.71224 6.42847C4.51777 6.35232 4.29849 5.96411 4.29849 5.54805C4.29849 5.34373 4.3531 5.20813 4.40855 5.16448C4.41577 5.15815 4.42431 5.15399 4.43337 5.15241C4.45225 5.15044 4.47127 5.15363 4.48881 5.1617L9.39174 7.06836C9.4783 7.10165 9.56918 7.1186 9.66068 7.11851C9.75298 7.11855 9.84467 7.10161 9.9321 7.06836L14.835 5.1617C14.9001 5.13644 14.9565 5.08905 14.9964 5.02613C15.0363 4.96321 15.0578 4.88788 15.0578 4.81064C15.0578 4.7334 15.0363 4.65807 14.9964 4.59515C14.9565 4.53223 14.9001 4.48485 14.835 4.45958L9.6284 2.4359L9.61185 2.43033H9.60358L9.57793 2.42383H9.57379H9.544H9.48276H9.46952H9.45132H9.43891L9.41988 2.43033C9.41713 2.42992 9.41434 2.42992 9.4116 2.43033L4.29517 4.41965C4.19857 4.44489 4.10779 4.49267 4.02872 4.55989C3.78047 4.75677 3.64062 5.11712 3.64062 5.54805C3.64062 6.26037 4.00804 6.94113 4.49626 7.12687L9.33133 9.0066C9.55073 9.09081 9.78883 9.09081 10.0082 9.0066L14.8441 7.12687C14.9271 7.0946 14.9952 7.02667 15.0336 6.93802C15.0719 6.84937 15.0773 6.74726 15.0485 6.65415C15.0198 6.56104 14.9592 6.48457 14.8802 6.44155C14.8013 6.39853 14.7103 6.39249 14.6273 6.42476L14.6306 6.42847Z"
        fill="#EE9F17"
      />
      <path
        d="M4.86656 2.70496C3.60723 3.12833 3.12631 3.60099 2.70355 4.86887C2.68576 4.90798 2.6571 4.94113 2.62101 4.96438C2.58492 4.98763 2.54291 5 2.49999 5C2.45708 5 2.41507 4.98763 2.37898 4.96438C2.34289 4.94113 2.31424 4.90798 2.29645 4.86887C1.87369 3.60771 1.40171 3.12833 0.133426 2.70496C0.0937333 2.68743 0.0599937 2.65873 0.036302 2.62234C0.0126103 2.58595 0 2.54344 0 2.5C0 2.45656 0.0126103 2.41405 0.036302 2.37766C0.0599937 2.34127 0.0937333 2.31255 0.133426 2.29503C1.39276 1.87165 1.87369 1.39901 2.29645 0.131131C2.31424 0.0920218 2.34289 0.0588673 2.37898 0.0356162C2.41507 0.0123652 2.45708 0 2.49999 0C2.54291 0 2.58492 0.0123652 2.62101 0.0356162C2.6571 0.0588673 2.68576 0.0920218 2.70355 0.131131C3.12631 1.39229 3.59828 1.87165 4.86656 2.29503C4.90625 2.31255 4.93999 2.34127 4.96368 2.37766C4.98738 2.41405 5 2.45656 5 2.5C5 2.54344 4.98738 2.58595 4.96368 2.62234C4.93999 2.65873 4.90625 2.68743 4.86656 2.70496Z"
        fill="#EE9F17"
      />
      <path
        d="M18.8666 12.7036C17.605 13.1263 17.1263 13.5983 16.7036 14.8666C16.6861 14.9063 16.6574 14.94 16.621 14.9637C16.5847 14.9874 16.5423 15 16.4989 15C16.4555 15 16.413 14.9874 16.3767 14.9637C16.3404 14.94 16.3117 14.9063 16.2942 14.8666C15.8737 13.605 15.3995 13.1263 14.1334 12.7036C14.0937 12.6861 14.06 12.6574 14.0363 12.6211C14.0126 12.5847 14 12.5423 14 12.4989C14 12.4555 14.0126 12.4131 14.0363 12.3767C14.06 12.3404 14.0937 12.3117 14.1334 12.2942C15.3928 11.8715 15.8737 11.3995 16.2942 10.1334C16.3117 10.0937 16.3404 10.06 16.3767 10.0363C16.413 10.0126 16.4555 10 16.4989 10C16.5423 10 16.5847 10.0126 16.621 10.0363C16.6574 10.06 16.6861 10.0937 16.7036 10.1334C17.1263 11.3928 17.5983 11.8715 18.8666 12.2942C18.9063 12.3117 18.94 12.3404 18.9637 12.3767C18.9874 12.4131 19 12.4555 19 12.4989C19 12.5423 18.9874 12.5847 18.9637 12.6211C18.94 12.6574 18.9063 12.6861 18.8666 12.7036Z"
        fill="#EE9F17"
      />
    </g>
    <defs>
      <clipPath id="clip0_1112_11673">
        <rect width="19" height="15" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
