import React from "react";
import styled from "styled-components";
// TODO: neo componentsの方で作成する
import {
  BookItemContainerInterpolation,
  BookCoverContainer,
} from "../Common/style";
import { BookshelfImage } from "~/components/atoms/images/BookshelfImage";

export type RecommendBookImgButtonListItemProps = {
  title: string;
  columnsNumber: number;
  imageUrl: string | undefined;
  onClick: (event: React.MouseEvent) => void;
};

export const RecommendBookImgButtonListItem: React.FC<
  RecommendBookImgButtonListItemProps
> = ({ title, columnsNumber, imageUrl, onClick }) => {
  return (
    <BookItemContainer onClick={onClick} columnsNumber={columnsNumber}>
      <BookCoverContainer>
        <BookshelfImage url={imageUrl} alt={title} />
      </BookCoverContainer>
    </BookItemContainer>
  );
};

const BookItemContainer = styled.button<
  Pick<RecommendBookImgButtonListItemProps, "columnsNumber">
>`
  background: none;
  padding: 0;
  border: none;
  ${BookItemContainerInterpolation}
`;
