import React from "react";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import { Yomimashoka } from ".";
import { useGetWantReadBooksQuery } from "~/generated/graphql";
import { LoadingSection } from "~/components/templates/Loading/LoadingSection";

const ITEMS_PER_PAGE = 10;

export const YomimashokaContainer: React.FC = () => {
  const { currentUser } = React.useContext(CurrentUserContext);
  const [currentCount, setCurrentCount] = React.useState<number>(0);
  const { data, loading, error } = useGetWantReadBooksQuery({
    variables: { begin: currentCount, end: currentCount + ITEMS_PER_PAGE },
    fetchPolicy: "network-only",
  });

  if (loading) return <LoadingSection />;
  if (error) return <>Error! {error.message}</>;
  if (!data) return null;

  return (
    <Yomimashoka
      wantReadBooks={data.getWantReadBooks.get}
      userId={currentUser?.general.id}
      wantReadBookListProps={{
        currentCount: currentCount,
        items: data.getWantReadBooks.get,
        itemTotal: data.getWantReadBooks.count,
        itemsPerPage: ITEMS_PER_PAGE,
        onPageChange: (count: number) => setCurrentCount(count),
        isLoading: loading,
      }}
    />
  );
};
