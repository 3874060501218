import * as React from "react";
import { NotFoundTemplate } from "~/components/templates/NotFound";
import { useLocation } from "react-router-dom";
import * as Sentry from "@sentry/react";
export const NotFound: React.VFC = () => {
  const location = useLocation();

  React.useEffect(() => {
    const fullUrl = window.location.href;

    Sentry.captureException(new Error(`Page not found: ${fullUrl}`), {
      tags: {
        url: fullUrl,
      },
    });
  }, [location.pathname]);
  return <NotFoundTemplate />;
};
