import React from "react";
import { useTranslation } from "react-i18next";
import {
  SenseiRemarkWithTwoButtonsModal,
  SenseiRemarkWithTwoButtonsModalProps,
} from "./SenseiRemarkWithTwoButtonsModal";

type RemarkOrder = "FirstRemark" | "SecondRemark";

export type NoRecordBookDiffModalProps = {
  isModalOpen: boolean;
  onClose: () => void;
};

export const NoRecordBookDiffModal: React.FC<NoRecordBookDiffModalProps> = ({
  isModalOpen,
  onClose,
}) => {
  const { t } = useTranslation();

  const [remarkOrder, setRemarkOrder] =
    React.useState<RemarkOrder>("FirstRemark");

  const remarkContent: Record<
    RemarkOrder,
    Omit<SenseiRemarkWithTwoButtonsModalProps, "isOpen">
  > = {
    FirstRemark: {
      message:
        "久しぶり！\nしばらく" +
        t<TranslateKeys>("感想") +
        "を見られなくてさみしかったよ〜😢\nまた" +
        t<TranslateKeys>("一緒") +
        "に" +
        t<TranslateKeys>("本") +
        "を" +
        t<TranslateKeys>("読んで") +
        "くれる？",
      firstButtonText: "いいよ",
      secondButtonText: "しかたないなぁ",
      onClickFirstButton: () => setRemarkOrder("SecondRemark"),
      onClickSecondButton: () => setRemarkOrder("SecondRemark"),
      imgSrc: "/img/sensei/standup_5.svg",
    },
    SecondRemark: {
      message:
        "ありがとう！\nそれじゃあ、" +
        t<TranslateKeys>("読書家の書") +
        "を" +
        t<TranslateKeys>("楽しんで") +
        "！",
      firstButtonText: "またね〜",
      secondButtonText: "ばいばーい",
      onClickFirstButton: onClose,
      onClickSecondButton: onClose,
      imgSrc: "/img/sensei/standup_2.svg",
    },
  };

  return (
    <SenseiRemarkWithTwoButtonsModal
      isOpen={isModalOpen}
      {...remarkContent[remarkOrder]}
    />
  );
};
