import { css, FlattenSimpleInterpolation } from "styled-components";
import { Space, SpaceStyle } from "./size";

export type MarginProps = {
  margin?: Space;
  marginTop?: SpaceStyle;
  marginBottom?: SpaceStyle;
  marginRight?: SpaceStyle;
  marginLeft?: SpaceStyle;
};

export const marginStyle = (props: MarginProps): FlattenSimpleInterpolation =>
  css`
    ${props.margin &&
    `
      margin: ${props.margin};
    `}
    ${props.marginTop &&
    `
      margin-top: ${props.marginTop};
    `}
  ${props.marginBottom &&
    `
      margin-bottom: ${props.marginBottom};
    `}
  ${props.marginRight &&
    `
      margin-right: ${props.marginRight};
    `}
  ${props.marginLeft &&
    `
      margin-left: ${props.marginLeft};
    `}
  `;
