import * as React from "react";
import { ProgressNavigation } from "~/components/organisms/ProgressNavigation";
import { ReadLogFeedbackModal } from "~/components/organisms/ReadLogFeedbackModal";
import { NavContainer, Wrapper } from "~/components/templates/Common";
import { PostReadLogBarWrapper } from "../Common/style";

type Props = {
  modalProps: Parameters<typeof ReadLogFeedbackModal>[0];
};

export const IndicateFeedbackTemplate: React.VFC<Props> = ({ modalProps }) => {
  return (
    <>
      <NavContainer>
        <PostReadLogBarWrapper>
          <ProgressNavigation
            progress={100}
            handleBack={() => {
              return;
            }}
            buttonsPattern={"single"}
          />
        </PostReadLogBarWrapper>
      </NavContainer>
      <Wrapper>
        <ReadLogFeedbackModal {...modalProps} />
      </Wrapper>
    </>
  );
};
