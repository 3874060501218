import React from "react";
import styled from "styled-components";
import { LabeledInput } from "~/components/molecules/forms/LabeledInput";
import { Wrapper } from "../Common";
import { ErrorToast } from "~/components/organisms/toasts/ErrorToast";
import { LabeledPasswordInput } from "~/components/molecules/forms/LabeledPasswordInput";
import { Stack } from "~/components/atoms/layout/Stack";
import { TextAnchor } from "~/components/molecules/buttons/TextButton";
import { TextButton } from "~/components/molecules/buttons/TextButton";
import { PrimaryButton } from "~/components/molecules/buttons/PrimaryButton";
import { config } from "../../../../config";
import { useNavigate } from "react-router-dom";

export type LoginTemplateProps = {
  email: string;
  password: string;
  handleChangeEmail: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangePassword: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  isOpenForgetPasswordToast: boolean;
  closeForgetPasswordToast: () => void;
};

// TODO: メールアドレス/パスワードによるログインに置き換え
export const LoginTemplate: React.FC<LoginTemplateProps> = ({
  email,
  password,
  handleChangeEmail,
  handleChangePassword,
  handleSubmit,
  isOpenForgetPasswordToast,
  closeForgetPasswordToast,
}) => {
  const navigate = useNavigate();
  return (
    <_Wrapper>
      <Stack rowGap="24px" alignItems="center">
        <_LogoImg src="/img/login/yondemy_logo.svg" />
        <_SenseiImg src="/img/sensei/standup_1.svg" />
        <_WhiteBox onSubmit={handleSubmit}>
          <Stack rowGap="32px">
            <Stack rowGap="24px">
              <LoginInputBox>
                <LabeledInput
                  id="email"
                  name="email"
                  autoComplete="email"
                  labelProps={{
                    label: "メールアドレス",
                    bold: true,
                    color: "semantic.text.heading",
                  }}
                  value={email}
                  onChange={handleChangeEmail}
                />
              </LoginInputBox>
              <LoginInputBox>
                <LabeledPasswordInput
                  id="password"
                  name="password"
                  autoComplete="current-password"
                  labelProps={{
                    label: "パスワード",
                    bold: true,
                    color: "semantic.text.heading",
                  }}
                  value={password}
                  onChange={handleChangePassword}
                />
              </LoginInputBox>
            </Stack>
            <PrimaryButton
              fontSize={"LG"}
              lineHeight={"MD"}
              bold
              color={"pri"}
              text={"ログイン"}
            />
          </Stack>
        </_WhiteBox>
        <Stack rowGap="12px" alignItems="center">
          <TextAnchor
            fontSize="SM"
            lineHeight="EQ"
            fontColor="semantic.secondary.main"
            text="パスワードがわからない方"
            href={`${config.PARENT_APP}forget`}
          />
          <TextButton
            fontSize="SM"
            lineHeight="EQ"
            fontColor="semantic.secondary.main"
            text="上記でログインができない方"
            onClick={() => navigate("/cs_contact")}
          />
        </Stack>
      </Stack>
      <ErrorToast
        text="メールアドレスまたはパスワードに間違いがあります"
        isOpen={isOpenForgetPasswordToast}
        onClose={closeForgetPasswordToast}
      />
    </_Wrapper>
  );
};

const LoginInputBox = styled.div`
  padding: 15px 0;
`;

// NOTE: BaseModalと同じwidthに揃えている
const _WhiteBox = styled.form`
  width: 100%;
  max-width: calc(496px + ${({ theme }) => theme.size.s3} * 2);
  box-sizing: border-box;
  padding: ${({ theme }) => theme.size.s6} ${({ theme }) => theme.size.s2};
  background-color: ${({ theme }) =>
    theme.colors.semantic.layout.backgroundBox};
  border-radius: ${({ theme }) => theme.borderRadius.s2};
  position: relative;
`;

const _Wrapper = styled(Wrapper)`
  padding-top: ${({ theme }) => theme.size.s4};
`;

const _SenseiImg = styled.img`
  width: 54px;
  height: 81px;
  margin-bottom: -45px;
`;

const _LogoImg = styled.img`
  width: 131px;
  height: 26px;
`;
