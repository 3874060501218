import React from "react";
import { ReadLogMessage } from "./ReadLogMessage";
import {
  BfReadLogFragment,
  ReadLogReactionType,
} from "~/generated/graphql";
import { SimplifiedReadLogMessageProps } from "./ReadLogMessage/SendStarModal/SimplifiedReadLogMessage";
import { SendStarMessageProps } from "./ReadLogMessage/SendStarMessage";

export type BookFriendMessageListItemProps = {
  talk: BfReadLogFragment;
  myId: number;
  className?: string;
  isInWantReadBookShelf?: boolean;
  isShowSenseiMessage?: boolean;
  onReaction: (id: string, readLogId:number, reaction: ReadLogReactionType) => Promise<boolean>;
  onWantReadBook: (readLog: BfReadLogFragment) => Promise<boolean>;
  onCompleteShowSenseiMessage: (readLogId: number) => void;
  onOpenSendStarModal: (item: SimplifiedReadLogMessageProps) => void;
  canSendStar: boolean;
  isShowSendStarMessage: boolean;
  sendStarMessageProps: SendStarMessageProps;
};

export const BookFriendMessageListItem: React.FC<
  BookFriendMessageListItemProps
> = ({
  myId,
  talk,
  className,
  isInWantReadBookShelf,
  isShowSenseiMessage,
  onReaction,
  onWantReadBook,
  onCompleteShowSenseiMessage,
  onOpenSendStarModal,
  canSendStar,
  isShowSendStarMessage,
  sendStarMessageProps,
}: BookFriendMessageListItemProps) => {
  const userType = !talk.user
    ? "admin"
    : talk.user.__typename === "DeletedUser"
    ? "deletedUser"
    : talk.user.__typename === "User" && talk.user.id === myId
    ? "me"
    : "user";

  return (
    <ReadLogMessage
      item={talk}
      user={talk.user}
      userType={userType}
      className={className}
      onReaction={onReaction}
      onWantReadBook={onWantReadBook}
      isInWantReadBookShelf={isInWantReadBookShelf}
      isShowSenseiMessage={isShowSenseiMessage}
      onCompleteShowSenseiMessage={onCompleteShowSenseiMessage}
      onOpenSendStarModal={onOpenSendStarModal}
      canSendStar={canSendStar}
      isShowSendStarMessage={isShowSendStarMessage}
      sendStarMessageProps={sendStarMessageProps}
    />
  );
};
