import React from "react";
import styled from "styled-components";
import { LinkifyTextProps } from "../LinkifyText";
import { usePreference } from "~/store/preference/usePreference";
import {
  minilessonSpeedTypeList,
  minilessonTimeSettings,
} from "~/components/organisms/adminMessage/AdminMessageListItem/messageListItems/constant";
import { useReactiveVar } from "@apollo/client";
import { isAnimationRunning } from "~/pages/Message";
import { MiniLessonContext } from "~/contexts/MiniLessonContext";
import runes2 from "runes2";
import { BoldifyText } from "./boldifyText";

const CHAR_COUNT_SESSION_STORAGE_KEY = "CHAR_COUNT_SESSION_STORAGE_KEY";

export type MessageItemTextProps = Omit<
  LinkifyTextProps,
  "children" | "fontSize"
> & {
  text: string;
  onCompleted: () => void;
  showAll: boolean;
};

export const MessageItemText: React.FC<MessageItemTextProps> = ({
  text,
  onCompleted,
  showAll,
  ...linkifyTextProps
}) => {
  const [message, setMessage] = React.useState<string>("");
  const splitText = runes2(text);
  const [count, setCount] = React.useState<number>(0);
  const { preference } = usePreference();
  const minilessonSpeed =
    minilessonSpeedTypeList[
      typeof preference?.minilessonSpeedType === "number"
        ? preference.minilessonSpeedType
        : 2
    ];

  const { fontSize } = React.useContext(MiniLessonContext);

  const isRunning = useReactiveVar(isAnimationRunning);
  React.useEffect(() => {
    setCount(0);
    if (showAll) {
      setMessage(text);
    } else {
      setMessage("");
      try {
        if (
          typeof Number(
            sessionStorage.getItem(CHAR_COUNT_SESSION_STORAGE_KEY)
          ) === "number"
        ) {
          setCount(
            Number(sessionStorage.getItem(CHAR_COUNT_SESSION_STORAGE_KEY))
          );
        }
      } catch (e) {
        console.error(e);
      }
    }
  }, [text, showAll]);

  React.useEffect(() => {
    if (showAll || !isRunning) {
      return;
    }
    if (count >= splitText.length) {
      const TimeoutID = window.setTimeout(() => {
        onCompleted();
        try {
          sessionStorage.setItem(CHAR_COUNT_SESSION_STORAGE_KEY, "0");
        } catch (e) {
          console.error(e);
        }
      }, minilessonTimeSettings[minilessonSpeed].INTERVAL_AFTER_ALL_CHARACTER_SHOWN);
      return () => clearTimeout(TimeoutID);
    }
    const TimeoutID = window.setTimeout(() => {
      let _newCount = splitText.findIndex(
        (char, i) => i > count && char !== "*"
      );
      if (_newCount === -1) {
        _newCount = splitText.length;
      }
      setCount(_newCount);
      let newMessage = "";
      for (let i = 0; i < _newCount; i++) {
        newMessage += splitText[i];
      }
      setMessage(newMessage);
    }, minilessonTimeSettings[minilessonSpeed].TIME_TO_SHOW_SINGLE_CHARACTER);

    return () => {
      clearTimeout(TimeoutID);
      try {
        sessionStorage.setItem(CHAR_COUNT_SESSION_STORAGE_KEY, String(count));
      } catch (e) {
        console.error(e);
      }
    };
    // onCompletedが冪等でないので、depsに入れると複数回発火してしまう
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, text, showAll, isRunning, minilessonSpeed]);
  return (
    <_Wrapper>
      <_HiddenTextWrapper>
        <BoldifyText fontSize={fontSize} {...linkifyTextProps} text={text} />
      </_HiddenTextWrapper>
      <_VisibleTextWrapper>
        <BoldifyText fontSize={fontSize} {...linkifyTextProps} text={message} />
      </_VisibleTextWrapper>
    </_Wrapper>
  );
};

const _Wrapper = styled.div`
  position: relative;
`;

const _HiddenTextWrapper = styled.div`
  opacity: 0;
`;

const _VisibleTextWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;
