import React from "react";
import styled from "styled-components";
import { Book, RecommendStatus } from "~/generated/graphql";
import { NavContainer } from "../Common";
import { BackNavbar } from "~/components/molecules/navbars/BackNavbar";
import { BookInfoCardWithButtons } from "./BookInfoCardWithButtons";
import { RoundedBox } from "~/components/atoms/surfaces/RoundedBox";
import { Text } from "~/components/atoms/texts/Text";
import { GetNextRecommendAlert } from "./GetNextRecommendAlert";
import { ReadLogs, ReadLogsProps } from "~/components/organisms/ReadLogs";
import { BookInfo } from "~/components/organisms/BookInfo";
import { PostReadLogFooter, PostReadLogFooterProps } from "./PostReadLogFooter";
import { useTranslation } from "~/i18n";

export type BookDetailInfoTemplateProps = {
  book: Book;
  isRecommend: boolean;
  recommendStatus?: RecommendStatus;
  recommendComment?: string;
  handleBack: () => void;
  handleAlertClose?: () => void;
  readLogsProps?: Omit<ReadLogsProps, "handlePostingReadLog">;
  footerProps?: PostReadLogFooterProps;
  handleClickGoToCalil: (isbn: string) => void;
};

export const BookDetailInfoTemplate: React.FC<BookDetailInfoTemplateProps> = ({
  book,
  isRecommend,
  recommendStatus,
  recommendComment,
  handleBack,
  handleAlertClose,
  readLogsProps,
  footerProps,
  handleClickGoToCalil,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <NavContainer>
        <BackNavbar
          onClick={handleBack}
          titleName={t<TranslateKeys>("本の詳細")}
        />
      </NavContainer>
      <_Wrapper>
        <_Container>
          <BookInfoCardWithButtons
            isRecommend={isRecommend}
            recommendStatus={recommendStatus}
            {...book}
            onReserve={() => handleClickGoToCalil(book.isbn)}
          />
          {handleAlertClose && recommendStatus === RecommendStatus.Default && (
            <GetNextRecommendAlert onClose={handleAlertClose} />
          )}
          {readLogsProps && <ReadLogs {...readLogsProps} />}
          <_Section>
            {recommendComment && (
              <_ReasonWrapper>
                <div className="icon" />
                <_RoundedBoxSpeechBubble>
                  <Text fontSize="MD" lineHeight="MD" className="label">
                    {recommendComment}
                  </Text>
                </_RoundedBoxSpeechBubble>
              </_ReasonWrapper>
            )}
            <BookInfo book={book} />
          </_Section>
        </_Container>
      </_Wrapper>
      {footerProps && <PostReadLogFooter {...footerProps} />}
    </>
  );
};

const _Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: ${({ theme }) =>
    theme.colors.semantic.layout.backgroundMain};
  display: flex;
  justify-content: center;
  padding: 72px 16px 108px 16px;
`;

const _Container = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

const _Section = styled.section`
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const _ReasonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  .icon {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background: url("/img/sensei/icon_point.jpg");
    background-size: contain;
  }
`;

const _RoundedBoxSpeechBubble = styled(RoundedBox)`
  width: 87%;
  border-radius: 16px 16px 16px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: ${({ theme }) => theme.colors.primitive.orange.o200};
`;
