import { makeVar, useReactiveVar } from "@apollo/client";
import { useCallback, useContext, useEffect } from "react";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";

type PreferenceValue<T> = T | undefined;

type Preference = {
  // 初めて登録した初回ログイン
  firstLogin?: PreferenceValue<boolean>;
  // booklistでReselectGuideModalを表示済みかどうか
  showReselectGuideModal?: PreferenceValue<boolean>;
  // calilの検索キーワード
  calilSearchWord?: PreferenceValue<string>;
  // おすすめ本削除についてのチュートリアル表示
  discardRecommendModalOpen?: PreferenceValue<boolean>;
  // TutorialPostReadLogModal表示済みかどうか
  firstImplession?: PreferenceValue<boolean>;
  // PinnedAlert: マイページの「スマホ・タブレットのホーム画面から〜」
  showPinnedAlertOnMypage?: PreferenceValue<boolean>;
  // PinnedAlert: ほんだなの「おすすめ本にない本の〜」
  showToastOnBookshelf?: PreferenceValue<boolean>;
  // TutorialBookListModalを表示したかどうか
  bookListReachFirst?: PreferenceValue<boolean>;
  // 選書の手引きを表示するかどうか
  hideRecommendModal?: PreferenceValue<boolean>;
  // 選書リストの初回説明
  recommendListFirstTimeInstruction?: PreferenceValue<boolean>;
  // 選書リストの説明
  recommendListInstruction?: PreferenceValue<boolean>;
  // 選書リスト: 前回の最新recommendId
  recommendListLatestRecommendId?: PreferenceValue<number>;
  // PWA化しているかどうかをGAに送信するイベントがすでに走ったかどうか
  pwaEventRun?: PreferenceValue<boolean>;
  // お手紙を読んだかどうか
  isAlreadyReadLetter?: PreferenceValue<boolean>;
  // sndの再生音量(0~10)
  sndVolume?: PreferenceValue<number>;
  // sndのmute
  sndMuted?: PreferenceValue<boolean>;
  // StampCardを今日初めて表示したか？(日付をYYYY-MM-DDで入れる)
  stampCardCheckedDate?: PreferenceValue<string>;
  // 最後にスタンプをもらった日(YYYY-MM-DD)
  lastStampObtainedDate?: PreferenceValue<string>;
  // 新着のおすすめ本の配列
  newRecommendIdList?: PreferenceValue<string>;
  // 最後におすすめ本一覧画面を開いた時の最新のレコメンド
  booklistLatestRecommendId?: PreferenceValue<number>;
  // ミニレッスンの文字送りのスピード
  // 0: slow, 1: default, 2: fast, 3: noAnimation
  minilessonSpeedType?: PreferenceValue<number>;
  // ミニレッスンのフォントサイズ
  // 0: small, 1: medium, 2: large, 3: xLarge
  minilessonFontSize?: PreferenceValue<number>;
  // 直近で獲得したStarEnergyのID
  latestStarEnergyID?: PreferenceValue<number>;
};

export type PreferenceKey = keyof Preference;

const PREFIX_LOCAL_STORAGE = "yondemy_preference" as const;

const preferenceLocalStorageKey = (userId: number): string =>
  `${PREFIX_LOCAL_STORAGE}_${userId}`;

const loadPreference = (storageKey: string): Preference => {
  try {
    const savedPreference = localStorage.getItem(storageKey);
    if (savedPreference) {
      return JSON.parse(savedPreference) as Preference;
    }
  } catch (e) {
    console.error(e);
  }

  const initPrefs: Preference = {};

  try {
    localStorage.setItem(storageKey, JSON.stringify(initPrefs));
  } catch (e) {
    console.error(e);
  }

  return initPrefs;
};

const preferenceVar = makeVar<Preference | undefined>(undefined);

type SavePreferenceOptions = {
  updateCurrentValue?: boolean; // falseを渡した場合、次回のlocalStorageのロードまで値が反映されない
};

const defaultSavePreferenceOptions: SavePreferenceOptions = {
  updateCurrentValue: true,
};

export const usePreference = (): {
  preference?: Preference;
  savePreference: (
    values: Partial<Preference>,
    options?: SavePreferenceOptions
  ) => void;
} => {
  const { currentUser } = useContext(CurrentUserContext);
  const userId = currentUser?.general.id;

  useEffect(() => {
    userId && preferenceVar(loadPreference(preferenceLocalStorageKey(userId)));
  }, [userId]);

  const preference = useReactiveVar(preferenceVar);

  const savePreference = useCallback(
    (values: Partial<Preference>, options: SavePreferenceOptions = {}) => {
      const _options: SavePreferenceOptions = {
        ...defaultSavePreferenceOptions,
        ...options,
      };

      const _currentPrefs = preferenceVar();

      if (!userId || !_currentPrefs) return;
      const newPref: Preference = { ..._currentPrefs, ...values };

      _options.updateCurrentValue && preferenceVar(newPref);
      try {
        localStorage.setItem(
          preferenceLocalStorageKey(userId),
          JSON.stringify(newPref)
        );
      } catch (e) {
        console.error(e);
      }
    },
    [preference, userId]
  );

  return { preference, savePreference };
};

/**
 * ログイン前にlocalstorageの情報を取得する
 */

export const useGetBeforeLoginPreference = (): Preference | undefined => {
  const localStorageKeys = Object.keys(localStorage || {}).filter((key) =>
    key?.startsWith(PREFIX_LOCAL_STORAGE)
  );
  if (localStorageKeys.length === 0) {
    return undefined;
  }
  return loadPreference(localStorageKeys[0]);
};
