import * as React from "react";
import styled from "styled-components";
import { Stack } from "~/components/atoms/layout/Stack";

type Props = {
  onClick: () => void;
};

export const Splash: React.FC<Props> = ({ onClick }) => {
  return (
    <_Container onClick={onClick}>
      <_NoticeContainer rowGap="16px">
        <_Box>
          <_VolumeIcon />
          <_Notice>
            おとが ながれるから
            <br />
            おんせいを オンにしてね！
          </_Notice>
        </_Box>
        <_TapToStart>タップしてはじめる</_TapToStart>
      </_NoticeContainer>
    </_Container>
  );
};

const _NoticeContainer = styled(Stack)`
  position: absolute;
  bottom: 40px;

  width: 100%;
  padding: 0 20px;

  align-items: center;

  font-family: "M PLUS 1 Code", sans-serif;
  color: ${({ theme }) => theme.colors.tex.t700};
  font-size: ${({ theme }) => theme.fontSize.MD};
  font-weight: 700;
  line-height: 160%;
`;

const _Box = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 10px;

  padding: 10px;
  margin-bottom: 12px;
  background-color: white;
  border-radius: 8px;

  ::after {
    position: absolute;
    content: "";
    left: calc(50% - 13px);
    top: calc(100% - 6px);
    border-top: 18px solid ${({ theme }) => theme.colors.base.white};
    border-right: 13px solid transparent;
    border-left: 13px solid transparent;
  }
`;

const _Notice = styled.p`
  font-size: ${({ theme }) => theme.fontSize.XL};
  color: ${({ theme }) => theme.colors.sec.s400};
  line-height: 160%;
  font-weight: 700;
`;

const _TapToStart = styled.div`
  font-size: ${({ theme }) => theme.fontSize.XL};
`;

const _Container = styled.div`
  position: relative;

  background-color: #f6f6f6;
  background-image: url(/img/mission/yondemy_story/splash.png);
  background-size: contain;
  background-repeat: no-repeat;

  cursor: pointer;

  width: 376px;
  height: 812px;
`;

const _VolumeIcon: React.FC = () => (
  <svg
    width="19"
    height="16"
    viewBox="0 0 19 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.093 1.89905L4.15823 4.83316H0.791667C0.354271 4.83316 0 5.18743 0 5.62483V10.3748C0 10.8119 0.354271 11.1665 0.791667 11.1665H4.15823L7.093 14.1006C7.58878 14.5964 8.44444 14.2481 8.44444 13.5408V2.45882C8.44444 1.75094 7.58813 1.40392 7.093 1.89905ZM14.7893 0.214116C14.4209 -0.0276727 13.9257 0.0742545 13.684 0.443369C13.4418 0.811824 13.5448 1.30695 13.9132 1.54873C16.0992 2.9833 17.4038 5.39491 17.4038 8.00016C17.4038 10.6054 16.0992 13.017 13.9132 14.4516C13.5448 14.693 13.4418 15.1885 13.684 15.5566C13.9162 15.9099 14.4073 16.0369 14.7893 15.7859C17.4256 14.0554 19 11.1444 19 7.99983C19 4.85526 17.4256 1.94457 14.7893 0.214116ZM15.8333 7.99983C15.8333 5.90422 14.7758 3.9775 13.0041 2.84608C12.635 2.61056 12.1455 2.72007 11.9116 3.09215C11.6777 3.46424 11.7869 3.95672 12.156 4.19257C13.4672 5.03009 14.25 6.45311 14.25 7.99983C14.25 9.54655 13.4672 10.9696 12.156 11.8071C11.7869 12.0426 11.6777 12.5351 11.9116 12.9075C12.1264 13.2492 12.6083 13.4069 13.0041 13.1536C14.7758 12.0222 15.8333 10.0958 15.8333 7.99983ZM11.1569 5.46418C10.7749 5.25538 10.293 5.39293 10.0812 5.7759C9.87043 6.15887 10.01 6.64014 10.3929 6.85158C10.8188 7.08545 11.0833 7.52582 11.0833 7.99983C11.0833 8.47417 10.8188 8.9142 10.3933 9.14807C10.0103 9.35951 9.87076 9.84078 10.0815 10.2238C10.2936 10.6084 10.7759 10.7449 11.1572 10.5355C12.0884 10.0225 12.667 9.05109 12.667 7.9995C12.667 6.9479 12.0884 5.97679 11.1569 5.46418Z"
      fill="#0068C9"
    />
  </svg>
);
