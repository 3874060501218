import * as React from "react";
import styled, { css } from "styled-components";
import { MissionProgress } from "./MissionProgress";
import { UserMissionTask } from "~/generated/graphql";
import { StampCard } from "./StampCard";
import { StampCardProps } from "./StampCard";
import { CircleArrowPaginationButton } from "~/components/molecules/buttons/CircleArrowPaginationButton";

type CarouselItemType = "Mission" | "Stamp";

export type MissionAndStampCarouselProps = {
  isShowMissionProgress: boolean;
  userMissionTasks: UserMissionTask[];
  stampCardProps: StampCardProps;
  openStory: () => void;
};

export const MissionAndStampCarousel: React.FC<
  MissionAndStampCarouselProps
> = ({
  isShowMissionProgress,
  userMissionTasks,
  stampCardProps,
  openStory,
}) => {
  const [carouselItem, setCarouselItem] =
    React.useState<CarouselItemType>("Mission");

  React.useEffect(() => {
    if (stampCardProps.getStampModalProps.open) {
      setCarouselItem("Stamp");
    }
  }, [stampCardProps.getStampModalProps.open]);

  if (isShowMissionProgress) {
    return carouselItem === "Mission" ? (
      <_Wrapper>
        <MissionProgress
          userMissionTasks={userMissionTasks}
          gotoLesson={stampCardProps.gotoLesson}
          hasTodaysLesson={stampCardProps.hasTodaysLesson}
          openStory={openStory}
        />
        <_CarouselButtonWrapper loc="right">
          <CircleArrowPaginationButton
            dir="next"
            disabled={false}
            onClick={() => setCarouselItem("Stamp")}
            color="sec"
          />
        </_CarouselButtonWrapper>
      </_Wrapper>
    ) : (
      <_Wrapper>
        <StampCard {...stampCardProps} />
        <_CarouselButtonWrapper loc="left">
          <CircleArrowPaginationButton
            dir="prev"
            disabled={false}
            onClick={() => setCarouselItem("Mission")}
            color="sec"
          />
        </_CarouselButtonWrapper>
      </_Wrapper>
    );
  } else {
    return <StampCard {...stampCardProps} />;
  }
};

const _Wrapper = styled.div`
  position: relative;
`;

const _CarouselButtonWrapper = styled.div<{ loc: "left" | "right" }>`
  ${({ loc, theme }) => {
    switch (loc) {
      case "left":
        return css`
          position: absolute;
          top: 50%;
          left: -15px;
          transform: translateY(-50%);
          z-index: ${theme.zIndex.floatingButton};
        `;
      case "right":
        return css`
          position: absolute;
          top: 50%;
          right: -15px;
          transform: translateY(-50%);
          z-index: ${theme.zIndex.floatingButton};
        `;
    }
  }}
`;
