import React from "react";
import styled from "styled-components";
import { EnterIsbnProps } from "./type";
import { NavContainer, Wrapper } from "../../../Common";
import { PostReadLogHeading, PostReadLogBodyWrapper } from "../../Common/style";
import { BackNavbar } from "~/components/molecules/navbars/BackNavbar";
import { BR } from "./style";
import { Label } from "~/components/atoms/wrappers/Label";
import { Input } from "~/components/atoms/input/Input";
import { ErrorMessage } from "~/components/molecules/texts/ErrorMessage";
import { Loading } from "~/components/atoms/Loading";
import { checkDegit, normalizeIsbn } from "./hook";
import { Stack } from "~/components/atoms/layout/Stack";
import { LargeButton } from "~/components/atoms/buttons/Button";
import { Icon } from "~/components/atoms/Icon";
import { Text } from "~/components/atoms/texts/Text";

export const EnterIsbn: React.FC<EnterIsbnProps> = ({
  handleBack,
  onClickGoNext,
  bookISBN,
  title,
  setBookISBN,
  setTitle,
  errorMessageIsbn,
  setErrorMessageIsbn,
  errorMessageTitle,
  setErrorMessageTitle,
  disabled,
  getBookByIsbn,
  loadingBookByIsbn,
}) => {
  const handleSearchTitle = (isbn: string, title: string) => {
    const tmp = checkDegit(normalizeIsbn(isbn));
    if (tmp === "") {
      setErrorMessageIsbn(
        "正しいISBNを入力してね！　本のうらがわに書いてある、13ケタの数字だよ！"
      );
      return;
    } else if (tmp === "0" && !title) {
      setErrorMessageTitle("タイトルを入力してね！");
      return;
    }

    if (!title) {
      getBookByIsbn(tmp);
    }
  };

  const handleChangeBookISBN: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    event.preventDefault();
    setErrorMessageIsbn("");
    setBookISBN(event.target.value);
  };

  const handleBlurBookISBN: React.FocusEventHandler<HTMLInputElement> = (
    event
  ) => {
    event.preventDefault();
    setErrorMessageIsbn("");
    setErrorMessageTitle("");
    handleSearchTitle(bookISBN, title);
  };

  const handleChangeTitle: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    event.preventDefault();
    setErrorMessageTitle("");
    setTitle(event.target.value);
  };

  const handleBlurTitle: React.FocusEventHandler<HTMLInputElement> = (
    event
  ) => {
    event.preventDefault();
    setErrorMessageIsbn("");
    setErrorMessageTitle("");
    handleSearchTitle(bookISBN, title);
  };
  return (
    <>
      <NavContainer>
        <BackNavbar
          titleName={""}
          onClick={handleBack}
          background={"none"}
          arrowColor={"sec"}
        />
      </NavContainer>
      <Wrapper>
        <PostReadLogBodyWrapper>
          <Stack rowGap="16px">
            <Stack rowGap="24px">
              <UnregisteredBookIsbnHeading
                fontSize={"LG"}
                lineHeight={"MD"}
                bold
              >
                自分で本を追加する
              </UnregisteredBookIsbnHeading>
              <_ContentWrapper>
                <Text
                  lineHeight="MD"
                  fontSize="MD"
                  fontColor="semantic.text.body"
                >
                  ISBN（本の裏に書いてある、9で始まる13桁の数字）を入力すると追加できます。
                  ISBNのない本は、ISBNの欄に「なし」と入力してください。
                </Text>
              </_ContentWrapper>
            </Stack>
            <_Stack rowGap="24px" alignItems="center">
              <BR.Wrapper>
                <BR.InputsCon>
                  <Label label={"ISBN"}>
                    <Input
                      value={bookISBN}
                      onChange={handleChangeBookISBN}
                      hasError={
                        errorMessageIsbn !== "" &&
                        errorMessageIsbn !== undefined
                      }
                      onBlur={handleBlurBookISBN}
                    />
                    {errorMessageIsbn && (
                      <BR.ErrorMessageCon>
                        <ErrorMessage>{errorMessageIsbn}</ErrorMessage>
                      </BR.ErrorMessageCon>
                    )}
                  </Label>
                  <Label label={"タイトル"}>
                    {loadingBookByIsbn ? (
                      <Loading size={"s"} type={"primary"} />
                    ) : (
                      <>
                        <Input
                          value={title}
                          onChange={handleChangeTitle}
                          hasError={
                            errorMessageTitle !== "" &&
                            errorMessageTitle !== undefined
                          }
                          onBlur={handleBlurTitle}
                        />
                        {errorMessageTitle && (
                          <BR.ErrorMessageCon>
                            <ErrorMessage>{errorMessageTitle}</ErrorMessage>
                          </BR.ErrorMessageCon>
                        )}
                      </>
                    )}
                  </Label>
                </BR.InputsCon>
              </BR.Wrapper>
              <LargeButton
                onClick={onClickGoNext}
                disabled={disabled}
                color={"primary"}
                rightIcon={<Icon name="arrowRight" color="white" />}
              >
                すすむ
              </LargeButton>
            </_Stack>
          </Stack>
        </PostReadLogBodyWrapper>
      </Wrapper>
    </>
  );
};

const UnregisteredBookIsbnHeading = styled(PostReadLogHeading)`
  text-align: left;
`;

const _ContentWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.primitive.monotone.m0};
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  border-radius: 16px;
`;

const _Stack = styled(Stack)`
  width: 100%;
`;
