import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Icon, IconType } from "~/components/atoms/Icon";
import { Heading1 } from "~/components/atoms/texts/Heading1";
import { IconButton } from "~/components/molecules/buttons/IconButton";
import { slide as Slide } from "react-burger-menu";
import { Text } from "~/components/atoms/texts/Text";
import { IMAGE_PATHS } from "~/helpers/constants/imagePath";
import { Row } from "~/components/atoms/layout/Row";
import { theme } from "~/styles/theme";
import { useSnd } from "~/utils/hooks/useSnd";
import { useTranslation } from "react-i18next";
import { BookCover } from "./bookCover";
import { useAudio } from "~/utils/hooks/useAudio";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import { SKIP_OPEN_ANIMATION_SESSION_STORAGE_KEY } from "~/pages/RecordBook";

export type PageTab =
  | "bookshelf"
  | "archievement"
  | "badgeList"
  | "videoArchive";

type TabInfo = {
  uri: string;
  iconName: IconType;
};

export const tabInfoList: Record<PageTab, TabInfo> = {
  bookshelf: {
    uri: "/record_book/bookshelf",
    iconName: "book",
  },
  archievement: {
    uri: "/record_book/archievement",
    iconName: "graph",
  },
  badgeList: {
    uri: "/record_book/badge_list",
    iconName: "medal",
  },
  videoArchive: {
    uri: "/record_book/video_archive",
    iconName: "film",
  },
};

export type RecordTemplateProps = {
  tab: PageTab;
  children: React.ReactNode;
  skipOpenAnimation: boolean;
};

export const RecordTemplate: React.FC<RecordTemplateProps> = ({
  tab,
  children,
  skipOpenAnimation,
}) => {
  const navigate = useNavigate();
  const { play, volume } = useSnd();
  const { play: playPageTurnSnd } = useAudio({ preload: ["pageTurn"] });
  const [isSideBarOpen, setIsSideBarOpen] = React.useState<boolean>(false);
  const [isOpenRecordBook, setIsOpenRecordBook] =
    React.useState<boolean>(false);

  const { currentUser } = React.useContext(CurrentUserContext);
  const { t } = useTranslation();

  React.useEffect(() => {
    if (!isOpenRecordBook && !skipOpenAnimation) {
      const timeoutId = window.setTimeout(() => {
        setIsOpenRecordBook(true);
        playPageTurnSnd("pageTurn");
      }, 3000);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isOpenRecordBook, playPageTurnSnd]);
  return (
    <_Wrapper>
      <Slide
        isOpen={isSideBarOpen}
        right
        width="193px"
        onOpen={() => setIsSideBarOpen(true)}
        onClose={() => setIsSideBarOpen(false)}
        onStateChange={() => {
          return isSideBarOpen;
        }}
        customBurgerIcon={false}
        styles={{ bmOverlay: { width: "100vw", left: "0" } }}
      >
        <_SlideContent>
          <Row
            onClick={() => setIsSideBarOpen(false)}
            justifyContent="flex-end"
          >
            <Icon
              name="close"
              color="gray"
              style={{ fontSize: theme.fontSize.XL }}
            />
          </Row>
          <MenuButton
            onClick={() => {
              navigate("/sharing_image");
            }}
          >
            <Image src={IMAGE_PATHS.ICON.SHARE} alt="record" />
            <Text fontSize="SM" lineHeight="MD" fontColor="tex.t400" bold>
              ほんだなをシェア
            </Text>
          </MenuButton>
        </_SlideContent>
      </Slide>
      <_HeaderWrapper>
        <IconButton
          onClick={() => {
            try {
              sessionStorage.removeItem(
                SKIP_OPEN_ANIMATION_SESSION_STORAGE_KEY
              );
            } catch (e) {
              console.error(e);
            }
            navigate("/");
          }}
          color="pri"
          name="arrowLeft"
          fontSize="XXXL"
        />
        <Heading1 fontSize="XL" lineHeight="EQ" fontColor="base.white" bold>
          {t<TranslateKeys>("読書家の書")}
        </Heading1>
        <IconButton
          onClick={() => setIsSideBarOpen(true)}
          color="white"
          name="share"
          fontSize="XXL"
        />
      </_HeaderWrapper>
      <_BookCover>
        {skipOpenAnimation ? (
          <_OpenedBookWrapper>
            <_BookSide>
              <_Paper>{children}</_Paper>
            </_BookSide>
          </_OpenedBookWrapper>
        ) : isOpenRecordBook ? (
          <_OpenedBookWrapper>
            <_BookSide>
              <_Paper>{children}</_Paper>
            </_BookSide>
            <_ClosedBookSide>
              <_BookCoverWrapper>
                <BookCover username={currentUser?.general.nickname || ""} />
              </_BookCoverWrapper>
            </_ClosedBookSide>
          </_OpenedBookWrapper>
        ) : (
          <_ClosedBookSide>
            <BookCover
              onClick={() => {
                setIsOpenRecordBook(true);
                playPageTurnSnd("pageTurn");
              }}
              username={currentUser?.general.nickname || ""}
            />
          </_ClosedBookSide>
        )}

        <_TabWrapper>
          {Object.keys(tabInfoList).map((pageTab) => {
            const selected = pageTab === tab;
            return (
              <_TabItem
                key={pageTab}
                selected={selected}
                onClick={() => {
                  if (!selected) {
                    navigate(tabInfoList[pageTab].uri);
                    play("SWIPE", { volume: Math.floor(volume * 0.2) });
                  }
                }}
              >
                <Icon
                  name={tabInfoList[pageTab].iconName}
                  color={selected ? "pri" : "lightGray"}
                />
              </_TabItem>
            );
          })}
        </_TabWrapper>
      </_BookCover>
    </_Wrapper>
  );
};

const _Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  max-width: 100svw;
  max-height: 100svh;
  background-image: url("/img/record_book/table.png");
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  row-gap: 8px;
  overflow: hidden;
`;

const _HeaderWrapper = styled.div`
  width: 100%;
  max-width: 600px;
  height: fit-content;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const _TabWrapper = styled.div`
  width: calc(100% - 34px);
  padding: 0 2px;
  position: absolute;
  top: -10px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  column-gap: 4px;
`;

const _TabItem = styled.div<{ selected: boolean }>`
  position: relative;
  width: 100%;
  height: ${({ selected }) => (selected ? "40px" : "32px")};
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.base.white};
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 12px;

  cursor: ${({ selected }) => (selected ? "auto" : "pointer")};

  ::before {
    position: absolute;
    top: 0;
    content: "";
    background-color: ${({ theme, selected }) =>
      selected ? theme.colors.pri.p400 : theme.colors.base.lightGray};
    width: 100%;
    height: 8px;
  }
`;

const _BookCover = styled.div`
  position: relative;
  background-color: #509029;
  height: 100%;
  width: calc(56vh + 34px);
  max-width: calc(100svw + 34px);
  margin-right: -34px;
  padding: 9px 14px 0 0;
  overflow: visible;
`;

const _BookSide = styled.div`
  background-color: #dcba70;
  width: 100%;
  height: 100%;
  padding: 17px 20px 0 0;
`;

const _ClosedBookSide = styled.div`
  background-color: #dcba70;
  width: 100%;
  height: 100%;
  padding-top: 24px;
`;

const _Paper = styled.div`
  width: 100%;
  height: calc(100vh - 90px);
  max-height: calc(100svh - 90px);
  background-image: url("/img/record_book/paper.png");
  background-size: cover;
  background-position: center;
  padding: 24px 20px 48px;
  overflow: scroll;
`;

const _SlideContent = styled.div`
  background: ${({ theme }) => theme.colors.base.white};
  padding: 15px 12px 0 15px;
  border-style: solid;
  border-width: 2px 0px 2px 2px;
  border-color: ${({ theme }) => theme.colors.base.lightGray};
  border-radius: 16px 0px 0px 16px;
`;

const MenuButton = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0;
  cursor: pointer;
`;

const Image = styled.img`
  margin-right: 16px;
  width: 20px;
`;

const _OpenedBookWrapper = styled.div`
  position: relative;
`;

const _BookCoverWrapper = styled.div`
  pointer-events: none;
  position: absolute;
  z-index: ${({ theme }) => theme.zIndex.popup};

  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  animation: wipe 0.8s ease-out;
  animation-fill-mode: forwards;
  transform-origin: center left;

  @keyframes wipe {
    from {
      transform: perspective(2000px) rotateY(0);
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.9);
      filter: brightness(1);
    }
    to {
      transform: perspective(2000px) rotateY(-90deg);
      box-shadow: 100px 0px 100px 100px rgba(0, 0, 0, 0);
      filter: brightness(0.5);
    }
  }
`;
