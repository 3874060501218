import { YLAndSentenceOrder } from "./YLReassesmentEnquete/const";
import { CurrentRecommendDifficultyPerception } from "~/generated/graphql";
import {
  PassageYL,
  passageYLs,
} from "~/components/organisms/BookSelectionAdjustment/ylReassesmentEnquete/YLReassesmentEnquete/types";

const roundToPassageYL = (value: number): PassageYL => {
  const passageYL = passageYLs.find((yl) => {
    return yl + 5 > value && value >= yl;
  });
  if (!passageYL) {
    if (value < 15) {
      return 15;
    } else {
      return 50;
    }
  }

  return passageYL;
};

type SentenceLevel = "easy" | "middle" | "difficult";

export type SentenceLevelRecord = Record<SentenceLevel, PassageYL>;

const getPerceptionRecord = (
  roundedSuitableYl: number
): Record<CurrentRecommendDifficultyPerception, () => SentenceLevelRecord> => {
  return {
    EASY: () => {
      const easyLevel = Math.min(roundedSuitableYl, 40);
      const difficultLevel = Math.min(roundedSuitableYl + 20, 50);
      const middleLevel = (easyLevel + difficultLevel) / 2;
      return {
        easy: roundToPassageYL(easyLevel),
        middle: roundToPassageYL(middleLevel),
        difficult: roundToPassageYL(difficultLevel),
      };
    },
    DIFFICULT: () => {
      const easyLevel = Math.max(15, roundedSuitableYl - 20);
      const difficultLevel = Math.max(roundedSuitableYl, 25);
      const middleLevel = (easyLevel + difficultLevel) / 2;
      return {
        easy: roundToPassageYL(easyLevel),
        middle: roundToPassageYL(middleLevel),
        difficult: roundToPassageYL(difficultLevel),
      };
    },
  };
};

type GetYlReassesmentEnqueteProps = {
  ylAndSentenceOrderList: YLAndSentenceOrder[];
  sentenceLevelRecord: SentenceLevelRecord;
};

export const getYlReassesmentEnquete = (
  latestSuitableYl: number,
  currentDifficultyPerception: CurrentRecommendDifficultyPerception
): GetYlReassesmentEnqueteProps => {
  const roundedSuitableYl: number = Math.floor(latestSuitableYl / 5) * 5;

  const perceptionRecord = getPerceptionRecord(roundedSuitableYl);
  const sentenceLevelAndPassageYlRecord: SentenceLevelRecord =
    perceptionRecord[currentDifficultyPerception]();

  const ylAndSentenceOrderList: YLAndSentenceOrder[] = [
    { passageYL: sentenceLevelAndPassageYlRecord.easy, sentenceOrder: "first" },
    {
      passageYL: sentenceLevelAndPassageYlRecord.difficult,
      sentenceOrder: "first",
    },
    {
      passageYL: sentenceLevelAndPassageYlRecord.easy,
      sentenceOrder: "second",
    },
    {
      passageYL: sentenceLevelAndPassageYlRecord.middle,
      sentenceOrder: "first",
    },
    {
      passageYL: sentenceLevelAndPassageYlRecord.difficult,
      sentenceOrder: "second",
    },
    {
      passageYL: sentenceLevelAndPassageYlRecord.middle,
      sentenceOrder: "second",
    },
  ];

  return {
    ylAndSentenceOrderList: ylAndSentenceOrderList,
    sentenceLevelRecord: sentenceLevelAndPassageYlRecord,
  };
};
