import { MissionOrder, UserIconType } from "~/generated/graphql";
import {
  iconUnlockTiming,
  missionProgressNumber,
  missonNumber,
} from "~/components/templates/EditProfile/const";

export type IconUnlockMissionStatus = "START" | "COMPLETE";

const reversedIconUnlockTiming: Record<IconUnlockMissionStatus, number> = {
  START: missionProgressNumber.IN_PROGRESS,
  COMPLETE: missionProgressNumber.NEED_COMPLETE,
};

export const getUnlockIcon = (
  missionOrder: MissionOrder,
  status: IconUnlockMissionStatus
): UserIconType[] => {
  return (Object.keys(iconUnlockTiming) as UserIconType[])
    .filter(
      (iconName: UserIconType) =>
        missonNumber[missionOrder] === iconUnlockTiming[iconName].mission &&
        reversedIconUnlockTiming[status] === iconUnlockTiming[iconName].timing
    )
};
