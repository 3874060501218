import * as React from "react";
import styled from "styled-components";
import { EmptyRecommendList } from "./ListItem/EmptyList";
import { RecommendFrequency, RecommendStatus } from "~/generated/graphql";
import { Recommend } from "./types";
import recommendFrequencyTable from "~/components/templates/EditRecommendFrequency/const";
import { useNavigate } from "react-router-dom";
import { ListHeader } from "./ListHeader";
import { BookInfoCard } from "~/components/molecules/BookInfoCard";
import { Text } from "~/components/atoms/texts/Text";
import { useTranslation } from "react-i18next";
import { ReserveBookButton } from "../../Bookshelf/ReserveBookButton";

type Props = {
  /**
   * おすすめの本一覧
   */
  recommends: Recommend[];
  /**
   * 一覧に表示する件数の設定
   */
  recommendFrequency?: RecommendFrequency;
  /**
   * 前回取得時の最新RecommendのID
   */
  latestRecommendId?: number;
};

export const RecommendList: React.VFC<Props> = ({
  recommends,
  latestRecommendId = 0,
  recommendFrequency = RecommendFrequency.Basic,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  // 新しく追加されたおすすめ本とすでに確認済みのおすすめ本一覧を分離する
  const recommendItems = React.useMemo(() => {
    return recommends.reduce(
      (result, recommend) => {
        return Number(latestRecommendId) < Number(recommend.id)
          ? { ...result, newItems: [...result.newItems, recommend] }
          : {
              ...result,
              confirmedItems: [...result.confirmedItems, recommend],
            };
      },
      { newItems: [], confirmedItems: [] }
    );
  }, [recommends, latestRecommendId]);

  const confirmedItems = recommendItems.confirmedItems;

  // ユーザーが設定した表示する件数など
  const freqSetting = recommendFrequencyTable[recommendFrequency];
  const restCount = freqSetting.border;
  const emptyCount =
    freqSetting.bookListMax - recommends.length > 0
      ? freqSetting.bookListMax - recommends.length
      : 0;

  return (
    <Container>
      <Text
        fontSize="SM"
        lineHeight="MD"
        bold
        style={{ width: "100%", maxWidth: "600px", margin: "0 auto" }}
      >
        {t<TranslateKeys>("ヨンデミー先生からおすすめされた本だよ！")}
      </Text>
      {recommends.length > 0 && <ReserveBookButton />}
      <ListHeader
        restCount={restCount}
        recommendLength={recommends.length}
        recommendFrequency={recommendFrequency}
      />
      <RecommendListContent>
        <EmptyRecommendList
          recommendFrequency={recommendFrequency}
          emptyCount={emptyCount}
        />
        {confirmedItems
          .slice()
          .sort((a, b) => b.id - a.id)
          .map((recommend, id) => (
            <BookInfoCard
              key={id}
              title={recommend.book.title || ""}
              imageUrl={recommend.book.imageUrl}
              recommendStatus={
                recommend.whenDone !== null
                  ? RecommendStatus.Ended
                  : recommend.readLog.count > 0
                  ? RecommendStatus.Reading
                  : RecommendStatus.Default
              }
              isRecommend={true}
              isInYomimashoka={false}
              yl={recommend.book.yl}
              onClick={() => {
                navigate(`/recommend/${recommend.id}`);
              }}
            />
          ))}
      </RecommendListContent>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.primitive.monotone.m0};
  border-radius: 0px 0px 12px 12px;
`;

const RecommendListContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;
