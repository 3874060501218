import styled from "styled-components";
import { Text } from "~/components/atoms/texts/Text";

export const TutorialStyle = {
  BottomButton: styled.button`
    background-color: ${({ theme }) => theme.colors.base.white};
    font-size: ${({ theme }) => theme.fontSize.LG};
    font-weight: bold;
    color: ${({ theme }) => theme.colors.tex.t700};
    border-top: 3px solid ${({ theme }) => theme.colors.base.lightGray};
    border-bottom: none;
    border-left: none;
    border-right: none;
    padding: 20px 0;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    @media screen and (min-width: 1024px) {
      width: 40%;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      border: 3px solid ${({ theme }) => theme.colors.base.lightGray};
      border-radius: ${({ theme }) => theme.deprecatedBorderRadius.MD};
    }
  `,
  CloseButtonContainer: styled.div`
    display: flex;
    flex-direction: row-reverse;
    padding: 16px 0;
  `,
  ContentContainer: styled.div`
    // NOTE: heightの値は全てのページが大体の端末で綺麗に収まるように決めている
    display: flex;
    flex-direction: column;
    height: calc((100vh - 120px) / 1.5);
    justify-content: space-around;
    align-items: center;
    @media screen and (max-height: 720px) {
      height: calc(100vh - 180px);
    }
  `,
  ImageContainer: styled.div`
    height: 415px;
    max-height: calc(100vh - 260px);
    min-height: 240px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 0;
  `,
  WordingContainer: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  Stack: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 14px;
  `,
  Text: styled(Text)`
    color: ${({ theme }) => theme.colors.tex.t800};
  `,
};
