import { MissionStorySetting } from "../type";

const baseDir = "/img/mission/complete_story/story_2";

export const CompleteStory2: MissionStorySetting[] = [
  {
    image: `${baseDir}/scene_1.jpg`,
    sound: "complete2_scene1_1",
    text: "「ふむふむ、この本は、ピクニックについて書かれているね」",
    ruby: [
      ...Array(8).fill(""),
      "ほん",
      ...Array(11).fill(""),
      "か",
      ...Array(7).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_1.jpg`,
    sound: "complete2_scene1_2",
    text: "「『いい天気の青空の下、広い広い原っぱにうさぎの家族がピクニックに来ています。』だって！」",
    ruby: [
      ...Array(4).fill(""),
      "てん",
      "き",
      "",
      "あお",
      "ぞら",
      "",
      "した",
      "",
      "ひろ",
      "",
      "ひろ",
      "",
      "はら",
      ...Array(7).fill(""),
      "か",
      "ぞく",
      ...Array(7).fill(""),
      "き",
      ...Array(11).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_2.jpg`,
    sound: "complete2_scene2",
    text: "よぉし、オイラに任せろ！　出てきた言葉でイメージが広がっていくぞ！",
    ruby: [
      ...Array(8).fill(""),
      "まか",
      ...Array(4).fill(""),
      "で",
      ...Array(3).fill(""),
      "こと",
      "ば",
      ...Array(6).fill(""),
      "ひろ",
      ...Array(7).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_3.jpg`,
    sound: "complete2_scene3_1",
    text: "「いい天気の青空！」「原っぱ！」「うさぎさん！」「お〜！おやつのドーナッツ！！」",
    ruby: [
      ...Array(3).fill(""),
      "てん",
      "き",
      "",
      "あお",
      "ぞら",
      ...Array(3).fill(""),
      "はら",
      ...Array(28).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_3.jpg`,
    sound: "complete2_scene3_2",
    text: "すごい！　太古のきょうりゅうは次々と筆でイメージを描いていきます。",
    ruby: [
      ...Array(5).fill(""),
      "たい",
      "こ",
      ...Array(8).fill(""),
      "つぎ",
      "つぎ",
      "",
      "ふで",
      ...Array(6).fill(""),
      "えが",
      ...Array(7).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_4.jpg`,
    sound: "complete2_scene4",
    text: "「ちょっとアンタ！　スーパーミラクルテンサイりんごちゃんの知恵を授けてあげるわ！」\n「スーパー？」「ミラクル？」「テンサイ？」",
    ruby: [
      ...Array(29).fill(""),
      "ち",
      "え",
      "",
      "さず",
      ...Array(30).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_5.jpg`,
    sound: "complete2_scene5_1",
    text: "「なんかわかんないけど、すっげ〜〜‼\n︙︙でもお前なんか偉そうだな😤」",
    ruby: [
      ...Array(24).fill(""),
      "まえ",
      ...Array(3).fill(""),
      "えら",
      ...Array(6).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_5.jpg`,
    sound: "complete2_scene5_2",
    text: "「ワタシは、知恵の実のりんごちゃんよ！\nワタシはテンサイだから、な〜んでも知っているの‼」",
    ruby: [
      ...Array(6).fill(""),
      "ち",
      "え",
      "",
      "み",
      ...Array(27).fill(""),
      "し",
      ...Array(7).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_6.jpg`,
    sound: "complete2_scene6_1",
    pageTurn: false,
    text: "「すごいね‼りんごちゃんの知恵を教えて！」「シャクシャク😁 いいわよ！　ここの情景が書かれている部分をじっくり味わってみて！",
    ruby: [
      ...Array(13).fill(""),
      "ち",
      "え",
      "",
      "おし",
      ...Array(22).fill(""),
      "じょう",
      "けい",
      "",
      "か",
      ...Array(5).fill(""),
      "ぶ",
      "ぶん",
      ...Array(5).fill(""),
      "あじ",
      ...Array(6).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_6.jpg`,
    sound: "complete2_scene6_2",
    pageTurn: false,
    text: "もっと豊かなイメージが広がるわよ！\nワタシがコツを教えてあげる！😁 」",
    ruby: [
      ...Array(3).fill(""),
      "ゆた",
      ...Array(7).fill(""),
      "ひろ",
      ...Array(13).fill(""),
      "おし",
      ...Array(9).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_7.jpg`,
    sound: "complete2_scene7_1",
    text: "「さあ、身体の感覚をたっぷりつかって、想像してみて！」",
    ruby: [
      ...Array(4).fill(""),
      "から",
      "だ",
      "",
      "かん",
      "かく",
      ...Array(10).fill(""),
      "そう",
      "ぞう",
      ...Array(6).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_7.jpg`,
    sound: "complete2_scene7_2",
    text: "「どんなふうに見える？　どんな手触り？　どんな音が聞こえる？　どんな味がする？　どんな匂いがしてる？」\nみるみると筆が大きくなってきました。",
    ruby: [
      ...Array(7).fill(""),
      "み",
      ...Array(7).fill(""),
      "て",
      "ざわ",
      ...Array(6).fill(""),
      "おと",
      "",
      "き",
      ...Array(8).fill(""),
      "あじ",
      ...Array(8).fill(""),
      "にお",
      ...Array(13).fill(""),
      "ふで",
      "",
      "おお",
      ...Array(11).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_8.jpg`,
    sound: "complete2_scene8",
    text: "よぉし、イメージが広がってきたぞ。",
    ruby: [...Array(9).fill(""), "ひろ", ...Array(7).fill("")],
  },
  {
    image: `${baseDir}/scene_9.jpg`,
    sound: "complete2_scene9",
    text: "太古のきょうりゅうは、大きな筆で思いっきり描きました。",
    ruby: [
      "たい",
      "こ",
      ...Array(9).fill(""),
      "おお",
      ...Array(2).fill(""),
      "ふで",
      "",
      "おも",
      ...Array(4).fill(""),
      "えが",
      ...Array(5).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_10.jpg`,
    sound: "complete2_scene10",
    text: "すると、なんと素適な景色が広がったことでしょう。まるで、本当にその場にいるかのようです。",
    ruby: [
      ...Array(7).fill(""),
      "す",
      "てき",
      "",
      "け",
      "しき",
      "",
      "ひろ",
      ...Array(14).fill(""),
      "ほん",
      "とう",
      ...Array(3).fill(""),
      "ば",
      ...Array(10).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_11.jpg`,
    sound: "complete2_scene11_1",
    text: "「おわーー‼‼　すっげーーーー！　オレってテンサイ⁉」\n「ふんっワタシのおかげなんだから！」",
    ruby: [...Array(46).fill("")],
  },
  {
    image: `${baseDir}/scene_11.jpg`,
    sound: "complete2_scene11_2",
    text: "「オイラがテンサイでいいじゃないかーー！　でもりんごちゃん、ありがとな！」\n「当たり前よ！」",
    ruby: [
      ...Array(39).fill(""),
      "あ",
      ...Array(2).fill(""),
      "まえ",
      ...Array(3).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_12.jpg`,
    sound: "complete2_scene12",
    text: "「わぁすごいねぇ、ぺんちゃん見て見て〜。\n君は、思い描くのが、とっても上手なんだね〜」\nおやおや？この声は、誰の声だ︙︙⁇",
    ruby: [
      ...Array(14).fill(""),
      "み",
      "",
      "み",
      ...Array(4).fill(""),
      "きみ",
      ...Array(2).fill(""),
      "おも",
      "",
      "えが",
      ...Array(8).fill(""),
      "じょう",
      "ず",
      ...Array(14).fill(""),
      "こえ",
      ...Array(2).fill(""),
      "だれ",
      "",
      "こえ",
      ...Array(5).fill(""),
    ],
  },
];
