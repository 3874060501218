import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { BadgesSquareCollection } from "~/components/templates/RecordBook/BadgeList/BadgesSquareCollection";
import { ALL_BUDGES, AllBadgeType, BadgeItem } from "~/helpers/constants/badge";
import { Row } from "~/components/atoms/layout/Row";
import { Text } from "~/components/atoms/texts/Text";
import { Stack } from "~/components/atoms/layout/Stack";
import { countAllBadgeNumber } from "~/components/templates/RecordBook/BadgeList/hook";

export type BadgeListRecordTemplateProps = {
  nickname: string;
  readBookSum: number;
  readCharSum: number;
  maxContinueReadLogDays: number;
};

const transformBadge = (type: AllBadgeType, threshold: number): BadgeItem[] => {
  const BADGE = ALL_BUDGES[type];
  return BADGE.THRESHOLDS.map((THRESHOLD) => {
    const isAcquired = threshold >= THRESHOLD;
    const fileName = `${BADGE.IMAGE_FILE_NAME_PREFIX}_${THRESHOLD}.png`;
    const imgSrc = isAcquired
      ? `/img/badge/1x/${fileName}`
      : `/img/badge/1x/m/${fileName}`;

    return { imgSrc, isAcquired };
  });
};

export const BadgeListRecordTemplate: React.FC<
  BadgeListRecordTemplateProps
> = ({ nickname, readBookSum, readCharSum, maxContinueReadLogDays }) => {
  const { t } = useTranslation();
  return (
    <Stack rowGap="16px" alignItems="center">
      <_CardWrapper>
        <Text fontColor="tex.t300" fontSize="XS" lineHeight="EQ">
          バッジ
        </Text>
        <Row alignItems="baseline">
          <Text fontColor="sec.s500" fontSize="XL" lineHeight="EQ" bold>
            {countAllBadgeNumber(
              readCharSum,
              maxContinueReadLogDays,
              readBookSum
            )}
          </Text>
          <Text fontColor="tex.t300" fontSize="XS" lineHeight="EQ">
            こ
          </Text>
        </Row>
      </_CardWrapper>
      <_BadgeListWrapper>
        <BadgesSquareCollection
          title={t<TranslateKeys>("読んだ文字数")}
          about={t<TranslateKeys>(
            "これまでに{{nickname}}さんが読んだ本の文字数の合計です。",
            { nickname }
          )}
          badges={transformBadge("readCharSum", readCharSum)}
        />
        <BadgesSquareCollection
          title={t<TranslateKeys>("読んだ本の冊数")}
          about={t<TranslateKeys>(
            "これまでに{{nickname}}さんが読んだ本の数です。",
            { nickname }
          )}
          badges={transformBadge("readBookSum", readBookSum)}
        />
        <BadgesSquareCollection
          title={t<TranslateKeys>("連続読書日数")}
          about={t<TranslateKeys>(
            "{{nickname}}さんの感想を出した日数の連続記録です。",
            { nickname }
          )}
          badges={transformBadge(
            "maxContinueReadLogDays",
            maxContinueReadLogDays
          )}
        />
      </_BadgeListWrapper>
    </Stack>
  );
};

const _BadgeListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 32px;

  background-color: ${({ theme }) => theme.colors.base.white};
  padding: 24px 16px;
  border-radius: 16px;
`;

const _CardWrapper = styled.div`
  width: 160px;
  background: ${({ theme }) => theme.colors.base.white};
  border-radius: 16px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;
