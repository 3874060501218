import React, { useCallback, useState } from "react";
import styled, { css } from "styled-components";
import {
  AdminChatMessage,
  UserType,
} from "~/components/organisms/adminMessage/AdminChatMessage";
import {
  DeletedUserOnTalkFragment,
  ReadLogOnTalkFragment,
  ReadLogReactionType,
  UserOnTalkFragment,
} from "~/generated/graphql";
import { Text } from "~/components/atoms/texts/Text";
import { WhitedLinkifyTextProps } from "~/components/molecules/texts/WhitedLinkifyText";
import { IconTextButton } from "~/components/molecules/buttons/IconTextButton";
import { Stack } from "~/components/atoms/layout/Stack";
import { Row } from "~/components/atoms/layout/Row";
import { ReactionButton } from "./ReactionButton";
import { useTranslation } from "~/i18n";
import { IMAGE_PATHS } from "~/helpers/constants/imagePath";
import { BfLockWrapper } from "~/components/organisms/Unlock/LockWrapper/bfLockWrapper";

import { WantReadBookButtonLockWrapper } from "~/components/organisms/Unlock/LockWrapper/wantReadBookButtonLockWrapper";
import { WantReadBookButton } from "./WantReadBookButton";
import { SenseiMessage } from "./SenseiMessage";

export type DeprecatedReadLogMessageProps = {
  item: ReadLogOnTalkFragment;
  user?: UserOnTalkFragment | DeletedUserOnTalkFragment;
  userType: UserType;
  className?: string;
  isInWantReadBookShelf?: boolean;
  isShowSenseiMessage?: boolean;
  onReaction: (id: number, reaction: ReadLogReactionType) => Promise<boolean>;
  onWantReadBook: (readLog: ReadLogOnTalkFragment) => Promise<boolean>;
  onCompleteShowSenseiMessage: (readLogId: number) => void;
};

export const DeprecatedReadLogMessage: React.FC<
  DeprecatedReadLogMessageProps
> = ({
  user,
  userType,
  item,
  className,
  isInWantReadBookShelf,
  isShowSenseiMessage,
  onReaction,
  onWantReadBook,
  onCompleteShowSenseiMessage,
}) => {
  const isShowReadLog =
    item !== undefined && item.like !== undefined ? item.like > 2 : false;
  const isWhiteText = userType === "me";
  const bookImageUrl = item.book.imageUrl
    ? item.book.imageUrl
    : IMAGE_PATHS.NO_IMAGE;
  const [isReviewExpanded, setIsReviewExpanded] = useState(false);
  const { t } = useTranslation();

  const extendMessage = (event: React.MouseEvent) => {
    event.preventDefault();
    setIsReviewExpanded(true);
    window.gtag("event", "extend-bf-message", {
      event_category: "bookfriend",
      event_label: "extend-bf-message",
      value: 1,
    });
  };

  const [reactionUpdating, setReactionUpdating] = useState<boolean>(false);
  const myReactions = item.myReaction;
  const onClickReaction = useCallback(
    (reaction: ReadLogReactionType) => {
      setReactionUpdating(true);
      onReaction(item.id, reaction).then(() => {
        setReactionUpdating(false);
      });
    },
    [onReaction, item]
  );

  const [wantReadBookUpdating, setWantReadBookUpdating] =
    useState<boolean>(false);
  const onClickWantReadBook = useCallback(() => {
    setWantReadBookUpdating(true);
    onWantReadBook(item).then(() => {
      setWantReadBookUpdating(false);
    });
  }, [onWantReadBook, item]);

  const onCompleted = React.useCallback(() => {
    onCompleteShowSenseiMessage(item.id);
  }, [item]);

  return (
    <AdminChatMessage userType={userType} user={user} className={className}>
      <BfLockWrapper userType={userType}>
        <BookInfo isWhite={isWhiteText}>
          <_ButtonAndTitleContainer>
            <BookTitleContainer>
              <BookTitle fontSize="SM" lineHeight="MD" isWhite={isWhiteText}>
                {item.book.title}
              </BookTitle>
              <BookAuthor fontSize="XS" lineHeight="MD" isWhite={isWhiteText}>
                {item.book.author}
              </BookAuthor>
            </BookTitleContainer>
            {userType === "user" && item.book.isbn !== "0" && (
              <WantReadBookButtonLockWrapper>
                <_WantReadBookButtonWrapper>
                  {isShowSenseiMessage && user?.__typename === "User" && (
                    <_SenseiMessageWrapper>
                      <SenseiMessage
                        readLogId={item.id}
                        translationParams={{ user: user?.nickname || "" }}
                        onCompleted={onCompleted}
                      />
                    </_SenseiMessageWrapper>
                  )}
                  <WantReadBookButton
                    onClick={onClickWantReadBook}
                    selected={!!isInWantReadBookShelf}
                    disabled={wantReadBookUpdating || !!isInWantReadBookShelf}
                  />
                </_WantReadBookButtonWrapper>
              </WantReadBookButtonLockWrapper>
            )}
          </_ButtonAndTitleContainer>
          <BookCoverContainer>
            <Image src={bookImageUrl} alt={item.book.title} />
          </BookCoverContainer>
        </BookInfo>
        {isShowReadLog && item.review && (
          <ReasonContainer>
            {item.review.length > 50 && !isReviewExpanded ? (
              <>
                <ReviewText
                  fontSize={"SM"}
                  lineHeight={"MD"}
                  isWhite={isWhiteText}
                >
                  {item.review.slice(0, 50) + "……"}
                </ReviewText>
                <ExpandButtonContainer>
                  <IconTextButton
                    text={"つづきをみる"}
                    iconPlace={"pre"}
                    name={"chevronArrowDown"}
                    color={isWhiteText ? "white" : "sec"}
                    onClick={extendMessage}
                    fontSize={"SM"}
                    lineHeight={"MD"}
                  />
                </ExpandButtonContainer>
              </>
            ) : (
              <ReviewText
                fontSize={"SM"}
                lineHeight={"MD"}
                isWhite={isWhiteText}
              >
                {item.review}
              </ReviewText>
            )}
          </ReasonContainer>
        )}
        {userType === "user" && (
          <ReactionWrapper>
            <Stack rowGap="8px">
              <ReactionLabel fontSize="XS" lineHeight="EQ">
                {t<TranslateKeys>("いいねを送る")}
              </ReactionLabel>
              <Row style={{ width: "100%" }}>
                <ReactionButton
                  reaction={ReadLogReactionType.Read}
                  disabled={
                    reactionUpdating ||
                    myReactions.includes(ReadLogReactionType.Read)
                  }
                  onClick={onClickReaction}
                  selected={myReactions.includes(ReadLogReactionType.Read)}
                />
                <ReactionButton
                  reaction={ReadLogReactionType.Like}
                  disabled={
                    reactionUpdating ||
                    myReactions.includes(ReadLogReactionType.Like)
                  }
                  onClick={onClickReaction}
                  selected={myReactions.includes(ReadLogReactionType.Like)}
                />
              </Row>
            </Stack>
          </ReactionWrapper>
        )}
      </BfLockWrapper>
    </AdminChatMessage>
  );
};

type WhiteStyleProps = {
  isWhite: boolean;
};

const ReactionLabel = styled(Text)`
  color: ${({ theme }) => theme.colors.tex.t400};
`;

const ReactionWrapper = styled.div`
  margin-top: 15px;
  padding-top: 15px;
  border-top: 2px solid ${({ theme }) => theme.colors.base.lightGray};
`;

const ReviewText = styled(Text)<WhitedLinkifyTextProps>`
  ${({ isWhite, theme }) => {
    return css`
      color: ${isWhite ? theme.colors.base.white : theme.colors.tex.t500};
    `;
  }}
`;
const BookTitle = styled(Text)<WhiteStyleProps>`
  color: ${({ theme, isWhite }) =>
    isWhite ? theme.colors.base.white : theme.colors.tex.t800};
`;
const BookAuthor = styled(Text)<WhiteStyleProps>`
  color: ${({ theme, isWhite }) =>
    isWhite ? theme.colors.base.white : theme.colors.tex.t300};
`;

const BookInfo = styled.div<WhiteStyleProps>`
  position: relative;
  display: flex;
  width: 100%;
  margin-bottom: 15px;
  border-radius: 8px;
  gap: 5%;
  padding: 8px 12px;
  background: ${({ theme, isWhite }) =>
    isWhite ? theme.colors.pri.p500 : theme.colors.base.background};
`;

const _ButtonAndTitleContainer = styled.div`
  width: 65%;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

const BookTitleContainer = styled.div`
  width: 100%;
`;

const BookCoverContainer = styled.div`
  width: 30%;
  max-width: 160px;
`;

const Image = styled.img`
  width: 100%;
  border-radius: ${({ theme }) => theme.deprecatedBorderRadius.SM};
`;

const ReasonContainer = styled.div`
  width: 100%;
`;

const ExpandButtonContainer = styled.div`
  margin-top: 5px;
`;

const _SenseiMessageWrapper = styled.div`
  position: absolute;
  bottom: 55px;
  /* MEMO: 中央揃え */
  left: calc(50% - 109px);
`;

const _WantReadBookButtonWrapper = styled.div`
  position: relative;
`;
