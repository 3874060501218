import { ConfigType } from "./type";

export const stgConf: ConfigType = {
  ENV: "staging",

  PAGE_PREFIX: "/",
  API_HOST: "https://api.user.alnair.stg.gotoloop.dev/",
  USER_APP: "https://user.alnair.stg.gotoloop.dev/",
  PARENT_APP: "https://parent.alnair.stg.gotoloop.dev/",

  CSRFP_TOKEN_HEADER: "X-CSRFP-Token",
  RAP_TOKEN_HEADER: "X-RAP-Token",

  RAP_TOKEN_LENGTH: 5,
  REQUEST_REPEAT: 4,

  LIST_LENGTH: 10,
  MID_LIST_LENGTH: 30,
  BIG_LIST_LENGTH: 100,

  TALK_FETCH_NUMBER: 10,

  ACCESS_TOKEN_HEADER: "X-Access-Token",

  GA_MEASUREMENT_ID: "G-WMVJ55KZ3P",
  GOOGLE_TAG_MANAGER_ID: null,
  REPLY_GODOWN: 20,

  SENTRY_DSN:
    "https://4313fb30d1eb4faea12a217cf6ea83c5@o967000.ingest.sentry.io/5918055",

  AUTH0_DOMAIN: "yondemy-stg.jp.auth0.com",
  AUTH0_CLIENT_ID: "lADval0rbR4ibtxkptc3nv0ltvvEX0Hg",
  AUTH0_AUDIENCE: "https://api.user.alnair.stg.gotoloop.dev",
};
