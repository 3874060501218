import { MissionOrder, MissionState, UserIconType } from "~/generated/graphql";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  getUnlockStatuses,
  userIconProfile,
  userLockedIconImage,
} from "~/components/templates/EditProfile/const";
import { BaseModal } from "~/components/atoms/wrappers/BaseModal";
import { userIconImage } from "~/components/organisms/adminMessage/AdminChatMessage";
import { TranslateKeys } from "~/i18n";
import styled from "styled-components";
import { Stack } from "~/components/atoms/layout/Stack";
import { Text } from "~/components/atoms/texts/Text";
import { LargeButton } from "~/components/atoms/buttons/Button";

export type SelectUserIconModalProps = {
  selectedIconType: UserIconType;
  missionOrder: MissionOrder;
  missionState: MissionState;
  isDialogOpen: boolean;
  closeDialog: () => void;
  handleUpdateIcon: (iconType: UserIconType) => void;
};
export const SelectUserIconModal: React.FC<SelectUserIconModalProps> = ({
  selectedIconType,
  missionOrder,
  missionState,
  isDialogOpen,
  handleUpdateIcon,
  closeDialog,
}) => {
  const { t } = useTranslation();
  const USER_ICON_COLUMN_SIZE = 3;

  const unlockStatuses = React.useMemo(
    () => getUnlockStatuses(missionOrder, missionState),
    [missionOrder, missionState]
  );

  const [userIconType, setUserIconType] =
    useState<UserIconType>(selectedIconType);

  useEffect(() => {
    setUserIconType(selectedIconType);
  }, [selectedIconType]);

  const [selectedLockedIcon, setSelectedLockedIcon] = useState(false);

  return (
    <BaseModal
      open={isDialogOpen}
      onClose={() => {
        setUserIconType(selectedIconType);
        closeDialog();
      }}
    >
      <Stack rowGap="24px">
        <_IconList columnSize={USER_ICON_COLUMN_SIZE}>
          {unlockStatuses.map(({ icon, status }, index) => {
            if (status === "LOCKED" || status === "UNLOCKED_NEXT") {
              return (
                <LockedIcon
                  key={index}
                  icon={status === "UNLOCKED_NEXT" ? icon : undefined}
                  onClick={() => {
                    setUserIconType(icon);
                    setSelectedLockedIcon(true);
                  }}
                  isActive={userIconType === icon}
                />
              );
            }
            return (
              <_IconButton
                key={index}
                onClick={() => {
                  setUserIconType(icon);
                  setSelectedLockedIcon(false);
                }}
              >
                <_UserIcon
                  src={userIconImage[icon]}
                  alt={t<TranslateKeys>(userIconProfile[icon].name)}
                  isActive={userIconType === icon}
                />
                {selectedIconType === icon && <_SelectedBatch />}
              </_IconButton>
            );
          })}
        </_IconList>
        <Stack rowGap="8px">
          <Text fontSize="XL" lineHeight="EQ" bold>
            {t<TranslateKeys>(
              userIconProfile[selectedLockedIcon ? "LOCKED" : userIconType].name
            )}
          </Text>
          <Text fontSize={"MD"} lineHeight={"MD"}>
            {t<TranslateKeys>(
              userIconProfile[selectedLockedIcon ? "LOCKED" : userIconType]
                .description
            )}
          </Text>
        </Stack>
        <LargeButton
          onClick={(event) => {
            event.preventDefault();
            handleUpdateIcon(userIconType as UserIconType);
            closeDialog();
          }}
          disabled={selectedLockedIcon}
          color={"primary"}
          style={{ margin: "0 auto" }}
        >
          <Text
            fontSize="XL"
            lineHeight="EQ"
            bold
            fontColor="primitive.monotone.m0"
          >
            {t<TranslateKeys>("これに決める")}
          </Text>
        </LargeButton>
      </Stack>
    </BaseModal>
  );
};

const _IconList = styled.div<{ columnSize: number }>`
  grid-template-columns: repeat(${(props) => props.columnSize}, 1fr);
  width: 100%;
  display: grid;
  row-gap: 24px;
  justify-items: center;
`;

const _IconWrapper = styled.div`
  position: absolute;
  right: 0;
  display: inline-block;
  border-radius: 50%;
  padding: 8px;
  background-color: ${({ theme }) => theme.colors.pri.p500};
  z-index: 1;
`;

const _SelectedBatch = styled(_IconWrapper)`
  background-color: #eb5856;
  border: 1px solid ${({ theme }) => theme.colors.semantic.layout.backgroundBox};
`;

const _IconButton = styled.button`
  width: fit-content;
  position: relative;
  display: inline-block;
  border: none;
  background-color: ${({ theme }) => theme.colors.base.white};
  padding: 0px;

  &:focus,
  &:active {
    outline: none;
  }
`;

const _UserIcon = styled.img<{ isActive: boolean }>`
  width: 72px;
  height: 72px;
  border-radius: 50%;
  outline: ${({ isActive }) => (isActive ? "3px solid" : "none")};
  outline-offset: 3px;
  outline-color: ${({ theme }) => theme.colors.primitive.orange.o400};
  @media screen and ${({ theme }) =>
      theme.breakPoints.maxWidthSmallSmartPhone} {
    width: 56px;
    height: 56px;
  }
`;

type LockedIconProps = {
  icon?: UserIconType;
  onClick: () => void;
  isActive: boolean;
};

const LockedIcon = ({ icon, onClick, isActive }: LockedIconProps) => (
  <_IconButton onClick={onClick}>
    <_UserIcon
      src={icon ? userLockedIconImage[icon] : "/img/icon/locked_icon.jpg"}
      alt={"locked-icon"}
      isActive={isActive}
    />
  </_IconButton>
);
