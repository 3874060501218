import React, { useState, useCallback } from "react";
import styled, { useTheme } from "styled-components";
import { BookImageLinkList } from "~/components/organisms/List/BookImageLinkList";
import { BookReviewList } from "~/components/organisms/List/BookReviewList";
import { BookImageLinkListItemProps } from "~/components/molecules/ListItem/BookImageLinkListItem";
import { BookInfoCardProps } from "~/components/molecules/BookInfoCard";
import { config } from "../../../../../config";
import { ToggleSwitch } from "~/components/atoms/forms/ToggleSwitch";
import { Text } from "~/components/atoms/texts/Text";
import { Row } from "~/components/atoms/layout/Row";
import { Stack } from "~/components/atoms/layout/Stack";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "~/i18n";

export const displayTypeImageList = "imageList";
export const displayTypeReviewList = "reviewList";

export type BookshelfDisplayType =
  | typeof displayTypeImageList
  | typeof displayTypeReviewList;

export type BookshelfRecordTemplateProps = {
  begin: number;
  recommends: (BookImageLinkListItemProps & {
    review?: string;
    yl?: number;
    like?: number;
  })[];
  totalCount?: number;
  setBegin: (value: number) => void;
  defaultDisplayType?: BookshelfDisplayType;
};

export const BookshelfRecordTemplate: React.FC<
  BookshelfRecordTemplateProps
> = ({
  recommends,
  totalCount = 0,
  begin,
  setBegin,
  defaultDisplayType = displayTypeImageList,
}) => {
  const [displayType, setDisplayType] =
    useState<BookshelfDisplayType>(defaultDisplayType);
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onChangeListStyle = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      if (displayType === displayTypeReviewList) {
        setDisplayType(displayTypeImageList);
      } else {
        setDisplayType(displayTypeReviewList);
      }
    },
    [displayType]
  );

  const hasItems = recommends.length > 0;

  const reviewItems: BookInfoCardProps[] = recommends.map((item) => {
    return {
      title: item.title || "",
      imageUrl: item.imageUrl,
      isInYomimashoka: false,
      yl: item.yl,
      onClick: () => {
        navigate(`/recommend/${String(item.recommendId)}`);
      },
      review: item.review,
      date: item.date ?? new Date(),
      like: item.like,
    };
  });

  const historyItems: BookImageLinkListItemProps[] = recommends.map((item) => {
    return {
      bookId: String(item.recommendId),
      recommendId: item.recommendId,
      title: item.title || "",
      imageUrl: item.imageUrl,
      columnsNumber: 3,
      status: item.status,
      showStatus: item.showStatus,
    };
  });

  return (
    <Stack rowGap="16px">
      <_CountWrapper>
        <Text fontSize="XS" lineHeight="EQ" color="tex.t300">
          {t<TranslateKeys>("読んだ本")}
        </Text>
        <Row alignItems="baseline" justifyContent="flex-end">
          <Text fontSize="XL" fontColor="sec.s500" lineHeight="EQ">
            {totalCount}
          </Text>
          <Text fontSize="XS" lineHeight="EQ" color="tex.t300">
            {t<TranslateKeys>("冊")}
          </Text>
        </Row>
      </_CountWrapper>
      {hasItems && (
        <_ToggleWrapper>
          <_GridIcon fill={theme.colors.pri.p400} />
          <ToggleSwitch
            isOnRight={displayType === displayTypeReviewList}
            onChange={onChangeListStyle}
          />
          <_ListIcon fill={theme.colors.pri.p400} />
        </_ToggleWrapper>
      )}
      {displayType === displayTypeReviewList ? (
        <BookReviewList
          currentCount={begin}
          items={reviewItems}
          itemTotal={totalCount}
          itemsPerPage={config.MID_LIST_LENGTH}
          onPageChange={setBegin}
        />
      ) : (
        <BookImageLinkList
          currentCount={begin}
          items={historyItems}
          itemTotal={totalCount}
          itemsPerPage={config.MID_LIST_LENGTH}
          onPageChange={setBegin}
        />
      )}
    </Stack>
  );
};

const _CountWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.base.background};
  border-radius: 16px;
  width: 192px;
  padding: 16px;
  margin: 0 auto;

  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

const _ToggleWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 12px;
`;

const _GridIcon: React.FC<{ fill: string }> = ({ fill }) => (
  <svg width={17} height={17} viewBox="0 0 17 17" fill="none">
    <rect x="9.5625" width="7.4375" height="7.4375" rx={1} fill={fill} />
    <rect width="7.4375" height="7.4375" rx={1} fill={fill} />
    <rect y="9.5625" width="7.4375" height="7.4375" rx={1} fill={fill} />
    <rect
      x="9.5625"
      y="9.5625"
      width="7.4375"
      height="7.4375"
      rx={1}
      fill={fill}
    />
  </svg>
);

const _ListIcon: React.VFC<{ fill: string }> = ({ fill }) => (
  <svg width={22} height={17} viewBox="0 0 22 17" fill="none">
    <path
      d="M0 1C0 0.447715 0.447715 0 1 0H6C6.55228 0 7 0.447715 7 1V6C7 6.55228 6.55228 7 6 7H1C0.447715 7 0 6.55228 0 6V1Z"
      fill={fill}
    />
    <path
      d="M0 11C0 10.4477 0.447715 10 1 10H6C6.55228 10 7 10.4477 7 11V16C7 16.5523 6.55228 17 6 17H1C0.447715 17 0 16.5523 0 16V11Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 0.5C9 0.223858 9.22386 0 9.5 0H21.5C21.7761 0 22 0.223858 22 0.5C22 0.776142 21.7761 1 21.5 1H9.5C9.22386 1 9 0.776142 9 0.5Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 10.5C9 10.2239 9.22386 10 9.5 10H21.5C21.7761 10 22 10.2239 22 10.5C22 10.7761 21.7761 11 21.5 11H9.5C9.22386 11 9 10.7761 9 10.5Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 3.5C9 3.22386 9.22386 3 9.5 3H21.5C21.7761 3 22 3.22386 22 3.5C22 3.77614 21.7761 4 21.5 4H9.5C9.22386 4 9 3.77614 9 3.5Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 13.5C9 13.2239 9.22386 13 9.5 13H21.5C21.7761 13 22 13.2239 22 13.5C22 13.7761 21.7761 14 21.5 14H9.5C9.22386 14 9 13.7761 9 13.5Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 6.5C9 6.22386 9.22386 6 9.5 6H21.5C21.7761 6 22 6.22386 22 6.5C22 6.77614 21.7761 7 21.5 7H9.5C9.22386 7 9 6.77614 9 6.5Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 16.5C9 16.2239 9.22386 16 9.5 16H21.5C21.7761 16 22 16.2239 22 16.5C22 16.7761 21.7761 17 21.5 17H9.5C9.22386 17 9 16.7761 9 16.5Z"
      fill={fill}
    />
  </svg>
);
