import styled, { useTheme } from "styled-components";
import * as React from "react";
import ReactSelect, {
  CSSObjectWithLabel,
  DropdownIndicatorProps,
  GroupBase,
  MenuListProps,
  SelectInstance,
  components as SelectComponents,
  SelectComponentsConfig,
} from "react-select";

export type SelectRef = SelectInstance<
  Option<string>,
  false,
  GroupBase<Option<string>>
>;

type SelectboxProps<TValue extends string = string> = {
  options: Option<TValue>[];
  value?: TValue;
  onChange?: (value: TValue) => void;
  hasError?: boolean;
  placeholder?: string;
  dropdownFooter?: React.ReactNode;
  fullWidth?: boolean;
  searchable?: boolean;
  noOptionsMesssage?: React.ReactNode;
  placeholderStyle?: CSSObjectWithLabel;
  disabled?: boolean;

  selectRef?: Parameters<typeof ReactSelect<Option, false>>[0]["ref"];
};

export type Option<TValue extends string = string> = {
  node: React.ReactNode;
  valueNode?: React.ReactNode;
  value: TValue;
  isDisabled?: boolean;
  searchText?: string;
};

/* eslint @typescript-eslint/explicit-module-boundary-types: 0 */
export const Selectbox = <TValue extends string = string>({
  value,
  onChange,
  placeholder,
  options,
  hasError = false,
  fullWidth = true,
  disabled,
  dropdownFooter,
  searchable = false,
  noOptionsMesssage = "条件に該当するものが見つかりませんでした",
  placeholderStyle,
  selectRef,
}: SelectboxProps<TValue>) => {
  const minWidth = fullWidth ? "100%" : "100px";
  const maxWidth = "100%";

  const theme = useTheme();

  const [styles, components] = React.useMemo(() => {
    const {
      fontSize,
      zIndex,
      size,
      borderRadius,
      colors: { primitive, semantic },
    } = theme;

    const colors = {
      disabledFill: semantic.layout.border,
      highlightFill: primitive.orange.o100,
      backgroundFill: primitive.monotone.m0,
      enable: semantic.primary.plus1,
      negative: semantic.alert.main,
      disabled: semantic.text.placeholder,
      backgroundFillDarken: primitive.monotone.m20,
      text: semantic.text.body,
      placeholder: semantic.text.placeholder,
      border: semantic.layout.borderPlus1,
    };

    const _styles = {
      control: (provided, state) => {
        return {
          ...provided,
          border: `2px solid ${
            hasError
              ? colors.negative
              : disabled
              ? colors.disabled
              : colors.border
          }`,
          borderRadius: borderRadius.s2,
          minHeight: "52px",
          backgroundColor: state.isDisabled
            ? colors.disabledFill
            : colors.backgroundFill,
          minWidth,
          maxWidth,
          display: "inline-flex",
          alignItems: "center",
          paddingTop: "0px",

          ":hover": {
            ...provided[":hover"],
            borderColor: hasError ? colors.negative : colors.enable,
            cursor: "pointer",
            backgroundColor: colors.backgroundFill,
          },
          ":disabled": {
            ...provided[":disabled"],
            borderColor: colors.disabled,
            backgroundColor: colors.disabledFill,
          },
          ":focus-within": {
            ...provided[":focus-within"],
            borderColor: hasError ? colors.negative : colors.enable,
            backgroundColor: colors.backgroundFill,
            boxShadow: "none",
          },
        };
      },
      valueContainer: (provided) => {
        return {
          ...provided,
          paddingLeft: "15px",
          paddingBottom: "3px",
          paddingTop: "3px",
          fontSize: fontSize.MD,
        };
      },
      singleValue: (provided) => {
        return {
          ...provided,
          color: colors.text,
        };
      },
      input: (provided) => {
        return {
          ...provided,
          color: colors.text,
          fontSize: fontSize.MD,
          minWidth: "20px",
          cursor: "text",
        };
      },
      option: (provided, state) => {
        return {
          ...provided,
          padding: size.s2,
          backgroundColor: state.isDisabled
            ? colors.disabledFill
            : state.isFocused
            ? colors.highlightFill
            : colors.backgroundFill,

          transition: "background-color 0.2s",

          color: colors.text,
          fontSize: fontSize.LG,

          cursor: "pointer",

          pointerEvents: state.isDisabled ? "none" : "inherit",

          ":active": {
            backgroundColor: colors.backgroundFill,
          },
        };
      },
      menu: (base) => ({
        ...base,
        width: "max-content",
        minWidth: "100%",
      }),
      placeholder: (provided) => {
        return {
          ...provided,
          color: colors.placeholder,
          fontSize: fontSize.MD,
          ...(placeholderStyle || {}),
        };
      },
      indicatorSeparator: () => ({ display: "none" }),
      indicatorsContainer: () => ({
        width: "36px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        transform: "none",
      }),
      menuPortal: (provided) => {
        return {
          ...provided,
          zIndex: zIndex.select,
        };
      },
    };

    const _components: Partial<
      SelectComponentsConfig<Option<string>, false, GroupBase<Option<string>>>
    > = {
      NoOptionsMessage: ({ ...props }) => (
        <SelectComponents.NoOptionsMessage {...props}>
          {noOptionsMesssage}
        </SelectComponents.NoOptionsMessage>
      ),

      MenuList: ({
        children,
        ...props
      }: MenuListProps<Option<string>, false>) => (
        <SelectComponents.MenuList {...props}>
          {children}
          {dropdownFooter && <_FooterWrapper>{dropdownFooter}</_FooterWrapper>}
        </SelectComponents.MenuList>
      ),

      DropdownIndicator: ({
        selectProps,
        isFocused,
      }: DropdownIndicatorProps<Option<string>, false>) => (
        <_DropdownTarget
          iconColor={
            selectProps.isDisabled
              ? colors.disabled
              : hasError
              ? colors.negative
              : isFocused
              ? colors.enable
              : colors.border
          }
          style={{
            transform: selectProps.menuIsOpen ? "rotate(180deg)" : "none",
          }}
          width="16"
          height="11"
          viewBox="0 0 16 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M6.98486 9.74365L0.344238 3.10303C-0.114746 2.64404 -0.114746 1.90186 0.344238 1.44775L1.44775 0.344238C1.90674 -0.114746 2.64893 -0.114746 3.10303 0.344238L7.81006 5.05127L12.5171 0.344238C12.9761 -0.114746 13.7183 -0.114746 14.1724 0.344238L15.2759 1.44775C15.7349 1.90674 15.7349 2.64893 15.2759 3.10303L8.63525 9.74365C8.18604 10.2026 7.44385 10.2026 6.98486 9.74365V9.74365Z" />
        </_DropdownTarget>
      ),
    };

    return [_styles, _components];
  }, [
    theme,
    hasError,
    disabled,
    minWidth,
    maxWidth,
    placeholderStyle,
    dropdownFooter,
    noOptionsMesssage,
  ]);

  const filterOption = React.useCallback((option, input) => {
    const searchText = option.data.searchText || "";
    return searchText.includes(input);
  }, []);

  const formatOptionLabel = React.useCallback((o, meta) => {
    if (meta.context === "value" && o.valueNode) {
      return o.valueNode;
    }
    return o.node;
  }, []);

  const selectedValue = React.useMemo(
    () => options.find((o) => o.value === value) || null,
    [value, options]
  );

  const handleOnChange = React.useCallback(
    (e) => {
      onChange && e?.value && onChange(e.value as TValue);
    },
    [onChange]
  );

  return (
    <_Container fullWidth={fullWidth}>
      <ReactSelect<Option, false>
        isDisabled={disabled}
        ref={selectRef}
        styles={styles}
        filterOption={filterOption}
        placeholder={placeholder}
        maxMenuHeight={800}
        menuPosition="fixed"
        menuPortalTarget={document.body}
        isSearchable={searchable}
        value={selectedValue}
        options={options}
        formatOptionLabel={formatOptionLabel}
        onChange={handleOnChange}
        components={components}
      />
    </_Container>
  );
};

const _FooterWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.base.lightGray};
`;

const _DropdownTarget = styled.svg<{ iconColor: string }>`
  position: absolute;
  right: ${({ theme }) => theme.size.M};
  top: 42%;
  path {
    fill: ${({ iconColor }) => iconColor};
  }
`;

const _Container = styled.div<{
  fullWidth?: boolean;
}>`
  position: relative;

  ${(props) => (props.fullWidth ? "width: 100%;" : "")};
`;
