import React, { useState } from "react";
import styled from "styled-components";
import { Text } from "~/components/atoms/texts/Text";
import { BaseModal } from "~/components/atoms/wrappers/modal";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import { PrimaryButton } from "~/components/molecules/buttons/PrimaryButton";
import { BalloonWithTail } from "~/components/molecules/wrappers/BalloonWithTail";
import { useBubbleModal } from "./hooks";

export type NewRecommendEndingModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const NewRecommendEndingModal: React.FC<NewRecommendEndingModalProps> =
  ({ isOpen, onClose }) => {
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const { currentUser } = React.useContext(CurrentUserContext);
    const nickname = currentUser?.general.nickname || "";
    const { buttons, content, imageUrl } = useBubbleModal(
      nickname,
      setCurrentIndex,
      onClose,
      currentIndex
    );

    return (
      <BaseModal isOpen={isOpen}>
        <_ModalWrapper>
          <BalloonWithTail>
            <Text fontSize={"MD"} lineHeight={"MD"} whiteSpace="pre-line">
              {content}
            </Text>
            <ButtonWrapper>
              {buttons.map(
                (el: { text: string; onClick: () => void }, i: number) => (
                  <PrimaryButton
                    key={i}
                    onClick={el.onClick}
                    disabled={false}
                    color={"pri"}
                    text={el.text}
                    withArrow={false}
                    fontSize={"XL"}
                    lineHeight={"EQ"}
                    bold
                    padding="16px 0"
                  />
                )
              )}
            </ButtonWrapper>
          </BalloonWithTail>
          <ImgCon>
            <img
              src={imageUrl}
              alt={"ヨンデミー先生です"}
              style={{ height: "100%" }}
            />
          </ImgCon>
        </_ModalWrapper>
      </BaseModal>
    );
  };

const _ModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  align-items: center;
  justify-content: flex-end;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.size.S};
`;

const ImgCon = styled.div``;
