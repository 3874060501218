import React, { VFC } from "react";
import styled, { css } from "styled-components";
import { Interpolation } from "~/styles/interpolation";
import TextareaAutoSize, {
  TextareaAutosizeProps,
} from "react-textarea-autosize";
import { useSnd } from "~/utils/hooks/useSnd";

export type AutoSizeTextAreaProps = TextareaAutosizeProps & {
  error?: boolean;
};

export const AutoSizeTextArea: VFC<AutoSizeTextAreaProps> = (props) => {
  const { error, onChange, ...textAreaProps } = props;
  const { play } = useSnd();
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange && onChange(event);
    play("TYPE");
  };
  return (
    <StyledTextarea {...textAreaProps} onChange={handleChange} error={error} />
  );
};

const StyledTextarea = styled(TextareaAutoSize)`
  ${({ theme }) => {
    const {
      colors,
      deprecatedBorderRadius: borderRadius,
      fontSize,
      lineHeight,
    } = theme;
    return css<Pick<AutoSizeTextAreaProps, "disabled" | "error">>`
      padding: 16px;
      width: 100%;
      height: 100%;
      resize: none;
      font-size: ${fontSize.MD};
      line-height: ${lineHeight.MD};
      border-radius: ${borderRadius.MD};
      color: ${colors.tex.t800};
      ${Interpolation.Form}
      &:active, &:focus {
        outline: none;
      }
    `;
  }}
`;
