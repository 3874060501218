import * as React from "react";
import Quagga from "@ericblade/quagga2";

// NOTE: 以下の公式のコードを参考にした
// https://github.com/ericblade/quagga2-react-example/blob/master/src/Scanner.js

const BarcodeScanner = (props: {
  onDetected: (result: string) => boolean;
  onError: () => void;
}): React.ReactElement => {
  React.useLayoutEffect(() => {
    let ignoreStart = false;
    const init = async () => {
      await new Promise((resolve) => setTimeout(resolve, 1));
      if (ignoreStart) {
        return;
      }
      Quagga.init(
        {
          inputStream: {
            name: "Live",
            type: "LiveStream",
            constraints: {
              width: { min: 300, max: 720 },
              height: { min: 300, max: 720 },
              facingMode: "environment",
              aspectRatio: { min: 271 / 120, max: 271 / 120 }, // ここをいじればカメラの表示サイズを変更可能
            },
          },
          locator: {
            patchSize: "medium",
            halfSample: true,
          },
          numOfWorkers: 2,
          frequency: 10,
          decoder: {
            readers: ["ean_reader"], // ISBNコードを読み取るdecoder
          },
          locate: true,
        },
        async (err) => {
          if (err) {
            console.error(err, "エラーが発生しました。");
            props.onError();
          } else {
            Quagga.start();
          }
          // スキャンを開始したら実行され、読み取りの枠を表示させる
          Quagga.onProcessed((result) => {
            const drawingCtx = Quagga.canvas.ctx.overlay,
              drawingCanvas = Quagga.canvas.dom.overlay;

            if (result) {
              if (result.boxes) {
                drawingCtx.clearRect(
                  0,
                  0,
                  Number(drawingCanvas.getAttribute("width")),
                  Number(drawingCanvas.getAttribute("height"))
                );
                result.boxes
                  .filter((box) => {
                    return box !== result.box;
                  })
                  .forEach((box) => {
                    Quagga.ImageDebug.drawPath(
                      box,
                      { x: 0, y: 1 },
                      drawingCtx,
                      {
                        color: "#a8ffac", // 読み取り開始したら表示される枠のデザイン
                        lineWidth: 2,
                      }
                    );
                  });
              }

              if (result.box) {
                Quagga.ImageDebug.drawPath(
                  result.box,
                  { x: 0, y: 1 },
                  drawingCtx,
                  {
                    color: "#73c3f8", // 読み取った時の枠のデザイン
                    lineWidth: 2,
                  }
                );
              }

              if (result.codeResult && result.codeResult.code) {
                Quagga.ImageDebug.drawPath(
                  result.line,
                  { x: "x", y: "y" },
                  drawingCtx,
                  {
                    color: "red",
                    lineWidth: 3, // 読み取ったバーコードの中間に横方向に表示される線のデザイン
                  }
                );
              }
            }
          });

          // バーコードが読み取れたらonDetectedが実行される
          Quagga.onDetected((successVal) => {
            const result = successVal.codeResult.code;
            if (result) {
              const isValidIsbn = props.onDetected(result);
              if (isValidIsbn) {
                ignoreStart = true;
                Quagga.stop();
                Quagga.offDetected();
                Quagga.offProcessed();
                Quagga.CameraAccess.release();
              }
            }
          });
        }
      );
    };

    init();

    return () => {
      ignoreStart = true;
      Quagga.stop();
      Quagga.offDetected();
      Quagga.offProcessed();
      Quagga.CameraAccess.release();
    };
  }, [props]);

  return <div id="interactive" className="viewport" />;
};

export default BarcodeScanner;
