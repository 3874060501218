import { useContext, useEffect } from "react";
import { Auth0Context } from "~/contexts/Auth0Context";
import { setUserProperties } from "./userProperties";
import { getLoggedInAt } from "./localStorage";

export const useUserPropertiesSync = (): void => {
  const { isAuthenticated } = useContext(Auth0Context);
  const loggedInAt = getLoggedInAt();

  useEffect(() => {
    if (isAuthenticated && loggedInAt) {
      setUserProperties({ logged_in_at: loggedInAt });
    } else if (!isAuthenticated) {
      setUserProperties({ logged_in_at: "" });
    }
  }, [isAuthenticated, loggedInAt]);
};
