import { ConfigType } from "./type";

export const epic0Conf: ConfigType = {
  ENV: "epic0",

  PAGE_PREFIX: "/",
  API_HOST: "https://api.user.alnair.test.gotoloop.dev/",
  USER_APP: "https://user.alnair.test.gotoloop.dev/",
  PARENT_APP: "https://parent.alnair.test.gotoloop.dev/",

  CSRFP_TOKEN_HEADER: "X-CSRFP-Token",
  RAP_TOKEN_HEADER: "X-RAP-Token",

  RAP_TOKEN_LENGTH: 5,
  REQUEST_REPEAT: 2,

  LIST_LENGTH: 10,
  MID_LIST_LENGTH: 30,
  BIG_LIST_LENGTH: 100,

  TALK_FETCH_NUMBER: 10,

  ACCESS_TOKEN_HEADER: "X-Access-Token",

  GA_MEASUREMENT_ID: "G-NLKQGN8V2Q",
  GOOGLE_TAG_MANAGER_ID: "GTM-NS4MWRX",

  REPLY_GODOWN: 20,

  SENTRY_DSN:
    "https://4313fb30d1eb4faea12a217cf6ea83c5@o967000.ingest.sentry.io/5918055",

  AUTH0_DOMAIN: "yondemy-epic0.jp.auth0.com",
  AUTH0_CLIENT_ID: "kLVJbUDDVbCngH1NzlbnrKP4pDcmsbXc",
  AUTH0_AUDIENCE: "https://api.user.alnair.test.gotoloop.dev",
};
