import React, { useState } from "react";
import styled from "styled-components";
import { NewRecommendItemModal } from "~/components/organisms/modals/NewRecommendItemModal";
import { NewRecommendEndingModal } from "~/components/organisms/modals/NewRecommendEndingModal";
import { IMAGE_PATHS } from "~/helpers/constants/imagePath";
import { BookshelfCurrentRecommendFragment as Recommend } from "~/generated/graphql";
import { useNewRecommendOpeningModal } from "./hooks";
import { OpenBoxAnimation } from "~/components/organisms/animation/OpenBoxAnimation";
import { BaseModal } from "~/components/atoms/wrappers/modal";
import { useAudio } from "~/utils/hooks/useAudio";
import { LargeButton } from "~/components/atoms/buttons/Button";
import { Text } from "~/components/atoms/texts/Text";
import { useTranslation } from "~/i18n";

export type PresentBoxListItemProps = {
  recommends: Recommend[];
  onCheckedAll: () => void;
};

export const PresentBoxListItem: React.VFC<PresentBoxListItemProps> = ({
  recommends,
  onCheckedAll,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { play } = useAudio({ preload: ["light", "initializeAudio"] });
  const { t } = useTranslation();

  const NewRecommendPages = {
    OPENING: 1,
    OPEN_BOX_ANIMATION: 2,
    RECOMMEND_ITEMS: 3,
    ENDING: 4,
  } as const;
  type NewRecommendPagesEnum = keyof typeof NewRecommendPages;
  const [newRecommendPage, setNewRecommendPage] =
    useState<NewRecommendPagesEnum>("OPENING");

  const openingModal = useNewRecommendOpeningModal({
    isOpen: isOpen && newRecommendPage === "OPENING",
    onClose: () => {
      play("initializeAudio");
      setNewRecommendPage("OPEN_BOX_ANIMATION");
    },
  });

  return (
    <_ListItem>
      <_PresentBoxWrapper>
        <Text fontSize="LG" lineHeight="MD" bold>
          {t<TranslateKeys>("おすすめの本が届いたよ")}
        </Text>
        <img
          src={IMAGE_PATHS.PRESENT_BOX}
          height="80px"
          alt="プレゼントボックス"
        />
        <LargeButton
          onClick={() => {
            setIsOpen(true);
          }}
        >
          {t<TranslateKeys>("届いた本を開ける")}
        </LargeButton>
      </_PresentBoxWrapper>
      {openingModal}
      {newRecommendPage === "OPEN_BOX_ANIMATION" && (
        <BaseModal isOpen={true}>
          <_AnimationWrapper>
            <OpenBoxAnimation
              onNext={() => setNewRecommendPage("RECOMMEND_ITEMS")}
            />
          </_AnimationWrapper>
        </BaseModal>
      )}
      <NewRecommendItemModal
        recommends={recommends}
        isOpen={isOpen && newRecommendPage === "RECOMMEND_ITEMS"}
        onClose={() => {
          setNewRecommendPage("ENDING");
        }}
        onCancel={() => {
          setIsOpen(false);
          setNewRecommendPage("OPENING");
        }}
      />
      <NewRecommendEndingModal
        isOpen={isOpen && newRecommendPage === "ENDING"}
        onClose={() => {
          onCheckedAll();
          setIsOpen(false);
          setNewRecommendPage("OPENING");
        }}
      />
    </_ListItem>
  );
};

const _ListItem = styled.div`
  display: flex;
  justify-content: center;
`;

const _PresentBoxWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.colors.primitive.yellow.y100};
  border-radius: 12px;
  padding: 12px 16px;
  gap: 12px;
`;

const _AnimationWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  max-height: 100svh;
  z-index: ${({ theme }) => theme.zIndex.modal};
`;
