import * as React from "react";
import styled from "styled-components";
import { LargeButton } from "~/components/atoms/buttons/Button";
import { Text } from "~/components/atoms/texts/Text";
import { BaseModal } from "~/components/atoms/wrappers/BaseModal";

type GetLetterModalProps = {
  open: boolean;
  onClose: () => void;
};

export const GetLetterModal: React.FC<GetLetterModalProps> = ({
  open,
  onClose,
}) => {
  return (
    <BaseModal open={open} onClose={onClose} closeOnOverlayClick={false}>
      <_Container>
        <div className="balloon">
          <div className="message">
            <Text fontSize="LG" lineHeight="MD" bold>
              おてがみがとどいているよ👀✨
            </Text>
          </div>
          <img src="/img/letter.svg" style={{ width: "163px" }} />
          <LargeButton onClick={onClose}>あけてみる</LargeButton>
        </div>
      </_Container>
    </BaseModal>
  );
};

const _Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .message {
    width: 100%;
  }

  .balloon {
    width: 100%;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    row-gap: 12px;
    align-items: center;
  }
`;
