import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { Stack } from "~/components/atoms/layout/Stack";
import { Text } from "~/components/atoms/texts/Text";
import { PrimaryButton } from "~/components/molecules/buttons/PrimaryButton";

export type PostReadLogAnimProps = {
  onNext: () => void;
  isGoNextByClick: boolean;
};

export const PostReadLogAnim: React.FC<PostReadLogAnimProps> = ({
  onNext,
  isGoNextByClick,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const videoSrc = "/video/postReadLogAnimation.mp4";
  const [canPlayVideo, setCanPlayVideo] = React.useState<boolean>(true);
  const [isPlaying, setIsPlaying] = React.useState<boolean>(false);
  const onPlay = () => setIsPlaying(true);

  useEffect(() => {
    videoRef.current?.addEventListener("ended", onNext);
  }, [onNext]);

  useEffect(() => {
    const video = videoRef.current;

    if (!video) {
      return;
    }
    video.volume = 0.2;
    video.addEventListener("play", onPlay);

    video.addEventListener("play", onPlay);
    const handleLoadMetaData = () => {
      const playPromise = video.play();
      video.muted = false;

      if (playPromise !== undefined) {
        const timeoutId = setTimeout(() => {
          setCanPlayVideo(false);
          console.log("timeout");
        }, 5000);
        playPromise
          .then(() => {
            clearTimeout(timeoutId);
            setCanPlayVideo(true);
          })
          .catch((error) => {
            clearTimeout(timeoutId);
            console.error(error);
            video.controls = true;
          });
      }
    };
    video.addEventListener("loadedmetadata", handleLoadMetaData);
    return () => {
      video.removeEventListener("play", onPlay);
      video.removeEventListener("loadedmetadata", handleLoadMetaData);
    };
  }, []);

  const handleVideoClick = (event: React.MouseEvent) => {
    event.preventDefault();
    if (videoRef.current && canPlayVideo && !isPlaying) {
      videoRef.current.muted = false;
      videoRef.current.play();
    } else {
      if (isGoNextByClick) {
        onNext();
      }
    }
  };

  return (
    <>
      <_Wrapper onClick={handleVideoClick}>
        {canPlayVideo ? (
          <video autoPlay playsInline muted controls={false} ref={videoRef}>
            <source src={videoSrc} type="video/mp4" />
            <_ErrorWrapper rowGap="16px">
              <Text fontSize="MD" lineHeight="EQ" fontColor="base.white">
                このブラウザでは動画の再生が許可されていません。
              </Text>
              <PrimaryButton
                fontSize="MD"
                lineHeight="MD"
                color="pri"
                text="次へ"
                onClick={onNext}
                bold
              />
            </_ErrorWrapper>
          </video>
        ) : (
          <_ErrorWrapper rowGap="16px">
            <Text fontSize="MD" lineHeight="EQ" fontColor="base.white">
              このブラウザでは動画の再生が許可されていません。
            </Text>
            <PrimaryButton
              fontSize="MD"
              lineHeight="MD"
              color="pri"
              text="次へ"
              onClick={onNext}
              bold
            />
          </_ErrorWrapper>
        )}
      </_Wrapper>
    </>
  );
};

const _Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  max-height: 100svh;
  background-color: #2f3885;

  display: flex;
  justify-content: center;
`;

const _ErrorWrapper = styled(Stack)`
  max-width: 600px;
  height: 100%;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
`;
