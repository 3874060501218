import React from "react";
import { PasswordInput, PasswordInputProps } from "../PasswordInput";
import { Label, LabelProps } from "~/components/atoms/wrappers/Label";
import { Text } from "~/components/atoms/texts/Text";
import styled from "styled-components";

export type LabeledPasswordInputProps = PasswordInputProps & {
  labelProps: Pick<LabelProps, "label" | "bold" | "color">;
} & {
  annotation?: string;
};

/**
  * @deprecated atoms/form/FormSlotとatoms/input/PasswordInputを組み合わせて使用してください
*/
export const LabeledPasswordInput: React.FC<LabeledPasswordInputProps> = ({
  labelProps,
  annotation,
  error,
  ...inputProps
}) => (
  <Label
    label={labelProps.label}
    bold={labelProps.bold}
    color={labelProps.color}
  >
    {annotation && (
      <AnnotationText fontSize={"XS"} lineHeight={"MD"}>
        {annotation}
      </AnnotationText>
    )}
    <PasswordInput error={error} {...inputProps} />
  </Label>
);

const AnnotationText = styled(Text)`
  margin: 4px 0 6px 0;
  color: ${({ theme }) => theme.colors.tex.t300};
`;
