import React from "react";
import styled from "styled-components";
import { Text } from "~/components/atoms/texts/Text";
import { BottomModal } from "~/components/atoms/wrappers/BottomModal";
import { PrimaryButton } from "~/components/molecules/buttons/PrimaryButton";
import { BalloonWithTail } from "~/components/molecules/wrappers/BalloonWithTail";
import { ReadLastBookSelectRecommend } from "~/generated/graphql";
import { useBubbleModal } from "./hooks";

type NewRecommendOpeningModalProps = {
  isOpen: boolean;
  onClose: () => void;
  isFirstRecommend: boolean;
  readBookRecommends: ReadLastBookSelectRecommend[];
  nickname: string;
  currentIndex: number;
  setCurrentIndex: (idx: number) => void;
};

export const NewRecommendOpeningModal: React.FC<
  NewRecommendOpeningModalProps
> = ({
  isOpen,
  onClose,
  isFirstRecommend,
  readBookRecommends,
  nickname,
  currentIndex,
  setCurrentIndex,
}) => {
  const { buttons, content, imageUrl } = useBubbleModal({
    nickname,
    isFirstRecommend,
    readBookRecommends,
    setCurrentIndex,
    onClose,
    currentIndex,
  });

  return (
    <BottomModal open={isOpen}>
      <_ModalWrapper>
        <BalloonWithTail>
          <Text fontSize={"MD"} lineHeight={"MD"} whiteSpace="pre-line">
            {content}
          </Text>
          <ButtonWrapper>
            {buttons.map(
              (el: { text: string; onClick: () => void }, i: number) => (
                <PrimaryButton
                  key={i}
                  onClick={el.onClick}
                  disabled={false}
                  color={"pri"}
                  text={el.text}
                  withArrow={false}
                  fontSize={"XL"}
                  lineHeight={"EQ"}
                  bold
                  padding="16px 0"
                />
              )
            )}
          </ButtonWrapper>
        </BalloonWithTail>
        <ImgCon src={imageUrl} alt={"ヨンデミー先生です"} />
      </_ModalWrapper>
    </BottomModal>
  );
};

const _ModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 20px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.size.S};
`;

const ImgCon = styled.img`
  width: 90px;
`;
