import React from "react";
import { useTranslation } from "react-i18next";
import { Row } from "~/components/atoms/layout/Row";
import { Stack } from "~/components/atoms/layout/Stack";
import { SectionTitle } from "~/components/molecules/texts/SectionTitle";
import { BfReadLogStarNum, ReadingTechniqueType } from "~/generated/graphql";
import { ReceivedStarItem } from "./ReceivedStarItem";
import { Text } from "~/components/atoms/texts/Text";
import styled from "styled-components";
import { TranslateKeys } from "~/i18n";

export type ReceivedStarProps = {
  receivedBfReadLogStar: BfReadLogStarNum[];
};

export const ReceivedStar: React.FC<ReceivedStarProps> = ({
  receivedBfReadLogStar,
}) => {
  const { t } = useTranslation();

  const receivedStarNumRecord = receivedBfReadLogStar.reduce(
    (record, receivedStar) => {
      record[receivedStar.readingTechniqueType] = receivedStar.num;
      return record;
    },
    {} as Record<ReadingTechniqueType, number>
  );

  const totalReceivedStarNum = receivedBfReadLogStar.reduce(
    (sum, receivedStar) => sum + receivedStar.num,
    0
  );

  return (
    <Stack rowGap="12px" style={{ width: "100%" }}>
      <SectionTitle>{t<TranslateKeys>("受け取った")}スター</SectionTitle>
      <Row columnGap="32px" style={{ position: "relative", width: "100%" }}>
        <Stack rowGap="16px" style={{ width: "100%" }}>
          <ReceivedStarItem
            readingTechniqueType={ReadingTechniqueType.Imagine}
            num={receivedStarNumRecord[ReadingTechniqueType.Imagine]}
          />
          <ReceivedStarItem
            readingTechniqueType={ReadingTechniqueType.Connect}
            num={receivedStarNumRecord[ReadingTechniqueType.Connect]}
          />
          <ReceivedStarItem
            readingTechniqueType={ReadingTechniqueType.Question}
            num={receivedStarNumRecord[ReadingTechniqueType.Question]}
          />
          <ReceivedStarItem
            readingTechniqueType={ReadingTechniqueType.Predict}
            num={receivedStarNumRecord[ReadingTechniqueType.Predict]}
          />
        </Stack>
        <_VerticalLine />
        <Stack rowGap="16px" style={{ width: "100%" }}>
          <ReceivedStarItem
            readingTechniqueType={ReadingTechniqueType.Acknowledge}
            num={receivedStarNumRecord[ReadingTechniqueType.Acknowledge]}
          />
          <ReceivedStarItem
            readingTechniqueType={ReadingTechniqueType.Judge}
            num={receivedStarNumRecord[ReadingTechniqueType.Judge]}
          />
          <ReceivedStarItem
            readingTechniqueType={ReadingTechniqueType.Interpret}
            num={receivedStarNumRecord[ReadingTechniqueType.Interpret]}
          />
          <Stack rowGap="4px" style={{ width: "100%" }}>
            <Text lineHeight="MD" fontSize="XS" bold={false}>
              {t<TranslateKeys>("全部")}で
            </Text>
            <div style={{ display: "flex", justifyContent: "right" }}>
              <Text lineHeight="EQ" fontSize="XS" bold={false}>
                <_NumSpan>{totalReceivedStarNum}</_NumSpan>
                {t<TranslateKeys>("個")}
              </Text>
            </div>
          </Stack>
        </Stack>
      </Row>
    </Stack>
  );
};

const _NumSpan = styled.span`
  color: ${({ theme }) => theme.colors.semantic.secondary.plus1};
  font-size: ${({ theme }) => theme.fontSize.XL};
  font-weight: bold;
  line-height: ${({ theme }) => theme.lineHeight.EQ};
`;

const _VerticalLine = styled.div`
  width: 1px;
  background-color: ${({ theme }) => theme.colors.semantic.layout.border};
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
`;
