import React from "react";
import styled, { useTheme } from "styled-components";
import { Icon } from "~/components/atoms/Icon";
import { Text } from "~/components/atoms/texts/Text";

export type ErrorMessageProps = {
  children: React.ReactNode;
  style?: React.CSSProperties;
};

export const ErrorMessage: React.FC<ErrorMessageProps> = ({ children, style }) => {
  const theme = useTheme();
  return (
    <Wrapper style={style}>
      <IconContainer>
        <Icon style={{ color: theme.colors.red.r400 }} name={"exclamationCircle"} />
      </IconContainer>
      <Message fontSize={"XS"} lineHeight={"MD"}>
        {children}
      </Message>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const IconContainer = styled.div`
  height: ${({ theme }) => theme.fontSize.XS};
  margin-right: ${({ theme }) => theme.size.XXS};
  display: flex;
  align-items: center;
`;

const Message = styled(Text)`
  color: ${({ theme }) => theme.colors.red.r400};
`;
