import React from "react";
import { useMemo } from "react";
import { ListItemProps } from "~/components/molecules/lists/MasonryPagiableList/types";
import { NextBookImageButtonListOtherItem } from "./NextBookItem/NextBookImageButtonListOtherItem";
import { Recommend } from "~/generated/graphql";
import { CircleArrowPagiableList } from "./CircleArrowPagiableList";
import { NextBookImageButtonListItem } from "./NextBookItem/NextBookImageButtonListItem";
import { BookImgButtonListItemProps } from "../../PostReadLog/SelectBookImgList/type";

type CommonNextBookImageButtonListItemProps = Omit<
  BookImgButtonListItemProps,
  "columnsNumber"
>;

export type SelectNextBookImgListProps = {
  data?: Pick<Recommend, "book" | "id">[];
  renderEmptyComponent: () => JSX.Element;
  onClick: (event: React.MouseEvent, isbn: string, title: string) => void;
  onClickOtherBooks: (event: React.MouseEvent) => void;
  hasOtherBook: boolean;
  loading?: boolean;
  hasNext: boolean;
  hasPrev: boolean;
  onChangePageToPrev: () => void;
  onChangePageToNext: () => void;
};

export const SelectNextBookImgList: React.FC<SelectNextBookImgListProps> = ({
  data,
  renderEmptyComponent,
  onClick,
  onClickOtherBooks,
  hasOtherBook,
  loading,
  hasNext,
  hasPrev,
  onChangePageToPrev,
  onChangePageToNext,
}) => {
  const normalItems: CommonNextBookImageButtonListItemProps[] = useMemo(() => {
    return data
      ? data.map((recommend) => {
          const { id, book } = recommend;
          return {
            recommendId: id,
            isbn: book.isbn,
            title: book.title ? book.title : "",
            imageUrl: book.imageUrl,
            isRead: false,
            bookImgItemType: "RECOMMEND",
          };
        })
      : [];
  }, [data]);

  const otherBookItem: CommonNextBookImageButtonListItemProps = {
    recommendId: null as unknown as number,
    isbn: null as unknown as string,
    title: null as unknown as string,
    imageUrl: null as unknown as string,
    bookImgItemType: "OTHER",
  };

  return (
    <CircleArrowPagiableList
      items={hasOtherBook ? [...normalItems, otherBookItem] : [...normalItems]}
      onChangePageToPrev={onChangePageToPrev}
      onChangePageToNext={onChangePageToNext}
      isLoading={loading}
      hasPrev={hasPrev}
      hasNext={hasNext}
      renderListItem={({
        item,
      }: ListItemProps<CommonNextBookImageButtonListItemProps>) => {
        const { recommendId, isbn, title, imageUrl, bookImgItemType } = item;
        if (bookImgItemType === "OTHER") {
          return (
            <NextBookImageButtonListOtherItem
              key={"isOtherItem"}
              onClick={(event) => onClickOtherBooks(event)}
            />
          );
        } else {
          return (
            <NextBookImageButtonListItem
              key={`${isbn}-${recommendId}`}
              title={title}
              imageUrl={imageUrl}
              onClick={(event) => onClick(event, isbn, title)}
            />
          );
        }
      }}
      renderEmptyComponent={renderEmptyComponent}
    />
  );
};
