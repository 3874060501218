type waitBookAnimImageKeys = "holdBooks" | "bookShelf";

export const waitBookAnimImages: Record<waitBookAnimImageKeys, string> = {
  holdBooks: "/img/sensei/hold_books.svg",
  bookShelf: "/img/initialize_modal/bookshelf.png",
} as const;

type openBoxAnimationImageKeys = "standUpNormal" | "light" | "bookShelf";

export const openBoxAnimationImages: Record<openBoxAnimationImageKeys, string> =
  {
    standUpNormal: "/img/sensei/standup_1.svg",
    light: "/img/initialize_modal/light.png",
    bookShelf: "/img/initialize_modal/bookshelf.png",
  } as const;

type writeReadLogImageKeys = "standUpNormal" | "writeReadLog" | "balloonTail";

export const writeReadLogImages: Record<writeReadLogImageKeys, string> = {
  standUpNormal: "/img/sensei/standup_1.svg",
  writeReadLog: "/img/initialize_modal/write_read_log.jpeg",
  balloonTail: "/img/misc/balloon_tail.svg",
} as const;

type postReadLogAnimImageKeys =
  | "grayBook"
  | "bookMark"
  | "riseRight"
  | "spreadLight"
  | "greenBook";

export const postReadLogAnimImages: Record<postReadLogAnimImageKeys, string> = {
  grayBook: "/img/initialize_modal/gray_book.png",
  bookMark: "/img/initialize_modal/book_mark.png",
  riseRight: "/img/initialize_modal/rise_light.png",
  spreadLight: "/img/initialize_modal/spread_light.png",
  greenBook: "/img/initialize_modal/green_book.png",
} as const;

type endingTutorialImageKeys = "standUpNormal" | "standUpSmile" | "standUpNotice";

export const endingTutorialImages: Record<endingTutorialImageKeys, string> = {
  standUpNormal: "/img/sensei/standup_1.svg",
  standUpSmile: "/img/sensei/standup_2.svg",
  standUpNotice: "/img/sensei/standup_8.svg",
} as const;

export const allImages: Record<string, string> = {
  ...waitBookAnimImages,
  ...openBoxAnimationImages,
  ...writeReadLogImages,
  ...postReadLogAnimImages,
  ...endingTutorialImages,
} as const;
