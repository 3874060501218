import React from "react";
import Confetti from "react-confetti";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Text } from "~/components/atoms/texts/Text";
import { useAudio } from "~/utils/hooks/useAudio";
import { BaseModal } from "~/components/atoms/wrappers/BaseModal";
import { LargeButton } from "~/components/atoms/buttons/Button";

export type LevelUpModalProps = {
  currentLevel: number;
  previousLevel: number;
  isOpen: boolean;
  onClose: () => void;
};

export const LevelUpModal: React.FC<LevelUpModalProps> = ({
  currentLevel,
  previousLevel,
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();

  const { play } = useAudio({ preload: ["fanfare"] });

  React.useEffect(() => {
    if (isOpen) {
      play("fanfare");
    }
  }, [isOpen, play]);

  return (
    <>
      <BaseModal open={isOpen} onClose={onClose}>
        <_ContentWrapper>
          <Text fontSize="MD" lineHeight="MD" fontColor="tex.t700" bold={true}>
            {t<TranslateKeys>("レベルアップ！")}
            <br />
            {t<TranslateKeys>("この調子！")}
          </Text>
          <_LevelUpWrapper>
            <_LevelTextWrapper>
              <Text
                fontSize="MD"
                lineHeight="EQ"
                fontColor="tex.t300"
                bold={true}
              >
                Lv.
              </Text>
              <Text
                fontSize="XXL"
                lineHeight="EQ"
                fontColor="tex.t300"
                bold={true}
              >
                {previousLevel}
              </Text>
            </_LevelTextWrapper>
            <_TriangleWrapper>
              <Triangle />
            </_TriangleWrapper>
            <Text
              fontSize="XXXXL"
              lineHeight="EQ"
              fontColor="pri.p500"
              bold={true}
            >
              {currentLevel}
            </Text>
          </_LevelUpWrapper>
          <LargeButton color="primary" onClick={onClose}>
            すすむ
          </LargeButton>
        </_ContentWrapper>
      </BaseModal>
      <Confetti width={getWidth()} height={getHeight()} />
    </>
  );
};

const getWidth = (): number => {
  return window.innerWidth;
};

const getHeight = (): number => {
  return window.innerHeight;
};

const _ContentWrapper = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  align-items: flex-start;
`;

const _LevelUpWrapper = styled.div`
  width: 100%;
  padding: 12px 64px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  column-gap: 12px;

  border: 3px solid;
  border-color: ${({ theme }) => theme.colors.semantic.layout.border};
  border-radius: 16px;
`;

const _LevelTextWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  column-gap: 2px;
`;

const Triangle: React.FC = () => (
  <svg
    width="8"
    height="10"
    viewBox="0 0 8 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5 4.13397C8.16667 4.51887 8.16667 5.48113 7.5 5.86603L1.5 9.33013C0.833334 9.71503 -4.47338e-07 9.2339 -4.13689e-07 8.4641L-1.10848e-07 1.5359C-7.71986e-08 0.766098 0.833333 0.284973 1.5 0.669873L7.5 4.13397Z"
      fill="#D5D2CD"
    />
  </svg>
);

const _TriangleWrapper = styled.div`
  margin-bottom: 4px;
`;
