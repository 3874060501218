import React from "react";
import styled from "styled-components";
import { Stack } from "~/components/atoms/layout/Stack";
import { BaseModal } from "~/components/atoms/wrappers/BaseModal";
import { Text } from "~/components/atoms/texts/Text";
import { Icon } from "~/components/atoms/Icon";
import { Row } from "~/components/atoms/layout/Row";
import { LargeButton } from "~/components/atoms/buttons/Button";

export type ReadEndExpTutorialModalProps = {
  isOpen: boolean;
  onClose: () => void;
  loading: boolean;
};

export const ReadEndExpTutorialModal: React.FC<
  ReadEndExpTutorialModalProps
> = ({ isOpen, onClose, loading }) => {
  return (
    <BaseModal open={isOpen} onClose={onClose}>
      <Stack alignItems="flex-start" rowGap="16px">
        <Row alignItems="flex-start" columnGap="8px">
          <_IconWrapper>
            <Icon
              name="question"
              color="tex"
              style={{ width: "20px", height: "20px" }}
            />
          </_IconWrapper>
          <Text lineHeight="MD" fontSize="MD" bold={true}>
            どくはボーナス✨
          </Text>
        </Row>
        <Text lineHeight="MD" fontSize="MD" bold={false}>
          どくはボーナスがもらえるのは
          <_MaxBooks>1日3冊まで</_MaxBooks>
          だよ！
          <br />
          ぜひ、毎日感想を教えてね😊
        </Text>
        <LargeButton color="primary" onClick={onClose} disabled={loading}>
          わかった！
        </LargeButton>
      </Stack>
    </BaseModal>
  );
};

const _IconWrapper = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
`;

const _MaxBooks = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.semantic.primary.main};
`;
