import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import {
  RecordTemplate,
  tabInfoList,
  PageTab,
} from "~/components/templates/RecordBook/Common";

export const SKIP_OPEN_ANIMATION_SESSION_STORAGE_KEY = "skipOpenAnimation";

export const RecordBook: React.FC = () => {
  const location = useLocation();
  const [skipOpenAnimation] = React.useState<boolean>(() => {
    try {
      const value =
        sessionStorage.getItem(SKIP_OPEN_ANIMATION_SESSION_STORAGE_KEY) ===
        "true";

      // NOTE: 感想提出後及び読書家の書から本の詳細画面またはSNSシェア画面へ移動した時にアニメーションをスキップする
      // 戻るボタンを押した時にremoveItemしているため通常はアニメーションが表示される
      sessionStorage.setItem(SKIP_OPEN_ANIMATION_SESSION_STORAGE_KEY, "true");

      return value;
    } catch (e) {
      console.error(e);
      return false;
    }
  });

  const tab: PageTab = React.useMemo(() => {
    const matchingTab = Object.entries(tabInfoList).find(([_, tabInfo]) =>
      location.pathname.includes(tabInfo.uri)
    );
    if (matchingTab) {
      return matchingTab[0] as PageTab;
    } else {
      return "bookshelf";
    }
  }, [location]);

  return (
    <RecordTemplate tab={tab} skipOpenAnimation={skipOpenAnimation}>
      <Outlet />
    </RecordTemplate>
  );
};
