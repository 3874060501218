import React from "react";
import styled, { useTheme } from "styled-components";
import { Text } from "~/components/atoms/texts/Text";

export const BR = {
  Wrapper: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${({ theme }) => theme.size.S};
  `,
  ButtonLabel: styled(Text)`
    width: 90%;
    color: ${({ theme }) => theme.colors.pri.p400};
    margin-top: ${({ theme }) => theme.size.S};
  `,
  ButtonCon: styled.div`
    width: 84px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: translateY(6px);
  `,
  InputsCon: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.size.XS};
  `,
  ErrorMessageCon: styled.div`
    margin-top: 4px;
  `,
  IconTextButtonCon: styled.div`
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
  `,
};

export const DialogStyle = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.size.XS};
    align-items: center;
    justify-content: center;
  `,
  Img: styled.img`
    width: 60%;
  `,
  Text: styled(Text)`
    color: ${({ theme }) => theme.colors.tex.t500};
  `,
};

export const BRTriangleArrow: React.VFC = () => {
  const theme = useTheme();
  return (
    <svg
      width="17"
      height="20"
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 10L-1.11265e-06 20L-2.38419e-07 -7.43094e-07L17 10Z"
        fill={theme.colors.pri.p400}
      />
    </svg>
  );
};
