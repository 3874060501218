import React from "react";
import styled from "styled-components";
import { ReadLog } from "~/generated/graphql";
import { Heading4 } from "~/components/atoms/texts/Heading4";
import { Text } from "~/components/atoms/texts/Text";
import { useTranslation } from "react-i18next";
import { TimeStamp } from "~/components/molecules/texts/TimeStamp";
import { LargeButton } from "~/components/atoms/buttons/Button";
import { Stack } from "~/components/atoms/layout/Stack";
import { useNavigate } from "react-router-dom";

export type OneReadLogProps = {
  allInfo: {
    __typename?: "ReadLog";
  } & Pick<ReadLog, "when" | "review" | "status" | "id">;
};

const _Wrapper = styled(Stack)`
  background-color: ${({ theme }) =>
    theme.colors.semantic.layout.backgroundBox};
  border-radius: 16px;
  padding: 16px;
  width: 100%;
  max-width: 632px;
  gap: 8px;
  border: 1px solid ${({ theme }) => theme.colors.semantic.primary.minus1};
`;

const _LargeButton = styled(LargeButton)`
  max-width: 600px;
`;

export const OneReadLog: React.FC<OneReadLogProps> = ({
  allInfo,
}: OneReadLogProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleEditReadLog = (logID: number) => {
    navigate(`/editreadlog/${logID}`);
  };

  if (allInfo.review == null) return <></>;
  return (
    <_Wrapper>
      <Heading4 fontSize="MD" lineHeight="EQ" bold>
        {t<TranslateKeys>("感想")}
      </Heading4>
      <Text fontSize="XS" lineHeight="EQ">
        <TimeStamp time={new Date(allInfo.when)} showYear inline />
        {t<TranslateKeys>("提出")}
      </Text>
      <Text fontSize="SM" lineHeight="MD">
        {allInfo.review}
      </Text>
      <_LargeButton
        onClick={() => handleEditReadLog(allInfo.id)}
        variant="blank"
      >
        {t<TranslateKeys>("編集する")}
      </_LargeButton>
    </_Wrapper>
  );
};
