import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { TutorialPostReadLogModal } from "~/components/organisms/modals/TutorialPostReadLogModal";
import { LoadingPage } from "~/components/templates/Loading/LoadingPage";
import { emotions } from "~/components/templates/PostReadLog/ConfirmEmotions/type";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import { usePostReadLogMutation } from "~/generated/graphql";
import { ConfirmDifficulty } from "./PostReadLogFlows/ConfirmDifficulty";
import { ConfirmEmotions } from "./PostReadLogFlows/ConfirmEmotions";
import { ConfirmLength } from "./PostReadLogFlows/ConfirmLength";
import { ConfirmLike } from "./PostReadLogFlows/ConfirmLike";
import { ConfirmReadStatus } from "./PostReadLogFlows/ConfirmReadStatus";
import { EnterReview } from "./PostReadLogFlows/EnterReview";
import { EnterUnregisteredBookIsbn } from "./PostReadLogFlows/EnterUnregisteredBookIsbn";
import { IndicateFeedback } from "./PostReadLogFlows/IndicateFeedback";
import { SelectBook } from "./PostReadLogFlows/SelectBook";
import { SubmitReadLog } from "./PostReadLogFlows/SubmitReadLog";
import { AllEmotionStates } from "./reducer";
import { usePostReadLogState } from "./usePostReadLogState";
import { IndicateExperiencePoint } from "./PostReadLogFlows/IndicateExperiencePoint";
import { PostReadLogAnimation } from "./PostReadLogFlows/PostReadLogAnimation";
import { IndicateUnlock } from "./PostReadLogFlows/IndicateUnlock";
import { IndicatePraiseReadingStreak } from "./PostReadLogFlows/IndicatePraiseReadingStreak";
import { IndicateBadge } from "./PostReadLogFlows/IndicateBadge";
import { useAudio } from "~/utils/hooks/useAudio";
import { FirstDislikeBook } from "./PostReadLogFlows/FirstDislikeBook";

export const PostReadLogMain: React.FC = () => {
  const { state, dispatch } = usePostReadLogState();
  const { updateExperience } = React.useContext(CurrentUserContext);
  useAudio({ preload: ["drumRoll", "fanfare"] });

  const location = useLocation();
  const initializing = location.pathname === "/postreadlog/init";

  React.useEffect(() => {
    if (initializing) {
      dispatch({ type: "resetState" });
      const searchParams = new URLSearchParams(location.search);
      searchParams.forEach((value, key) => {
        switch (key) {
          case "ISBN":
            dispatch({ type: "setBookISBN", payload: value });
            break;
          case "recommendID":
            dispatch({ type: "setRecommendID", payload: value });
            break;
          case "title":
            dispatch({ type: "setTitle", payload: value });
            break;
          case "readStatus":
            dispatch({ type: "setReadStatus", payload: value });
            break;
          case "flow":
            dispatch({ type: "setFlow", payload: value });
            break;
        }
      });

      navigate("/postreadlog", { replace: true });
    }
  }, [location, initializing]);

  const {
    recommendID,
    bookISBN,
    title,
    like,
    difficulty,
    length,
    readStatus,
    yomikikase,
    review,
    flow,
  } = state;

  const {
    wakuwaku,
    sikusiku,
    dokidoki,
    nikoniko,
    nattoku,
    waraeru,
    bikkuri,
    punpun,
    kowai,
    dousite,
  }: AllEmotionStates = state;

  const [postReadLog, { loading }] = usePostReadLogMutation({
    onCompleted(res) {
      updateExperience(
        res.postReadLog.account.general.userExperience,
        res.postReadLog.userExperienceEvent
      );

      dispatch({ type: "setPostReadLogResult", payload: res.postReadLog });
      dispatch({
        type: "setFlow",
        payload: "postReadLogAnimation",
      });
    },
    onError() {
      alert("感想の作成に失敗しました");
    },
  });

  const changeEmptyStringToNull = (str: string): string => {
    if (str == null) {
      return null as unknown as string;
    } else if (str === "") {
      return null as unknown as string;
    } else {
      return str;
    }
  };

  const navigate = useNavigate();

  const handlePost = (): void => {
    if (loading === true) return;
    const emotionStr = emotionStateToMutationVariables({
      wakuwaku,
      sikusiku,
      dokidoki,
      nikoniko,
      nattoku,
      waraeru,
      bikkuri,
      punpun,
      kowai,
      dousite,
    });
    postReadLog({
      variables: {
        readLogInfo: {
          recommendID: recommendID,
          bookISBN: bookISBN,
          title: title,
          like: like,
          difficulty: difficulty,
          length: length,
          status: readStatus,
          yomikikase: yomikikase,
          review: changeEmptyStringToNull(review),
          emotions: emotionStr,
        },
      },
    });
  };

  React.useEffect(() => {
    console.log(flow);
    if (!flow && !initializing) {
      navigate("/");
    }
  }, [flow]);

  const currentUserContext = React.useContext(CurrentUserContext);

  if (currentUserContext.loading || !currentUserContext.currentUser)
    return <LoadingPage />;
  if (currentUserContext.error)
    return <>error!{currentUserContext.error.message}</>;
  return (
    <>
      <TutorialPostReadLogModal
        nickname={currentUserContext.currentUser.general.nickname}
      />
      <FlowController
        handlePost={handlePost}
        loading={loading}
        nickname={currentUserContext.currentUser.general.nickname}
      />
    </>
  );
};

type FlowControllerProps = {
  handlePost: () => void;
  nickname: string;
  loading: boolean;
};

const FlowController: React.FC<FlowControllerProps> = ({
  handlePost,
  loading,
  nickname,
}) => {
  const {
    state: { flow },
  } = usePostReadLogState();
  switch (flow) {
    case "selectBook":
      return <SelectBook />;
    case "enterUnregisteredBookIsbn":
      return <EnterUnregisteredBookIsbn />;
    case "confirmReadStatus":
      return <ConfirmReadStatus />;
    case "confirmLike":
      return <ConfirmLike />;
    case "confirmDifficulty":
      return <ConfirmDifficulty />;
    case "confirmLength":
      return <ConfirmLength />;
    case "confirmEmotions":
      return <ConfirmEmotions nickname={nickname} />;
    case "enterReview":
      return <EnterReview />;
    // case "enterFavoriteScene":
    //   return <EnterFavoriteScene />;
    case "submitReadLog":
      return (
        <SubmitReadLog
          post={handlePost}
          loading={loading}
          nickname={nickname}
        />
      );
    case "postReadLogAnimation":
      return <PostReadLogAnimation />;
    case "indicateExperiencePoint":
      return <IndicateExperiencePoint />;
    case "indicateUnlock":
      return <IndicateUnlock />;
    case "indicatePraiseReadingStreak":
      return <IndicatePraiseReadingStreak />;
    case "indicateBadge":
      return <IndicateBadge />;
    case "indicateFeedback":
      return <IndicateFeedback />;
    case "firstDislikeBook":
      return <FirstDislikeBook />;
    default:
      return <></>;
  }
};

export const emotionStateToMutationVariables = (
  emotionValues: AllEmotionStates
): string => {
  let tmp = "";
  Object.keys(emotionValues).forEach((key) => {
    if (emotionValues[key]) {
      if (tmp === "") {
        tmp += emotions[key].ja;
      } else {
        tmp += "," + emotions[key].ja;
      }
    }
  });
  return tmp;
};
