import React, { useMemo } from "react";
import styled from "styled-components";
import { Text } from "~/components/atoms/texts/Text";
import {
  ListItemProps,
  MessageList as BaseMessageList,
} from "~/components/molecules/lists/MessageList";
import { BookFriendMessageListItem as BaseBookFriendMessageListItem } from "../BookFriendMessageListItem";
import {
  BfReadLogFragment,
  BfReadLogCacheAllFragment,
  ReadLogReactionType,
} from "~/generated/graphql";
import { SimplifiedReadLogMessageProps } from "../BookFriendMessageListItem/ReadLogMessage/SendStarModal/SimplifiedReadLogMessage";
import { SendStarMessageProps } from "../BookFriendMessageListItem/ReadLogMessage/SendStarMessage";

export type BookFriendMessageListProps = {
  data: BfReadLogCacheAllFragment;
  myId: number;
  prevFetchAll: boolean;
  nextFetchAll: boolean;
  onPrevLoading: (item: BfReadLogFragment) => Promise<void>;
  onNextLoading: (item: BfReadLogFragment) => Promise<void>;
  className?: string;
  onReaction: (
    id: string,
    readLogId: number,
    reaction: ReadLogReactionType
  ) => Promise<boolean>;
  firstItemIndex: number;

  readLogIdListForSenseiMessage: number[];

  onWantReadBook: (readLog: BfReadLogFragment) => Promise<boolean>;

  onCompleteShowSenseiMessage: (readLogId: number) => void;
  onOpenSendStarModal: (item: SimplifiedReadLogMessageProps) => void;
  canSendStar: boolean;
  readLogIdForSendStarMessage?: number;
  sendStarMessageProps: SendStarMessageProps;
};

type TalkItem = BfReadLogFragment & { firstUnreadItem?: boolean };

export const BookFriendMessageList: React.VFC<BookFriendMessageListProps> = ({
  data,
  myId,
  prevFetchAll,
  nextFetchAll,
  onPrevLoading,
  onNextLoading,
  onReaction,
  firstItemIndex,
  readLogIdListForSenseiMessage,
  onWantReadBook,
  onCompleteShowSenseiMessage,
  onOpenSendStarModal,
  canSendStar,
  readLogIdForSendStarMessage,
  sendStarMessageProps,
}) => {
  const items = useMemo(() => {
    const bfReadLogs = data.bfReadLogs;
    if (!data.latestBfReadLogViewTime) {
      return bfReadLogs;
    } else {
      const oldBfReadLogs = bfReadLogs.filter((bfReadLog) => {
        return (
          new Date(bfReadLog.createdAt).getTime() <=
          new Date(data.latestBfReadLogViewTime).getTime()
        );
      });
      const unreadBfReadLogs = bfReadLogs.filter((bfReadLog) => {
        return (
          new Date(bfReadLog.createdAt).getTime() >
          new Date(data.latestBfReadLogViewTime).getTime()
        );
      });
      return unreadBfReadLogs.length === 0
        ? oldBfReadLogs
        : [
            ...oldBfReadLogs,
            { ...unreadBfReadLogs[0], firstUnreadItem: true },
            ...unreadBfReadLogs.slice(1),
          ];
    }
  }, [data]);

  return (
    <MessageList
      firstItemIndex={firstItemIndex}
      items={items}
      onPrevLoading={onPrevLoading}
      onNextLoading={onNextLoading}
      fetchAll={prevFetchAll}
      nextFetchAll={nextFetchAll}
      renderListItem={({ item }: ListItemProps<TalkItem>) => {
        const talkItem = item as BfReadLogFragment;
        return (
          <div>
            {item.firstUnreadItem && (
              <UnReadBorderText fontSize={"SM"} lineHeight={"EQ"}>
                未読のメッセージ
              </UnReadBorderText>
            )}
            <BookFriendMessageListItem
              key={talkItem.id}
              myId={myId}
              talk={talkItem}
              onReaction={onReaction}
              onWantReadBook={onWantReadBook}
              isInWantReadBookShelf={talkItem.readLogInfo.isMyWantReadBook}
              isShowSenseiMessage={readLogIdListForSenseiMessage.includes(
                talkItem.readLogInfo.readLogId
              )}
              onCompleteShowSenseiMessage={onCompleteShowSenseiMessage}
              onOpenSendStarModal={onOpenSendStarModal}
              canSendStar={canSendStar}
              isShowSendStarMessage={
                readLogIdForSendStarMessage === talkItem.readLogInfo.readLogId
              }
              sendStarMessageProps={sendStarMessageProps}
            />
          </div>
        );
      }}
    />
  );
};

const UnReadBorderText = styled(Text)`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.pri.p400};
  margin: 20px 10px;
  &::before,
  ::after {
    border-top: 1.5px solid ${(props) => props.theme.colors.pri.p400};
    content: "";
    display: inline;
    flex-grow: 1;
  }
  &::before {
    margin: 0 0.5em;
  }
  &::after {
    margin: 0 0.5em;
  }
`;

const MessageList = styled(BaseMessageList)`
  background: ${(props) => props.theme.colors.base.ashGray};
`;

const BookFriendMessageListItem = styled(BaseBookFriendMessageListItem)`
  padding: 5px 20px;
  @media screen and (min-width: 600px) {
    padding: 5px 10%;
  }
`;
