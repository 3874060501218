import React from "react";
import styled from "styled-components";
import { RecommendFrequency } from "~/generated/graphql";

type BackgroundSetting = {
  height: string;
  scale: number;
};

const backgroundSettings: { [key in RecommendFrequency]: BackgroundSetting } = {
  LITTLE: { height: "173.5px", scale: 340 },
  BASIC: { height: "323px", scale: 70 },
  MANY: { height: "323px", scale: 70 },
  ENOUGH: { height: "532px", scale: 33 },
  BULK: { height: "532px", scale: 33 },
} as const;

type EmptyRecommendListProps = {
  recommendFrequency?: RecommendFrequency;
  emptyCount: number;
};

export const EmptyRecommendList: React.FC<EmptyRecommendListProps> = ({
  recommendFrequency = RecommendFrequency.Basic,
  emptyCount,
}) => {
  return (
    <EmptyListContent>
      {[...new Array(emptyCount)].map((_, i) => (
        <EmptyItem
          recommendFrequency={recommendFrequency}
          key={`empty_${i}`}
          index={i}
        ></EmptyItem>
      ))}
    </EmptyListContent>
  );
};

const EmptyListContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

type EmptyItemType = {
  recommendFrequency: RecommendFrequency;
  index: number;
};

const EmptyItem = styled.div<EmptyItemType>`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding-top: 26px;
  height: 134px;
  border-radius: 12px;
  box-sizing: border-box;
  box-shadow: inset 0px 2px 0px 1px rgba(0, 0, 0, 0.25);
  background-color: rgba(246, 246, 246, 0.5);
  background-image: ${`url("/img/sensei/standup_read.svg")`};
  background-blend-mode: lighten;
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: auto
    ${(props) => backgroundSettings[props.recommendFrequency].height};
  background-position-y: ${(props) =>
    `calc(${
      props.index * backgroundSettings[props.recommendFrequency].scale
    }% - ${
      props.index * 8
    }px + 46px)`}; // 枠の部分にキャラクターの大事な部分（鼻など）が当たらないように微調整
`;
