import React from "react";
import styled from "styled-components";
import { Icon } from "~/components/atoms/Icon";
import { Stack } from "~/components/atoms/layout/Stack";
import { Text } from "~/components/atoms/texts/Text";
import { SolidButton } from "~/components/atoms/buttons/SolidButton";
import { NotificationLineLink } from "../../../../../util/constant";

export const BookSelectionError: React.FC = () => {
  const handleClick = () => {
    window.location.href = NotificationLineLink;
  };
  return (
    <_Wrapper>
      <_Container>
        <_TitleWrapper>
          <_Icon name="exclamationTriangle" />
          選書エラー
        </_TitleWrapper>
        <_ContentWrapper>
          <Text fontSize="MD" fontColor="tex.t900" lineHeight="MD">
            初回選書に失敗しました。
            <br />
            不具合が発生している可能性があります。
            <br />
            大変お手数をおかけしますが、下記よりご連絡ください。
          </Text>
          <Stack rowGap="12px">
            <Text bold fontSize="MD" fontColor="tex.t900" lineHeight="MD">
              メールアドレス
            </Text>
            <_MailAddress href="mailto:cs@yondemy.com">
              cs@yondemy.com
            </_MailAddress>
          </Stack>
          <_LineWrapper rowGap="12px">
            <Text bold fontSize="MD" fontColor="tex.t900" lineHeight="MD">
              LINE相談窓口
            </Text>
            <SolidButton
              variant="Normal"
              shape="Normal"
              color="green"
              onClick={handleClick}
              style={{ padding: "16px" }}
            >
              <Text bold fontSize="XL" fontColor="base.white" lineHeight="EQ">
                LINEで友だち追加
              </Text>
            </SolidButton>
          </_LineWrapper>
        </_ContentWrapper>
      </_Container>
    </_Wrapper>
  );
};

const _Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  padding: 0 6%;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.base.background};
  @media screen and (min-width: 600px) {
    padding: 0 10%;
  }
  @media screen and (min-width: 1024px) {
    padding: 0 20%;
  }
`;

const _Container = styled.div`
  padding: 32px 0;
  display: flex;
  flex-direction: column;
  row-gap: 32px;
`;

const _TitleWrapper = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
  justify-content: flex-start;

  font-size: ${({ theme }) => theme.fontSize.XL};
  color: ${({ theme }) => theme.colors.red.r400};
  font-weight: 700;
`;

const _Icon = styled(Icon)`
  color: ${({ theme }) => theme.colors.red.r400};
`;

const _ContentWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.base.white};
  border-radius: 16px;
  padding: 48px 16px;

  display: flex;
  flex-direction: column;
  row-gap: 32px;
  align-items: flex-start;
`;

const _MailAddress = styled.a`
  color: ${({ theme }) => theme.colors.sec.s400};
  font-size: ${({ theme }) => theme.fontSize.MD};
  line-height: 160%;
  cursor: pointer;
`;

const _LineWrapper = styled(Stack)`
  width: 100%;
`;
