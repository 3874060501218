import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  Time: any;
};

export type Account = {
  __typename?: 'Account';
  createdAt: Scalars['Time'];
  kanziLevel: KanziLevel;
  birthday: Scalars['Time'];
  userPlan: UserPlan;
  belong?: Maybe<BfGroup>;
  myLibraryName?: Maybe<Scalars['String']>;
  recommendFrequency: RecommendFrequency;
  recommends: RecommendList;
  readLog: ReadLogList;
  general: User;
  haveUnreadInMessage: Scalars['Boolean'];
  haveUnreadInBfGroupMessage: Scalars['Boolean'];
  shouldReturnSomeReactive: Scalars['Boolean'];
  recommendTerm: RecommendList;
  readLogTerm: ReadLogList;
  ongoingPromise?: Maybe<ReadLogPromise>;
};


export type AccountRecommendsArgs = {
  id?: Maybe<Scalars['Int']>;
  done?: Maybe<Scalars['Boolean']>;
  self?: Maybe<Scalars['Boolean']>;
};


export type AccountReadLogArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type AccountRecommendTermArgs = {
  term: RecommendTerm;
};


export type AccountReadLogTermArgs = {
  createdAtFrom: Scalars['Time'];
  toTerm: ReadLogTerm;
};

export type AddWantReadBookInput = {
  inReadLogID: Scalars['Int'];
};

export type AnswerCharacterEnqueteInput = {
  answer1: CharacterEnqueteChoice;
  answer2: CharacterEnqueteChoice;
  answer3: CharacterEnqueteChoice;
  answer4: CharacterEnqueteChoice;
  answer5: CharacterEnqueteChoice;
  answer6: CharacterEnqueteChoice;
  characterType: CharacterType;
};

export type AnswerInput = {
  openEnded?: Maybe<OpenEndedAnswerInput>;
  choices?: Maybe<ChoicesAnswerInput>;
  dummy?: Maybe<DummyAnswerInput>;
};

export type AnswerPreBookRecommendEnqueteInput = {
  postPreBookRecommendLogID: Scalars['Int'];
  preBookRecommendEnquetes: Array<PreBookRecommendEnqueteInput>;
};

export type AnswerReactiveResult = {
  __typename?: 'AnswerReactiveResult';
  talkUpdate: TalkUpdate;
  userExperienceEvent?: Maybe<UserExperienceEvent>;
  userExperience?: Maybe<UserExperience>;
};

export enum AvailableBookType {
  Available = 'AVAILABLE',
  Ordered = 'ORDERED',
  Unavailable = 'UNAVAILABLE',
  Unknown = 'UNKNOWN'
}

export type BfGroup = {
  __typename?: 'BfGroup';
  id: Scalars['Int'];
  nameForUser: Scalars['String'];
  member: UserList;
};

export type BfNotice = {
  __typename?: 'BfNotice';
  unreadBorderTime: Scalars['Time'];
  getBfNoticeList: BfNoticeList;
};

export type BfNoticeList = {
  __typename?: 'BfNoticeList';
  get: Array<BfNoticeListItem>;
  count: Scalars['Int'];
};


export type BfNoticeListGetArgs = {
  begin?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['Int']>;
};

export type BfNoticeListItem = BfReadLogReactionNoticeItem | TeacherReadLogReactionNoticeItem | BfReadLogStarNoticeItem;

export type BfReadLog = {
  __typename?: 'BfReadLog';
  id: Scalars['String'];
  createdAt: Scalars['Time'];
  user: UserOrDeletedUser;
  readLogInfo: BfReadLogReadLogInfo;
  myReaction: BfReadLogMyReaction;
};

export type BfReadLogCache = {
  __typename?: 'BfReadLogCache';
  /** 0: empty, 1: loading, 2: loaded */
  state: Scalars['Int'];
  digPrevAll: Scalars['Boolean'];
  digNextAll: Scalars['Boolean'];
  latestBfReadLogViewTime: Scalars['Date'];
  bfReadLogs: Array<BfReadLog>;
};

export type BfReadLogMyReaction = {
  __typename?: 'BfReadLogMyReaction';
  starType?: Maybe<ReadingTechniqueType>;
  reactionType: Array<ReadLogReactionType>;
};

export type BfReadLogReactionNoticeItem = {
  __typename?: 'BfReadLogReactionNoticeItem';
  id: Scalars['Int'];
  readLog: ReadLog;
  reactionType: ReadLogReactionType;
  reactionUser: UserOrDeletedUser;
  createdAt: Scalars['Time'];
};

export type BfReadLogReadLogInfo = {
  __typename?: 'BfReadLogReadLogInfo';
  readLogId: Scalars['Int'];
  book: Book;
  review?: Maybe<Scalars['String']>;
  like?: Maybe<Scalars['Int']>;
  status: ReadStatus;
  isMyWantReadBook: Scalars['Boolean'];
};

export type BfReadLogStarNoticeItem = {
  __typename?: 'BfReadLogStarNoticeItem';
  id: Scalars['Int'];
  readLog: ReadLog;
  readingTechniqueType: ReadingTechniqueType;
  reactionUser: UserOrDeletedUser;
  createdAt: Scalars['Time'];
};

export type BfReadLogStarNum = {
  __typename?: 'BfReadLogStarNum';
  readingTechniqueType: ReadingTechniqueType;
  num: Scalars['Int'];
};

export type Book = {
  __typename?: 'Book';
  isbn: Scalars['String'];
  yl?: Maybe<Scalars['Int']>;
  publisher?: Maybe<Scalars['String']>;
  pageCount?: Maybe<Scalars['Int']>;
  author?: Maybe<Scalars['String']>;
  painter?: Maybe<Scalars['String']>;
  wordCount?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Time']>;
  synopsis?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  impression?: Maybe<Scalars['String']>;
  affiliateLink?: Maybe<Scalars['String']>;
};

export enum BookCoverPerception {
  Welcome = 'WELCOME',
  Acceptable = 'ACCEPTABLE',
  Reluctant = 'RELUCTANT'
}

export enum CharacterEnqueteChoice {
  Choice_1 = 'CHOICE_1',
  Choice_2 = 'CHOICE_2',
  Choice_3 = 'CHOICE_3',
  Choice_4 = 'CHOICE_4'
}

export enum CharacterType {
  Dinosaur = 'DINOSAUR',
  SchoolPrincipal = 'SCHOOL_PRINCIPAL',
  Sheep = 'SHEEP',
  Wolf = 'WOLF',
  Lion = 'LION',
  Penguin = 'PENGUIN',
  Apple = 'APPLE',
  Giraffe = 'GIRAFFE'
}

export type ChoiceItem = {
  __typename?: 'ChoiceItem';
  choice: Scalars['String'];
  selected?: Maybe<Scalars['Boolean']>;
};

export type Choices = {
  __typename?: 'Choices';
  multiple?: Maybe<Scalars['Boolean']>;
  items: Array<ChoiceItem>;
};

export type ChoicesAnswerInput = {
  selected: Array<Scalars['Boolean']>;
};

export type CreateYlReassesmentEnqueteResult = Ok | ErrorSuitableYlNotFound;

export enum CurrentRecommendDifficultyPerception {
  Easy = 'EASY',
  Difficult = 'DIFFICULT'
}

export type DailyReadingHabitAchievement = {
  __typename?: 'DailyReadingHabitAchievement';
  day: Scalars['Int'];
  miniLesson: Scalars['Boolean'];
  postReadLog: Scalars['Boolean'];
};


export type DeletedUser = {
  __typename?: 'DeletedUser';
  userID: Scalars['Int'];
};

export type DoneTutorialFlagInput = {
  key: TutorialFlagKey;
};

export type DummyAnswerInput = {
  dummy?: Maybe<Scalars['String']>;
};

export type DummyQuestion = {
  __typename?: 'DummyQuestion';
  dummy?: Maybe<Scalars['String']>;
};

export type EnqueteResult = {
  __typename?: 'EnqueteResult';
  question: Scalars['String'];
  answer: Scalars['String'];
};

export type EnqueteResultList = {
  __typename?: 'EnqueteResultList';
  count: Scalars['Int'];
  get: Array<EnqueteResult>;
};


export type EnqueteResultListGetArgs = {
  begin?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['Int']>;
};

export type ErrorSuitableYlNotFound = {
  __typename?: 'ErrorSuitableYLNotFound';
  message: Scalars['String'];
};

export type GetRecordBookDiffResult = {
  __typename?: 'GetRecordBookDiffResult';
  IsFirstTimeViewInWeek: Scalars['Boolean'];
  TermStartDate: Scalars['Date'];
  TermEndDate: Scalars['Date'];
  ReadCharSumDiff: Scalars['Int'];
  ReadBookSumDiff: Scalars['Int'];
  MiniLessonTotalNumDiff: Scalars['Int'];
};

export type GetSuitableYlResult = SuitableYl | ErrorSuitableYlNotFound;

export enum KanziLevel {
  L7 = 'L7',
  L6 = 'L6',
  L5 = 'L5',
  L4 = 'L4',
  L3 = 'L3',
  L2 = 'L2',
  L1 = 'L1'
}

export type LastBookSelectReaction = {
  __typename?: 'LastBookSelectReaction';
  hasNoPreviousBookSelect: Scalars['Boolean'];
  readLastBookSelectRecommends: Array<Maybe<ReadLastBookSelectRecommend>>;
};

export type LoginTipsContent = OnboardingTips | ReadLogReviewTips | PromisedBookTips | SelectBookTips;

export enum MissionOrder {
  Mission_1 = 'MISSION_1',
  Mission_2 = 'MISSION_2',
  Mission_3 = 'MISSION_3',
  Mission_4 = 'MISSION_4',
  Mission_5 = 'MISSION_5',
  Mission_6 = 'MISSION_6',
  Mission_7 = 'MISSION_7'
}

export type MissionProgress = {
  __typename?: 'MissionProgress';
  missionOrder?: Maybe<MissionOrder>;
  userMissionTasks?: Maybe<Array<UserMissionTask>>;
  missionState: MissionState;
};

export enum MissionState {
  BeforeFirstMission = 'BEFORE_FIRST_MISSION',
  NeedStart = 'NEED_START',
  InProgress = 'IN_PROGRESS',
  WaitingForNextMission = 'WAITING_FOR_NEXT_MISSION',
  NeedComplete = 'NEED_COMPLETE',
  AllOver = 'ALL_OVER'
}

export enum MissionTaskType {
  MiniLesson = 'MINI_LESSON',
  ReadLog = 'READ_LOG',
  ReadLogWithReview = 'READ_LOG_WITH_REVIEW',
  ReadLogContinueDays = 'READ_LOG_CONTINUE_DAYS',
  SendLike = 'SEND_LIKE',
  ReceiveLike = 'RECEIVE_LIKE'
}

export type Mutation = {
  __typename?: 'Mutation';
  createAuthSession: Ok;
  destroyAuthSession: Ok;
  postReadLog: PostReadLogResult;
  editReadLog: ReadLog;
  updateAccount: Account;
  answerReactive: AnswerReactiveResult;
  getMessageLog: TalkLog;
  updateMessageLog: TalkUpdate;
  restartMessageReactive: TalkUpdate;
  sendToMessage: TalkUpdate;
  updateBfGroupLog: TalkUpdate;
  searchBookWithPaapi: Book;
  changeRecommendFrequency: Account;
  createReadLogPromise: User;
  doneTutorialByFlag: TutorialFlag;
  updateUnreadBfNotice: Scalars['String'];
  addBfReadLogReaction: Scalars['String'];
  addWantReadBook: Ok;
  removeWantReadBook: Ok;
  updateProcureStatus: Scalars['Int'];
  removeRecommend: Recommend;
  completeMission?: Maybe<MissionOrder>;
  startMission: MissionProgress;
  createYLReassesmentEnquete: CreateYlReassesmentEnqueteResult;
  upsertLatestRecordBookViewTime: Ok;
  addBfReadLogStar: Ok;
  upsertLatestBfReadLogViewTime: Ok;
  doneTodaysLoginTips: Ok;
  answerPreBookRecommendEnquete: Ok;
  answerCharacterEnquete: Ok;
};


export type MutationCreateAuthSessionArgs = {
  token: Scalars['String'];
};


export type MutationPostReadLogArgs = {
  readLogInfo: ReadLogInput;
};


export type MutationEditReadLogArgs = {
  readLogUpdate: ReadLogUpdate;
};


export type MutationUpdateAccountArgs = {
  accountInfo: UpdateAccountInput;
};


export type MutationAnswerReactiveArgs = {
  talkID: Scalars['Int'];
  answer: AnswerInput;
};


export type MutationGetMessageLogArgs = {
  oldCount: Scalars['Int'];
};


export type MutationUpdateMessageLogArgs = {
  contain: Array<Scalars['Int']>;
  waiting: Array<Scalars['Int']>;
};


export type MutationSendToMessageArgs = {
  message: Scalars['String'];
};


export type MutationUpdateBfGroupLogArgs = {
  contain: Array<Scalars['Int']>;
  waiting: Array<Scalars['Int']>;
};


export type MutationSearchBookWithPaapiArgs = {
  isbn: Scalars['String'];
};


export type MutationChangeRecommendFrequencyArgs = {
  frequency: RecommendFrequency;
};


export type MutationCreateReadLogPromiseArgs = {
  promiseDate: PromiseDate;
  chooseNext: Scalars['Boolean'];
  nextISBN?: Maybe<Scalars['String']>;
};


export type MutationDoneTutorialByFlagArgs = {
  input: DoneTutorialFlagInput;
};


export type MutationAddBfReadLogReactionArgs = {
  readLogId: Scalars['Int'];
  reactionType: ReadLogReactionType;
};


export type MutationAddWantReadBookArgs = {
  input: AddWantReadBookInput;
};


export type MutationRemoveWantReadBookArgs = {
  input: RemoveWantReadBookInput;
};


export type MutationUpdateProcureStatusArgs = {
  recommendID: Scalars['Int'];
  procureStatus: ProcureStatus;
};


export type MutationRemoveRecommendArgs = {
  recommendID: Scalars['Int'];
  removeReason: RemoveReason;
};


export type MutationCreateYlReassesmentEnqueteArgs = {
  input: YlReassesmentEnqueteInput;
};


export type MutationAddBfReadLogStarArgs = {
  readLogID: Scalars['Int'];
  readingTechniqueType: ReadingTechniqueType;
};


export type MutationAnswerPreBookRecommendEnqueteArgs = {
  input: AnswerPreBookRecommendEnqueteInput;
};


export type MutationAnswerCharacterEnqueteArgs = {
  input: AnswerCharacterEnqueteInput;
};

export type MyMiniLessonHistory = {
  __typename?: 'MyMiniLessonHistory';
  hasInProgressMiniLesson: Scalars['Boolean'];
  completedMiniLessonEndDates: Array<Maybe<Scalars['Date']>>;
};

export type Ok = {
  __typename?: 'OK';
  state: OkState;
};

export enum OkState {
  Success = 'SUCCESS'
}

export type OnboardingTips = {
  __typename?: 'OnboardingTips';
  loginTimes: Scalars['Int'];
};

export type OpenEnded = {
  __typename?: 'OpenEnded';
  answer?: Maybe<Scalars['String']>;
};

export type OpenEndedAnswerInput = {
  answer: Scalars['String'];
};

export enum OrderOfReadLog {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC'
}

export enum OrderOfRecommend {
  Finishtime = 'FINISHTIME',
  Recommendtime = 'RECOMMENDTIME'
}

export type PostPreBookRecommendLog = {
  __typename?: 'PostPreBookRecommendLog';
  id: Scalars['Int'];
  preBookRecommends: Array<PreBookRecommend>;
};

export type PostReadLogResult = {
  __typename?: 'PostReadLogResult';
  readLog: ReadLog;
  promise?: Maybe<ReadLogPromise>;
  continuousFulfilledPromise: Scalars['Int'];
  account: Account;
  userExperienceEvent: Array<Maybe<UserExperienceEvent>>;
  wasWantReadBook: Scalars['Boolean'];
};

export type PreBookRecommend = {
  __typename?: 'PreBookRecommend';
  id: Scalars['Int'];
  book: Book;
};

export type PreBookRecommendEnqueteInput = {
  preBookRecommendID: Scalars['Int'];
  bookCoverPerception: BookCoverPerception;
};

export enum ProcureStatus {
  Untouched = 'UNTOUCHED',
  Reserved = 'RESERVED',
  Purchased = 'PURCHASED'
}

export enum PromiseDate {
  Tomorrow = 'TOMORROW',
  DayAfterTomorrow = 'DAY_AFTER_TOMORROW',
  TwoDaysAfterTomorrow = 'TWO_DAYS_AFTER_TOMORROW'
}

export enum PromiseStatus {
  Fulfill = 'FULFILL',
  Earlyfulfill = 'EARLYFULFILL',
  Break = 'BREAK',
  Unsolved = 'UNSOLVED'
}

export type PromisedBookTips = {
  __typename?: 'PromisedBookTips';
  booktitle: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  bfGroupCache: BfReadLogCache;
  getAvailableStarEnergyID?: Maybe<Scalars['Int']>;
  getBfNotice: BfNotice;
  getBfReadLogs: Array<BfReadLog>;
  getDoneRecommends: RecommendList;
  getFirstBookRecommends: Array<Recommend>;
  getLastBookSelectReaction: LastBookSelectReaction;
  getLatestBfReadLogViewTime: Scalars['Time'];
  getLoginTips?: Maybe<LoginTipsContent>;
  getMissionProgress: MissionProgress;
  getMonthlyHabitCalendar: Array<DailyReadingHabitAchievement>;
  getMyMiniLessonHistory: MyMiniLessonHistory;
  getOlderMessageLog: Array<Talk>;
  getPostPreBookRecommendLog?: Maybe<PostPreBookRecommendLog>;
  getReadingRecommends: RecommendList;
  getRecordBookDiff: GetRecordBookDiffResult;
  getSuitableYL: GetSuitableYlResult;
  getTutorialFlags: Array<TutorialFlag>;
  getUnreadBfNoticeExist: Scalars['Boolean'];
  getUsers: Array<UserAvailability>;
  getWantReadBookByID: WantReadBook;
  getWantReadBooks: WantReadBookList;
  haveUnreadInBfGroupMessage: Scalars['Boolean'];
  isDoneFirstMiniLesson: Scalars['Boolean'];
  me: Account;
  messageCache: TalkCache;
  searchCityByMyLibrary?: Maybe<Scalars['String']>;
  sessionPresence: Scalars['Boolean'];
  totalBfReactionCount: TotalBfReactionCount;
};


export type QueryGetBfReadLogsArgs = {
  limit: Scalars['Int'];
  direction: SearchDirection;
  exclusiveStartId?: Maybe<Scalars['String']>;
};


export type QueryGetMonthlyHabitCalendarArgs = {
  year: Scalars['Int'];
  month: Scalars['Int'];
};


export type QueryGetMyMiniLessonHistoryArgs = {
  from: Scalars['Date'];
  to: Scalars['Date'];
};


export type QueryGetOlderMessageLogArgs = {
  current: Scalars['Int'];
  count: Scalars['Int'];
};


export type QueryGetTutorialFlagsArgs = {
  tutorialFlagKeys: Array<Maybe<TutorialFlagKey>>;
};


export type QueryGetWantReadBookByIdArgs = {
  id: Scalars['Int'];
};

export type Question = OpenEnded | Choices | DummyQuestion;

export type ReactiveHistory = {
  __typename?: 'ReactiveHistory';
  state: ReactiveHistoryState;
  message: Array<Scalars['String']>;
  question?: Maybe<Question>;
  response?: Maybe<Array<Scalars['String']>>;
  sentTime: Scalars['Time'];
  responseTime?: Maybe<Scalars['Time']>;
  speaker: TalkItemSpeaker;
};

export enum ReactiveHistoryState {
  Wait = 'WAIT',
  Completed = 'COMPLETED',
  Cancelled = 'CANCELLED'
}

export type ReadLastBookSelectRecommend = {
  __typename?: 'ReadLastBookSelectRecommend';
  title: Scalars['String'];
  whenDone: Scalars['Time'];
  readLogReaction: ReadLogReaction;
};

export type ReadLog = {
  __typename?: 'ReadLog';
  id: Scalars['Int'];
  when: Scalars['Time'];
  user: UserOrDeletedUser;
  book: Book;
  review?: Maybe<Scalars['String']>;
  favoriteScene?: Maybe<Scalars['String']>;
  favoritePage?: Maybe<Scalars['Int']>;
  emotions?: Maybe<Scalars['String']>;
  difficulty?: Maybe<Scalars['Int']>;
  length?: Maybe<Scalars['Int']>;
  like?: Maybe<Scalars['Int']>;
  status: ReadStatus;
  yomikikase?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['Time'];
  myReaction: Array<ReadLogReactionType>;
  myStar?: Maybe<ReadingTechniqueType>;
  isMyWantReadBook: Scalars['Boolean'];
};

export type ReadLogInput = {
  recommendID?: Maybe<Scalars['Int']>;
  bookISBN: Scalars['String'];
  title: Scalars['String'];
  like?: Maybe<Scalars['Int']>;
  difficulty?: Maybe<Scalars['Int']>;
  length?: Maybe<Scalars['Int']>;
  review?: Maybe<Scalars['String']>;
  emotions: Scalars['String'];
  status: ReadStatusForPostReadLog;
  favoriteScene?: Maybe<Scalars['String']>;
  favoritePage?: Maybe<Scalars['Int']>;
  yomikikase: Scalars['Boolean'];
};

export type ReadLogList = {
  __typename?: 'ReadLogList';
  count: Scalars['Int'];
  get: Array<ReadLog>;
};


export type ReadLogListGetArgs = {
  begin?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['Int']>;
  order: OrderOfReadLog;
};

export type ReadLogPromise = {
  __typename?: 'ReadLogPromise';
  dueDate: Scalars['Date'];
  status: PromiseStatus;
  chooseNext: Scalars['Boolean'];
  nextISBN?: Maybe<Scalars['String']>;
};

export enum ReadLogReactionType {
  Like = 'LIKE',
  Read = 'READ'
}

export type ReadLogReviewTips = {
  __typename?: 'ReadLogReviewTips';
  readLogWithReview: ReadLog;
};

export enum ReadLogTerm {
  Days_7 = 'DAYS_7'
}

export type ReadLogUpdate = {
  readLogID: Scalars['Int'];
  review: Scalars['String'];
  favoriteScene?: Maybe<Scalars['String']>;
  favoritePage?: Maybe<Scalars['Int']>;
  yomikikase?: Maybe<Scalars['Boolean']>;
};

export enum ReadStatus {
  Reading = 'READING',
  End = 'END',
  Quit = 'QUIT'
}

export enum ReadStatusForPostReadLog {
  Reading = 'READING',
  End = 'END'
}

export enum ReadingTechniqueType {
  Imagine = 'IMAGINE',
  Connect = 'CONNECT',
  Question = 'QUESTION',
  Predict = 'PREDICT',
  Acknowledge = 'ACKNOWLEDGE',
  Judge = 'JUDGE',
  Interpret = 'INTERPRET'
}

export type ReassesmentEnqueteDifficulties = {
  firstDifficulty: YlReassesmentEnqueteDifficulty;
  secondDifficulty: YlReassesmentEnqueteDifficulty;
};

export type Recommend = {
  __typename?: 'Recommend';
  id: Scalars['Int'];
  whenRecommend: Scalars['Time'];
  whenDone?: Maybe<Scalars['Time']>;
  book: Book;
  recommendComment: Scalars['String'];
  availableBookType: AvailableBookType;
  recommendStatus: RecommendStatus;
  self: Scalars['Boolean'];
  procureStatus: ProcureStatus;
  readLog: ReadLogList;
};


export type RecommendReadLogArgs = {
  statusEnd: Scalars['Boolean'];
  statusReading: Scalars['Boolean'];
  statusQuit: Scalars['Boolean'];
};

export enum RecommendFrequency {
  Little = 'LITTLE',
  Basic = 'BASIC',
  Many = 'MANY',
  Enough = 'ENOUGH',
  Bulk = 'BULK'
}

export type RecommendList = {
  __typename?: 'RecommendList';
  count: Scalars['Int'];
  get: Array<Recommend>;
};


export type RecommendListGetArgs = {
  begin?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['Int']>;
  order: OrderOfRecommend;
};

export enum RecommendStatus {
  Default = 'DEFAULT',
  Reading = 'READING',
  Ended = 'ENDED',
  Quit = 'QUIT',
  Discarded = 'DISCARDED'
}

export enum RecommendTerm {
  Days_30 = 'DAYS_30',
  Days_90 = 'DAYS_90',
  All = 'ALL'
}

export type Remark = {
  __typename?: 'Remark';
  time: Scalars['Time'];
  text: Scalars['String'];
};

export enum RemoveReason {
  TooDifficult = 'TOO_DIFFICULT',
  TooEasy = 'TOO_EASY',
  NotInteresting = 'NOT_INTERESTING',
  CannotGet = 'CANNOT_GET',
  HaveRead = 'HAVE_READ'
}

export type RemoveWantReadBookInput = {
  id: Scalars['Int'];
};

export enum SearchDirection {
  Newer = 'Newer',
  Older = 'Older'
}

export type SelectBookTips = {
  __typename?: 'SelectBookTips';
  recommendList: RecommendList;
};

export enum StringMatchType {
  Exact = 'EXACT',
  PartialAnd = 'PARTIAL_AND',
  PartialOr = 'PARTIAL_OR'
}

export type StringSearch = {
  type?: Maybe<StringMatchType>;
  strs: Array<Scalars['String']>;
};

export type SuitableYl = {
  __typename?: 'SuitableYL';
  value: Scalars['Int'];
};

export type Talk = {
  __typename?: 'Talk';
  id: Scalars['Int'];
  item: TalkItem;
  user?: Maybe<UserOrDeletedUser>;
};

export type TalkCache = {
  __typename?: 'TalkCache';
  /** 0: empty, 1: loading, 2: loaded */
  state: Scalars['Int'];
  count: Scalars['Int'];
  digAll: Scalars['Boolean'];
  oldTalks: Array<Talk>;
  unreadTalks?: Maybe<Array<Talk>>;
};

export type TalkItem = Remark | ReadLog | ReactiveHistory | Recommend;

export enum TalkItemSpeaker {
  YondemySensei = 'YONDEMY_SENSEI',
  YondemySenseiCry = 'YONDEMY_SENSEI_CRY',
  YondemySenseiFire = 'YONDEMY_SENSEI_FIRE',
  YondemySenseiGood = 'YONDEMY_SENSEI_GOOD',
  YondemySenseiHeart = 'YONDEMY_SENSEI_HEART',
  YondemySenseiSurprised = 'YONDEMY_SENSEI_SURPRISED',
  Apple = 'APPLE',
  BookWorm = 'BOOK_WORM',
  Dinosaur = 'DINOSAUR',
  Lion = 'LION',
  Penguin = 'PENGUIN',
  Wolf = 'WOLF',
  Giraffe = 'GIRAFFE',
  Principal = 'PRINCIPAL',
  Sheep = 'SHEEP'
}

export type TalkLog = {
  __typename?: 'TalkLog';
  old: Array<Talk>;
  unread: Array<Talk>;
  count: Scalars['Int'];
};

export type TalkUpdate = {
  __typename?: 'TalkUpdate';
  delete: Array<Scalars['Int']>;
  change: Array<Talk>;
  new: Array<Talk>;
};

export type TeacherReadLogReactionNoticeItem = {
  __typename?: 'TeacherReadLogReactionNoticeItem';
  id: Scalars['Int'];
  readLog: ReadLog;
  createdAt: Scalars['Time'];
};


export enum TimeRange {
  All = 'ALL'
}

export type TotalBfReactionCount = {
  __typename?: 'TotalBfReactionCount';
  like: Scalars['Int'];
  read: Scalars['Int'];
};

export type TutorialFlag = {
  __typename?: 'TutorialFlag';
  key: TutorialFlagKey;
  value: Scalars['Boolean'];
};

export enum TutorialFlagKey {
  FirstLogin = 'FIRST_LOGIN',
  HideRecommendModal = 'HIDE_RECOMMEND_MODAL',
  RecommendListFirstTimeInstruction = 'RECOMMEND_LIST_FIRST_TIME_INSTRUCTION',
  RecommendListInstruction = 'RECOMMEND_LIST_INSTRUCTION',
  FirstImplessionBookFriend = 'FIRST_IMPLESSION_BOOK_FRIEND',
  FirstImplessionReadLog = 'FIRST_IMPLESSION_READ_LOG',
  FirstGetBfReadLogReactionsInstruction = 'FIRST_GET_BF_READ_LOG_REACTIONS_INSTRUCTION',
  HowToGetNextRecommendAlertClosed = 'HOW_TO_GET_NEXT_RECOMMEND_ALERT_CLOSED',
  ReachedMaxBookCountForExpModal = 'REACHED_MAX_BOOK_COUNT_FOR_EXP_MODAL',
  FirstDislikeBook = 'FIRST_DISLIKE_BOOK',
  FirstDeleteBook = 'FIRST_DELETE_BOOK',
  FirstBookFriendTutorial = 'FIRST_BOOK_FRIEND_TUTORIAL'
}

export type UpdateAccountInput = {
  kanziLevel?: Maybe<KanziLevel>;
  nickname?: Maybe<Scalars['String']>;
  userIconType?: Maybe<UserIconType>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['Int'];
  nickname: Scalars['String'];
  readBookSum: Scalars['Int'];
  readCharSum: Scalars['Int'];
  continueReadLogDays: Scalars['Int'];
  maxContinueReadLogDays: Scalars['Int'];
  continueMiniLessonDays: Scalars['Int'];
  userIconType: UserIconType;
  enqueteResults: EnqueteResultList;
  userExperience: UserExperience;
  miniLessonTotalNum: Scalars['Int'];
  readLogTotalNum: Scalars['Int'];
  receivedTotalBfReadLogReactionNum: Scalars['Int'];
  receivedBfReadLogStar: Array<BfReadLogStarNum>;
};


export type UserReadBookSumArgs = {
  timeRange: TimeRange;
};


export type UserReadCharSumArgs = {
  timeRange: TimeRange;
};

export type UserAvailability = {
  __typename?: 'UserAvailability';
  userID: Scalars['Int'];
  nickname: Scalars['String'];
  userPlan: UserPlan;
  userIconType: UserIconType;
};

export type UserExperience = {
  __typename?: 'UserExperience';
  userLevel: Scalars['Int'];
  expAfterLastLevelUp: Scalars['Int'];
  expToNextLevelUp: Scalars['Int'];
};

export type UserExperienceEvent = {
  __typename?: 'UserExperienceEvent';
  eventName: UserExperienceEventName;
  point: Scalars['Int'];
};

export enum UserExperienceEventName {
  PostFirstReadLogOfTheDay = 'POST_FIRST_READ_LOG_OF_THE_DAY',
  PostReadLogEnded = 'POST_READ_LOG_ENDED',
  TakeMiniLesson = 'TAKE_MINI_LESSON'
}

export enum UserIconType {
  Penguin = 'PENGUIN',
  Lion = 'LION',
  Worm = 'WORM',
  Apple = 'APPLE',
  Dinosaur = 'DINOSAUR',
  Wolf = 'WOLF',
  Giraffe = 'GIRAFFE',
  Sheep = 'SHEEP'
}

export type UserList = {
  __typename?: 'UserList';
  count: Scalars['Int'];
  /** if `end` is null, it imply that this query should return exact one User. */
  get: Array<User>;
};


export type UserListGetArgs = {
  begin?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['Int']>;
};

export type UserMissionTask = {
  __typename?: 'UserMissionTask';
  missionTaskType: MissionTaskType;
  current: Scalars['Int'];
  goal: Scalars['Int'];
};

export type UserOrDeletedUser = User | DeletedUser;

export enum UserPlan {
  Premium = 'PREMIUM',
  Basic = 'BASIC',
  Light = 'LIGHT',
  Invalidation = 'INVALIDATION'
}

export type WantReadBook = {
  __typename?: 'WantReadBook';
  id: Scalars['Int'];
  userID: Scalars['Int'];
  book: Book;
  recommendStatus: RecommendStatus;
  createdAt: Scalars['Time'];
  readRecommendID?: Maybe<Scalars['Int']>;
};

export type WantReadBookList = {
  __typename?: 'WantReadBookList';
  count: Scalars['Int'];
  get: Array<WantReadBook>;
};


export type WantReadBookListGetArgs = {
  begin?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['Int']>;
};

export enum YlReassesmentEnqueteDifficulty {
  VeryEasy = 'VERY_EASY',
  Easy = 'EASY',
  Normal = 'NORMAL',
  Difficult = 'DIFFICULT',
  VeryDifficult = 'VERY_DIFFICULT'
}

export enum YlReassesmentEnqueteEntryPage {
  RemoveRecommend = 'REMOVE_RECOMMEND',
  BooksLeftNotice = 'BOOKS_LEFT_NOTICE',
  IntroduceYlReassessmentNotice = 'INTRODUCE_YL_REASSESSMENT_NOTICE',
  MenuSideBar = 'MENU_SIDE_BAR',
  ParentBookListViewer = 'PARENT_BOOK_LIST_VIEWER',
  FirstBookRecommendReport = 'FIRST_BOOK_RECOMMEND_REPORT',
  Unknown = 'UNKNOWN'
}

export type YlReassesmentEnqueteInput = {
  version: YlReassesmentEnqueteVersion;
  currentDifficultyPerception: CurrentRecommendDifficultyPerception;
  yl15difficulties?: Maybe<ReassesmentEnqueteDifficulties>;
  yl20difficulties?: Maybe<ReassesmentEnqueteDifficulties>;
  yl25difficulties?: Maybe<ReassesmentEnqueteDifficulties>;
  yl30difficulties?: Maybe<ReassesmentEnqueteDifficulties>;
  yl35difficulties?: Maybe<ReassesmentEnqueteDifficulties>;
  yl40difficulties?: Maybe<ReassesmentEnqueteDifficulties>;
  yl45difficulties?: Maybe<ReassesmentEnqueteDifficulties>;
  yl50difficulties?: Maybe<ReassesmentEnqueteDifficulties>;
  entryPage: YlReassesmentEnqueteEntryPage;
};

export enum YlReassesmentEnqueteVersion {
  Version_1 = 'VERSION_1'
}

export type ReadLogReaction = {
  __typename?: 'readLogReaction';
  like: Scalars['Int'];
  emotion: Scalars['String'];
};

export type GetUnreadBfNoticeExistQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUnreadBfNoticeExistQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUnreadBfNoticeExist'>
);

export type BfReadLogReactionNoticeItemFragment = (
  { __typename?: 'BfReadLogReactionNoticeItem' }
  & Pick<BfReadLogReactionNoticeItem, 'id' | 'reactionType' | 'createdAt'>
  & { readLog: (
    { __typename?: 'ReadLog' }
    & ReadLogOnTalkFragment
  ), reactionUser: (
    { __typename: 'User' }
    & UserOnTalkFragment
  ) | (
    { __typename: 'DeletedUser' }
    & DeletedUserOnTalkFragment
  ) }
);

export type TeacherReadLogReactionNoticeItemFragment = (
  { __typename?: 'TeacherReadLogReactionNoticeItem' }
  & Pick<TeacherReadLogReactionNoticeItem, 'id' | 'createdAt'>
  & { readLog: (
    { __typename?: 'ReadLog' }
    & ReadLogOnTalkFragment
  ) }
);

export type BfReadLogStarNoticeItemFragment = (
  { __typename?: 'BfReadLogStarNoticeItem' }
  & Pick<BfReadLogStarNoticeItem, 'id' | 'readingTechniqueType' | 'createdAt'>
  & { readLog: (
    { __typename?: 'ReadLog' }
    & ReadLogOnTalkFragment
  ), reactionUser: (
    { __typename: 'User' }
    & UserOnTalkFragment
  ) | (
    { __typename: 'DeletedUser' }
    & DeletedUserOnTalkFragment
  ) }
);

export type GetBfNoticeQueryVariables = Exact<{
  begin?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['Int']>;
}>;


export type GetBfNoticeQuery = (
  { __typename?: 'Query' }
  & { getBfNotice: (
    { __typename?: 'BfNotice' }
    & Pick<BfNotice, 'unreadBorderTime'>
    & { getBfNoticeList: (
      { __typename?: 'BfNoticeList' }
      & Pick<BfNoticeList, 'count'>
      & { get: Array<(
        { __typename?: 'BfReadLogReactionNoticeItem' }
        & BfReadLogReactionNoticeItemFragment
      ) | (
        { __typename?: 'TeacherReadLogReactionNoticeItem' }
        & TeacherReadLogReactionNoticeItemFragment
      ) | (
        { __typename?: 'BfReadLogStarNoticeItem' }
        & BfReadLogStarNoticeItemFragment
      )> }
    ) }
  ) }
);

export type UpdateUnreadBfNoticeMutationVariables = Exact<{ [key: string]: never; }>;


export type UpdateUnreadBfNoticeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateUnreadBfNotice'>
);

export type GetAllUnDoneRecommendQueryVariables = Exact<{
  begin: Scalars['Int'];
  end: Scalars['Int'];
}>;


export type GetAllUnDoneRecommendQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'Account' }
    & { recommends: (
      { __typename?: 'RecommendList' }
      & Pick<RecommendList, 'count'>
      & { get: Array<(
        { __typename?: 'Recommend' }
        & Pick<Recommend, 'recommendStatus' | 'self'>
        & { book: (
          { __typename?: 'Book' }
          & Pick<Book, 'yl'>
        ) }
        & BookshelfCurrentRecommendFragment
      )> }
    ) }
  ) }
);

export type LoginTipsQueryVariables = Exact<{ [key: string]: never; }>;


export type LoginTipsQuery = (
  { __typename?: 'Query' }
  & { getLoginTips?: Maybe<(
    { __typename?: 'OnboardingTips' }
    & Pick<OnboardingTips, 'loginTimes'>
  ) | (
    { __typename?: 'ReadLogReviewTips' }
    & { readLogWithReview: (
      { __typename?: 'ReadLog' }
      & { book: (
        { __typename?: 'Book' }
        & Pick<Book, 'title'>
      ) }
    ) }
  ) | (
    { __typename?: 'PromisedBookTips' }
    & Pick<PromisedBookTips, 'booktitle'>
  ) | (
    { __typename?: 'SelectBookTips' }
    & { recommendList: (
      { __typename?: 'RecommendList' }
      & Pick<RecommendList, 'count'>
      & { get: Array<(
        { __typename?: 'Recommend' }
        & Pick<Recommend, 'id'>
        & { book: (
          { __typename?: 'Book' }
          & Pick<Book, 'isbn' | 'title' | 'imageUrl'>
        ) }
      )> }
    ) }
  )> }
);

export type DoneTodaysLoginTipsMutationVariables = Exact<{ [key: string]: never; }>;


export type DoneTodaysLoginTipsMutation = (
  { __typename?: 'Mutation' }
  & { doneTodaysLoginTips: (
    { __typename?: 'OK' }
    & Pick<Ok, 'state'>
  ) }
);

export type BookshelfRecommendHistoryFragment = (
  { __typename?: 'Recommend' }
  & Pick<Recommend, 'id' | 'whenDone' | 'recommendStatus'>
  & { book: (
    { __typename?: 'Book' }
    & Pick<Book, 'title' | 'imageUrl' | 'isbn'>
  ) }
);

export type GetReadingRecommendsQueryVariables = Exact<{
  begin: Scalars['Int'];
  end: Scalars['Int'];
}>;


export type GetReadingRecommendsQuery = (
  { __typename?: 'Query' }
  & { getReadingRecommends: (
    { __typename?: 'RecommendList' }
    & Pick<RecommendList, 'count'>
    & { get: Array<(
      { __typename?: 'Recommend' }
      & BookshelfRecommendHistoryFragment
    )> }
  ) }
);

export type BookshelfCurrentRecommendFragment = (
  { __typename?: 'Recommend' }
  & Pick<Recommend, 'id' | 'whenDone' | 'whenRecommend' | 'recommendComment'>
  & { book: (
    { __typename?: 'Book' }
    & Pick<Book, 'title' | 'imageUrl' | 'isbn' | 'yl'>
  ), readLog: (
    { __typename?: 'ReadLogList' }
    & Pick<ReadLogList, 'count'>
    & { get: Array<(
      { __typename?: 'ReadLog' }
      & Pick<ReadLog, 'id' | 'status'>
    )> }
  ) }
);

export type GetMyCurrentRecommendQueryVariables = Exact<{
  begin: Scalars['Int'];
  end: Scalars['Int'];
}>;


export type GetMyCurrentRecommendQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'Account' }
    & Pick<Account, 'recommendFrequency'>
    & { recommends: (
      { __typename?: 'RecommendList' }
      & Pick<RecommendList, 'count'>
      & { get: Array<(
        { __typename?: 'Recommend' }
        & BookshelfCurrentRecommendFragment
      )> }
    ) }
  ) }
);

export type GetMyMiniLessonHistoryQueryVariables = Exact<{
  from: Scalars['Date'];
  to: Scalars['Date'];
}>;


export type GetMyMiniLessonHistoryQuery = (
  { __typename?: 'Query' }
  & { getMyMiniLessonHistory: (
    { __typename?: 'MyMiniLessonHistory' }
    & Pick<MyMiniLessonHistory, 'hasInProgressMiniLesson' | 'completedMiniLessonEndDates'>
  ) }
);

export type RemoveWantReadBookMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveWantReadBookMutation = (
  { __typename?: 'Mutation' }
  & { removeWantReadBook: (
    { __typename?: 'OK' }
    & Pick<Ok, 'state'>
  ) }
);

export type GetLastBookSelectReactionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLastBookSelectReactionQuery = (
  { __typename?: 'Query' }
  & { getLastBookSelectReaction: (
    { __typename?: 'LastBookSelectReaction' }
    & Pick<LastBookSelectReaction, 'hasNoPreviousBookSelect'>
    & { readLastBookSelectRecommends: Array<Maybe<(
      { __typename?: 'ReadLastBookSelectRecommend' }
      & Pick<ReadLastBookSelectRecommend, 'title' | 'whenDone'>
      & { readLogReaction: (
        { __typename?: 'readLogReaction' }
        & Pick<ReadLogReaction, 'like' | 'emotion'>
      ) }
    )>> }
  ) }
);

export type ShouldReturnSomeReactiveQueryVariables = Exact<{ [key: string]: never; }>;


export type ShouldReturnSomeReactiveQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'Account' }
    & Pick<Account, 'shouldReturnSomeReactive'>
  ) }
);

export type SendToMessageMutationVariables = Exact<{
  message: Scalars['String'];
}>;


export type SendToMessageMutation = (
  { __typename?: 'Mutation' }
  & { sendToMessage: (
    { __typename?: 'TalkUpdate' }
    & TalkUpdateAllFragment
  ) }
);

export type RestartMessageReactiveMutationVariables = Exact<{ [key: string]: never; }>;


export type RestartMessageReactiveMutation = (
  { __typename?: 'Mutation' }
  & { restartMessageReactive: (
    { __typename?: 'TalkUpdate' }
    & TalkUpdateAllFragment
  ) }
);

export type AnswerReactiveMutationVariables = Exact<{
  talkID: Scalars['Int'];
  answer: AnswerInput;
}>;


export type AnswerReactiveMutation = (
  { __typename?: 'Mutation' }
  & { answerReactive: (
    { __typename?: 'AnswerReactiveResult' }
    & { talkUpdate: (
      { __typename?: 'TalkUpdate' }
      & TalkUpdateAllFragment
    ), userExperienceEvent?: Maybe<(
      { __typename?: 'UserExperienceEvent' }
      & Pick<UserExperienceEvent, 'eventName' | 'point'>
    )>, userExperience?: Maybe<(
      { __typename?: 'UserExperience' }
      & Pick<UserExperience, 'userLevel' | 'expAfterLastLevelUp' | 'expToNextLevelUp'>
    )> }
  ) }
);

export type GetRecentReadlogQueryVariables = Exact<{
  begin?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['Int']>;
}>;


export type GetRecentReadlogQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'Account' }
    & { readLog: (
      { __typename?: 'ReadLogList' }
      & { get: Array<(
        { __typename?: 'ReadLog' }
        & ReadLogOnTalkFragment
      )> }
    ) }
  ) }
);

export type GetUnreadExistQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUnreadExistQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'Account' }
    & Pick<Account, 'haveUnreadInMessage' | 'haveUnreadInBfGroupMessage'>
    & { general: (
      { __typename?: 'User' }
      & Pick<User, 'nickname'>
    ) }
  ) }
);

export type GetReadcharForGraphQueryVariables = Exact<{
  recommendTerm: RecommendTerm;
  end: Scalars['Int'];
}>;


export type GetReadcharForGraphQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'Account' }
    & Pick<Account, 'createdAt'>
    & { recommendTerm: (
      { __typename?: 'RecommendList' }
      & { get: Array<(
        { __typename?: 'Recommend' }
        & Pick<Recommend, 'whenDone'>
        & { book: (
          { __typename?: 'Book' }
          & Pick<Book, 'title' | 'wordCount'>
        ) }
      )> }
    ), general: (
      { __typename?: 'User' }
      & Pick<User, 'readCharSum'>
    ) }
  ) }
);

export type GetMonthlyHabitCalendarQueryVariables = Exact<{
  year: Scalars['Int'];
  month: Scalars['Int'];
}>;


export type GetMonthlyHabitCalendarQuery = (
  { __typename?: 'Query' }
  & { getMonthlyHabitCalendar: Array<(
    { __typename?: 'DailyReadingHabitAchievement' }
    & Pick<DailyReadingHabitAchievement, 'day' | 'miniLesson' | 'postReadLog'>
  )> }
);

export type GetReviewRecordsQueryVariables = Exact<{
  readLogTerm: ReadLogTerm;
  end: Scalars['Int'];
  startTime: Scalars['Time'];
}>;


export type GetReviewRecordsQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'Account' }
    & { readLogTerm: (
      { __typename?: 'ReadLogList' }
      & { get: Array<(
        { __typename?: 'ReadLog' }
        & Pick<ReadLog, 'when'>
        & { book: (
          { __typename?: 'Book' }
          & Pick<Book, 'title'>
        ) }
      )> }
    ) }
  ) }
);

export type AuthSessionPresenceQueryVariables = Exact<{ [key: string]: never; }>;


export type AuthSessionPresenceQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'sessionPresence'>
);

export type CreateAuthSessionMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type CreateAuthSessionMutation = (
  { __typename?: 'Mutation' }
  & { createAuthSession: (
    { __typename?: 'OK' }
    & Pick<Ok, 'state'>
  ) }
);

export type CurrentUserFragment = (
  { __typename?: 'Account' }
  & Pick<Account, 'haveUnreadInMessage' | 'haveUnreadInBfGroupMessage' | 'recommendFrequency' | 'birthday' | 'createdAt' | 'kanziLevel'>
  & { belong?: Maybe<(
    { __typename?: 'BfGroup' }
    & Pick<BfGroup, 'id'>
  )>, general: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'userIconType' | 'nickname'>
    & { userExperience: (
      { __typename?: 'UserExperience' }
      & Pick<UserExperience, 'userLevel' | 'expAfterLastLevelUp' | 'expToNextLevelUp'>
    ) }
  ) }
);

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'isDoneFirstMiniLesson'>
  & { me: (
    { __typename?: 'Account' }
    & CurrentUserFragment
  ) }
);

export type GetUserAvailabilitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserAvailabilitiesQuery = (
  { __typename?: 'Query' }
  & { getUsers: Array<(
    { __typename?: 'UserAvailability' }
    & Pick<UserAvailability, 'userID' | 'userPlan' | 'userIconType' | 'nickname'>
  )> }
);

export type BfReadLogFragment = (
  { __typename?: 'BfReadLog' }
  & Pick<BfReadLog, 'id' | 'createdAt'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'nickname' | 'userIconType'>
  ) | (
    { __typename?: 'DeletedUser' }
    & Pick<DeletedUser, 'userID'>
  ), readLogInfo: (
    { __typename?: 'BfReadLogReadLogInfo' }
    & BfReadLogInfoFragment
  ), myReaction: (
    { __typename?: 'BfReadLogMyReaction' }
    & Pick<BfReadLogMyReaction, 'starType' | 'reactionType'>
  ) }
);

export type BfReadLogInfoFragment = (
  { __typename?: 'BfReadLogReadLogInfo' }
  & Pick<BfReadLogReadLogInfo, 'readLogId' | 'review' | 'like' | 'status' | 'isMyWantReadBook'>
  & { book: (
    { __typename?: 'Book' }
    & Pick<Book, 'isbn' | 'yl' | 'title' | 'imageUrl' | 'author'>
  ) }
);

export type BfReadLogCacheAllFragment = (
  { __typename?: 'BfReadLogCache' }
  & Pick<BfReadLogCache, 'state' | 'digPrevAll' | 'digNextAll' | 'latestBfReadLogViewTime'>
  & { bfReadLogs: Array<(
    { __typename?: 'BfReadLog' }
    & BfReadLogFragment
  )> }
);

export type TalkAllFragment = (
  { __typename?: 'Talk' }
  & Pick<Talk, 'id'>
  & { item: (
    { __typename: 'Remark' }
    & RemarkAllFragment
  ) | (
    { __typename: 'ReadLog' }
    & ReadLogOnTalkFragment
  ) | (
    { __typename: 'ReactiveHistory' }
    & ReactiveHistoryAllFragment
  ) | (
    { __typename: 'Recommend' }
    & RecommendOnTalkFragment
  ), user?: Maybe<(
    { __typename: 'User' }
    & UserOnTalkFragment
  ) | (
    { __typename: 'DeletedUser' }
    & DeletedUserOnTalkFragment
  )> }
);

export type RemarkAllFragment = (
  { __typename?: 'Remark' }
  & Pick<Remark, 'time' | 'text'>
);

export type ReadLogOnTalkFragment = (
  { __typename?: 'ReadLog' }
  & Pick<ReadLog, 'id' | 'when' | 'review' | 'like' | 'status' | 'myReaction' | 'myStar' | 'isMyWantReadBook'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'nickname' | 'userIconType'>
  ) | (
    { __typename?: 'DeletedUser' }
    & Pick<DeletedUser, 'userID'>
  ), book: (
    { __typename?: 'Book' }
    & Pick<Book, 'isbn' | 'yl' | 'title' | 'imageUrl' | 'author'>
  ) }
);

export type ReactiveHistoryAllFragment = (
  { __typename?: 'ReactiveHistory' }
  & Pick<ReactiveHistory, 'state' | 'message' | 'response' | 'sentTime' | 'responseTime' | 'speaker'>
  & { question?: Maybe<(
    { __typename: 'OpenEnded' }
    & OpenEndedAllFragment
  ) | (
    { __typename: 'Choices' }
    & ChoicesAllFragment
  ) | (
    { __typename: 'DummyQuestion' }
    & DummyQuestionAllFragment
  )> }
);

export type RecommendOnTalkFragment = (
  { __typename?: 'Recommend' }
  & Pick<Recommend, 'id' | 'whenRecommend' | 'recommendComment'>
  & { book: (
    { __typename?: 'Book' }
    & Pick<Book, 'isbn' | 'yl' | 'title' | 'imageUrl' | 'author'>
  ) }
);

export type UserOnTalkFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'nickname' | 'userIconType'>
);

export type DeletedUserOnTalkFragment = (
  { __typename?: 'DeletedUser' }
  & Pick<DeletedUser, 'userID'>
);

export type OpenEndedAllFragment = (
  { __typename?: 'OpenEnded' }
  & Pick<OpenEnded, 'answer'>
);

export type ChoicesAllFragment = (
  { __typename?: 'Choices' }
  & Pick<Choices, 'multiple'>
  & { items: Array<(
    { __typename?: 'ChoiceItem' }
    & ChoiceItemFragment
  )> }
);

export type ChoiceItemFragment = (
  { __typename?: 'ChoiceItem' }
  & Pick<ChoiceItem, 'choice' | 'selected'>
);

export type DummyQuestionAllFragment = (
  { __typename?: 'DummyQuestion' }
  & Pick<DummyQuestion, 'dummy'>
);

export type TalkLogAllFragment = (
  { __typename?: 'TalkLog' }
  & Pick<TalkLog, 'count'>
  & { old: Array<(
    { __typename?: 'Talk' }
    & TalkAllFragment
  )>, unread: Array<(
    { __typename?: 'Talk' }
    & TalkAllFragment
  )> }
);

export type TalkUpdateAllFragment = (
  { __typename?: 'TalkUpdate' }
  & Pick<TalkUpdate, 'delete'>
  & { change: Array<(
    { __typename?: 'Talk' }
    & TalkAllFragment
  )>, new: Array<(
    { __typename?: 'Talk' }
    & TalkAllFragment
  )> }
);

export type TalkCacheAllFragment = (
  { __typename?: 'TalkCache' }
  & Pick<TalkCache, 'state' | 'digAll' | 'count'>
  & { oldTalks: Array<(
    { __typename?: 'Talk' }
    & TalkAllFragment
  )>, unreadTalks?: Maybe<Array<(
    { __typename?: 'Talk' }
    & TalkAllFragment
  )>> }
);

export type AddBfReadLogReactionMutationVariables = Exact<{
  readLogId: Scalars['Int'];
  reactionType: ReadLogReactionType;
}>;


export type AddBfReadLogReactionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addBfReadLogReaction'>
);

export type AddBfReadLogStarMutationVariables = Exact<{
  readLogID: Scalars['Int'];
  readingTechniqueType: ReadingTechniqueType;
}>;


export type AddBfReadLogStarMutation = (
  { __typename?: 'Mutation' }
  & { addBfReadLogStar: (
    { __typename?: 'OK' }
    & Pick<Ok, 'state'>
  ) }
);

export type GetWantReadBooksQueryVariables = Exact<{
  begin: Scalars['Int'];
  end: Scalars['Int'];
}>;


export type GetWantReadBooksQuery = (
  { __typename?: 'Query' }
  & { getWantReadBooks: (
    { __typename?: 'WantReadBookList' }
    & Pick<WantReadBookList, 'count'>
    & { get: Array<(
      { __typename?: 'WantReadBook' }
      & Pick<WantReadBook, 'id' | 'userID' | 'recommendStatus' | 'createdAt' | 'readRecommendID'>
      & { book: (
        { __typename?: 'Book' }
        & Pick<Book, 'isbn' | 'yl' | 'publisher' | 'pageCount' | 'author' | 'painter' | 'wordCount' | 'title' | 'published' | 'synopsis' | 'imageUrl' | 'impression' | 'affiliateLink'>
      ) }
    )> }
  ) }
);

export type AddWantReadBookMutationVariables = Exact<{
  readLogId: Scalars['Int'];
}>;


export type AddWantReadBookMutation = (
  { __typename?: 'Mutation' }
  & { addWantReadBook: (
    { __typename?: 'OK' }
    & Pick<Ok, 'state'>
  ) }
);

export type GetAvailableStarEnergyIdQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAvailableStarEnergyIdQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getAvailableStarEnergyID'>
);

export type GetBfReadLogsQueryVariables = Exact<{
  limit: Scalars['Int'];
  exclusiveStartId?: Maybe<Scalars['String']>;
  direction: SearchDirection;
}>;


export type GetBfReadLogsQuery = (
  { __typename?: 'Query' }
  & { getBfReadLogs: Array<(
    { __typename?: 'BfReadLog' }
    & BfReadLogFragment
  )> }
);

export type UpsertLatestBfReadLogViewTimeMutationVariables = Exact<{ [key: string]: never; }>;


export type UpsertLatestBfReadLogViewTimeMutation = (
  { __typename?: 'Mutation' }
  & { upsertLatestBfReadLogViewTime: (
    { __typename?: 'OK' }
    & Pick<Ok, 'state'>
  ) }
);

export type GetLatestBfReadLogViewTimeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLatestBfReadLogViewTimeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getLatestBfReadLogViewTime'>
);

export type GetCurrentRecommendNumberQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentRecommendNumberQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'Account' }
    & { recommends: (
      { __typename?: 'RecommendList' }
      & Pick<RecommendList, 'count'>
    ) }
  ) }
);

export type GetMissionProgressQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMissionProgressQuery = (
  { __typename?: 'Query' }
  & { getMissionProgress: (
    { __typename?: 'MissionProgress' }
    & Pick<MissionProgress, 'missionOrder' | 'missionState'>
    & { userMissionTasks?: Maybe<Array<(
      { __typename?: 'UserMissionTask' }
      & Pick<UserMissionTask, 'missionTaskType' | 'current' | 'goal'>
    )>> }
  ) }
);

export type StartMissionMutationVariables = Exact<{ [key: string]: never; }>;


export type StartMissionMutation = (
  { __typename?: 'Mutation' }
  & { startMission: (
    { __typename?: 'MissionProgress' }
    & Pick<MissionProgress, 'missionOrder' | 'missionState'>
    & { userMissionTasks?: Maybe<Array<(
      { __typename?: 'UserMissionTask' }
      & Pick<UserMissionTask, 'missionTaskType' | 'current' | 'goal'>
    )>> }
  ) }
);

export type CompleteMissionMutationVariables = Exact<{ [key: string]: never; }>;


export type CompleteMissionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'completeMission'>
);

export type AnswerCharacterEnqueteMutationVariables = Exact<{
  input: AnswerCharacterEnqueteInput;
}>;


export type AnswerCharacterEnqueteMutation = (
  { __typename?: 'Mutation' }
  & { answerCharacterEnquete: (
    { __typename?: 'OK' }
    & Pick<Ok, 'state'>
  ) }
);

export type GetSuitableYlResultQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSuitableYlResultQuery = (
  { __typename?: 'Query' }
  & { getSuitableYL: (
    { __typename?: 'SuitableYL' }
    & Pick<SuitableYl, 'value'>
  ) | (
    { __typename?: 'ErrorSuitableYLNotFound' }
    & Pick<ErrorSuitableYlNotFound, 'message'>
  ) }
);

export type GetMyKanziLevelQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyKanziLevelQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'Account' }
    & Pick<Account, 'kanziLevel'>
  ) }
);

export type UpdateKanziLevelMutationVariables = Exact<{
  accountInfo: UpdateAccountInput;
}>;


export type UpdateKanziLevelMutation = (
  { __typename?: 'Mutation' }
  & { updateAccount: (
    { __typename?: 'Account' }
    & Pick<Account, 'kanziLevel'>
  ) }
);

export type GetMyProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyProfileQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'Account' }
    & Pick<Account, 'kanziLevel' | 'recommendFrequency'>
    & { general: (
      { __typename?: 'User' }
      & Pick<User, 'nickname' | 'userIconType' | 'id'>
    ) }
  ) }
);

export type UpdateProfileMutationVariables = Exact<{
  accountInfo: UpdateAccountInput;
}>;


export type UpdateProfileMutation = (
  { __typename?: 'Mutation' }
  & { updateAccount: (
    { __typename?: 'Account' }
    & Pick<Account, 'kanziLevel'>
    & { general: (
      { __typename?: 'User' }
      & Pick<User, 'nickname' | 'userIconType'>
    ) }
  ) }
);

export type GetOneReadlogQueryVariables = Exact<{
  readlogID: Scalars['Int'];
}>;


export type GetOneReadlogQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'Account' }
    & { readLog: (
      { __typename?: 'ReadLogList' }
      & { get: Array<(
        { __typename?: 'ReadLog' }
        & Pick<ReadLog, 'review' | 'favoriteScene'>
      )> }
    ) }
  ) }
);

export type UpdateOneReadlogMutationVariables = Exact<{
  readLogUpdate: ReadLogUpdate;
}>;


export type UpdateOneReadlogMutation = (
  { __typename?: 'Mutation' }
  & { editReadLog: (
    { __typename?: 'ReadLog' }
    & Pick<ReadLog, 'review' | 'favoriteScene'>
  ) }
);

export type ChangeRecommendFrequencyMutationVariables = Exact<{
  frequency: RecommendFrequency;
}>;


export type ChangeRecommendFrequencyMutation = (
  { __typename?: 'Mutation' }
  & { changeRecommendFrequency: (
    { __typename?: 'Account' }
    & Pick<Account, 'recommendFrequency'>
  ) }
);

export type GetCurrentRecommendFrequencyQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentRecommendFrequencyQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'Account' }
    & Pick<Account, 'recommendFrequency'>
  ) }
);

export type GetFirstBookRecommendsQueryVariables = Exact<{
  begin: Scalars['Int'];
  end: Scalars['Int'];
}>;


export type GetFirstBookRecommendsQuery = (
  { __typename?: 'Query' }
  & { getFirstBookRecommends: Array<(
    { __typename?: 'Recommend' }
    & Pick<Recommend, 'id' | 'whenDone' | 'whenRecommend' | 'recommendComment'>
    & { book: (
      { __typename?: 'Book' }
      & Pick<Book, 'title' | 'imageUrl' | 'isbn'>
    ), readLog: (
      { __typename?: 'ReadLogList' }
      & Pick<ReadLogList, 'count'>
      & { get: Array<(
        { __typename?: 'ReadLog' }
        & Pick<ReadLog, 'id' | 'status'>
      )> }
    ) }
  )> }
);

export type GetPostPreBookRecommendLogQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPostPreBookRecommendLogQuery = (
  { __typename?: 'Query' }
  & { getPostPreBookRecommendLog?: Maybe<(
    { __typename?: 'PostPreBookRecommendLog' }
    & Pick<PostPreBookRecommendLog, 'id'>
    & { preBookRecommends: Array<(
      { __typename?: 'PreBookRecommend' }
      & Pick<PreBookRecommend, 'id'>
      & { book: (
        { __typename?: 'Book' }
        & Pick<Book, 'title' | 'imageUrl' | 'isbn'>
      ) }
    )> }
  )> }
);

export type AnswerPreBookRecommendEnqueteMutationVariables = Exact<{
  input: AnswerPreBookRecommendEnqueteInput;
}>;


export type AnswerPreBookRecommendEnqueteMutation = (
  { __typename?: 'Mutation' }
  & { answerPreBookRecommendEnquete: (
    { __typename?: 'OK' }
    & Pick<Ok, 'state'>
  ) }
);

export type GetOlderMessageLogQueryVariables = Exact<{
  current: Scalars['Int'];
  count: Scalars['Int'];
}>;


export type GetOlderMessageLogQuery = (
  { __typename?: 'Query' }
  & { getOlderMessageLog: Array<(
    { __typename?: 'Talk' }
    & TalkAllFragment
  )> }
);

export type PostReadLogMutationVariables = Exact<{
  readLogInfo: ReadLogInput;
}>;


export type PostReadLogMutation = (
  { __typename?: 'Mutation' }
  & { postReadLog: (
    { __typename?: 'PostReadLogResult' }
    & PostReadLogResultForFeedbackModalFragment
    & ExperienceEventResultFragment
  ) }
);

export type GetNowRecommendQueryVariables = Exact<{
  begin: Scalars['Int'];
  end: Scalars['Int'];
}>;


export type GetNowRecommendQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'Account' }
    & { recommends: (
      { __typename?: 'RecommendList' }
      & Pick<RecommendList, 'count'>
      & { get: Array<(
        { __typename?: 'Recommend' }
        & Pick<Recommend, 'id' | 'whenDone'>
        & { book: (
          { __typename?: 'Book' }
          & Pick<Book, 'title' | 'imageUrl' | 'isbn'>
        ) }
      )> }
    ) }
  ) }
);

export type GetBookDataByRecommendIdQueryVariables = Exact<{
  recommendId: Scalars['Int'];
}>;


export type GetBookDataByRecommendIdQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'Account' }
    & { recommends: (
      { __typename?: 'RecommendList' }
      & { get: Array<(
        { __typename?: 'Recommend' }
        & { book: (
          { __typename?: 'Book' }
          & Pick<Book, 'isbn' | 'title'>
        ) }
      )> }
    ) }
  ) }
);

export type GetBookByIsbnMutationVariables = Exact<{
  isbn: Scalars['String'];
}>;


export type GetBookByIsbnMutation = (
  { __typename?: 'Mutation' }
  & { searchBookWithPaapi: (
    { __typename?: 'Book' }
    & Pick<Book, 'isbn' | 'title' | 'imageUrl'>
  ) }
);

export type PostReadLogResultForFeedbackModalFragment = (
  { __typename?: 'PostReadLogResult' }
  & Pick<PostReadLogResult, 'continuousFulfilledPromise' | 'wasWantReadBook'>
  & { readLog: (
    { __typename?: 'ReadLog' }
    & Pick<ReadLog, 'id' | 'like' | 'difficulty' | 'emotions' | 'length' | 'status' | 'review'>
    & { book: (
      { __typename?: 'Book' }
      & Pick<Book, 'title' | 'impression'>
    ) }
  ), promise?: Maybe<(
    { __typename?: 'ReadLogPromise' }
    & Pick<ReadLogPromise, 'dueDate' | 'status'>
  )>, account: (
    { __typename?: 'Account' }
    & { ongoingPromise?: Maybe<(
      { __typename?: 'ReadLogPromise' }
      & Pick<ReadLogPromise, 'dueDate' | 'status'>
    )>, general: (
      { __typename?: 'User' }
      & Pick<User, 'continueReadLogDays' | 'maxContinueReadLogDays' | 'readBookSum' | 'readCharSum' | 'nickname'>
    ) }
  ) }
);

export type ExperienceEventResultFragment = (
  { __typename?: 'PostReadLogResult' }
  & { account: (
    { __typename?: 'Account' }
    & { general: (
      { __typename?: 'User' }
      & { userExperience: (
        { __typename?: 'UserExperience' }
        & Pick<UserExperience, 'userLevel' | 'expAfterLastLevelUp' | 'expToNextLevelUp'>
      ) }
    ) }
  ), userExperienceEvent: Array<Maybe<(
    { __typename?: 'UserExperienceEvent' }
    & Pick<UserExperienceEvent, 'eventName' | 'point'>
  )>> }
);

export type MakePromiseMutationVariables = Exact<{
  promiseDate: PromiseDate;
  chooseNext: Scalars['Boolean'];
  nextISBN?: Maybe<Scalars['String']>;
}>;


export type MakePromiseMutation = (
  { __typename?: 'Mutation' }
  & { createReadLogPromise: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) }
);

export type GetFootprintQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFootprintQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'Account' }
    & Pick<Account, 'createdAt'>
    & { general: (
      { __typename?: 'User' }
      & Pick<User, 'nickname' | 'readBookSum' | 'readCharSum' | 'continueReadLogDays' | 'maxContinueReadLogDays' | 'miniLessonTotalNum' | 'readLogTotalNum' | 'receivedTotalBfReadLogReactionNum'>
      & { receivedBfReadLogStar: Array<(
        { __typename?: 'BfReadLogStarNum' }
        & Pick<BfReadLogStarNum, 'readingTechniqueType' | 'num'>
      )> }
    ) }
  ) }
);

export type GetRecordBookDiffQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRecordBookDiffQuery = (
  { __typename?: 'Query' }
  & { getRecordBookDiff: (
    { __typename?: 'GetRecordBookDiffResult' }
    & Pick<GetRecordBookDiffResult, 'IsFirstTimeViewInWeek' | 'TermStartDate' | 'TermEndDate' | 'ReadCharSumDiff' | 'ReadBookSumDiff' | 'MiniLessonTotalNumDiff'>
  ) }
);

export type UpsertLatestRecordBookViewTimeMutationVariables = Exact<{ [key: string]: never; }>;


export type UpsertLatestRecordBookViewTimeMutation = (
  { __typename?: 'Mutation' }
  & { upsertLatestRecordBookViewTime: (
    { __typename?: 'OK' }
    & Pick<Ok, 'state'>
  ) }
);

export type GetMyRecordQueryVariables = Exact<{
  timeRange: TimeRange;
}>;


export type GetMyRecordQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'Account' }
    & Pick<Account, 'createdAt'>
    & { general: (
      { __typename?: 'User' }
      & Pick<User, 'nickname' | 'readBookSum' | 'readCharSum' | 'continueReadLogDays' | 'maxContinueReadLogDays' | 'continueMiniLessonDays'>
    ) }
  ) }
);

export type GetDoneRecommendBookshelfQueryVariables = Exact<{
  begin: Scalars['Int'];
  end: Scalars['Int'];
}>;


export type GetDoneRecommendBookshelfQuery = (
  { __typename?: 'Query' }
  & { getDoneRecommends: (
    { __typename?: 'RecommendList' }
    & Pick<RecommendList, 'count'>
    & { get: Array<(
      { __typename?: 'Recommend' }
      & Pick<Recommend, 'id' | 'recommendStatus' | 'whenDone'>
      & { book: (
        { __typename?: 'Book' }
        & Pick<Book, 'title' | 'imageUrl' | 'yl'>
      ), readLog: (
        { __typename?: 'ReadLogList' }
        & { get: Array<(
          { __typename?: 'ReadLog' }
          & Pick<ReadLog, 'review' | 'like'>
        )> }
      ) }
    )> }
  ) }
);

export type RemoveRecommendMutationVariables = Exact<{
  recommendID: Scalars['Int'];
  removeReason: RemoveReason;
}>;


export type RemoveRecommendMutation = (
  { __typename?: 'Mutation' }
  & { removeRecommend: (
    { __typename?: 'Recommend' }
    & Pick<Recommend, 'id'>
  ) }
);

export type GetUserAvailabilitiesForVerificationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserAvailabilitiesForVerificationQuery = (
  { __typename?: 'Query' }
  & { getUsers: Array<(
    { __typename?: 'UserAvailability' }
    & Pick<UserAvailability, 'userID' | 'nickname' | 'userPlan' | 'userIconType'>
  )> }
);

export type GetDoneRecommendQueryVariables = Exact<{
  begin: Scalars['Int'];
  end: Scalars['Int'];
}>;


export type GetDoneRecommendQuery = (
  { __typename?: 'Query' }
  & { getDoneRecommends: (
    { __typename?: 'RecommendList' }
    & { get: Array<(
      { __typename?: 'Recommend' }
      & Pick<Recommend, 'id'>
      & { book: (
        { __typename?: 'Book' }
        & Pick<Book, 'imageUrl'>
      ) }
    )> }
  ) }
);

export type GetOneRecommendInfoQueryVariables = Exact<{
  recommendID: Scalars['Int'];
  begin: Scalars['Int'];
  end: Scalars['Int'];
}>;


export type GetOneRecommendInfoQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'Account' }
    & { recommends: (
      { __typename?: 'RecommendList' }
      & { get: Array<(
        { __typename?: 'Recommend' }
        & Pick<Recommend, 'id' | 'whenRecommend' | 'whenDone' | 'recommendStatus' | 'recommendComment' | 'self'>
        & { book: (
          { __typename?: 'Book' }
          & Pick<Book, 'isbn' | 'yl' | 'publisher' | 'pageCount' | 'author' | 'painter' | 'wordCount' | 'title' | 'published' | 'synopsis' | 'imageUrl'>
        ), readLog: (
          { __typename?: 'ReadLogList' }
          & Pick<ReadLogList, 'count'>
          & { get: Array<(
            { __typename?: 'ReadLog' }
            & Pick<ReadLog, 'id' | 'when' | 'review' | 'emotions' | 'difficulty' | 'like' | 'status' | 'favoriteScene'>
          )> }
        ) }
      )> }
    ) }
  ) }
);

export type SearchCityByMyLibraryQueryVariables = Exact<{ [key: string]: never; }>;


export type SearchCityByMyLibraryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'searchCityByMyLibrary'>
);

export type GetWantReadBookByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetWantReadBookByIdQuery = (
  { __typename?: 'Query' }
  & { getWantReadBookByID: (
    { __typename?: 'WantReadBook' }
    & Pick<WantReadBook, 'id' | 'userID' | 'readRecommendID' | 'recommendStatus'>
    & { book: (
      { __typename?: 'Book' }
      & Pick<Book, 'isbn' | 'yl' | 'publisher' | 'pageCount' | 'author' | 'painter' | 'wordCount' | 'title' | 'published' | 'synopsis' | 'imageUrl' | 'impression' | 'affiliateLink'>
    ) }
  ) }
);

export type CreateYlReassesmentEnqueteMutationVariables = Exact<{
  input: YlReassesmentEnqueteInput;
}>;


export type CreateYlReassesmentEnqueteMutation = (
  { __typename?: 'Mutation' }
  & { createYLReassesmentEnquete: (
    { __typename?: 'OK' }
    & Pick<Ok, 'state'>
  ) | (
    { __typename?: 'ErrorSuitableYLNotFound' }
    & Pick<ErrorSuitableYlNotFound, 'message'>
  ) }
);

export type DestroyAuthSessionMutationVariables = Exact<{ [key: string]: never; }>;


export type DestroyAuthSessionMutation = (
  { __typename?: 'Mutation' }
  & { destroyAuthSession: (
    { __typename?: 'OK' }
    & Pick<Ok, 'state'>
  ) }
);

export type BfGroupCacheQueryVariables = Exact<{ [key: string]: never; }>;


export type BfGroupCacheQuery = (
  { __typename?: 'Query' }
  & { bfGroupCache: (
    { __typename?: 'BfReadLogCache' }
    & BfReadLogCacheAllFragment
  ) }
);

export type MessageCacheQueryVariables = Exact<{ [key: string]: never; }>;


export type MessageCacheQuery = (
  { __typename?: 'Query' }
  & { messageCache: (
    { __typename?: 'TalkCache' }
    & TalkCacheAllFragment
  ) }
);

export type TutorialFlagsQueryVariables = Exact<{
  tutorialFlagKeys: Array<Maybe<TutorialFlagKey>> | Maybe<TutorialFlagKey>;
}>;


export type TutorialFlagsQuery = (
  { __typename?: 'Query' }
  & { getTutorialFlags: Array<(
    { __typename?: 'TutorialFlag' }
    & Pick<TutorialFlag, 'key' | 'value'>
  )> }
);

export type SetTutorialFlagMutationVariables = Exact<{
  input: DoneTutorialFlagInput;
}>;


export type SetTutorialFlagMutation = (
  { __typename?: 'Mutation' }
  & { doneTutorialByFlag: (
    { __typename?: 'TutorialFlag' }
    & Pick<TutorialFlag, 'key' | 'value'>
  ) }
);

export type GetMessageLogMutationVariables = Exact<{
  oldCount: Scalars['Int'];
}>;


export type GetMessageLogMutation = (
  { __typename?: 'Mutation' }
  & { getMessageLog: (
    { __typename?: 'TalkLog' }
    & TalkLogAllFragment
  ) }
);

export const ReadLogOnTalkFragmentDoc = gql`
    fragment ReadLogOnTalk on ReadLog {
  id
  when
  user {
    ... on User {
      id
      nickname
      userIconType
    }
    ... on DeletedUser {
      userID
    }
  }
  book {
    isbn
    yl
    title
    imageUrl
    author
  }
  review
  like
  status
  myReaction
  myStar
  isMyWantReadBook
}
    `;
export const UserOnTalkFragmentDoc = gql`
    fragment UserOnTalk on User {
  id
  nickname
  userIconType
}
    `;
export const DeletedUserOnTalkFragmentDoc = gql`
    fragment DeletedUserOnTalk on DeletedUser {
  userID
}
    `;
export const BfReadLogReactionNoticeItemFragmentDoc = gql`
    fragment bfReadLogReactionNoticeItem on BfReadLogReactionNoticeItem {
  id
  readLog {
    ...ReadLogOnTalk
  }
  reactionType
  reactionUser {
    __typename
    ...UserOnTalk
    ...DeletedUserOnTalk
  }
  createdAt
}
    ${ReadLogOnTalkFragmentDoc}
${UserOnTalkFragmentDoc}
${DeletedUserOnTalkFragmentDoc}`;
export const TeacherReadLogReactionNoticeItemFragmentDoc = gql`
    fragment teacherReadLogReactionNoticeItem on TeacherReadLogReactionNoticeItem {
  id
  readLog {
    ...ReadLogOnTalk
  }
  createdAt
}
    ${ReadLogOnTalkFragmentDoc}`;
export const BfReadLogStarNoticeItemFragmentDoc = gql`
    fragment bfReadLogStarNoticeItem on BfReadLogStarNoticeItem {
  id
  readLog {
    ...ReadLogOnTalk
  }
  readingTechniqueType
  reactionUser {
    __typename
    ...UserOnTalk
    ...DeletedUserOnTalk
  }
  createdAt
}
    ${ReadLogOnTalkFragmentDoc}
${UserOnTalkFragmentDoc}
${DeletedUserOnTalkFragmentDoc}`;
export const BookshelfRecommendHistoryFragmentDoc = gql`
    fragment bookshelfRecommendHistory on Recommend {
  id
  whenDone
  recommendStatus
  book {
    title
    imageUrl
    isbn
  }
}
    `;
export const BookshelfCurrentRecommendFragmentDoc = gql`
    fragment bookshelfCurrentRecommend on Recommend {
  id
  whenDone
  whenRecommend
  recommendComment
  book {
    title
    imageUrl
    isbn
    yl
  }
  readLog(statusEnd: true, statusReading: true, statusQuit: true) {
    count
    get(begin: $begin, end: $end, order: CREATED_AT_ASC) {
      id
      status
    }
  }
}
    `;
export const CurrentUserFragmentDoc = gql`
    fragment CurrentUser on Account {
  haveUnreadInMessage
  haveUnreadInBfGroupMessage
  recommendFrequency
  birthday
  createdAt
  kanziLevel
  belong {
    id
  }
  general {
    id
    userIconType
    nickname
    userExperience {
      userLevel
      expAfterLastLevelUp
      expToNextLevelUp
    }
  }
}
    `;
export const BfReadLogInfoFragmentDoc = gql`
    fragment BfReadLogInfo on BfReadLogReadLogInfo {
  readLogId
  book {
    isbn
    yl
    title
    imageUrl
    author
  }
  review
  like
  status
  isMyWantReadBook
}
    `;
export const BfReadLogFragmentDoc = gql`
    fragment BfReadLog on BfReadLog {
  id
  createdAt
  user {
    ... on User {
      id
      nickname
      userIconType
    }
    ... on DeletedUser {
      userID
    }
  }
  readLogInfo {
    ...BfReadLogInfo
  }
  myReaction {
    starType
    reactionType
  }
}
    ${BfReadLogInfoFragmentDoc}`;
export const BfReadLogCacheAllFragmentDoc = gql`
    fragment BfReadLogCacheAll on BfReadLogCache {
  state
  digPrevAll
  digNextAll
  latestBfReadLogViewTime
  bfReadLogs {
    ...BfReadLog
  }
}
    ${BfReadLogFragmentDoc}`;
export const RemarkAllFragmentDoc = gql`
    fragment RemarkAll on Remark {
  time
  text
}
    `;
export const OpenEndedAllFragmentDoc = gql`
    fragment OpenEndedAll on OpenEnded {
  answer
}
    `;
export const ChoiceItemFragmentDoc = gql`
    fragment ChoiceItem on ChoiceItem {
  choice
  selected
}
    `;
export const ChoicesAllFragmentDoc = gql`
    fragment ChoicesAll on Choices {
  multiple
  items {
    ...ChoiceItem
  }
}
    ${ChoiceItemFragmentDoc}`;
export const DummyQuestionAllFragmentDoc = gql`
    fragment DummyQuestionAll on DummyQuestion {
  dummy
}
    `;
export const ReactiveHistoryAllFragmentDoc = gql`
    fragment ReactiveHistoryAll on ReactiveHistory {
  state
  message
  question {
    __typename
    ...OpenEndedAll
    ...ChoicesAll
    ...DummyQuestionAll
  }
  response
  sentTime
  responseTime
  speaker
}
    ${OpenEndedAllFragmentDoc}
${ChoicesAllFragmentDoc}
${DummyQuestionAllFragmentDoc}`;
export const RecommendOnTalkFragmentDoc = gql`
    fragment RecommendOnTalk on Recommend {
  id
  whenRecommend
  recommendComment
  book {
    isbn
    yl
    title
    imageUrl
    author
  }
}
    `;
export const TalkAllFragmentDoc = gql`
    fragment TalkAll on Talk {
  id
  item {
    __typename
    ...RemarkAll
    ...ReadLogOnTalk
    ...ReactiveHistoryAll
    ...RecommendOnTalk
  }
  user {
    __typename
    ...UserOnTalk
    ...DeletedUserOnTalk
  }
}
    ${RemarkAllFragmentDoc}
${ReadLogOnTalkFragmentDoc}
${ReactiveHistoryAllFragmentDoc}
${RecommendOnTalkFragmentDoc}
${UserOnTalkFragmentDoc}
${DeletedUserOnTalkFragmentDoc}`;
export const TalkLogAllFragmentDoc = gql`
    fragment TalkLogAll on TalkLog {
  old {
    ...TalkAll
  }
  unread {
    ...TalkAll
  }
  count
}
    ${TalkAllFragmentDoc}`;
export const TalkUpdateAllFragmentDoc = gql`
    fragment TalkUpdateAll on TalkUpdate {
  delete
  change {
    ...TalkAll
  }
  new {
    ...TalkAll
  }
}
    ${TalkAllFragmentDoc}`;
export const TalkCacheAllFragmentDoc = gql`
    fragment TalkCacheAll on TalkCache {
  state
  digAll
  count
  oldTalks {
    ...TalkAll
  }
  unreadTalks {
    ...TalkAll
  }
}
    ${TalkAllFragmentDoc}`;
export const PostReadLogResultForFeedbackModalFragmentDoc = gql`
    fragment PostReadLogResultForFeedbackModal on PostReadLogResult {
  readLog {
    id
    like
    difficulty
    emotions
    length
    status
    book {
      title
      impression
    }
    review
  }
  promise {
    dueDate
    status
  }
  continuousFulfilledPromise
  account {
    ongoingPromise {
      dueDate
      status
    }
    general {
      continueReadLogDays
      maxContinueReadLogDays
      readBookSum(timeRange: ALL)
      readCharSum(timeRange: ALL)
      nickname
    }
  }
  wasWantReadBook
}
    `;
export const ExperienceEventResultFragmentDoc = gql`
    fragment ExperienceEventResult on PostReadLogResult {
  account {
    general {
      userExperience {
        userLevel
        expAfterLastLevelUp
        expToNextLevelUp
      }
    }
  }
  userExperienceEvent {
    eventName
    point
  }
}
    `;
export const GetUnreadBfNoticeExistDocument = gql`
    query getUnreadBfNoticeExist {
  getUnreadBfNoticeExist
}
    `;

/**
 * __useGetUnreadBfNoticeExistQuery__
 *
 * To run a query within a React component, call `useGetUnreadBfNoticeExistQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnreadBfNoticeExistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnreadBfNoticeExistQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUnreadBfNoticeExistQuery(baseOptions?: Apollo.QueryHookOptions<GetUnreadBfNoticeExistQuery, GetUnreadBfNoticeExistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnreadBfNoticeExistQuery, GetUnreadBfNoticeExistQueryVariables>(GetUnreadBfNoticeExistDocument, options);
      }
export function useGetUnreadBfNoticeExistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnreadBfNoticeExistQuery, GetUnreadBfNoticeExistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnreadBfNoticeExistQuery, GetUnreadBfNoticeExistQueryVariables>(GetUnreadBfNoticeExistDocument, options);
        }
export type GetUnreadBfNoticeExistQueryHookResult = ReturnType<typeof useGetUnreadBfNoticeExistQuery>;
export type GetUnreadBfNoticeExistLazyQueryHookResult = ReturnType<typeof useGetUnreadBfNoticeExistLazyQuery>;
export type GetUnreadBfNoticeExistQueryResult = Apollo.QueryResult<GetUnreadBfNoticeExistQuery, GetUnreadBfNoticeExistQueryVariables>;
export const GetBfNoticeDocument = gql`
    query getBfNotice($begin: Int, $end: Int) {
  getBfNotice {
    unreadBorderTime
    getBfNoticeList {
      count
      get(begin: $begin, end: $end) {
        ...bfReadLogReactionNoticeItem
        ...teacherReadLogReactionNoticeItem
        ...bfReadLogStarNoticeItem
      }
    }
  }
}
    ${BfReadLogReactionNoticeItemFragmentDoc}
${TeacherReadLogReactionNoticeItemFragmentDoc}
${BfReadLogStarNoticeItemFragmentDoc}`;

/**
 * __useGetBfNoticeQuery__
 *
 * To run a query within a React component, call `useGetBfNoticeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBfNoticeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBfNoticeQuery({
 *   variables: {
 *      begin: // value for 'begin'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useGetBfNoticeQuery(baseOptions?: Apollo.QueryHookOptions<GetBfNoticeQuery, GetBfNoticeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBfNoticeQuery, GetBfNoticeQueryVariables>(GetBfNoticeDocument, options);
      }
export function useGetBfNoticeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBfNoticeQuery, GetBfNoticeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBfNoticeQuery, GetBfNoticeQueryVariables>(GetBfNoticeDocument, options);
        }
export type GetBfNoticeQueryHookResult = ReturnType<typeof useGetBfNoticeQuery>;
export type GetBfNoticeLazyQueryHookResult = ReturnType<typeof useGetBfNoticeLazyQuery>;
export type GetBfNoticeQueryResult = Apollo.QueryResult<GetBfNoticeQuery, GetBfNoticeQueryVariables>;
export const UpdateUnreadBfNoticeDocument = gql`
    mutation updateUnreadBfNotice {
  updateUnreadBfNotice
}
    `;
export type UpdateUnreadBfNoticeMutationFn = Apollo.MutationFunction<UpdateUnreadBfNoticeMutation, UpdateUnreadBfNoticeMutationVariables>;

/**
 * __useUpdateUnreadBfNoticeMutation__
 *
 * To run a mutation, you first call `useUpdateUnreadBfNoticeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUnreadBfNoticeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUnreadBfNoticeMutation, { data, loading, error }] = useUpdateUnreadBfNoticeMutation({
 *   variables: {
 *   },
 * });
 */
export function useUpdateUnreadBfNoticeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUnreadBfNoticeMutation, UpdateUnreadBfNoticeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUnreadBfNoticeMutation, UpdateUnreadBfNoticeMutationVariables>(UpdateUnreadBfNoticeDocument, options);
      }
export type UpdateUnreadBfNoticeMutationHookResult = ReturnType<typeof useUpdateUnreadBfNoticeMutation>;
export type UpdateUnreadBfNoticeMutationResult = Apollo.MutationResult<UpdateUnreadBfNoticeMutation>;
export type UpdateUnreadBfNoticeMutationOptions = Apollo.BaseMutationOptions<UpdateUnreadBfNoticeMutation, UpdateUnreadBfNoticeMutationVariables>;
export const GetAllUnDoneRecommendDocument = gql`
    query GetAllUnDoneRecommend($begin: Int!, $end: Int!) {
  me {
    recommends(done: false) {
      count
      get(begin: $begin, end: $end, order: RECOMMENDTIME) {
        recommendStatus
        self
        book {
          yl
        }
        ...bookshelfCurrentRecommend
      }
    }
  }
}
    ${BookshelfCurrentRecommendFragmentDoc}`;

/**
 * __useGetAllUnDoneRecommendQuery__
 *
 * To run a query within a React component, call `useGetAllUnDoneRecommendQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUnDoneRecommendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUnDoneRecommendQuery({
 *   variables: {
 *      begin: // value for 'begin'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useGetAllUnDoneRecommendQuery(baseOptions: Apollo.QueryHookOptions<GetAllUnDoneRecommendQuery, GetAllUnDoneRecommendQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllUnDoneRecommendQuery, GetAllUnDoneRecommendQueryVariables>(GetAllUnDoneRecommendDocument, options);
      }
export function useGetAllUnDoneRecommendLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllUnDoneRecommendQuery, GetAllUnDoneRecommendQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllUnDoneRecommendQuery, GetAllUnDoneRecommendQueryVariables>(GetAllUnDoneRecommendDocument, options);
        }
export type GetAllUnDoneRecommendQueryHookResult = ReturnType<typeof useGetAllUnDoneRecommendQuery>;
export type GetAllUnDoneRecommendLazyQueryHookResult = ReturnType<typeof useGetAllUnDoneRecommendLazyQuery>;
export type GetAllUnDoneRecommendQueryResult = Apollo.QueryResult<GetAllUnDoneRecommendQuery, GetAllUnDoneRecommendQueryVariables>;
export const LoginTipsDocument = gql`
    query loginTips {
  getLoginTips {
    ... on OnboardingTips {
      loginTimes
    }
    ... on ReadLogReviewTips {
      readLogWithReview {
        book {
          title
        }
      }
    }
    ... on PromisedBookTips {
      booktitle
    }
    ... on SelectBookTips {
      recommendList {
        count
        get(begin: 0, end: 10, order: FINISHTIME) {
          id
          book {
            isbn
            title
            imageUrl
          }
        }
      }
    }
  }
}
    `;

/**
 * __useLoginTipsQuery__
 *
 * To run a query within a React component, call `useLoginTipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginTipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginTipsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoginTipsQuery(baseOptions?: Apollo.QueryHookOptions<LoginTipsQuery, LoginTipsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoginTipsQuery, LoginTipsQueryVariables>(LoginTipsDocument, options);
      }
export function useLoginTipsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoginTipsQuery, LoginTipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoginTipsQuery, LoginTipsQueryVariables>(LoginTipsDocument, options);
        }
export type LoginTipsQueryHookResult = ReturnType<typeof useLoginTipsQuery>;
export type LoginTipsLazyQueryHookResult = ReturnType<typeof useLoginTipsLazyQuery>;
export type LoginTipsQueryResult = Apollo.QueryResult<LoginTipsQuery, LoginTipsQueryVariables>;
export const DoneTodaysLoginTipsDocument = gql`
    mutation doneTodaysLoginTips {
  doneTodaysLoginTips {
    state
  }
}
    `;
export type DoneTodaysLoginTipsMutationFn = Apollo.MutationFunction<DoneTodaysLoginTipsMutation, DoneTodaysLoginTipsMutationVariables>;

/**
 * __useDoneTodaysLoginTipsMutation__
 *
 * To run a mutation, you first call `useDoneTodaysLoginTipsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDoneTodaysLoginTipsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [doneTodaysLoginTipsMutation, { data, loading, error }] = useDoneTodaysLoginTipsMutation({
 *   variables: {
 *   },
 * });
 */
export function useDoneTodaysLoginTipsMutation(baseOptions?: Apollo.MutationHookOptions<DoneTodaysLoginTipsMutation, DoneTodaysLoginTipsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DoneTodaysLoginTipsMutation, DoneTodaysLoginTipsMutationVariables>(DoneTodaysLoginTipsDocument, options);
      }
export type DoneTodaysLoginTipsMutationHookResult = ReturnType<typeof useDoneTodaysLoginTipsMutation>;
export type DoneTodaysLoginTipsMutationResult = Apollo.MutationResult<DoneTodaysLoginTipsMutation>;
export type DoneTodaysLoginTipsMutationOptions = Apollo.BaseMutationOptions<DoneTodaysLoginTipsMutation, DoneTodaysLoginTipsMutationVariables>;
export const GetReadingRecommendsDocument = gql`
    query getReadingRecommends($begin: Int!, $end: Int!) {
  getReadingRecommends {
    count
    get(begin: $begin, end: $end, order: RECOMMENDTIME) {
      ...bookshelfRecommendHistory
    }
  }
}
    ${BookshelfRecommendHistoryFragmentDoc}`;

/**
 * __useGetReadingRecommendsQuery__
 *
 * To run a query within a React component, call `useGetReadingRecommendsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReadingRecommendsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReadingRecommendsQuery({
 *   variables: {
 *      begin: // value for 'begin'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useGetReadingRecommendsQuery(baseOptions: Apollo.QueryHookOptions<GetReadingRecommendsQuery, GetReadingRecommendsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReadingRecommendsQuery, GetReadingRecommendsQueryVariables>(GetReadingRecommendsDocument, options);
      }
export function useGetReadingRecommendsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReadingRecommendsQuery, GetReadingRecommendsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReadingRecommendsQuery, GetReadingRecommendsQueryVariables>(GetReadingRecommendsDocument, options);
        }
export type GetReadingRecommendsQueryHookResult = ReturnType<typeof useGetReadingRecommendsQuery>;
export type GetReadingRecommendsLazyQueryHookResult = ReturnType<typeof useGetReadingRecommendsLazyQuery>;
export type GetReadingRecommendsQueryResult = Apollo.QueryResult<GetReadingRecommendsQuery, GetReadingRecommendsQueryVariables>;
export const GetMyCurrentRecommendDocument = gql`
    query GetMyCurrentRecommend($begin: Int!, $end: Int!) {
  me {
    recommendFrequency
    recommends(self: false, done: false) {
      count
      get(begin: $begin, end: $end, order: RECOMMENDTIME) {
        ...bookshelfCurrentRecommend
      }
    }
  }
}
    ${BookshelfCurrentRecommendFragmentDoc}`;

/**
 * __useGetMyCurrentRecommendQuery__
 *
 * To run a query within a React component, call `useGetMyCurrentRecommendQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyCurrentRecommendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyCurrentRecommendQuery({
 *   variables: {
 *      begin: // value for 'begin'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useGetMyCurrentRecommendQuery(baseOptions: Apollo.QueryHookOptions<GetMyCurrentRecommendQuery, GetMyCurrentRecommendQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyCurrentRecommendQuery, GetMyCurrentRecommendQueryVariables>(GetMyCurrentRecommendDocument, options);
      }
export function useGetMyCurrentRecommendLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyCurrentRecommendQuery, GetMyCurrentRecommendQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyCurrentRecommendQuery, GetMyCurrentRecommendQueryVariables>(GetMyCurrentRecommendDocument, options);
        }
export type GetMyCurrentRecommendQueryHookResult = ReturnType<typeof useGetMyCurrentRecommendQuery>;
export type GetMyCurrentRecommendLazyQueryHookResult = ReturnType<typeof useGetMyCurrentRecommendLazyQuery>;
export type GetMyCurrentRecommendQueryResult = Apollo.QueryResult<GetMyCurrentRecommendQuery, GetMyCurrentRecommendQueryVariables>;
export const GetMyMiniLessonHistoryDocument = gql`
    query GetMyMiniLessonHistory($from: Date!, $to: Date!) {
  getMyMiniLessonHistory(from: $from, to: $to) {
    hasInProgressMiniLesson
    completedMiniLessonEndDates
  }
}
    `;

/**
 * __useGetMyMiniLessonHistoryQuery__
 *
 * To run a query within a React component, call `useGetMyMiniLessonHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyMiniLessonHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyMiniLessonHistoryQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetMyMiniLessonHistoryQuery(baseOptions: Apollo.QueryHookOptions<GetMyMiniLessonHistoryQuery, GetMyMiniLessonHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyMiniLessonHistoryQuery, GetMyMiniLessonHistoryQueryVariables>(GetMyMiniLessonHistoryDocument, options);
      }
export function useGetMyMiniLessonHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyMiniLessonHistoryQuery, GetMyMiniLessonHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyMiniLessonHistoryQuery, GetMyMiniLessonHistoryQueryVariables>(GetMyMiniLessonHistoryDocument, options);
        }
export type GetMyMiniLessonHistoryQueryHookResult = ReturnType<typeof useGetMyMiniLessonHistoryQuery>;
export type GetMyMiniLessonHistoryLazyQueryHookResult = ReturnType<typeof useGetMyMiniLessonHistoryLazyQuery>;
export type GetMyMiniLessonHistoryQueryResult = Apollo.QueryResult<GetMyMiniLessonHistoryQuery, GetMyMiniLessonHistoryQueryVariables>;
export const RemoveWantReadBookDocument = gql`
    mutation removeWantReadBook($id: Int!) {
  removeWantReadBook(input: {id: $id}) {
    state
  }
}
    `;
export type RemoveWantReadBookMutationFn = Apollo.MutationFunction<RemoveWantReadBookMutation, RemoveWantReadBookMutationVariables>;

/**
 * __useRemoveWantReadBookMutation__
 *
 * To run a mutation, you first call `useRemoveWantReadBookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveWantReadBookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeWantReadBookMutation, { data, loading, error }] = useRemoveWantReadBookMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveWantReadBookMutation(baseOptions?: Apollo.MutationHookOptions<RemoveWantReadBookMutation, RemoveWantReadBookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveWantReadBookMutation, RemoveWantReadBookMutationVariables>(RemoveWantReadBookDocument, options);
      }
export type RemoveWantReadBookMutationHookResult = ReturnType<typeof useRemoveWantReadBookMutation>;
export type RemoveWantReadBookMutationResult = Apollo.MutationResult<RemoveWantReadBookMutation>;
export type RemoveWantReadBookMutationOptions = Apollo.BaseMutationOptions<RemoveWantReadBookMutation, RemoveWantReadBookMutationVariables>;
export const GetLastBookSelectReactionDocument = gql`
    query getLastBookSelectReaction {
  getLastBookSelectReaction {
    hasNoPreviousBookSelect
    readLastBookSelectRecommends {
      title
      whenDone
      readLogReaction {
        like
        emotion
      }
    }
  }
}
    `;

/**
 * __useGetLastBookSelectReactionQuery__
 *
 * To run a query within a React component, call `useGetLastBookSelectReactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastBookSelectReactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastBookSelectReactionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLastBookSelectReactionQuery(baseOptions?: Apollo.QueryHookOptions<GetLastBookSelectReactionQuery, GetLastBookSelectReactionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLastBookSelectReactionQuery, GetLastBookSelectReactionQueryVariables>(GetLastBookSelectReactionDocument, options);
      }
export function useGetLastBookSelectReactionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLastBookSelectReactionQuery, GetLastBookSelectReactionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLastBookSelectReactionQuery, GetLastBookSelectReactionQueryVariables>(GetLastBookSelectReactionDocument, options);
        }
export type GetLastBookSelectReactionQueryHookResult = ReturnType<typeof useGetLastBookSelectReactionQuery>;
export type GetLastBookSelectReactionLazyQueryHookResult = ReturnType<typeof useGetLastBookSelectReactionLazyQuery>;
export type GetLastBookSelectReactionQueryResult = Apollo.QueryResult<GetLastBookSelectReactionQuery, GetLastBookSelectReactionQueryVariables>;
export const ShouldReturnSomeReactiveDocument = gql`
    query shouldReturnSomeReactive {
  me {
    shouldReturnSomeReactive
  }
}
    `;

/**
 * __useShouldReturnSomeReactiveQuery__
 *
 * To run a query within a React component, call `useShouldReturnSomeReactiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useShouldReturnSomeReactiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShouldReturnSomeReactiveQuery({
 *   variables: {
 *   },
 * });
 */
export function useShouldReturnSomeReactiveQuery(baseOptions?: Apollo.QueryHookOptions<ShouldReturnSomeReactiveQuery, ShouldReturnSomeReactiveQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShouldReturnSomeReactiveQuery, ShouldReturnSomeReactiveQueryVariables>(ShouldReturnSomeReactiveDocument, options);
      }
export function useShouldReturnSomeReactiveLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShouldReturnSomeReactiveQuery, ShouldReturnSomeReactiveQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShouldReturnSomeReactiveQuery, ShouldReturnSomeReactiveQueryVariables>(ShouldReturnSomeReactiveDocument, options);
        }
export type ShouldReturnSomeReactiveQueryHookResult = ReturnType<typeof useShouldReturnSomeReactiveQuery>;
export type ShouldReturnSomeReactiveLazyQueryHookResult = ReturnType<typeof useShouldReturnSomeReactiveLazyQuery>;
export type ShouldReturnSomeReactiveQueryResult = Apollo.QueryResult<ShouldReturnSomeReactiveQuery, ShouldReturnSomeReactiveQueryVariables>;
export const SendToMessageDocument = gql`
    mutation sendToMessage($message: String!) {
  sendToMessage(message: $message) {
    ...TalkUpdateAll
  }
}
    ${TalkUpdateAllFragmentDoc}`;
export type SendToMessageMutationFn = Apollo.MutationFunction<SendToMessageMutation, SendToMessageMutationVariables>;

/**
 * __useSendToMessageMutation__
 *
 * To run a mutation, you first call `useSendToMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendToMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendToMessageMutation, { data, loading, error }] = useSendToMessageMutation({
 *   variables: {
 *      message: // value for 'message'
 *   },
 * });
 */
export function useSendToMessageMutation(baseOptions?: Apollo.MutationHookOptions<SendToMessageMutation, SendToMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendToMessageMutation, SendToMessageMutationVariables>(SendToMessageDocument, options);
      }
export type SendToMessageMutationHookResult = ReturnType<typeof useSendToMessageMutation>;
export type SendToMessageMutationResult = Apollo.MutationResult<SendToMessageMutation>;
export type SendToMessageMutationOptions = Apollo.BaseMutationOptions<SendToMessageMutation, SendToMessageMutationVariables>;
export const RestartMessageReactiveDocument = gql`
    mutation restartMessageReactive {
  restartMessageReactive {
    ...TalkUpdateAll
  }
}
    ${TalkUpdateAllFragmentDoc}`;
export type RestartMessageReactiveMutationFn = Apollo.MutationFunction<RestartMessageReactiveMutation, RestartMessageReactiveMutationVariables>;

/**
 * __useRestartMessageReactiveMutation__
 *
 * To run a mutation, you first call `useRestartMessageReactiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestartMessageReactiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restartMessageReactiveMutation, { data, loading, error }] = useRestartMessageReactiveMutation({
 *   variables: {
 *   },
 * });
 */
export function useRestartMessageReactiveMutation(baseOptions?: Apollo.MutationHookOptions<RestartMessageReactiveMutation, RestartMessageReactiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestartMessageReactiveMutation, RestartMessageReactiveMutationVariables>(RestartMessageReactiveDocument, options);
      }
export type RestartMessageReactiveMutationHookResult = ReturnType<typeof useRestartMessageReactiveMutation>;
export type RestartMessageReactiveMutationResult = Apollo.MutationResult<RestartMessageReactiveMutation>;
export type RestartMessageReactiveMutationOptions = Apollo.BaseMutationOptions<RestartMessageReactiveMutation, RestartMessageReactiveMutationVariables>;
export const AnswerReactiveDocument = gql`
    mutation answerReactive($talkID: Int!, $answer: AnswerInput!) {
  answerReactive(talkID: $talkID, answer: $answer) {
    talkUpdate {
      ...TalkUpdateAll
    }
    userExperienceEvent {
      eventName
      point
    }
    userExperience {
      userLevel
      expAfterLastLevelUp
      expToNextLevelUp
    }
  }
}
    ${TalkUpdateAllFragmentDoc}`;
export type AnswerReactiveMutationFn = Apollo.MutationFunction<AnswerReactiveMutation, AnswerReactiveMutationVariables>;

/**
 * __useAnswerReactiveMutation__
 *
 * To run a mutation, you first call `useAnswerReactiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnswerReactiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [answerReactiveMutation, { data, loading, error }] = useAnswerReactiveMutation({
 *   variables: {
 *      talkID: // value for 'talkID'
 *      answer: // value for 'answer'
 *   },
 * });
 */
export function useAnswerReactiveMutation(baseOptions?: Apollo.MutationHookOptions<AnswerReactiveMutation, AnswerReactiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AnswerReactiveMutation, AnswerReactiveMutationVariables>(AnswerReactiveDocument, options);
      }
export type AnswerReactiveMutationHookResult = ReturnType<typeof useAnswerReactiveMutation>;
export type AnswerReactiveMutationResult = Apollo.MutationResult<AnswerReactiveMutation>;
export type AnswerReactiveMutationOptions = Apollo.BaseMutationOptions<AnswerReactiveMutation, AnswerReactiveMutationVariables>;
export const GetRecentReadlogDocument = gql`
    query GetRecentReadlog($begin: Int, $end: Int) {
  me {
    readLog {
      get(begin: $begin, end: $end, order: CREATED_AT_DESC) {
        ...ReadLogOnTalk
      }
    }
  }
}
    ${ReadLogOnTalkFragmentDoc}`;

/**
 * __useGetRecentReadlogQuery__
 *
 * To run a query within a React component, call `useGetRecentReadlogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentReadlogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentReadlogQuery({
 *   variables: {
 *      begin: // value for 'begin'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useGetRecentReadlogQuery(baseOptions?: Apollo.QueryHookOptions<GetRecentReadlogQuery, GetRecentReadlogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecentReadlogQuery, GetRecentReadlogQueryVariables>(GetRecentReadlogDocument, options);
      }
export function useGetRecentReadlogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecentReadlogQuery, GetRecentReadlogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecentReadlogQuery, GetRecentReadlogQueryVariables>(GetRecentReadlogDocument, options);
        }
export type GetRecentReadlogQueryHookResult = ReturnType<typeof useGetRecentReadlogQuery>;
export type GetRecentReadlogLazyQueryHookResult = ReturnType<typeof useGetRecentReadlogLazyQuery>;
export type GetRecentReadlogQueryResult = Apollo.QueryResult<GetRecentReadlogQuery, GetRecentReadlogQueryVariables>;
export const GetUnreadExistDocument = gql`
    query GetUnreadExist {
  me {
    haveUnreadInMessage
    haveUnreadInBfGroupMessage
    general {
      nickname
    }
  }
}
    `;

/**
 * __useGetUnreadExistQuery__
 *
 * To run a query within a React component, call `useGetUnreadExistQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnreadExistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnreadExistQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUnreadExistQuery(baseOptions?: Apollo.QueryHookOptions<GetUnreadExistQuery, GetUnreadExistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnreadExistQuery, GetUnreadExistQueryVariables>(GetUnreadExistDocument, options);
      }
export function useGetUnreadExistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnreadExistQuery, GetUnreadExistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnreadExistQuery, GetUnreadExistQueryVariables>(GetUnreadExistDocument, options);
        }
export type GetUnreadExistQueryHookResult = ReturnType<typeof useGetUnreadExistQuery>;
export type GetUnreadExistLazyQueryHookResult = ReturnType<typeof useGetUnreadExistLazyQuery>;
export type GetUnreadExistQueryResult = Apollo.QueryResult<GetUnreadExistQuery, GetUnreadExistQueryVariables>;
export const GetReadcharForGraphDocument = gql`
    query GetReadcharForGraph($recommendTerm: RecommendTerm!, $end: Int!) {
  me {
    recommendTerm(term: $recommendTerm) {
      get(end: $end, order: FINISHTIME) {
        whenDone
        book {
          title
          wordCount
        }
      }
    }
    general {
      readCharSum(timeRange: ALL)
    }
    createdAt
  }
}
    `;

/**
 * __useGetReadcharForGraphQuery__
 *
 * To run a query within a React component, call `useGetReadcharForGraphQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReadcharForGraphQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReadcharForGraphQuery({
 *   variables: {
 *      recommendTerm: // value for 'recommendTerm'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useGetReadcharForGraphQuery(baseOptions: Apollo.QueryHookOptions<GetReadcharForGraphQuery, GetReadcharForGraphQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReadcharForGraphQuery, GetReadcharForGraphQueryVariables>(GetReadcharForGraphDocument, options);
      }
export function useGetReadcharForGraphLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReadcharForGraphQuery, GetReadcharForGraphQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReadcharForGraphQuery, GetReadcharForGraphQueryVariables>(GetReadcharForGraphDocument, options);
        }
export type GetReadcharForGraphQueryHookResult = ReturnType<typeof useGetReadcharForGraphQuery>;
export type GetReadcharForGraphLazyQueryHookResult = ReturnType<typeof useGetReadcharForGraphLazyQuery>;
export type GetReadcharForGraphQueryResult = Apollo.QueryResult<GetReadcharForGraphQuery, GetReadcharForGraphQueryVariables>;
export const GetMonthlyHabitCalendarDocument = gql`
    query getMonthlyHabitCalendar($year: Int!, $month: Int!) {
  getMonthlyHabitCalendar(year: $year, month: $month) {
    day
    miniLesson
    postReadLog
  }
}
    `;

/**
 * __useGetMonthlyHabitCalendarQuery__
 *
 * To run a query within a React component, call `useGetMonthlyHabitCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMonthlyHabitCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMonthlyHabitCalendarQuery({
 *   variables: {
 *      year: // value for 'year'
 *      month: // value for 'month'
 *   },
 * });
 */
export function useGetMonthlyHabitCalendarQuery(baseOptions: Apollo.QueryHookOptions<GetMonthlyHabitCalendarQuery, GetMonthlyHabitCalendarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMonthlyHabitCalendarQuery, GetMonthlyHabitCalendarQueryVariables>(GetMonthlyHabitCalendarDocument, options);
      }
export function useGetMonthlyHabitCalendarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMonthlyHabitCalendarQuery, GetMonthlyHabitCalendarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMonthlyHabitCalendarQuery, GetMonthlyHabitCalendarQueryVariables>(GetMonthlyHabitCalendarDocument, options);
        }
export type GetMonthlyHabitCalendarQueryHookResult = ReturnType<typeof useGetMonthlyHabitCalendarQuery>;
export type GetMonthlyHabitCalendarLazyQueryHookResult = ReturnType<typeof useGetMonthlyHabitCalendarLazyQuery>;
export type GetMonthlyHabitCalendarQueryResult = Apollo.QueryResult<GetMonthlyHabitCalendarQuery, GetMonthlyHabitCalendarQueryVariables>;
export const GetReviewRecordsDocument = gql`
    query GetReviewRecords($readLogTerm: ReadLogTerm!, $end: Int!, $startTime: Time!) {
  me {
    readLogTerm(createdAtFrom: $startTime, toTerm: $readLogTerm) {
      get(end: $end, order: CREATED_AT_ASC) {
        when
        book {
          title
        }
      }
    }
  }
}
    `;

/**
 * __useGetReviewRecordsQuery__
 *
 * To run a query within a React component, call `useGetReviewRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReviewRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReviewRecordsQuery({
 *   variables: {
 *      readLogTerm: // value for 'readLogTerm'
 *      end: // value for 'end'
 *      startTime: // value for 'startTime'
 *   },
 * });
 */
export function useGetReviewRecordsQuery(baseOptions: Apollo.QueryHookOptions<GetReviewRecordsQuery, GetReviewRecordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReviewRecordsQuery, GetReviewRecordsQueryVariables>(GetReviewRecordsDocument, options);
      }
export function useGetReviewRecordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReviewRecordsQuery, GetReviewRecordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReviewRecordsQuery, GetReviewRecordsQueryVariables>(GetReviewRecordsDocument, options);
        }
export type GetReviewRecordsQueryHookResult = ReturnType<typeof useGetReviewRecordsQuery>;
export type GetReviewRecordsLazyQueryHookResult = ReturnType<typeof useGetReviewRecordsLazyQuery>;
export type GetReviewRecordsQueryResult = Apollo.QueryResult<GetReviewRecordsQuery, GetReviewRecordsQueryVariables>;
export const AuthSessionPresenceDocument = gql`
    query AuthSessionPresence {
  sessionPresence
}
    `;

/**
 * __useAuthSessionPresenceQuery__
 *
 * To run a query within a React component, call `useAuthSessionPresenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthSessionPresenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthSessionPresenceQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthSessionPresenceQuery(baseOptions?: Apollo.QueryHookOptions<AuthSessionPresenceQuery, AuthSessionPresenceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AuthSessionPresenceQuery, AuthSessionPresenceQueryVariables>(AuthSessionPresenceDocument, options);
      }
export function useAuthSessionPresenceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuthSessionPresenceQuery, AuthSessionPresenceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AuthSessionPresenceQuery, AuthSessionPresenceQueryVariables>(AuthSessionPresenceDocument, options);
        }
export type AuthSessionPresenceQueryHookResult = ReturnType<typeof useAuthSessionPresenceQuery>;
export type AuthSessionPresenceLazyQueryHookResult = ReturnType<typeof useAuthSessionPresenceLazyQuery>;
export type AuthSessionPresenceQueryResult = Apollo.QueryResult<AuthSessionPresenceQuery, AuthSessionPresenceQueryVariables>;
export const CreateAuthSessionDocument = gql`
    mutation CreateAuthSession($token: String!) {
  createAuthSession(token: $token) {
    state
  }
}
    `;
export type CreateAuthSessionMutationFn = Apollo.MutationFunction<CreateAuthSessionMutation, CreateAuthSessionMutationVariables>;

/**
 * __useCreateAuthSessionMutation__
 *
 * To run a mutation, you first call `useCreateAuthSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAuthSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAuthSessionMutation, { data, loading, error }] = useCreateAuthSessionMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useCreateAuthSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateAuthSessionMutation, CreateAuthSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAuthSessionMutation, CreateAuthSessionMutationVariables>(CreateAuthSessionDocument, options);
      }
export type CreateAuthSessionMutationHookResult = ReturnType<typeof useCreateAuthSessionMutation>;
export type CreateAuthSessionMutationResult = Apollo.MutationResult<CreateAuthSessionMutation>;
export type CreateAuthSessionMutationOptions = Apollo.BaseMutationOptions<CreateAuthSessionMutation, CreateAuthSessionMutationVariables>;
export const GetCurrentUserDocument = gql`
    query getCurrentUser {
  me {
    ...CurrentUser
  }
  isDoneFirstMiniLesson
}
    ${CurrentUserFragmentDoc}`;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
      }
export function useGetCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
        }
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export const GetUserAvailabilitiesDocument = gql`
    query getUserAvailabilities {
  getUsers {
    userID
    userPlan
    userIconType
    nickname
  }
}
    `;

/**
 * __useGetUserAvailabilitiesQuery__
 *
 * To run a query within a React component, call `useGetUserAvailabilitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAvailabilitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAvailabilitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserAvailabilitiesQuery(baseOptions?: Apollo.QueryHookOptions<GetUserAvailabilitiesQuery, GetUserAvailabilitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserAvailabilitiesQuery, GetUserAvailabilitiesQueryVariables>(GetUserAvailabilitiesDocument, options);
      }
export function useGetUserAvailabilitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserAvailabilitiesQuery, GetUserAvailabilitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserAvailabilitiesQuery, GetUserAvailabilitiesQueryVariables>(GetUserAvailabilitiesDocument, options);
        }
export type GetUserAvailabilitiesQueryHookResult = ReturnType<typeof useGetUserAvailabilitiesQuery>;
export type GetUserAvailabilitiesLazyQueryHookResult = ReturnType<typeof useGetUserAvailabilitiesLazyQuery>;
export type GetUserAvailabilitiesQueryResult = Apollo.QueryResult<GetUserAvailabilitiesQuery, GetUserAvailabilitiesQueryVariables>;
export const AddBfReadLogReactionDocument = gql`
    mutation addBfReadLogReaction($readLogId: Int!, $reactionType: ReadLogReactionType!) {
  addBfReadLogReaction(readLogId: $readLogId, reactionType: $reactionType)
}
    `;
export type AddBfReadLogReactionMutationFn = Apollo.MutationFunction<AddBfReadLogReactionMutation, AddBfReadLogReactionMutationVariables>;

/**
 * __useAddBfReadLogReactionMutation__
 *
 * To run a mutation, you first call `useAddBfReadLogReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBfReadLogReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBfReadLogReactionMutation, { data, loading, error }] = useAddBfReadLogReactionMutation({
 *   variables: {
 *      readLogId: // value for 'readLogId'
 *      reactionType: // value for 'reactionType'
 *   },
 * });
 */
export function useAddBfReadLogReactionMutation(baseOptions?: Apollo.MutationHookOptions<AddBfReadLogReactionMutation, AddBfReadLogReactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddBfReadLogReactionMutation, AddBfReadLogReactionMutationVariables>(AddBfReadLogReactionDocument, options);
      }
export type AddBfReadLogReactionMutationHookResult = ReturnType<typeof useAddBfReadLogReactionMutation>;
export type AddBfReadLogReactionMutationResult = Apollo.MutationResult<AddBfReadLogReactionMutation>;
export type AddBfReadLogReactionMutationOptions = Apollo.BaseMutationOptions<AddBfReadLogReactionMutation, AddBfReadLogReactionMutationVariables>;
export const AddBfReadLogStarDocument = gql`
    mutation addBfReadLogStar($readLogID: Int!, $readingTechniqueType: ReadingTechniqueType!) {
  addBfReadLogStar(
    readLogID: $readLogID
    readingTechniqueType: $readingTechniqueType
  ) {
    state
  }
}
    `;
export type AddBfReadLogStarMutationFn = Apollo.MutationFunction<AddBfReadLogStarMutation, AddBfReadLogStarMutationVariables>;

/**
 * __useAddBfReadLogStarMutation__
 *
 * To run a mutation, you first call `useAddBfReadLogStarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBfReadLogStarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBfReadLogStarMutation, { data, loading, error }] = useAddBfReadLogStarMutation({
 *   variables: {
 *      readLogID: // value for 'readLogID'
 *      readingTechniqueType: // value for 'readingTechniqueType'
 *   },
 * });
 */
export function useAddBfReadLogStarMutation(baseOptions?: Apollo.MutationHookOptions<AddBfReadLogStarMutation, AddBfReadLogStarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddBfReadLogStarMutation, AddBfReadLogStarMutationVariables>(AddBfReadLogStarDocument, options);
      }
export type AddBfReadLogStarMutationHookResult = ReturnType<typeof useAddBfReadLogStarMutation>;
export type AddBfReadLogStarMutationResult = Apollo.MutationResult<AddBfReadLogStarMutation>;
export type AddBfReadLogStarMutationOptions = Apollo.BaseMutationOptions<AddBfReadLogStarMutation, AddBfReadLogStarMutationVariables>;
export const GetWantReadBooksDocument = gql`
    query getWantReadBooks($begin: Int!, $end: Int!) {
  getWantReadBooks {
    count
    get(begin: $begin, end: $end) {
      id
      userID
      recommendStatus
      createdAt
      readRecommendID
      book {
        isbn
        yl
        publisher
        pageCount
        author
        painter
        wordCount
        title
        published
        synopsis
        imageUrl
        impression
        affiliateLink
      }
    }
  }
}
    `;

/**
 * __useGetWantReadBooksQuery__
 *
 * To run a query within a React component, call `useGetWantReadBooksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWantReadBooksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWantReadBooksQuery({
 *   variables: {
 *      begin: // value for 'begin'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useGetWantReadBooksQuery(baseOptions: Apollo.QueryHookOptions<GetWantReadBooksQuery, GetWantReadBooksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWantReadBooksQuery, GetWantReadBooksQueryVariables>(GetWantReadBooksDocument, options);
      }
export function useGetWantReadBooksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWantReadBooksQuery, GetWantReadBooksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWantReadBooksQuery, GetWantReadBooksQueryVariables>(GetWantReadBooksDocument, options);
        }
export type GetWantReadBooksQueryHookResult = ReturnType<typeof useGetWantReadBooksQuery>;
export type GetWantReadBooksLazyQueryHookResult = ReturnType<typeof useGetWantReadBooksLazyQuery>;
export type GetWantReadBooksQueryResult = Apollo.QueryResult<GetWantReadBooksQuery, GetWantReadBooksQueryVariables>;
export const AddWantReadBookDocument = gql`
    mutation addWantReadBook($readLogId: Int!) {
  addWantReadBook(input: {inReadLogID: $readLogId}) {
    state
  }
}
    `;
export type AddWantReadBookMutationFn = Apollo.MutationFunction<AddWantReadBookMutation, AddWantReadBookMutationVariables>;

/**
 * __useAddWantReadBookMutation__
 *
 * To run a mutation, you first call `useAddWantReadBookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWantReadBookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWantReadBookMutation, { data, loading, error }] = useAddWantReadBookMutation({
 *   variables: {
 *      readLogId: // value for 'readLogId'
 *   },
 * });
 */
export function useAddWantReadBookMutation(baseOptions?: Apollo.MutationHookOptions<AddWantReadBookMutation, AddWantReadBookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddWantReadBookMutation, AddWantReadBookMutationVariables>(AddWantReadBookDocument, options);
      }
export type AddWantReadBookMutationHookResult = ReturnType<typeof useAddWantReadBookMutation>;
export type AddWantReadBookMutationResult = Apollo.MutationResult<AddWantReadBookMutation>;
export type AddWantReadBookMutationOptions = Apollo.BaseMutationOptions<AddWantReadBookMutation, AddWantReadBookMutationVariables>;
export const GetAvailableStarEnergyIdDocument = gql`
    query getAvailableStarEnergyID {
  getAvailableStarEnergyID
}
    `;

/**
 * __useGetAvailableStarEnergyIdQuery__
 *
 * To run a query within a React component, call `useGetAvailableStarEnergyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableStarEnergyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableStarEnergyIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAvailableStarEnergyIdQuery(baseOptions?: Apollo.QueryHookOptions<GetAvailableStarEnergyIdQuery, GetAvailableStarEnergyIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAvailableStarEnergyIdQuery, GetAvailableStarEnergyIdQueryVariables>(GetAvailableStarEnergyIdDocument, options);
      }
export function useGetAvailableStarEnergyIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAvailableStarEnergyIdQuery, GetAvailableStarEnergyIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAvailableStarEnergyIdQuery, GetAvailableStarEnergyIdQueryVariables>(GetAvailableStarEnergyIdDocument, options);
        }
export type GetAvailableStarEnergyIdQueryHookResult = ReturnType<typeof useGetAvailableStarEnergyIdQuery>;
export type GetAvailableStarEnergyIdLazyQueryHookResult = ReturnType<typeof useGetAvailableStarEnergyIdLazyQuery>;
export type GetAvailableStarEnergyIdQueryResult = Apollo.QueryResult<GetAvailableStarEnergyIdQuery, GetAvailableStarEnergyIdQueryVariables>;
export const GetBfReadLogsDocument = gql`
    query getBfReadLogs($limit: Int!, $exclusiveStartId: String, $direction: SearchDirection!) {
  getBfReadLogs(
    limit: $limit
    exclusiveStartId: $exclusiveStartId
    direction: $direction
  ) {
    ...BfReadLog
  }
}
    ${BfReadLogFragmentDoc}`;

/**
 * __useGetBfReadLogsQuery__
 *
 * To run a query within a React component, call `useGetBfReadLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBfReadLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBfReadLogsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      exclusiveStartId: // value for 'exclusiveStartId'
 *      direction: // value for 'direction'
 *   },
 * });
 */
export function useGetBfReadLogsQuery(baseOptions: Apollo.QueryHookOptions<GetBfReadLogsQuery, GetBfReadLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBfReadLogsQuery, GetBfReadLogsQueryVariables>(GetBfReadLogsDocument, options);
      }
export function useGetBfReadLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBfReadLogsQuery, GetBfReadLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBfReadLogsQuery, GetBfReadLogsQueryVariables>(GetBfReadLogsDocument, options);
        }
export type GetBfReadLogsQueryHookResult = ReturnType<typeof useGetBfReadLogsQuery>;
export type GetBfReadLogsLazyQueryHookResult = ReturnType<typeof useGetBfReadLogsLazyQuery>;
export type GetBfReadLogsQueryResult = Apollo.QueryResult<GetBfReadLogsQuery, GetBfReadLogsQueryVariables>;
export const UpsertLatestBfReadLogViewTimeDocument = gql`
    mutation upsertLatestBfReadLogViewTime {
  upsertLatestBfReadLogViewTime {
    state
  }
}
    `;
export type UpsertLatestBfReadLogViewTimeMutationFn = Apollo.MutationFunction<UpsertLatestBfReadLogViewTimeMutation, UpsertLatestBfReadLogViewTimeMutationVariables>;

/**
 * __useUpsertLatestBfReadLogViewTimeMutation__
 *
 * To run a mutation, you first call `useUpsertLatestBfReadLogViewTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertLatestBfReadLogViewTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertLatestBfReadLogViewTimeMutation, { data, loading, error }] = useUpsertLatestBfReadLogViewTimeMutation({
 *   variables: {
 *   },
 * });
 */
export function useUpsertLatestBfReadLogViewTimeMutation(baseOptions?: Apollo.MutationHookOptions<UpsertLatestBfReadLogViewTimeMutation, UpsertLatestBfReadLogViewTimeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertLatestBfReadLogViewTimeMutation, UpsertLatestBfReadLogViewTimeMutationVariables>(UpsertLatestBfReadLogViewTimeDocument, options);
      }
export type UpsertLatestBfReadLogViewTimeMutationHookResult = ReturnType<typeof useUpsertLatestBfReadLogViewTimeMutation>;
export type UpsertLatestBfReadLogViewTimeMutationResult = Apollo.MutationResult<UpsertLatestBfReadLogViewTimeMutation>;
export type UpsertLatestBfReadLogViewTimeMutationOptions = Apollo.BaseMutationOptions<UpsertLatestBfReadLogViewTimeMutation, UpsertLatestBfReadLogViewTimeMutationVariables>;
export const GetLatestBfReadLogViewTimeDocument = gql`
    query getLatestBfReadLogViewTime {
  getLatestBfReadLogViewTime
}
    `;

/**
 * __useGetLatestBfReadLogViewTimeQuery__
 *
 * To run a query within a React component, call `useGetLatestBfReadLogViewTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestBfReadLogViewTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestBfReadLogViewTimeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLatestBfReadLogViewTimeQuery(baseOptions?: Apollo.QueryHookOptions<GetLatestBfReadLogViewTimeQuery, GetLatestBfReadLogViewTimeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLatestBfReadLogViewTimeQuery, GetLatestBfReadLogViewTimeQueryVariables>(GetLatestBfReadLogViewTimeDocument, options);
      }
export function useGetLatestBfReadLogViewTimeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLatestBfReadLogViewTimeQuery, GetLatestBfReadLogViewTimeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLatestBfReadLogViewTimeQuery, GetLatestBfReadLogViewTimeQueryVariables>(GetLatestBfReadLogViewTimeDocument, options);
        }
export type GetLatestBfReadLogViewTimeQueryHookResult = ReturnType<typeof useGetLatestBfReadLogViewTimeQuery>;
export type GetLatestBfReadLogViewTimeLazyQueryHookResult = ReturnType<typeof useGetLatestBfReadLogViewTimeLazyQuery>;
export type GetLatestBfReadLogViewTimeQueryResult = Apollo.QueryResult<GetLatestBfReadLogViewTimeQuery, GetLatestBfReadLogViewTimeQueryVariables>;
export const GetCurrentRecommendNumberDocument = gql`
    query getCurrentRecommendNumber {
  me {
    recommends(done: false, self: false) {
      count
    }
  }
}
    `;

/**
 * __useGetCurrentRecommendNumberQuery__
 *
 * To run a query within a React component, call `useGetCurrentRecommendNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentRecommendNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentRecommendNumberQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentRecommendNumberQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentRecommendNumberQuery, GetCurrentRecommendNumberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentRecommendNumberQuery, GetCurrentRecommendNumberQueryVariables>(GetCurrentRecommendNumberDocument, options);
      }
export function useGetCurrentRecommendNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentRecommendNumberQuery, GetCurrentRecommendNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentRecommendNumberQuery, GetCurrentRecommendNumberQueryVariables>(GetCurrentRecommendNumberDocument, options);
        }
export type GetCurrentRecommendNumberQueryHookResult = ReturnType<typeof useGetCurrentRecommendNumberQuery>;
export type GetCurrentRecommendNumberLazyQueryHookResult = ReturnType<typeof useGetCurrentRecommendNumberLazyQuery>;
export type GetCurrentRecommendNumberQueryResult = Apollo.QueryResult<GetCurrentRecommendNumberQuery, GetCurrentRecommendNumberQueryVariables>;
export const GetMissionProgressDocument = gql`
    query getMissionProgress {
  getMissionProgress {
    missionOrder
    userMissionTasks {
      missionTaskType
      current
      goal
    }
    missionState
  }
}
    `;

/**
 * __useGetMissionProgressQuery__
 *
 * To run a query within a React component, call `useGetMissionProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMissionProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMissionProgressQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMissionProgressQuery(baseOptions?: Apollo.QueryHookOptions<GetMissionProgressQuery, GetMissionProgressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMissionProgressQuery, GetMissionProgressQueryVariables>(GetMissionProgressDocument, options);
      }
export function useGetMissionProgressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMissionProgressQuery, GetMissionProgressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMissionProgressQuery, GetMissionProgressQueryVariables>(GetMissionProgressDocument, options);
        }
export type GetMissionProgressQueryHookResult = ReturnType<typeof useGetMissionProgressQuery>;
export type GetMissionProgressLazyQueryHookResult = ReturnType<typeof useGetMissionProgressLazyQuery>;
export type GetMissionProgressQueryResult = Apollo.QueryResult<GetMissionProgressQuery, GetMissionProgressQueryVariables>;
export const StartMissionDocument = gql`
    mutation startMission {
  startMission {
    missionOrder
    userMissionTasks {
      missionTaskType
      current
      goal
    }
    missionState
  }
}
    `;
export type StartMissionMutationFn = Apollo.MutationFunction<StartMissionMutation, StartMissionMutationVariables>;

/**
 * __useStartMissionMutation__
 *
 * To run a mutation, you first call `useStartMissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartMissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startMissionMutation, { data, loading, error }] = useStartMissionMutation({
 *   variables: {
 *   },
 * });
 */
export function useStartMissionMutation(baseOptions?: Apollo.MutationHookOptions<StartMissionMutation, StartMissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartMissionMutation, StartMissionMutationVariables>(StartMissionDocument, options);
      }
export type StartMissionMutationHookResult = ReturnType<typeof useStartMissionMutation>;
export type StartMissionMutationResult = Apollo.MutationResult<StartMissionMutation>;
export type StartMissionMutationOptions = Apollo.BaseMutationOptions<StartMissionMutation, StartMissionMutationVariables>;
export const CompleteMissionDocument = gql`
    mutation completeMission {
  completeMission
}
    `;
export type CompleteMissionMutationFn = Apollo.MutationFunction<CompleteMissionMutation, CompleteMissionMutationVariables>;

/**
 * __useCompleteMissionMutation__
 *
 * To run a mutation, you first call `useCompleteMissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteMissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeMissionMutation, { data, loading, error }] = useCompleteMissionMutation({
 *   variables: {
 *   },
 * });
 */
export function useCompleteMissionMutation(baseOptions?: Apollo.MutationHookOptions<CompleteMissionMutation, CompleteMissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteMissionMutation, CompleteMissionMutationVariables>(CompleteMissionDocument, options);
      }
export type CompleteMissionMutationHookResult = ReturnType<typeof useCompleteMissionMutation>;
export type CompleteMissionMutationResult = Apollo.MutationResult<CompleteMissionMutation>;
export type CompleteMissionMutationOptions = Apollo.BaseMutationOptions<CompleteMissionMutation, CompleteMissionMutationVariables>;
export const AnswerCharacterEnqueteDocument = gql`
    mutation AnswerCharacterEnquete($input: AnswerCharacterEnqueteInput!) {
  answerCharacterEnquete(input: $input) {
    ... on OK {
      state
    }
  }
}
    `;
export type AnswerCharacterEnqueteMutationFn = Apollo.MutationFunction<AnswerCharacterEnqueteMutation, AnswerCharacterEnqueteMutationVariables>;

/**
 * __useAnswerCharacterEnqueteMutation__
 *
 * To run a mutation, you first call `useAnswerCharacterEnqueteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnswerCharacterEnqueteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [answerCharacterEnqueteMutation, { data, loading, error }] = useAnswerCharacterEnqueteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAnswerCharacterEnqueteMutation(baseOptions?: Apollo.MutationHookOptions<AnswerCharacterEnqueteMutation, AnswerCharacterEnqueteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AnswerCharacterEnqueteMutation, AnswerCharacterEnqueteMutationVariables>(AnswerCharacterEnqueteDocument, options);
      }
export type AnswerCharacterEnqueteMutationHookResult = ReturnType<typeof useAnswerCharacterEnqueteMutation>;
export type AnswerCharacterEnqueteMutationResult = Apollo.MutationResult<AnswerCharacterEnqueteMutation>;
export type AnswerCharacterEnqueteMutationOptions = Apollo.BaseMutationOptions<AnswerCharacterEnqueteMutation, AnswerCharacterEnqueteMutationVariables>;
export const GetSuitableYlResultDocument = gql`
    query GetSuitableYLResult {
  getSuitableYL {
    ... on SuitableYL {
      value
    }
    ... on ErrorSuitableYLNotFound {
      message
    }
  }
}
    `;

/**
 * __useGetSuitableYlResultQuery__
 *
 * To run a query within a React component, call `useGetSuitableYlResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSuitableYlResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSuitableYlResultQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSuitableYlResultQuery(baseOptions?: Apollo.QueryHookOptions<GetSuitableYlResultQuery, GetSuitableYlResultQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSuitableYlResultQuery, GetSuitableYlResultQueryVariables>(GetSuitableYlResultDocument, options);
      }
export function useGetSuitableYlResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSuitableYlResultQuery, GetSuitableYlResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSuitableYlResultQuery, GetSuitableYlResultQueryVariables>(GetSuitableYlResultDocument, options);
        }
export type GetSuitableYlResultQueryHookResult = ReturnType<typeof useGetSuitableYlResultQuery>;
export type GetSuitableYlResultLazyQueryHookResult = ReturnType<typeof useGetSuitableYlResultLazyQuery>;
export type GetSuitableYlResultQueryResult = Apollo.QueryResult<GetSuitableYlResultQuery, GetSuitableYlResultQueryVariables>;
export const GetMyKanziLevelDocument = gql`
    query GetMyKanziLevel {
  me {
    kanziLevel
  }
}
    `;

/**
 * __useGetMyKanziLevelQuery__
 *
 * To run a query within a React component, call `useGetMyKanziLevelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyKanziLevelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyKanziLevelQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyKanziLevelQuery(baseOptions?: Apollo.QueryHookOptions<GetMyKanziLevelQuery, GetMyKanziLevelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyKanziLevelQuery, GetMyKanziLevelQueryVariables>(GetMyKanziLevelDocument, options);
      }
export function useGetMyKanziLevelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyKanziLevelQuery, GetMyKanziLevelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyKanziLevelQuery, GetMyKanziLevelQueryVariables>(GetMyKanziLevelDocument, options);
        }
export type GetMyKanziLevelQueryHookResult = ReturnType<typeof useGetMyKanziLevelQuery>;
export type GetMyKanziLevelLazyQueryHookResult = ReturnType<typeof useGetMyKanziLevelLazyQuery>;
export type GetMyKanziLevelQueryResult = Apollo.QueryResult<GetMyKanziLevelQuery, GetMyKanziLevelQueryVariables>;
export const UpdateKanziLevelDocument = gql`
    mutation UpdateKanziLevel($accountInfo: UpdateAccountInput!) {
  updateAccount(accountInfo: $accountInfo) {
    kanziLevel
  }
}
    `;
export type UpdateKanziLevelMutationFn = Apollo.MutationFunction<UpdateKanziLevelMutation, UpdateKanziLevelMutationVariables>;

/**
 * __useUpdateKanziLevelMutation__
 *
 * To run a mutation, you first call `useUpdateKanziLevelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKanziLevelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKanziLevelMutation, { data, loading, error }] = useUpdateKanziLevelMutation({
 *   variables: {
 *      accountInfo: // value for 'accountInfo'
 *   },
 * });
 */
export function useUpdateKanziLevelMutation(baseOptions?: Apollo.MutationHookOptions<UpdateKanziLevelMutation, UpdateKanziLevelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateKanziLevelMutation, UpdateKanziLevelMutationVariables>(UpdateKanziLevelDocument, options);
      }
export type UpdateKanziLevelMutationHookResult = ReturnType<typeof useUpdateKanziLevelMutation>;
export type UpdateKanziLevelMutationResult = Apollo.MutationResult<UpdateKanziLevelMutation>;
export type UpdateKanziLevelMutationOptions = Apollo.BaseMutationOptions<UpdateKanziLevelMutation, UpdateKanziLevelMutationVariables>;
export const GetMyProfileDocument = gql`
    query GetMyProfile {
  me {
    kanziLevel
    recommendFrequency
    general {
      nickname
      userIconType
      id
    }
  }
}
    `;

/**
 * __useGetMyProfileQuery__
 *
 * To run a query within a React component, call `useGetMyProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyProfileQuery(baseOptions?: Apollo.QueryHookOptions<GetMyProfileQuery, GetMyProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyProfileQuery, GetMyProfileQueryVariables>(GetMyProfileDocument, options);
      }
export function useGetMyProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyProfileQuery, GetMyProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyProfileQuery, GetMyProfileQueryVariables>(GetMyProfileDocument, options);
        }
export type GetMyProfileQueryHookResult = ReturnType<typeof useGetMyProfileQuery>;
export type GetMyProfileLazyQueryHookResult = ReturnType<typeof useGetMyProfileLazyQuery>;
export type GetMyProfileQueryResult = Apollo.QueryResult<GetMyProfileQuery, GetMyProfileQueryVariables>;
export const UpdateProfileDocument = gql`
    mutation UpdateProfile($accountInfo: UpdateAccountInput!) {
  updateAccount(accountInfo: $accountInfo) {
    kanziLevel
    general {
      nickname
      userIconType
    }
  }
}
    `;
export type UpdateProfileMutationFn = Apollo.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      accountInfo: // value for 'accountInfo'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, options);
      }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<UpdateProfileMutation, UpdateProfileMutationVariables>;
export const GetOneReadlogDocument = gql`
    query GetOneReadlog($readlogID: Int!) {
  me {
    readLog(id: $readlogID) {
      get(order: CREATED_AT_ASC) {
        review
        favoriteScene
      }
    }
  }
}
    `;

/**
 * __useGetOneReadlogQuery__
 *
 * To run a query within a React component, call `useGetOneReadlogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneReadlogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneReadlogQuery({
 *   variables: {
 *      readlogID: // value for 'readlogID'
 *   },
 * });
 */
export function useGetOneReadlogQuery(baseOptions: Apollo.QueryHookOptions<GetOneReadlogQuery, GetOneReadlogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOneReadlogQuery, GetOneReadlogQueryVariables>(GetOneReadlogDocument, options);
      }
export function useGetOneReadlogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOneReadlogQuery, GetOneReadlogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOneReadlogQuery, GetOneReadlogQueryVariables>(GetOneReadlogDocument, options);
        }
export type GetOneReadlogQueryHookResult = ReturnType<typeof useGetOneReadlogQuery>;
export type GetOneReadlogLazyQueryHookResult = ReturnType<typeof useGetOneReadlogLazyQuery>;
export type GetOneReadlogQueryResult = Apollo.QueryResult<GetOneReadlogQuery, GetOneReadlogQueryVariables>;
export const UpdateOneReadlogDocument = gql`
    mutation UpdateOneReadlog($readLogUpdate: ReadLogUpdate!) {
  editReadLog(readLogUpdate: $readLogUpdate) {
    review
    favoriteScene
  }
}
    `;
export type UpdateOneReadlogMutationFn = Apollo.MutationFunction<UpdateOneReadlogMutation, UpdateOneReadlogMutationVariables>;

/**
 * __useUpdateOneReadlogMutation__
 *
 * To run a mutation, you first call `useUpdateOneReadlogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneReadlogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneReadlogMutation, { data, loading, error }] = useUpdateOneReadlogMutation({
 *   variables: {
 *      readLogUpdate: // value for 'readLogUpdate'
 *   },
 * });
 */
export function useUpdateOneReadlogMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneReadlogMutation, UpdateOneReadlogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneReadlogMutation, UpdateOneReadlogMutationVariables>(UpdateOneReadlogDocument, options);
      }
export type UpdateOneReadlogMutationHookResult = ReturnType<typeof useUpdateOneReadlogMutation>;
export type UpdateOneReadlogMutationResult = Apollo.MutationResult<UpdateOneReadlogMutation>;
export type UpdateOneReadlogMutationOptions = Apollo.BaseMutationOptions<UpdateOneReadlogMutation, UpdateOneReadlogMutationVariables>;
export const ChangeRecommendFrequencyDocument = gql`
    mutation ChangeRecommendFrequency($frequency: RecommendFrequency!) {
  changeRecommendFrequency(frequency: $frequency) {
    recommendFrequency
  }
}
    `;
export type ChangeRecommendFrequencyMutationFn = Apollo.MutationFunction<ChangeRecommendFrequencyMutation, ChangeRecommendFrequencyMutationVariables>;

/**
 * __useChangeRecommendFrequencyMutation__
 *
 * To run a mutation, you first call `useChangeRecommendFrequencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeRecommendFrequencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeRecommendFrequencyMutation, { data, loading, error }] = useChangeRecommendFrequencyMutation({
 *   variables: {
 *      frequency: // value for 'frequency'
 *   },
 * });
 */
export function useChangeRecommendFrequencyMutation(baseOptions?: Apollo.MutationHookOptions<ChangeRecommendFrequencyMutation, ChangeRecommendFrequencyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeRecommendFrequencyMutation, ChangeRecommendFrequencyMutationVariables>(ChangeRecommendFrequencyDocument, options);
      }
export type ChangeRecommendFrequencyMutationHookResult = ReturnType<typeof useChangeRecommendFrequencyMutation>;
export type ChangeRecommendFrequencyMutationResult = Apollo.MutationResult<ChangeRecommendFrequencyMutation>;
export type ChangeRecommendFrequencyMutationOptions = Apollo.BaseMutationOptions<ChangeRecommendFrequencyMutation, ChangeRecommendFrequencyMutationVariables>;
export const GetCurrentRecommendFrequencyDocument = gql`
    query GetCurrentRecommendFrequency {
  me {
    recommendFrequency
  }
}
    `;

/**
 * __useGetCurrentRecommendFrequencyQuery__
 *
 * To run a query within a React component, call `useGetCurrentRecommendFrequencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentRecommendFrequencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentRecommendFrequencyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentRecommendFrequencyQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentRecommendFrequencyQuery, GetCurrentRecommendFrequencyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentRecommendFrequencyQuery, GetCurrentRecommendFrequencyQueryVariables>(GetCurrentRecommendFrequencyDocument, options);
      }
export function useGetCurrentRecommendFrequencyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentRecommendFrequencyQuery, GetCurrentRecommendFrequencyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentRecommendFrequencyQuery, GetCurrentRecommendFrequencyQueryVariables>(GetCurrentRecommendFrequencyDocument, options);
        }
export type GetCurrentRecommendFrequencyQueryHookResult = ReturnType<typeof useGetCurrentRecommendFrequencyQuery>;
export type GetCurrentRecommendFrequencyLazyQueryHookResult = ReturnType<typeof useGetCurrentRecommendFrequencyLazyQuery>;
export type GetCurrentRecommendFrequencyQueryResult = Apollo.QueryResult<GetCurrentRecommendFrequencyQuery, GetCurrentRecommendFrequencyQueryVariables>;
export const GetFirstBookRecommendsDocument = gql`
    query getFirstBookRecommends($begin: Int!, $end: Int!) {
  getFirstBookRecommends {
    id
    whenDone
    whenRecommend
    recommendComment
    book {
      title
      imageUrl
      isbn
    }
    readLog(statusEnd: true, statusReading: true, statusQuit: true) {
      count
      get(begin: $begin, end: $end, order: CREATED_AT_ASC) {
        id
        status
      }
    }
  }
}
    `;

/**
 * __useGetFirstBookRecommendsQuery__
 *
 * To run a query within a React component, call `useGetFirstBookRecommendsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFirstBookRecommendsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFirstBookRecommendsQuery({
 *   variables: {
 *      begin: // value for 'begin'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useGetFirstBookRecommendsQuery(baseOptions: Apollo.QueryHookOptions<GetFirstBookRecommendsQuery, GetFirstBookRecommendsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFirstBookRecommendsQuery, GetFirstBookRecommendsQueryVariables>(GetFirstBookRecommendsDocument, options);
      }
export function useGetFirstBookRecommendsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFirstBookRecommendsQuery, GetFirstBookRecommendsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFirstBookRecommendsQuery, GetFirstBookRecommendsQueryVariables>(GetFirstBookRecommendsDocument, options);
        }
export type GetFirstBookRecommendsQueryHookResult = ReturnType<typeof useGetFirstBookRecommendsQuery>;
export type GetFirstBookRecommendsLazyQueryHookResult = ReturnType<typeof useGetFirstBookRecommendsLazyQuery>;
export type GetFirstBookRecommendsQueryResult = Apollo.QueryResult<GetFirstBookRecommendsQuery, GetFirstBookRecommendsQueryVariables>;
export const GetPostPreBookRecommendLogDocument = gql`
    query getPostPreBookRecommendLog {
  getPostPreBookRecommendLog {
    id
    preBookRecommends {
      id
      book {
        title
        imageUrl
        isbn
      }
    }
  }
}
    `;

/**
 * __useGetPostPreBookRecommendLogQuery__
 *
 * To run a query within a React component, call `useGetPostPreBookRecommendLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostPreBookRecommendLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostPreBookRecommendLogQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPostPreBookRecommendLogQuery(baseOptions?: Apollo.QueryHookOptions<GetPostPreBookRecommendLogQuery, GetPostPreBookRecommendLogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPostPreBookRecommendLogQuery, GetPostPreBookRecommendLogQueryVariables>(GetPostPreBookRecommendLogDocument, options);
      }
export function useGetPostPreBookRecommendLogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPostPreBookRecommendLogQuery, GetPostPreBookRecommendLogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPostPreBookRecommendLogQuery, GetPostPreBookRecommendLogQueryVariables>(GetPostPreBookRecommendLogDocument, options);
        }
export type GetPostPreBookRecommendLogQueryHookResult = ReturnType<typeof useGetPostPreBookRecommendLogQuery>;
export type GetPostPreBookRecommendLogLazyQueryHookResult = ReturnType<typeof useGetPostPreBookRecommendLogLazyQuery>;
export type GetPostPreBookRecommendLogQueryResult = Apollo.QueryResult<GetPostPreBookRecommendLogQuery, GetPostPreBookRecommendLogQueryVariables>;
export const AnswerPreBookRecommendEnqueteDocument = gql`
    mutation answerPreBookRecommendEnquete($input: AnswerPreBookRecommendEnqueteInput!) {
  answerPreBookRecommendEnquete(input: $input) {
    state
  }
}
    `;
export type AnswerPreBookRecommendEnqueteMutationFn = Apollo.MutationFunction<AnswerPreBookRecommendEnqueteMutation, AnswerPreBookRecommendEnqueteMutationVariables>;

/**
 * __useAnswerPreBookRecommendEnqueteMutation__
 *
 * To run a mutation, you first call `useAnswerPreBookRecommendEnqueteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnswerPreBookRecommendEnqueteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [answerPreBookRecommendEnqueteMutation, { data, loading, error }] = useAnswerPreBookRecommendEnqueteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAnswerPreBookRecommendEnqueteMutation(baseOptions?: Apollo.MutationHookOptions<AnswerPreBookRecommendEnqueteMutation, AnswerPreBookRecommendEnqueteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AnswerPreBookRecommendEnqueteMutation, AnswerPreBookRecommendEnqueteMutationVariables>(AnswerPreBookRecommendEnqueteDocument, options);
      }
export type AnswerPreBookRecommendEnqueteMutationHookResult = ReturnType<typeof useAnswerPreBookRecommendEnqueteMutation>;
export type AnswerPreBookRecommendEnqueteMutationResult = Apollo.MutationResult<AnswerPreBookRecommendEnqueteMutation>;
export type AnswerPreBookRecommendEnqueteMutationOptions = Apollo.BaseMutationOptions<AnswerPreBookRecommendEnqueteMutation, AnswerPreBookRecommendEnqueteMutationVariables>;
export const GetOlderMessageLogDocument = gql`
    query getOlderMessageLog($current: Int!, $count: Int!) {
  getOlderMessageLog(current: $current, count: $count) {
    ...TalkAll
  }
}
    ${TalkAllFragmentDoc}`;

/**
 * __useGetOlderMessageLogQuery__
 *
 * To run a query within a React component, call `useGetOlderMessageLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOlderMessageLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOlderMessageLogQuery({
 *   variables: {
 *      current: // value for 'current'
 *      count: // value for 'count'
 *   },
 * });
 */
export function useGetOlderMessageLogQuery(baseOptions: Apollo.QueryHookOptions<GetOlderMessageLogQuery, GetOlderMessageLogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOlderMessageLogQuery, GetOlderMessageLogQueryVariables>(GetOlderMessageLogDocument, options);
      }
export function useGetOlderMessageLogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOlderMessageLogQuery, GetOlderMessageLogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOlderMessageLogQuery, GetOlderMessageLogQueryVariables>(GetOlderMessageLogDocument, options);
        }
export type GetOlderMessageLogQueryHookResult = ReturnType<typeof useGetOlderMessageLogQuery>;
export type GetOlderMessageLogLazyQueryHookResult = ReturnType<typeof useGetOlderMessageLogLazyQuery>;
export type GetOlderMessageLogQueryResult = Apollo.QueryResult<GetOlderMessageLogQuery, GetOlderMessageLogQueryVariables>;
export const PostReadLogDocument = gql`
    mutation PostReadLog($readLogInfo: ReadLogInput!) {
  postReadLog(readLogInfo: $readLogInfo) {
    ...PostReadLogResultForFeedbackModal
    ...ExperienceEventResult
  }
}
    ${PostReadLogResultForFeedbackModalFragmentDoc}
${ExperienceEventResultFragmentDoc}`;
export type PostReadLogMutationFn = Apollo.MutationFunction<PostReadLogMutation, PostReadLogMutationVariables>;

/**
 * __usePostReadLogMutation__
 *
 * To run a mutation, you first call `usePostReadLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostReadLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postReadLogMutation, { data, loading, error }] = usePostReadLogMutation({
 *   variables: {
 *      readLogInfo: // value for 'readLogInfo'
 *   },
 * });
 */
export function usePostReadLogMutation(baseOptions?: Apollo.MutationHookOptions<PostReadLogMutation, PostReadLogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PostReadLogMutation, PostReadLogMutationVariables>(PostReadLogDocument, options);
      }
export type PostReadLogMutationHookResult = ReturnType<typeof usePostReadLogMutation>;
export type PostReadLogMutationResult = Apollo.MutationResult<PostReadLogMutation>;
export type PostReadLogMutationOptions = Apollo.BaseMutationOptions<PostReadLogMutation, PostReadLogMutationVariables>;
export const GetNowRecommendDocument = gql`
    query GetNowRecommend($begin: Int!, $end: Int!) {
  me {
    recommends(done: false) {
      count
      get(begin: $begin, end: $end, order: RECOMMENDTIME) {
        id
        whenDone
        book {
          title
          imageUrl
          isbn
        }
      }
    }
  }
}
    `;

/**
 * __useGetNowRecommendQuery__
 *
 * To run a query within a React component, call `useGetNowRecommendQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNowRecommendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNowRecommendQuery({
 *   variables: {
 *      begin: // value for 'begin'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useGetNowRecommendQuery(baseOptions: Apollo.QueryHookOptions<GetNowRecommendQuery, GetNowRecommendQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNowRecommendQuery, GetNowRecommendQueryVariables>(GetNowRecommendDocument, options);
      }
export function useGetNowRecommendLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNowRecommendQuery, GetNowRecommendQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNowRecommendQuery, GetNowRecommendQueryVariables>(GetNowRecommendDocument, options);
        }
export type GetNowRecommendQueryHookResult = ReturnType<typeof useGetNowRecommendQuery>;
export type GetNowRecommendLazyQueryHookResult = ReturnType<typeof useGetNowRecommendLazyQuery>;
export type GetNowRecommendQueryResult = Apollo.QueryResult<GetNowRecommendQuery, GetNowRecommendQueryVariables>;
export const GetBookDataByRecommendIdDocument = gql`
    query GetBookDataByRecommendID($recommendId: Int!) {
  me {
    recommends(id: $recommendId) {
      get(order: FINISHTIME) {
        book {
          isbn
          title
        }
      }
    }
  }
}
    `;

/**
 * __useGetBookDataByRecommendIdQuery__
 *
 * To run a query within a React component, call `useGetBookDataByRecommendIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookDataByRecommendIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookDataByRecommendIdQuery({
 *   variables: {
 *      recommendId: // value for 'recommendId'
 *   },
 * });
 */
export function useGetBookDataByRecommendIdQuery(baseOptions: Apollo.QueryHookOptions<GetBookDataByRecommendIdQuery, GetBookDataByRecommendIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBookDataByRecommendIdQuery, GetBookDataByRecommendIdQueryVariables>(GetBookDataByRecommendIdDocument, options);
      }
export function useGetBookDataByRecommendIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBookDataByRecommendIdQuery, GetBookDataByRecommendIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBookDataByRecommendIdQuery, GetBookDataByRecommendIdQueryVariables>(GetBookDataByRecommendIdDocument, options);
        }
export type GetBookDataByRecommendIdQueryHookResult = ReturnType<typeof useGetBookDataByRecommendIdQuery>;
export type GetBookDataByRecommendIdLazyQueryHookResult = ReturnType<typeof useGetBookDataByRecommendIdLazyQuery>;
export type GetBookDataByRecommendIdQueryResult = Apollo.QueryResult<GetBookDataByRecommendIdQuery, GetBookDataByRecommendIdQueryVariables>;
export const GetBookByIsbnDocument = gql`
    mutation GetBookByIsbn($isbn: String!) {
  searchBookWithPaapi(isbn: $isbn) {
    isbn
    title
    imageUrl
  }
}
    `;
export type GetBookByIsbnMutationFn = Apollo.MutationFunction<GetBookByIsbnMutation, GetBookByIsbnMutationVariables>;

/**
 * __useGetBookByIsbnMutation__
 *
 * To run a mutation, you first call `useGetBookByIsbnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetBookByIsbnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getBookByIsbnMutation, { data, loading, error }] = useGetBookByIsbnMutation({
 *   variables: {
 *      isbn: // value for 'isbn'
 *   },
 * });
 */
export function useGetBookByIsbnMutation(baseOptions?: Apollo.MutationHookOptions<GetBookByIsbnMutation, GetBookByIsbnMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetBookByIsbnMutation, GetBookByIsbnMutationVariables>(GetBookByIsbnDocument, options);
      }
export type GetBookByIsbnMutationHookResult = ReturnType<typeof useGetBookByIsbnMutation>;
export type GetBookByIsbnMutationResult = Apollo.MutationResult<GetBookByIsbnMutation>;
export type GetBookByIsbnMutationOptions = Apollo.BaseMutationOptions<GetBookByIsbnMutation, GetBookByIsbnMutationVariables>;
export const MakePromiseDocument = gql`
    mutation makePromise($promiseDate: PromiseDate!, $chooseNext: Boolean!, $nextISBN: String) {
  createReadLogPromise(
    promiseDate: $promiseDate
    chooseNext: $chooseNext
    nextISBN: $nextISBN
  ) {
    id
  }
}
    `;
export type MakePromiseMutationFn = Apollo.MutationFunction<MakePromiseMutation, MakePromiseMutationVariables>;

/**
 * __useMakePromiseMutation__
 *
 * To run a mutation, you first call `useMakePromiseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakePromiseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makePromiseMutation, { data, loading, error }] = useMakePromiseMutation({
 *   variables: {
 *      promiseDate: // value for 'promiseDate'
 *      chooseNext: // value for 'chooseNext'
 *      nextISBN: // value for 'nextISBN'
 *   },
 * });
 */
export function useMakePromiseMutation(baseOptions?: Apollo.MutationHookOptions<MakePromiseMutation, MakePromiseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MakePromiseMutation, MakePromiseMutationVariables>(MakePromiseDocument, options);
      }
export type MakePromiseMutationHookResult = ReturnType<typeof useMakePromiseMutation>;
export type MakePromiseMutationResult = Apollo.MutationResult<MakePromiseMutation>;
export type MakePromiseMutationOptions = Apollo.BaseMutationOptions<MakePromiseMutation, MakePromiseMutationVariables>;
export const GetFootprintDocument = gql`
    query GetFootprint {
  me {
    createdAt
    general {
      nickname
      readBookSum(timeRange: ALL)
      readCharSum(timeRange: ALL)
      continueReadLogDays
      maxContinueReadLogDays
      miniLessonTotalNum
      readLogTotalNum
      receivedTotalBfReadLogReactionNum
      receivedBfReadLogStar {
        readingTechniqueType
        num
      }
    }
  }
}
    `;

/**
 * __useGetFootprintQuery__
 *
 * To run a query within a React component, call `useGetFootprintQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFootprintQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFootprintQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFootprintQuery(baseOptions?: Apollo.QueryHookOptions<GetFootprintQuery, GetFootprintQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFootprintQuery, GetFootprintQueryVariables>(GetFootprintDocument, options);
      }
export function useGetFootprintLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFootprintQuery, GetFootprintQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFootprintQuery, GetFootprintQueryVariables>(GetFootprintDocument, options);
        }
export type GetFootprintQueryHookResult = ReturnType<typeof useGetFootprintQuery>;
export type GetFootprintLazyQueryHookResult = ReturnType<typeof useGetFootprintLazyQuery>;
export type GetFootprintQueryResult = Apollo.QueryResult<GetFootprintQuery, GetFootprintQueryVariables>;
export const GetRecordBookDiffDocument = gql`
    query GetRecordBookDiff {
  getRecordBookDiff {
    IsFirstTimeViewInWeek
    TermStartDate
    TermEndDate
    ReadCharSumDiff
    ReadBookSumDiff
    MiniLessonTotalNumDiff
  }
}
    `;

/**
 * __useGetRecordBookDiffQuery__
 *
 * To run a query within a React component, call `useGetRecordBookDiffQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecordBookDiffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecordBookDiffQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRecordBookDiffQuery(baseOptions?: Apollo.QueryHookOptions<GetRecordBookDiffQuery, GetRecordBookDiffQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecordBookDiffQuery, GetRecordBookDiffQueryVariables>(GetRecordBookDiffDocument, options);
      }
export function useGetRecordBookDiffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecordBookDiffQuery, GetRecordBookDiffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecordBookDiffQuery, GetRecordBookDiffQueryVariables>(GetRecordBookDiffDocument, options);
        }
export type GetRecordBookDiffQueryHookResult = ReturnType<typeof useGetRecordBookDiffQuery>;
export type GetRecordBookDiffLazyQueryHookResult = ReturnType<typeof useGetRecordBookDiffLazyQuery>;
export type GetRecordBookDiffQueryResult = Apollo.QueryResult<GetRecordBookDiffQuery, GetRecordBookDiffQueryVariables>;
export const UpsertLatestRecordBookViewTimeDocument = gql`
    mutation UpsertLatestRecordBookViewTime {
  upsertLatestRecordBookViewTime {
    state
  }
}
    `;
export type UpsertLatestRecordBookViewTimeMutationFn = Apollo.MutationFunction<UpsertLatestRecordBookViewTimeMutation, UpsertLatestRecordBookViewTimeMutationVariables>;

/**
 * __useUpsertLatestRecordBookViewTimeMutation__
 *
 * To run a mutation, you first call `useUpsertLatestRecordBookViewTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertLatestRecordBookViewTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertLatestRecordBookViewTimeMutation, { data, loading, error }] = useUpsertLatestRecordBookViewTimeMutation({
 *   variables: {
 *   },
 * });
 */
export function useUpsertLatestRecordBookViewTimeMutation(baseOptions?: Apollo.MutationHookOptions<UpsertLatestRecordBookViewTimeMutation, UpsertLatestRecordBookViewTimeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertLatestRecordBookViewTimeMutation, UpsertLatestRecordBookViewTimeMutationVariables>(UpsertLatestRecordBookViewTimeDocument, options);
      }
export type UpsertLatestRecordBookViewTimeMutationHookResult = ReturnType<typeof useUpsertLatestRecordBookViewTimeMutation>;
export type UpsertLatestRecordBookViewTimeMutationResult = Apollo.MutationResult<UpsertLatestRecordBookViewTimeMutation>;
export type UpsertLatestRecordBookViewTimeMutationOptions = Apollo.BaseMutationOptions<UpsertLatestRecordBookViewTimeMutation, UpsertLatestRecordBookViewTimeMutationVariables>;
export const GetMyRecordDocument = gql`
    query GetMyRecord($timeRange: TimeRange!) {
  me {
    createdAt
    general {
      nickname
      readBookSum(timeRange: $timeRange)
      readCharSum(timeRange: $timeRange)
      continueReadLogDays
      maxContinueReadLogDays
      continueMiniLessonDays
    }
  }
}
    `;

/**
 * __useGetMyRecordQuery__
 *
 * To run a query within a React component, call `useGetMyRecordQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyRecordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyRecordQuery({
 *   variables: {
 *      timeRange: // value for 'timeRange'
 *   },
 * });
 */
export function useGetMyRecordQuery(baseOptions: Apollo.QueryHookOptions<GetMyRecordQuery, GetMyRecordQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyRecordQuery, GetMyRecordQueryVariables>(GetMyRecordDocument, options);
      }
export function useGetMyRecordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyRecordQuery, GetMyRecordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyRecordQuery, GetMyRecordQueryVariables>(GetMyRecordDocument, options);
        }
export type GetMyRecordQueryHookResult = ReturnType<typeof useGetMyRecordQuery>;
export type GetMyRecordLazyQueryHookResult = ReturnType<typeof useGetMyRecordLazyQuery>;
export type GetMyRecordQueryResult = Apollo.QueryResult<GetMyRecordQuery, GetMyRecordQueryVariables>;
export const GetDoneRecommendBookshelfDocument = gql`
    query getDoneRecommendBookshelf($begin: Int!, $end: Int!) {
  getDoneRecommends {
    get(begin: $begin, end: $end, order: FINISHTIME) {
      id
      recommendStatus
      whenDone
      book {
        title
        imageUrl
        yl
      }
      readLog(statusEnd: true, statusReading: true, statusQuit: false) {
        get(begin: 0, end: 1, order: CREATED_AT_DESC) {
          review
          like
        }
      }
    }
    count
  }
}
    `;

/**
 * __useGetDoneRecommendBookshelfQuery__
 *
 * To run a query within a React component, call `useGetDoneRecommendBookshelfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDoneRecommendBookshelfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDoneRecommendBookshelfQuery({
 *   variables: {
 *      begin: // value for 'begin'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useGetDoneRecommendBookshelfQuery(baseOptions: Apollo.QueryHookOptions<GetDoneRecommendBookshelfQuery, GetDoneRecommendBookshelfQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDoneRecommendBookshelfQuery, GetDoneRecommendBookshelfQueryVariables>(GetDoneRecommendBookshelfDocument, options);
      }
export function useGetDoneRecommendBookshelfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDoneRecommendBookshelfQuery, GetDoneRecommendBookshelfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDoneRecommendBookshelfQuery, GetDoneRecommendBookshelfQueryVariables>(GetDoneRecommendBookshelfDocument, options);
        }
export type GetDoneRecommendBookshelfQueryHookResult = ReturnType<typeof useGetDoneRecommendBookshelfQuery>;
export type GetDoneRecommendBookshelfLazyQueryHookResult = ReturnType<typeof useGetDoneRecommendBookshelfLazyQuery>;
export type GetDoneRecommendBookshelfQueryResult = Apollo.QueryResult<GetDoneRecommendBookshelfQuery, GetDoneRecommendBookshelfQueryVariables>;
export const RemoveRecommendDocument = gql`
    mutation RemoveRecommend($recommendID: Int!, $removeReason: RemoveReason!) {
  removeRecommend(recommendID: $recommendID, removeReason: $removeReason) {
    id
  }
}
    `;
export type RemoveRecommendMutationFn = Apollo.MutationFunction<RemoveRecommendMutation, RemoveRecommendMutationVariables>;

/**
 * __useRemoveRecommendMutation__
 *
 * To run a mutation, you first call `useRemoveRecommendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRecommendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRecommendMutation, { data, loading, error }] = useRemoveRecommendMutation({
 *   variables: {
 *      recommendID: // value for 'recommendID'
 *      removeReason: // value for 'removeReason'
 *   },
 * });
 */
export function useRemoveRecommendMutation(baseOptions?: Apollo.MutationHookOptions<RemoveRecommendMutation, RemoveRecommendMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveRecommendMutation, RemoveRecommendMutationVariables>(RemoveRecommendDocument, options);
      }
export type RemoveRecommendMutationHookResult = ReturnType<typeof useRemoveRecommendMutation>;
export type RemoveRecommendMutationResult = Apollo.MutationResult<RemoveRecommendMutation>;
export type RemoveRecommendMutationOptions = Apollo.BaseMutationOptions<RemoveRecommendMutation, RemoveRecommendMutationVariables>;
export const GetUserAvailabilitiesForVerificationDocument = gql`
    query getUserAvailabilitiesForVerification {
  getUsers {
    userID
    nickname
    userPlan
    userIconType
  }
}
    `;

/**
 * __useGetUserAvailabilitiesForVerificationQuery__
 *
 * To run a query within a React component, call `useGetUserAvailabilitiesForVerificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAvailabilitiesForVerificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAvailabilitiesForVerificationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserAvailabilitiesForVerificationQuery(baseOptions?: Apollo.QueryHookOptions<GetUserAvailabilitiesForVerificationQuery, GetUserAvailabilitiesForVerificationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserAvailabilitiesForVerificationQuery, GetUserAvailabilitiesForVerificationQueryVariables>(GetUserAvailabilitiesForVerificationDocument, options);
      }
export function useGetUserAvailabilitiesForVerificationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserAvailabilitiesForVerificationQuery, GetUserAvailabilitiesForVerificationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserAvailabilitiesForVerificationQuery, GetUserAvailabilitiesForVerificationQueryVariables>(GetUserAvailabilitiesForVerificationDocument, options);
        }
export type GetUserAvailabilitiesForVerificationQueryHookResult = ReturnType<typeof useGetUserAvailabilitiesForVerificationQuery>;
export type GetUserAvailabilitiesForVerificationLazyQueryHookResult = ReturnType<typeof useGetUserAvailabilitiesForVerificationLazyQuery>;
export type GetUserAvailabilitiesForVerificationQueryResult = Apollo.QueryResult<GetUserAvailabilitiesForVerificationQuery, GetUserAvailabilitiesForVerificationQueryVariables>;
export const GetDoneRecommendDocument = gql`
    query getDoneRecommend($begin: Int!, $end: Int!) {
  getDoneRecommends {
    get(begin: $begin, end: $end, order: FINISHTIME) {
      id
      book {
        imageUrl
      }
    }
  }
}
    `;

/**
 * __useGetDoneRecommendQuery__
 *
 * To run a query within a React component, call `useGetDoneRecommendQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDoneRecommendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDoneRecommendQuery({
 *   variables: {
 *      begin: // value for 'begin'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useGetDoneRecommendQuery(baseOptions: Apollo.QueryHookOptions<GetDoneRecommendQuery, GetDoneRecommendQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDoneRecommendQuery, GetDoneRecommendQueryVariables>(GetDoneRecommendDocument, options);
      }
export function useGetDoneRecommendLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDoneRecommendQuery, GetDoneRecommendQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDoneRecommendQuery, GetDoneRecommendQueryVariables>(GetDoneRecommendDocument, options);
        }
export type GetDoneRecommendQueryHookResult = ReturnType<typeof useGetDoneRecommendQuery>;
export type GetDoneRecommendLazyQueryHookResult = ReturnType<typeof useGetDoneRecommendLazyQuery>;
export type GetDoneRecommendQueryResult = Apollo.QueryResult<GetDoneRecommendQuery, GetDoneRecommendQueryVariables>;
export const GetOneRecommendInfoDocument = gql`
    query GetOneRecommendInfo($recommendID: Int!, $begin: Int!, $end: Int!) {
  me {
    recommends(id: $recommendID) {
      get(order: FINISHTIME) {
        id
        whenRecommend
        whenDone
        recommendStatus
        recommendComment
        self
        book {
          isbn
          yl
          publisher
          pageCount
          author
          painter
          wordCount
          title
          published
          synopsis
          imageUrl
        }
        readLog(statusEnd: true, statusReading: true, statusQuit: true) {
          count
          get(begin: $begin, end: $end, order: CREATED_AT_ASC) {
            id
            when
            review
            emotions
            difficulty
            like
            status
            favoriteScene
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetOneRecommendInfoQuery__
 *
 * To run a query within a React component, call `useGetOneRecommendInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneRecommendInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneRecommendInfoQuery({
 *   variables: {
 *      recommendID: // value for 'recommendID'
 *      begin: // value for 'begin'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useGetOneRecommendInfoQuery(baseOptions: Apollo.QueryHookOptions<GetOneRecommendInfoQuery, GetOneRecommendInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOneRecommendInfoQuery, GetOneRecommendInfoQueryVariables>(GetOneRecommendInfoDocument, options);
      }
export function useGetOneRecommendInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOneRecommendInfoQuery, GetOneRecommendInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOneRecommendInfoQuery, GetOneRecommendInfoQueryVariables>(GetOneRecommendInfoDocument, options);
        }
export type GetOneRecommendInfoQueryHookResult = ReturnType<typeof useGetOneRecommendInfoQuery>;
export type GetOneRecommendInfoLazyQueryHookResult = ReturnType<typeof useGetOneRecommendInfoLazyQuery>;
export type GetOneRecommendInfoQueryResult = Apollo.QueryResult<GetOneRecommendInfoQuery, GetOneRecommendInfoQueryVariables>;
export const SearchCityByMyLibraryDocument = gql`
    query SearchCityByMyLibrary {
  searchCityByMyLibrary
}
    `;

/**
 * __useSearchCityByMyLibraryQuery__
 *
 * To run a query within a React component, call `useSearchCityByMyLibraryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCityByMyLibraryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCityByMyLibraryQuery({
 *   variables: {
 *   },
 * });
 */
export function useSearchCityByMyLibraryQuery(baseOptions?: Apollo.QueryHookOptions<SearchCityByMyLibraryQuery, SearchCityByMyLibraryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchCityByMyLibraryQuery, SearchCityByMyLibraryQueryVariables>(SearchCityByMyLibraryDocument, options);
      }
export function useSearchCityByMyLibraryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchCityByMyLibraryQuery, SearchCityByMyLibraryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchCityByMyLibraryQuery, SearchCityByMyLibraryQueryVariables>(SearchCityByMyLibraryDocument, options);
        }
export type SearchCityByMyLibraryQueryHookResult = ReturnType<typeof useSearchCityByMyLibraryQuery>;
export type SearchCityByMyLibraryLazyQueryHookResult = ReturnType<typeof useSearchCityByMyLibraryLazyQuery>;
export type SearchCityByMyLibraryQueryResult = Apollo.QueryResult<SearchCityByMyLibraryQuery, SearchCityByMyLibraryQueryVariables>;
export const GetWantReadBookByIdDocument = gql`
    query getWantReadBookByID($id: Int!) {
  getWantReadBookByID(id: $id) {
    id
    userID
    readRecommendID
    recommendStatus
    book {
      isbn
      yl
      publisher
      pageCount
      author
      painter
      wordCount
      title
      published
      synopsis
      imageUrl
      impression
      affiliateLink
    }
  }
}
    `;

/**
 * __useGetWantReadBookByIdQuery__
 *
 * To run a query within a React component, call `useGetWantReadBookByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWantReadBookByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWantReadBookByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWantReadBookByIdQuery(baseOptions: Apollo.QueryHookOptions<GetWantReadBookByIdQuery, GetWantReadBookByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWantReadBookByIdQuery, GetWantReadBookByIdQueryVariables>(GetWantReadBookByIdDocument, options);
      }
export function useGetWantReadBookByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWantReadBookByIdQuery, GetWantReadBookByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWantReadBookByIdQuery, GetWantReadBookByIdQueryVariables>(GetWantReadBookByIdDocument, options);
        }
export type GetWantReadBookByIdQueryHookResult = ReturnType<typeof useGetWantReadBookByIdQuery>;
export type GetWantReadBookByIdLazyQueryHookResult = ReturnType<typeof useGetWantReadBookByIdLazyQuery>;
export type GetWantReadBookByIdQueryResult = Apollo.QueryResult<GetWantReadBookByIdQuery, GetWantReadBookByIdQueryVariables>;
export const CreateYlReassesmentEnqueteDocument = gql`
    mutation CreateYLReassesmentEnquete($input: YLReassesmentEnqueteInput!) {
  createYLReassesmentEnquete(input: $input) {
    ... on OK {
      state
    }
    ... on ErrorSuitableYLNotFound {
      message
    }
  }
}
    `;
export type CreateYlReassesmentEnqueteMutationFn = Apollo.MutationFunction<CreateYlReassesmentEnqueteMutation, CreateYlReassesmentEnqueteMutationVariables>;

/**
 * __useCreateYlReassesmentEnqueteMutation__
 *
 * To run a mutation, you first call `useCreateYlReassesmentEnqueteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateYlReassesmentEnqueteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createYlReassesmentEnqueteMutation, { data, loading, error }] = useCreateYlReassesmentEnqueteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateYlReassesmentEnqueteMutation(baseOptions?: Apollo.MutationHookOptions<CreateYlReassesmentEnqueteMutation, CreateYlReassesmentEnqueteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateYlReassesmentEnqueteMutation, CreateYlReassesmentEnqueteMutationVariables>(CreateYlReassesmentEnqueteDocument, options);
      }
export type CreateYlReassesmentEnqueteMutationHookResult = ReturnType<typeof useCreateYlReassesmentEnqueteMutation>;
export type CreateYlReassesmentEnqueteMutationResult = Apollo.MutationResult<CreateYlReassesmentEnqueteMutation>;
export type CreateYlReassesmentEnqueteMutationOptions = Apollo.BaseMutationOptions<CreateYlReassesmentEnqueteMutation, CreateYlReassesmentEnqueteMutationVariables>;
export const DestroyAuthSessionDocument = gql`
    mutation DestroyAuthSession {
  destroyAuthSession {
    state
  }
}
    `;
export type DestroyAuthSessionMutationFn = Apollo.MutationFunction<DestroyAuthSessionMutation, DestroyAuthSessionMutationVariables>;

/**
 * __useDestroyAuthSessionMutation__
 *
 * To run a mutation, you first call `useDestroyAuthSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyAuthSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyAuthSessionMutation, { data, loading, error }] = useDestroyAuthSessionMutation({
 *   variables: {
 *   },
 * });
 */
export function useDestroyAuthSessionMutation(baseOptions?: Apollo.MutationHookOptions<DestroyAuthSessionMutation, DestroyAuthSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DestroyAuthSessionMutation, DestroyAuthSessionMutationVariables>(DestroyAuthSessionDocument, options);
      }
export type DestroyAuthSessionMutationHookResult = ReturnType<typeof useDestroyAuthSessionMutation>;
export type DestroyAuthSessionMutationResult = Apollo.MutationResult<DestroyAuthSessionMutation>;
export type DestroyAuthSessionMutationOptions = Apollo.BaseMutationOptions<DestroyAuthSessionMutation, DestroyAuthSessionMutationVariables>;
export const BfGroupCacheDocument = gql`
    query bfGroupCache {
  bfGroupCache @client {
    ...BfReadLogCacheAll
  }
}
    ${BfReadLogCacheAllFragmentDoc}`;

/**
 * __useBfGroupCacheQuery__
 *
 * To run a query within a React component, call `useBfGroupCacheQuery` and pass it any options that fit your needs.
 * When your component renders, `useBfGroupCacheQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBfGroupCacheQuery({
 *   variables: {
 *   },
 * });
 */
export function useBfGroupCacheQuery(baseOptions?: Apollo.QueryHookOptions<BfGroupCacheQuery, BfGroupCacheQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BfGroupCacheQuery, BfGroupCacheQueryVariables>(BfGroupCacheDocument, options);
      }
export function useBfGroupCacheLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BfGroupCacheQuery, BfGroupCacheQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BfGroupCacheQuery, BfGroupCacheQueryVariables>(BfGroupCacheDocument, options);
        }
export type BfGroupCacheQueryHookResult = ReturnType<typeof useBfGroupCacheQuery>;
export type BfGroupCacheLazyQueryHookResult = ReturnType<typeof useBfGroupCacheLazyQuery>;
export type BfGroupCacheQueryResult = Apollo.QueryResult<BfGroupCacheQuery, BfGroupCacheQueryVariables>;
export const MessageCacheDocument = gql`
    query messageCache {
  messageCache @client {
    ...TalkCacheAll
  }
}
    ${TalkCacheAllFragmentDoc}`;

/**
 * __useMessageCacheQuery__
 *
 * To run a query within a React component, call `useMessageCacheQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageCacheQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageCacheQuery({
 *   variables: {
 *   },
 * });
 */
export function useMessageCacheQuery(baseOptions?: Apollo.QueryHookOptions<MessageCacheQuery, MessageCacheQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessageCacheQuery, MessageCacheQueryVariables>(MessageCacheDocument, options);
      }
export function useMessageCacheLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessageCacheQuery, MessageCacheQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessageCacheQuery, MessageCacheQueryVariables>(MessageCacheDocument, options);
        }
export type MessageCacheQueryHookResult = ReturnType<typeof useMessageCacheQuery>;
export type MessageCacheLazyQueryHookResult = ReturnType<typeof useMessageCacheLazyQuery>;
export type MessageCacheQueryResult = Apollo.QueryResult<MessageCacheQuery, MessageCacheQueryVariables>;
export const TutorialFlagsDocument = gql`
    query tutorialFlags($tutorialFlagKeys: [TutorialFlagKey]!) {
  getTutorialFlags(tutorialFlagKeys: $tutorialFlagKeys) {
    key
    value
  }
}
    `;

/**
 * __useTutorialFlagsQuery__
 *
 * To run a query within a React component, call `useTutorialFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTutorialFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTutorialFlagsQuery({
 *   variables: {
 *      tutorialFlagKeys: // value for 'tutorialFlagKeys'
 *   },
 * });
 */
export function useTutorialFlagsQuery(baseOptions: Apollo.QueryHookOptions<TutorialFlagsQuery, TutorialFlagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TutorialFlagsQuery, TutorialFlagsQueryVariables>(TutorialFlagsDocument, options);
      }
export function useTutorialFlagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TutorialFlagsQuery, TutorialFlagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TutorialFlagsQuery, TutorialFlagsQueryVariables>(TutorialFlagsDocument, options);
        }
export type TutorialFlagsQueryHookResult = ReturnType<typeof useTutorialFlagsQuery>;
export type TutorialFlagsLazyQueryHookResult = ReturnType<typeof useTutorialFlagsLazyQuery>;
export type TutorialFlagsQueryResult = Apollo.QueryResult<TutorialFlagsQuery, TutorialFlagsQueryVariables>;
export const SetTutorialFlagDocument = gql`
    mutation setTutorialFlag($input: DoneTutorialFlagInput!) {
  doneTutorialByFlag(input: $input) {
    key
    value
  }
}
    `;
export type SetTutorialFlagMutationFn = Apollo.MutationFunction<SetTutorialFlagMutation, SetTutorialFlagMutationVariables>;

/**
 * __useSetTutorialFlagMutation__
 *
 * To run a mutation, you first call `useSetTutorialFlagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTutorialFlagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTutorialFlagMutation, { data, loading, error }] = useSetTutorialFlagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetTutorialFlagMutation(baseOptions?: Apollo.MutationHookOptions<SetTutorialFlagMutation, SetTutorialFlagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetTutorialFlagMutation, SetTutorialFlagMutationVariables>(SetTutorialFlagDocument, options);
      }
export type SetTutorialFlagMutationHookResult = ReturnType<typeof useSetTutorialFlagMutation>;
export type SetTutorialFlagMutationResult = Apollo.MutationResult<SetTutorialFlagMutation>;
export type SetTutorialFlagMutationOptions = Apollo.BaseMutationOptions<SetTutorialFlagMutation, SetTutorialFlagMutationVariables>;
export const GetMessageLogDocument = gql`
    mutation getMessageLog($oldCount: Int!) {
  getMessageLog(oldCount: $oldCount) {
    ...TalkLogAll
  }
}
    ${TalkLogAllFragmentDoc}`;
export type GetMessageLogMutationFn = Apollo.MutationFunction<GetMessageLogMutation, GetMessageLogMutationVariables>;

/**
 * __useGetMessageLogMutation__
 *
 * To run a mutation, you first call `useGetMessageLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetMessageLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getMessageLogMutation, { data, loading, error }] = useGetMessageLogMutation({
 *   variables: {
 *      oldCount: // value for 'oldCount'
 *   },
 * });
 */
export function useGetMessageLogMutation(baseOptions?: Apollo.MutationHookOptions<GetMessageLogMutation, GetMessageLogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetMessageLogMutation, GetMessageLogMutationVariables>(GetMessageLogDocument, options);
      }
export type GetMessageLogMutationHookResult = ReturnType<typeof useGetMessageLogMutation>;
export type GetMessageLogMutationResult = Apollo.MutationResult<GetMessageLogMutation>;
export type GetMessageLogMutationOptions = Apollo.BaseMutationOptions<GetMessageLogMutation, GetMessageLogMutationVariables>;