import React, { useState, FC } from "react";
import recommendSettings from "~/components/templates/EditRecommendSetting/const";
import { CurrentUserFragment } from "~/generated/graphql";
import { BeUnderTwoBooks } from "./BeUnderTwoBooks";
import { DeleteRecommend } from "./DeleteRecommend";
import { FinalScreen } from "./FinalScreen";
import { FinishReading } from "./FinishReading";
import { GetBooks } from "./GetBooks";
import { GetFirstRecommend } from "./GetFirstRecommend";
import { GetNewRecommend } from "./GetNewRecommend";
import { images } from "./images";
import { RefuseToRead } from "./RefuseToRead";
import { StartScreen } from "./StartScreen";
import { SubmitEndReadlog } from "./SubmitEndReadlog";
import { SubmitOngoingReadlog } from "./SubmitOngoingReadlog";
import { SwipeOutRecommend } from "./SwipeOutRecommend";
import { UnableToBorrow } from "./UnableToBorrow";
import { useReplayableGIF } from "./useReplayableGIF";
import { BorrowManyBooks } from "./BorrowManyBooks";
import { MeetFavoriteBook } from "./MeetFavoriteBook";
import { MakeOnlineReservation } from "./MakeOnlineReservation";

export type RecommendTutorialModalListProps = {
  handleClose: () => void;
  currentUser?: CurrentUserFragment;
};

export interface RecommendTutorialProps {
  step: number;
  STEP_MAX: 15;
  handleGoNext: () => void;
  handleGoBack: () => void;
  handleChangeStep: (step: number) => void;
  handleClose: () => void;
}

export const RecommendTutorialModalList: FC<
  RecommendTutorialModalListProps
> = ({ handleClose, currentUser }) => {
  // preloadのための初期化
  useReplayableGIF(Object.values(images));

  const [step, setStep] = useState(0);
  const STEP_MAX = 15;
  const handleChangeStep = (step: number) => {
    setStep(step);
  };
  const props: RecommendTutorialProps = {
    handleChangeStep,
    handleClose,
    step,
    STEP_MAX,
    handleGoNext: () => handleChangeStep(step + 1),
    handleGoBack: () => handleChangeStep(step - 1),
  };

  switch (step) {
    case 0:
      return <StartScreen {...props} />;
    case 1:
      return (
        <GetFirstRecommend
          bookListMax={
            recommendSettings[currentUser?.recommendFrequency || "BASIC"]
              ?.border + 1
          }
          {...props}
        />
      );
    case 2:
      return <GetBooks {...props} />;
    case 3:
      return <BorrowManyBooks {...props} />;
    case 4:
      return <MeetFavoriteBook {...props} />;
    case 5:
      return <MakeOnlineReservation {...props} />;
    case 6:
      return <SubmitOngoingReadlog {...props} />;
    case 7:
      return <FinishReading {...props} />;
    case 8:
      return <SubmitEndReadlog {...props} />;
    case 9:
      return <SwipeOutRecommend {...props} />;
    case 10:
      return <UnableToBorrow {...props} />;
    case 11:
      return <RefuseToRead {...props} />;
    case 12:
      return <DeleteRecommend {...props} />;
    case 13:
      return (
        <BeUnderTwoBooks
          border={
            recommendSettings[currentUser?.recommendFrequency || "BASIC"]
              ?.border
          }
          {...props}
        />
      );
    case 14:
      return <GetNewRecommend {...props} />;
    case 15:
      return <FinalScreen {...props} />;
    default:
      return <StartScreen {...props} />;
  }
};
