import { useApolloClient } from "@apollo/client";
import debounce from "lodash/debounce";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BookFriendMessageTemplate } from "~/components/templates/BookFriendMessage";
import { LoadingPage } from "~/components/templates/Loading/LoadingPage";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import {
  GetBfReadLogsDocument,
  GetBfReadLogsQuery,
  GetBfReadLogsQueryVariables,
  useAddBfReadLogReactionMutation,
  useBfGroupCacheQuery,
  useAddWantReadBookMutation,
  BfReadLogMyReaction,
  BfReadLogReadLogInfo,
  BfReadLogFragment,
  useAddBfReadLogStarMutation,
  ReadingTechniqueType,
  useGetAvailableStarEnergyIdQuery,
  SearchDirection,
  useUpsertLatestBfReadLogViewTimeMutation,
  useGetLatestBfReadLogViewTimeQuery,
  ReadLogReactionType,
} from "~/generated/graphql";
import {
  appendToBfGroupCache,
  initBfGroupCache,
  modifyBfReadLogBfGroupCache,
  modifyDigNextAllByGroupCache,
  modifyLatestBfReadLogViewTimeByGroupCache,
  setLoadingBfGroupCache,
} from "~/store/bf";
import { config } from "../../../config";
import { INITIAL_FIRST_ITEM_INDEX } from "../Message";
import { SendStarModal } from "~/components/organisms/bookFriendMessage/BookFriendMessageListItem/ReadLogMessage/SendStarModal";
import { SimplifiedReadLogMessageProps } from "~/components/organisms/bookFriendMessage/BookFriendMessageListItem/ReadLogMessage/SendStarModal/SimplifiedReadLogMessage";
import { usePreference } from "~/store/preference/usePreference";
import { GetStarEnergyModal } from "~/components/organisms/bookFriendMessage/GetStarEnergyModal";

type LastSendStarInfo = {
  readLogId: number;
  readingTechniqueType: ReadingTechniqueType;
};

export const BookFriendMessageListPage: React.FC = () => {
  const client = useApolloClient();
  const navigate = useNavigate();
  const [readLogForStarModal, setReadLogForStarModal] = React.useState<
    SimplifiedReadLogMessageProps | undefined
  >(undefined);

  const [readLogInfoForSendStarModal, setReadLogInfoForSendStarModal] =
    React.useState<LastSendStarInfo | undefined>(undefined);

  const { preference, savePreference } = usePreference();

  const [showGetEnergyModal, setShowGetEnergyModal] = useState<boolean>(false);

  const { data } = useBfGroupCacheQuery();
  const {
    currentUser,
    loading: currentUserLoading,
    error: currentUserError,
    refetch: refetchCurrentUser,
  } = useContext(CurrentUserContext);

  const userID = currentUser?.general.id;
  const bfGroup = currentUser?.belong;

  const [firstItemIndex, setFirstItemIndex] = useState<number>(
    INITIAL_FIRST_ITEM_INDEX
  );

  const onChangeTab = (page: number) => {
    // タブ切り替え時に未読インジケーターを更新する
    refetchCurrentUser();
    latestRefetch();
    switch (page) {
      case 0:
        navigate("/bf");
        break;
      case 1:
        navigate("/");
        break;
      case 2:
        navigate("/message");
        break;
      default:
        return;
    }
  };

  const [upsertLatestBfReadLogViewTime] =
    useUpsertLatestBfReadLogViewTimeMutation();

  const initBfReadLogs = async () => {
    const { data } = await client.query<
      GetBfReadLogsQuery,
      GetBfReadLogsQueryVariables
    >({
      query: GetBfReadLogsDocument,
      fetchPolicy: "no-cache",
      variables: {
        limit: config.TALK_FETCH_NUMBER,
        direction: SearchDirection.Older,
      },
    });

    initBfGroupCache(
      client.cache,
      config.TALK_FETCH_NUMBER,
      data.getBfReadLogs,
      latestData?.getLatestBfReadLogViewTime
    );

    upsertLatestBfReadLogViewTime();
  };

  const { data: latestData, refetch: latestRefetch } =
    useGetLatestBfReadLogViewTimeQuery({
      fetchPolicy: "no-cache",
      onCompleted(res) {
        if (data?.bfGroupCache.state === 2) {
          modifyLatestBfReadLogViewTimeByGroupCache(
            client.cache,
            res?.getLatestBfReadLogViewTime
          );
        } else {
          setLoadingBfGroupCache(client, true);
          initBfReadLogs();
        }
      },
      onError() {
        setLoadingBfGroupCache(client, true);
        initBfReadLogs();
      },
    });

  // MEMO: スクロールするたびにリクエストがかかってしまうのでdebounceで遅延させる
  const onPrevLoading = useCallback(
    debounce(async (item) => {
      // 全て読み込んだらリクエストをかけない
      if (data?.bfGroupCache.digPrevAll) return;

      setLoadingBfGroupCache(client, false);
      const { data: bfData } = await client.query<
        GetBfReadLogsQuery,
        GetBfReadLogsQueryVariables
      >({
        query: GetBfReadLogsDocument,
        fetchPolicy: "no-cache",
        variables: {
          limit: config.TALK_FETCH_NUMBER,
          direction: SearchDirection.Older,
          exclusiveStartId: item.id,
        },
      });

      setFirstItemIndex((i) => i - bfData.getBfReadLogs.length);
      appendToBfGroupCache(
        client.cache,
        config.TALK_FETCH_NUMBER,
        bfData.getBfReadLogs,
        SearchDirection.Older
      );
    }, 1000),
    [data?.bfGroupCache]
  );

  const onNextLoading = useCallback(
    debounce(async (item) => {
      // 全て読み込んだらリクエストをかけない
      if (data?.bfGroupCache.digNextAll) return;

      setLoadingBfGroupCache(client, false);
      const { data: bfData } = await client.query<
        GetBfReadLogsQuery,
        GetBfReadLogsQueryVariables
      >({
        query: GetBfReadLogsDocument,
        fetchPolicy: "no-cache",
        variables: {
          limit: config.TALK_FETCH_NUMBER,
          direction: SearchDirection.Newer,
          exclusiveStartId: item.id,
        },
      });

      appendToBfGroupCache(
        client.cache,
        config.TALK_FETCH_NUMBER,
        bfData.getBfReadLogs,
        SearchDirection.Newer
      );
      upsertLatestBfReadLogViewTime();
    }, 1000),
    [data?.bfGroupCache]
  );

  const [addBfReadLogReaction] = useAddBfReadLogReactionMutation();
  const onReaction = useCallback(
    async (id: string, readLogId: number, reaction: ReadLogReactionType) => {
      const res = await addBfReadLogReaction({
        variables: {
          readLogId: readLogId,
          reactionType: reaction,
        },
      });

      if (res.errors) return false;

      modifyBfReadLogBfGroupCache(client, `BfReadLog:${id}`, {
        myReaction: {
          reactionType: [reaction],
        } as BfReadLogMyReaction,
      });

      return true;
    },
    [addBfReadLogReaction]
  );

  const [addBfReadLogStar, { loading: sendStarLoading }] =
    useAddBfReadLogStarMutation();
  const onSendStar = useCallback(
    (readingTechniqueType: ReadingTechniqueType) =>
      new Promise<boolean>((res) => {
        if (!readLogForStarModal) {
          res(false);
        } else {
          addBfReadLogStar({
            variables: {
              readLogID: readLogForStarModal.readLogInfo.readLogId,
              readingTechniqueType: readingTechniqueType,
            },
          })
            .then(() => {
              modifyBfReadLogBfGroupCache(
                client,
                `BfReadLog:${readLogForStarModal.id}`,
                {
                  myReaction: {
                    starType: readingTechniqueType,
                  } as BfReadLogMyReaction,
                }
              );
              setReadLogInfoForSendStarModal({
                readLogId: readLogForStarModal.readLogInfo.readLogId,
                readingTechniqueType: readingTechniqueType,
              });
              setReadLogForStarModal(undefined);
              energyRefetch().then(() => res(true));
            })
            .catch(() => {
              res(false);
            });
        }
      }),
    [addBfReadLogStar, client, readLogForStarModal, readLogInfoForSendStarModal]
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      modifyDigNextAllByGroupCache(client.cache, false);
    }, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  //NOTE: 以下の処理はよみま書架関連

  const [addWantReadBookShelfMutation] = useAddWantReadBookMutation();
  const [readLogIdListForSenseiMessage, setReadLogIdListForSenseiMessage] =
    useState<number[]>([]);

  const onWantReadBook = React.useCallback(
    (readLog: BfReadLogFragment): Promise<boolean> => {
      return new Promise((resolve) => {
        addWantReadBookShelfMutation({
          variables: {
            readLogId: readLog.readLogInfo.readLogId,
          },
        })
          .then(() => {
            // NOTE: talkはrefetchできないので手動でアップデート
            data?.bfGroupCache.bfReadLogs.forEach((talkItem) => {
              if (
                talkItem.readLogInfo.book.isbn === readLog.readLogInfo.book.isbn
              ) {
                modifyBfReadLogBfGroupCache(
                  client,
                  `BfReadLog:${talkItem.id}`,
                  {
                    readLogInfo: {
                      isMyWantReadBook: true,
                    } as BfReadLogReadLogInfo,
                  }
                );
              }
            });
            // NOTE: SenseiMessageの再レンダリングを避けるためにsetTimeoutに入れて上の処理とずらす必要がある
            window.setTimeout(() => {
              setReadLogIdListForSenseiMessage((oldReadLogIdList) => [
                ...oldReadLogIdList,
                readLog.readLogInfo.readLogId,
              ]);
            });
          })
          .catch(() => {
            resolve(false);
          });
      });
    },
    [addWantReadBookShelfMutation, data?.bfGroupCache.bfReadLogs, client]
  );

  const onCompleteShowSenseiMessage = React.useCallback((readLogId: number) => {
    setReadLogIdListForSenseiMessage((oldReadLogIdList) =>
      oldReadLogIdList.filter((id) => id !== readLogId)
    );
  }, []);
  //NOTE: ここまでよみま書架関連

  const {
    error: energyError,
    data: energyData,
    loading: energyLoading,
    refetch: energyRefetch,
  } = useGetAvailableStarEnergyIdQuery({
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      setShowGetEnergyModal(
        Boolean(res.getAvailableStarEnergyID) &&
          (!preference?.latestStarEnergyID ||
            res.getAvailableStarEnergyID !== preference.latestStarEnergyID)
      );
    },
  });

  const onCloseGetEnergyModal = () => {
    setShowGetEnergyModal(false);
    savePreference({
      latestStarEnergyID: energyData?.getAvailableStarEnergyID,
    });
  };

  if (currentUserLoading || energyLoading || !currentUser)
    return <LoadingPage />;
  if (currentUserError) return <>Error!{currentUserError.message}</>;
  if (energyError) return <>Error!{energyError.message}</>;
  if (!data?.bfGroupCache || !userID || !energyData) return null;

  return (
    <>
      {readLogForStarModal && (
        <SendStarModal
          onSubmit={onSendStar}
          isOpen={readLogForStarModal !== undefined}
          onClose={() => {
            setReadLogForStarModal(undefined);
          }}
          readLogMessageProps={readLogForStarModal}
        />
      )}
      <GetStarEnergyModal
        isOpen={showGetEnergyModal}
        onClose={onCloseGetEnergyModal}
      />
      <BookFriendMessageTemplate
        currentUser={currentUser}
        hasStarEnergy={energyData.getAvailableStarEnergyID != null}
        onChangeTab={onChangeTab}
        bookFriendListProps={{
          data: data && data.bfGroupCache,
          myId: userID,
          prevFetchAll:
            (data && data.bfGroupCache?.digPrevAll && !!bfGroup) || false,
          nextFetchAll:
            (data && data.bfGroupCache?.digNextAll && !!bfGroup) || false,
          onPrevLoading: onPrevLoading as (
            item: BfReadLogFragment
          ) => Promise<void>,
          onNextLoading: onNextLoading as (
            item: BfReadLogFragment
          ) => Promise<void>,
          onReaction,
          firstItemIndex,
          onWantReadBook,
          readLogIdListForSenseiMessage,
          onCompleteShowSenseiMessage,
          onOpenSendStarModal: (item: SimplifiedReadLogMessageProps) =>
            setReadLogForStarModal(item),
          canSendStar:
            !sendStarLoading && energyData.getAvailableStarEnergyID != null,
          readLogIdForSendStarMessage: readLogInfoForSendStarModal?.readLogId,
          sendStarMessageProps: {
            readingTechniqueType:
              readLogInfoForSendStarModal?.readingTechniqueType,
            onCompleted: () => setReadLogInfoForSendStarModal(undefined),
          },
        }}
      />
    </>
  );
};
