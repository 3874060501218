import { useCallback, useEffect, useState } from "react";
import {
  TutorialFlag,
  TutorialFlagKey,
  useSetTutorialFlagMutation,
  useTutorialFlagsQuery,
} from "~/generated/graphql";

type tutorialFlagValue<T> = T | undefined;

const updateFlags = (
  flagValue: TutorialFlag,
  currentFlags: tutorialFlagValue<TutorialFlag[]>
): tutorialFlagValue<TutorialFlag[]> => {
  if (!currentFlags) {
    return [flagValue];
  }

  const newFlags = currentFlags.slice();
  const isEqualKey = (element: TutorialFlag) => element.key === flagValue.key;

  if (newFlags.findIndex(isEqualKey) !== -1) {
    newFlags.splice(newFlags.findIndex(isEqualKey), 1, flagValue);
  } else {
    newFlags.splice(1, 0, flagValue);
  }

  return newFlags;
};

type Params = {
  userId?: number;
};

export const useTutorialFlags = ({
  userId,
}: Params): {
  tutorialFlags: TutorialFlag[] | undefined;
  getTutorialFlag: (value: TutorialFlagKey) => boolean;
  saveTutorialFlags: (value: TutorialFlagKey) => void;
} => {
  const [setTutorialFlagMutation] = useSetTutorialFlagMutation({});
  const [tutorialFlags, setTutorialFlags] = useState<
    TutorialFlag[] | undefined
  >(undefined);

  const { data } = useTutorialFlagsQuery({
    variables: {
      tutorialFlagKeys: Object.values(TutorialFlagKey),
    },
  });

  useEffect(() => {
    data && setTutorialFlags(data.getTutorialFlags);
  }, [data]);

  const getTutorialFlag = useCallback(
    (value: TutorialFlagKey) => {
      const isEqualKey = (element: TutorialFlag) => element.key === value;
      if (
        tutorialFlags &&
        tutorialFlags.findIndex(isEqualKey) !== -1 &&
        tutorialFlags[tutorialFlags?.findIndex(isEqualKey)].value
      ) {
        return true;
      }
      return false;
    },
    [tutorialFlags, userId]
  );

  const saveTutorialFlags = useCallback(
    (value: TutorialFlagKey) => {
      if (!userId || !data) return;

      setTutorialFlags(updateFlags({ key: value, value: true }, tutorialFlags));

      setTutorialFlagMutation({
        variables: {
          input: {
            key: value,
          },
        },
      });
    },
    [tutorialFlags, userId]
  );
  return { tutorialFlags, getTutorialFlag, saveTutorialFlags };
};
