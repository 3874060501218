import * as React from "react";
import { GetMyRecordQuery } from "~/generated/graphql";
import { usePostReadLogState } from "../usePostReadLogState";
import { IndicateBadgeTemplate } from "~/components/templates/PostReadLog/IndicateBadge";
import { NewRecords } from "~/components/organisms/modals/GetBadgeModal/hooks";

export const IndicateBadge: React.FC = () => {
  const { state, dispatch } = usePostReadLogState();

  const handleNext = React.useCallback(() => {
    dispatch({
      type: "setFlow",
      payload: "indicateFeedback",
    });
  }, []);

  const myNewRecords = React.useMemo(() => {
    if (state.postReadLogResult == undefined) {
      handleNext();
      return null;
    } else {
      return {
        readBookSum: state.postReadLogResult.account.general.readBookSum,
        readCharSum: state.postReadLogResult.account.general.readCharSum,
        maxContinueReadLogDays:
          state.postReadLogResult.account.general.maxContinueReadLogDays,
      };
    }
  }, [state.postReadLogResult]);

  const tmp = localStorage.getItem("TemporarilyStoredReadingRecords");
  const oldRecords: GetMyRecordQuery | null = React.useMemo(() => {
    return tmp ? JSON.parse(tmp) : null;
  }, [tmp]);

  const myOldRecords = React.useMemo(() => {
    return {
      readBookSum: oldRecords?.me.general.readBookSum,
      readCharSum: oldRecords?.me.general.readCharSum,
      maxContinueReadLogDays: oldRecords?.me.general.maxContinueReadLogDays,
    };
  }, [oldRecords]);

  if (myNewRecords === null) {
    return <></>;
  } else {
    return (
      <IndicateBadgeTemplate
        onClose={handleNext}
        myNewRecords={myNewRecords as NewRecords}
        myOldRecords={myOldRecords}
        modalOpen={myOldRecords != null}
      />
    );
  }
};
