import * as React from "react";
import { GetMyRecordQuery } from "~/generated/graphql";
import { ALL_BUDGES, AllBadgeType, BadgeItem } from "~/helpers/constants/badge";
import { BadgesSquareCollection } from "~/components/templates/RecordBook/BadgeList/BadgesSquareCollection";
import { useTranslation } from "~/i18n";
import styled from "styled-components";

export type BadgeListProps = {
  data: GetMyRecordQuery;
};

export const BadgeList: React.FC<BadgeListProps> = ({ data }) => {
  const { t } = useTranslation();

  const { nickname, readBookSum, readCharSum, maxContinueReadLogDays } =
    data.me.general;

  const transformBadge = (
    type: AllBadgeType,
    threshold: number
  ): BadgeItem[] => {
    const BADGE = ALL_BUDGES[type];
    return BADGE.THRESHOLDS.map((THRESHOLD) => {
      const isAcquired = threshold >= THRESHOLD;
      const fileName = `${BADGE.IMAGE_FILE_NAME_PREFIX}_${THRESHOLD}.png`;
      const imgSrc = isAcquired
        ? `/img/badge/1x/${fileName}`
        : `/img/badge/1x/m/${fileName}`;

      return { imgSrc, isAcquired };
    });
  };

  return (
    <FlexContent>
      <BadgeContainer>
        <BadgesSquareCollection
          title={t<TranslateKeys>("読んだ文字数")}
          about={t<TranslateKeys>(
            "これまでに{{nickname}}さんが読んだ本の文字数の合計です。",
            { nickname }
          )}
          badges={transformBadge("readCharSum", readCharSum)}
        />
      </BadgeContainer>
      <BadgeContainer>
        <BadgesSquareCollection
          title={t<TranslateKeys>("読んだ本の冊数")}
          about={t<TranslateKeys>(
            "これまでに{{nickname}}さんが読んだ本の数です。",
            { nickname }
          )}
          badges={transformBadge("readBookSum", readBookSum)}
        />
      </BadgeContainer>
      <BadgeContainer>
        <BadgesSquareCollection
          title={t<TranslateKeys>("連続読書日数")}
          about={t<TranslateKeys>(
            "{{nickname}}さんの感想を出した日数の連続記録です。",
            { nickname }
          )}
          badges={transformBadge(
            "maxContinueReadLogDays",
            maxContinueReadLogDays
          )}
        />
      </BadgeContainer>
    </FlexContent>
  );
};

const FlexContent = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const BadgeContainer = styled.div`
  margin-bottom: 30px;
`;
