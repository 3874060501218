import { typeIdentifyEnquete, TypeQuestionAndAnswer } from "~/components/organisms/BookSelectionAdjustment/CharacterAssessment/Enquete/const";

/**
 * 配列からランダムに指定した数の要素を選択して返す関数
 * @param array - 元の配列
 * @param num - 選択する要素の数
 * @returns ランダムに選択された要素の配列
 */
const getRandomElements = <T>(array: T[], num: number): T[] => {
  const shuffled = [...array].sort(() => 0.5 - Math.random());
  return shuffled.slice(0, num);
};

export const getRandomQuestions = (): TypeQuestionAndAnswer[] =>
  getRandomElements(typeIdentifyEnquete, 5);
