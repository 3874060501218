import { css, FlattenSimpleInterpolation } from "styled-components";
import { Space, SpaceStyle } from "./size";

export type PaddingProps = {
  padding?: Space;
  paddingTop?: SpaceStyle;
  paddingBottom?: SpaceStyle;
  paddingRight?: SpaceStyle;
  paddingLeft?: SpaceStyle;
};

export const expandPaddingProps = (
  previousProps: PaddingProps
): PaddingProps => {
  return {
    padding: previousProps.padding,
    paddingTop: previousProps.paddingTop,
    paddingBottom: previousProps.paddingBottom,
    paddingRight: previousProps.paddingRight,
    paddingLeft: previousProps.paddingLeft,
  };
};

export const paddingStyle = (props: PaddingProps): FlattenSimpleInterpolation =>
  css`
    ${props.padding && `padding: ${props.padding};`}
    ${props.paddingTop && `padding-top: ${props.paddingTop};`}
    ${props.paddingBottom && `padding-bottom: ${props.paddingBottom};`}
    ${props.paddingRight && `padding-right: ${props.paddingRight};`}
    ${props.paddingLeft && `padding-left: ${props.paddingLeft};`}
  `;
