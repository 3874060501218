import * as React from "react";
import styled from "styled-components";
import { Icon } from "~/components/atoms/Icon";

type Props = {
  hasUnread?: boolean;
  onClick: () => void;
};

export const ReactionsButton: React.FC<Props> = ({ hasUnread, onClick }) => {
  return (
    <_Container onClick={onClick}>
      <_BellContainer animation={hasUnread}>
        <Icon
          name="bell"
          color="white"
          style={{ width: "25px", height: "25px" }}
        />
      </_BellContainer>
      {hasUnread && <_NotificationDot />}
    </_Container>
  );
};

const _Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.sky.s400};
  color: white;
`;

const _NotificationDot = styled.div`
  position: absolute;
  right: 4px;
  top: 4px;
  background-color: ${({ theme }) => theme.colors.red.r400};

  width: 8px;
  height: 8px;
  border-radius: 4px;

  animation: zoomInZoomOut 1s linear infinite alternate;
  @keyframes zoomInZoomOut {
    from {
      transform: scale(1);
    }

    50% {
      transform: scale(1.5);
    }

    to {
      transform: scale(1);
    }
  }
`;

const _BellContainer = styled.div<{ animation?: boolean }>`
  animation: ${({ animation }) =>
    animation ? "0.5s ease-in 0s infinite alternate ring_bell" : "none"};

  transform-origin: top center;

  @keyframes ring_bell {
    from {
      transform: rotateZ(10deg);
    }

    50% {
      transform: rotateZ(0deg);
    }

    to {
      transform: rotateZ(-10deg);
    }
  }
`;
