import React, { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import { Icon } from "~/components/atoms/Icon";
import { Text } from "~/components/atoms/texts/Text";
import { BaseModal } from "~/components/atoms/wrappers/modal";
import { PrimaryButton } from "~/components/molecules/buttons/PrimaryButton";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import { sendButtonClickEvent } from "~/utils/googleAnalytics/useButtonClickTracking";

type Props = {
  isOpen: boolean;
  onClick: () => void;
  onRetry: (event: React.MouseEvent) => void;
};

export const AccidentarySkipStoryModal: FC<Props> = ({
  isOpen,
  onClick,
  onRetry,
}: Props) => {
  const { t } = useTranslation();
  const { currentUser } = useContext(CurrentUserContext);
  const onClickWithGA = () => {
    onClick();
    sendButtonClickEvent("skip_mission_story", String(currentUser?.general.id));
  };

  const onRetryWithGA = (e: React.MouseEvent) => {
    onRetry(e);
    sendButtonClickEvent(
      "retry_mission_story",
      String(currentUser?.general.id)
    );
  };

  return (
    <BaseModal isOpen={isOpen}>
      <BallonCon>
        <Ballon>
          <Icon
            name="exclamationCircle"
            color="gray"
            style={{ width: "20px", height: "20px" }}
          />
          <Text
            lineHeight="EQ"
            fontSize="XS"
            bold={false}
            fontColor="base.darkGray"
          >
            {t<TranslateKeys>("音声の 再生が 許可されて いないようです。")}
            <br />
            {t<TranslateKeys>("音声を 出して もう一度 見てみてね。")}
          </Text>
        </Ballon>
        <_ButtonWrapper>
          <PrimaryButton
            onClick={onRetryWithGA}
            color="pri"
            text="リトライ"
            lineHeight="EQ"
            fontSize="XL"
            bold={true}
            padding="16px"
          />
          <PrimaryButton
            onClick={onClickWithGA}
            color="pri"
            text="ストーリーをとばす"
            lineHeight="EQ"
            fontSize="XL"
            bold={true}
            padding="16px"
          />
        </_ButtonWrapper>
      </BallonCon>
    </BaseModal>
  );
};

const BallonCon = styled.div`
  ${({ theme }) => {
    return css`
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: 96px;

      width: 335px;
      height: 100%;
      @media screen and ${theme.breakPoints.maxWidthSmallSmartPhone} {
        width: 90vw;
      }
    `;
  }}
`;

const Ballon = styled.div`
  ${({ theme }) => {
    return css`
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 8px;

      background-color: ${theme.colors.base.white};
      border-radius: ${theme.deprecatedBorderRadius.MD};

      width: fit-content;
      padding: 16px;
      z-index: ${theme.zIndex.modal};
      @media screen and ${theme.breakPoints.maxWidthSmallSmartPhone} {
        padding: 16px 40px;
      }
    `;
  }}
`;

const _ButtonWrapper = styled.div`
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.modal};
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;
