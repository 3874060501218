import { MissionStorySetting } from "../type";

const baseDir = "/img/mission/complete_story/story_4";

export const CompleteStory4: MissionStorySetting[] = [
  {
    image: `${baseDir}/scene_1.jpeg`,
    sound: "complete4_scene1_1",
    text: "「『つなげる』は『自分の知っていることとつなげて読む』ワザだったよね」",
    ruby: [
      ...Array(9).fill(""),
      "じ",
      "ぶん",
      "",
      "し",
      ...Array(11).fill(""),
      "よ",
      ...Array(11).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_1.jpeg`,
    sound: "complete4_scene1_2",
    text: "「このシャボン玉みたいなのは、本に関係する、知っていることや、記憶や経験ってことみたいね」",
    ruby: [
      ...Array(7).fill(""),
      "だま",
      ...Array(7).fill(""),
      "ほん",
      "",
      "かん",
      "けい",
      ...Array(3).fill(""),
      "し",
      ...Array(8).fill(""),
      "き",
      "おく",
      "",
      "けい",
      "けん",
    ],
  },
  {
    image: `${baseDir}/scene_1.jpeg`,
    sound: "complete4_scene1_3",
    text: "「じゃあ、これをワザのアイテム、ロープでつなげていこう！」",
    ruby: [...Array(38).fill("")],
  },
  {
    image: `${baseDir}/scene_2.jpeg`,
    sound: "complete4_scene2_1",
    text: "「でも、ロープなんてないか︙︙」\n「あ、あのぉ、うち︙︙ロープ、持ってます︙︙」",
    ruby: [...Array(32).fill(""), "も", ...Array(8).fill("")],
  },
  {
    image: `${baseDir}/scene_2.jpeg`,
    sound: "complete4_scene2_2",
    text: "「え〜〜⁉　なんで持ってるの⁉⁉」\n「うち、心配性だから、モコモコの中に入れて色々持ち歩いてるんですぅ︙︙」\n「すっげーーーー‼」",
    ruby: [
      ...Array(9).fill(""),
      "も",
      ...Array(12).fill(""),
      "しん",
      "ぱい",
      "しょう",
      ...Array(9).fill(""),
      "なか",
      "",
      "い",
      ...Array(2).fill(""),
      "いろ",
      "いろ",
      "も",
      "",
      "ある",
      ...Array(21).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_3.jpeg`,
    sound: "complete4_scene3_1",
    text: "空に浮かぶ本の船に乗りながら、みんなで本に書かれていることをじっくり読んでいきます。",
    ruby: [
      "そら",
      "",
      "う",
      ...Array(2).fill(""),
      "ほん",
      "",
      "ふね",
      "",
      "の",
      ...Array(9).fill(""),
      "ほん",
      "",
      "か",
      ...Array(12).fill(""),
      "よ",
      ...Array(7).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_3.jpeg`,
    sound: "complete4_scene3_2",
    text: "そして、目を閉じながら、自分の知っていることとつなげていきます。",
    ruby: [
      ...Array(4).fill(""),
      "め",
      "",
      "と",
      ...Array(5).fill(""),
      "じ",
      "ぶん",
      "",
      "し",
      ...Array(16).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_3.jpeg`,
    sound: "complete4_scene3_3",
    text: "「ここに出てくる主人公は、とっても優しくて穏やかでベジみたいだね」",
    ruby: [
      ...Array(4).fill(""),
      "で",
      ...Array(3).fill(""),
      "しゅ",
      "じん",
      "こう",
      ...Array(6).fill(""),
      "やさ",
      ...Array(3).fill(""),
      "おだ",
      ...Array(11).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_4.jpeg`,
    sound: "complete4_scene4_1",
    text: "ベジタライオンの姿が浮かんでいるシャボン玉がありました。\nさっそく、ロープでつかまえます。",
    ruby: [
      ...Array(8).fill(""),
      "すがた",
      "",
      "う",
      ...Array(9).fill(""),
      "だま",
      ...Array(24).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_4.jpeg`,
    sound: "complete4_scene4_2",
    text: "ロープを引っ張りながら、ペンギンせんぱいは初めてベジタライオンと出会ったときのことを思い出していました。",
    ruby: [
      ...Array(4).fill(""),
      "ひ",
      "",
      "ぱ",
      ...Array(14).fill(""),
      "はじ",
      ...Array(10).fill(""),
      "で",
      "あ",
      ...Array(8).fill(""),
      "おも",
      "",
      "だ",
      ...Array(7).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_5.jpeg`,
    sound: "complete4_scene5_1",
    text: "「この花のイメージは、むかし本で見たゴッホの『ひまわり』みたいだ！」",
    ruby: [
      ...Array(3).fill(""),
      "はな",
      ...Array(10).fill(""),
      "ほん",
      "",
      "み",
      ...Array(17).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_5.jpeg`,
    sound: "complete4_scene5_2",
    text: "「ぺんちゃんは本で綺麗な景色をたくさん知って、芸術に興味を持つようになったんだよね」\n「そうだったんだ！」",
    ruby: [
      ...Array(7).fill(""),
      "ほん",
      "",
      "き",
      "れい",
      "",
      "け",
      "しき",
      ...Array(5).fill(""),
      "し",
      ...Array(3).fill(""),
      "げい",
      "じゅつ",
      "",
      "きょう",
      "み",
      "",
      "も",
      ...Array(23).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_6.jpeg`,
    sound: "complete4_scene6",
    text: "どんどん、どんどん、ロープでつなげていきます。",
    ruby: [...Array(23).fill("")],
  },
  {
    image: `${baseDir}/scene_7.jpeg`,
    sound: "complete4_scene7_1",
    text: "そんなとき、本の中には、ペンギンせんぱいの故郷である「南極」が出てきました。",
    ruby: [
      ...Array(6).fill(""),
      "ほん",
      "",
      "なか",
      ...Array(12).fill(""),
      "ふる",
      "さと",
      ...Array(4).fill(""),
      "なん",
      "きょく",
      ...Array(2).fill(""),
      "で",
    ],
  },
  {
    image: `${baseDir}/scene_7.jpeg`,
    sound: "complete4_scene7_2",
    text: "すると︙︙う、うわ〜〜〜〜⁉\n目の前にとっっても大きなシャボン玉が現れたではありませんか。",
    ruby: [
      ...Array(15).fill(""),
      "め",
      "",
      "まえ",
      ...Array(6).fill(""),
      "おお",
      ...Array(6).fill(""),
      "だま",
      "",
      "あらわ",
      ...Array(11).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_8.jpeg`,
    sound: "complete4_scene8_1",
    text: "「みんなの『つなげる』力で、ロープをもっと大きく長くしよう！」\nみんなでロープをつなげていきます。",
    ruby: [
      ...Array(11).fill(""),
      "ちから",
      ...Array(9).fill(""),
      "おお",
      ...Array(2).fill(""),
      "なが",
      ...Array(24).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_8.jpeg`,
    sound: "complete4_scene8_2",
    text: "力を合わせて、思いっきり重くて長〜いロープを、、\nぶんぶん、とぉりゃっ！！",
    ruby: [
      "ちから",
      "",
      "あ",
      ...Array(4).fill(""),
      "おも",
      ...Array(4).fill(""),
      "おも",
      ...Array(2).fill(""),
      "なが",
      ...Array(21).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_9.jpeg`,
    sound: "complete4_scene9",
    text: "よいしょ、よいしょ。\nがんばってつなげた大きなシャボン玉をちょうど本の船の上に引っ張ってきたとき︙︙",
    ruby: [
      ...Array(20).fill(""),
      "おお",
      ...Array(6).fill(""),
      "だま",
      ...Array(5).fill(""),
      "ほん",
      "",
      "ふね",
      "",
      "うえ",
      "",
      "ひ",
      "",
      "ぱ",
      ...Array(8).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_10.jpeg`,
    sound: "complete4_scene10_1",
    text: "ぱんっ！！シャボン玉が弾けて、またたく間にキラキラと光に包まれました。",
    ruby: [
      ...Array(9).fill(""),
      "だま",
      "",
      "はじ",
      ...Array(7).fill(""),
      "ま",
      ...Array(6).fill(""),
      "ひかり",
      "",
      "つつ",
      ...Array(6).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_10.jpeg`,
    sound: "complete4_scene10_2",
    text: "その瞬間、ペンギンせんぱいは、ハッと、南極でのことを思い出しました。",
    ruby: [
      ...Array(2).fill(""),
      "しゅん",
      "かん",
      ...Array(15).fill(""),
      "なん",
      "きょく",
      ...Array(5).fill(""),
      "おも",
      "",
      "だ",
      ...Array(5).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_11.jpeg`,
    sound: "complete4_scene11_1",
    text: "真っ白な氷に囲まれた南極で、本を読んでいろんなきれいな景色を知ったこと、美しいものに憧れていろんな創作をしてきたこと、",
    ruby: [
      "ま",
      "",
      "しろ",
      "",
      "こおり",
      "",
      "かこ",
      ...Array(3).fill(""),
      "なん",
      "きょく",
      ...Array(2).fill(""),
      "ほん",
      "",
      "よ",
      ...Array(10).fill(""),
      "け",
      "しき",
      "",
      "し",
      ...Array(5).fill(""),
      "うつく",
      ...Array(5).fill(""),
      "あこが",
      ...Array(6).fill(""),
      "そう",
      "さく",
      ...Array(8).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_11.jpeg`,
    sound: "complete4_scene11_2",
    text: "本で知った景色を初めて自分の目で見たこと︙︙",
    ruby: [
      "ほん",
      "",
      "し",
      ...Array(2).fill(""),
      "け",
      "しき",
      "",
      "はじ",
      ...Array(2).fill(""),
      "じ",
      "ぶん",
      "",
      "め",
      "",
      "み",
      ...Array(5).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_12.jpeg`,
    sound: "complete4_scene12",
    text: "その、初めて見た美しいオーロラの景色が、目の前に広がっていました。",
    ruby: [
      ...Array(3).fill(""),
      "はじ",
      ...Array(2).fill(""),
      "み",
      "",
      "うつく",
      ...Array(7).fill(""),
      "け",
      "しき",
      ...Array(2).fill(""),
      "め",
      "",
      "まえ",
      "",
      "ひろ",
      ...Array(8).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_13.jpeg`,
    sound: "complete4_scene13_1",
    text: "「︙︙ぼく、大事なことを思い出したよ︙︙もっと美しい世界が見たい︙︙みんなともっと冒険していきたい︙︙！」",
    ruby: [
      ...Array(6).fill(""),
      "だい",
      "じ",
      ...Array(4).fill(""),
      "おも",
      "",
      "だ",
      ...Array(8).fill(""),
      "うつく",
      ...Array(2).fill(""),
      "せ",
      "かい",
      "",
      "み",
      ...Array(11).fill(""),
      "ぼう",
      "けん",
      ...Array(10).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_13.jpeg`,
    sound: "complete4_scene13_2",
    text: "みんなと一緒に見たこの景色を、ペンギンせんぱいはきっと忘れません。そして、みんなにとっても大切な大切な思い出になりました。",
    ruby: [
      ...Array(4).fill(""),
      "いっ",
      "しょ",
      "",
      "み",
      ...Array(3).fill(""),
      "け",
      "しき",
      ...Array(14).fill(""),
      "わす",
      ...Array(17).fill(""),
      "たい",
      "せつ",
      "",
      "たい",
      "せつ",
      "",
      "おも",
      "",
      "で",
      ...Array(7).fill(""),
    ],
  },
];
