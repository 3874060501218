import styled, { css } from "styled-components";

export const BookItemContainerInterpolation = css<{ columnsNumber: number }>`
  display: block;
  width: ${(props) => (100 - 6 * props.columnsNumber) / props.columnsNumber}%;
  margin: 3%;
  opacity: 0.9;
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
  &:active,
  &:focus {
    opacity: 1;
  }
`;

export const BookCoverImgInterpolation = css`
  width: 100%;
  border-radius: ${({ theme }) => theme.deprecatedBorderRadius.XS};
`;

export const BookCoverContainer = styled.div`
  width: 100%;
  position: relative;
  z-index: 0;
`;
