import React, { VFC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Slider } from "~/components/atoms/forms/Slider";
import { Heading1 } from "~/components/atoms/texts/Heading1";
import { Wrapper } from "~/components/templates/Common";
import { RecommendTutorialProps } from "..";
import { TutorialStyle as s } from "../style";
import { NoteBox } from "../ui/NoteBox";

export type StartScreenProps = RecommendTutorialProps;

export const StartScreen: VFC<StartScreenProps> = ({
  handleChangeStep,
  handleGoNext,
  step,
  STEP_MAX,
}) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <SliderWrapper>
        <Slider
          min={0}
          max={STEP_MAX}
          type="range"
          value={step}
          onChange={(event) => handleChangeStep(Number(event.target.value))}
        />
      </SliderWrapper>
      <s.ImageContainer>
        <Heading1
          fontSize="SUPERL"
          lineHeight="EQ"
          bold
          style={{ textAlign: "center" }}
        >
          選書の手引き
        </Heading1>
        <Container>
          <img src="/img/sensei/standup_3.svg" />
        </Container>
      </s.ImageContainer>
      <s.Stack>
        <NoteBox text={t<TranslateKeys>("保護者のかたと 一緒に見てね！")} />
        <NoteBox
          text={
            "1回目の選書をお送りしました！\n「2回目の選書をもらうまでに、何をすれば良いか？」をご説明します。"
          }
        />
      </s.Stack>
      <s.BottomButton onClick={handleGoNext}>次へ</s.BottomButton>
    </Wrapper>
  );
};

const SliderWrapper = styled.div`
  padding-top: 32px;
`;

const Container = styled.div`
  padding: 60px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 400px) {
    padding: 24px 0;
  }
`;
