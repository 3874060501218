import moment from "moment";
import React, { useState } from "react";
import styled from "styled-components";
import { FeedbackMessage } from "./FeedbackMessage";
import { ReadStatusMessage } from "./ReadStatusMessage";
import { PromiseMessage } from "./PromiseMessage";
import { ReadLogResult } from "./types";
import {
  StandUpImageType,
  standUpImageValue,
} from "~/helpers/constants/imagePath";
import { SelectNextBookImgListWithoutOnClickProps } from "./PromiseMessage/types";
import { PromiseDate, PromiseStatus } from "~/generated/graphql";
import { BottomModal } from "~/components/atoms/wrappers/BottomModal";

type Props = {
  isOpen?: boolean;
  readLogResult: ReadLogResult;
  selectNextBookImgListWithoutOnClickProps: SelectNextBookImgListWithoutOnClickProps;

  onExit: () => void;
  onMakePromise: (
    date: string,
    chooseNext: boolean,
    nextISBN?: string
  ) => Promise<boolean>;
};

type Section = "Feedback" | "ReadStatus" | "Promise";

export const ReadLogFeedbackModal: React.FC<Props> = ({
  readLogResult: { account, promise, readLog, wasWantReadBook },
  onExit,
  onMakePromise,
  selectNextBookImgListWithoutOnClickProps,
  isOpen = false,
}) => {
  const [section, setSection] = useState<Section>("Feedback");
  const [teacherImage, setTeacherImage] = useState<StandUpImageType>("SMILE");
  const parsedDueDate = parseDueDate(promise?.dueDate);

  return (
    <BottomModal open={isOpen}>
      <_Wrapper>
        <div className="balloon">
          {section === "Feedback" && (
            <FeedbackMessage
              wasWantReadBook={wasWantReadBook}
              promise={promise}
              translationParams={{
                user: account.general.nickname,
                title: readLog.book.title ? readLog.book.title : "",
                dueDateMonth: parsedDueDate.month,
                dueDateDay: parsedDueDate.day,
              }}
              onNext={() => {
                setSection("ReadStatus");
                setTeacherImage("NORMAL");
              }}
            />
          )}
          {section === "ReadStatus" && (
            <ReadStatusMessage
              readStatus={readLog.status}
              impression={readLog.book.impression}
              onNext={() => {
                setSection("Promise");
                setTeacherImage("NORMAL");
                if (account.ongoingPromise?.status === PromiseStatus.Unsolved) {
                  onExit();
                }
              }}
            />
          )}
          {section === "Promise" && (
            <PromiseMessage
              selectNextBookImgListWithoutOnClickProps={
                selectNextBookImgListWithoutOnClickProps
              }
              onExit={onExit}
              onMakePromise={(
                date: PromiseDate,
                chooseNext: boolean,
                nextISBN: string
              ) => {
                return new Promise((resolve) => {
                  onMakePromise(date, chooseNext, nextISBN)
                    .then((res) => {
                      if (res) {
                        setTeacherImage("FIRE");
                      }
                      resolve(res);
                    })
                    .catch(() => resolve(false));
                });
              }}
              translationParams={{
                user: account.general.nickname,
              }}
              onChangeImage={setTeacherImage}
            />
          )}
        </div>
        <div className="balloon-tail">
          <div className="balloon-tail-image" />
        </div>
        <div className="sensei">
          <div
            className="sensei-image"
            style={{
              backgroundImage: `url(/img/sensei/standup_${standUpImageValue(
                teacherImage
              )}.svg)`,
              maxHeight: "12svh",
            }}
          />
        </div>
      </_Wrapper>
    </BottomModal>
  );
};

const _Wrapper = styled.div`
  overflow: scroll;
  max-width: 600px;
  width: calc(100vw - 60px);
  height: fit-content;
  min-height: 100%;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  padding: 50px 0;

  .balloon {
    border-radius: 16px;
    background-color: white;
    padding: 20px;
  }

  .balloon-tail {
    display: flex;
    justify-content: center;

    .balloon-tail-image {
      width: 10px;
      height: 10px;
      transform: translateY(-1px); // 吹き出し本体との間に隙間ができるのを防ぐ
      background-image: url(/img/misc/balloon_tail.svg);
      background-repeat: no-repeat;
      background-position: center top;
      background-size: contain;
    }
  }

  .sensei {
    display: flex;
    justify-content: center;
    padding-top: 20px;

    .sensei-image {
      width: 80px;
      height: 120px;
      background-repeat: no-repeat;
      background-position: center bottom;
      background-size: contain;
    }
  }
`;

const parseDueDate = (
  dueDate?: string
): { month: number; day: number; dayName: string } => {
  if (!dueDate) {
    return { month: 0, day: 0, dayName: "" };
  }
  const date = moment(dueDate);
  return {
    month: date.month(),
    day: date.date(),
    dayName: date.format("ddd"),
  };
};
