import React from "react";
import styled from "styled-components";
import { Text } from "../../texts/Text";
import { AllColorTheme } from "~/styles/design_token/color";

export type LabelProps = {
  children: React.ReactNode;
  label: string;
  bold?: boolean;
  color?: AllColorTheme;
  htmlFor?: string;
};

export const Label: React.FC<LabelProps> = ({
  children,
  label,
  bold,
  color,
  htmlFor,
}) => (
  <StyledLabel htmlFor={htmlFor}>
    <Para
      fontSize={"SM"}
      lineHeight={"EQ"}
      bold={bold}
      fontColor={color ? color : "tex.t400"}
    >
      {label}
    </Para>
    {children}
  </StyledLabel>
);

const Para = styled(Text)`
  margin-bottom: ${({ theme }) => theme.size.XS};
`;

const StyledLabel = styled.label`
  width: 100%;
`;
