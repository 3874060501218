import React from "react";
import styled from "styled-components";
import { Icon } from "~/components/atoms/Icon";
import { Stack } from "~/components/atoms/layout/Stack";
import { Row } from "~/components/atoms/layout/Row";
import { Text } from "~/components/atoms/texts/Text";
import { ReadingTechniqueType } from "~/generated/graphql";
import {
  ColorOfReadingTechnique,
  techniqueLabel,
} from "~/components/organisms/bookFriendMessage/BookFriendMessageListItem/SendStarModal/const";

export type ReceivedStarItemProps = {
  readingTechniqueType: ReadingTechniqueType;
  num: number;
};

export const ReceivedStarItem: React.FC<ReceivedStarItemProps> = ({
  readingTechniqueType,
  num,
}) => {
  return (
    <Stack rowGap="4px" style={{ width: "100%" }}>
      <Row columnGap="4px">
        <Icon
          name="star"
          style={{ color: ColorOfReadingTechnique[readingTechniqueType] }}
        />
        <Text lineHeight="MD" fontSize="XS" bold={false}>
          {techniqueLabel[readingTechniqueType]}
        </Text>
      </Row>
      <div style={{ display: "flex", justifyContent: "right" }}>
        <Text lineHeight="EQ" fontSize="XS" bold={false}>
          <_NumSpan>{num}</_NumSpan>こ
        </Text>
      </div>
    </Stack>
  );
};

const _NumSpan = styled.span`
  color: ${({ theme }) => theme.colors.semantic.secondary.plus1};
  font-size: ${({ theme }) => theme.fontSize.XL};
  font-weight: bold;
  line-height: ${({ theme }) => theme.lineHeight.EQ};
`;
