import { config } from "../config";

declare global {
  interface Window {
    gtag: Gtag.Gtag;
  }
}

// configに設定されたGA_MEASUREMENT_IDでgtag.jsを初期化する
export const initGtag = (): void => {
  if (config.GA_MEASUREMENT_ID) {
    document.getElementById("gtag1")?.remove();

    const script1 = document.createElement("script");
    script1.id = "gtag1";
    script1.src = `https://www.googletagmanager.com/gtag/js?id=${config.GA_MEASUREMENT_ID}`;
    script1.async = true;
    document.head.appendChild(script1);

    window.gtag("config", config.GA_MEASUREMENT_ID);
  }
};

export const googleAnalyticsMove = (path: string): void => {
  if (config.GA_MEASUREMENT_ID != null) {
    window.gtag("event", "page_view", {
      page_path: path,
    });
  }
};
