import { makeVar, useReactiveVar } from "@apollo/client";
import { useCallback, useEffect, useMemo, useState } from "react";
import { usePreference } from "~/store/preference/usePreference";
import { modifyVolume } from "~/utils/hooks/useSnd";
import { getAudioContext } from "~/utils/hooks/useAudio";
import { soundMap } from "./soundMap";

export type SoundKey = keyof typeof soundMap;

type UseAudioParams = {
  preload: SoundKey[];
};

type LoadingStatus = "loading" | "loaded";

const _buffers = makeVar<Record<string, AudioBuffer>>({});
const _loadingStatus = makeVar<Record<string, LoadingStatus>>({});

export const useAudio = ({
  preload,
}: UseAudioParams): {
  play: (
    key: SoundKey,
    delay?: number,
    onEnded?: () => void,
    endDelay?: number
  ) => (() => void) | undefined;
  loaded: boolean;
  buffers: Record<string, AudioBuffer>;
} => {
  const { preference } = usePreference();
  const loadingStatus = useReactiveVar(_loadingStatus);
  const buffers = useReactiveVar(_buffers);
  // NOTE: 2つのストーリーを連続で見ようとした時にload前にloadedがtrueにならないようにする
  const [isStartLoading, setIsStartLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsStartLoading(true);
    preload.forEach((p) => {
      if (loadingStatus[p] || !soundMap[p]) {
        return;
      }
      loadingStatus[p] = "loading";

      const src = soundMap[p].src;
      const request = new XMLHttpRequest();
      request.open("GET", src, true);
      request.responseType = "arraybuffer";
      request.onload = () => {
        const ctx = getAudioContext();
        const res = request.response;
        ctx.decodeAudioData(res, (buf) => {
          buffers[p] = buf;

          const _ls: Record<string, LoadingStatus> = {
            ...loadingStatus,
            [p]: "loaded",
          };
          _loadingStatus(_ls);
          loadingStatus[p] = "loaded";
        });
      };

      request.send();
    });
  }, [preload, loadingStatus, buffers]);

  const play = useCallback(
    (key: SoundKey, delay = 0, onEnded?: () => void, endDelay = 2000) => {
      console.log("play", key);
      if (!preference?.sndMuted && buffers[key]) {
        console.log("play2", key, buffers[key]);
        const ctx = getAudioContext();
        const source = ctx.createBufferSource();
        source.buffer = buffers[key];

        const gainNode = ctx.createGain();
        const level = soundMap[key]?.level || 1.0;
        const volume = level * modifyVolume(preference?.sndVolume || 10);
        gainNode.gain.value = volume;
        gainNode.connect(ctx.destination);

        source.connect(gainNode);
        if (typeof ctx.currentTime !== "number" || !isFinite(ctx.currentTime)) {
          return () => {
            console.error("fail to start audio device");
          };
        }

        source.start(ctx.currentTime + delay);

        const _onEnded = () => {
          setTimeout(() => {
            onEnded && onEnded();
          }, endDelay);
        };

        source.onended = _onEnded;

        return () => {
          source.stop(ctx.currentTime);
        };
      }
    },
    [preference, buffers]
  );

  const loaded = useMemo(() => {
    if (!isStartLoading) {
      return false;
    }
    const _values = Object.values(loadingStatus);
    return _values.length > 0 && _values.every((v) => v === "loaded");
  }, [loadingStatus, isStartLoading]);

  return { play, loaded, buffers };
};
