import React, { useState } from "react";
import styled from "styled-components";
import { RecommendBookshelf } from "~/components/organisms/Bookshelf/RecommendBookshelf";
import { config } from "../../../../../config";
import { BookshelfTab } from "./partial/BookshelfTab";
import { YomimashokaContainer } from "../YomimashokaBookshelf/container";
import {
  useGetMyCurrentRecommendQuery,
  useGetFirstBookRecommendsQuery,
} from "~/generated/graphql";
import { usePreference } from "~/store/preference/usePreference";
import { PresentBoxListItem } from "../../List/RecommendList/ListItem/PresentBoxListItem";
import { Loading } from "~/components/atoms/Loading";
import { BookshelfCurrentRecommendFragment } from "~/generated/graphql";
import { AllRecommendBookshelf } from "../AllRecommendBookshelf/container";

export const CURRENT_TAB_KEY = "CURRENT_TAB_KEY";

export type BookshelfType = "yomimashoka" | "recommend" | "all";

export const useHistoryBookshelf = (
  firstTab: BookshelfType
): { presentBox: React.ReactNode; bookshelf: React.ReactNode } => {
  const { preference, savePreference } = usePreference();
  const {
    loading,
    error: myCurrentRecommendError,
    data,
  } = useGetMyCurrentRecommendQuery({
    variables: {
      begin: 0,
      end: config.MID_LIST_LENGTH,
    },
    fetchPolicy: "no-cache",
  });

  // NOTE: PWA化した際に初回レコメンドでワクワクレコメンドが出ないようにする
  useGetFirstBookRecommendsQuery({
    variables: {
      begin: 0,
      end: config.MID_LIST_LENGTH,
    },
    onCompleted: (result) => {
      if (!preference?.recommendListLatestRecommendId) {
        savePreference({
          recommendListLatestRecommendId: Math.max(
            ...result.getFirstBookRecommends.map((recommend) => recommend.id)
          ),
        });
      }
    },
  });

  const recommends: BookshelfCurrentRecommendFragment[] = React.useMemo(
    () => data?.me.recommends.get || [],
    [data]
  );
  const [updatedRecommendId, setUpdatedRecommendId] = useState<
    number | undefined
  >(undefined);

  const latestRecommendId = React.useMemo(() => {
    if (updatedRecommendId) return updatedRecommendId;
    return preference?.recommendListLatestRecommendId || 0;
  }, [updatedRecommendId, preference]);

  const onConfirmedNewAllRecommend = () => {
    if (recommends.length > 0) {
      const ids = recommends.map((recommend) => recommend.id);
      const latestRecommendId = Math.max(...ids);
      setUpdatedRecommendId(latestRecommendId);
      savePreference(
        { recommendListLatestRecommendId: latestRecommendId },
        { updateCurrentValue: false } // localStorageには保存するがpreferenceに反映されるのは次回ロード時
      );
    }
  };

  // 新しく追加されたおすすめ本とすでに確認済みのおすすめ本一覧を分離する
  const recommendItems = recommends.reduce(
    (result, recommend) => {
      return latestRecommendId < recommend.id
        ? { ...result, newItems: [...result.newItems, recommend] }
        : {
            ...result,
            confirmedItems: [...result.confirmedItems, recommend],
          };
    },
    { newItems: [], confirmedItems: [] }
  );

  const newItems = recommendItems.newItems;

  // 現状idはauto incrementのため比較可能
  const showPresentBox = React.useMemo(() => {
    return 0 < newItems.length;
  }, [latestRecommendId, newItems]);

  const [bookshelfType, setBookshelfType] =
    React.useState<BookshelfType>(firstTab);

  if (loading || !data) return { presentBox: <Loading />, bookshelf: <></> };
  if (myCurrentRecommendError)
    return {
      presentBox: <>error!{myCurrentRecommendError.message}</>,
      bookshelf: <></>,
    };

  return {
    presentBox: (
      <>
        {showPresentBox && (
          <PresentBoxListItem
            recommends={newItems}
            onCheckedAll={onConfirmedNewAllRecommend}
          />
        )}
      </>
    ),
    bookshelf: (
      <HistoryBookShelfContainer id="history_bookshelf">
        <BookshelfTab
          value={bookshelfType}
          onChange={(value) => {
            setBookshelfType(value);
            try {
              sessionStorage.setItem(CURRENT_TAB_KEY, String(value));
            } catch (e) {
              console.error(e);
            }
          }}
        />
        {bookshelfType === "recommend" ? (
          <RecommendBookshelf
            loading={loading}
            error={myCurrentRecommendError}
            data={data}
            baseItems={recommends}
            latestRecommendId={latestRecommendId}
          />
        ) : bookshelfType === "all" ? (
          <AllRecommendBookshelf />
        ) : (
          <YomimashokaContainer />
        )}
      </HistoryBookShelfContainer>
    ),
  };
};

const HistoryBookShelfContainer = styled.div`
  min-height: 80vh;
`;
