import * as React from "react";
import styled from "styled-components";
import { useTranslation } from "~/i18n";
import { Icon } from "~/components/atoms/Icon";
import {
  BfReadLogReactionNoticeItem,
  ReadLogReactionNoticeItem,
  ReadLogReactionType,
} from "./types";
import {
  ColorOfReadingTechnique,
  techniqueLabel,
} from "../bookFriendMessage/BookFriendMessageListItem/SendStarModal/const";
import { theme } from "~/styles/theme";
import { Row } from "~/components/atoms/layout/Row";
import { Text } from "~/components/atoms/texts/Text";
import { Stack } from "~/components/atoms/layout/Stack";

type Props = {
  items: ReadLogReactionNoticeItem[];
  unread?: boolean;
};

const reactionTypeLabel: Record<ReadLogReactionType, string> = {
  LIKE: "いいね",
  READ: "よんだ",
};

export const ReactionItem: React.FC<Props> = ({ items, unread }) => {
  const { t } = useTranslation();
  const itemType = items[0].__typename;

  const getUserName = (user: BfReadLogReactionNoticeItem["reactionUser"]) =>
    user.__typename === "User"
      ? user.nickname + "さん"
      : "おやすみちゅうのともだち";

  const renderReactionText = () => {
    switch (itemType) {
      case "BfReadLogReactionNoticeItem":
        return (
          <_Text fontSize="XS" lineHeight="MD" bold={false}>
            {items.length > 2 ? (
              <_UserName>
                {getUserName(items[0].reactionUser)}、他
                {items.length - 1}人
              </_UserName>
            ) : (
              items.map((item: BfReadLogReactionNoticeItem, index) => (
                <_UserName key={index}>
                  {index > 0 && "、"}
                  {getUserName(item.reactionUser)}
                </_UserName>
              ))
            )}
            から{reactionTypeLabel[items[0].reactionType]}をもらったよ！
          </_Text>
        );
      case "TeacherReadLogReactionNoticeItem":
        return (
          <_Text fontSize="XS" lineHeight="MD" bold={false}>
            <_UserName>{t<TranslateKeys>("ヨンデミー先生")}</_UserName>
            からいいねをもらったよ！
          </_Text>
        );
      case "BfReadLogStarNoticeItem":
        return (
          <_Text fontSize="XS" lineHeight="MD" bold={false}>
            <_UserName>{getUserName(items[0].reactionUser)}</_UserName>から「
            {techniqueLabel[items[0].readingTechniqueType]}
            」のスターをもらったよ！
          </_Text>
        );
      default:
        return null;
    }
  };

  const renderIcon = () => {
    switch (itemType) {
      case "BfReadLogReactionNoticeItem":
        return items[0].reactionType === ReadLogReactionType.Like ? (
          <_Icon
            name="heart"
            style={{ color: theme.colors.primitive.red.r400 }}
          />
        ) : (
          <_Icon
            name="grinWink"
            style={{ color: theme.colors.primitive.orange.o400 }}
          />
        );
      case "TeacherReadLogReactionNoticeItem":
        return (
          <_Icon
            name="heart"
            style={{ color: theme.colors.primitive.red.r400 }}
          />
        );
      case "BfReadLogStarNoticeItem":
        return (
          <_Icon
            name="star"
            style={{
              color: ColorOfReadingTechnique[items[0].readingTechniqueType],
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <_Container unread={unread}>
      <Row alignItems="flex-start">
        {renderIcon()}
        <Stack rowGap="4px">
          {renderReactionText()}
          <Stack rowGap="2px">
            <_BookTitle style={{ display: "flex", alignItems: "flex-start" }}>
              <Icon
                name="book"
                color="gray"
                style={{ fontSize: "13px", paddingTop: "0.1em" }}
              />
              <_GrayText
                fontSize="XS"
                lineHeight="MD"
                bold={false}
                lineClamp={2}
              >
                {items[0].readLog.book.title}
              </_GrayText>
            </_BookTitle>
            <_GrayText fontSize="XS" lineHeight="MD" bold={false} lineClamp={3}>
              {items[0].readLog.review}
            </_GrayText>
          </Stack>
        </Stack>
      </Row>
    </_Container>
  );
};

const _Text = styled(Text)`
  color: ${({ theme }) => theme.colors.semantic.text.body};
`;

const _GrayText = styled(Text)<{ lineClamp: number }>`
  color: ${({ theme }) => theme.colors.semantic.text.label};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${({ lineClamp }) => lineClamp};
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
`;

const _UserName = styled.span`
  font-weight: bold;
`;

const _Icon = styled(Icon)`
  font-size: ${({ theme }) => theme.fontSize.XXXL};
`;

const _BookTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 2px;
`;

const _Container = styled.div<{ unread?: boolean }>`
  background-color: ${({ unread }) =>
    unread ? theme.colors.primitive.yellow.y100 : "white"};
  line-height: 160%;

  position: relative;
  padding: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.semantic.layout.border};
`;
