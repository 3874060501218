import React from "react";
import styled, { css } from "styled-components";

type Props = {
  children: React.ReactNode;
  padding?: "LG" | "MD";
};

export const BalloonWithTail: React.FC<Props> = ({
  children,
  padding = "MD",
}) => {
  return (
    <BallonCon padding={padding}>
      <div className="balloon">{children}</div>
      <div className="balloon-tail"></div>
    </BallonCon>
  );
};

const BallonCon = styled.div<{ padding: string }>`
  //レスポンシブ対応
  @media screen and ${({ theme }) => theme.breakPoints.maxWidthSmallPC} {
    width: 50vw;
  }
  @media screen and ${({ theme }) => theme.breakPoints.maxWidthSmartPhone} {
    width: 70vw;
  }
  @media screen and ${({ theme }) =>
      theme.breakPoints.maxWidthSmallSmartPhone} {
    width: 90vw;
  }
  @media screen and ${({ theme }) => theme.breakPoints.minWidthSmallPC} {
    width: 400px;
  }

  .balloon {
    ${({ theme, padding }) => {
      switch (padding) {
        case "LG":
          return css`
            display: flex;
            flex-direction: column;
            row-gap: ${theme.size.M};
            background-color: ${theme.colors.base.white};
            border-radius: ${theme.deprecatedBorderRadius.MD};
            padding: 24px;
          `;
        default:
          return css`
            display: flex;
            flex-direction: column;
            row-gap: ${theme.size.M};
            background-color: ${theme.colors.base.white};
            border-radius: ${theme.deprecatedBorderRadius.MD};
            padding: ${theme.size.XXL} ${theme.size.L} ${theme.size.L}
              ${theme.size.L};
          `;
      }
    }}
  }

  .balloon-tail {
    transform: translateY(-1px);
    width: 100%;
    height: 12px;
    background-image: url(/img/misc/balloon_tail.svg);
    background-position: bottom center;
    background-repeat: no-repeat;
  }
`;
