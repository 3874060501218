import React, { FC } from "react";
import { Stack } from "~/components/atoms/layout/Stack";
import { slide as Menu } from "react-burger-menu";
import { Icon } from "~/components/atoms/Icon";
import { Text } from "~/components/atoms/texts/Text";
import { Row } from "~/components/atoms/layout/Row";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { IMAGE_PATHS } from "~/helpers/constants/imagePath";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import { theme } from "~/styles/theme";
import { config } from "../../../../config";
import { Selectbox } from "~/components/atoms/input/Selectbox";
import { UserIconType, UserPlan } from "~/generated/graphql";
import { userIconImage } from "~/components/organisms/adminMessage/AdminChatMessage";
import { ylReassesmentEnqueteQueryStringRecord } from "~/pages/YLReassesmentEnquete";

type MenuSideBarProps = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};

export const MenuSideBar: FC<MenuSideBarProps> = ({
  isOpen,
  onOpen,
  onClose,
}) => {
  const navigate = useNavigate();
  const { currentUser, userAvailabilities } =
    React.useContext(CurrentUserContext);

  return (
    <Menu
      isOpen={isOpen}
      right
      width="218px"
      onOpen={onOpen}
      onClose={onClose}
      onStateChange={() => {
        return isOpen;
      }}
      customBurgerIcon={false}
    >
      <_MenuContent>
        <_ContentWrapper>
          <_TopContent>
            <Row onClick={onClose} justifyContent="flex-end">
              <Icon
                name="close"
                color="gray"
                style={{ fontSize: theme.fontSize.XL }}
              />
            </Row>
            <Stack marginBottom="16px">
              <_UserInfoWrapper
                onClick={() => {
                  navigate("/mypage?view=profile");
                }}
              >
                <_UserIcon
                  src={
                    userIconImage[
                      currentUser?.general.userIconType || UserIconType.Worm
                    ]
                  }
                />
                <Text
                  fontSize="SM"
                  lineHeight="MD"
                  fontColor="semantic.text.heading"
                  bold
                >
                  {currentUser?.general.nickname}
                </Text>
              </_UserInfoWrapper>
              <_MenuButton
                onClick={() => {
                  navigate("/mypage?view=profile");
                }}
              >
                <_Image src={IMAGE_PATHS.ICON.USER} alt="user" />
                <Text
                  fontSize="SM"
                  lineHeight="EQ"
                  fontColor="semantic.text.heading"
                  bold
                >
                  マイページ
                </Text>
              </_MenuButton>
              <_MenuButton
                onClick={() => {
                  navigate(
                    `/book_select_adjustment?entry_page=${ylReassesmentEnqueteQueryStringRecord.MENU_SIDE_BAR}`
                  );
                }}
              >
                <Icon
                  name="commentDots"
                  color="tex"
                  style={{ color: "#504E4B", width: "20px", height: "20px" }}
                />
                <Text
                  fontSize="SM"
                  lineHeight="EQ"
                  fontColor="semantic.text.heading"
                  bold
                >
                  選書を調整
                </Text>
              </_MenuButton>
              <_MenuButton
                onClick={() => {
                  navigate("/sharing_image");
                }}
              >
                <_Image src={IMAGE_PATHS.ICON.SHARE} alt="share" />
                <Text
                  fontSize="SM"
                  lineHeight="EQ"
                  fontColor="semantic.text.heading"
                  bold
                >
                  きろくをシェア
                </Text>
              </_MenuButton>
              <_MenuButton
                onClick={() => {
                  navigate("/character_introduction");
                }}
              >
                <_Image src={IMAGE_PATHS.ICON.THREE_USERS} alt="share" />
                <Text
                  fontSize="SM"
                  lineHeight="EQ"
                  fontColor="semantic.text.heading"
                  bold
                >
                  キャラクター図鑑
                </Text>
              </_MenuButton>
              <_MenuButton
                onClick={() => {
                  window.open(config.PARENT_APP, "_blank", "noreferrer");
                }}
              >
                <_Image
                  style={{ width: "20px", height: "20px" }}
                  src={IMAGE_PATHS.ICON.USER_FRIENDS}
                  alt="home"
                />
                <Row>
                  <Text
                    fontSize="SM"
                    lineHeight="EQ"
                    fontColor="semantic.text.heading"
                    bold
                  >
                    保護者アプリ
                  </Text>
                  <Icon
                    name="externalLink"
                    color="tex"
                    style={{ color: "#504E4B", width: "12px", height: "12px" }}
                  />
                </Row>
              </_MenuButton>
              <_MenuButton
                onClick={() => {
                  window.open(
                    "https://tayori.com/q/yondemy",
                    "_blank",
                    "noreferrer"
                  );
                }}
              >
                <Icon
                  name="infoCircle"
                  color="tex"
                  style={{ color: "#504E4B", width: "20px", height: "20px" }}
                />
                <Row>
                  <Text
                    fontSize="SM"
                    lineHeight="EQ"
                    fontColor="semantic.text.heading"
                    bold
                  >
                    ヨンデミー知恵袋
                  </Text>
                  <Icon
                    name="externalLink"
                    color="tex"
                    style={{ color: "#504E4B", width: "12px", height: "12px" }}
                  />
                </Row>
              </_MenuButton>
            </Stack>
          </_TopContent>
          <_BottomContent>
            {userAvailabilities.length > 1 && (
              <Stack rowGap="16px">
                <Text
                  lineHeight="EQ"
                  fontSize="SM"
                  fontColor="semantic.text.heading"
                  bold
                >
                  アカウント切り替え
                </Text>
                <Stack rowGap="0px">
                  <Selectbox
                    options={userAvailabilities.map((account) => {
                      return {
                        value: String(account.userID),
                        node: (
                          <p style={{ width: "160px" }}>{account.nickname}</p>
                        ),
                        isDisabled: account.userPlan === UserPlan.Invalidation,
                      };
                    })}
                    value={String(currentUser?.general.id)}
                    onChange={(userID) => {
                      navigate(`/set_user/${userID}`);
                    }}
                  />
                </Stack>
              </Stack>
            )}
          </_BottomContent>
        </_ContentWrapper>
      </_MenuContent>
    </Menu>
  );
};

const _MenuContent = styled.div`
  background: ${({ theme }) => theme.colors.base.white};
  padding: 16px 12px;
  border-style: solid;
  border-width: 2px 0px 2px 2px;
  border-color: ${({ theme }) => theme.colors.base.lightGray};
  border-radius: 16px 0px 0px 16px;
  overflow-y: scroll;
  height: 100vh;
  @supports (height: 100dvh) {
    height: 100dvh;
  }
`;

const _ContentWrapper = styled.div`
  min-height: calc(100vh - 36px);
  @supports (min-height: calc(100dvh - 36px)) {
    min-height: calc(100dvh - 36px);
  }
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  justify-content: space-between;
`;

const _TopContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

const _BottomContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

const _MenuButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 8px;
  padding: 20px 0;
  border-bottom: 2px solid ${({ theme }) => theme.colors.base.lightGray};
  cursor: pointer;
`;

const _Image = styled.img`
  width: 20px;
  height: 20px;
`;

const _UserInfoWrapper = styled.div`
  cursor: pointer;
  display: flex;
  column-gap: 8px;
  align-items: center;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.colors.primitive.monotone.m40};
  padding: 12px;
  border-radius: 12px;
`;

const _UserIcon = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
`;
