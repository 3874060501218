import React from "react";
import styled from "styled-components";
import { BalloonWithTail } from "~/components/molecules/wrappers/BalloonWithTail";
import { Text } from "~/components/atoms/texts/Text";

export type OnboardingBaseModalProps = {
  onClick: (e: React.MouseEvent) => void;
  imgSrc: string;
  message: string;
};

export const OnboardingBaseModal: React.FC<OnboardingBaseModalProps> = ({
  onClick,
  imgSrc,
  message,
}) => (
  <_Wrapper onClick={onClick}>
    <_Container>
      <BalloonWithTail padding="LG">
        <_ContentWrapper>
          <_MessageText
            bold
            fontSize="MD"
            lineHeight="MD"
            fontColor="tex.t700"
            whiteSpace="pre-wrap"
          >
            {message}
          </_MessageText>
          <_Triangle />
        </_ContentWrapper>
      </BalloonWithTail>
      <_SenseiImg src={imgSrc} />
    </_Container>
  </_Wrapper>
);

const _Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url("/img/initialize_modal/bookshelf.png");
  background-repeat: repeat-x;
  background-size: contain;
  cursor: pointer;
`;

const _Container = styled.div`
  height: 100%;
  padding: 0 20px 54px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  row-gap: 24px;
`;

const _ContentWrapper = styled.div`
  display: flex;
  column-gap: 8px;
  justify-content: space-around;
  align-items: center;
`;

const _Triangle: React.FC = () => (
  <svg
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.1564 7.28687C16.447 8.06437 16.447 9.93563 15.1564 10.7131L3.78207 17.5656C2.44909 18.3687 0.749997 17.4087 0.749997 15.8525L0.749998 2.14751C0.749998 0.591308 2.44909 -0.368683 3.78208 0.434377L15.1564 7.28687Z"
      fill="#F3AE36"
    />
  </svg>
);

const _MessageText = styled(Text)`
  font-family: "M PLUS 1 Code";
  line-height: 160%;
`;

const _SenseiImg = styled.img`
  width: 160px;
`;
