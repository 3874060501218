import { AllBadgeType, ALL_BUDGES } from "~/helpers/constants/badge";

type BadgeType = {
  label: string;
  value: string;
  imageFileName: string;
};

type UseGetNewBadgesReturnType = {
  newBadge: BadgeType;
  nextBadge?: BadgeType;
}[];

export type NewRecords = {
  readBookSum: number;
  readCharSum: number;
  maxContinueReadLogDays: number;
};

export type OldRecords = Partial<NewRecords>;

export const useGetNewBadges = (
  oldRecord: OldRecords,
  newRecord: NewRecords
): UseGetNewBadgesReturnType => {
  const newAllBadges = getAllBadge(newRecord);
  const oldAllBadges =
    oldRecord.readCharSum !== undefined &&
    oldRecord.readCharSum !== undefined &&
    oldRecord.maxContinueReadLogDays !== undefined
      ? getAllBadge(oldRecord as NewRecords)
      : getAllBadge({
          readBookSum: 0,
          readCharSum: 0,
          maxContinueReadLogDays: 0,
        });

  return (Object.keys(ALL_BUDGES) as AllBadgeType[]).reduce(
    (result, badgeType) => {
      const diff =
        (newAllBadges[badgeType] || 0) - (oldAllBadges[badgeType] || 0);
      if (diff === 0) return result;

      const newLastIndex = newAllBadges[badgeType] || 0;

      const newGetBadgeIndex = Number(newLastIndex) - 1;
      const badge = ALL_BUDGES[badgeType];
      const newThreshold = badge.THRESHOLDS[newGetBadgeIndex];
      const nextThreshold =
        newGetBadgeIndex === ALL_BUDGES[badgeType].THRESHOLDS.length - 1
          ? undefined
          : badge.THRESHOLDS[newGetBadgeIndex + 1];

      return [
        ...result,
        {
          newBadge: {
            value: `${newThreshold}${getUnit(badgeType)}`,
            label: getLabel(badgeType),
            imageFileName: `${badge.IMAGE_FILE_NAME_PREFIX}_${newThreshold}`,
          },
          nextBadge: nextThreshold
            ? {
                value: `${nextThreshold}${getUnit(badgeType)}`,
                label: getLabel(badgeType),
                imageFileName: `${badge.IMAGE_FILE_NAME_PREFIX}_${nextThreshold}`,
              }
            : undefined,
        },
      ];
    },
    []
  );
};

const getLabel = (badgeType: AllBadgeType): string => {
  const labels: { [key in AllBadgeType]: string } = {
    readBookSum: "読んだ冊数",
    readCharSum: "読んだ文字数",
    maxContinueReadLogDays: "れんぞく読書日数",
  };
  return labels[badgeType];
};

const getUnit = (badgeType: AllBadgeType): string => {
  const units: { [key in AllBadgeType]: string } = {
    readBookSum: "冊",
    readCharSum: "字",
    maxContinueReadLogDays: "日",
  };
  return units[badgeType];
};

const getBadgeLastIndex = (type: AllBadgeType, value: number): number => {
  const BADGE = ALL_BUDGES[type];
  let lastAcquiredIndex = 0;
  for (let index = 0; index < BADGE.THRESHOLDS.length; index++) {
    if (value < BADGE.THRESHOLDS[index]) {
      lastAcquiredIndex = index;
      break;
    }
  }
  if (value >= Math.max(...BADGE.THRESHOLDS)) {
    lastAcquiredIndex = BADGE.THRESHOLDS.length;
  }
  return lastAcquiredIndex;
};

const getAllBadge = (
  record: NewRecords
): { [key in AllBadgeType]?: number } => {
  return Object.keys(ALL_BUDGES).reduce((result, key) => {
    const type = key as AllBadgeType;
    return {
      ...result,
      [key]: getBadgeLastIndex(type, record[type]),
    };
  }, {});
};
