import React, { useState, useEffect } from "react";
import {
  useLoginTipsQuery,
  useDoneTodaysLoginTipsMutation,
} from "~/generated/graphql";
import { CommonStyle } from "./Common";
import { Text } from "~/components/atoms/texts/Text";
import { useTranslation } from "~/i18n";
import { OnboardingTips } from "./TodaysTips/OnboardingTips";
import { ReadLogReviewTips } from "./TodaysTips/ReadLogReviewTips";
import { PromisedBookTips } from "./TodaysTips/PromisedBookTips";
import { SelectBookTipsContainer } from "./TodaysTips/SelectBookTips/container";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { useMemo } from "react";
import { useGetMyMiniLessonHistoryQuery } from "~/generated/graphql";
type Flow =
  | "startPage"
  | "someTipsPage"
  | "skipMinilesson"
  | "confirmMinilesson"
  | "endPage";

export const useLoginTips = (): {
  loginTips?: React.ReactNode;
  loading: boolean;
  isOpenLoginTips: boolean;
} => {
  const { data, loading, error } = useLoginTipsQuery({
    fetchPolicy: "no-cache",
  });
  const [setTodaysLoginTips] = useDoneTodaysLoginTipsMutation();
  const [flow, setFlow] = useState<Flow>("startPage");
  const [isOpenLoginTips, setIsOpenLoginTips] = useState<boolean>(true);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { currentUser } = useContext(CurrentUserContext);

  const startTime = new Date();
  startTime.setDate(startTime.getDate() - 3);
  const startDate = new Date(
    startTime.getFullYear(),
    startTime.getMonth(),
    startTime.getDate()
  );
  const todayTime = new Date();
  const todayDate = new Date(
    todayTime.getFullYear(),
    todayTime.getMonth(),
    todayTime.getDate()
  );
  const { data: miniLessonHistoryData, loading: miniLessonloading } =
    useGetMyMiniLessonHistoryQuery({
      fetchPolicy: "cache-and-network",
      variables: {
        from: startDate,
        to: todayDate,
      },
    });
  const nickname = currentUser?.general.nickname;
  const hasTodaysLesson: boolean = useMemo(() => {
    return !!miniLessonHistoryData?.getMyMiniLessonHistory
      .hasInProgressMiniLesson;
  }, [miniLessonHistoryData, miniLessonloading]);

  const onClickSomeTipsToMinilesson = () => {
    hasTodaysLesson ? setFlow("confirmMinilesson") : setFlow("skipMinilesson");
  };

  useEffect(() => {
    if (data && !data.getLoginTips) {
      setIsOpenLoginTips(false);
    }
  }, [data]);
  const loginTips: React.ReactNode = useMemo(() => {
    if (loading || !data?.getLoginTips) {
      return <></>;
    }
    if (flow === "startPage") {
      return (
        <CommonStyle
          loginTipsButtonProps={{
            text: t<TranslateKeys>("こんにちは！"),
            onClick: () => setFlow("someTipsPage"),
          }}
        >
          <Text fontColor="tex.t700" fontSize="MD" lineHeight="MD">
            {t<TranslateKeys>(
              "{{user}}さん、こんにちは！\n会えて 嬉しいな😊\n今日の お話は これ！",
              { user: nickname }
            )}
          </Text>
        </CommonStyle>
      );
    } else if (flow === "someTipsPage") {
      if (error) {
        setIsOpenLoginTips(false);
        return <>error</>;
      } else {
        switch (data.getLoginTips.__typename) {
          case "OnboardingTips": {
            return (
              <OnboardingTips
                loginTime={data.getLoginTips.loginTimes}
                onClick={() => onClickSomeTipsToMinilesson()}
              />
            );
          }
          case "ReadLogReviewTips": {
            return (
              <ReadLogReviewTips
                bookTitle={data.getLoginTips.readLogWithReview.book.title || ""}
                onClick={() => onClickSomeTipsToMinilesson()}
              />
            );
          }
          case "PromisedBookTips": {
            return (
              <PromisedBookTips
                bookTitle={data.getLoginTips.booktitle}
                onClick={() => onClickSomeTipsToMinilesson()}
              />
            );
          }
          case "SelectBookTips": {
            return (
              <SelectBookTipsContainer
                recommendList={data.getLoginTips.recommendList.get}
                count={data.getLoginTips.recommendList.count}
                onNext={() => onClickSomeTipsToMinilesson()}
              />
            );
          }
          case undefined: {
            return <></>;
          }
        }
      }
    } else if (flow === "confirmMinilesson") {
      return (
        <CommonStyle
          loginTipsButtonProps={{
            text: t<TranslateKeys>("ミニレッスンを 受ける"),
            onClick: () => {
              navigate("/message");
              setTodaysLoginTips();
              setIsOpenLoginTips(false);
            },
          }}
          skipButtonProps={{
            label: t<TranslateKeys>("あとにする"),
            onSkip: () => setFlow("endPage"),
          }}
        >
          <Text fontColor="tex.t700" fontSize="MD" lineHeight="MD">
            {t<TranslateKeys>(
              "お話できて 楽しかったよ！\nこのまま ミニレッスンも 受けちゃう？"
            )}
          </Text>
        </CommonStyle>
      );
    } else if (flow === "skipMinilesson") {
      return (
        <CommonStyle
          loginTipsButtonProps={{
            text: t<TranslateKeys>("はーい！"),
            onClick: () => setFlow("endPage"),
          }}
        >
          <Text fontColor="tex.t700" fontSize="MD" lineHeight="MD">
            {t<TranslateKeys>("それじゃあ、今日も 本の世界を 楽しんでね😊")}
          </Text>
        </CommonStyle>
      );
    } else if (flow === "endPage") {
      return (
        <CommonStyle
          loginTipsButtonProps={{
            text: t<TranslateKeys>("はーい！"),
            onClick: () => {
              setTodaysLoginTips();
              setIsOpenLoginTips(false);
            },
          }}
        >
          <Text fontColor="tex.t700" fontSize="MD" lineHeight="MD">
            {t<TranslateKeys>("また後で！待ってるね！")}
          </Text>
        </CommonStyle>
      );
    }
    return null;
  }, [flow, data, setTodaysLoginTips, onClickSomeTipsToMinilesson, nickname]);

  return { loginTips, loading, isOpenLoginTips };
};
