import React, { VFC } from "react";
import { SelectProps } from "~/components/atoms/forms/Select";
import { Label, LabelProps } from "~/components/atoms/wrappers/Label";
import { Select } from "~/components/atoms/forms/Select";

type LabeledSelectProps = SelectProps & Pick<LabelProps, "label">;

/**
 * @deprecated atoms/form/FormSlotとatoms/input/SelectBoxを組み合わせて使用してください
 */
export const LabeledSelect: VFC<LabeledSelectProps> = ({
  label,
  error,
  ...SelectProps
}) => (
  <Label label={label}>
    <Select error={error} {...SelectProps} />
  </Label>
);
