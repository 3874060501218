import * as React from "react";
import { useState } from "react";
import { SearchWordModal } from "~/components/organisms/BookListViewer/SearchWordModal";
import { useSearchCityByMyLibraryQuery } from "~/generated/graphql";
import { usePreference } from "~/store/preference/usePreference";

export const useCalil = (): {
  handleClickGoToCalil: (isbn: string) => void;
  calilSearchWordModal: React.ReactNode;
} => {
  const [searchWordModalOpen, setSearchWordModalOpen] =
    useState<boolean>(false);
  const { preference, savePreference } = usePreference();
  const [savedIsbn, setSavedIsbn] = useState<string>();

  const { data } = useSearchCityByMyLibraryQuery();

  const handleClickGoToCalil = React.useCallback(
    (isbn: string) => {
      if (!preference) {
        return;
      }

      const _searchWord = preference.calilSearchWord;

      if (!_searchWord) {
        if (data && data.searchCityByMyLibrary) {
          savePreference({ calilSearchWord: data.searchCityByMyLibrary });

          window.open(
            `https://calil.jp/book/${isbn}/search?nearby=${data.searchCityByMyLibrary}`,
            "_blank"
          );
          window.gtag("event", "reserve", {
            event_category: "book",
            event_label: data.searchCityByMyLibrary,
            value: 1,
            isbn: isbn,
          });
        } else {
          setSearchWordModalOpen(true);
          setSavedIsbn(isbn);
        }
      } else {
        window.open(
          `https://calil.jp/book/${isbn}/search?nearby=${_searchWord}`,
          "_blank"
        );
        window.gtag("event", "reserve", {
          event_category: "book",
          event_label: _searchWord,
          value: 1,
          isbn: isbn,
        });
      }
    },
    [preference, setSavedIsbn]
  );

  const calilSearchWordModal: React.ReactNode = React.useMemo(
    () =>
      searchWordModalOpen ? (
        <SearchWordModal
          onClose={() => {
            setSearchWordModalOpen(false);
            window.setTimeout(() => {
              savedIsbn && handleClickGoToCalil(savedIsbn);
            }, 300); // モーダルが閉じるのを見届けてから遷移する
          }}
          onChange={(e) => {
            savePreference({ calilSearchWord: e.currentTarget.value });
          }}
          isOpen
        />
      ) : null,
    [searchWordModalOpen, savedIsbn, handleClickGoToCalil]
  );

  return { handleClickGoToCalil, calilSearchWordModal };
};
