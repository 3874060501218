import React from "react";
import {
  ReactiveHistoryAllFragment,
  TalkItemSpeaker,
} from "~/generated/graphql";
import { MultiChoiceQuestion } from "../../messageListItems/MultiChoiceMessage/container/MultiChoiceQuestion";
import { SingleChoiceQuestion } from "../../messageListItems/SingleChoiceMessage/container/SingleChoiceQuestion";
import { OpenEnded } from "../../messageListItems/InputTextMessage/container/OpenEnded";
import { DummyQuestion } from "../../messageListItems/DummyMessage/container/DummyQuestion";
import { RemarkMessage } from "../../messageListItems/RemarkMessage";
import styled from "styled-components";

/**
 * ミニレッスンで_typenameに応じてtalkを出し分ける
 * - OpenEnded: テキスト入力で送信
 * - SingleChoiceQuestion: 1つ選択可能（Radio box）
 * - MultiChoiceQuestion: 複数選択可能(Check box)
 * - DummyQuestion: ボタンのみ
 *
 */

export type QuestionsProps = {
  /**
   * トークのitemId
   */
  id: number;
  /**
   * トークの情報
   */
  item: ReactiveHistoryAllFragment;
  className?: string;
  showAll?: boolean;
  incrementCurrentIndex: () => void;
  speaker: TalkItemSpeaker;
};

export const Questions: React.FC<QuestionsProps> = ({
  id,
  item,
  className,
  showAll = true,
  incrementCurrentIndex,
  speaker,
}) => {
  const { question, message, sentTime, state, response, responseTime } = item;
  const messages = (message || []).map((text) => {
    return { text, time: new Date(sentTime) };
  });

  const lastItem = messages.pop();
  const questions = messages.map((item, index) => {
    return (
      <RemarkMessage
        id={id}
        key={index}
        item={item}
        userType="admin"
        showAll={showAll}
        incrementCurrentIndex={incrementCurrentIndex}
        speaker={speaker}
      />
    );
  });

  if (lastItem !== undefined) {
    switch (question?.__typename) {
      case "OpenEnded":
        questions.push(
          <OpenEnded
            key={-1}
            id={id}
            state={state}
            item={lastItem}
            question={question}
            defaultValue={question.answer || ""}
            showAll={showAll}
            incrementCurrentIndex={incrementCurrentIndex}
            speaker={speaker}
          />
        );
        break;
      case "Choices":
        if (question.multiple) {
          questions.push(
            <MultiChoiceQuestion
              id={id}
              key={-1}
              state={state}
              item={lastItem}
              choices={question.items}
              showAll={showAll}
              incrementCurrentIndex={incrementCurrentIndex}
              speaker={speaker}
            />
          );
        } else {
          questions.push(
            <SingleChoiceQuestion
              key={-1}
              id={id}
              state={state}
              item={lastItem}
              choices={question.items}
              showAll={showAll}
              incrementCurrentIndex={incrementCurrentIndex}
              speaker={speaker}
            />
          );
        }
        break;
      case "DummyQuestion":
        questions.push(
          <DummyQuestion
            key={-1}
            id={id}
            state={state}
            item={lastItem}
            showAll={showAll}
            incrementCurrentIndex={incrementCurrentIndex}
            speaker={speaker}
          />
        );
        break;
      default:
        return <>invalid question type</>;
    }
  }
  return (
    <QuestionListItem className={className}>
      {questions}
      {(response || []).map((text, index) => {
        return (
          <RemarkMessage
            id={id}
            key={index}
            item={{ text, time: responseTime }}
            userType="admin"
            showAll={showAll}
            incrementCurrentIndex={incrementCurrentIndex}
            speaker={speaker}
          />
        );
      })}
    </QuestionListItem>
  );
};

const QuestionListItem = styled.div``;
