import * as React from "react";
import { ConfirmDifficultyTemplate } from "~/components/templates/PostReadLog/ConfirmDifficulty";
import { usePostReadLogState } from "../usePostReadLogState";

export const ConfirmDifficulty: React.VFC = () => {
  const {
    state: { questionNumber, lastProgressNumber },
    dispatch,
  } = usePostReadLogState();

  const handleNext = (): void => {
    dispatch({
      type: "setQuestionNumber",
      payload: currectQuestionNumber + 1,
    });
    dispatch({
      type: "setFlow",
      payload: "confirmLength",
    });
  };

  const handleBack = (): void => {
    dispatch({
      type: "setQuestionNumber",
      payload: currectQuestionNumber - 1,
    });
    dispatch({
      type: "setFlow",
      payload: "confirmLike",
    });
  };

  const onClicks = [
    //かんたん
    (event: React.MouseEvent) => {
      event.preventDefault();
      dispatch({
        type: "setDifficulty",
        payload: 1,
      });
      handleNext();
    },
    //ちょっとかんたん
    (event: React.MouseEvent) => {
      event.preventDefault();
      dispatch({
        type: "setDifficulty",
        payload: 2,
      });
      handleNext();
    },
    //ぴったり！
    (event: React.MouseEvent) => {
      event.preventDefault();
      dispatch({
        type: "setDifficulty",
        payload: 3,
      });
      handleNext();
    },
    //ちょっとむずかしい
    (event: React.MouseEvent) => {
      event.preventDefault();
      dispatch({
        type: "setDifficulty",
        payload: 4,
      });
      handleNext();
    },
    //むずかしい
    (event: React.MouseEvent) => {
      event.preventDefault();
      dispatch({
        type: "setDifficulty",
        payload: 5,
      });
      handleNext();
    },
  ];

  const currectQuestionNumber = questionNumber ? questionNumber : 1;
  const progress = Math.floor(
    (currectQuestionNumber / lastProgressNumber) * 100
  );

  return (
    <ConfirmDifficultyTemplate
      progress={progress}
      handleBack={handleBack}
      onClicks={onClicks}
    />
  );
};
