import React from "react";
import styled from "styled-components";
import { DailyAchievement } from "./DailyAchievement";
import { Text } from "~/components/atoms/texts/Text";
import { Stack } from "~/components/atoms/layout/Stack";
import { Row } from "~/components/atoms/layout/Row";
import { DailyReadingHabitAchievement } from "~/generated/graphql";

const dayOfTheWeek = ["日", "月", "火", "水", "木", "金", "土"] as const;
const weekCount = 6;
const weekDayCount = 7;

const getDayInfo = (year: number, month: number): (number | undefined)[][] => {
  const firstDay = new Date(year, month - 1, 1);
  const lastDay = new Date(year, month, 0);
  const firstDayOfWeek = firstDay.getDay(); // 月の最初の日の曜日 (0: 日曜, 1: 月曜, ...)
  const lastDayDate = lastDay.getDate(); // 月の最後の日

  const calendar: (number | undefined)[][] = Array.from(
    { length: weekCount },
    () => Array(weekDayCount).fill(undefined)
  );

  for (let week = 0; week < weekCount; week++) {
    for (let dayOfWeek = 0; dayOfWeek < weekDayCount; dayOfWeek++) {
      const currentDayOfWeek = dayOfWeek - firstDayOfWeek;

      //NOTE: week と dayOfWeek に対応する日付
      const currentDate = weekDayCount * week + currentDayOfWeek + 1;
      if (
        dayOfWeek + weekDayCount * week >= firstDayOfWeek &&
        currentDate <= lastDayDate
      ) {
        calendar[week][dayOfWeek] = currentDate;
      }
    }
  }

  return calendar;
};

export type ReadingHabitCalendarProps = {
  year: number;
  month: number;
  dailyAchievements: DailyReadingHabitAchievement[];
};

export const ReadingHabitCalendar: React.FC<ReadingHabitCalendarProps> = ({
  year,
  month,
  dailyAchievements,
}) => {
  const calendarArr: {
    day?: number;
    miniLesson: boolean;
    readLog: boolean;
  }[][] = React.useMemo(() => {
    const dayInfo = getDayInfo(year, month);

    return dayInfo.map((week) => {
      return week.map((day) => {
        const achievement = dailyAchievements.find((d) => d.day === day);
        return day === undefined
          ? { miniLesson: false, readLog: false }
          : {
              day: day,
              miniLesson: achievement?.miniLesson || false,
              readLog: achievement?.postReadLog || false,
            };
      });
    });
  }, [year, month, dailyAchievements]);

  return (
    <Stack rowGap="4px">
      <Row columnGap="4px">
        {dayOfTheWeek.map((day, day_index) => (
          <_DayNameWrapper key={day_index}>
            <Text lineHeight="EQ" fontSize="XS">
              {day}
            </Text>
          </_DayNameWrapper>
        ))}
      </Row>
      {calendarArr.map((week, i) => {
        return (
          <Row key={i} columnGap="4px">
            {week.map((dayInfo, i_week) => {
              return dayInfo.day ? (
                <DailyAchievement
                  key={i_week}
                  day={dayInfo.day}
                  miniLesson={dayInfo.miniLesson}
                  readLog={dayInfo.readLog}
                />
              ) : (
                <_DummyDay key={i_week} />
              );
            })}
          </Row>
        );
      })}
    </Stack>
  );
};
const _DayNameWrapper = styled.div`
  width: 20px;
  height: 20px;
  padding: 4px;
`;

const _DummyDay = styled.div`
  width: 20px;
  height: 20px;
  visibility: hidden;
`;
