import React, { useContext } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import {
  GetMyProfileQuery,
  useDestroyAuthSessionMutation,
  useGetMyProfileQuery,
} from "../../generated/graphql";
import { LoadingPage } from "~/components/templates/Loading/LoadingPage";
import { Wrapper } from "~/components/templates/Common";
import { ProfileTemplate } from "~/components/templates/Profile";
import { BackNavbar } from "~/components/molecules/navbars/BackNavbar";
import { Auth0Context } from "~/contexts/Auth0Context";
import { useApolloClient } from "@apollo/client";

export const Mypage: React.FC = () => {
  const navigate = useNavigate();
  const { logout } = useContext(Auth0Context);
  const client = useApolloClient();
  const [destroyAuthSessionMutation] = useDestroyAuthSessionMutation();

  const handleLogout = async (event: React.MouseEvent): Promise<void> => {
    event.preventDefault();
    client.resetStore();
    await destroyAuthSessionMutation();
    logout();
    navigate("/");
  };

  const { loading, error, data } = useGetMyProfileQuery();
  if (loading) {
    return <LoadingPage />;
  }
  if (error) {
    return <>Error! {error.message}</>;
  }

  return (
    <MyPageWrapper>
      <BackNavbar onClick={() => navigate("/")} titleName={"マイページ"} />
      <Wrapper>
        <TopWrapper>
          <ProfileTemplate
            data={data as GetMyProfileQuery}
            handleLogout={handleLogout}
          />
        </TopWrapper>
      </Wrapper>
    </MyPageWrapper>
  );
};

const MyPageWrapper = styled.div`
  background: #e5e5e5;
`;

const TopWrapper = styled.div`
  padding-top: 20px;
  padding-bottom: 76px;
`;
