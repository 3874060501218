import React from "react";
import { useUnlock } from "~/store/unlock/useUnlock";

type ReceiveStarModalLockWrapperProps = {
  children: React.ReactNode;
};

export const ReceiveStarModalLockWrapper: React.FC<
  ReceiveStarModalLockWrapperProps
> = ({ children }) => {
  const { isLocked } = useUnlock();

  if (isLocked("bfStar")) {
    return <></>;
  } else {
    return <>{children}</>;
  }
};
