import React, { useState, FC, useContext } from "react";
import { Text } from "~/components/atoms/texts/Text";
import { TopNavbar } from "~/components/organisms/navbars/TopNavbar";
import styled from "styled-components";
import { BaseModal as OldBaseModal } from "~/components/atoms/wrappers/modal";
import { BaseModal as NewBaseModal } from "~/components/atoms/wrappers/BaseModal";

import {
  ReadLogReactionType,
  ReadStatus,
  UserIconType,
} from "~/generated/graphql";
import { LargeButton } from "~/components/atoms/buttons/Button";
import { Stack } from "~/components/atoms/layout/Stack";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import { Snackbar } from "~/components/atoms/Snackbar";
import {
  ReadLogMessage,
  ReadLogMessageProps,
} from "~/components/organisms/bookFriendMessage/BookFriendMessageListItem/ReadLogMessage";
import { theme } from "~/styles/theme";
import { useTranslation } from "~/i18n";

export type TutorialAtBookFriendProps = {
  onNext: () => void;
};

export const TutorialAtBookFriend: FC<TutorialAtBookFriendProps> = ({
  onNext,
}) => {
  const [tutorialPage, setTutorialPage] = useState(0);
  const { currentUser } = useContext(CurrentUserContext);
  const nickname = currentUser?.general.nickname;
  const { t } = useTranslation();

  const [reactionType, setReactionType] = useState<ReadLogReactionType[]>([]);
  const mockOnReaction = (
    _: string,
    __: number,
    reaction: ReadLogReactionType
  ) => {
    return new Promise<boolean>((resolve) => {
      setReactionType((prevReactions) => {
        if (prevReactions.includes(reaction)) {
          return prevReactions.filter((r) => r !== reaction);
        } else {
          return [...prevReactions, reaction];
        }
      });
      if (reaction === ReadLogReactionType.Like) {
        setTimeout(() => {
          setTutorialPage(1);
        }, 1000); // 1秒待っていいねを押した感じを演出する
      }
      resolve(true);
    });
  };

  const renderReadLogMessage = ({ isTutorial }: { isTutorial: boolean }) => (
    <ReadLogMessage
      {...{
        ...readLogProps,
        onReaction: mockOnReaction,
        item: {
          ...readLogProps.item,
          myReaction: {
            ...readLogProps.item.myReaction,
            reactionType,
          },
        },
        isTutorial,
      }}
    />
  );

  return (
    <>
      <OldBaseModal
        isOpen={true}
        style={{
          overlay: {
            zIndex: theme.zIndex.modal,
          },
          content: {
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            width: "100vw",
            padding: "0",
            zIndex: theme.zIndex.blackBack,
          },
        }}
      >
        <TopNavbar
          haveUnreadInBfGroupMessage={false}
          haveUnreadInMessage={false}
          currentTab={0}
          onChangeTab={() => {}}
        />
        {tutorialPage === 0 && (
          <OldBaseModal isOpen={true}>
            <_Spacer />
            <Snackbar icon="heart" stickPlace="top" edgeOffset="104px">
              <Text
                lineHeight="MD"
                fontSize="SM"
                fontColor="semantic.text.body"
              >
                {t<TranslateKeys>("本の虫の 感想があるね！")}
                <br />
                {t<TranslateKeys>("❤️ いいねを 送ってみよう！")}
              </Text>
            </Snackbar>
            <_ReadLogContainer>
              <_BookFriendMessageListItem>
                {renderReadLogMessage({ isTutorial: true })}
              </_BookFriendMessageListItem>
            </_ReadLogContainer>
          </OldBaseModal>
        )}
        {tutorialPage === 1 && (
          <>
            <_BookFriendMessageListItem>
              {renderReadLogMessage({ isTutorial: false })}
            </_BookFriendMessageListItem>
            <NewBaseModal open={true}>
              <Stack rowGap="16px">
                <Text
                  lineHeight="EQ"
                  fontSize="XL"
                  fontColor="semantic.text.heading"
                  bold
                >
                  {t<TranslateKeys>("いいねが 送れたよ！")}
                </Text>
                <Text
                  lineHeight="MD"
                  fontSize="MD"
                  fontColor="semantic.text.body"
                >
                  {t<TranslateKeys>("本の虫に 気持ちが 伝わったね！")}
                </Text>
                <img
                  src="/img/unlock/book_worm_thanks.svg"
                  style={{ height: "118px" }}
                />
                <_Buttonwrapper>
                  <LargeButton onClick={() => setTutorialPage(2)}>
                    やったー！
                  </LargeButton>
                </_Buttonwrapper>
              </Stack>
            </NewBaseModal>
          </>
        )}
        {tutorialPage === 2 && (
          <>
            <_BookFriendMessageListItem>
              {renderReadLogMessage({ isTutorial: false })}
            </_BookFriendMessageListItem>
            <NewBaseModal open={true}>
              <Stack rowGap="16px">
                <Text
                  lineHeight="EQ"
                  fontSize="XL"
                  fontColor="semantic.text.heading"
                  bold
                >
                  おや？
                </Text>
                <Text
                  lineHeight="MD"
                  fontSize="MD"
                  fontColor="semantic.text.body"
                >
                  {t<TranslateKeys>(
                    "本の虫からも {{user}}さんの 感想に いいねが 届いているみたい……。",
                    { user: nickname }
                  )}
                </Text>
                <img
                  src="/img/unlock/book_worm_heart.svg"
                  style={{ height: "118px" }}
                />
                <_Buttonwrapper>
                  <LargeButton onClick={onNext}>ほんだなにいく</LargeButton>
                </_Buttonwrapper>
              </Stack>
            </NewBaseModal>
          </>
        )}
      </OldBaseModal>
    </>
  );
};

const _Spacer = styled.div`
  height: 200px;
`;

const _ReadLogContainer = styled.div`
  background-color: ${(props) =>
    props.theme.colors.semantic.layout.backgroundMain};
  border-radius: 16px;
  padding: 4px;
  width: fit-content;
`;

const _BookFriendMessageListItem = styled.div`
  padding: 5px 20px;
  @media screen and (min-width: 600px) {
    padding: 5px 10%;
  }
`;

const _Buttonwrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const readLogProps: ReadLogMessageProps = {
  onWantReadBook: () => Promise.resolve(true),
  onReaction: () => Promise.resolve(true),
  onCompleteShowSenseiMessage: () => {},
  item: {
    id: "111",
    createdAt: new Date(),
    user: {
      id: 1,
      nickname: "本の虫",
      userIconType: UserIconType.Worm,
    },
    readLogInfo: {
      readLogId: 1,
      book: {
        title: "あたりかも",
        author: "きたじまごうき",
        isbn: "0",
        imageUrl: "https://m.media-amazon.com/images/I/512v9trAP0L._SL500_.jpg",
      },
      review: "いえの れいぞうこも こおりのくにに つながっていたら いいのに。",
      like: 23,
      status: ReadStatus.Reading,
      isMyWantReadBook: false,
    },
    myReaction: {
      reactionType: [],
    },
  },
  user: {
    id: 1,
    nickname: "本の虫",
    userIconType: UserIconType.Worm,
    __typename: "User",
  },
  userType: "user",
  onOpenSendStarModal: () => {},
  canSendStar: false,
  isShowSendStarMessage: false,
  sendStarMessageProps: {
    onCompleted: () => {},
  },
  isVisibleStarButton: false,
  isTutorial: true,
};
