import format from "date-fns/format";
import i18n, { TFunction } from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import l1 from "./locales/l1";
import l2 from "./locales/l2";
import l3 from "./locales/l3";
import l4 from "./locales/l4";
import l5 from "./locales/l5";
import l6 from "./locales/l6";
import l7 from "./locales/l7";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      l1: { translation: l1 },
      l2: { translation: l2 },
      l3: { translation: l3 },
      l4: { translation: l4 },
      l5: { translation: l5 },
      l6: { translation: l6 },
      l7: { translation: l7 },
    },
    lng: "l4",
    fallbackLng: "l4",
    interpolation: {
      escapeValue: false,
      format: function (value, _format, _lng) {
        if (_format && value instanceof Date) return format(value, _format);
        return value;
      },
    },
  });

export type TranslateKeys = keyof typeof l4;

export { useTranslation, i18n };
export type { TFunction };
