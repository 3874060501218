import React, { VFC } from "react";
import styled from "styled-components";
import { Text } from "~/components/atoms/texts/Text";
import { PostReadLogChoiceType } from "~/components/templates/PostReadLog/Common/type";
import { ColorTheme } from "~/styles/design_token/color";

export const SmallTag: VFC<PostReadLogChoiceType & { color: ColorTheme }> = ({
  emoji,
  ja,
  color,
}) => {
  return (
    <StyledCon color={color}>
      <Para
        fontSize={"XS"}
        lineHeight={"EQ"}
        style={{ marginRight: "3px" }}
        color={color}
      >
        {emoji}
      </Para>
      <Para fontSize={"XS"} lineHeight={"EQ"} color={color}>
        {ja}
      </Para>
    </StyledCon>
  );
};

const StyledCon = styled.div<{ color: ColorTheme }>`
  display: flex;
  align-items: center;
  background-color: ${({ theme, color }) => {
    switch (color) {
      case "pri":
        return theme.colors.pri.p400;
      case "gray":
        return theme.colors.base.lightGray;
      default:
        return theme.colors.pri.p400;
    }
  }};
  padding: ${({ theme }) => theme.size.XS};
  border-radius: ${({ theme }) => theme.deprecatedBorderRadius.XS};
`;

const Para = styled(Text)<{ color: ColorTheme }>`
  color: ${({ theme, color }) => {
    switch (color) {
      case "gray":
        return theme.colors.tex.t400;
      default:
        return theme.colors.base.white;
    }
  }};
`;
