/**
 * ISBNのバリデーション
 */
export const checkDegit = (isbn: string): string => {
  if (isbn === "なし") {
    return "0";
  }
  const toNumber = (s: string): number => {
    if (s === "x" || s === "X") {
      return 10;
    }
    const tmp = Number(s);
    if (isNaN(tmp)) {
      return -1;
    }
    return tmp;
  };
  if (isbn.length === 10) {
    const degits: number[] = [];
    for (let i = 0; i < 10; i++) {
      degits.push(toNumber(isbn.charAt(i)));
    }
    if (degits.find((e) => e === -1) !== undefined) {
      return "";
    }
    let remainder = 0;
    degits.reverse().forEach((e, index) => {
      remainder += e * (1 + index);
    });
    if (remainder % 11 !== 0) {
      return "";
    }
    const degits13 = degits.concat([8, 7, 9]);
    let checkDigit13 = 0;
    let weight = 1;
    for (let i = 1; i < 13; i++) {
      checkDigit13 += (2 + weight) * degits13[i];
      weight = weight * -1;
    }
    checkDigit13 = checkDigit13 % 10;
    checkDigit13 = 10 - checkDigit13;
    checkDigit13 = checkDigit13 % 10;
    degits13[0] = checkDigit13;
    isbn = degits13.reverse().join("");
  }
  if (isbn.length === 13) {
    const degits: number[] = [];
    for (let i = 0; i < 13; i++) {
      degits.push(toNumber(isbn.charAt(i)));
    }
    if (degits.find((e) => e === -1) !== undefined) {
      return "";
    }
    if (!(degits[0] === 9 && degits[1] === 7)) {
      return "";
    }
    let remainder = 0;
    degits.forEach((e, index) => {
      remainder += e * ((index % 2) * 2 + 1);
    });
    if (remainder % 10 !== 0) {
      return "";
    }
    return isbn;
  }
  return "";
};

/**
 * ISBNの正規化
 */
export const normalizeIsbn = (isbn: string): string => {
  const isbnWithoutSpace = isbn.trim();
  let str = "";
  if (isbnWithoutSpace.includes("-")) {
    const array = isbnWithoutSpace.split("-");
    for (let i = 0; i < array.length; i++) {
      str += array[i];
    }
  } else {
    str = isbnWithoutSpace;
  }
  return str;
};
