import React, { FC, useContext } from "react";
import styled, { css } from "styled-components";
import { Stack } from "~/components/atoms/layout/Stack";
import { Text } from "~/components/atoms/texts/Text";
import { BaseModal } from "~/components/atoms/wrappers/modal";
import { PrimaryButton } from "~/components/molecules/buttons/PrimaryButton";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import { sendButtonClickEvent } from "~/utils/googleAnalytics/useButtonClickTracking";
import { useTranslation } from "~/i18n";

type EnqueteQuitModalProps = {
  isOpen: boolean;
  onQuitEnquete: (event: React.MouseEvent) => void;
  onResume: () => void;
  enqueteType: EnqueteType;
};
type EnqueteType = "yl_reassessment_enquete" | "character_assessment";

type SentenceList = {
  title: TranslateKeys;
  quit: TranslateKeys;
  continue: TranslateKeys;
};

const quitEnqueteSentence: Record<EnqueteType, SentenceList> = {
  yl_reassessment_enquete: {
    title: "相談をやめますか？",
    quit: "YL相談をやめる",
    continue: "相談を続ける",
  },
  character_assessment: {
    title: "ジャンル診断をやめますか？",
    quit: "診断をやめる",
    continue: "診断を続ける",
  },
};

export const EnqueteQuitModal: FC<EnqueteQuitModalProps> = ({
  isOpen,
  onQuitEnquete,
  onResume,
  enqueteType,
}) => {
  const { t } = useTranslation();
  const { currentUser } = useContext(CurrentUserContext);

  const onQuitWithGA = (e: React.MouseEvent) => {
    onQuitEnquete(e);
    sendButtonClickEvent(
      "quit_yl_reassessment_enquete",
      String(currentUser?.general.id)
    );
  };

  return (
    <BaseModal isOpen={isOpen}>
      <BallonCon>
        <Ballon>
          <Stack alignItems="flex-start" rowGap="16px">
            <Text lineHeight="MD" fontSize="MD" bold fontColor="tex.t700">
              {t<TranslateKeys>(quitEnqueteSentence[enqueteType].title)}
            </Text>
            <_ButtonWrapper alignItems="center" rowGap="16px">
              <PrimaryButton
                onClick={onQuitWithGA}
                color="pri"
                text={t<TranslateKeys>(quitEnqueteSentence[enqueteType].quit)}
                lineHeight="EQ"
                fontSize="MD"
                bold={true}
                padding="16px"
              />
              <PrimaryButton
                onClick={onResume}
                color="gray"
                text={t<TranslateKeys>(
                  quitEnqueteSentence[enqueteType].continue
                )}
                lineHeight="EQ"
                fontSize="MD"
                bold={true}
                padding="16px"
              />
            </_ButtonWrapper>
          </Stack>
        </Ballon>
      </BallonCon>
    </BaseModal>
  );
};

const BallonCon = styled.div`
  ${({ theme }) => {
    return css`
      display: flex;
      align-items: center;
      justify-content: center;

      width: 100%;
      height: 100%;
      @media screen and ${theme.breakPoints.maxWidthSmallSmartPhone} {
        width: 90vw;
      }
    `;
  }}
`;

const Ballon = styled.div`
  ${({ theme }) => {
    return css`
      background-color: ${theme.colors.base.white};
      border-radius: ${theme.deprecatedBorderRadius.MD};

      width: 335px;
      max-width: 100%;
      padding: 16px 32px;
    `;
  }}
`;

const _ButtonWrapper = styled(Stack)`
  width: 100%;
`;
