import React from "react";
import styled from "styled-components";
//import { TranslateKeys, useTranslation } from "~/i18n";
import { Message } from "~/components/molecules/Message";
import { LibraryModalWrapper } from "../../ui/LibraryModalWrapper";
import { useTranslation } from "~/i18n";
import { YLEnqueteBackNavbar } from "../../ui/YLEnqueteBackNavbar";

export type BeforeEnqueteProps = {
  onNext: () => void;
  onQuit: () => void;
  onBack: () => void;
};

export const BeforeEnquete: React.FC<BeforeEnqueteProps> = ({
  onNext,
  onQuit,
  onBack,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <YLEnqueteBackNavbar
        withBlur={true}
        onQuitButton={onQuit}
        onBackButton={onBack}
      />
      <LibraryModalWrapper
        isBright={true}
        showSensei={true}
        senseiImageType="NORMAL"
        heartNum={1}
      >
        <_MessageWrapper>
          <Message
            onNext={onNext}
            message={t<TranslateKeys>(
              "パッと 文章を 見て、\nかんたんか むずかしいか\n教えてね！"
            )}
            speaker={t<TranslateKeys>("ヨンデミー先生")}
            boxHeight="middle"
          />
        </_MessageWrapper>
      </LibraryModalWrapper>
    </>
  );
};

const _MessageWrapper = styled.div`
  position: absolute;
  bottom: 15%;
  width: 100%;

  display: flex;
  justify-content: center;
`;
