import React from "react";
import styled from "styled-components";
import { Text } from "~/components/atoms/texts/Text";
import { PrimaryButton } from "~/components/molecules/buttons/PrimaryButton";
import { ClosableToast } from "../ClosableToast";

export type NotifyButtonTextToastProps = {
  text: string;
  isOpen: boolean;
  onClose: () => void;
  buttonText: string;
  onButtonClick: () => void;
};

export const NotifyButtonTextToast: React.FC<NotifyButtonTextToastProps> = ({
  isOpen,
  onClose,
  text,
  buttonText,
  onButtonClick,
}) => {
  return (
    <ClosableToast
      icon="bell"
      stickPlace={"top"}
      onClose={onClose}
      closeOnClick={true}
      buttonLabel={"とじる"}
      isOpen={isOpen}
      closeAfter={10000}
      underBoxChildren={
        <ButtonWrapper>
          <PrimaryButton
            bold={true}
            fontSize={"MD"}
            lineHeight={"MD"}
            onClick={onButtonClick}
            color={"gray"}
            text={buttonText}
            keepAll={true}
          ></PrimaryButton>
        </ButtonWrapper>
      }
    >
      <NotifyToastMessage fontSize={"XS"} lineHeight={"MD"}>
        {text}
      </NotifyToastMessage>
    </ClosableToast>
  );
};

const NotifyToastMessage = styled(Text)`
  color: ${({ theme }) => theme.colors.pri.p500};
  @media screen and (min-width: 768px) {
    font-size: ${({ theme }) => theme.fontSize.SM};
  }
  white-space: pre-wrap;
`;

const ButtonWrapper = styled.div`
  width: 50%;
  min-width: 150px;
  max-width: 200px;
`;
