import React from "react";
import styled from "styled-components";
import { Stack } from "~/components/atoms/layout/Stack";
import { Row } from "~/components/atoms/layout/Row";
import { Text } from "~/components/atoms/texts/Text";
import { useTranslation } from "~/i18n";

export const ReadingHabitExplanation: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Stack rowGap="8px">
      <Row columnGap="4px">
        <_ExplanationFragment upperLeft={true} lowerRight={false} />
        <Text fontSize="XS" lineHeight="EQ" fontColor="semantic.text.label">
          ミニレッスン
        </Text>
      </Row>
      <Row columnGap="4px">
        <_ExplanationFragment upperLeft={false} lowerRight={true} />
        <Text fontSize="XS" lineHeight="EQ" fontColor="semantic.text.label">
          {t<TranslateKeys>("感想")}
        </Text>
      </Row>
    </Stack>
  );
};

const _ExplanationFragment = styled.div<{
  upperLeft: boolean;
  lowerRight: boolean;
}>`
  border-radius: ${({ theme }) => theme.borderRadius.half};
  width: 20px;
  height: 20px;

  background: linear-gradient(
    -45deg,
    ${({ lowerRight, theme }) =>
        lowerRight ? theme.colors.semantic.primary.main : "transparent"}
      50%,
    ${({ upperLeft, theme }) =>
        upperLeft ? theme.colors.semantic.primary.main : "transparent"}
      50%
  );

  display: flex;
  justify-content: center;
  align-items: center;
`;
