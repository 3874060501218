import { MissionStorySetting } from "../type";

const baseDir = "/img/mission/start_story/story_3";

export const StartStory3: MissionStorySetting[] = [
  {
    image: `${baseDir}/scene_1.jpeg`,
    sound: "start3_scene1",
    text: "「自分はベジタライオンだよぉ、ライオンだけど草食なんだ〜。こっちは大親友のペンちゃん、ペンギンせんぱいだよぉ」",
    ruby: [
      "",
      "じ",
      "ぶん",
      ...Array(19).fill(""),
      "そう",
      "しょく",
      ...Array(9).fill(""),
      "だい",
      "しん",
      "ゆう",
      ...Array(19).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_2.jpeg`,
    sound: "start3_scene2",
    text: "そんなとき、雲のすきまから、星の渦があらわれて、あっという間に空は星のカーテンに覆われていきました。",
    ruby: [
      ...Array(6).fill(""),
      "くも",
      ...Array(7).fill(""),
      "ほし",
      "",
      "うず",
      ...Array(12).fill(""),
      "ま",
      "",
      "そら",
      "",
      "ほし",
      ...Array(6).fill(""),
      "おお",
      ...Array(9).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_3.jpeg`,
    sound: "start3_scene3",
    text: "すると︙︙\n真っ赤な星がひとつ、降りてきます。",
    ruby: [
      ...Array(6).fill(""),
      "ま",
      "",
      "か",
      "",
      "ほし",
      ...Array(5).fill(""),
      "お",
      ...Array(6).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_4.jpeg`,
    sound: "start3_scene4",
    text: "これは︙︙「おもいえがく」のスターだ！\nすごい！どくしょかのワザのスターが一つ手に入ったよ！",
    ruby: [
      ...Array(37).fill(""),
      "ひと",
      "",
      "て",
      "",
      "はい",
      ...Array(4).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_5.jpeg`,
    sound: "start3_scene5_1",
    text: "ぐぅぅぎゅるるるるる\n「あ、太古のきょうりゅう、お腹が空いたんだね！どこかで少し休もっか〜」",
    ruby: [
      ...Array(14).fill(""),
      "たい",
      "こ",
      ...Array(9).fill(""),
      "なか",
      "",
      "す",
      ...Array(10).fill(""),
      "すこ",
      "",
      "やす",
      ...Array(5).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_5.jpeg`,
    sound: "start3_scene5_2",
    text: "おや？何やらヨンデミー先生が準備してくれている冒険の宿があるって本の虫が言っているよ。",
    ruby: [
      ...Array(3).fill(""),
      "なに",
      ...Array(7).fill(""),
      "せん",
      "せい",
      "",
      "じゅん",
      "び",
      ...Array(7).fill(""),
      "ぼう",
      "けん",
      "",
      "やど",
      ...Array(5).fill(""),
      "ほん",
      "",
      "むし",
      "",
      "い",
      ...Array(6).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_6.jpeg`,
    sound: "start3_scene6",
    text: "どくしょかのしょを開くと︙︙\nニョキニョキ、ニョキニョキ",
    ruby: [...Array(9).fill(""), "ひら", ...Array(18).fill("")],
  },
  {
    image: `${baseDir}/scene_7.jpeg`,
    sound: "start3_scene7_1",
    text: "わ〜〜！どくしょかのしょには、イメージしたものを形にする力があるのでした。",
    ruby: [
      ...Array(24).fill(""),
      "かたち",
      ...Array(3).fill(""),
      "ちから",
      ...Array(8).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_7.jpeg`,
    sound: "start3_scene7_2",
    text: "読書家の大先輩、ヨンデミー先生は、どくしょかのしょで宿まで作れちゃうんですね。",
    ruby: [
      "どく",
      "しょ",
      "か",
      "",
      "だい",
      "せん",
      "ぱい",
      ...Array(6).fill(""),
      "せん",
      "せい",
      ...Array(11).fill(""),
      "やど",
      ...Array(2).fill(""),
      "つく",
      ...Array(9).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_8.jpeg`,
    sound: "start3_scene8",
    text: "「うわ〜、スッゲー‼　これが、オイラたちの宿！ますます冒険が楽しくなってきたな！」",
    ruby: [
      ...Array(21).fill(""),
      "やど",
      ...Array(5).fill(""),
      "ぼう",
      "けん",
      "",
      "たの",
      ...Array(10).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_9.jpeg`,
    sound: "start3_scene9_1",
    text: "「ペンちゃんは、料理がとっても得意なんだよ！自分もお腹が空いたから、ペンちゃんのご飯食べたいな〜」",
    ruby: [
      ...Array(8).fill(""),
      "りょう",
      "り",
      ...Array(5).fill(""),
      "とく",
      "い",
      ...Array(5).fill(""),
      "じ",
      "ぶん",
      ...Array(2).fill(""),
      "なか",
      "",
      "す",
      ...Array(12).fill(""),
      "はん",
      "た",
      ...Array(6).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_9.jpeg`,
    sound: "start3_scene9_2",
    text: "「ベジが言うなら仕方ないなぁ︙︙！」\nペンギンせんぱいシェフのお出ましです。",
    ruby: [
      ...Array(4).fill(""),
      "い",
      ...Array(3).fill(""),
      "し",
      "かた",
      ...Array(22).fill(""),
      "で",
      ...Array(5).fill(""),
    ],
  },
];
