import React from "react";
import styled from "styled-components";
import { NavContainer } from "../Common";
import { CurrentUserFragment } from "~/generated/graphql";
import {
  BookFriendMessageList,
  BookFriendMessageListProps,
} from "~/components/organisms/bookFriendMessage/BookFriendMessageList";
import {
  TopNavbar,
  TopNavbarProps,
} from "~/components/organisms/navbars/TopNavbar";
import { TodaysStarEnergyIcon } from "~/components/organisms/bookFriendMessage/TodaysStarEnergyIcon";
import { NotAssigned } from "~/components/organisms/bookFriendMessage/NotAssigned";
import { useUnlock } from "~/store/unlock/useUnlock";
import { LoadingPage } from "../Loading/LoadingPage";
import { BfLockPage } from "./BfLock";

type BookFriendMessageTemplateProps = {
  currentUser: CurrentUserFragment;
  bookFriendListProps: BookFriendMessageListProps;
  hasStarEnergy: boolean;
} & Pick<TopNavbarProps, "onChangeTab">;

export const BookFriendMessageTemplate: React.FC<
  BookFriendMessageTemplateProps
> = ({ bookFriendListProps, currentUser, onChangeTab, hasStarEnergy }) => {
  const { isLocked } = useUnlock();
  return (
    <BookFriendMessageContainer>
      <NavContainer>
        <TopNavbar
          haveUnreadInBfGroupMessage={
            currentUser?.haveUnreadInBfGroupMessage || false
          }
          haveUnreadInMessage={currentUser?.haveUnreadInMessage || false}
          currentTab={0}
          onChangeTab={onChangeTab}
        />
      </NavContainer>
      {!isLocked("bookFriend") && (
        <_FloatingStarEnergyIconWrapper>
          <TodaysStarEnergyIcon hasStarEnergy={hasStarEnergy} />
        </_FloatingStarEnergyIconWrapper>
      )}
      <MessageListContainer>
        {isLocked("bookFriend") ? (
          <BfLockPage />
        ) : bookFriendListProps.data.bfReadLogs.length === 0 &&
          !currentUser.belong ? (
          <_NotAssignedWrapper>
            <NotAssigned nickname={currentUser.general.nickname} />
          </_NotAssignedWrapper>
        ) : bookFriendListProps.data.bfReadLogs.length !== 0 ? (
          <BookFriendMessageList {...bookFriendListProps} />
        ) : (
          <LoadingPage />
        )}
      </MessageListContainer>
    </BookFriendMessageContainer>
  );
};

const BookFriendMessageContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const MessageListContainer = styled.div`
  margin-top: 88px;
  height: 100%;
`;

const _FloatingStarEnergyIconWrapper = styled.div`
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.floatingButton};
  top: 110px;
  right: 0px;
`;

const _NotAssignedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.floatingButton};
  bottom: 0;
  width: 100%;
  min-height: 50%;
  padding: 20px;
`;
