import { useMemo } from "react";
import { useTranslation } from "~/i18n";

type BubbleModalType = {
  content: string;
  buttons: { text: string; onClick: () => void }[];
  imageUrl: string;
};

export const useBubbleModal = (
  nickname: string,
  setCurrentIndex: (number) => void,
  onClose: () => void,
  currentIndex: number
): BubbleModalType => {
  const { t } = useTranslation();
  const currentItem = useMemo(() => {
    const items = [
      {
        content: t<TranslateKeys>(
          "{{ nickname }}さんに、\nぜひ この 本たちを 助けてほしい！🤝✨",
          { nickname }
        ),
        buttons: [
          { text: "まかせて😆", onClick: () => setCurrentIndex(1) },
          { text: "わかった！", onClick: () => setCurrentIndex(1) },
        ],
        imageUrl: "/img/sensei/standup_2.svg",
      },
      {
        content: t<TranslateKeys>(
          "図書館で 予約するか、本屋さんで 買ってみてね👀✨"
        ),
        buttons: [{ text: "はーい🙌", onClick: () => setCurrentIndex(2) }],
        imageUrl: "/img/sensei/standup_3.svg",
      },
      {
        content: t<TranslateKeys>("次の本も、楽しんで 読んでね！🤝"),
        buttons: [
          {
            text: "もちろん🙌",
            onClick: () => {
              onClose();
              setCurrentIndex(0);
            },
          },
          {
            text: "わくわく😆",
            onClick: () => {
              onClose();
              setCurrentIndex(0);
            },
          },
        ],
        imageUrl: "/img/sensei/standup_2.svg",
      },
    ];
    return items[currentIndex];
  }, [nickname, setCurrentIndex, currentIndex, onClose]);
  return currentItem;
};
