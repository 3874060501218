import { css } from "styled-components";
import { ColorTheme } from "./design_token/color";

export const Interpolation = {
  Border: css`
    border: 2px solid ${({ theme }) => theme.colors.base.lightGray};
  `,
  BorderBottom: css`
    border-bottom: 2px solid ${({ theme }) => theme.colors.base.lightGray};
  `,
  Form: css<{ error: boolean }>`
    border: 2px solid
      ${({ theme, error }) =>
        error ? theme.colors.red.r300 : theme.colors.base.gray};
    background-color: ${({ theme }) => theme.colors.base.white};
    &:active,
    :focus {
      border: 2px solid ${({ theme }) => theme.colors.pri.p400};
      transition: border 100ms linear;
    }
    &:disabled {
      background-color: ${({ theme }) => theme.colors.base.lightGray};
      &:active,
      :focus {
        border: 2px solid ${({ theme }) => theme.colors.base.gray};
        transition: none;
      }
    }
    &::placeholder {
      color: ${({ theme }) => theme.colors.tex.t100};
    }
  `,
};

export const ButtonInterpolation = {
  Square: css`
    width: 84px;
    height: 84px;
  `,
  RoundedCorner: css`
    height: 32px;
    padding: 0;
  `,
};

export const ColorInterpolation = {
  Sub: css<{ color: ColorTheme }>`
    color: ${({ theme, color }) => {
      switch (color) {
        case "pri":
          return theme.colors.pri.p500;
        case "sec":
          return theme.colors.sec.s500;
        case "violet":
          return theme.colors.violet.v500;
        case "green":
          return theme.colors.green.g500;
        case "red":
          return theme.colors.red.r500;
        case "sky":
          return theme.colors.sky.s500;
        case "wood":
          return theme.colors.wood.w600;
        case "gray":
          return theme.colors.tex.t200;
        case "tex":
          return theme.colors.tex.t500;
        case "white":
          return theme.colors.base.white;
        case "lightGray":
          return theme.colors.base.lightGray;
        case "darkGray":
          return theme.colors.primitive.tex.t300;
        default:
          return theme.colors.pri.p500;
      }
    }};
  `,
};

export const VisuallyHiddenInterpolation = css`
  position: absolute;
  top: -1px;
  left: 0;
  width: 1px;
  height: 1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
`;
