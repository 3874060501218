import React from "react";
import styled, { css } from "styled-components";
import { AllColorTheme } from "~/styles/design_token/color";
import get from "lodash/get";

export type ProgressBarProps = {
  progress: number;
  height?: string;
  progressColor?: AllColorTheme;
  backgroundColor?: AllColorTheme;
};

export const ProgressBar = ({
  progress,
  height = "10px",
  progressColor = "pri.p600",
  backgroundColor = "pri.p300",
}: ProgressBarProps): JSX.Element => {
  return (
    <ProgressWrapper height={height} color={backgroundColor}>
      <Bar progress={progress} color={progressColor} height={height} />
    </ProgressWrapper>
  );
};

const ProgressWrapper = styled.div<{ height: string; color: AllColorTheme }>`
  ${({ theme, color, height }) => {
    return css`
      width: 100%;
      border-radius: ${height};
      height: ${height};
      background-color: ${get(theme.colors, color)};
    `;
  }}
`;

const Bar = styled.div<{
  progress: number;
  color: AllColorTheme;
  height: string;
}>`
  ${({ theme, color, height, progress }) => {
    return css`
      height: 100%;
      border-radius: ${height};
      width: ${progress}%;
      background-color: ${get(theme.colors, color)};
    `;
  }}
`;
