import React, { useCallback, useEffect, useMemo, useContext } from "react";
import { Welcome } from "~/components/organisms/BookSelectionAdjustment/ylReassesmentEnquete/Welcome";
import { SelectDifficultyPerception } from "~/components/organisms/BookSelectionAdjustment/ylReassesmentEnquete/SelectDifficultyPerception";
import { AfterEnquete } from "~/components/organisms/BookSelectionAdjustment/ylReassesmentEnquete/AfterEnquete";
import { YLReassesmentEnquete as _Enquete } from "~/components/organisms/BookSelectionAdjustment/ylReassesmentEnquete/YLReassesmentEnquete";
import { BeforeEnquete } from "~/components/organisms/BookSelectionAdjustment/ylReassesmentEnquete/BeforeEnquete";
import { SuitableYlNotFound } from "~/components/organisms/BookSelectionAdjustment/ui/SuitableYlNotFound";
import { useNavigate, useLocation } from "react-router-dom";
import {
  YlReassesmentEnqueteDifficulty,
  CurrentRecommendDifficultyPerception,
  useGetSuitableYlResultQuery,
  useCreateYlReassesmentEnqueteMutation,
  YlReassesmentEnqueteEntryPage,
  YlReassesmentEnqueteVersion,
  ReassesmentEnqueteDifficulties,
} from "~/generated/graphql";
import {
  PassageYL,
  passageYLs,
} from "~/components/organisms/BookSelectionAdjustment/ylReassesmentEnquete/YLReassesmentEnquete/types";
import { getYlReassesmentEnquete } from "~/components/organisms/BookSelectionAdjustment/ylReassesmentEnquete/getYlReassesmentEnquete";
import { LoadingPage } from "~/components/templates/Loading/LoadingPage";
import { SubmitResponse } from "~/components/organisms/BookSelectionAdjustment/ylReassesmentEnquete/AfterEnquete";
import { makeVar } from "@apollo/client";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import { EnqueteQuitModal } from "~/components/organisms/BookSelectionAdjustment/ui/EnqueteQuitModal";

type Flow =
  | "welcome"
  | "selectDifficultyPerception"
  | "beforeEnquete"
  | "enqueteForUser"
  | "afterEnquete"
  | "suitableYlNotFound";

type EnqueteResult = Record<PassageYL, YlReassesmentEnqueteDifficulty[]>;

export const ylReassesmentEnqueteQueryStringRecord: Record<
  YlReassesmentEnqueteEntryPage,
  string
> = {
  [YlReassesmentEnqueteEntryPage.RemoveRecommend]: "remove_recommend",
  [YlReassesmentEnqueteEntryPage.BooksLeftNotice]: "books_left_notice",
  [YlReassesmentEnqueteEntryPage.IntroduceYlReassessmentNotice]:
    "introduce_yl_reassessment_notice",
  [YlReassesmentEnqueteEntryPage.ParentBookListViewer]:
    "parent_book_list_viewer",
  [YlReassesmentEnqueteEntryPage.MenuSideBar]: "menu_side_bar",
  [YlReassesmentEnqueteEntryPage.FirstBookRecommendReport]:
    "first_book_recommend_report",
  [YlReassesmentEnqueteEntryPage.Unknown]: "",
};

const finishLinkRecord: Record<YlReassesmentEnqueteEntryPage, string> = {
  [YlReassesmentEnqueteEntryPage.RemoveRecommend]: "/",
  [YlReassesmentEnqueteEntryPage.BooksLeftNotice]: "/",
  [YlReassesmentEnqueteEntryPage.IntroduceYlReassessmentNotice]: "/",
  [YlReassesmentEnqueteEntryPage.ParentBookListViewer]: "/",
  [YlReassesmentEnqueteEntryPage.MenuSideBar]: "/",
  [YlReassesmentEnqueteEntryPage.FirstBookRecommendReport]: "/",
  [YlReassesmentEnqueteEntryPage.Unknown]: "/",
};

const convertEnqueteResult = (
  difficultyArr: YlReassesmentEnqueteDifficulty[]
): ReassesmentEnqueteDifficulties | undefined => {
  return difficultyArr.length == 2
    ? {
        firstDifficulty: difficultyArr[0],
        secondDifficulty: difficultyArr[1],
      }
    : undefined;
};

export const isShowGotoYlReassesmentToastVar = makeVar<boolean>(false);

export const YLReassesmentEnquetePage: React.FC = () => {
  const [flow, setFlow] = React.useState<Flow>("welcome");
  const [isOpenQuitModal, setIsOpenQuitModal] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const [isLastIndex, setIsLastIndex] = React.useState<boolean>(false);

  const { currentUser } = useContext(CurrentUserContext);
  const nickname = currentUser?.general.nickname || "";

  const {
    data: queryData,
    loading: queryLoading,
    error: queryError,
  } = useGetSuitableYlResultQuery({
    fetchPolicy: "network-only",
  });

  const location = useLocation();
  const entryPage: YlReassesmentEnqueteEntryPage = useMemo(() => {
    const search = location.search;
    const searchParams = new URLSearchParams(search);
    const entryPageStr = searchParams.get("entry_page");
    switch (entryPageStr) {
      case ylReassesmentEnqueteQueryStringRecord["REMOVE_RECOMMEND"]:
        return YlReassesmentEnqueteEntryPage.RemoveRecommend;
      case ylReassesmentEnqueteQueryStringRecord["BOOKS_LEFT_NOTICE"]:
        return YlReassesmentEnqueteEntryPage.BooksLeftNotice;
      case ylReassesmentEnqueteQueryStringRecord[
        "INTRODUCE_YL_REASSESSMENT_NOTICE"
      ]:
        return YlReassesmentEnqueteEntryPage.IntroduceYlReassessmentNotice;
      case ylReassesmentEnqueteQueryStringRecord["PARENT_BOOK_LIST_VIEWER"]:
        return YlReassesmentEnqueteEntryPage.ParentBookListViewer;
      case ylReassesmentEnqueteQueryStringRecord["MENU_SIDE_BAR"]:
        return YlReassesmentEnqueteEntryPage.MenuSideBar;
      case ylReassesmentEnqueteQueryStringRecord["FIRST_BOOK_RECOMMEND_REPORT"]:
        return YlReassesmentEnqueteEntryPage.FirstBookRecommendReport;
      default:
        return YlReassesmentEnqueteEntryPage.Unknown;
    }
  }, [location]);

  const [createYlReassesmentEnqueteMutation, { loading: mutationLoading }] =
    useCreateYlReassesmentEnqueteMutation();

  useEffect(() => {
    if (queryData?.getSuitableYL.__typename === "ErrorSuitableYLNotFound") {
      console.log("suitable YL not Found");
      setFlow("suitableYlNotFound");
    }
  }, [queryData]);

  const [currentDifficultyPerception, setCurrentDifficultyPerception] =
    React.useState<CurrentRecommendDifficultyPerception>(
      CurrentRecommendDifficultyPerception.Easy
    );

  const { ylAndSentenceOrderList, sentenceLevelRecord } = useMemo(() => {
    const latestSuitableYl: number =
      queryData?.getSuitableYL.__typename === "SuitableYL"
        ? queryData.getSuitableYL.value
        : 25; // MEMO: suitableYL がない場合の処理はuseEffectで行うので、ここでは適当な値を返す
    return getYlReassesmentEnquete(
      latestSuitableYl,
      currentDifficultyPerception
    );
  }, [queryData, currentDifficultyPerception]);

  const [enqueteResult, setEnqueteResult] = React.useState<EnqueteResult>({
    15: [],
    20: [],
    25: [],
    30: [],
    35: [],
    40: [],
    45: [],
    50: [],
  });

  console.log(enqueteResult);

  const isValid = React.useMemo<boolean>(() => {
    const selectedPassageYLArr: PassageYL[] =
      Object.values(sentenceLevelRecord);

    const flag = passageYLs.every((passageYL) => {
      const tmp = selectedPassageYLArr.includes(passageYL)
        ? enqueteResult[passageYL].length === 2
        : enqueteResult[passageYL].length === 0;
      return tmp;
    });
    return flag;
  }, [enqueteResult, sentenceLevelRecord, passageYLs]);
  console.log("isValid", isValid);

  const updateEnqueteResult = useCallback(
    (passageYL: PassageYL, difficulty: YlReassesmentEnqueteDifficulty) => {
      console.log(passageYL, difficulty);
      setEnqueteResult((prev) => ({
        ...prev,
        [passageYL]: [...prev[passageYL], difficulty],
      }));
    },
    [setEnqueteResult]
  );

  const handleBack = React.useCallback(
    (enqueteIndex: number) => {
      if (enqueteIndex === 0) {
        setFlow("beforeEnquete");
        return;
      }
      const { passageYL: prevPassageYL } =
        ylAndSentenceOrderList[enqueteIndex - 1];
      setEnqueteResult((prev) => ({
        ...prev,
        [prevPassageYL]: prev[prevPassageYL].slice(0, -1),
      }));
    },
    [ylAndSentenceOrderList]
  );

  const onQuit = React.useCallback(() => {
    navigate(finishLinkRecord[entryPage]);
  }, [entryPage]);

  const content: React.ReactNode = React.useMemo(() => {
    const handleSubmitEnquete = (): Promise<SubmitResponse> => {
      return new Promise((resolve) => {
        if (!isValid) {
          resolve("invalidEnquete");
        }
        createYlReassesmentEnqueteMutation({
          variables: {
            input: {
              version: YlReassesmentEnqueteVersion.Version_1,
              currentDifficultyPerception: currentDifficultyPerception,
              yl15difficulties: convertEnqueteResult(enqueteResult[15]),
              yl20difficulties: convertEnqueteResult(enqueteResult[20]),
              yl25difficulties: convertEnqueteResult(enqueteResult[25]),
              yl30difficulties: convertEnqueteResult(enqueteResult[30]),
              yl35difficulties: convertEnqueteResult(enqueteResult[35]),
              yl40difficulties: convertEnqueteResult(enqueteResult[40]),
              yl45difficulties: convertEnqueteResult(enqueteResult[45]),
              yl50difficulties: convertEnqueteResult(enqueteResult[50]),
              entryPage: entryPage,
            },
          },
        })
          .then((res) => {
            if (res.data?.createYLReassesmentEnquete.__typename === "OK") {
              resolve("ok");
              navigate(finishLinkRecord[entryPage]);
            } else if (
              res.data?.createYLReassesmentEnquete.__typename ===
              "ErrorSuitableYLNotFound"
            ) {
              //TODO: 「お問合せください」のページに飛ばす
              resolve("errorSuitableYLNotFound");
            } else {
              resolve("mutationError");
            }
          })
          .catch(() => {
            resolve("mutationError");
          });
      });
    };

    return (
      <>
        {flow === "welcome" && (
          <Welcome
            onNext={() => {
              setFlow("selectDifficultyPerception");
            }}
            nickname={nickname}
            onQuit={() => setIsOpenQuitModal(true)}
            isLastIndex={isLastIndex}
            setIsLastIndex={setIsLastIndex}
          />
        )}
        {flow === "selectDifficultyPerception" && (
          <SelectDifficultyPerception
            onNext={(difficulty) => {
              setCurrentDifficultyPerception(difficulty);
              setFlow("beforeEnquete");
            }}
            onQuit={() => setIsOpenQuitModal(true)}
            onBack={() => (setIsLastIndex(true), setFlow("welcome"))}
          />
        )}
        {flow === "beforeEnquete" && (
          <BeforeEnquete
            onNext={() => setFlow("enqueteForUser")}
            onQuit={() => setIsOpenQuitModal(true)}
            onBack={() => setFlow("selectDifficultyPerception")}
          />
        )}
        {flow === "enqueteForUser" && (
          <_Enquete
            ylAndSentenceOrderArr={ylAndSentenceOrderList}
            updateEnqueteResult={updateEnqueteResult}
            onNext={() => {
              setFlow("afterEnquete");
            }}
            handleBack={handleBack}
            onQuit={() => setIsOpenQuitModal(true)}
            nickname={nickname}
          />
        )}
        {flow === "afterEnquete" && (
          <AfterEnquete
            onNext={handleSubmitEnquete}
            loading={mutationLoading}
          />
        )}
        {flow === "suitableYlNotFound" && (
          <SuitableYlNotFound
            enqueteType="yl_reassessment_enquete"
            onQuit={onQuit}
          />
        )}
      </>
    );
  }, [
    flow,
    navigate,
    ylAndSentenceOrderList,
    updateEnqueteResult,
    enqueteResult,
    isValid,
    currentDifficultyPerception,
    entryPage,
    nickname,
  ]);

  if (queryLoading) {
    return <LoadingPage />;
  }

  if (queryError) {
    return <>Error! {queryError.message}</>;
  }

  if (!queryData || !currentUser) {
    return null;
  }

  return (
    <>
      <EnqueteQuitModal
        enqueteType="yl_reassessment_enquete"
        isOpen={isOpenQuitModal}
        onQuitEnquete={onQuit}
        onResume={() => setIsOpenQuitModal(false)}
      />
      {content}
    </>
  );
};
