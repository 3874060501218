import { ApolloProvider } from "@apollo/client";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { A2HSProvider } from "~/contexts/A2HSContext";
import { CurrentUserProvider } from "~/contexts/CurrentUserContext";
import "~/utils/sentry";
import "~/utils/serviceWorker";
import { config } from "../config";
import { apolloClient } from "./apollo";
import { App } from "./app";
import { LoadingPage } from "./components/templates/Loading/LoadingPage";
import { Auth0Provider } from "./contexts/Auth0Context";
import {
  AuthSessionProvider,
  useAuthSessionContext,
} from "./contexts/AuthSessionProvider";
import { SwitchUserContextProvider } from "./contexts/SwitchUserContext";
import { initGtag } from "./google_analytics";
import { initMoment } from "./helpers/initializers/moment";
import { Maintenance } from "./pages/503";
import { PublicApp } from "./public_app";
import { theme } from "./styles/theme";
import { initFontAwesomeIcons } from "./utils/fontAwesomeIcon";
import { trackLoginEventIfJustLoggedIn } from "./utils/googleAnalytics/authentication";
import { useUserPropertiesSync } from "./utils/googleAnalytics/useUserPropertiesSync";
import { setupGoogleTagManager } from "./utils/googleTagManager";
import { useMaintenance } from "./utils/hooks/useMaintenance";

initGtag();
initMoment();
setupGoogleTagManager();
initFontAwesomeIcons();

const Main: React.FC = () => {
  const { isAuthenticated, isLoading } = useAuthSessionContext();

  const { isMaintenanceMode, endTime } = useMaintenance();

  React.useEffect(() => {
    if (!isAuthenticated) return;

    trackLoginEventIfJustLoggedIn();
  });

  useUserPropertiesSync();

  if (isMaintenanceMode) {
    return (
      <ThemeProvider theme={theme}>
        <Maintenance endTime={endTime} />
      </ThemeProvider>
    );
  }

  if (isLoading) {
    return (
      <ThemeProvider theme={theme}>
        {" "}
        <LoadingPage />
      </ThemeProvider>
    );
  }

  return (
    <A2HSProvider>
      {isAuthenticated ? (
        <BrowserRouter basename={config.PAGE_PREFIX}>
          <ThemeProvider theme={theme}>
            <CurrentUserProvider>
              <App />
            </CurrentUserProvider>
          </ThemeProvider>
        </BrowserRouter>
      ) : (
        <BrowserRouter basename={config.PAGE_PREFIX}>
          <ThemeProvider theme={theme}>
            <PublicApp />
          </ThemeProvider>
        </BrowserRouter>
      )}
    </A2HSProvider>
  );
};

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <Auth0Provider>
    <SwitchUserContextProvider>
      <ApolloProvider client={apolloClient}>
        <AuthSessionProvider>
          <Main />
        </AuthSessionProvider>
      </ApolloProvider>
    </SwitchUserContextProvider>
  </Auth0Provider>,
  document.getElementById("main")
);
