import React from "react";
import styled from "styled-components";
//import { TranslateKeys, useTranslation } from "~/i18n";
import { Message } from "~/components/molecules/Message";
import { LibraryModalWrapper } from "../../ui/LibraryModalWrapper";
import { CurrentRecommendDifficultyPerception } from "~/generated/graphql";
import { EmojiButton } from "~/components/molecules/buttons/EmojiButton";
import { ColorTheme } from "~/styles/design_token/color";
import { Stack } from "~/components/atoms/layout/Stack";
import { useTranslation } from "~/i18n";
import { YLEnqueteBackNavbar } from "../../ui/YLEnqueteBackNavbar";

export type SelectDifficultyPerceptionProps = {
  onNext: (difficultyPerception: CurrentRecommendDifficultyPerception) => void;
  onQuit: () => void;
  onBack: () => void;
};

type ButtonProps = {
  text: string;
  emoji: string;
  color: ColorTheme;
  onClick: () => void;
};
export const SelectDifficultyPerception: React.FC<
  SelectDifficultyPerceptionProps
> = ({ onNext, onQuit, onBack }) => {
  const { t } = useTranslation();
  const buttons: ButtonProps[] = React.useMemo(
    () => [
      {
        color: "pri",
        emoji: "😋",
        text: "かんたん",
        onClick: () => {
          onNext(CurrentRecommendDifficultyPerception.Easy);
        },
      },
      {
        color: "pri",
        emoji: "😣",
        text: "むずかしい",
        onClick: () => {
          onNext(CurrentRecommendDifficultyPerception.Difficult);
        },
      },
    ],
    [onNext]
  );

  return (
    <>
      <YLEnqueteBackNavbar
        withBlur={true}
        onQuitButton={onQuit}
        onBackButton={onBack}
      />
      <LibraryModalWrapper
        isBright={false}
        showSensei={true}
        senseiImageType="NORMAL"
        heartNum={0}
      >
        <_MessageWrapper>
          <Message
            message={t<TranslateKeys>(
              "今の おすすめの 本は\nかんたん？\nそれとも、むずかしい？"
            )}
            speaker={t<TranslateKeys>("ヨンデミー先生")}
            boxHeight="middle"
          />
        </_MessageWrapper>
        <_ButtonWrapper rowGap="16px">
          {buttons.map((button, index) => (
            <EmojiButton
              key={index}
              text={button.text}
              emoji={button.emoji}
              color={button.color}
              onClick={button.onClick}
              fontSize="MD"
              lineHeight="LG"
              bold
            />
          ))}
        </_ButtonWrapper>
      </LibraryModalWrapper>
    </>
  );
};

const _MessageWrapper = styled.div`
  position: absolute;
  bottom: 27%;
  width: 100%;

  display: flex;
  justify-content: center;
`;

const _ButtonWrapper = styled(Stack)`
  position: absolute;
  bottom: 10%;
  width: 100%;
  max-width: 600px;

  padding: 0px 20px;

  display: flex;
  justify-content: center;
`;
