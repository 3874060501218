import React from "react";
import { DeprecatedRecommendMessage } from "./messageListItems/DeprecatedRecommendMessage";
import { DeprecatedReadLogMessage } from "./messageListItems/DeprecatedReadLogMessage";
import { RemarkMessage } from "./messageListItems/RemarkMessage";
import { AdminRemarks } from "./reactiveMessageListItems/AdminRemarks";
import { Questions } from "./reactiveMessageListItems/Questions";
import { TalkAllFragment, TalkItemSpeaker } from "~/generated/graphql";

/**
 * /messageのトーク一覧のlistItem
 */

export type AdminMessageListItemProps = {
  talk: TalkAllFragment;
  myId: number;
  className?: string;
  index: number;
  currentIndex: number;
  incrementCurrentIndex: () => void;
};

export const AdminMessageListItem: React.VFC<AdminMessageListItemProps> = ({
  myId,
  talk,
  className,
  index,
  currentIndex,
  incrementCurrentIndex,
}) => {
  const userType = !talk.user
    ? "admin"
    : talk.user.__typename === "DeletedUser"
    ? "deletedUser"
    : talk.user.id === myId
    ? "me"
    : "user";

  React.useEffect(() => {
    if (
      (talk.item.__typename === "ReadLog" ||
        talk.item.__typename === "Recommend") &&
      index === currentIndex
    ) {
      incrementCurrentIndex();
    }
  }, [talk.item.__typename, index, currentIndex, incrementCurrentIndex]);

  switch (talk.item.__typename) {
    case "Remark":
      return (
        <RemarkMessage
          id={talk.id}
          item={talk.item}
          user={talk.user}
          userType={userType}
          className={className}
          showAll={index < currentIndex}
          incrementCurrentIndex={incrementCurrentIndex}
          speaker={TalkItemSpeaker.YondemySensei}
        />
      );
    case "ReadLog":
      return (
        <DeprecatedReadLogMessage
          item={talk.item}
          user={talk.item.user}
          userType={userType}
          className={className}
          onReaction={() => Promise.resolve(true)}
          onWantReadBook={() => Promise.resolve(true)}
          onCompleteShowSenseiMessage={() => {
            return;
          }}
        />
      );
    case "Recommend":
      return (
        <DeprecatedRecommendMessage item={talk.item} className={className} />
      );
    case "ReactiveHistory":
      return talk.item.question ? (
        <Questions
          id={talk.id}
          item={talk.item}
          className={className}
          showAll={index !== currentIndex}
          incrementCurrentIndex={incrementCurrentIndex}
          speaker={talk.item.speaker}
        />
      ) : (
        <AdminRemarks
          id={talk.id}
          item={talk.item}
          className={className}
          incrementCurrentIndex={incrementCurrentIndex}
          showAll={index !== currentIndex}
          speaker={talk.item.speaker}
        />
      );
    default:
      return <></>;
  }
};
