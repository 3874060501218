import { TFunction } from "~/i18n";
import { BubbleModalType } from "./hooks";

export const getPositiveCaseItem = (
  nickname: string,
  whenDone: Date,
  likeNum: number,
  feeling: string,
  title: string,
  setCurrentIndex: (idx: number) => void,
  currentIndex: number,
  onClose: () => void,
  t: TFunction
): BubbleModalType => {
  const convertLike = (likeNum: number): string => {
    switch (likeNum) {
      case 1:
        return t<TranslateKeys>("苦手");
      case 2:
        return t<TranslateKeys>("好きではない");
      case 3:
        return t<TranslateKeys>("好き");
      case 4:
        return t<TranslateKeys>("とても好き");
      case 5:
        return t<TranslateKeys>("もう一度読みたい");
      default:
        return t<TranslateKeys>("好き");
    }
  };
  const like = convertLike(likeNum);

  const convertWhenDone = (whenDone: Date): string => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const yesterday = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    );
    yesterday.setDate(yesterday.getDate() - 1);
    const dayBeforeYesterday = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    );
    dayBeforeYesterday.setDate(yesterday.getDate() - 2);

    const submittedDate = new Date(whenDone);
    const diff = ~~((now.getTime() - submittedDate.getTime()) / 1000);
    const num = ~~(diff / 86400);

    if (submittedDate > today) {
      return t<TranslateKeys>("今日");
    } else if (submittedDate > yesterday) {
      return t<TranslateKeys>("昨日");
    } else if (submittedDate > dayBeforeYesterday) {
      return t<TranslateKeys>("一昨日");
    } else {
      return t<TranslateKeys>("{{num}}日前に", { num });
    }
  };
  const day = convertWhenDone(whenDone);

  const randomMessageList = [
    t<TranslateKeys>(
      "きっと {{ nickname }}さんに ピッタリだと 思っていたから、\n{{ like }}って 教えてくれて、とてもうれしかったよ！🥰",
      { nickname, like }
    ),
    t<TranslateKeys>(
      "この本が「{{like}}」ってことは……。\n\n次も とっておきの本を 思いついちゃったよ😎✨",
      { like }
    ),
    feeling !== "" &&
      t<TranslateKeys>(
        "この本は、{{nickname}}さんにとって 「{{feeling}}」な本だったんだね😊",
        { nickname, feeling }
      ),
    t<TranslateKeys>(
      "{{ nickname }}さんにいのちを吹き込まれて とってもうれしそうだったよ💞",
      { nickname }
    ),
  ].filter((message): message is string => Boolean(message));

  const positiveCaseItems = [
    {
      content: t<TranslateKeys>(
        "{{nickname}}さん、{{day}}読んだ『{{title}}』のことは 覚えている？👀✨",
        { nickname, day, title }
      ),
      buttons: [
        { text: "もちろん👍", onClick: () => setCurrentIndex(1) },
        { text: "なんとなく🤔", onClick: () => setCurrentIndex(1) },
      ],
      imageUrl: "/img/sensei/standup_1.svg",
    },
    {
      content:
        randomMessageList[Math.floor(Math.random() * randomMessageList.length)],
      buttons: [{ text: "うんうん😆", onClick: () => setCurrentIndex(2) }],
      imageUrl: "/img/sensei/standup_2.svg",
    },
    {
      content: t<TranslateKeys>("さて、次のおすすめは……😏"),
      buttons: [
        {
          text: "わくわく👀✨",
          onClick: () => {
            onClose();
            setCurrentIndex(0);
          },
        },
        {
          text: "しりたい😆",
          onClick: () => {
            onClose();
            setCurrentIndex(0);
          },
        },
      ],
      imageUrl: "/img/sensei/standup_7.svg",
    },
  ];

  return positiveCaseItems[currentIndex];
};

export const getNegativeCaseItem = (
  nickname: string,
  setCurrentIndex: (idx: number) => void,
  currentIndex: number,
  onClose: () => void,
  t: TFunction
): BubbleModalType => {
  const randomMessageList = [
    t<TranslateKeys>(
      "でも 大丈夫😊\n\nもう一度、{{nickname}}さんにピッタリの本を 考えてみました😃",
      { nickname }
    ),
    t<TranslateKeys>(
      "でもね！\n{{ nickname }}さんの おかげで、たくさんの 本たちが 元気を取り戻したんだ📚✨",
      { nickname }
    ),
  ];

  const negativeCaseItems = [
    {
      content: t<TranslateKeys>(
        "{{nickname}}さん、前回のおすすめには「好き」って思える本がなかったみたいだね🤔",
        { nickname }
      ),
      buttons: [
        { text: "うんうん😅", onClick: () => setCurrentIndex(1) },
        { text: "そうかも🤔", onClick: () => setCurrentIndex(1) },
      ],
      imageUrl: "/img/sensei/standup_1.svg",
    },
    {
      content:
        randomMessageList[Math.floor(Math.random() * randomMessageList.length)],
      buttons: [
        { text: "すごい😲", onClick: () => setCurrentIndex(2) },
        { text: "うれしい😊", onClick: () => setCurrentIndex(2) },
      ],
      imageUrl: "/img/sensei/standup_2.svg",
    },
    {
      content: t<TranslateKeys>("さて、次のおすすめは……😏"),
      buttons: [
        {
          text: "わくわく👀✨",
          onClick: () => {
            onClose();
            setCurrentIndex(0);
          },
        },
        {
          text: "しりたい😆",
          onClick: () => {
            onClose();
            setCurrentIndex(0);
          },
        },
      ],
      imageUrl: "/img/sensei/standup_7.svg",
    },
  ];

  return negativeCaseItems[currentIndex];
};

export const getEmptyCaseItem = (
  nickname: string,
  setCurrentIndex: (idx: number) => void,
  currentIndex: number,
  onClose: () => void,
  t: TFunction
): BubbleModalType => {
  const emptyCaseItems = [
    {
      content: t<TranslateKeys>(
        "{{nickname}}さん、お待たせしました！\n\n新しくおすすめをえらんだよ😆",
        { nickname }
      ),
      buttons: [
        { text: "やった🙌", onClick: () => setCurrentIndex(1) },
        { text: "ありがとう😊", onClick: () => setCurrentIndex(1) },
      ],
      imageUrl: "/img/sensei/standup_1.svg",
    },
    {
      content: t<TranslateKeys>(
        "ヨンデミー先生は まだまだたくさんの本を 知っているよ💪\n\n{{nickname}}さんに ピッタリの本を、たっぷり考えてみました😊",
        { nickname }
      ),
      buttons: [
        { text: "すごい😲", onClick: () => setCurrentIndex(2) },
        { text: "そうなんだ👀✨", onClick: () => setCurrentIndex(2) },
      ],
      imageUrl: "/img/sensei/standup_2.svg",
    },
    {
      content: t<TranslateKeys>("さて、次のおすすめは……😏"),
      buttons: [
        {
          text: "わくわく👀✨",
          onClick: () => {
            onClose();
            setCurrentIndex(0);
          },
        },
        {
          text: "しりたい😆",
          onClick: () => {
            onClose();
            setCurrentIndex(0);
          },
        },
      ],
      imageUrl: "/img/sensei/standup_7.svg",
    },
  ];

  return emptyCaseItems[currentIndex];
};

export const getFirstBookSelectItem = (
  nickname: string,
  setCurrentIndex: (idx: number) => void,
  currentIndex: number,
  onClose: () => void,
  t: TFunction
): BubbleModalType => {
  const firstBookSelectItems = [
    {
      content: t<TranslateKeys>(
        "{{nickname}}さん、お待たせしました！\n\n{{nickname}}さんに とっておきの本を おすすめするよ😆",
        { nickname }
      ),
      buttons: [
        { text: "やった🙌", onClick: () => setCurrentIndex(1) },
        { text: "ありがとう😊", onClick: () => setCurrentIndex(1) },
      ],
      imageUrl: "/img/sensei/standup_1.svg",
    },
    {
      content: t<TranslateKeys>(
        "さっきはヨンデミー先生に いろいろなことを 教えてくれて、ありがとう！\n\nおかげで、{{nickname}}さんには どんな本がぴったりかなー？と、楽しく考えることが できました😌",
        { nickname }
      ),
      buttons: [
        { text: "どういたしまして🤝", onClick: () => setCurrentIndex(2) },
        { text: "そうなんだ👀✨", onClick: () => setCurrentIndex(2) },
      ],
      imageUrl: "/img/sensei/standup_2.svg",
    },
    {
      content: t<TranslateKeys>(
        "さて、そんな{{nickname}}さんへおすすめする本は……😏",
        { nickname }
      ),
      buttons: [
        {
          text: "わくわく👀✨",
          onClick: () => {
            onClose();
            setCurrentIndex(0);
          },
        },
        {
          text: "しりたい😆",
          onClick: () => {
            onClose();
            setCurrentIndex(0);
          },
        },
      ],
      imageUrl: "/img/sensei/standup_7.svg",
    },
  ];

  return firstBookSelectItems[currentIndex];
};
