import React, { FC, useContext } from "react";
import styled, { css } from "styled-components";
import { Text } from "~/components/atoms/texts/Text";
import { BaseModal } from "~/components/atoms/wrappers/modal";
import { PrimaryButton } from "~/components/molecules/buttons/PrimaryButton";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import { sendButtonClickEvent } from "~/utils/googleAnalytics/useButtonClickTracking";

type Props = {
  isOpen: boolean;
  onCancel: () => void;
  onSkip: () => void;
};

export const SkipStoryModal: FC<Props> = ({
  isOpen,
  onCancel,
  onSkip,
}: Props) => {
  const { currentUser } = useContext(CurrentUserContext);

  const onSkipWithGA = () => {
    onSkip();
    sendButtonClickEvent("skip_mission_story", String(currentUser?.general.id));
  };

  return (
    <BaseModal isOpen={isOpen}>
      <_ModalWrapper>
        <BallonCon>
          <Ballon>
            <Text
              lineHeight="MD"
              fontSize="MD"
              bold={false}
              fontColor="base.darkGray"
            >
              ヨンデミーストーリーをスキップしますか？
            </Text>
            <_ButtonWrapper>
              <PrimaryButton
                onClick={onSkipWithGA}
                color="pri"
                text="スキップする"
                lineHeight="EQ"
                fontSize="XL"
                bold={true}
                padding="16px"
              />
              <PrimaryButton
                onClick={onCancel}
                color="gray"
                text="やっぱりやめる"
                lineHeight="EQ"
                fontSize="XL"
                bold={true}
                padding="16px"
              />
            </_ButtonWrapper>
          </Ballon>
        </BallonCon>
      </_ModalWrapper>
    </BaseModal>
  );
};

const _ModalWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: scroll;
`;

const BallonCon = styled.div`
  ${({ theme }) => {
    return css`
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: 96px;

      width: 360px;
      height: 100%;
      @media screen and ${theme.breakPoints.maxWidthSmallSmartPhone} {
        width: 90vw;
      }
      @media (orientation: portrait) {
        width: 360px;
        transform: rotate(90deg);
      }
    `;
  }}
`;

const Ballon = styled.div`
  ${({ theme }) => {
    return css`
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;

      background-color: ${theme.colors.base.white};
      border-radius: ${theme.deprecatedBorderRadius.MD};

      width: fit-content;
      padding: 16px;
      z-index: ${theme.zIndex.modal};
      @media screen and ${theme.breakPoints.maxWidthSmallSmartPhone} {
        padding: 16px 40px;
      }
      @media (orientation: portrait) {
        padding: 16px;
      }
    `;
  }}
`;

const _ButtonWrapper = styled.div`
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.modal};
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;
