import React from "react";
import styled from "styled-components";
import { AutoSizeTextArea } from "~/components/atoms/forms/AutoSizeTextArea";

export type MessageFooterFormProps = {
  value: string;
  onChange: (value: string) => void;
  onSubmit: (event: React.MouseEvent) => void | Promise<void>;
  placeholder?: string;
  disabledSubmit?: boolean;
  disabledInput?: boolean;
  maxRows?: number;
};
export const MessageFooterForm: React.VFC<MessageFooterFormProps> = ({
  value,
  onChange,
  onSubmit,
  placeholder,
  disabledSubmit = false,
  disabledInput = false,
  maxRows = 3,
}: MessageFooterFormProps) => {
  return (
    <Wrapper>
      <FormArea>
        <MessageTextArea
          placeholder={placeholder}
          disabled={disabledInput}
          value={value}
          onChange={(event) => onChange(event.target.value)}
          maxRows={maxRows}
        />
        <SendButton onClick={onSubmit} disabled={disabledSubmit}>
          送信
        </SendButton>
      </FormArea>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  padding: 12px 2.5%;
  background-color: ${(props) => props.theme.colors.base.white};
  border-top: 0.5px solid ${(props) => props.theme.colors.base.gray};
`;

const FormArea = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MessageTextArea = styled(AutoSizeTextArea)`
  height: 1em;
  font-size: 1em;
  padding: 8px 16px;
`;

const SendButton = styled.button`
  font-size: 16px;
  padding: 5px;
  border: none;
  background-color: rgba(0, 0, 0, 0);
  text-align: center;
  font-family: inherit;
  color: ${(props) => props.theme.colors.pri.p400};
  cursor: pointer;
  min-width: 80px;
  @media screen and (max-width: 374px) {
    font-size: 15px;
    min-width: 50px;
    padding: 0;
  }
`;
