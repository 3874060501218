import React, { VFC } from "react";
import styled from "styled-components";
import { Text } from "~/components/atoms/texts/Text";
import { PrimaryButton } from "~/components/molecules/buttons/PrimaryButton";
import { Dialog } from "~/components/molecules/modals/Dialog";

type VoiceModalProps = {
  isOpen: boolean;
  onClose: (event: React.MouseEvent) => void;
};
export const VoiceModal: VFC<VoiceModalProps> = ({ isOpen, onClose }) => {
  return (
    <Dialog
      isOpen={isOpen}
      title={"声で入力する"}
      body={
        <>
          <Text
            fontSize={"SM"}
            lineHeight={"MD"}
            style={{ marginBottom: "12px" }}
          >
            スマートフォン・タブレットをご利用の方は、キーボードのマイクのボタンをおすと、声で感想をかくことができます。
          </Text>
          <GuideImage />
        </>
      }
    >
      <PrimaryButton
        onClick={onClose}
        disabled={false}
        color={"pri"}
        text={"OK"}
        fontSize={"LG"}
        lineHeight={"MD"}
        bold
      />
    </Dialog>
  );
};

const GuideImage: VFC = () => {
  const ua: string = navigator.userAgent;
  if (ua.indexOf("Android") > 0) {
    return (
      <ImgCon>
        <Img
          src={"/img/help/voice_Android.jpg"}
          alt={"キーボードの左下に音声入力ボタンがあります"}
        />
      </ImgCon>
    );
  } else if (
    ua.indexOf("ipad") > -1 ||
    (ua.indexOf("macintosh") > -1 && "ontouchend" in document)
  ) {
    return (
      <ImgCon>
        <Img
          src={"/img/help/voice_iPad.jpg"}
          alt={"キーボードの左下に音声入力ボタンがあります"}
        />
      </ImgCon>
    );
  } else {
    return (
      <ImgCon>
        <Img
          src={"/img/help/voice_iPhone.jpg"}
          alt={"キーボードの左下に音声入力ボタンがあります"}
        />
      </ImgCon>
    );
  }
};

const ImgCon = styled.div`
  display: flex;
  justify-content: center;
`;

const Img = styled.img`
  border-radius: 12px;
  width: 50%;
`;
