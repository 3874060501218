import * as React from "react";
import styled from "styled-components";
import { Text } from "~/components/atoms/texts/Text";
import { useTranslation } from "~/i18n";
import { UserMissionTask } from "~/generated/graphql";
import { MissionTaskTableItem } from "./MissionTaskTableItem";
import { LargeButton, TinyButton } from "~/components/atoms/buttons/Button";
import { Stack } from "~/components/atoms/layout/Stack";

type MissionProgressProps = {
  userMissionTasks: UserMissionTask[];
  gotoLesson: () => void;
  hasTodaysLesson?: boolean;
  openStory: () => void;
};

export const MissionProgress: React.FC<MissionProgressProps> = ({
  userMissionTasks,
  gotoLesson,
  hasTodaysLesson = false,
  openStory,
}: MissionProgressProps) => {
  const { t } = useTranslation<TranslateKeys>();
  return (
    <_Wrapper>
      <_TitleAndButtonWrapper>
        <Text
          fontSize="MD"
          lineHeight="EQ"
          fontColor="semantic.text.heading"
          bold={true}
        >
          ヨンデミッション
        </Text>
        <TinyButton color="tertiary" onClick={openStory} variant="blank">
          <Text
            fontSize="XXS"
            lineHeight="EQ"
            fontColor="semantic.text.body"
            bold={true}
          >
            {t<TranslateKeys>("ストーリーをもう一度見る")}
          </Text>
        </TinyButton>
      </_TitleAndButtonWrapper>
      <Stack rowGap="16px">
        {userMissionTasks.map((task, index) => {
          return (
            <MissionTaskTableItem
              key={index}
              missionTaskType={task.missionTaskType}
              current={task.current}
              goal={task.goal}
            />
          );
        })}
      </Stack>
      <_ButtonWrapper>
        <LargeButton
          disabled={!hasTodaysLesson}
          onClick={gotoLesson}
          color="primary"
        >
          <Text
            fontSize="LG"
            lineHeight="EQ"
            fontColor="semantic.layout.backgroundBox"
            bold={true}
          >
            {t<TranslateKeys>("今日のミニレッスンを受ける")}
          </Text>
        </LargeButton>
      </_ButtonWrapper>
    </_Wrapper>
  );
};

const _Wrapper = styled.div`
  position: relative;
  width: 100%;
  background-color: white;
  padding: 16px;
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

const _TitleAndButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
`;

const _ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
