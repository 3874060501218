import React from "react";
import styled from "styled-components";
import { Text } from "~/components/atoms/texts/Text";
import { PageTitle as BasePageTitle } from "~/components/molecules/texts/PageTitle";
import { PwaTutorialCardItem } from "~/components/organisms/PwaTutorial/PwaTutorialCardItem";
import type { PwaTutorialCardItemProps } from "~/components/organisms/PwaTutorial/PwaTutorialCardItem";
import { CheckBoxWithLabel } from "~/components/molecules/checkboxWithLabel";
import { Stack } from "~/components/atoms/layout/Stack";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "~/components/molecules/buttons/PrimaryButton";
import { A2HSContext } from "~/contexts/A2HSContext";

export type { PwaTutorialCardItemProps };

export type PwsTutorialListProps = {
  items: PwaTutorialCardItemProps[];
};

export const SKIP_PWA_TUTORIAL_LOCALSTORAGE_KEY =
  "skip_pwa_tutorial_localstorage_key";

export const PwsTutorialList: React.FC<PwsTutorialListProps> = ({ items }) => {
  const [isSkipFutureDisplay, setIsSkipFutureDisplay] =
    React.useState<boolean>(false);

  const navigate = useNavigate();
  const { setShowPwaTutorial } = React.useContext(A2HSContext);

  React.useEffect(() => {
    try {
      const _isSkipFutureDisplay = localStorage.getItem(
        SKIP_PWA_TUTORIAL_LOCALSTORAGE_KEY
      );
      if (
        _isSkipFutureDisplay !== null &&
        typeof JSON.parse(_isSkipFutureDisplay) === "boolean"
      ) {
        setIsSkipFutureDisplay(JSON.parse(_isSkipFutureDisplay));
      }
    } catch (e) {
      console.error(e);
    }
  }, []);
  return (
    <Container>
      <TitleContainer>
        <Stack rowGap="12px">
          <PageTitle>子どもアプリをホームから開けるようにしましょう</PageTitle>
          <_Card>
            <_TutorialImg src="/img/pwa/pwa-tutorial.png" />
          </_Card>
          <Text fontSize="SM" lineHeight="MD" fontColor="tex.t500" bold>
            ワンタッチで簡単にアプリを開けるようになります
          </Text>
        </Stack>
      </TitleContainer>
      <ItemsContainer>
        {items.map((item, index) => {
          return (
            <PwaTutorialCardItem
              key={index}
              {...item}
              isHideNext={items.length === index + 1}
            />
          );
        })}
      </ItemsContainer>
      <FooterWrapper>
        <Footer>
          <Description fontSize={"XS"} lineHeight={"MD"}>
            マイページからいつでも設定できます。
          </Description>

          <_Stack rowGap="12px" alignItems="center">
            <FooterButton
              text={"スキップして使う"}
              onClick={() => {
                navigate("/");
                localStorage.setItem(
                  SKIP_PWA_TUTORIAL_LOCALSTORAGE_KEY,
                  String(isSkipFutureDisplay)
                );
                setShowPwaTutorial(false);
              }}
              color="gray"
              fontSize={"MD"}
              lineHeight={"EQ"}
              bold
            />
            <CheckBoxWithLabel
              onClick={() => {
                setIsSkipFutureDisplay((v) => !v);
              }}
              isChecked={isSkipFutureDisplay}
              label="今後も表示しない"
              size="SM"
            />
          </_Stack>
        </Footer>
      </FooterWrapper>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  row-gap: 24px;
`;

const PageTitle = styled(BasePageTitle)`
  color: ${({ theme }) => theme.colors.sec.s400};
  text-align: left;
  line-height: 1.4;
`;

const TitleContainer = styled.div``;

const _Card = styled.div`
  max-width: 560px;
  background: ${({ theme }) => theme.colors.base.white};
  padding: 16px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
`;

const _TutorialImg = styled.img`
  width: 100%;
`;

const Footer = styled.div`
  max-width: 500px;
  margin: 0 auto;
`;

const FooterWrapper = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.base.white};
  padding: 16px;
  border-top: 2px solid ${({ theme }) => theme.colors.base.lightGray};
`;

const ItemsContainer = styled.div`
  padding-bottom: 136px;
`;

const Description = styled(Text)`
  margin-bottom: 8px;
`;

const FooterButton = styled(PrimaryButton)`
  padding: 16px;
`;

const _Stack = styled(Stack)`
  width: 100%;
`;
