import React from "react";
import styled from "styled-components";
import { BfReadLogFragment } from "~/generated/graphql";
import { ChatBubble } from "~/components/atoms/wrappers/ChatBubble";
import { Text } from "~/components/atoms/texts/Text";
import { IMAGE_PATHS } from "~/helpers/constants/imagePath";
import { IconTextButton } from "~/components/molecules/buttons/IconTextButton";

export type SimplifiedReadLogMessageProps = Pick<
  BfReadLogFragment,
  "id" | "readLogInfo"
>;

export const SimplifiedReadLogMessage: React.FC<
  SimplifiedReadLogMessageProps
> = (item) => {
  const isShowReadLog =
    item !== undefined && item.readLogInfo.like !== undefined ? item.readLogInfo.like > 2 : false;
  const bookImageUrl = item.readLogInfo.book.imageUrl
    ? item.readLogInfo.book.imageUrl
    : IMAGE_PATHS.NO_IMAGE;
  const [isReviewExpanded, setIsReviewExpanded] =
    React.useState<boolean>(false);
  return (
    <_ChatBubble variant="white">
      <_BookInfo>
        <_ButtonAndTitleContainer>
          <_BookTitleContainer>
            <Text fontSize="SM" lineHeight="MD" fontColor="primitive.tex.t800">
              {item.readLogInfo.book.title}
            </Text>
            <Text fontSize="XS" lineHeight="MD" fontColor="primitive.tex.t300">
              {item.readLogInfo.book.author}
            </Text>
          </_BookTitleContainer>
        </_ButtonAndTitleContainer>
        <_BookCoverContainer>
          <_Image src={bookImageUrl} alt={item.readLogInfo.book.title} />
        </_BookCoverContainer>
      </_BookInfo>
      {isShowReadLog && item.readLogInfo.review && (
        <_ReasonContainer>
          {item.readLogInfo.review.length > 50 && !isReviewExpanded ? (
            <>
              <Text
                fontSize={"SM"}
                lineHeight={"MD"}
                fontColor="primitive.tex.t500"
              >
                {item.readLogInfo.review.slice(0, 50) + "……"}
              </Text>
              <_ExpandButtonContainer>
                <IconTextButton
                  text={"つづきをよむ"}
                  iconPlace={"pre"}
                  name={"chevronArrowDown"}
                  color={"sec"}
                  onClick={() => setIsReviewExpanded(true)}
                  fontSize={"SM"}
                  lineHeight={"MD"}
                />
              </_ExpandButtonContainer>
            </>
          ) : (
            <Text
              fontSize={"SM"}
              lineHeight={"MD"}
              fontColor="primitive.tex.t500"
            >
              {item.readLogInfo.review}
            </Text>
          )}
        </_ReasonContainer>
      )}
    </_ChatBubble>
  );
};

const _ChatBubble = styled(ChatBubble)`
  width: 260px;
`;

const _BookInfo = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  margin-bottom: 15px;
  border-radius: 8px;
  gap: 5%;
  padding: 8px 12px;
  background: ${({ theme }) => theme.colors.semantic.layout.backgroundMain};
`;

const _ButtonAndTitleContainer = styled.div`
  width: 65%;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

const _BookTitleContainer = styled.div`
  width: 100%;
`;

const _BookCoverContainer = styled.div`
  width: 30%;
  max-width: 160px;
`;

const _Image = styled.img`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.s1half};
`;

const _ReasonContainer = styled.div`
  width: 100%;
`;

const _ExpandButtonContainer = styled.div`
  margin-top: 5px;
`;
