import React from "react";
import styled from "styled-components";
import { Text } from "~/components/atoms/texts/Text";
import { Icon } from "~/components/atoms/Icon";

export type NoteBoxProps = {
  text: string;
};

export const NoteBox: React.FC<NoteBoxProps> = ({ text }: NoteBoxProps) => {
  return (
    <Box>
      <StyledIcon name="pencil" />
      <Text
        fontSize="MD"
        lineHeight="MD"
        bold
        whiteSpace="pre-wrap"
        fontColor="sec.s500"
      >
        {text}
      </Text>
    </Box>
  );
};

const Box = styled.div`
  width: fit-content;
  background-color: ${({ theme }) => theme.colors.base.white};
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledIcon = styled(Icon)`
  height: ${({ theme }) => theme.fontSize.MD};
  color: ${({ theme }) => theme.colors.sec.s500};
`;
