import moment from "moment";
import * as React from "react";
import styled from "styled-components";
import { Text } from "~/components/atoms/texts/Text";
import { useTranslation } from "~/i18n";
import { GetStampModalProps } from "./GetStampModal";
import { LargeButton } from "~/components/atoms/buttons/Button";
import { UserIconType } from "~/generated/graphql";

const DAY_HEIGHT = "86px";

export type StampCardProps = {
  today: Date;
  lessonDates: Date[];
  firstTime?: boolean;
  userIconType: UserIconType;
  gotoLesson: () => void;
  hasTodaysLesson?: boolean;
  getStampModalProps: GetStampModalProps;
};

const iconImages: Record<UserIconType, string> = {
  PENGUIN: "penguin.svg",
  LION: "lion.svg",
  WORM: "bug.svg",
  APPLE: "apple.svg",
  // MEMO: デザインの関係で、スタンプがないキャラクターについては一時的に一律本の虫で対応する
  SHEEP: "bug.svg",
  GIRAFFE: "bug.svg",
  WOLF: "bug.svg",
  DINOSAUR: "bug.svg",
};
const dayColors = {
  日: "red.r400",
  月: "tex.t700",
  火: "tex.t700",
  水: "tex.t700",
  木: "tex.t700",
  金: "tex.t700",
  土: "sec.s600",
} as const;

const DATE_FORMAT = "YYYY-MM-DD";

export const StampCard: React.FC<StampCardProps> = ({
  today,
  firstTime,
  userIconType,
  lessonDates,
  gotoLesson,
  hasTodaysLesson = false,
}: StampCardProps) => {
  const [momentDay, strToday, startDate, strLessonDates] = React.useMemo(() => {
    const _t = moment(today);
    const _tStr = _t.format(DATE_FORMAT);
    const _strLessonDates = lessonDates.map((d) =>
      moment(d).format(DATE_FORMAT)
    );
    return [_t, _tStr, _t.clone().add(-4, "days"), _strLessonDates];
  }, [today, lessonDates]);

  const dayItems = React.useMemo(() => {
    return [...new Array(8)].map((_, i) => {
      const date = startDate.clone().add(i, "days");
      const strDate = date.format(DATE_FORMAT);
      const isToday = strDate === strToday;
      const hasLesson = strLessonDates.includes(strDate);
      const dayOfWeek = date.format("ddd");
      return {
        date,
        hasLesson,
        dateText: date.get("date"),
        dayOfWeek,
        isToday,
        color: isToday ? "pri.p500" : dayColors[dayOfWeek],
      };
    });
  }, [today, lessonDates, startDate]);

  const { t } = useTranslation<TranslateKeys>();

  return (
    <_Wrapper>
      <_CommentWrapper>
        <Text fontSize="XS" lineHeight="EQ" fontColor="tex.t500">
          {t<TranslateKeys>("ミニレッスンを受けると、スタンプがたまるよ！")}
        </Text>
      </_CommentWrapper>
      <_StampCardWrapper firstTime={firstTime}>
        <div className="today-frame" />
        <div className="days">
          {dayItems.map((dayItem, index) => {
            return (
              <div
                key={`d_${index}`}
                className={`day ${
                  index === 0 ? "start-day" : index === 7 ? "end-day" : ""
                }`}
              >
                <_Stamp
                  userIconType={userIconType}
                  hasLesson={dayItem.hasLesson}
                  isAndAfterToday={
                    dayItem.date.isAfter(momentDay) || dayItem.isToday
                  }
                />
                <Text
                  fontSize="MD"
                  lineHeight="EQ"
                  bold
                  fontColor={dayItem.color}
                >
                  {dayItem.dateText || "aaaaaaaa"}
                </Text>
                <Text fontSize="XS" lineHeight="EQ" fontColor={dayItem.color}>
                  {dayItem.dayOfWeek}
                </Text>
              </div>
            );
          })}
        </div>
      </_StampCardWrapper>
      <_ButtonWrapper>
        <LargeButton
          disabled={!hasTodaysLesson}
          onClick={gotoLesson}
          color="primary"
        >
          <Text
            fontSize="LG"
            lineHeight="EQ"
            fontColor="semantic.layout.backgroundBox"
            bold={true}
          >
            {t<TranslateKeys>("今日のミニレッスンを受ける")}
          </Text>
        </LargeButton>
      </_ButtonWrapper>
    </_Wrapper>
  );
};

const _Stamp = styled.div<{
  hasLesson: boolean;
  userIconType: UserIconType;
  isAndAfterToday: boolean;
}>`
  width: 26px;
  height: 29px;

  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${(props) => {
    if (props.hasLesson || props.isAndAfterToday) {
      return `url(/img/stamp_card/${iconImages[props.userIconType]})`;
    }

    return "none";
  }};

  filter: ${(props) =>
    props.isAndAfterToday && !props.hasLesson ? "grayscale(100%)" : "none"};
  opacity: ${(props) =>
    props.isAndAfterToday && !props.hasLesson ? "0.3" : "1"};
`;

const _CommentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px;
  gap: 10px;

  background-color: ${({ theme }) => theme.colors.base.background};
  border-radius: 8px;
`;

const _Wrapper = styled.div`
  position: relative;
  width: 100%;
  background-color: white;
  padding: 16px;
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  row-gap: 7px;
`;

const _StampCardWrapper = styled.div<{ firstTime?: boolean }>`
  height: calc(16px + ${DAY_HEIGHT});
  position: relative;
  width: 100%;
  display: flex;
  align-items: flex-end;

  @keyframes slide {
    from {
      left: 0;
    }
    to {
      left: calc((100% / 7) * -1);
    }
  }

  @keyframes fade {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  .today-frame {
    height: calc(16px + ${DAY_HEIGHT});
    width: calc(100% / 7);
    border: 2px solid orange;
    border-radius: 8px;
    position: absolute;
    left: calc((100% / 7) * 3);
  }

  .days {
    animation: ${(props) =>
      props.firstTime ? "0.5s ease-in-out 0.3s 1 normal slide" : "none"};
    animation-fill-mode: forwards;

    height: ${DAY_HEIGHT};
    width: calc(100% / 7 * 8);
    position: absolute;
    left: ${(props) => (props.firstTime ? "0" : "calc((100% / 7) * -1)")};
    display: flex;
    flex-direction: row;
    column-gap: 0px;

    .day {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      align-items: center;

      width: calc(100% / 7);
    }

    .start-day {
      opacity: ${(props) => (props.firstTime ? "1" : "0")};
      animation: ${(props) =>
        props.firstTime ? "0.5s ease-in-out 0.3s 1 normal fade" : "none"};
      animation-fill-mode: forwards;
    }
    .end-day {
      opacity: ${(props) => (props.firstTime ? "0" : "1")};
      animation: ${(props) =>
        props.firstTime ? "0.5s ease-in-out 0.3s 1 reverse fade" : "none"};
      animation-fill-mode: forwards;
    }
  }
`;

const _ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
