import React from "react";
import styled, { css } from "styled-components";
import { FontSize } from "~/styles/design_token/font";
import { BaseButton } from "./base";

type TextButtonProps = {
  fontSize: FontSize;
  prefix?: React.ReactNode; //アイコン（svg or FontAwesomeIcon)
  suffix?: React.ReactNode; //アイコン
  children: React.ReactNode;
  onClick: (event: React.MouseEvent) => void;
  disabled?: boolean;
};

/**
 * @deprecated 実際にTextButtonを使用する時は、molecules内のTextButtonを使用する
 */
export const TextButton: React.FC<TextButtonProps> = ({
  fontSize,
  prefix,
  suffix,
  children,
  onClick,
  disabled = false,
}) => {
  return (
    <BaseButton
      onClick={onClick}
      disabled={disabled}
      fontSize={fontSize}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {prefix && <Prefix fontSize={fontSize}>{prefix}</Prefix>}
      {children}
      {suffix && <Suffix fontSize={fontSize}>{suffix}</Suffix>}
    </BaseButton>
  );
};

const Prefix = styled.div<{ fontSize: FontSize }>`
  ${({ theme, fontSize }) => {
    return css`
      margin-right: ${theme.size.XS};
      height: ${theme.fontSize[fontSize]};
    `;
  }}
`;

const Suffix = styled.div<{ fontSize: FontSize }>`
  ${({ theme, fontSize }) => {
    return css`
      margin-left: ${theme.size.XS};
      height: ${theme.fontSize[fontSize]};
    `;
  }}
`;
