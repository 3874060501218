import * as React from "react";
import { SubmitReadLogTemplate } from "~/components/templates/PostReadLog/SubmitReadLog";
import {
  ReadStatusForPostReadLog,
  TimeRange,
  useGetMyRecordQuery,
} from "~/generated/graphql";
import { AllEmotionStates } from "../reducer";
import { usePostReadLogState } from "../usePostReadLogState";
import { REVIEW_DATA_KEY } from "./EnterReview";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";

type SubmitReadLogProps = {
  post: () => void;
  loading: boolean;
  nickname: string;
};

type State = {
  bookISBN: string;
  readStatus: ReadStatusForPostReadLog;
  like: number;
  difficulty: number;
  length: number;
  review: string;
} & AllEmotionStates;

export const SubmitReadLog: React.VFC<SubmitReadLogProps> = ({
  post,
  loading,
  nickname,
}) => {
  const { state, dispatch } = usePostReadLogState();
  const { currentUser } = React.useContext(CurrentUserContext);

  const {
    bookISBN,
    readStatus,
    like,
    difficulty,
    length,
    review,
    ...emotions
  }: State = state;

  const myRecord = useGetMyRecordQuery({
    variables: { timeRange: TimeRange.All },
  });

  const handleBack = (): void => {
    dispatch({
      type: "setFlow",
      payload: "enterReview",
    });
  };

  // const handleGoToFavoriteScene = (): void => {
  //   if (readStatus === ReadStatus.Quit) {
  //     return;
  //   } else {
  //     dispatch({
  //       type: "setFlow",
  //       payload: "enterFavoriteScene",
  //     });
  //   }
  // };

  const handleGoToReview = (): void => {
    dispatch({
      type: "setFlow",
      payload: "enterReview",
    });
  };

  const setRecordToLocalStorage = (): void => {
    try {
      localStorage.setItem(
        "TemporarilyStoredReadingRecords",
        JSON.stringify(myRecord.data)
      );
      window.localStorage.removeItem(
        REVIEW_DATA_KEY + "_" + String(currentUser?.general.id) + "_" + bookISBN
      );
    } catch (e) {
      console.error(e);
    }
  };

  const handlePost = (event: React.MouseEvent): void => {
    event.preventDefault();
    if (myRecord == null || myRecord.data == null) {
      return;
    }
    setRecordToLocalStorage();
    post();
  };

  return (
    <SubmitReadLogTemplate
      handleBack={handleBack}
      onClickGoNext={handlePost}
      loading={loading}
      nickname={nickname}
      readStatus={readStatus}
      like={like}
      difficulty={difficulty}
      length={length}
      emotionsObj={emotions}
      review={review}
      onClickReviewRewrite={handleGoToReview}
      // onClickFavoriteSceneRewrite={handleGoToFavoriteScene}
    />
  );
};
