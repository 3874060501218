import styled, { css } from "styled-components";

export const BookItemContainerInterpolation = css`
  margin: 3%;
  opacity: 0.9;
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
  &:active,
  &:focus {
    opacity: 1;
  }
`;

export const BookCoverContainer = styled.div`
  width: 100%;
  position: relative;
  z-index: 0;
`;
