import React from "react";
import styled from "styled-components";
import { Message } from "~/components/molecules/Message";
import { LibraryModalWrapper } from "../../ui/LibraryModalWrapper";
import { TranslateKeys, useTranslation } from "~/i18n";
import { YLEnqueteBackNavbar } from "../../ui/YLEnqueteBackNavbar";
import { LargeButton } from "~/components/atoms/buttons/Button";
import { StandUpImageType } from "~/helpers/constants/imagePath";

export type WelcomeProps = {
  nickname: string;
  onNext: () => void;
  onQuit: () => void;
  isLastIndex: boolean;
  setIsLastIndex: (isLastIndex: boolean) => void;
};

type MessageConfig = {
  message: TranslateKeys;
  senseiImageType: StandUpImageType;
  showButton: boolean;
};

const messageConfigs: MessageConfig[] = [
  {
    message:
      "YL相談所へようこそ！\nヨンデミー先生と一緒に{{ nickname }}さんが読む本を探していこう！",
    senseiImageType: "NORMAL",
    showButton: false,
  },
  {
    message:
      "ヨンデミー先生は、{{ nickname }}さんのことを知ることで、よりパワーアップするんだ！",
    senseiImageType: "FIRE",
    showButton: false,
  },
  {
    message: "ヨンデミー先生とお話ししてくれるかな？",
    senseiImageType: "NORMAL",
    showButton: true,
  },
  {
    message: "ありがとう！\nそれじゃあ、これから出す質問に答えてね。",
    senseiImageType: "NORMAL",
    showButton: false,
  },
];

export const Welcome: React.FC<WelcomeProps> = ({
  onNext,
  nickname,
  onQuit,
  isLastIndex,
  setIsLastIndex,
}) => {
  const { t } = useTranslation();
  const [messageIndex, setMessageIndex] = React.useState<number>(0);
  const [isShowButton, setIsShowButton] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (isLastIndex) {
      setMessageIndex(messageConfigs.length - 1);
    }
  }, [isLastIndex]);

  const handleNext = React.useCallback(() => {
    if (messageIndex >= messageConfigs.length - 1) {
      setIsLastIndex(false);
      onNext();
    } else {
      setMessageIndex((i) => i + 1);
    }
  }, [onNext, messageIndex, setIsLastIndex]);

  const onClickMessage = React.useCallback(() => {
    if (messageConfigs[messageIndex].showButton) {
      return;
    }
    handleNext();
  }, [handleNext, messageIndex]);

  const onEnded = React.useCallback(() => {
    if (!messageConfigs[messageIndex].showButton) {
      return;
    }
    setTimeout(() => {
      setIsShowButton(true);
    }, 300);
  }, [messageIndex]);

  const onClickButton = React.useCallback(() => {
    setIsShowButton(false);
    handleNext();
  }, [handleNext]);

  const handleBack = React.useCallback(() => {
    if (isShowButton) {
      setIsShowButton(false);
    }
    if (messageIndex === 0) {
      onQuit();
    } else {
      setMessageIndex((i) => i - 1);
    }
  }, [onQuit, messageIndex, isShowButton]);

  return (
    <>
      <YLEnqueteBackNavbar
        withBlur={true}
        onQuitButton={onQuit}
        onBackButton={handleBack}
      />
      <LibraryModalWrapper
        isBright={true}
        showSensei={true}
        senseiImageType={messageConfigs[messageIndex].senseiImageType}
      >
        {isShowButton && (
          <_ButtonWrapper>
            <LargeButton color="primary" onClick={onClickButton}>
              はーい
            </LargeButton>
          </_ButtonWrapper>
        )}
        <_MessageWrapper>
          <Message
            message={t<TranslateKeys>(messageConfigs[messageIndex].message, {
              nickname,
            })}
            speaker={t<TranslateKeys>("ヨンデミー先生")}
            onEnded={onEnded}
            boxHeight={"middle"}
            onNext={
              messageConfigs[messageIndex].showButton
                ? undefined
                : onClickMessage
            }
          />
        </_MessageWrapper>
      </LibraryModalWrapper>
    </>
  );
};

const _MessageWrapper = styled.div`
  position: absolute;
  bottom: 15%;
  width: 100%;

  display: flex;
  justify-content: center;
`;

const _ButtonWrapper = styled.div`
  position: absolute;
  bottom: 6%;
  width: 100%;
  max-width: 600px;

  padding: 0px 20px;

  display: flex;
  justify-content: center;
`;
