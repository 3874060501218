import React, { useState, VFC } from "react";
import { useNavigate } from "react-router-dom";
import { EditRecommendFrequencyTemplate } from "~/components/templates/EditRecommendFrequency";
import { LoadingPage } from "~/components/templates/Loading/LoadingPage";
import {
  RecommendFrequency,
  useChangeRecommendFrequencyMutation,
  useGetCurrentRecommendFrequencyQuery,
} from "~/generated/graphql";

export const EditRecommendFrequency: VFC = () => {
  const [recommendFrequency, setRecommendFrequency] = useState(
    "BASIC" as RecommendFrequency
  );
  const [openModal, setOpenModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const { loading, error, data, refetch } =
    useGetCurrentRecommendFrequencyQuery({
      onCompleted: () => {
        setRecommendFrequency(
          data?.me.recommendFrequency as RecommendFrequency
        );
      },
    });
  const [changeRecommendFrequency] = useChangeRecommendFrequencyMutation({
    onCompleted() {
      refetch();
      setModalMessage("おすすめ冊数が変更されました");
      setOpenModal(true);
    },
  });
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault();
    changeRecommendFrequency({
      variables: {
        frequency: recommendFrequency,
      },
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();
    setRecommendFrequency(event.target.value as RecommendFrequency);
  };

  if (loading || !data) return <LoadingPage />;
  if (error) return <>Error! {error.message}</>;
  return (
    <EditRecommendFrequencyTemplate
      recommendFrequency={recommendFrequency}
      onClick={handleClick}
      onChange={handleChange}
      handleBack={() => navigate("/mypage?view=profile")}
      openModal={openModal}
      setOpenModal={setOpenModal}
      modalMessage={modalMessage}
    />
  );
};
