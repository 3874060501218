import { ApolloCache, ApolloClient } from "@apollo/client";
import {
  BfReadLogFragment,
  BfGroupCacheQuery,
  BfGroupCacheDocument,
  BfReadLogFragmentDoc,
  SearchDirection,
} from "~/generated/graphql";
import mergeWith from "lodash/mergeWith";

export function setLoadingBfGroupCache(
  client: ApolloClient<unknown>,
  init: boolean
): void {
  if (init) {
    const queryResult = {
      bfGroupCache: {
        __typename: "BfReadLogCache",
        state: 1,
        digPrevAll: false,
        digNextAll: false,
        latestBfReadLogViewTime: null,
        bfReadLogs: [],
      },
    };
    client.writeQuery({
      query: BfGroupCacheDocument,
      data: queryResult,
    });
  } else {
    const queryResult = client.readQuery<BfGroupCacheQuery>({
      query: BfGroupCacheDocument,
    });
    if (queryResult) {
      const newQueryResult: BfGroupCacheQuery = {
        bfGroupCache: {
          ...queryResult.bfGroupCache,
          state: 1,
        },
      };
      client.writeQuery({
        query: BfGroupCacheDocument,
        data: newQueryResult,
      });
    }
  }
}

export function initBfGroupCache(
  cache: ApolloCache<unknown>,
  count: number,
  bfReadLogs: BfReadLogFragment[],
  latestBfReadLogViewTime?: Date
): void {
  const queryResult = cache.readQuery<BfGroupCacheQuery>({
    query: BfGroupCacheDocument,
  });
  if (queryResult) {
    const newQueryResult: BfGroupCacheQuery = {
      bfGroupCache: Object.assign({}, queryResult.bfGroupCache, {
        state: 2,
        digPrevAll: bfReadLogs.length < count || bfReadLogs.length === 0,
        digNextAll: bfReadLogs.length < count || bfReadLogs.length === 0,
        latestBfReadLogViewTime:
          latestBfReadLogViewTime || new Date().toISOString(),
        bfReadLogs: sortBfReadLogs(bfReadLogs),
      }),
    };
    cache.writeQuery({
      query: BfGroupCacheDocument,
      data: newQueryResult,
    });
  }
}

type TalkType = "BfReadLog";
type CacheId = `${TalkType}:${string}`;

export function modifyBfReadLogBfGroupCache(
  client: ApolloClient<unknown>,
  id: CacheId,
  record: Partial<BfReadLogFragment>
): void {
  const baseQueries = {
    id,
    fragmentName: "BfReadLog",
    fragment: BfReadLogFragmentDoc,
  };
  const existCache = client.readFragment<BfReadLogFragment>(baseQueries);

  client.writeFragment({
    ...baseQueries,
    data: mergeWith({}, existCache, record, (a, b) => {
      return Array.isArray(a) && Array.isArray(b) ? [...a, ...b] : undefined;
    }),
  });
}

export function modifyDigNextAllByGroupCache(
  cache: ApolloCache<unknown>,
  haveUnreadInBfGroupMessage: boolean
): void {
  const queryResult = cache.readQuery<BfGroupCacheQuery>({
    query: BfGroupCacheDocument,
  });
  if (queryResult) {
    const newQueryResult: BfGroupCacheQuery = {
      bfGroupCache: {
        ...queryResult.bfGroupCache,
        digNextAll: haveUnreadInBfGroupMessage,
      },
    };
    cache.writeQuery({
      query: BfGroupCacheDocument,
      data: newQueryResult,
    });
  }
}

export function modifyLatestBfReadLogViewTimeByGroupCache(
  cache: ApolloCache<unknown>,
  latestBfReadLogViewTime: Date
): void {
  const queryResult = cache.readQuery<BfGroupCacheQuery>({
    query: BfGroupCacheDocument,
  });
  if (queryResult) {
    const newQueryResult: BfGroupCacheQuery = {
      bfGroupCache: {
        ...queryResult.bfGroupCache,
        latestBfReadLogViewTime: latestBfReadLogViewTime,
      },
    };
    cache.writeQuery({
      query: BfGroupCacheDocument,
      data: newQueryResult,
    });
  }
}

export function appendToBfGroupCache(
  cache: ApolloCache<unknown>,
  count: number,
  bfReadLogs: BfReadLogFragment[],
  direction: SearchDirection
): void {
  const queryResult = cache.readQuery<BfGroupCacheQuery>({
    query: BfGroupCacheDocument,
  });
  if (queryResult) {
    const cacheBfReadLogs = queryResult.bfGroupCache.bfReadLogs;
    const duplicateOldBfReadLogs = duplicateBfReadLogs(
      cacheBfReadLogs,
      sortBfReadLogs(bfReadLogs)
    );
    const newQueryResult: BfGroupCacheQuery = {
      bfGroupCache: {
        ...queryResult.bfGroupCache,
        state: 2,
      },
    };
    if (direction === SearchDirection.Older) {
      newQueryResult.bfGroupCache.digPrevAll = bfReadLogs.length < count;
      newQueryResult.bfGroupCache.bfReadLogs =
        duplicateOldBfReadLogs.concat(cacheBfReadLogs);
    } else {
      newQueryResult.bfGroupCache.digNextAll = true;
      newQueryResult.bfGroupCache.bfReadLogs = cacheBfReadLogs.concat(
        duplicateOldBfReadLogs
      );
    }
    cache.writeQuery({
      query: BfGroupCacheDocument,
      data: newQueryResult,
    });
  }
}

function sortBfReadLogs(bfReadLogs: BfReadLogFragment[]): BfReadLogFragment[] {
  return bfReadLogs.sort(
    (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
  );
}

function duplicateBfReadLogs(
  current: BfReadLogFragment[],
  target: BfReadLogFragment[]
): BfReadLogFragment[] {
  return target.filter((t) => {
    return !current.find((c) => c.id === t.id);
  });
}
