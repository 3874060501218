import React from "react";
import { MasonryPagiableList } from "~/components/molecules/lists/MasonryPagiableList";
import { ListItemProps } from "~/components/molecules/lists/MasonryPagiableList/types";
import { MasonryPagiableListProps } from "~/components/molecules/lists/MasonryPagiableList/types";
import {
  BookImageLinkListItem,
  BookImageLinkListItemProps,
} from "~/components/molecules/ListItem/BookImageLinkListItem";

type BaseBookImageLinkListProps =
  MasonryPagiableListProps<BookImageLinkListItemProps>;

export type BookImageLinkListProps = Omit<
  BaseBookImageLinkListProps,
  "renderListItem"
>;

export const BookImageLinkList: React.FC<BookImageLinkListProps> = ({
  currentCount,
  items,
  itemTotal,
  itemsPerPage,
  scrollToOffset,
  isLoading,
  onPageChange,
  renderEmptyComponent,
}) => {
  return (
    <MasonryPagiableList
      currentCount={currentCount}
      items={items}
      itemTotal={itemTotal}
      itemsPerPage={itemsPerPage}
      onPageChange={onPageChange}
      isLoading={isLoading}
      scrollToOffset={scrollToOffset}
      renderListItem={({ item }: ListItemProps<BookImageLinkListItemProps>) => {
        const {
          bookId,
          recommendId,
          title,
          imageUrl,
          status,
          columnsNumber,
          showStatus,
        } = item;
        return (
          <BookImageLinkListItem
            key={item.bookId}
            bookId={bookId}
            recommendId={recommendId}
            title={title}
            imageUrl={imageUrl}
            status={status}
            columnsNumber={columnsNumber}
            showStatus={showStatus}
          />
        );
      }}
      renderEmptyComponent={renderEmptyComponent}
    />
  );
};
