import React, { useState, FC } from "react";
import { Text } from "~/components/atoms/texts/Text";
import styled, { useTheme } from "styled-components";
import { BaseModal as OldBaseModal } from "~/components/atoms/wrappers/modal";
import { BaseModal as NewBaseModal } from "~/components/atoms/wrappers/BaseModal";
import {
  ReadLogOnTalkFragment,
  ReadLogReactionType,
  ReadStatus,
  UserIconType,
} from "~/generated/graphql";
import { LargeButton } from "~/components/atoms/buttons/Button";
import { Stack } from "~/components/atoms/layout/Stack";
import { ReactionsButton } from "../../../BookFriendReactions/ReactionsButton";
import { IconButton } from "~/components/molecules/buttons/IconButton";
import { ReactionItem } from "../../../BookFriendReactions/ReactionItem";
import ReactModal from "react-modal";
import { theme } from "~/styles/theme";
import { useNavigate } from "react-router-dom";
import { Snackbar } from "~/components/atoms/Snackbar";
import { ReadLogReactionNoticeItem } from "../../../BookFriendReactions/types";
import { useTranslation } from "~/i18n";

export type TutorialAtTopPageProps = {
  onNext: () => void;
  recentReadLog: ReadLogOnTalkFragment;
};

export const TutorialAtTopPage: FC<TutorialAtTopPageProps> = ({
  onNext,
  recentReadLog,
}) => {
  const [tutorialPage, setTutorialPage] = useState(0);
  const { colors } = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      {tutorialPage === 0 && (
        <OldBaseModal isOpen={true}>
          <Snackbar icon="heart" stickPlace="top" edgeOffset="104px">
            <Text lineHeight="MD" fontSize="SM" fontColor="semantic.text.body">
              {t<TranslateKeys>("通知を 開いてみよう！")}
            </Text>
          </Snackbar>
          <_LeftFloatingStyle>
            <ReactionsButton onClick={() => setTutorialPage(1)} hasUnread />
          </_LeftFloatingStyle>
        </OldBaseModal>
      )}
      {tutorialPage === 1 && (
        <ReactModal
          className="reactions-modal"
          isOpen={true}
          onRequestClose={() => setTutorialPage(2)}
          style={{
            overlay: {
              zIndex: theme.zIndex.modal,
            },
            content: {
              position: "absolute",
              boxSizing: "border-box",
              top: "50vh",
              left: "50vw",
              transform: "translate(-50%, -50%)",
              width: "50vw",
              minWidth: "320px",
              backgroundColor: "white",
              borderRadius: "16px",
              border: `solid ${colors.base.lightGray}`,
            },
          }}
        >
          <_Header>
            リアクション
            <_RightHeaderWrapper>
              <IconButton
                name="cancel"
                onClick={() => setTutorialPage(2)}
                fontSize="MD"
                color="gray"
              />
            </_RightHeaderWrapper>
          </_Header>
          <_ListWrapper>
            <ReactionItem
              items={[
                {
                  ...groupedItems,
                  readLog: recentReadLog,
                },
              ]}
              unread={false}
            />
          </_ListWrapper>
        </ReactModal>
      )}
      {tutorialPage === 2 && (
        <NewBaseModal open={true}>
          <Stack rowGap="16px">
            <Text
              lineHeight="EQ"
              fontSize="XL"
              fontColor="semantic.text.heading"
              bold
            >
              {t<TranslateKeys>("本の友へ ようこそ！")}
            </Text>
            <Text lineHeight="MD" fontSize="MD" fontColor="semantic.text.body">
              {t<TranslateKeys>(
                "この調子で、他の友達の 感想にも いいねを 送ってみよう！"
              )}
              <br />
              {t<TranslateKeys>("きっと 喜んでもらえるよ✨")}
            </Text>
            <img
              src="/img/unlock/characters.svg"
              style={{ maxHeight: "200px" }}
            />
            <_ButtonWrapper>
              <LargeButton
                onClick={() => {
                  onNext();
                  navigate("/bf");
                }}
              >
                {t<TranslateKeys>("本の友にいく")}
              </LargeButton>
            </_ButtonWrapper>
          </Stack>
        </NewBaseModal>
      )}
    </>
  );
};

const groupedItems: ReadLogReactionNoticeItem = {
  __typename: "BfReadLogReactionNoticeItem",
  id: 1,
  reactionType: ReadLogReactionType.Like,
  reactionUser: {
    __typename: "User",
    id: 1,
    nickname: "本の虫",
    userIconType: UserIconType.Worm,
  },
  readLog: {
    id: 1,
    when: "",
    status: ReadStatus.End,
    user: {
      __typename: "User",
      id: 1,
      nickname: "よんでみお",
      userIconType: UserIconType.Lion,
    },
    book: {
      isbn: "xxxxxxxxxxxxx",
      title: "ぴょんたとおしりたんていのおしり大作戦〜花の巻〜",
    },
    myReaction: [],
    isMyWantReadBook: false,
    review:
      "ちいさいおしりとおおきいおしりがケンカして、ちいさいおしりが勝ったのが面白かった。途中で雪が降ったから、おなら攻撃がキャンセルされたのが",
  },
  createdAt: "2022/06/01 03:20:00",
};

const headerHeight = "50px";

const _Header = styled.div`
  color: ${({ theme }) => theme.colors.semantic.text.heading};
  font-size: 16px;
  font-weight: bold;

  height: ${headerHeight};
  border-bottom: solid ${({ theme }) => theme.colors.semantic.layout.border};

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 0px 16px;
`;

const _ListWrapper = styled.div`
  overflow: scroll;
  border-radius: 8px;
`;

const _RightHeaderWrapper = styled.div`
  gap: 8px;
  display: flex;
`;

const _LeftFloatingStyle = styled.div`
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.floatingButton};
  bottom: 40px;
  left: 20px;
`;

const _ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
