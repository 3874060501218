import React, { useContext, useEffect, FC } from "react";
import styled from "styled-components";
import { TopNavbar } from "~/components/organisms/navbars/TopNavbar";
import { useHistoryBookshelf } from "~/components/organisms/Bookshelf/Bookshelf/useHistoryBookshelf";
import { RecordSummary } from "~/components/organisms/MyRecord/RecordSummary";
import { NavContainer } from "../Common";
import { BookshlefTemplateProps } from "./type";
import { useTranslation } from "~/i18n";
import { FloatingIconButton } from "~/components/molecules/buttons/FloatingIconButton";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import { MissionAndStampCarousel } from "~/components/organisms/Bookshelf/MissionAndStampCarousel";
import { userIconImage } from "~/components/organisms/adminMessage/AdminChatMessage";
import { UserIconType } from "~/generated/graphql";
import { BookshelfType } from "~/components/organisms/Bookshelf/Bookshelf/useHistoryBookshelf";

export const BookshlefTemplate: FC<BookshlefTemplateProps> = ({
  onChangeTab,
  onClickReviewButton,
  reactionsButton,
  hash,
  tab = "recommend",
  missionAndStampCarouselProps,
}) => {
  const { t } = useTranslation();
  const { currentUser } = useContext(CurrentUserContext);
  const { isShowMissionProgress, userMissionTasks, stampCardProps, openStory } =
    missionAndStampCarouselProps;

  const historyBookshelfValues = useHistoryBookshelf(tab as BookshelfType);
  const { presentBox, bookshelf } = historyBookshelfValues;

  // 他のタブから戻ったときにスクロール位置をリセット
  useEffect(() => {
    if (hash) {
      window.location.hash = "#" + hash;
    } else {
      window.scrollTo({ top: 0 });
    }
  }, [hash]);

  return (
    <>
      <NavContainer>
        <TopNavbar
          haveUnreadInBfGroupMessage={
            currentUser?.haveUnreadInBfGroupMessage || false
          }
          haveUnreadInMessage={currentUser?.haveUnreadInMessage || false}
          currentTab={1}
          onChangeTab={onChangeTab}
        />
      </NavContainer>
      <_Wrapper>
        <_Container>
          {presentBox}
          <RecordSummary
            user={{
              name: currentUser?.general.nickname || "",
              imageUrl:
                userIconImage[
                  currentUser?.general.userIconType || UserIconType.Penguin
                ],
            }}
          />
          {stampCardProps && userMissionTasks && (
            <MissionAndStampCarousel
              isShowMissionProgress={isShowMissionProgress}
              stampCardProps={stampCardProps}
              userMissionTasks={userMissionTasks}
              openStory={openStory}
            />
          )}
          {bookshelf}
        </_Container>
      </_Wrapper>
      <_LeftFloatingStyle>{reactionsButton}</_LeftFloatingStyle>
      <_RightFloatingStyle>
        <FloatingIconButton
          onClick={onClickReviewButton}
          text={t<TranslateKeys>("感想を書く")}
          fontSize={"LG"}
          name={"pencil"}
          iconPlace={"pre"}
        />
      </_RightFloatingStyle>
    </>
  );
};

const _Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 0 32px;
  background-color: ${({ theme }) =>
    theme.colors.semantic.layout.backgroundMain};
  @media screen and ${({ theme }) =>
      theme.breakPoints.maxWidthSmallSmartPhone} {
    padding: 0 16px;
  }
`;

const _Container = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;

  padding: 100px 0 110px 0;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.size.M};
`;

const _RightFloatingStyle = styled.div`
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.floatingButton};
  bottom: 40px;
  right: 20px;
`;

const _LeftFloatingStyle = styled.div`
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.floatingButton};
  bottom: 40px;
  left: 20px;
`;
