import React from "react";
import styled from "styled-components";
import { Text } from "~/components/atoms/texts/Text";
import { ClosableToast } from "../ClosableToast";

export type ErrorToastProps = {
  text: string;
  isOpen: boolean;
  onClose: () => void;
};

export const ErrorToast: React.FC<ErrorToastProps> = ({
  isOpen,
  onClose,
  text,
}) => {
  return (
    <ClosableToast
      icon="exclamationCircle"
      stickPlace={"top"}
      buttonLabel={"とじる"}
      color="red"
      onClose={onClose}
      isOpen={isOpen}
      closeAfter={7000}
    >
      <ErrorToastMessage fontSize={"SM"} lineHeight={"MD"}>
        {text}
      </ErrorToastMessage>
    </ClosableToast>
  );
};

const ErrorToastMessage = styled(Text)`
  color: ${({ theme }) => theme.colors.red.r400};
`;
