import React from "react";
import styled from "styled-components";
import { Message } from "~/components/molecules/Message";
import { PrimaryButton } from "~/components/molecules/buttons/PrimaryButton";

export type RecoverModalProps = {
  onRestore: (e: React.MouseEvent) => void;
  onClose: (e: React.MouseEvent) => void;
};

export const RecoverModal: React.FC<RecoverModalProps> = (
  props: RecoverModalProps
) => {
  return (
    <_Wrapper>
      <_Container>
        <Message
          message={`ストーリーを中断しました。\nマナーモードや音量設定が0の場合、\nよみきかせが流れません。\n設定を確認してから、\nストーリーを再開してください。`}
          showTriangle={false}
        />
        <PrimaryButton
          onClick={props.onRestore}
          text="再開する"
          color="pri"
          fontSize="MD"
          lineHeight="MD"
          bold
        />
        <PrimaryButton
          onClick={props.onClose}
          text="最初からはじめる"
          color="pri"
          fontSize="MD"
          lineHeight="MD"
          bold
        />
      </_Container>
    </_Wrapper>
  );
};

const _Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.base.white};
  display: flex;
  justify-content: center;
  overflow: scroll;
`;

const _Container = styled.div`
  margin: auto;
  width: 340px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 16px;
  @media (orientation: portrait) {
    transform: rotate(90deg);
  }
`;
