import * as React from "react";
import styled from "styled-components";
import { RemarkAllFragment, TalkItemSpeaker } from "~/generated/graphql";

const ogpPattern = /(<ogp.+\/>)/g;

type Params = {
  userType: string;
  item: RemarkAllFragment;
  speaker?: TalkItemSpeaker;
};

const parseMessageTags = ({
  userType,
  item,
}: Params): [string, React.ReactNode[]] => {
  if (userType === "me") {
    return [item.text, []];
  }
  let _t = item.text;
  const _atts: React.ReactNode[] = [];

  // OGP
  const m = _t.match(ogpPattern);
  if (m && m.length > 0) {
    m.forEach((_m, i) => {
      _t = _t.replace(_m, "");
      const url = _m.match(/url="([^"]+)"/);
      const image = _m.match(/image="([^"]+)"/);
      const title = _m.match(/title="([^"]+)"/);

      if (url && image) {
        _atts.push(
          <_OGPContainer
            image={image[1]}
            key={`att_${i}`}
            href={url[1]}
            target="_blank"
            rel="noopener"
          >
            {title ? title[1] : ""}
          </_OGPContainer>
        );
      }
    });
  }

  return [_t, _atts];
};

const _OGPContainer = styled.a<{ image: string }>`
  padding: 12px;
  display: inline-block;
  width: 270px;
  height: 140px;
  background-image: url(${({ image }) => image});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 1;

  :hover {
    opacity: 0.8;
  }
`;

export const useParsedMessage = ({
  userType,
  item,
}: Params): {
  text: string;
  attachments: React.ReactNode[];
} => {
  const [text, attachments] = React.useMemo(() => {
    return parseMessageTags({ userType, item });
  }, [userType, item]);

  return {
    text,
    attachments,
  };
};
