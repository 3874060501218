import React, { VFC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled, { css } from "styled-components";
import { ColorTheme } from "~/styles/design_token/color";
import { ColorInterpolation } from "~/styles/interpolation";
import { RibbonIcon } from "./InlineSvgIcons";

// NOTE: アプリケーションで使用するアイコン一覧
export const fontawesomeIcons = {
  question: {
    prefix: "fas",
    iconName: "question-circle",
  },
  pencil: {
    prefix: "fas",
    iconName: "pencil-alt",
  },
  arrowLeft: {
    prefix: "fas",
    iconName: "arrow-left",
  },
  arrowRight: {
    prefix: "fas",
    iconName: "arrow-right",
  },
  book: {
    prefix: "fas",
    iconName: "book",
  },
  bookmark: {
    prefix: "fas",
    iconName: "bookmark",
  },
  check: {
    prefix: "fas",
    iconName: "check",
  },
  clipboard: {
    prefix: "fas",
    iconName: "clipboard-list",
  },
  clipboardCheck: {
    prefix: "fas",
    iconName: "clipboard-check",
  },
  crown: {
    prefix: "fas",
    iconName: "crown",
  },
  file: {
    prefix: "fas",
    iconName: "file",
  },
  microphone: {
    prefix: "fas",
    iconName: "microphone",
  },
  plus: {
    prefix: "fas",
    iconName: "plus",
  },
  skull: {
    prefix: "fas",
    iconName: "skull-crossbones",
  },
  search: {
    prefix: "fas",
    iconName: "search",
  },
  camera: {
    prefix: "fas",
    iconName: "camera",
  },
  copy: {
    prefix: "fas",
    iconName: "copy",
  },
  cancel: {
    prefix: "fas",
    iconName: "times",
  },
  bell: {
    prefix: "fas",
    iconName: "bell",
  },
  close: {
    prefix: "fas",
    iconName: "times-circle",
  },
  chevronRight: {
    prefix: "fas",
    iconName: "chevron-right",
  },
  chevronArrowDown: {
    prefix: "fas",
    iconName: "chevron-down",
  },
  medal: {
    prefix: "fas",
    iconName: "medal",
  },
  dottedLine: {
    prefix: "fas",
    iconName: "ellipsis-h",
  },
  cog: {
    prefix: "fas",
    iconName: "cog",
  },
  heart: {
    prefix: "fas",
    iconName: "heart",
  },
  infoCircle: {
    prefix: "fas",
    iconName: "info-circle",
  },
  star: {
    prefix: "fas",
    iconName: "star",
  },
  trash: {
    prefix: "fas",
    iconName: "trash",
  },
  exclamation: {
    prefix: "fas",
    iconName: "exclamation",
  },
  exclamationCircle: {
    prefix: "fas",
    iconName: "exclamation-circle",
  },
  externalLink: {
    prefix: "fas",
    iconName: "external-link-alt",
  },
  envelope: {
    prefix: "fas",
    iconName: "envelope",
  },
  exclamationTriangle: {
    prefix: "fas",
    iconName: "exclamation-triangle",
  },
  volumeHigh: {
    prefix: "fas",
    iconName: "volume-up",
  },
  volumeLow: {
    prefix: "fas",
    iconName: "volume-down",
  },
  circleArrowLeft: {
    prefix: "fas",
    iconName: "arrow-alt-circle-left",
  },
  bars: {
    prefix: "fas",
    iconName: "bars",
  },
  comment: {
    prefix: "fas",
    iconName: "comment",
  },
  cloudArrowButton: {
    prefix: "fas",
    iconName: "cloud-arrow-down",
  },
  print: {
    prefix: "fas",
    iconName: "print",
  },
  clone: {
    prefix: "fas",
    iconName: "clone",
  },
  share: {
    prefix: "fas",
    iconName: "share-nodes",
  },
  commentDots: {
    prefix: "fas",
    iconName: "comment-dots",
  },
  thumbsUp: {
    prefix: "fas",
    iconName: "thumbs-up",
  },
  gear: {
    prefix: "fas",
    iconName: "gear",
  },
  caretDown: {
    prefix: "fas",
    iconName: "caret-down",
  },
  graph: {
    prefix: "fas",
    iconName: "chart-column",
  },
  film: {
    prefix: "fas",
    iconName: "film",
  },
  caretUp: {
    prefix: "fas",
    iconName: "caret-up",
  },
  forwardFast: {
    prefix: "fas",
    iconName: "forward-fast",
  },
  eye: {
    prefix: "fas",
    iconName: "eye",
  },
  eyeSlash: {
    prefix: "fas",
    iconName: "eye-slash",
  },
  grinWink: {
    prefix: "fas",
    iconName: "grin-wink",
  },
} as const;

export const customIconPaths = {
  present: "/img/icon/present.svg",
  openedPresent: "/img/icon/opened-present.svg",
};

type InlineCustomIconType = "ribbon";

const icons = {
  ...fontawesomeIcons,
  ...customIconPaths,
};

export type IconType = keyof typeof icons | InlineCustomIconType;

export type IconProps = {
  /**
   * アイコン名
   */
  name: IconType;
  /**
   * FontAwesomeIconのsvg要素に適用するCSS
   */
  style?: React.CSSProperties;
  /**
   * カラーテーマ
   */
  color?: ColorTheme;
  /**
   * Class名
   */
  className?: string;
  /**
   * Iconがクリックされたときに発火
   */
  onClick?: (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => void | Promise<void>;
};

export const Icon: VFC<IconProps> = ({
  name,
  style,
  color,
  className,
  onClick,
}) => {
  if (fontawesomeIcons[name]) {
    return (
      <StyledIcon
        style={style}
        icon={fontawesomeIcons[name]}
        color={color}
        className={className}
        onClick={onClick}
      />
    );
  }
  if (customIconPaths[name]) {
    return (
      <StyledImageIcon style={style} src={customIconPaths[name]} alt={name} />
    );
  }

  switch (name) {
    case "ribbon":
      return <RibbonIcon fill={color} style={style} className={className} />;
    default:
      throw new Error("not found icon");
  }
};

const StyledIcon = styled(FontAwesomeIcon)<Pick<IconProps, "color">>`
  ${() => {
    return css`
      width: 1em;
      height: 1em;
      display: flex;
      align-items: center;
      ${ColorInterpolation.Sub}
    `;
  }}
`;

const StyledImageIcon = styled.img<Pick<IconProps, "color">>`
  ${() => {
    return css`
      width: auto;
      height: 100%;
      object-fit: contain;
      display: flex;
      align-items: center;
      ${ColorInterpolation.Sub}
    `;
  }}
`;
