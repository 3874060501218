import React from "react";
import styled from "styled-components";
import { LargeButton } from "~/components/atoms/buttons/Button";
import { Icon } from "~/components/atoms/Icon";
import { Row } from "~/components/atoms/layout/Row";
import { Heading4 } from "~/components/atoms/texts/Heading4";
import { Text } from "~/components/atoms/texts/Text";
import { RecommendStatus } from "~/generated/graphql";
import { IMAGE_PATHS } from "~/helpers/constants/imagePath";
import { useCopyToClipboard } from "~/store/bookViewer/useCopyToClipboard";
import { theme } from "~/styles/theme";
import { useTranslation } from "~/i18n";

type BookInfoCardProps = {
  title?: string;
  imageUrl?: string;
  recommendStatus?: RecommendStatus;
  isRecommend?: boolean;
  isbn: string;
  author?: string;
  onReserve: () => void;
  yl?: number;
};

type RecommendStatusInfo = {
  backgroundColor: string;
  fontColor: string;
  status: string;
};

const recommendStatusInfo: Record<
  Exclude<RecommendStatus, "QUIT" | "DISCARDED">,
  RecommendStatusInfo
> = {
  "DEFAULT": {
    backgroundColor: theme.colors.primitive.monotone.m40,
    fontColor: theme.colors.semantic.text.body,
    status: "よんでない",
  },
  "READING": {
    backgroundColor: theme.colors.primitive.red.r0,
    fontColor: theme.colors.primitive.red.r400,
    status: "よんでいる",
  },
  "ENDED": {
    backgroundColor: theme.colors.primitive.green.g100,
    fontColor: theme.colors.primitive.green.g600,
    status: "よんだ",
  },
};

export const BookInfoCardWithButtons: React.FC<BookInfoCardProps> = ({
  title,
  imageUrl,
  recommendStatus,
  isRecommend,
  isbn,
  author,
  yl,
  onReserve,
}) => {
  const { t } = useTranslation();
  const { handleCopyToClipboard, copyToClipboardPopup } = useCopyToClipboard();
  const showRecommendStatus =
    recommendStatus === RecommendStatus.Default ||
    recommendStatus === RecommendStatus.Reading ||
    recommendStatus === RecommendStatus.Ended;
  return (
    <_Wrapper>
      <_InfoContainer>
        {(isRecommend || showRecommendStatus) && (
          <Row columnGap="4px">
            {isRecommend && <_RecommendMarker>おすすめ</_RecommendMarker>}
            {showRecommendStatus && (
              <_RecommendStatus recommendStatus={recommendStatus}>
                {recommendStatusInfo[recommendStatus].status}
              </_RecommendStatus>
            )}
          </Row>
        )}
        {yl && (
          <_YLMarker>
            <Text
              fontColor="semantic.primary.plus1"
              fontSize="XS"
              bold
              lineHeight="MD"
            >
              YL.{Math.floor(yl / 100)}
            </Text>
          </_YLMarker>
        )}
        <Heading4
          color="semantic.text.heading"
          fontSize="XL"
          bold
          lineHeight="EQ"
        >
          {title}
        </Heading4>
        <Text
          fontColor="semantic.text.heading"
          fontSize="XS"
          lineHeight="EQ"
          bold
        >
          {author}
        </Text>
        <_BookImg src={imageUrl || IMAGE_PATHS.NO_IMAGE} alt={title} />
        <_LargeButton onClick={onReserve} variant="blank">
          {t<TranslateKeys>("図書館で予約")}
        </_LargeButton>
        <_LargeButton
          onClick={() => handleCopyToClipboard(isbn)}
          variant="blank"
        >
          <Row columnGap="8px">
            <Text
              fontSize="SM"
              fontColor="semantic.text.heading"
              lineHeight="EQ"
            >
              ISBN
            </Text>
            <Text
              fontSize="SM"
              fontColor="semantic.primary.main"
              lineHeight="EQ"
            >
              {isbn}
            </Text>
            <Icon name="copy" />
          </Row>
        </_LargeButton>
      </_InfoContainer>
      {copyToClipboardPopup}
    </_Wrapper>
  );
};

const _Wrapper = styled.div`
  width: 100%;
  max-width: 600px;
  padding: 16px;

  border: 1px solid ${({ theme }) => theme.colors.primitive.monotone.m40};
  border-radius: 12px;
  background-color: ${({ theme }) =>
    theme.colors.semantic.layout.backgroundBox};
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 12px;
`;

const _BookImg = styled.img`
  height: 160px;
  margin: 0 auto;
  object-fit: contain;
  border-radius: 8px;
`;

const _InfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

const _RecommendStatus = styled.div<{ recommendStatus: RecommendStatus }>`
  width: fit-content;
  padding: 2px 8px;
  background-color: ${({ recommendStatus }) =>
    recommendStatusInfo[recommendStatus].backgroundColor};
  color: ${({ recommendStatus }) =>
    recommendStatusInfo[recommendStatus].fontColor};
  border-radius: 12px;
  font-size: ${({ theme }) => theme.fontSize.XS};
  line-height: ${({ theme }) => theme.lineHeight.MD};
  font-weight: bold;
`;

const _RecommendMarker = styled.div`
  width: fit-content;
  padding: 2px 8px;
  background-color: ${({ theme }) => theme.colors.semantic.primary.main};
  color: ${({ theme }) => theme.colors.primitive.monotone.m0};
  border-radius: 12px;
  font-size: ${({ theme }) => theme.fontSize.XS};
  line-height: ${({ theme }) => theme.lineHeight.MD};
  font-weight: bold;

  display: flex;
  column-gap: 4px;
  align-items: center;

  ::before {
    width: 16px;
    height: 16px;
    content: "";
    background-image: url("/img/sensei/icon_smile.jpg");
    background-size: contain;
    border-radius: 50%;
  }
`;

const _LargeButton = styled(LargeButton)`
  max-width: 600px;
`;

const _YLMarker = styled.div`
  width: fit-content;
  padding: 0px 5px;
  background-color: ${({ theme }) => theme.colors.primitive.orange.o100};
  border-radius: 4px;
`;
