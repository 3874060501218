import React from "react";
import styled from "styled-components";
import { YouTubeIframe } from "~/components/atoms/YouTubeIframe";
import { MessageItemText } from "~/components/molecules/texts/MessageItemText";
import { AdminChatMessage, UserType } from "../../../AdminChatMessage";
import { DeletedUserOnTalkFragment, RemarkAllFragment, TalkItemSpeaker, UserOnTalkFragment } from "~/generated/graphql";
import { useParsedMessage } from "../parseMessageTags";
import { omitUrl } from "../omitUrl";
import { useReplaceMessageToRecommend } from "../useReplaceMessageToRecommend";

export type RemarkMessageProps = {
  id: number;
  item: RemarkAllFragment;
  user?: UserOnTalkFragment | DeletedUserOnTalkFragment;
  userType: UserType;
  className?: string;
  showAll: boolean;
  incrementCurrentIndex: () => void;
  speaker: TalkItemSpeaker;
};
export const RemarkMessage: React.FC<RemarkMessageProps> = ({
  id,
  item,
  user,
  userType,
  className,
  showAll,
  incrementCurrentIndex,
  speaker,
}) => {
  const { text: _text, attachments } = useParsedMessage({
    userType,
    item,
  });
  const { replaceMessageToRecommend } = useReplaceMessageToRecommend();

  if (userType === "me" && !showAll) {
    incrementCurrentIndex();
  }
  const { text, imageUrl, youtubeUrl } = omitUrl(
    replaceMessageToRecommend(_text, id)
  );

  return (
    <AdminChatMessage
      user={user}
      userType={userType}
      className={className}
      attachments={attachments}
      speaker={speaker}
    >
      {userType === "me" ? (
        <MessageItemText
          textType="plain_me"
          showAll={true}
          onCompleted={() => {
            return;
          }}
          text={text}
        />
      ) : (
        <>
          <MessageItemText
            textType="plain"
            showAll={showAll}
            onCompleted={incrementCurrentIndex}
            text={text}
          />
          {youtubeUrl && (
            <YouTubeContainer>
              <YouTubeIframe
                url={
                  "https://www.youtube.com/embed/" +
                  youtubeUrl.substr(youtubeUrl.indexOf("youtu.be") + 9, 11)
                }
              />
            </YouTubeContainer>
          )}
          {imageUrl && <img src={imageUrl} style={{ width: "100%" }} />}
        </>
      )}
    </AdminChatMessage>
  );
};

const YouTubeContainer = styled.div`
  position: relative;
  margin: 10px 0;
  width: 63vw;
  height: 35.43vw;
  max-width: 451px;
  max-height: 254px;
  overflow: hidden;
  & iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: none;
  }
`;
