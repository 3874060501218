import React, { VFC } from "react";
import { useNavigate } from "react-router-dom";
import { EditKanziLevelTemplate } from "~/components/templates/EditKanziLevel";
import { KanziLevelFormState } from "~/components/templates/EditKanziLevel/type";
import { LoadingPage } from "~/components/templates/Loading/LoadingPage";
import {
  KanziLevel,
  useGetMyKanziLevelQuery,
  useUpdateKanziLevelMutation,
} from "~/generated/graphql";

export const EditKanziLevel: VFC = () => {
  const [kanziLevelForm, setKanziLevelForm] =
    React.useState<KanziLevelFormState>({
      kanziLevel: KanziLevel.L1,
    });
  const onChangeKanziLevel = (
    key: keyof typeof kanziLevelForm,
    value: KanziLevel
  ): void => {
    setKanziLevelForm({
      ...kanziLevelForm,
      [key]: value,
    });
  };

  const navigate = useNavigate();
  const { loading, error, data, refetch } = useGetMyKanziLevelQuery({
    onCompleted: () => {
      setKanziLevelForm({
        kanziLevel: data?.me.kanziLevel ?? (null as unknown as KanziLevel),
      });
    },
  });

  const [updateProfile] = useUpdateKanziLevelMutation({
    update(cache, result) {
      result.data?.updateAccount &&
        cache.modify({
          fields: {
            me(currentMe) {
              return Object.assign(
                {},
                currentMe,
                result.data?.updateAccount || {}
              ); //何も分からん
            },
          },
        });
    },
    onCompleted() {
      refetch();
      navigate("/mypage?view=profile");
    },
  });

  const handleUpdate = (): void => {
    const { kanziLevel } = kanziLevelForm;
    updateProfile({
      variables: {
        accountInfo: {
          kanziLevel,
        },
      },
    });
  };

  if (loading || !data) return <LoadingPage />;
  if (error) return <>Error! {error.message}</>;
  return (
    <>
      <EditKanziLevelTemplate
        kanziLevelForm={kanziLevelForm}
        onChangeKanziLevel={onChangeKanziLevel}
        handleUpdate={handleUpdate}
      />
    </>
  );
};
