import React from "react";
import { theme } from "~/styles/theme";

type HeartProps = {
  isFilled: boolean;
};

export const Heart: React.FC<HeartProps> = ({ isFilled }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="21"
      viewBox="0 0 24 21"
      fill="none"
    >
      <path
        d="M24 5.34371C23.7733 4.27149 23.2853 3.27078 22.5788 2.42912C21.8723 1.58746 20.9688 0.93051 19.9473 0.515771C18.9258 0.101031 17.8174 -0.0588424 16.7193 0.0501004C15.6211 0.159043 14.5666 0.533475 13.6479 1.14068C13.0522 1.54759 12.5238 2.04388 12.0814 2.61177C11.626 2.04829 11.0913 1.55282 10.4937 1.14068C9.11767 0.220307 7.44777 -0.163187 5.80438 0.0637608C4.16099 0.290708 2.65986 1.11211 1.58916 2.37037C0.518462 3.62863 -0.0463406 5.23506 0.00297748 6.88143C0.0522956 8.52779 0.712242 10.0981 1.85637 11.291L11.0229 20.8739L11.1499 21H11.2769H11.4463H12.526H12.6954H12.8224L12.9494 20.8739L22.1159 11.291C22.875 10.5004 23.4281 9.53765 23.727 8.48648C24.0258 7.43532 24.0614 6.32756 23.8306 5.25963"
        fill={isFilled ? "#E83828" : theme.colors.primitive.monotone.m100}
      />
    </svg>
  );
};
