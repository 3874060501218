import { RecommendFrequency } from "~/generated/graphql";

type listContents = {
  name: string;
  bookListMax: number;
};

const constList: Record<RecommendFrequency, listContents> = {
  LITTLE: {
    name: "じっくりコース",
    bookListMax: 5,
  },
  BASIC: {
    name: "どんどんコース",
    bookListMax: 8,
  },
  MANY: {
    name: "たっぷりコース",
    bookListMax: 10,
  },
  ENOUGH: {
    name: "まとめてコース",
    bookListMax: 15,
  },
  BULK: {
    name: "どさっとコース",
    bookListMax: 20,
  },
};

export default constList;
