import React from "react";
import styled, { css } from "styled-components";
import Confetti from "react-confetti";
import { MissionOrder } from "~/generated/graphql";
import { BaseModal } from "~/components/atoms/wrappers/modal";
import { Text } from "~/components/atoms/texts/Text";
import { Interpolation } from "~/styles/interpolation";
import { PrimaryButton } from "~/components/molecules/buttons/PrimaryButton";
import { useAudio } from "~/utils/hooks/useAudio";
import { missionOrderToDisplayMaterial } from "..";

type MissionClearModalProps = {
  missionOrder: MissionOrder;
  onClick: () => void;
};

export const MissionClearModal: React.FC<MissionClearModalProps> = ({
  missionOrder,
  onClick,
}: MissionClearModalProps) => {
  const displayMaterial = missionOrderToDisplayMaterial[missionOrder];

  const [isOpen, setIsOpen] = React.useState<boolean>(true);

  const { play } = useAudio({ preload: ["fanfare"] });

  React.useEffect(() => {
    if (isOpen) {
      play("fanfare");
    }
  }, [isOpen, play]);

  const onClose = () => {
    setIsOpen(false);
    onClick();
  };

  return (
    <BaseModal isOpen={isOpen}>
      <BallonCon>
        <Ballon>
          <_TitleWrapper>
            <Text lineHeight="EQ" fontSize="XL" bold={true} marginBottom="8px">
              ヨンデミッション その{displayMaterial.number}
            </Text>
          </_TitleWrapper>
          <_Img src="/img/orange_yondemy_logo.svg" />
          <_TextWrapper>クリア</_TextWrapper>
        </Ballon>
        <_ButtonWrapper>
          <PrimaryButton
            onClick={onClose}
            color="pri"
            text="やったー！"
            lineHeight="EQ"
            fontSize="XL"
            bold={true}
            padding="16px"
          />
        </_ButtonWrapper>
      </BallonCon>
      <Confetti width={window.innerWidth} height={window.innerHeight} />
    </BaseModal>
  );
};

const Ballon = styled.div`
  ${({ theme }) => {
    return css`
      display: flex;
      flex-direction: column;
      gap: 16px;

      background-color: ${theme.colors.base.white};
      border-radius: ${theme.deprecatedBorderRadius.MD};

      width: 100%;
      padding: 16px 53px;
      z-index: ${theme.zIndex.modal};
      @media screen and ${theme.breakPoints.maxWidthSmallSmartPhone} {
        padding: 16px 40px;
      }
    `;
  }}
`;

const BallonCon = styled.div`
  ${({ theme }) => {
    return css`
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: 96px;

      width: 335px;
      height: 100%;
      @media screen and ${theme.breakPoints.maxWidthSmallSmartPhone} {
        width: 90vw;
      }
    `;
  }}
`;

const _TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${Interpolation.BorderBottom}
`;

const _Img = styled.img`
  width: 100%;
`;

const _TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 4px;
  font-size: ${({ theme }) => theme.fontSize.ULTRAL};
  font-weight: bold;
  color: ${({ theme }) => theme.colors.pri.p500};
`;

const _ButtonWrapper = styled.div`
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.modal};
`;
