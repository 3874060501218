import React from "react";
import { CongratulateUnlockModal } from "../CongratulateUnlockModal";

export type UnlockEnterFreeReviewModalProps = {
  onClose: (e: React.MouseEvent) => void;
  nickname: string;
};

export const UnlockEnterFreeReviewModal: React.FC<UnlockEnterFreeReviewModalProps> = ({
  onClose,
  nickname,
}) => {
  return (
    <CongratulateUnlockModal
      isOpen={true}
      onClose={onClose}
      nickname={nickname}
      lockedFeature="enterFreeReview"
    />
  );
};
