import React, { useMemo } from "react";
import { CommonStyle } from "../../Common";
import { Text } from "~/components/atoms/texts/Text";
import { useTranslation } from "~/i18n";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import styled from "styled-components";

export type OnboardingTipsProps = {
  loginTime: number;
  onClick: () => void;
};

export type ModalContent = {
  text: React.ReactNode;
  buttonLabel: string;
};

export const OnboardingTips: React.FC<OnboardingTipsProps> = ({
  loginTime,
  onClick,
}) => {
  const { t } = useTranslation();
  const { currentUser } = React.useContext(CurrentUserContext);
  const nickname = currentUser?.general.nickname;
  const userID = currentUser?.general.id;
  const LOCAL_STORAGE_KEY = "modalContentIndex" + userID?.toString();
  const [pageIndex, setPageIndex] = React.useState<number>(0);

  const modalContentIndex: number = useMemo(() => {
    if (loginTime <= 16) {
      return (loginTime - 1) % 8;
    } else {
      try {
        const value = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (value === null) {
          return 0;
        } else {
          return (parseInt(value, 10) + 1) % 8;
        }
      } catch (e) {
        console.error(e);
        return 0;
      }
    }
  }, [loginTime, LOCAL_STORAGE_KEY]);

  const onClickButton = (): void => {
    if (pageIndex === onboardingTipsContents[modalContentIndex].length - 1) {
      onClick();
      try {
        localStorage.setItem(
          LOCAL_STORAGE_KEY,
          JSON.stringify(modalContentIndex)
        );
      } catch (e) {
        console.error(e);
      }
    } else {
      setPageIndex(pageIndex + 1);
    }
  };

  const onboardingTipsContents: ModalContent[][] = [
    [
      {
        text: (
          <Text fontColor="tex.t700" fontSize="MD" lineHeight="MD">
            {t<TranslateKeys>("ヨンデミーの心得　その1")}
            <br />
            <_OrangeSpan>{t<TranslateKeys>("読まなくてもよし！")}</_OrangeSpan>
            <br />
            {t<TranslateKeys>(
              "読みたい 本を 読みたい ときに 楽しんで 読もう✨"
            )}
          </Text>
        ),
        buttonLabel: t<TranslateKeys>("読みたいときに読む！"),
      },
    ],
    [
      {
        text: (
          <Text fontColor="tex.t700" fontSize="MD" lineHeight="MD">
            {t<TranslateKeys>("ヨンデミーの心得　その2")}
            <br />
            <_OrangeSpan>
              {t<TranslateKeys>("自分で選んでもよし！")}
            </_OrangeSpan>
            <br />
            {t<TranslateKeys>(
              "{{user}}さんが 選んだ 本を ヨンデミー先生に 教えてね。",
              { user: nickname }
            )}
          </Text>
        ),
        buttonLabel: t<TranslateKeys>("自分でも選ぶ！"),
      },
    ],
    [
      {
        text: (
          <Text fontColor="tex.t700" fontSize="MD" lineHeight="MD">
            {t<TranslateKeys>("ヨンデミーの心得　その3")}
            <br />
            <_OrangeSpan>
              {t<TranslateKeys>("簡単な 本も 楽しんでよし！")}
            </_OrangeSpan>
            <br />
            {t<TranslateKeys>("簡単な 本を 深く 楽しむのも 立派な 読書家だよ")}
          </Text>
        ),
        buttonLabel: t<TranslateKeys>("簡単な本も 楽しむ！"),
      },
    ],
    [
      {
        text: (
          <Text fontColor="tex.t700" fontSize="MD" lineHeight="MD">
            {t<TranslateKeys>("ヨンデミーの心得　その4")}
            <br />
            <_OrangeSpan>
              {t<TranslateKeys>("途中で 感想を 出してもよし！")}
            </_OrangeSpan>
            <br />
            {t<TranslateKeys>(
              "1日で 読み切らなくても、途中までの 感想を 教えてくれると 嬉しいな"
            )}
          </Text>
        ),
        buttonLabel: t<TranslateKeys>("途中でも 出していい！"),
      },
    ],
    [
      {
        text: (
          <Text fontColor="tex.t700" fontSize="MD" lineHeight="MD">
            {t<TranslateKeys>(
              "{{user}}さんは、いつも どんなときに 本を 読んでいるかな?\n夜 寝る前、ご飯の後 など、好きな タイミングで",
              { user: nickname }
            )}
            &nbsp;
            <_OrangeSpan>{t<TranslateKeys>("毎日の 読書時間")}</_OrangeSpan>
            {t<TranslateKeys>("を 作ってみよう✨")}
          </Text>
        ),
        buttonLabel: t<TranslateKeys>("ふむふむ"),
      },
    ],
    [
      {
        text: (
          <Text fontColor="tex.t700" fontSize="MD" lineHeight="MD">
            {t<TranslateKeys>("ヨンデミー先生は いつも、")}
            <_OrangeSpan>
              {t<TranslateKeys>("{{user}}さんの 感想をもとに", {
                user: nickname,
              })}
            </_OrangeSpan>
            {t<TranslateKeys>("、次の おすすめの 本を 探しているよ✨")}
          </Text>
        ),
        buttonLabel: t<TranslateKeys>("そうなんだ"),
      },
      {
        text: (
          <Text fontColor="tex.t700" fontSize="MD" lineHeight="MD">
            {t<TranslateKeys>(
              "{{user}}さんに ぴったりの 本を おすすめするために、",
              { user: nickname }
            )}
            <_OrangeSpan>
              {t<TranslateKeys>("自分の 気持ちに 合った 感想を 選ぶ")}
            </_OrangeSpan>
            {t<TranslateKeys>("ようにしてね😉")}
          </Text>
        ),
        buttonLabel: t<TranslateKeys>("はーい🙌"),
      },
    ],
    [
      {
        text: (
          <Text fontColor="tex.t700" fontSize="MD" lineHeight="MD">
            <_OrangeSpan>
              {t<TranslateKeys>("ヨンデミーレベル（YL）")}
            </_OrangeSpan>
            {t<TranslateKeys>(
              "っていうのは、本の 難しさ のことだよ！\n{{user}}さんに ピッタリの ヨンデミーレベルの 本を 見つけてくるから、楽しみに していてね",
              { user: nickname }
            )}
          </Text>
        ),
        buttonLabel: t<TranslateKeys>("なるほど！"),
      },
    ],
    [
      {
        text: (
          <Text fontColor="tex.t700" fontSize="MD" lineHeight="MD">
            <_OrangeSpan>{t<TranslateKeys>("レベル")}</_OrangeSpan>
            {t<TranslateKeys>(
              "は {{user}}さんの 読書家としての 頑張りが 積み重なって いくもの！\nレベル100まで いけるかな？",
              { user: nickname }
            )}
          </Text>
        ),
        buttonLabel: t<TranslateKeys>("いくぞー！"),
      },
    ],
  ];

  return (
    <CommonStyle
      loginTipsButtonProps={{
        text: onboardingTipsContents[modalContentIndex][pageIndex].buttonLabel,
        onClick: onClickButton,
      }}
    >
      {onboardingTipsContents[modalContentIndex][pageIndex].text}
    </CommonStyle>
  );
};

const _OrangeSpan = styled.span`
  font-weight: bold;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.semantic.primary.main};
`;
