import React from "react";
import {
  MissionOrder,
  MissionProgress,
  useStartMissionMutation,
} from "~/generated/graphql";
import { StartMissionFlow } from ".";

type StartMissionFlowContainerProps = {
  missionOrder: MissionOrder;
  goToBookshelf: () => void;
};

export const StartMissionFlowContainer: React.FC<
  StartMissionFlowContainerProps
> = ({ missionOrder, goToBookshelf }: StartMissionFlowContainerProps) => {
  const [startMissionMutation, { loading }] = useStartMissionMutation();

  const handleStartMission = React.useCallback((): Promise<
    MissionProgress | undefined
  > => {
    return new Promise<MissionProgress | undefined>((resolve) => {
      startMissionMutation()
        .then((res) => {
          if (res.errors) {
            alert("エラーが発生しました");
            resolve(undefined);
          } else {
            resolve(res.data?.startMission);
          }
        })
        .catch((e) => {
          console.log(e);
          alert("エラーが発生しました");
          resolve(undefined);
        });
    });
  }, [startMissionMutation]);

  return (
    <StartMissionFlow
      missionOrder={missionOrder}
      handleStartMission={handleStartMission}
      goToBookshelf={goToBookshelf}
      loading={loading}
    />
  );
};
