import React from "react";
import styled from "styled-components";
import { Stack } from "~/components/atoms/layout/Stack";
import { Text } from "~/components/atoms/texts/Text";
import { LargeButton } from "~/components/atoms/buttons/Button";
import { NavContainer } from "~/components/templates/Common";
import { BackNavbar } from "~/components/molecules/navbars/BackNavbar";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import { Row } from "~/components/atoms/layout/Row";
import {
  BookLevel,
  CharacterAssessmentList,
  RecommendedBookList,
} from "./const";
import { useTranslation } from "~/i18n";
import { CharacterType } from "~/generated/graphql";

export type ResultProps = {
  characterType: CharacterType;
  suitableYL: number;
  onClose: () => void;
};

const getBookLevel = (YL: number): BookLevel => {
  if (YL < 25) return "beginner";
  if (YL < 35) return "intermediate";
  return "advanced";
};

export const Result: React.FC<ResultProps> = ({
  characterType,
  suitableYL,
  onClose,
}) => {
  const { currentUser } = React.useContext(CurrentUserContext);
  const nickname = currentUser?.general.nickname || "";
  const { t } = useTranslation();
  const bookLevel = getBookLevel(suitableYL);

  const character = CharacterAssessmentList[characterType];
  const books = RecommendedBookList[characterType][bookLevel];

  return (
    <_Wrapper>
      <NavContainer>
        <BackNavbar titleName="診断結果" onClick={onClose} iconType="cancel" />
      </NavContainer>
      <_Stack rowGap="24px" alignItems="center">
        <_CharacterBox backgroundColor={character.backgroundColor}>
          <Stack rowGap="12px" alignItems="center">
            <Stack rowGap="0px" alignItems="center">
              <_LargeText fontSize="XL" lineHeight="MD" bold>
                {nickname}さんを
                <br />
                {t<TranslateKeys>("本生物にたとえるなら......")}
              </_LargeText>
            </Stack>
            <_Img src={character.imageUrl} />
            <_SubBox>
              <Text
                fontSize="XS"
                lineHeight="MD"
                bold
                style={{ textAlign: "center", whiteSpace: "pre-wrap" }}
              >
                {t<TranslateKeys>(character.remark, { nickname })}
              </Text>
            </_SubBox>
            <_LargeText fontSize="XL" lineHeight="MD" bold>
              {t<TranslateKeys>(character.name)}
            </_LargeText>
          </Stack>
        </_CharacterBox>

        <_Box>
          <Stack rowGap="12px">
            <Text fontSize="SM" lineHeight="EQ" bold>
              おすすめジャンル
            </Text>
            <Text fontSize="SM" lineHeight="MD">
              <_BoldSpan>
                {t<TranslateKeys>(character.recommendedGenre)}
              </_BoldSpan>
            </Text>
          </Stack>
        </_Box>

        <_Box>
          <Stack rowGap="12px">
            <Text fontSize="SM" lineHeight="EQ" bold>
              {t<TranslateKeys>("おすすめ理由")}
            </Text>
            <Text fontSize="SM" lineHeight="MD">
              {t<TranslateKeys>(character.name)}は、
              <_BoldSpan>
                {t<TranslateKeys>(character.charPersonality)}
              </_BoldSpan>
              ！
              <br />
              {t<TranslateKeys>(character.userPersonality)}
              &nbsp;
              {nickname}さんも、{t<TranslateKeys>(character.name)}
              {t<TranslateKeys>("と 仲良くなれそう！")}
              <br />
              そんな{nickname}さんには&nbsp;
              <_BoldSpan>{t<TranslateKeys>(character.bookType)}</_BoldSpan>
              がおすすめ😊
            </Text>
          </Stack>
        </_Box>

        <_Row>
          <_Icon src={character.iconUrl} />
          <_ChatBubble>
            <Text fontSize="SM" lineHeight="MD" bold>
              {t<TranslateKeys>(character.pronoun)}
            </Text>
          </_ChatBubble>
        </_Row>

        <_Box>
          <Stack rowGap="12px">
            <Text fontSize="SM" lineHeight="EQ" bold>
              {t<TranslateKeys>("おすすめの本")}
            </Text>
            <Text fontSize="SM" lineHeight="MD">
              {t<TranslateKeys>("こんな本たちを おすすめするよ。")}
              <br />
              {t<TranslateKeys>(
                "他にも いろんな本を おすすめするので、楽しみにしていてね😉"
              )}
            </Text>
            {books.map((book, index) => (
              <_BookCard key={index}>
                <Row columnGap="12px" alignItems="flex-start">
                  <_BookImg src={book.imgUrl} />
                  <Stack rowGap="4px">
                    <Text fontSize="MD" lineHeight="MD" bold>
                      {book.title}
                    </Text>
                    <Text fontSize="XS" lineHeight="MD">
                      {book.author}
                    </Text>
                  </Stack>
                </Row>
              </_BookCard>
            ))}
          </Stack>
        </_Box>

        <_ButtonWrapper>
          <LargeButton color="primary" onClick={onClose}>
            {t<TranslateKeys>("診断結果をとじる")}
          </LargeButton>
        </_ButtonWrapper>
      </_Stack>
    </_Wrapper>
  );
};

const _Row = styled(Row)`
  width: 100%;
`;

const _BookImg = styled.img`
  width: 80px;
  min-height: 110px;
  object-fit: cover;
  border-radius: 8px;
`;

const _BookCard = styled.div`
  background-color: ${({ theme }) =>
    theme.colors.semantic.layout.backgroundBox};
  padding: 12px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.primitive.monotone.m40};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
`;

const _ChatBubble = styled.div`
  width: 100%;
  background-color: ${({ theme }) =>
    theme.colors.semantic.layout.backgroundBox};
  padding: 16px;
  border-radius: 16px 16px 16px 0;
`;

const _Img = styled.img`
  width: 30%;
`;

const _Icon = styled.img`
  width: 50px;
  height: 50px;
`;

const _LargeText = styled(Text)`
  color: ${({ theme }) => theme.colors.semantic.layout.backgroundBox};
  text-align: center;
`;

const _BoldSpan = styled.span`
  font-weight: bold;
`;

const _Wrapper = styled.div`
  min-height: 100vh;
  background-color: ${({ theme }) =>
    theme.colors.semantic.layout.backgroundMain};
  padding: 56px 0;
`;

const _Stack = styled(Stack)`
  max-width: 520px;
  margin: 0 auto;
  padding: 20px;
`;

const _CharacterBox = styled.div<{ backgroundColor: string }>`
  width: 100%;
  background-color: ${(props) => props.backgroundColor};
  padding: 16px;
  border-radius: 16px;
`;

const _SubBox = styled.div`
  width: 100%;
  background-color: ${({ theme }) =>
    theme.colors.semantic.layout.backgroundBox};
  padding: 8px;
  border-radius: 12px;
`;

const _Box = styled.div`
  width: 100%;
  background-color: ${({ theme }) =>
    theme.colors.semantic.layout.backgroundBox};
  padding: 16px;
  border-radius: 16px;
`;

const _ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
