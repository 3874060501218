import React from "react";
import styled from "styled-components";
import { Text } from "~/components/atoms/texts/Text";
import { ReadingTechniqueType } from "~/generated/graphql";
import { StarIcon } from "../star";
import { readingTechniqueTypeStr } from "../const";
import { IconTextButton } from "~/components/molecules/buttons/IconTextButton";
import { TinyButton } from "~/components/atoms/buttons/Button";
import { useSnd } from "~/utils/hooks/useSnd";
import { useTranslation } from "react-i18next";

export type SelectStarModalFooterProps = {
  handleNext: (readingTechniqueType: ReadingTechniqueType) => void;
  onClose: () => void;
  onOpenDescriptionModal: () => void;
  isOpenDescriptionModal: boolean;
};

export const SelectStarModalFooter: React.FC<SelectStarModalFooterProps> = ({
  handleNext,
  onClose,
  onOpenDescriptionModal,
  isOpenDescriptionModal,
}) => {
  const { play } = useSnd();

  const { t } = useTranslation();
  return (
    <_Wrapper isOpenDescriptionModal={isOpenDescriptionModal}>
      <_Container>
        <Text fontSize="XS" lineHeight="MD" fontColor="semantic.text.body">
          {"どの「どくしょかのワザ」を" +
            t<TranslateKeys>("使った") +
            t<TranslateKeys>("感想") +
            "かな？"}
        </Text>
        <_StarButtonContainer>
          {Object.values(ReadingTechniqueType).map((readingTechniqueType) => (
            <_StarButton
              key={readingTechniqueType}
              onClick={() => {
                handleNext(readingTechniqueType);
                play("TAP");
              }}
            >
              <StarIcon
                readingTechniqueType={readingTechniqueType}
                size={20}
                checked={false}
              />
              <Text
                fontSize="SM"
                lineHeight="MD"
                fontColor="semantic.text.body"
              >
                {readingTechniqueTypeStr[readingTechniqueType]}
              </Text>
            </_StarButton>
          ))}
        </_StarButtonContainer>
        <IconTextButton
          iconPlace="pre"
          color="tex"
          text={"どくしょかの7つのワザとは"}
          onClick={onOpenDescriptionModal}
          name="question"
          fontSize="XS"
          lineHeight="MD"
          fontColor="semantic.text.body"
        />
        <TinyButton color="gray" onClick={onClose}>
          とじる
        </TinyButton>
      </_Container>
    </_Wrapper>
  );
};

const _Wrapper = styled.div<{ isOpenDescriptionModal: boolean }>`
  width: 100vw;
  background-color: ${({ theme }) => theme.colors.primitive.monotone.m0};
  border: 2px solid ${({ theme }) => theme.colors.semantic.layout.border};
  border-bottom: none;
  border-radius: 16px 16px 0 0;

  padding: 16px 20px;
  display: flex;
  justify-content: center;
`;

const _Container = styled.div`
  width: 335px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 12px;
`;

const _StarButtonContainer = styled.div`
  display: grid;
  grid-template-columns: max-content max-content max-content;
  grid-template-rows: 1fr 1fr 1fr;
  column-gap: 16px;
  row-gap: 12px;
`;

const _StarButton = styled.button`
  /* reset css */
  background-color: transparent;
  border: none;
  cursor: pointer;
  &:focus,
  &:active {
    outline: none;
  }
  padding: 0;
  appearance: none;
  /* reset cssここまで */

  display: flex;
  justify-content: flex-start;
  column-gap: 2px;
`;
