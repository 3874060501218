import React, { VFC } from "react";
import styled, { css } from "styled-components";
import { ColorTheme } from "~/styles/design_token/color";
import { ButtonInterpolation } from "~/styles/interpolation";
import { useSnd } from "~/utils/hooks/useSnd";
import { RadioButtonProps } from "../type";

export const SolidRadioButton: VFC<RadioButtonProps> = ({
  selected,
  disabled = false,
  onClick,
  shape,
  color,
  children,
}) => {
  const { play } = useSnd();
  const handleClick = (event: React.MouseEvent) => {
    play("TAP");
    onClick(event);
  };
  return (
    <ButtonCon disabled={disabled}>
      <StyledButton
        selected={selected}
        shape={shape}
        disabled={disabled}
        color={color}
        onClick={handleClick}
      >
        {children}
      </StyledButton>
    </ButtonCon>
  );
};

const StyledButton = styled.button<
  Pick<RadioButtonProps, "selected" | "shape" | "disabled" | "color">
>`
  ${({ theme, selected, shape, disabled, color }) => {
    const {
      colors,
      deprecatedBorderRadius: borderRadius,
      lineHeight,
      size,
    } = theme;
    const { base, pri, sec, violet, green, red, sky } = colors;
    const MOTION_RANGE = size.BUTTON_MOTION_RANGE_SUB;
    return css`
      width: 100%;
      background-color: ${base.white};
      box-shadow: 0 ${MOTION_RANGE} 0
        ${() => {
          switch (color) {
            case "pri":
              return pri.p600;
            case "sec":
              return sec.s600;
            case "violet":
              return violet.v600;
            case "green":
              return green.g600;
            case "red":
              return red.r600;
            case "sky":
              return sky.s600;
            case "gray":
              return base.gray;
            case "white":
              return "rgba(255,255,255,0.5)";
            default:
              return pri.p600;
          }
        }};
      border: 3px solid
        ${() => {
          switch (color) {
            case "pri":
              return pri.p600;
            case "sec":
              return sec.s600;
            case "violet":
              return violet.v600;
            case "green":
              return green.g600;
            case "red":
              return red.r600;
            case "sky":
              return sky.s600;
            case "gray":
              return base.gray;
            case "white":
              return "rgba(255,255,255,0.5)";
            default:
              return pri.p600;
          }
        }};
      & p {
        color: ${() => {
          switch (color) {
            case "pri":
              return pri.p600;
            case "sec":
              return sec.s600;
            case "violet":
              return violet.v600;
            case "green":
              return green.g600;
            case "red":
              return red.r600;
            case "sky":
              return sky.s600;
            case "gray":
              return base.gray;
            case "white":
              return "rgba(255,255,255,0.5)";
            default:
              return pri.p600;
          }
        }};
      }
      ${shape === "Square" && ButtonInterpolation.Square};
      ${shape === "RoundedCorner" && ButtonInterpolation.RoundedCorner};
      padding: 8px 0;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      cursor: ${!disabled && "pointer"};
      border-radius: ${borderRadius.MD};
      font-family: inherit;
      font-weight: bold;
      text-align: center;
      text-decoration: none;
      line-height: ${lineHeight.MD};
      margin-bottom: ${MOTION_RANGE};
      ${selected && SelectedInterpolation(color, MOTION_RANGE, disabled)}
      &:focus {
        outline: none;
      }
      &:active {
        outline: none;
        ${SelectedInterpolation(color, MOTION_RANGE, disabled)}
      }
    `;
  }}
`;

const ButtonCon = styled.div<Pick<RadioButtonProps, "disabled">>`
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
`;

const SelectedInterpolation = (
  color: ColorTheme,
  MOTION_RANGE: string,
  disabled?: boolean
) => {
  if (!disabled) {
    return css`
      background-color: ${({ theme }) => {
        switch (color) {
          case "pri":
            return theme.colors.pri.p600;
          case "sec":
            return theme.colors.sec.s600;
          case "violet":
            return theme.colors.violet.v600;
          case "green":
            return theme.colors.green.g600;
          case "red":
            return theme.colors.red.r600;
          case "sky":
            return theme.colors.sky.s600;
          case "gray":
            return theme.colors.base.gray;
          case "white":
            return theme.colors.base.white;
          default:
            return theme.colors.pri.p600;
        }
      }};
      box-shadow: ${!disabled && "none"};
      transform: ${!disabled && `translateY(${MOTION_RANGE})`};
      & p {
        color: ${({ theme }) => theme.colors.base.white};
      }
    `;
  }
};
