import React from "react";
import { CommonStyle } from "../../Common";
import { Text } from "~/components/atoms/texts/Text";
import { TranslateKeys, useTranslation } from "~/i18n";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";

export type ReadLogReviewTipsProps = {
  bookTitle: string;
  onClick: () => void;
};

export type ModalContent = {
  text: React.ReactNode;
  buttonLabel: string;
};

export const ReadLogReviewTips: React.FC<ReadLogReviewTipsProps> = ({
  bookTitle,
  onClick,
}) => {
  const { t } = useTranslation();
  const { currentUser } = React.useContext(CurrentUserContext);
  const nickname = currentUser?.general.nickname;

  const reviewContents: string[] = [
    t<TranslateKeys>("素敵だった"),
    t<TranslateKeys>("面白かった"),
    t<TranslateKeys>("楽しかった"),
    t<TranslateKeys>("共感できた"),
  ];

  const reviewContentsIndex = Math.floor(Math.random() * reviewContents.length);

  const review = reviewContents[reviewContentsIndex];

  return (
    <CommonStyle
      loginTipsButtonProps={{
        text: t<TranslateKeys>("やったー😀"),
        onClick: onClick,
      }}
    >
      {
        <Text fontColor="tex.t700" fontSize="MD" lineHeight="MD">
          {t<TranslateKeys>(
            "昨日 読んでいた『{{title}}』の {{user}}さんの 感想を 見ました！\nとっても {{review}}よ！\n今日も、本の 感想を 聞かせてくれると 嬉しいな😊",
            { title: bookTitle, user: nickname, review: review }
          )}
        </Text>
      }
    </CommonStyle>
  );
};
