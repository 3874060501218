import React, { useContext } from "react";
import {
  MissionStory,
  STORY_PAGE_SESSION_STORAGE_KEY,
} from "~/components/organisms/modals/QuestLevel/MissionStory";
import { TomorrowMissionModal } from "~/components/organisms/modals/QuestLevel/MissionModal/TomorrowMissionModal";
import { PlayMissionStoryModal } from "~/components/organisms/modals/QuestLevel/MissionModal/PlayMissionStoryModal";
import { MissionOrder } from "~/generated/graphql";
import { initAudioContext } from "~/utils/hooks/useAudio";
import { MissionClearModal } from "~/components/organisms/modals/QuestLevel/MissionModal/MissionClearModal";
import { RecoverModal } from "~/components/organisms/modals/QuestLevel/MissionModal/StoryRecoverModal";
import { NextMissionTrailer } from "~/components/organisms/modals/QuestLevel/MissionModal/NextMissionTrailer";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import { UnlockUserIconModal } from "src/components/organisms/modals/QuestLevel/MissionModal/UnlockUserIconModal";
import { getUnlockIcon } from "~/components/templates/CompleteMissionFlow/const";
import { LoadingPage } from "~/components/templates/Loading/LoadingPage";

type CompleteMissionFlowProps = {
  missionOrder: MissionOrder;
  handleCompleteMission: () => Promise<MissionOrder | undefined>;
  goToBookshelf: () => void;
  loading: boolean;
};

type CompleteMissionFlowState =
  | "MissionClearModal"
  | "PlayMissionStoryModal"
  | "MissionStory"
  | "NextMissionTrailer"
  | "TomorrowMissionModal"
  | "UnlockUserIcon";

export const CompleteMissionFlow: React.FC<CompleteMissionFlowProps> = ({
  missionOrder,
  handleCompleteMission,
  goToBookshelf,
  loading,
}: CompleteMissionFlowProps) => {
  const [flowState, setFlowState] =
    React.useState<CompleteMissionFlowState>("MissionClearModal");
  const [isError, setIsError] = React.useState<boolean>(false);
  const { currentUser } = useContext(CurrentUserContext);
  const nickname = currentUser?.general.nickname;

  const [isOpenRecoverModal, setIsOpenRecoverModal] = React.useState<boolean>(
    Boolean(sessionStorage.getItem(STORY_PAGE_SESSION_STORAGE_KEY))
  );
  const [restartPageNumber, setRestartPageNumber] = React.useState<number>(0);

  const onClickToStartStory = React.useCallback((e: React.MouseEvent) => {
    try {
      initAudioContext(e);
    } catch {
      console.error("Failed to initialize audio context");
    }
    setFlowState("MissionStory");
  }, []);

  const onStoryCompleted = React.useCallback(() => {
    handleCompleteMission()
      .then((res) => {
        if (res) {
          setFlowState("NextMissionTrailer");
          try {
            sessionStorage.removeItem(STORY_PAGE_SESSION_STORAGE_KEY);
          } catch (e) {
            console.error(e);
          }
        } else {
          goToBookshelf();
        }
      })
      .catch(() => {
        setIsError(true);
      });
  }, [goToBookshelf, handleCompleteMission]);
  const unlockedIcons = getUnlockIcon(missionOrder, "COMPLETE");
  const pageNumber = Number(
    sessionStorage.getItem(STORY_PAGE_SESSION_STORAGE_KEY)
  );

  if (isOpenRecoverModal) {
    return (
      <RecoverModal
        onRestore={(event: React.MouseEvent) => {
          initAudioContext(event);
          setRestartPageNumber(isNaN(pageNumber) ? 0 : pageNumber);
          setIsOpenRecoverModal(false);
          setFlowState("MissionStory");
        }}
        onClose={(event: React.MouseEvent) => {
          initAudioContext(event);
          try {
            sessionStorage.removeItem(STORY_PAGE_SESSION_STORAGE_KEY);
          } catch (e) {
            console.error(e);
          }
          setIsOpenRecoverModal(false);
        }}
      />
    );
  }

  if (loading) return <LoadingPage />;

  switch (flowState) {
    case "MissionClearModal":
      return (
        <MissionClearModal
          missionOrder={missionOrder}
          onClick={() => setFlowState("PlayMissionStoryModal")}
        />
      );
    case "PlayMissionStoryModal":
      return (
        <PlayMissionStoryModal
          missionOrder={missionOrder}
          storyType="Complete"
          onClick={onClickToStartStory}
          isError={isError}
        />
      );
    case "MissionStory":
      return (
        <MissionStory
          missionOrder={missionOrder}
          storyType="Complete"
          onCompleted={onStoryCompleted}
          startPage={restartPageNumber}
        />
      );
    case "NextMissionTrailer":
      return (
        <NextMissionTrailer
          missionOrder={missionOrder}
          onNext={() => setFlowState("TomorrowMissionModal")}
        />
      );
    case "TomorrowMissionModal":
      return (
        <TomorrowMissionModal
          onClick={() =>
            unlockedIcons.length > 0
              ? setFlowState("UnlockUserIcon")
              : goToBookshelf()
          }
        />
      );
    case "UnlockUserIcon":
      return (
        <UnlockUserIconModal
          unlockedIcons={unlockedIcons}
          nickname={nickname}
          onNext={goToBookshelf}
        />
      );
  }
};
