import styled from "styled-components";

export const NavContainer = styled.div`
  width: 100%;
  position: fixed;
  top: -1px;
  z-index: ${(props) => props.theme.zIndex.navbar};
`;

export const Wrapper = styled.div`
  width: 100%;
  padding: 0 7% 0 7%;
  min-height: 100vh;
  @media screen and ${({ theme }) =>
      theme.breakPoints.minWidthSmallSmartPhone} {
    padding: 0 10% 0 10%;
  }
  @media ${({ theme }) => theme.breakPoints.minWidthSmartPhone} {
    width: 100%;
    padding: 0 20% 0 20%;
  }
  @media ${({ theme }) => theme.breakPoints.minWidthSmallPC} {
    width: 100%;
    padding: 0 30% 0 30%;
  }
  background-color: ${({ theme }) => theme.colors.base.ashGray};
`;
