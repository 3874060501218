import React from "react";
import { CongratulateUnlockModal } from "../CongratulateUnlockModal";

export type UnlockWantReadBookModalProps = {
  onClose: (e: React.MouseEvent) => void;
  nickname: string;
};

export const UnlockWantReadBookModal: React.FC<
  UnlockWantReadBookModalProps
> = ({ onClose, nickname }) => {
  return (
    <CongratulateUnlockModal
      isOpen={true}
      onClose={onClose}
      nickname={nickname}
      lockedFeature="wantReadBook"
    />
  );
};
