import { useMemo } from "react";
import { ReadLastBookSelectRecommend } from "~/generated/graphql";
import {
  getEmptyCaseItem,
  getFirstBookSelectItem,
  getNegativeCaseItem,
  getPositiveCaseItem,
} from "./const";
import { useTranslation } from "~/i18n";

export type BubbleModalType = {
  content: string;
  buttons: { text: string; onClick: () => void }[];
  imageUrl: string;
};

export const useBubbleModal = (props: {
  nickname: string;
  isFirstRecommend: boolean;
  readBookRecommends: ReadLastBookSelectRecommend[];
  setCurrentIndex: (idx: number) => void;
  onClose: () => void;
  currentIndex: number;
}): BubbleModalType => {
  const { t } = useTranslation();

  const currentItem = useMemo(() => {
    const {
      nickname,
      isFirstRecommend,
      readBookRecommends,
      setCurrentIndex,
      onClose,
      currentIndex,
    } = props;

    if (isFirstRecommend) {
      return getFirstBookSelectItem(
        nickname,
        setCurrentIndex,
        currentIndex,
        onClose,
        t
      );
    }

    if (readBookRecommends.length > 0) {
      const mostFavoriteRecommend = readBookRecommends
        .slice()
        .sort(
          (a: ReadLastBookSelectRecommend, b: ReadLastBookSelectRecommend) =>
            a.readLogReaction.like > b.readLogReaction.like ? -1 : 1
        )[0];

      if (mostFavoriteRecommend.readLogReaction.like >= 3) {
        const feeling =
          mostFavoriteRecommend.readLogReaction.emotion.split(",")[0];

        return getPositiveCaseItem(
          nickname,
          mostFavoriteRecommend.whenDone,
          mostFavoriteRecommend.readLogReaction.like,
          feeling,
          mostFavoriteRecommend.title,
          setCurrentIndex,
          currentIndex,
          onClose,
          t
        );
      }

      return getNegativeCaseItem(
        nickname,
        setCurrentIndex,
        currentIndex,
        onClose,
        t
      );
    }

    return getEmptyCaseItem(
      nickname,
      setCurrentIndex,
      currentIndex,
      onClose,
      t
    );
  }, [props, t]);

  return currentItem;
};
