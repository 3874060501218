import { makeVar, useReactiveVar } from "@apollo/client";
import React, { useCallback, useEffect } from "react";
import { usePreference } from "~/store/preference/usePreference";
import { modifyVolume } from "./useSnd";

const soundMap = {
  initializeAudio: { src: "/audio/initializeAudio.mp3", level: 0 },
  getStamp: { src: "/audio/getStamp.mp3", level: 1.0 },
  fanfare: { src: "/audio/fanfare.mp3", level: 0.05 }, //https://www.nakano-sound.com/free/fn.html のファンファーレを利用
  drumRoll: { src: "/audio/drumRoll.mp3", level: 0.05 },
  light: { src: "/audio/light.mp3", level: 0.15 },
  pageTurn: {
    src: "/audio/mission_story/yondemy_story/pageTurn.mp3",
    level: 0.3,
  },
};

type SoundKey = keyof typeof soundMap;

type UseAudioParams = {
  preload: SoundKey[];
};

type LoadingStatus = "loading" | "loaded";

const _buffers = makeVar<Record<string, AudioBuffer>>({});
const _loadingStatus = makeVar<Record<string, LoadingStatus>>({});

const AudioContext = window.AudioContext || window.webkitAudioContext;

 
let _ctx: AudioContext;

const _initAudioContext = (): void => {
  _ctx = new AudioContext();

  // Safariではなんらかの音を鳴らしておかないと初期化したことにならない
  const osc = _ctx.createOscillator();
  const gain = _ctx.createGain();
  gain.gain.value = 0;
  gain.connect(_ctx.destination);

  osc.type = "square";
  osc.frequency.setValueAtTime(440, _ctx.currentTime);
  osc.connect(gain);
  osc.start();
  osc.stop(_ctx.currentTime + 0.1);
};

// AudioContextの初期化にはMouseEventが必要であることを示すために引数を取る
export const initAudioContext = (_e: React.MouseEvent): void => {
  if (!_ctx) {
    _initAudioContext();
  }
  _ctx.resume();
};

export const getAudioContext = (): AudioContext => {
  // 後方互換のために入れておく
  if (!_ctx) {
    _initAudioContext();
  }
  return _ctx;
};

export const useAudio = ({
  preload,
}: UseAudioParams): {
  play: (key: SoundKey, delay?: number) => (() => void) | undefined;
} => {
  const { preference } = usePreference();
  const loadingStatus = useReactiveVar(_loadingStatus);
  const buffers = useReactiveVar(_buffers);

  useEffect(() => {
    preload.forEach((p) => {
      if (loadingStatus[p] || !soundMap[p]) {
        return;
      }
      loadingStatus[p] = "loading";

      const src = soundMap[p].src;
      const request = new XMLHttpRequest();
      request.open("GET", src, true);
      request.responseType = "arraybuffer";
      request.onload = () => {
        const ctx = getAudioContext();
        const res = request.response;
        ctx.decodeAudioData(res, (buf) => {
          buffers[p] = buf;
          loadingStatus[p] = "loaded";
        });
      };

      request.send();
    });
  }, [preload]);

  const play = useCallback(
    (key: SoundKey, delay = 0) => {
      if (!preference?.sndMuted && buffers[key]) {
        const ctx = getAudioContext();
        const source = ctx.createBufferSource();
        source.buffer = buffers[key];

        const gainNode = ctx.createGain();
        const level = soundMap[key]?.level || 1.0;
        const volume = level * modifyVolume(preference?.sndVolume || 10);
        gainNode.gain.value = volume;
        gainNode.connect(ctx.destination);

        source.connect(gainNode);
        if (typeof ctx.currentTime !== "number" || !isFinite(ctx.currentTime)) {
          return () => {
            console.error("fail to start audio device");
          };
        }
        source.start(ctx.currentTime + delay);

        return () => {
          source.stop(ctx.currentTime);
        };
      }
    },
    [preference, buffers]
  );

  return { play };
};
