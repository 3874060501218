import React from "react";
import styled from "styled-components";
import { SolidButton } from "~/components/atoms/buttons/SolidButton";
import { Text, TextProps } from "~/components/atoms/texts/Text";
import { Icon } from "~/components/atoms/Icon";
import { ColorTheme } from "~/styles/design_token/color";
import {
  Anchorize,
  AnchorizeProps,
  Linkerize,
  LinkerizeProps,
} from "~/components/atoms/buttons/tag_changer";

export type SecondaryButtonColorTheme = ColorTheme | "gray-orange";

export type SecondaryButtonProps = TextProps & {
  color: SecondaryButtonColorTheme;
  onClick: (e: React.MouseEvent) => void;
  text: string;
  disabled: boolean;
  withArrow?: boolean;
};

const getInnerColor = (color: SecondaryButtonColorTheme): ColorTheme => {
  if (
    /* colorがColorThemeに存在する色であればそれをそのまま返す */
    color === "pri" ||
    color === "sec" ||
    color === "violet" ||
    color === "green" ||
    color === "red" ||
    color === "sky" ||
    color === "gray" ||
    color === "white"
  ) {
    return color as ColorTheme;
  } else {
    switch (color) {
      case "gray-orange":
        return "pri";
      default:
        return "pri";
    }
  }
};

const getButtonColor = (color: SecondaryButtonColorTheme): ColorTheme => {
  if (
    color === "pri" ||
    color === "sec" ||
    color === "violet" ||
    color === "green" ||
    color === "red" ||
    color === "sky" ||
    color === "gray" ||
    color === "lightGray" ||
    color === "white"
  ) {
    return color as ColorTheme;
  } else {
    switch (color) {
      case "gray-orange":
        return "gray";
      default:
        return "pri";
    }
  }
};

/**
 * @deprecated src/components/atoms/buttons/Button を color="secondary" で使用してください
 */
export const SecondaryButton: React.FC<SecondaryButtonProps> = ({
  color,
  onClick,
  text,
  disabled,
  withArrow = false,
  ...textProps
}) => {
  return (
    <SolidButton
      variant={"Outline"}
      color={getButtonColor(color)}
      shape={"Normal"}
      onClick={onClick}
      disabled={disabled}
    >
      <LeftSide></LeftSide>
      <Center>
        <InnerText {...textProps}>{text}</InnerText>
      </Center>
      <RightSide>
        {withArrow && (
          <Icon
            color={getInnerColor(color)}
            name={"arrowRight"}
            style={{ height: "1.6em", width: "auto" }}
          />
        )}
      </RightSide>
    </SolidButton>
  );
};

export type SecondaryLinkProps = Omit<SecondaryButtonProps, "onClick"> &
  Omit<LinkerizeProps, keyof SecondaryButtonProps>;

export const SecondaryLink: React.FC<SecondaryLinkProps> = ({
  color,
  text,
  disabled,
  withArrow,
  fontSize,
  bold,
  lineHeight,
  ...linkProps
}) => (
  <Linkerize {...linkProps} disabled={disabled}>
    <SecondaryButton
      color={color}
      fontSize={fontSize}
      lineHeight={lineHeight}
      bold={bold}
      onClick={() => {
        return;
      }}
      disabled={disabled}
      text={text}
      withArrow={withArrow}
    />
  </Linkerize>
);

export type SecondaryAnchorProps = Omit<SecondaryButtonProps, "onClick"> &
  Omit<AnchorizeProps, keyof SecondaryButtonProps>;

export const SecondaryAnchor: React.FC<SecondaryAnchorProps> = ({
  color,
  text,
  disabled,
  withArrow,
  fontSize,
  bold,
  lineHeight,
  ...anchorProps
}) => (
  <Anchorize {...anchorProps} disabled={disabled}>
    <SecondaryButton
      color={color}
      fontSize={fontSize}
      lineHeight={lineHeight}
      bold={bold}
      onClick={() => {
        return;
      }}
      disabled={disabled}
      text={text}
      withArrow={withArrow}
    />
  </Anchorize>
);

const LeftSide = styled.div`
  width: 10%;
`;

const Center = styled.div`
  width: 80%;
`;

const RightSide = styled.div`
  width: 10%;
`;

const InnerText = styled(Text)`
  text-align: center;
`;
