export type FontSize =
  | "ULTRAL"
  | "SUPERL"
  | "XXXXL"
  | "XXXL"
  | "XXL"
  | "XL"
  | "LG"
  | "MD"
  | "SM"
  | "XS"
  | "XXS"
  | "XXXS";

export type FontLineHeight = "EQ" | "MD" | "LG";

export const defaultFontSize: Readonly<Record<FontSize, string>> = {
  ULTRAL: "3rem", //48px
  SUPERL: "2.5rem", //40px
  XXXXL: "2rem", //32px
  XXXL: "1.5rem", //24px
  XXL: "1.375rem", //22px
  XL: "1.25rem", //20px
  LG: "1.125rem", //18px
  MD: "1rem", //16px
  SM: "0.875rem", //14px
  XS: "0.75rem", //12px
  XXS: "0.625rem", //10px
  XXXS: "0.5rem", //8px
};

export const defaultFontLineHight: Readonly<Record<FontLineHeight, number>> = {
  EQ: 1,
  MD: 1.6,
  LG: 1.6,
};
