import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Stack } from "~/components/atoms/layout/Stack";
import { Text } from "~/components/atoms/texts/Text";
import { RecommendStatus, WantReadBook } from "~/generated/graphql";
import { YomimashokaBookshelfLockWrapper } from "../../Unlock/LockWrapper/YomimashokaBookshelfLockWrapper";
import { TranslateKeys, useTranslation } from "~/i18n";
import { MasonryPagiableList } from "~/components/molecules/lists/MasonryPagiableList";
import {
  ListItemProps,
  MasonryPagiableListProps,
} from "~/components/molecules/lists/MasonryPagiableList/types";
import { BookInfoCard } from "~/components/molecules/BookInfoCard";
import { TinyButton } from "~/components/atoms/buttons/Button";
import { sendButtonClickEvent } from "~/utils/googleAnalytics/useButtonClickTracking";
import { ReserveBookButton } from "../ReserveBookButton";

export type YomimashokaProps = {
  wantReadBooks: WantReadBook[];
  userId?: number;
  wantReadBookListProps: Omit<MasonryPagiableListProps, "renderListItem">;
};

export const Yomimashoka: React.FC<YomimashokaProps> = ({
  wantReadBooks,
  userId,
  wantReadBookListProps,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <_Wrapper rowGap="16px">
      <_Text fontSize="SM" lineHeight="MD" fontColor="tex.t700" bold>
        {t<TranslateKeys>(
          "「本の友」で他の人の感想を読んで、よみま書架に入れた本だよ"
        )}
      </_Text>
      <YomimashokaBookshelfLockWrapper>
        {wantReadBooks.length === 0 && (
          <_Container>
            <Text
              fontSize="MD"
              lineHeight="MD"
              fontColor="tex.t700"
              style={{ textAlign: "center" }}
            >
              {t<TranslateKeys>("まだよみま書架に本がないよ")}
              <br />
              {t<TranslateKeys>("本の友に行って")}
              <br />
              {t<TranslateKeys>("他の人の感想を見てみよう")}
            </Text>
            <_TinyButton
              onClick={() => {
                navigate("/bf");
                sendButtonClickEvent("bf_from_yomimashoka", `${userId}`);
              }}
              variant="blank"
            >
              {t<TranslateKeys>("本の友を見る")}
            </_TinyButton>
          </_Container>
        )}
        {wantReadBooks.length !== 0 && (
          <>
            <ReserveBookButton />
            <_BooksWrapper>
              <MasonryPagiableList
                {...wantReadBookListProps}
                renderListItem={({ item }: ListItemProps<WantReadBook>) => (
                  <div
                    key={item.id}
                    style={{ marginBottom: "8px", width: "100%" }}
                  >
                    <BookInfoCard
                      title={item.book.title || ""}
                      imageUrl={item.book.imageUrl}
                      recommendStatus={
                        item.recommendStatus || RecommendStatus.Default
                      }
                      isRecommend={false}
                      isInYomimashoka={true}
                      yl={item.book.yl}
                      onClick={() => {
                        if (item.readRecommendID) {
                          navigate(`/recommend/${item.readRecommendID}`);
                        } else {
                          navigate(`/yomimashoka/${item.id}`);
                        }
                      }}
                    />
                  </div>
                )}
              />
            </_BooksWrapper>
          </>
        )}
      </YomimashokaBookshelfLockWrapper>
    </_Wrapper>
  );
};

const _Wrapper = styled(Stack)`
  background-color: ${({ theme }) => theme.colors.primitive.monotone.m0};
  padding: 16px;
  border-radius: 0px 0px 12px 12px;
  align-items: center;
`;

const _BooksWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 48px;

  border-radius: 60px 60px 0 0;
`;

const _Text = styled(Text)`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
`;

const _Container = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.size.s2};
  background-color: ${({ theme }) => theme.colors.primitive.monotone.m40};
  border-radius: 16px;
  padding: ${({ theme }) => theme.size.s3};
`;

const _TinyButton = styled(TinyButton)`
  max-width: 600px;
`;
