import React from "react";
import styled from "styled-components";
import { Stack } from "~/components/atoms/layout/Stack";
import { Text } from "~/components/atoms/texts/Text";
import { LargeButton } from "~/components/atoms/buttons/Button";
import { NavContainer } from "~/components/templates/Common";
import { BackNavbar } from "~/components/molecules/navbars/BackNavbar";
import { useLocation, useNavigate } from "react-router-dom";

export type StartScreenProps = {
  onClose: () => void;
};

// 保護者向けのため漢字レベル対応の必要なし
export const StartScreen: React.FC<StartScreenProps> = ({ onClose }) => {
  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const entryPageStr = searchParams.get("entry_page");

  return (
    <_Wrapper>
      <NavContainer>
        <BackNavbar titleName="選書を調整" onClick={onClose} />
      </NavContainer>
      <_Stack rowGap="20px" alignItems="center">
        <_Box>
          <Stack rowGap="16px">
            <_Title fontSize={"LG"} lineHeight={"EQ"} bold>
              YL相談所
            </_Title>
            <Text fontSize={"SM"} lineHeight={"MD"}>
              YL（ヨンデミーレベル）が合わないと感じるときにお越しください。
              <br />
              いくつかの文章を読み、質問に答えていただくと、YLの再診断ができます。
            </Text>
            <_ButtonWrapper>
              <LargeButton
                color="primary"
                onClick={() =>
                  navigate(
                    `/yl_reassessment_enquete?entry_page=${entryPageStr}`
                  )
                }
              >
                YL相談所にいく
              </LargeButton>
            </_ButtonWrapper>
          </Stack>
        </_Box>
        <_Box>
          <Stack rowGap="16px">
            <_Title fontSize={"LG"} lineHeight={"EQ"} bold>
              ジャンル心理テスト
            </_Title>
            <Text fontSize={"SM"} lineHeight={"MD"}>
              どんなジャンルの本を読むか迷っているときにお越しください。
              <br />
              簡単な心理テストに答えると、キャラクターが本をおすすめします。
              <br />
              何度でもお気軽に受けてください。
            </Text>
            <_ButtonWrapper>
              <LargeButton
                color="primary"
                onClick={() => navigate("/character_assessment/enquete")}
              >
                ジャンル心理テストを受ける
              </LargeButton>
            </_ButtonWrapper>
          </Stack>
        </_Box>
      </_Stack>
    </_Wrapper>
  );
};

const _Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: ${({ theme }) =>
    theme.colors.semantic.layout.backgroundMain};
  padding: 56px 0;
`;

const _Stack = styled(Stack)`
  max-width: 520px;
  margin: 0 auto;
  padding: 20px;
`;

const _Box = styled.div`
  width: 100%;
  background-color: ${({ theme }) =>
    theme.colors.semantic.layout.backgroundBox};
  padding: 16px;
  border-radius: 16px;
`;

const _Title = styled(Text)`
  text-align: start;
  white-space: pre-wrap;
`;

const _ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
