import React, { VFC } from "react";
import { PwsTutorialList } from "~/components/organisms/PwaTutorial/PwsTutorialList";
import type { PwsTutorialListProps } from "~/components/organisms/PwaTutorial/PwsTutorialList";
import styled from "styled-components";

type PwaTutorialTemplateProps = {
  items: PwsTutorialListProps["items"];
};

export const PwaTutorialTemplate: VFC<PwaTutorialTemplateProps> = ({
  items,
}) => {
  return (
    <Layout>
      <PwsTutorialList items={items} />
    </Layout>
  );
};

const Layout = styled.div`
  background-color: ${({ theme }) => theme.colors.base.ashGray};
  padding: 40px 30px 62px 30px;
  min-height: 100vh;
  @media (min-width: 768px) {
    padding: 40px 160px 62px 160px;
  }
  @media (min-width: 1100px) {
    padding: 40px 250px 62px 250px;
  }
  @media (min-width: 1400px) {
    padding: 40px 400px 62px 400px;
  }
`;
