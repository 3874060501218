import React from "react";
import styled from "styled-components";
import {
  CONTENT_WIDTH,
  CONTENT_HEIGHT,
  useScale,
} from "~/utils/hooks/useScale";
import {
  StandUpImageType,
  standUpImageValue,
} from "~/helpers/constants/imagePath";
import { HeartLine } from "../HeartLine";

export type SenseiModalProps = {
  onClick?: (e: React.MouseEvent) => void;
  children: React.ReactNode;
  showSensei: boolean;
  isBright: boolean;
  senseiImageType: StandUpImageType;
  heartNum?: number;
};

export const LibraryModalWrapper: React.FC<SenseiModalProps> = ({
  onClick,
  children,
  showSensei,
  isBright,
  senseiImageType,
  heartNum,
}) => {
  const scale = useScale();

  return (
    <_Wrapper onClick={onClick}>
      <_ConversationWrapper>
        {!isBright && <_Overlay />}
        {heartNum !== undefined && (
          <_HeartLineWrapper>
            <HeartLine filledHearts={heartNum} />
          </_HeartLineWrapper>
        )}
        <_Container style={{ scale: `${scale}` }}>
          <_Images overlay={!isBright}>
            {showSensei && (
              <_SenseiImage
                src={`/img/sensei/standup_${standUpImageValue(
                  senseiImageType
                )}.svg`}
                height="auto"
              />
            )}
            <_TableImage src="/img/initialize_modal/bright_table.png" />
          </_Images>
          <_ChildrenWrapper>{children}</_ChildrenWrapper>
        </_Container>
      </_ConversationWrapper>
    </_Wrapper>
  );
};

const _Overlay = styled.div`
  position: absolute;
  min-width: 100vw;
  //height: 100vh;
  min-height: 100vh;
  top: 0;
  background-color: ${({ theme }) => theme.colors.base.overlay};
`;

const _Wrapper = styled.div`
  :hover {
    cursor: pointer;
  }
`;

const _ConversationWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  max-height: 100svh;
  position: relative;
  overflow: hidden;
  background-image: url("/img/initialize_modal/bookshelf.png");

  background-repeat: repeat-x;
  background-size: contain;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const _Container = styled.div`
  position: relative;

  transform-origin: top 50%;

  width: ${CONTENT_WIDTH}px;
  height: ${CONTENT_HEIGHT}px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`;

const _Images = styled.div<{ overlay: boolean }>`
  filter: ${({ overlay }) => (overlay ? "brightness(0.5)" : "none")};
  text-align: center;
`;

const _SenseiImage = styled.img`
  width: calc(${CONTENT_WIDTH}px * 0.66);
  margin-bottom: -30%;
`;

const _TableImage = styled.img`
  width: ${CONTENT_WIDTH}px;
`;

const _ChildrenWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const _HeartLineWrapper = styled.div`
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
`;
