import React from "react";
import styled from "styled-components";
import { IconButton } from "~/components/molecules/buttons/IconButton";
import { ProgressBar } from "~/components/atoms/ProgressBar";

export type ProgressNavigationProps = {
  progress: number;
  handleBack: () => void;
  buttonsPattern?: "single" | "double"; //不要、あとで消す (不要なので一旦nullableにした)
};
export const ProgressNavigation: React.VFC<ProgressNavigationProps> = ({
  progress,
  handleBack,
  buttonsPattern, //eslint-disable-line
}) => {
  return (
    <NavigationWrapper>
      <SideButtonWrapper>
        <IconButton
          name={"arrowLeft"}
          onClick={handleBack}
          color={"sec"}
          fontSize={"XXXL"}
        />
      </SideButtonWrapper>
      <ProgressBar progress={progress} />
    </NavigationWrapper>
  );
};

const NavigationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: ${(props) => props.theme.zIndex.navbar};
  background-color: ${({ theme }) => theme.colors.base.ashGray};
`;

const SideButtonWrapper = styled.div`
  margin-right: 16px;
`;
