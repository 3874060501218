import React from "react";
import {
  PostPreBookRecommendLog,
  PreBookRecommend,
  PreBookRecommendEnqueteInput,
  BookCoverPerception,
} from "~/generated/graphql";
import { SelectCoverPage } from "~/components/organisms/CoverDiagnosis/SelectCoverPage";
import { IntroPage } from "~/components/organisms/CoverDiagnosis/IntroPage";
import { IMAGE_PATHS } from "~/helpers/constants/imagePath";
import { LoadingPage } from "~/components/templates/Loading/LoadingPage";

type Flow = "intro" | "selectCover";

export type CoverDiagnosisProps = {
  postPreBookRecommendLog?: PostPreBookRecommendLog;
  onPostPreBookRecommendLog: (
    preBookRecommendEnquetes: PreBookRecommendEnqueteInput[]
  ) => void;
  loading: boolean;
};

export const CoverDiagnosis: React.FC<CoverDiagnosisProps> = ({
  postPreBookRecommendLog,
  onPostPreBookRecommendLog,
  loading = false,
}) => {
  const [flow, setFlow] = React.useState<Flow>("intro");

  const preBookRecommends: PreBookRecommend[] = postPreBookRecommendLog
    ? postPreBookRecommendLog.preBookRecommends
    : [];

  const [preBookRecommendEnquetes, setPreBookRecommendEnquetes] =
    React.useState<PreBookRecommendEnqueteInput[]>([]);
  const currentPreBookRecommendIndex = preBookRecommendEnquetes.length;

  const handleSelectNext = React.useCallback(
    (perception: BookCoverPerception) => {
      if (currentPreBookRecommendIndex === preBookRecommends.length - 1) {
        onPostPreBookRecommendLog([
          ...preBookRecommendEnquetes,
          {
            preBookRecommendID:
              preBookRecommends[currentPreBookRecommendIndex].id,
            bookCoverPerception: perception,
          },
        ]);
        return;
      }
      const newEnquete: PreBookRecommendEnqueteInput = {
        preBookRecommendID: preBookRecommends[currentPreBookRecommendIndex].id,
        bookCoverPerception: perception,
      };
      setPreBookRecommendEnquetes((prevEnquetes) => [
        ...prevEnquetes,
        newEnquete,
      ]);
      if (
        !preBookRecommends[currentPreBookRecommendIndex].book.imageUrl ||
        preBookRecommends[currentPreBookRecommendIndex].book.imageUrl === ""
      ) {
        throw new Error(
          "isbn: " +
            preBookRecommends[currentPreBookRecommendIndex].book.isbn +
            ", title: " +
            preBookRecommends[currentPreBookRecommendIndex].book.title +
            " has undefined imageUrl"
        );
      }
    },
    [
      currentPreBookRecommendIndex,
      preBookRecommends,
      preBookRecommendEnquetes,
      onPostPreBookRecommendLog,
      postPreBookRecommendLog,
    ]
  );

  return (
    <>
      {flow === "intro" && <IntroPage onClick={() => setFlow("selectCover")} />}
      {flow === "selectCover" &&
        (loading ? (
          <LoadingPage />
        ) : (
          <SelectCoverPage
            onClicks={[
              () => handleSelectNext(BookCoverPerception.Welcome),
              () => handleSelectNext(BookCoverPerception.Acceptable),
              () => handleSelectNext(BookCoverPerception.Reluctant),
            ]}
            imageUrl={
              preBookRecommends[currentPreBookRecommendIndex].book.imageUrl ||
              IMAGE_PATHS.NO_IMAGE
            }
            title={preBookRecommends[currentPreBookRecommendIndex].book.title}
          />
        ))}
    </>
  );
};
