import React from "react";
import { useNavigate } from "react-router-dom";
import { StampCardProps } from "~/components/organisms/Bookshelf/MissionAndStampCarousel/StampCard";
import { GetStampModal } from "~/components/organisms/Bookshelf/MissionAndStampCarousel/StampCard/GetStampModal";
import { useBookshelfExpModal } from "~/components/organisms/Bookshelf/useBookshelfExpModal";
import { A2HSContext } from "~/contexts/A2HSContext";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import {
  TutorialFlagKey,
  useGetCurrentRecommendNumberQuery,
} from "~/generated/graphql";
import { useLoginTips } from "~/components/organisms/Bookshelf/LoginTips/useLoginTips";
import { useUnlock } from "~/store/unlock/useUnlock";
import { TutorialBookFriendModal } from "~/components/organisms/modals/TutorialBookFriendModal";

type TopPageModalsProps = {
  isOpen: boolean;
  stampCardProps?: StampCardProps;
  loadingStampCard: boolean;
};

const modalsTypes = [
  "INITIAL_TUTORIAL",
  "PWA_TUTORIAL",
  "LOGIN_TIPS",
  "STAMPCARD_MODAL",
  "GET_EXPERIENCE",
  "RECOMMEND_TUTORIAL",
  "BOOK_FRIEND_TUTORIAL",
  "NONE",
] as const;

type ModalType = (typeof modalsTypes)[keyof typeof modalsTypes];

export const TopPageModals: React.FC<TopPageModalsProps> = ({
  isOpen,
  stampCardProps,
  loadingStampCard,
}) => {
  const [modalType, setModalType] = React.useState<ModalType>("NONE");
  const { tutorialFlags, getTutorialFlag, saveTutorialFlags } =
    React.useContext(CurrentUserContext);
  const navigate = useNavigate();
  const {
    data: currentRecommendNumberData,
    loading: loadingRecommend,
    error: errorRecommend,
  } = useGetCurrentRecommendNumberQuery({});
  const { getExpModal, isOpenGetExpModal } = useBookshelfExpModal();
  const { showPwaTutorial } = React.useContext(A2HSContext);
  const {
    loginTips,
    loading: loadingLoginTips,
    isOpenLoginTips,
  } = useLoginTips();
  const { isLocked } = useUnlock();
  const isBookFriendLocked = isLocked("bookFriend");

  const onEndBookFriendTutorial = React.useCallback(() => {
    saveTutorialFlags(TutorialFlagKey.FirstBookFriendTutorial);
  }, [saveTutorialFlags]);

  React.useEffect(() => {
    for (const v of modalsTypes) {
      switch (v) {
        case "INITIAL_TUTORIAL":
          if (tutorialFlags && !getTutorialFlag(TutorialFlagKey.FirstLogin)) {
            navigate("/initial_tutorial");
            return;
          } else if (!tutorialFlags) {
            return;
          }
          break;
        case "PWA_TUTORIAL":
          if (showPwaTutorial) {
            navigate("/pwa_tutorial");
            return;
          }
          break;
        case "LOGIN_TIPS":
          if (isOpenLoginTips) {
            setModalType("LOGIN_TIPS");
            return;
          } else if (loadingLoginTips) {
            return;
          }
          break;
        case "STAMPCARD_MODAL":
          if (stampCardProps?.getStampModalProps.open) {
            setModalType("STAMPCARD_MODAL");
            return;
          } else if (!stampCardProps || loadingStampCard) {
            return;
          }
          break;
        case "GET_EXPERIENCE":
          if (isOpenGetExpModal) {
            setModalType("GET_EXPERIENCE");
            return;
          }
          break;
        case "RECOMMEND_TUTORIAL":
          if (
            tutorialFlags &&
            !getTutorialFlag(TutorialFlagKey.HideRecommendModal)
          ) {
            navigate("/recommend_tutorial");
            return;
          } else if (!tutorialFlags || loadingRecommend) {
            return;
          }
          break;
        case "BOOK_FRIEND_TUTORIAL":
          if (
            tutorialFlags &&
            !getTutorialFlag(TutorialFlagKey.FirstBookFriendTutorial) &&
            !isBookFriendLocked
          ) {
            setModalType("BOOK_FRIEND_TUTORIAL");
          }
          break;
      }
    }
  }, [
    tutorialFlags,
    currentRecommendNumberData,
    loadingRecommend,
    loadingStampCard,
    loadingLoginTips,
    stampCardProps,
    isOpenGetExpModal,
    isOpenLoginTips,
    isBookFriendLocked,
    getTutorialFlag,
    navigate,
  ]);

  if (errorRecommend) {
    return <>エラーです。</>;
  }
  return (
    <>
      {isOpen && modalType === "STAMPCARD_MODAL" ? (
        stampCardProps && (
          <GetStampModal {...stampCardProps.getStampModalProps} />
        )
      ) : isOpenGetExpModal && modalType === "GET_EXPERIENCE" ? (
        getExpModal
      ) : isOpenLoginTips && modalType === "LOGIN_TIPS" ? (
        loginTips
      ) : modalType === "BOOK_FRIEND_TUTORIAL" ? (
        <TutorialBookFriendModal onEnd={onEndBookFriendTutorial} />
      ) : null}
    </>
  );
};
