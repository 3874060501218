import React, { useEffect, useState, VFC } from "react";
import { useNavigate } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import { PrimaryButton } from "~/components/molecules/buttons/PrimaryButton";
import { LabeledInput } from "~/components/molecules/forms/LabeledInput";
import { BackNavbar } from "~/components/molecules/navbars/BackNavbar";
import { Interpolation } from "~/styles/interpolation";
import { NavContainer, Wrapper } from "../Common";
import { EditProfileTemplateProps } from "./type";
import { Label } from "~/components/atoms/wrappers/Label";
import { Icon } from "~/components/atoms/Icon";
import { useTranslation } from "react-i18next";
import { Text } from "~/components/atoms/texts/Text";
import { NicknameMaxLength } from "../../../../../util/constant";
import { Validation_StringLengthBySegmenter } from "../../../../../util/validation";
import { UserIconType } from "~/generated/graphql";
import { userIconImage } from "~/components/organisms/adminMessage/AdminChatMessage";
import { TranslateKeys } from "~/i18n";
import { userIconProfile } from "~/components/templates/EditProfile/const";
import { SelectUserIconModal } from "~/components/templates/EditProfile/SelectUserIconModal";

export type UnlockIconStatus = "UNLOCKED" | "LOCKED" | "UNLOCKED_NEXT";

export const EditProfileTemplate: VFC<EditProfileTemplateProps> = ({
  handleUpdate,
  handleUpdateIcon,
  missionOrder,
  missionState,
  user,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [selectedUserIconType, setSelectedUserIconType] =
    React.useState<UserIconType>(user.userIconType);
  const [nickname, setNickname] = React.useState(user.nickname);

  useEffect(() => {
    setNickname(user.nickname);
    setSelectedUserIconType(user.userIconType);
  }, [user]);

  const isValidNickname = React.useMemo(
    () => Validation_StringLengthBySegmenter(nickname, 1, NicknameMaxLength),
    [nickname]
  );

  return (
    <>
      <NavContainer>
        <BackNavbar
          titleName={t<TranslateKeys>("プロフィール変更")}
          onClick={() => navigate("/mypage?view=profile")}
        />
      </NavContainer>
      <Wrapper>
        <_TopWrapper>
          <_Container>
            <Label label={t<TranslateKeys>("アイコン変更")}>
              <_CenteringCon>
                <_ImgButton onClick={() => setIsDialogOpen(true)}>
                  <UserIcon
                    src={userIconImage[selectedUserIconType]}
                    alt={userIconProfile[selectedUserIconType].name}
                    isActive={false}
                  />
                  <_IconWrapper>
                    <Icon
                      name="pencil"
                      style={{
                        color: theme.colors.base.white,
                        height: theme.fontSize["MD"],
                        width: "auto",
                      }}
                    />
                  </_IconWrapper>
                </_ImgButton>
              </_CenteringCon>
            </Label>
            <_NicknameContainer>
              <LabeledInput
                labelProps={{ label: "ニックネーム" }}
                value={nickname}
                onChange={(event) => setNickname(event.target.value)}
                error={nickname === "" || !isValidNickname}
              />
              {isValidNickname ? (
                <_NoticeTextWrapper>
                  <Icon
                    name="infoCircle"
                    style={{
                      color: theme.colors.tex.t300,
                      height: theme.fontSize.MD,
                      width: "auto",
                    }}
                  />
                  <Text fontColor="tex.t300" fontSize="SM" lineHeight="MD">
                    {t<TranslateKeys>("{{ max }}文字まで使えるよ！", {
                      max: NicknameMaxLength,
                    })}
                  </Text>
                </_NoticeTextWrapper>
              ) : (
                <_WarningTextWrapper>
                  <Icon
                    name="skull"
                    style={{
                      color: theme.colors.red.r300,
                      height: theme.fontSize.MD,
                      width: "auto",
                    }}
                  />
                  <Text fontColor="red.r300" fontSize="SM" lineHeight="MD">
                    {nickname.length === 0 ? (
                      <>
                        {t<TranslateKeys>("ニックネームが書かれていないよ。")}
                        <br />
                        {t<TranslateKeys>("きみの名前を教えてね！")}
                      </>
                    ) : (
                      <>
                        {t<TranslateKeys>("すてきな名前があふれちゃう！")}
                        <br />
                        {t<TranslateKeys>("{{ max }}文字までで決めてね", {
                          max: NicknameMaxLength,
                        })}
                      </>
                    )}
                  </Text>
                </_WarningTextWrapper>
              )}
            </_NicknameContainer>
          </_Container>
          <PrimaryButton
            text={t<TranslateKeys>("保存して完了")}
            onClick={(event) => {
              event.preventDefault();
              handleUpdate({ nickname, userIconType: selectedUserIconType });
              navigate("/mypage?view=profile");
            }}
            disabled={!isValidNickname}
            color={"pri"}
            fontSize={"XL"}
            lineHeight={"MD"}
            bold
          />
        </_TopWrapper>
      </Wrapper>
      <SelectUserIconModal
        selectedIconType={selectedUserIconType}
        isDialogOpen={isDialogOpen}
        closeDialog={() => setIsDialogOpen(false)}
        missionOrder={missionOrder}
        missionState={missionState}
        handleUpdateIcon={(iconType: UserIconType) => {
          setSelectedUserIconType(iconType);
          handleUpdateIcon(iconType);
        }}
      />
    </>
  );
};

const _TopWrapper = styled.div`
  padding: 74px 0 0 0;
`;

const _Container = styled.div`
  ${Interpolation.Border}
  width: 100%;
  border-radius: ${({ theme }) => theme.deprecatedBorderRadius.MD};
  background-color: ${({ theme }) => theme.colors.base.white};
  padding: ${({ theme }) => theme.size.XL};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.size.XXL};
  margin-bottom: 32px;
`;

const _CenteringCon = styled.div`
  display: flex;
  justify-content: center;
`;

const _ImgButton = styled.button`
  position: relative;
  display: inline-block;
  border: none;
  background-color: ${({ theme }) => theme.colors.base.white};

  &:focus,
  &:active {
    outline: none;
  }
`;

export const UserIcon = styled.img<{ isActive: boolean }>`
  width: 72px;
  height: 72px;
  border-radius: 50%;
  outline: ${({ isActive }) => (isActive ? "3px solid" : "none")};
  outline-offset: 3px;
  outline-color: ${({ theme }) => theme.colors.pri.p400};
`;

const _IconWrapper = styled.div`
  position: absolute;
  right: 0;
  display: inline-block;
  border-radius: 50%;
  padding: 8px;
  background-color: ${({ theme }) => theme.colors.pri.p500};
  z-index: 1;
`;

const _NicknameContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.size.XS};
`;

const _NoticeTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 6px;
  justify-content: flex-end;
  align-items: center;
`;

const _WarningTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 6px;
  justify-content: flex-end;
  align-items: flex-start;
`;
