import React, { VFC } from "react";
import styled, { css } from "styled-components";
import { BaseNavbar } from "~/components/atoms/wrappers/BaseNavbar";
import { IconButton } from "../../buttons/IconButton";
import { PageTitle } from "../../texts/PageTitle";
import { Interpolation } from "~/styles/interpolation";
import { ColorTheme } from "~/styles/design_token/color";
import { IconType } from "~/components/atoms/Icon";

export type BackNavbarProps = {
  /*
   * ナビゲーションバーのタイトル
   */
  titleName: string;
  /*
   * 矢印ボタンクリックイベント時に走る関数
   */
  onClick: (event: React.MouseEvent) => void;
  /**
   * 背景の有無
   */
  background?: "none" | "white";
  /**
   * 矢印ボタンの色
   */
  arrowColor?: ColorTheme;
  iconType?: Extract<IconType, "arrowLeft" | "cancel">;
};

export const BackNavbar: VFC<BackNavbarProps> = ({
  titleName,
  onClick,
  background = "white",
  arrowColor = "pri",
  iconType = "arrowLeft",
}) => {
  return (
    <Wrapper background={background}>
      <Container iconType={iconType}>
        <IconButton
          onClick={onClick}
          color={arrowColor}
          name={iconType}
          fontSize={iconType === "arrowLeft" ? "XXXL" : "XXXXL"}
          disabled={false}
        />
        <PageTitle>{titleName}</PageTitle>
        <ActionContainer />
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled(BaseNavbar)<Pick<BackNavbarProps, "background">>`
  ${({ background, theme }) => {
    return css`
      ${background === "white" && Interpolation.BorderBottom}
      ${background === "none" &&
      css`
        background-color: ${theme.colors.base.ashGray};
      `}
    `;
  }}
`;

type ContainerProps = { iconType: "arrowLeft" | "cancel" };

const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme, iconType }) =>
    iconType === "arrowLeft"
      ? `${theme.size.s2} 5%`
      : `${theme.size.s1half} 5%`};
`;

const ActionContainer = styled.div`
  min-width: 30px;
`;
