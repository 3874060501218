import * as React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { BookshelfImage } from "~/components/atoms/images/BookshelfImage";
import { BookStatus } from "./BookStatus";
import { RecommendStatus } from "~/generated/graphql";

export type BookImageLinkListItemProps = {
  /*
    本のId
  */
  bookId: string;
  /*
    おすすめId
  */
  recommendId: number;
  /*
    本のタイトル
  */
  title: string;
  /*
    画像のURL
  */
  imageUrl?: string;
  /**
   * 本を読んだステータス
   */
  status: RecommendStatus;
  /*
    1行に何個表示するかどうか
  */
  columnsNumber: number;
  showStatus: boolean;
  /*
    感想を提出した日
  */
  date?: Date;
};

export const BookImageLinkListItem: React.VFC<BookImageLinkListItemProps> = ({
  recommendId,
  title,
  columnsNumber,
  status,
  imageUrl,
  showStatus,
}) => {
  return (
    <BookItemContainerWithLink
      to={`/recommend/${recommendId}`}
      columns_number={columnsNumber}
    >
      <BookCoverContainer>
        <BookshelfImage url={imageUrl} alt={title} />
      </BookCoverContainer>
      {showStatus && <BookStatus status={status} />}
    </BookItemContainerWithLink>
  );
};

type BookItemContainerWithLinkProps = {
  // MEMO: dom渡す値はキャメルケースにできないのでsnake_case
  columns_number: number;
};

const BookItemContainerWithLink = styled(Link)<BookItemContainerWithLinkProps>`
  display: block;
  width: ${(props) => (100 - 6 * props.columns_number) / props.columns_number}%;
  margin: 3%;
  opacity: 0.9;
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
  &:active,
  &:focus {
    opacity: 1;
  }
`;

const BookCoverContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 0;
  margin-bottom: 8px;
`;
