import React, { VFC } from "react";
import styled, { css } from "styled-components";
import { useClickTracking } from "~/utils/googleAnalytics/useButtonClickTracking";
import { ColorTheme } from "~/styles/design_token/color";
import { useSnd } from "~/utils/hooks/useSnd";

export type FloatingButtonProps = {
  /**
   * molecules以上で内側の要素は制御
   */
  children: React.ReactNode;
  /**
   * 配色
   */
  color?: ColorTheme;
  onClick: (event: React.MouseEvent) => void;
  disabled?: boolean;
};

export const FloatingButton: VFC<FloatingButtonProps> = ({
  children,
  onClick,
  disabled = false,
  color = "pri",
}) => {
  const { play } = useSnd();
  const handleClick = (event: React.MouseEvent) => {
    play("TAP");
    onClick(event);
  };

  const { onClickWithTracking } = useClickTracking({
    onClick: handleClick,
    label: children,
  });

  return (
    <StyledButton
      onClick={onClickWithTracking}
      disabled={disabled}
      color={color}
    >
      {children}
    </StyledButton>
  );
};

const StyledButton = styled.button<Pick<FloatingButtonProps, "color">>`
  ${({ theme, color }) => {
    const { colors, size, zIndex } = theme;
    const { base, pri, sec, violet, green, red, sky } = colors;
    return css`
      position: relative;
      display: flex;
      align-items: center;
      border: none;
      border-radius: 999px;
      padding: ${size.L} ${size.M};
      z-index: ${zIndex.floatingButton};
      background-color: ${colors.pri.p400};
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
      background-color: ${() => {
        switch (color) {
          case "pri":
            return pri.p500;
          case "sec":
            return sec.s500;
          case "violet":
            return violet.v500;
          case "green":
            return green.g500;
          case "red":
            return red.r500;
          case "sky":
            return sky.s500;
          case "gray":
            return base.gray;
          default:
            return pri.p500;
        }
      }};
      &:active,
      &:hover {
        transform: scale(1.05);
        outline: none;
      }
    `;
  }}
`;
