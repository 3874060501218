import * as React from "react";
import { useMemo, useState } from "react";
import { NotifyTextToast } from "~/components/organisms/toasts/NotifyTextToast";

export const useCopyToClipboard = (): {
  handleCopyToClipboard: (isbn: string) => false | undefined;
  copyToClipboardPopup: JSX.Element;
} => {
  const [open, setOpen] = useState<boolean>(false);

  const handleCopyToClipboard = (isbn: string) => {
    if (!navigator.clipboard) {
      alert("クリップボードにコピーできませんでした");
      return false;
    }
    navigator.clipboard.writeText(isbn).then(() => {
      setOpen(true);
    });
  };

  const copyToClipboardPopup = useMemo(() => {
    return (
      <NotifyTextToast
        text="コピーできました"
        isOpen={open}
        setIsOpen={setOpen}
      />
    );
  }, [open, setOpen]);

  return { handleCopyToClipboard, copyToClipboardPopup };
};
