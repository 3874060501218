import React, { ChangeEvent } from "react";
import styled, { css } from "styled-components";
import { SolidButton } from "~/components/atoms/buttons/SolidButton";
import { AdminChatMessage } from "../../../AdminChatMessage";
import { MessageItemText } from "~/components/molecules/texts/MessageItemText";
import { YouTubeIframe } from "~/components/atoms/YouTubeIframe";
import { useParsedMessage } from "../parseMessageTags";
import {
  MinilessonSpeedType,
  minilessonSpeedTypeList,
  minilessonTimeSettings,
} from "../constant";
import { usePreference } from "~/store/preference/usePreference";
import { omitUrl } from "../omitUrl";
import { AdminMessageText } from "../../../AdminMessageText";
import { MiniLessonContext } from "~/contexts/MiniLessonContext";
import { AdminMessageInput } from "../../../AdminMessageInput";
import { useTranslation } from "~/i18n";
import { TalkItemSpeaker } from "~/generated/graphql";
import { useReplaceMessageToRecommend } from "../useReplaceMessageToRecommend";

export type InputMessageProps = {
  id: number;
  item: { text: string; time: Date };
  buttonText: string;
  value: string;
  onSubmit: () => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  formDisabled?: boolean;
  buttonDisabled?: boolean;
  showAll: boolean;
  incrementCurrentIndex: () => void;
  speaker: TalkItemSpeaker;
};
export const InputMessage: React.FC<InputMessageProps> = ({
  id,
  item,
  buttonText,
  onSubmit,
  formDisabled,
  buttonDisabled,
  value,
  onChange,
  showAll,
  incrementCurrentIndex,
  speaker,
}) => {
  const { text: _text, attachments } = useParsedMessage({
    userType: "admin",
    item,
  });
  const [showQuestion, setShowQuestion] = React.useState<boolean>(showAll);
  const { preference } = usePreference();
  const minilessonSpeed =
    minilessonSpeedTypeList[
      typeof preference?.minilessonSpeedType === "number"
        ? preference.minilessonSpeedType
        : 2
    ];

  const { fontSize } = React.useContext(MiniLessonContext);

  const { replaceMessageToRecommend } = useReplaceMessageToRecommend();

  const { text, imageUrl, youtubeUrl } = omitUrl(
    replaceMessageToRecommend(_text, id)
  );

  const { t } = useTranslation();

  return (
    <AdminChatMessage
      userType="admin"
      variant="Reactive"
      attachments={attachments}
      speaker={speaker}
    >
      <Container>
        <MessageItemText
          textType="plain"
          showAll={showAll}
          onCompleted={() => {
            window.setTimeout(() => {
              incrementCurrentIndex();
            }, minilessonTimeSettings[minilessonSpeed].ANIMATION_DURATION_TIME + minilessonTimeSettings[minilessonSpeed].INTERVAL_TO_SHOW_QUESTION);
            setShowQuestion(true);
          }}
          text={text}
        />
        {youtubeUrl && (
          <YouTubeContainer>
            <YouTubeIframe
              url={
                "https://www.youtube.com/embed/" +
                youtubeUrl.substr(youtubeUrl.indexOf("youtu.be") + 9, 11)
              }
            />
          </YouTubeContainer>
        )}
        {imageUrl && <img src={imageUrl} style={{ width: "100%" }} />}
        <_QuestionWrapper
          showAll={showAll}
          showQuestion={showQuestion}
          minilessonSpeedType={minilessonSpeed}
        >
          <AdminMessageInput
            placeholder={t<TranslateKeys>("答えを書いてね")}
            value={value}
            onChange={onChange}
            disabled={formDisabled}
          />
          <SolidButton
            variant={"Normal"}
            shape={"RoundedCorner"}
            disabled={buttonDisabled}
            onClick={onSubmit}
            color={"pri"}
            style={{ color: "white" }}
          >
            <AdminMessageText textType="primaryButton" fontSize={fontSize}>
              {buttonText}
            </AdminMessageText>
          </SolidButton>
        </_QuestionWrapper>
      </Container>
    </AdminChatMessage>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.size.S};
`;

const YouTubeContainer = styled.div`
  position: relative;
  margin: 10px 0;
  width: 63vw;
  height: 35.43vw;
  max-width: 451px;
  max-height: 254px;
  overflow: hidden;
  & iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: none;
  }
`;

const _QuestionWrapper = styled.div<{
  showAll: boolean;
  showQuestion: boolean;
  minilessonSpeedType: MinilessonSpeedType;
}>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.size.M};
  ${({ showAll, showQuestion, minilessonSpeedType }) => {
    if (!showQuestion) {
      return css`
        opacity: 0;
        pointer-events: none;
      `;
    }
    if (!showAll) {
      return css`
        animation: ${minilessonTimeSettings[minilessonSpeedType]
            .ANIMATION_DURATION_TIME}ms
          popup
          ${minilessonTimeSettings[minilessonSpeedType]
            .INTERVAL_TO_SHOW_QUESTION}ms
          ease-in-out forwards;
        opacity: 0;
      `;
    }
  }}
  @keyframes popup {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
