import React, { FC } from "react";
import styled from "styled-components";
import { Text } from "~/components/atoms/texts/Text";
import { PrimaryButton } from "~/components/molecules/buttons/PrimaryButton";
import { ReadStatus } from "~/generated/graphql";
import { useTranslation } from "~/i18n";

type Props = {
  impression?: string;
  readStatus: ReadStatus;
  onNext: () => void;
};

const _MultilineText = styled(Text)`
  white-space: pre-wrap;
`;

export const ReadStatusMessage: FC<Props> = ({
  impression,
  onNext,
  readStatus,
}) => {
  const { t } = useTranslation();

  if (readStatus !== ReadStatus.End || !impression) {
    onNext();
  }

  return (
    <_Wrapper>
      <_MultilineText fontSize="MD" lineHeight="MD">
        {t<TranslateKeys>("他の子の 感想を送ります！　ぜひ読んでみてね♪")}
        <_ImpressionWrapper>
          <div className="impression-wrapper-label">
            {t<TranslateKeys>("感想")}
          </div>
          <_MultilineText fontSize="MD" lineHeight="MD">
            {impression}
          </_MultilineText>
        </_ImpressionWrapper>
      </_MultilineText>
      <PrimaryButton
        text="はーい"
        fontSize="MD"
        lineHeight="MD"
        color="pri"
        onClick={onNext}
      />
    </_Wrapper>
  );
};

const _Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

const _ImpressionWrapper = styled.div`
  margin: 16px 16px 0px 16px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 10px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.base.lightGray};
  padding: 16px;

  .impression-wrapper-label {
    display: inline-block;
    color: ${({ theme }) => theme.colors.base.white};
    background-color: ${({ theme }) => theme.colors.base.darkGray};
    padding: 4px;
    border-radius: 4px;
  }
`;
