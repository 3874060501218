import React from "react";
import styled from "styled-components";
import { Row } from "~/components/atoms/layout/Row";
import { Stack } from "~/components/atoms/layout/Stack";
import { Heading2 } from "~/components/atoms/texts/Heading2";
import { BaseModal } from "~/components/atoms/wrappers/BaseModal";
import { ReadingTechniqueType } from "~/generated/graphql";
import { StarIcon } from "../../bookFriendMessage/BookFriendMessageListItem/ReadLogMessage/SendStarModal/star";
import { Heading3 } from "~/components/atoms/texts/Heading3";
import {
  readingTechniqueDescription,
  readingTechniqueTypeStr,
} from "../../bookFriendMessage/BookFriendMessageListItem/ReadLogMessage/SendStarModal/const";
import { TinyButton } from "~/components/atoms/buttons/Button";
import { Text } from "~/components/atoms/texts/Text";
import { useTranslation } from "react-i18next";
import { TranslateKeys } from "~/i18n";

export type TechniqueDescriptionModalProps = {
  onClose: () => void;
  isOpen: boolean;
};

export const TechniqueDescriptionModal: React.FC<
  TechniqueDescriptionModalProps
> = ({ onClose, isOpen }) => {
  const { t } = useTranslation();
  return (
    <BaseModal open={isOpen} onClose={onClose}>
      <Stack rowGap="16px">
        <Heading2
          fontSize="XL"
          lineHeight="EQ"
          fontColor="semantic.text.heading"
          bold
        >
          どくしょかの7つのワザ
        </Heading2>
        <_TechniqueDescriptionContainer>
          {Object.values(ReadingTechniqueType).map((techniqueType) => (
            <Stack rowGap="4px" key={techniqueType}>
              <Row columnGap="4px">
                <StarIcon
                  readingTechniqueType={techniqueType}
                  size={16}
                  checked
                />
                <Heading3
                  fontSize="SM"
                  lineHeight="MD"
                  fontColor="semantic.text.heading"
                  bold
                >
                  {readingTechniqueTypeStr[techniqueType]}
                </Heading3>
              </Row>
              <Text
                fontSize="XS"
                lineHeight="MD"
                fontColor="primitive.tex.t900"
              >
                {t<TranslateKeys>(readingTechniqueDescription[techniqueType])}
              </Text>
            </Stack>
          ))}
        </_TechniqueDescriptionContainer>
        <_ButtonWrapper>
          <TinyButton color="primary" onClick={onClose}>
            {t<TranslateKeys>("閉じる")}
          </TinyButton>
        </_ButtonWrapper>
      </Stack>
    </BaseModal>
  );
};

const _TechniqueDescriptionContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: max-content max-content max-content max-content;
  column-gap: 20px;
  row-gap: 16px;
`;

const _ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
