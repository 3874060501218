import React, { FC } from "react";
import styled, { css } from "styled-components";
import { CurrentUserFragment } from "~/generated/graphql";
import { NavContainer } from "../Common";
import {
  TopNavbar,
  TopNavbarProps,
} from "~/components/organisms/navbars/TopNavbar";
import {
  AdminMessageList,
  AdminMessageListProps,
} from "~/components/organisms/adminMessage/AdminMessageList";
import {
  MessageFooterForm,
  MessageFooterFormProps,
} from "~/components/organisms/forms/MessageFooterForm";
import { useTranslation } from "~/i18n";
import { PrimaryFlatButton } from "~/components/molecules/buttons/PrimaryFlatButton";
import { theme } from "~/styles/theme";
import { Icon } from "~/components/atoms/Icon";

type MessageTemplateProps = {
  adminListProps: AdminMessageListProps;
  footerMessageProps: MessageFooterFormProps;
  currentUser: CurrentUserFragment;
  shouldReturnSomeReactive: boolean;
  restartReactive: () => void;
  handleOpenSettingModal: () => void;
  isScrolling: boolean;
} & Pick<TopNavbarProps, "onChangeTab">;

const backgroundColorAndImage = (): {
  url: string | undefined;
  color: string;
} => {
  const date = new Date();
  if (date.getMonth() + 1 === 12 && date.getDate() <= 24) {
    return {
      url: "/img/background/message/christmas_tree.jpeg",
      color: theme.colors.semantic.layout.borderPlus1,
    };
  } else if (date.getMonth() + 1 === 12 && date.getDate() === 25) {
    return {
      url: "/img/background/message/christmas_tree_with_present.jpeg",
      color: theme.colors.semantic.layout.borderPlus1,
    };
  } else if (
    (date.getMonth() + 1 === 12 && date.getDate() >= 26) ||
    (date.getMonth() + 1 === 1 && date.getDate() >= 4) ||
    date.getMonth() + 1 === 2
  ) {
    return {
      url: "/img/background/message/penguin_apple_snow.jpg",
      color: theme.colors.semantic.layout.borderPlus1,
    };
  } else if (date.getMonth() + 1 === 3) {
    return {
      url: "/img/background/message/march.jpg",
      color: theme.colors.semantic.layout.borderPlus1,
    };
  } else if (
    date.getFullYear() === 2024 &&
    date.getMonth() + 1 === 1 &&
    date.getDate() <= 3
  ) {
    return {
      url: "/img/background/message/new_year_2024.jpeg",
      color: theme.colors.semantic.layout.borderPlus1,
    };
  } else {
    return {
      url: undefined,
      color: theme.colors.semantic.layout.backgroundMain,
    };
  }
};

export const AdminMessageTemplate: FC<MessageTemplateProps> = ({
  adminListProps,
  footerMessageProps,
  currentUser,
  onChangeTab,
  shouldReturnSomeReactive,
  restartReactive,
  handleOpenSettingModal,
  isScrolling,
}) => {
  const { t } = useTranslation();
  const { url, color } = backgroundColorAndImage();
  return (
    <AdminMessageContainer url={url} color={color}>
      <NavContainer>
        <TopNavbar
          haveUnreadInBfGroupMessage={
            currentUser?.haveUnreadInBfGroupMessage || false
          }
          haveUnreadInMessage={currentUser?.haveUnreadInMessage || false}
          currentTab={2}
          onChangeTab={onChangeTab}
        />
      </NavContainer>
      <MessageListContainer>
        <AdminMessageList {...adminListProps} />
        {shouldReturnSomeReactive && (
          <RestartMinilessonButtonContainer>
            <PrimaryFlatButton
              onClick={restartReactive}
              text={t<TranslateKeys>("ミニレッスンを再開する")}
              color="red"
              fontSize="MD"
              lineHeight="MD"
              disabled={false}
              className={"shadowen"}
            />
          </RestartMinilessonButtonContainer>
        )}
        {!isScrolling && (
          <_IconWrapper onClick={handleOpenSettingModal}>
            <Icon
              name="gear"
              color="pri"
              style={{ width: "24px", height: "24px" }}
            />
          </_IconWrapper>
        )}
      </MessageListContainer>
      <MessageFooterForm {...footerMessageProps} />
    </AdminMessageContainer>
  );
};

const AdminMessageContainer = styled.div<{
  url: string | undefined;
  color: string;
}>`
  height: 100vh;
  max-height: 100svh;
  display: flex;
  flex-direction: column;
  background: ${({ color }) => color};

  ${({ url, theme }) => {
    if (url) {
      return css`
        background-repeat: no-repeat;
        background-image: url(${url});
        background-position: center;
        @media screen and (max-width: ${theme.screenSize.smartPhoneMax}) {
          background-size: cover;
        }
        @media screen and (min-width: ${theme.screenSize.smartPhoneMax}) {
          background-size: contain;
        }
      `;
    }
  }}
`;

const MessageListContainer = styled.div`
  margin-top: 88px;
  height: calc(100% - 88px);
  position: relative;
`;

const RestartMinilessonButtonContainer = styled.div`
  position: absolute;
  bottom: 12px;
  left: 50%;
  transform: translateX(-50%);
  z-index: ${({ theme }) => theme.zIndex.modal};
  width: 80%;
  @media screen and (min-width: 768px) {
    width: 300px;
  }
  & .shadowen {
    box-shadow: 2px 2px 5px rgb(0 0 0 / 20%);
  }
`;

const _IconWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  width: fit-content;
  background-color: ${({ theme }) =>
    theme.colors.semantic.layout.backgroundBox};
  padding: 8px;
  border-radius: 8px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.2);

  cursor: pointer;
  animation: fade-in 0.3s ease-in-out forwards;

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
