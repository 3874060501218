import React, { FC, useContext } from "react";
import { LoginTemplate } from "./components/templates/Login";
import { useAudio } from "./utils/hooks/useAudio";
import { Auth0Context } from "./contexts/Auth0Context";
import { setLoginPageViewedAt } from "./utils/googleAnalytics/localStorage";
import { trackEvent } from "./utils/googleAnalytics/trackEvent";

export const Login: FC = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [isOpenForgetPasswordToast, setIsOpenForgetPasswordToast] =
    React.useState(false);
  const { play } = useAudio({ preload: ["initializeAudio"] });

  React.useEffect(() => {
    setLoginPageViewedAt(new Date());
    trackEvent({ event: "login_page_opened" });
  }, []);

  const handleChangeEmail = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    event.preventDefault();
    setEmail(event.target.value);
  };

  const handleChangePassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    event.preventDefault();
    setPassword(event.target.value);
  };

  const { login } = useContext(Auth0Context);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    setIsOpenForgetPasswordToast(false);
    login(email, password, () => {
      setIsOpenForgetPasswordToast(true);
    });
    play("initializeAudio");
  };

  return (
    <LoginTemplate
      email={email}
      password={password}
      handleChangeEmail={handleChangeEmail}
      handleChangePassword={handleChangePassword}
      handleSubmit={handleSubmit}
      isOpenForgetPasswordToast={isOpenForgetPasswordToast}
      closeForgetPasswordToast={() => setIsOpenForgetPasswordToast(false)}
    />
  );
};

/*
export const Login: React.FC = () => {
  const [id, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [mutationState, setMutationState] = React.useState("idle");
  const [mutationMessage, setMutationMessage] = React.useState("");
  const [mutationMessageLink, setMutationMessageLink] = React.useState("");
  const handleIdChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault();
    setEmail(e.target.value);
  };
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();
    setPassword(e.target.value);
  };
  const handleSubmit = (): void => {
    sessionStore.login(id, password);
  };
  const handleMutationStateIndicater = (dMessage: string, mutationSuccess: boolean): void => {
    setMutationMessage(dMessage);
    if(mutationSuccess){
      setMutationState("success");
    }else{
      setMutationState("fail");
    }
    return;
  };
  React.useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      if(sessionStore.loginFailed === true) {
        switch (sessionStore.failReason) {
        case "not_active_account":
          handleMutationStateIndicater("アカウントの有効期限が切れています", false);
          setMutationMessageLink(GuideForExpireLink);
          break;
        case "unauthorized":
          handleMutationStateIndicater("IDまたはパスワードに間違いがあります", false);
          setMutationMessageLink(ForgetPasswordHelpLink);
          break;
        default:
          handleMutationStateIndicater("", false);
        }

      }
    }
    return (() => {
      unmounted = true;
    });
  }, []);
  return (
    <>
      <Wrapper>
        <LogoContainer>
          <img src={"/img/yondemylogo.png"} style={{ width: "100%" }} />
        </LogoContainer>
        <LoginInputBox>
          <Input
            size="lg"
            value={id}
            onChange={handleIdChange}
            label={"お子さん用ユーザーID"}
            annotation={"メールアドレスではありません。"}
          />
        </LoginInputBox>
        <LoginInputBox>
          <Input
            size={"lg"}
            type={"password"}
            value={password}
            onChange={handlePasswordChange}
            label={"お子さん用パスワード"}
            annotation={"初めてログインする際のお子さん用パスワードは、お子さん用ユーザーIDと同じです。"}
          />
          <FlexCon>
            <HelpLink
              target="_blank"
              href={ForgetPasswordHelpLink}
              rel="noopener noreferrer"
            >
              お子さん用ID・パスワードがわからない場合はこちら
            </HelpLink>
          </FlexCon>
        </LoginInputBox>
        <LoginButtonContainer>
          <LargeButton
            buttonText="ログイン"
            onClick={handleSubmit}
          />
        </LoginButtonContainer>
      </Wrapper>
      <IndicationMutationStateCon>
        <IndicateMutationState
          setMutationState={setMutationState}
          mutationState={mutationState}
          displayedMessage={mutationMessage}
        >
          <div>
            <Message>
              {mutationMessage}
            </Message>
            <DetailLinkCon>
              <FontAwesomeIcon icon={"question-circle"} style={{color: "white", marginRight: "5px"}} />
              <MessageLink
                href={mutationMessageLink}
                target={"_blank"}
                rel={"noopener noreferrer"}
              >
                {"くわしく"}
              </MessageLink>
            </DetailLinkCon>
          </div>
        </IndicateMutationState>
      </IndicationMutationStateCon>
    </>
  );
};

const LogoContainer = styled.div`
  margin: 9vh auto 9vh auto;
  width: 80%;
`;

const LoginInputBox = styled.div`
  padding: 15px 0;
`;

const HelpLink = styled.a`
  display: block;
  text-decoration: none;
  font-size: 14px;
  line-height: 160%;
  color: ${(props) => props.theme.colors.tex.t400};
  &:hover,
  &:active,
  &:focus {
    text-decoration: underline;
    color: ${(props) => props.theme.colors.tex.t600};
  }
`;

const FlexCon = styled.div`
  display: flex;
  justify-content: center;
  padding: 15px 0;
`;

const LoginButtonContainer = styled.div`
  padding: 6vh 0;
`;

const Message = styled.p`
  color: ${(props) => props.theme.colors.base.white};
  font-size: 14px;
  font-weight: bold;
  font-family: inherit;
  margin: 0;
`;

const IndicationMutationStateCon = styled.div`
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: ${(props) => props.theme.zIndex.popup};
  width: 95%;
  @media screen and (min-width: 750px) {
    width: 600px;
  }
`;

const DetailLinkCon = styled.div`
  display: flex;
  align-items: center;
  padding-top: 8px;
`; */
