import React from "react";
import styled from "styled-components";
import { Text } from "~/components/atoms/texts/Text";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";

export const ExpProgressBar: React.FC = () => {
  const { userExperience } = React.useContext(CurrentUserContext);
  if (!userExperience) {
    return <></>;
  }
  return (
    <_Wrapper>
      <_Background width={userExperience.progress} />
      <_TextWrapper>
        <Text fontColor="sec.s500" fontSize="XS" lineHeight="EQ" bold>
          レベル
        </Text>
        <Text fontColor="sec.s500" fontSize="XL" lineHeight="EQ" bold>
          {userExperience?.userLevel}
        </Text>
      </_TextWrapper>
    </_Wrapper>
  );
};

const _Wrapper = styled.div`
  position: relative;
  width: 100px;
  height: 37px;
  background-color: ${({ theme }) => theme.colors.base.white};

  border: 3px solid ${({ theme }) => theme.colors.base.lightGray};
  border-radius: 16px;
  overflow: hidden;
`;

const _Background = styled.div<{ width: number }>`
  width: ${({ width }) => `${width}%`};
  height: 100%;
  background-color: ${({ theme }) => theme.colors.pri.p500};
  background-image: ${({ theme }) => `linear-gradient(
    -80deg,
    transparent 38%,
    ${theme.colors.pri.p400} 38%,
    ${theme.colors.pri.p400} 62%,
    transparent 62%
  );`};
  background-size: 10px 31px;
  background-repeat: repeat;
`;

const _TextWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 6px 4px;

  display: flex;
  justify-content: center;
  align-items: baseline;
  column-gap: 8px;
`;
