import React from "react";
import styled from "styled-components";
import { Stack } from "~/components/atoms/layout/Stack";
import { Text } from "~/components/atoms/texts/Text";
import {
  BookInfoCard,
  BookInfoCardProps,
} from "~/components/molecules/BookInfoCard";
import {
  PaginationButton,
  PaginationButtonProps,
} from "~/components/molecules/buttons/PaginationButton";
import { ReserveBookButton } from "../ReserveBookButton";
import { useTranslation } from "~/i18n";

type AllRecommendListTemplateProps = {
  items: BookInfoCardProps[];
  paginationButtonProps: PaginationButtonProps;
};

export const AllRecommendListTemplate: React.FC<
  AllRecommendListTemplateProps
> = ({ items, paginationButtonProps }) => {
  const { t } = useTranslation();
  return (
    <_Wrapper>
      {items.length === 0 && (
        <_EmptyWrapper>
          <Text fontSize="MD" lineHeight="MD">
            {t<TranslateKeys>(
              "まだおすすめ・よみま書架の本がないよ。ヨンデミー先生のおすすめを待っててね。"
            )}
          </Text>
        </_EmptyWrapper>
      )}
      {items.length !== 0 && (
        <Stack rowGap="8px" alignItems="center">
          <ReserveBookButton />
          {items.map((item, index) => (
            <BookInfoCard key={item.title + String(index)} {...item} />
          ))}
          <PaginationButton {...paginationButtonProps} />
        </Stack>
      )}
    </_Wrapper>
  );
};

const _Wrapper = styled(Stack)`
  background-color: ${({ theme }) => theme.colors.primitive.monotone.m0};
  padding: 16px;
  border-radius: 0px 0px 12px 12px;
  gap: 16px;
`;

const _EmptyWrapper = styled.div`
  border-radius: 16px;
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.primitive.monotone.m40};
  text-align: center;
`;
