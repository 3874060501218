import React, { ReactNode } from "react";
import styled from "styled-components";
import { LineImage } from "./yondemyStory";

type StoryMessageProps = {
  message: string;
  lineImage?: LineImage;
  messageFinished?: boolean;
  onFinished: () => void;
};

const timeout = 150;
const initialCount = -12;

export const StoryMessage: React.FC<StoryMessageProps> = ({
  message,
  lineImage = "/img/world_view_onboarding/yondemy_story/line_orange.png",
  messageFinished = false,
  onFinished,
}) => {
  const [_message, setMessage] = React.useState<ReactNode>("");
  const [count, setCount] = React.useState<number>(initialCount);

  React.useEffect(() => {
    setCount(initialCount);
    setMessage(<span className="invisible">{message}</span>);
  }, [message]);

  React.useEffect(() => {
    if (count >= message.length) {
      return;
    }
    const TimeoutID = window.setTimeout(() => {
      let _count = count + 1;
      if (messageFinished) {
        _count = message.length;
      }

      setCount(_count);

      if (_count <= 0) {
        return;
      }

      const newMessage = (
        <>
          <span className="visible">{message.substring(0, _count)}</span>
          <span className="invisible">{message.substring(_count)}</span>
        </>
      );
      setMessage(newMessage);

      if (_count >= message.length) {
        onFinished();
      }
    }, timeout);

    return () => clearTimeout(TimeoutID);
  }, [count, message, messageFinished]);

  return (
    <_Wrapper>
      <_MiddleBox>
        <_MessageWrapper>
          <_MessageText>{_message}</_MessageText>
          <_TriangleWrapper>
            <_Triangle />
          </_TriangleWrapper>
        </_MessageWrapper>
        <_LineImg src={lineImage} />
      </_MiddleBox>
    </_Wrapper>
  );
};

const _Wrapper = styled.div`
  user-select: none;
  width: 100%;
  position: relative;
  opacity: 0;
  backdrop-filter: blur(4px);

  animation: fadeIn 0.5s ease-out;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const _LineImg = styled.img`
  position: absolute;
  top: -16px;
  width: 100%;
`;

const _MiddleBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const _MessageWrapper = styled.div`
  width: 376px;
  //  height: 156px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 8px;
`;

const _MessageText = styled.div`
  font-family: "M PLUS 2", sans-serif;
  height: 100%;
  color: ${({ theme }) => theme.colors.tex.t700};
  font-size: ${({ theme }) => theme.fontSize.LG};
  line-height: 160%;
  white-space: pre-wrap;

  .invisible {
    color: transparent;
  }
`;

const _TriangleWrapper = styled.div`
  animation: 2s ease-in-out fadeInOut infinite;

  @keyframes fadeInOut {
    from {
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;

const _Triangle: React.FC = () => (
  <svg
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.1564 7.28687C16.447 8.06437 16.447 9.93563 15.1564 10.7131L3.78207 17.5656C2.44909 18.3687 0.749997 17.4087 0.749997 15.8525L0.749998 2.14751C0.749998 0.591308 2.44909 -0.368683 3.78208 0.434377L15.1564 7.28687Z"
      fill="#F3AE36"
    />
  </svg>
);
