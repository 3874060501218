import * as React from "react";
import styled from "styled-components";
import { useTranslation } from "~/i18n";
import { Text } from "~/components/atoms/texts/Text";
import { RecommendFrequency } from "~/generated/graphql";
import recommendFrequencyTable from "~/components/templates/EditRecommendFrequency/const";

export type ListHeaderProps = {
  /**
   * おすすめ本リストの残り冊数
   */
  restCount: number;
  /**
   * おすすめ本リストの表示冊数
   */
  recommendLength: number;
  /**
   * 一覧に表示する件数
   */
  recommendFrequency?: RecommendFrequency;
};

export const ListHeader: React.VFC<ListHeaderProps> = ({
  restCount,
  recommendLength,
  recommendFrequency = RecommendFrequency.Basic,
}) => {
  const { t } = useTranslation<TranslateKeys>();

  const freqSetting = recommendFrequencyTable[recommendFrequency];

  const bookListMax = freqSetting.bookListMax;
  const emptyCount = bookListMax - recommendLength;
  const countdownForNextRecommend = recommendLength - restCount;

  const message = React.useMemo<React.ReactNode>(() => {
    return countdownForNextRecommend > 0 ? (
      <Text fontSize="SM" lineHeight="MD" bold>
        {t<TranslateKeys>("おすすめの本の")}
        <_Span>
          {t<TranslateKeys>("残りが{{border}}冊", { border: restCount })}
        </_Span>
        {t<TranslateKeys>("になったら、次のおすすめが届くよ。")}
        <_Span>
          {t<TranslateKeys>("あと{{rest}}冊", {
            rest: countdownForNextRecommend,
          })}
        </_Span>
        だよ！
      </Text>
    ) : (
      <Text fontSize="SM" lineHeight="MD" bold>
        {t<TranslateKeys>("もうすぐ次のおすすめが届くよ。待っててね！")}
      </Text>
    );
  }, [countdownForNextRecommend, restCount, t]);

  if (emptyCount <= 0) return <></>;

  return (
    <Container>
      <img src="/img/sensei/standup_1.svg" style={{ height: "80px" }}></img>
      {message}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.primitive.yellow.y100};
  border-radius: 12px;
  padding: 12px;
  column-gap: 12px;
  align-items: center;
`;

const _Span = styled.span`
  color: ${({ theme }) => theme.colors.semantic.primary.plus1};
`;
