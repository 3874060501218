import React, { VFC } from "react";
import styled from "styled-components";
import { LockedEnterReviewTemplateProps } from "./type";
import { ProgressNavigation } from "~/components/organisms/ProgressNavigation";
import { NavContainer, Wrapper } from "../../Common";
import {
  PostReadLogHeading,
  PostReadLogBodyWrapper,
  PostReadLogMarginInterpolation,
  PostReadLogBarWrapper,
} from "../Common/style";
import { PrimaryButton } from "~/components/molecules/buttons/PrimaryButton";
import { difficultyChoices } from "../ConfirmDifficulty";
import { useLengthChoices } from "../ConfirmLength";
import { useLikeChoices } from "../ConfirmLike";
import { emotions } from "../ConfirmEmotions/type";
import { useTranslation } from "~/i18n";
import { SmallTag } from "~/components/atoms/indicators/SmallTag";
import { unlockLevel } from "~/store/unlock/useUnlock";

const chainSvgUrl = "/img/unlock/enter_review_chain.svg";
const keySvgUrl = "/img/unlock/book_worm_key.svg";

export const LockedEnterReviewTemplate: VFC<LockedEnterReviewTemplateProps> = ({
  progress,
  bookTitle,
  like,
  difficulty,
  length,
  emotionsObj,
  handleBack,
  onClickGoNext,
}) => {
  const { t } = useTranslation();
  const likeChoices = useLikeChoices(t);
  const lengthChoices = useLengthChoices(t);

  const isNotLike = like < 3; //苦手と答えたユーザーには別のメッセージを表示する
  return (
    <>
      <NavContainer>
        <PostReadLogBarWrapper>
          <ProgressNavigation
            progress={progress}
            handleBack={handleBack}
            buttonsPattern={"single"}
          />
        </PostReadLogBarWrapper>
      </NavContainer>
      <Wrapper>
        <PostReadLogBodyWrapper>
          <_LowerOpacityCon>
            <HeadingCon>
              <PostReadLogHeading fontSize={"MD"} lineHeight={"MD"} bold>
                {isNotLike ? (
                  <>
                    {`${t<TranslateKeys>(
                      "『{{bookTitle}}』の あまり好きではなかったところは どこだろう？",
                      { bookTitle }
                    )}🤔💭`}
                    <br />
                    {t<TranslateKeys>(
                      "あまり好きではなかった本の 感想は「本の友」には 流れません！"
                    )}
                    <br />
                    {`${t<TranslateKeys>(
                      "ヨンデミー先生 にだけ こっそり教えてね"
                    )}😊`}
                  </>
                ) : (
                  <>
                    {t<TranslateKeys>("『{{bookTitle}}』の", { bookTitle })}
                    {t<TranslateKeys>("一言感想を教えてください！")}
                  </>
                )}
              </PostReadLogHeading>
            </HeadingCon>
          </_LowerOpacityCon>
          <TextAreaCon>
            <_LowerOpacityCon>
              <ChoicesCon>
                <Row>
                  <SmallTag {...likeChoices[like - 1]} color={"gray"} />
                  <SmallTag
                    {...difficultyChoices[difficulty - 1]}
                    color={"gray"}
                  />
                  <SmallTag {...lengthChoices[length - 1]} color={"gray"} />
                </Row>
                <Row>
                  {Object.keys(emotionsObj)
                    .filter((key) => emotionsObj[key] === true)
                    .map((key, index) => (
                      <SmallTag key={index} {...emotions[key]} color={"gray"} />
                    ))}
                </Row>
              </ChoicesCon>
            </_LowerOpacityCon>
            <_LockedTextAreaCon>
              <_LockedTextArea />
              <_Chain />
              <_BookWormKey src={keySvgUrl} />
              <_CommentWrapper>
                <_Comment>
                  <span>レベル{unlockLevel.enterFreeReview} </span>になったら
                  <br />
                  カギがあくみたい......
                </_Comment>
              </_CommentWrapper>
            </_LockedTextAreaCon>
          </TextAreaCon>
          <ButtonCon>
            <PrimaryButton
              onClick={onClickGoNext}
              text={"すすむ"}
              color={"pri"}
              fontSize={"LG"}
              lineHeight={"MD"}
              bold
              withArrow
            />
          </ButtonCon>
        </PostReadLogBodyWrapper>
      </Wrapper>
    </>
  );
};

const HeadingCon = styled.div``;

const ChoicesCon = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const ButtonCon = styled.div`
  ${PostReadLogMarginInterpolation.Middle}
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & > * {
    margin: ${({ theme }) => theme.size.XS} 0;
  }
`;

const TextAreaCon = styled.div`
  ${PostReadLogMarginInterpolation.Middle}
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.size.S};
`;

const _LowerOpacityCon = styled.div`
  opacity: 0.2;
  filter: blur(3px);
`;

const _LockedTextArea = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  overflow: hidden;

  border-radius: ${({ theme }) => theme.deprecatedBorderRadius.MD};
  border: 2px solid ${({ theme }) => theme.colors.base.gray};
  background-color: ${({ theme }) => theme.colors.base.white};

  opacity: 0.2;
`;

const _LockedTextAreaCon = styled.div`
  position: relative;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const _Chain = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  background-image: url(${chainSvgUrl});
  background-repeat: no-repeat;
  background-size: 1200px;
  background-position: center center;
`;

const _BookWormKey = styled.img`
  position: absolute;
  top: 20%;

  width: 25%;
  @media screen and (min-width: ${({ theme }) => theme.screenSize.smallPCMax}) {
    width: 20%;
  }
  object-fit: contain;
`;

const _CommentWrapper = styled.div`
  position: absolute;
  padding: 10px;
  bottom: 90%;

  background-color: ${({ theme }) => theme.colors.sec.s400};
  border-radius: ${({ theme }) => theme.deprecatedBorderRadius.S};

  ::after {
    position: absolute;
    content: "";
    left: calc(50% - 8px);
    top: 98%;
    border-top: 12px solid ${({ theme }) => theme.colors.sec.s400};
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
  }
`;

const _Comment = styled.p`
  color: ${({ theme }) => theme.colors.base.white};
  line-height: 160%;
  font-size: ${({ theme }) => theme.fontSize.SM};
  font-weight: bold;

  span {
    font-size: ${({ theme }) => theme.fontSize.XXL};
  }
`;
