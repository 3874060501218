import React from "react";
import { Text } from "~/components/atoms/texts/Text";
import { Stack } from "~/components/atoms/layout/Stack";
import { TranslateKeys, useTranslation } from "~/i18n";
import { Loading } from "~/components/atoms/Loading";
import { BaseModal } from "~/components/atoms/wrappers/BaseModal";
import { LargeButton } from "~/components/atoms/buttons/Button";

export type ConfirmRemoveYomimashokaModalProps = {
  onClickReturn: () => void;
  onClickRemove: () => Promise<boolean>;
  isOpen: boolean;
  loading: boolean;
};

export const ConfirmRemoveYomimashokaModal: React.FC<
  ConfirmRemoveYomimashokaModalProps
> = ({ onClickRemove, onClickReturn, isOpen, loading }) => {
  const { t } = useTranslation();
  return (
    <BaseModal open={isOpen} onClose={onClickReturn}>
      <Stack rowGap="24px">
        <Text fontColor="tex.t700" fontSize="LG" lineHeight="MD">
          {t<TranslateKeys>("選んだ本をよみま書架から消しますか？")}
        </Text>
        <Stack rowGap="16px">
          {loading ? (
            <Loading />
          ) : (
            <LargeButton color="red" onClick={onClickRemove}>
              {t<TranslateKeys>("消す")}
            </LargeButton>
          )}
          <LargeButton color="gray" onClick={onClickReturn}>
            やっぱりやめる
          </LargeButton>
        </Stack>
      </Stack>
    </BaseModal>
  );
};
