import React from "react";
import styled from "styled-components";
// TODO: neo componentsの方で作成する
import {
  BookCoverContainer,
  BookItemContainerInterpolation,
} from "../Common/style";
import { BookshelfImage } from "~/components/atoms/images/BookshelfImage";

export type NextBookImageButtonListItemProps = {
  /*
    本のタイトル
  */
  title: string;
  /*
    画像のURL
  */
  imageUrl?: string;

  /*
    本のボタンをクリックした時に発火する関数
  */
  onClick: (event: React.MouseEvent) => void;
};

export const NextBookImageButtonListItem: React.FC<
  NextBookImageButtonListItemProps
> = ({ title, imageUrl, onClick }) => {
  return (
    <BookItemContainer onClick={onClick}>
      <BookCoverContainer>
        <BookshelfImage url={imageUrl} alt={title} />
      </BookCoverContainer>
    </BookItemContainer>
  );
};

const BookItemContainer = styled.button`
  background: none;
  padding: 0;
  border: none;
  ${BookItemContainerInterpolation}
`;
