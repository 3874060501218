import React from "react";
import styled from "styled-components";
import { BackNavbar } from "~/components/molecules/navbars/BackNavbar";
import {
  EditReadLogForm,
  EditReadLogFormProps,
} from "~/components/organisms/forms/EditReadLogForm";
import {
  NotifyTextToast,
  NotifyTextToastProps,
} from "~/components/organisms/toasts/NotifyTextToast";
import { useTranslation } from "~/i18n";

type EditReadLogTemplateProps = {
  onBack: () => void;
  editReadLogForm: EditReadLogFormProps;
  error: Omit<NotifyTextToastProps, "text">;
};

export const EditReadLogTemplate: React.FC<EditReadLogTemplateProps> = ({
  onBack,
  editReadLogForm,
  error,
}) => {
  const { t } = useTranslation();

  return (
    <EditReadLogTemplateContainer>
      <NavContainer>
        <BackNavbar titleName={t<TranslateKeys>("感想編集")} onClick={onBack} />
      </NavContainer>
      <Wrapper>
        <EditReadLogForm {...editReadLogForm} />
      </Wrapper>
      <NotifyTextToast
        {...error}
        text={t<TranslateKeys>(
          "ごめんなさい！送ることができませんでした。もう一度試してみてね"
        )}
      />
    </EditReadLogTemplateContainer>
  );
};

const EditReadLogTemplateContainer = styled.div``;

const NavContainer = styled.div`
  width: 100%;
  position: fixed;
  top: -1px;
  z-index: ${(props) => props.theme.zIndex.navbar};
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 30px 4%;
  min-height: 100vh;
  @media screen and ${({ theme }) =>
      theme.breakPoints.minWidthSmallSmartPhone} {
    padding: 60px 10% 0 10%;
  }
  @media ${({ theme }) => theme.breakPoints.minWidthSmartPhone} {
    width: 100%;
    padding: 60px 30% 0 30%;
  }
  background-color: ${({ theme }) => theme.colors.base.ashGray};
`;
