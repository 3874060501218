import React, { useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { ReadLogTerm, useGetReviewRecordsQuery } from "~/generated/graphql";
import { useTranslation } from "~/i18n";
import { SectionTitle } from "~/components/molecules/texts/SectionTitle";

moment.locale("ja", {
  weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"],
});
const MAX_NUMBER_OF_TITLES = 2; //画面に表示する本のタイトル数の最大数

type ReadLogTermData = {
  when: any; //eslint-disable-line
  book: {
    title?: string;
  };
}[];

const getTitlesAtSelectedDate = (
  readLogTerm: ReadLogTermData,
  date: Date
): string => {
  const bookArr = readLogTerm.filter(
    (data) =>
      new Date(data.when).toLocaleDateString() === date.toLocaleDateString()
  );

  const titleArr = bookArr.map(({ book }) => {
    if (book.title && book.title.length > 20) {
      return `${book.title.slice(0, 20)}…`;
    }
    return book.title;
  });

  if (titleArr.length > MAX_NUMBER_OF_TITLES) {
    const tmp = titleArr.slice(0, MAX_NUMBER_OF_TITLES);
    return `${tmp.join(", ")}, ほか${
      titleArr.length - MAX_NUMBER_OF_TITLES
    }さつ`;
  }
  return titleArr.join(", ");
};

const enumerateDaysBetweenDates = (startDate: string, endDate: string) => {
  const date = [] as string[];
  while (moment(startDate) <= moment(endDate)) {
    date.push(startDate);
    startDate = moment(startDate).add(1, "days").toISOString();
  }
  return date;
};

const get0Oclock = (): Date => {
  const _d = moment().add(-6, "days").toDate();
  return new Date(_d.getFullYear(), _d.getMonth(), _d.getDate(), 0, 0, 0);
};

export const Reviews: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState(get0Oclock().toISOString());
  const { t } = useTranslation();

  const decreaseWeek = () => {
    const newDate = moment(selectedDate)
      .startOf("days")
      .add(-7, "days")
      .toISOString();
    setSelectedDate(newDate);
  };

  const increaseWeek = () => {
    const newDate = moment(selectedDate)
      .startOf("days")
      .add(7, "days")
      .toISOString();
    setSelectedDate(newDate);
  };

  const getDateArr = (
    readLogTerm: ReadLogTermData,
    selectedDate: string
  ): { label: string; titles: string; day: number }[] => {
    const endDate = moment(selectedDate).add(6, "days").toISOString();
    const dateList = enumerateDaysBetweenDates(selectedDate, endDate);
    return dateList.map((date) => ({
      label: moment(date).format("M/D(ddd)"),
      titles: getTitlesAtSelectedDate(readLogTerm, new Date(date)),
      day: moment(date).day(),
    }));
  };

  const { loading, error, data } = useGetReviewRecordsQuery({
    variables: {
      readLogTerm: ReadLogTerm.Days_7,
      end: 100,
      startTime: selectedDate,
    },
    fetchPolicy: "network-only",
  });

  const todaysDay = moment().day();

  if (loading) {
    //todo: キャッシュ導入してLoading中の動きを滑らかに
    return (
      <>
        <SectionTitle>{t<TranslateKeys>("感想提出")}</SectionTitle>
        <ReviewsWrapper>
          <ReviewButton
            onClick={() => {
              return;
            }}
          >
            <img src="/img/upper_arrow.svg" alt="前の週の感想をみる" />
          </ReviewButton>
          <ReviewItem day={todaysDay - 6} />
          <ReviewItem day={todaysDay - 5} />
          <ReviewItem day={todaysDay - 4} />
          <ReviewItem day={todaysDay - 3} />
          <ReviewItem day={todaysDay - 2} />
          <ReviewItem day={todaysDay - 1} />
          <ReviewItem day={todaysDay} />
          <ReviewButton
            onClick={() => {
              return;
            }}
          >
            <img src="/img/down_arrow.svg" alt="次の週の感想をみる" />
          </ReviewButton>
        </ReviewsWrapper>
      </>
    );
  }
  if (error) return <>Error!{error.message}</>;

  return (
    <>
      <SectionTitle>{t<TranslateKeys>("感想提出")}</SectionTitle>
      <ReviewsWrapper>
        <ReviewButton onClick={decreaseWeek}>
          <img src="/img/upper_arrow.svg" alt="前の週の感想をみる" />
        </ReviewButton>
        {getDateArr(
          data?.me.readLogTerm.get as ReadLogTermData,
          selectedDate
        ).map(({ label, titles, day }, index) => (
          <ReviewItem key={index} day={day}>
            <ReviewDate>{label}</ReviewDate>
            <ReviewDescription>{titles}</ReviewDescription>
          </ReviewItem>
        ))}
        <ReviewButton onClick={increaseWeek}>
          <img src="/img/down_arrow.svg" alt="次の週の感想をみる" />
        </ReviewButton>
      </ReviewsWrapper>
    </>
  );
};

const ReviewsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
`;

const ReviewButton = styled.button`
  background: none;
  border: none;
  opacity: 0.9;
  &:focus {
    outline: none;
    border: none;
    opacity: 1;
  }
`;

const ReviewItem = styled.div<{ day: number }>`
  width: 100%;
  height: 80px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  font-weight: bold;
  padding: 0 15px;
  background-color: ${({ day, theme }) => {
    if (day % 7 === 0) {
      return theme.colors.primitive.red.r200;
    } else if (day % 7 === 6) {
      return theme.colors.primitive.green.g200;
    } else {
      return theme.colors.primitive.orange.o100;
    }
  }};
`;

const ReviewDate = styled.span`
  font-size: 16px;
  margin-right: 9px;
  color: ${({ theme }) => theme.colors.semantic.text.label};
`;

const ReviewDescription = styled.p`
  font-size: 14px;
  line-height: 160%;
  text-align: left;
  color: ${({ theme }) => theme.colors.semantic.text.heading};
  margin: 0;

  // 3行まで
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
`;
