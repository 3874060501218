import React, { VFC } from "react";
import styled from "styled-components";
import { ConfirmDifficultyTemplateProps } from "./type";
import { EmojiButton } from "~/components/molecules/buttons/EmojiButton";
import { NavContainer, Wrapper } from "../../Common";
import { ProgressNavigation } from "~/components/organisms/ProgressNavigation";
import {
  postReadLogEmojiButtonStyle,
  PostReadLogHeading,
  PostReadLogBodyWrapper,
  PostReadLogMarginInterpolation,
  PostReadLogCharacterImg,
  PostReadLogBarWrapper,
} from "../Common/style";
import { PostReadLogChoiceType } from "../Common/type";
import { useTranslation } from "~/i18n";

export const difficultyChoices: PostReadLogChoiceType[] = [
  { ja: "かんたん", emoji: "😋" },
  { ja: "ちょっとかんたん", emoji: "😗" },
  { ja: "ぴったり！", emoji: "😉" },
  { ja: "ちょっとむずかしい", emoji: "😅" },
  { ja: "むずかしい", emoji: "😣" },
];

export const ConfirmDifficultyTemplate: VFC<ConfirmDifficultyTemplateProps> = ({
  progress,
  handleBack,
  onClicks,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <NavContainer>
        <PostReadLogBarWrapper>
          <ProgressNavigation
            progress={progress}
            handleBack={handleBack}
            buttonsPattern={"single"}
          />
        </PostReadLogBarWrapper>
      </NavContainer>
      <Wrapper>
        <PostReadLogBodyWrapper>
          <HeadingCon>
            <PostReadLogHeading fontSize={"LG"} lineHeight={"MD"} bold>
              {t<TranslateKeys>("当てはまるものをえらんでね！")}
            </PostReadLogHeading>
          </HeadingCon>
          <CharacterCon>
            <PostReadLogCharacterImg src="/img/sensei/standup_3.svg" />
          </CharacterCon>
          <ButtonCon>
            {onClicks.map((onClick, index) => (
              <EmojiButton
                key={index}
                {...postReadLogEmojiButtonStyle}
                emoji={difficultyChoices[index].emoji}
                text={difficultyChoices[index].ja}
                onClick={onClick}
              />
            ))}
          </ButtonCon>
        </PostReadLogBodyWrapper>
      </Wrapper>
    </>
  );
};

const CharacterCon = styled.div`
  ${PostReadLogMarginInterpolation.Narrow}
  display: flex;
  justify-content: center;
`;

const HeadingCon = styled.div``;

const ButtonCon = styled.div`
  ${PostReadLogMarginInterpolation.Narrow}
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & > * {
    margin: ${({ theme }) => theme.size.XS} 0;
  }
`;
