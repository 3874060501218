import * as React from "react";
import { GetLetterModal } from "./GetLetterModal";
import { CelebrateBirthdayModal } from "./CelebrateBirthdayModal";

type BirthdayModalProps = {
  onClose: () => void;
};
type Flow = "getLetter" | "celebrateBirthday";

export const BirthdayModal: React.FC<BirthdayModalProps> = ({ onClose }) => {
  const [flow, setFlow] = React.useState<Flow>("getLetter");

  return (
    <>
      <GetLetterModal
        open={flow === "getLetter"}
        onClose={() => setFlow("celebrateBirthday")}
      />
      <CelebrateBirthdayModal
        open={flow === "celebrateBirthday"}
        onClose={onClose}
      />
    </>
  );
};
