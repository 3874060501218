import * as React from "react";
import {
  useGetBfNoticeQuery,
  useGetUnreadBfNoticeExistQuery,
  useUpdateUnreadBfNoticeMutation,
} from "~/generated/graphql";
import { ReactionsButton } from "./ReactionsButton";
import { ReactionsModal } from "./ReactionsModal";
import { BfReadLogStarNoticeItem, ReadLogReactionNoticeItem } from "./types";
import { useUnlock } from "~/store/unlock/useUnlock";
import debounce from "lodash/debounce";
import { sendButtonClickEvent } from "~/utils/googleAnalytics/useButtonClickTracking";

export type UseBookFriendReactionValues = {
  reactionsButton: React.ReactNode;
  reactionsModal: React.ReactNode;
};

const FETCH_LIST_SIZE = 30;

export const useBookFriendReactions = (): UseBookFriendReactionValues => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [items, setItems] = React.useState<ReadLogReactionNoticeItem[]>([]);
  const [openReceiveStarModal, setOpenReceiveStarModal] =
    React.useState<boolean>(false);
  const [hasUnread, setHasUnread] = React.useState<boolean>(false);
  const [unreadBorderTime, setUnreadBorderTime] = React.useState<
    Date | undefined
  >(undefined);
  const [isDoneFirstFetch, setIsDoneFirstFetch] =
    React.useState<boolean>(false);

  const { isLocked } = useUnlock();
  const isBookFriendLocked = isLocked("bookFriend");

  const { data: unread, refetch: refetchUnreadExist } =
    useGetUnreadBfNoticeExistQuery({
      fetchPolicy: "network-only",
      skip: isBookFriendLocked,
    });

  const [updateUnreadBfNotice] = useUpdateUnreadBfNoticeMutation();

  const onClose = React.useCallback(() => {
    refetchUnreadExist();
    updateUnreadBfNotice();
    setHasUnread(false);
    setOpen(false);
    setItems([]);
    setIsDoneFirstFetch(false);
  }, [refetchUnreadExist, updateUnreadBfNotice]);

  const { data: bfNoticeData, refetch: bfNoticeRefetch } = useGetBfNoticeQuery({
    skip: !open,
    variables: {
      begin: 0,
      end: FETCH_LIST_SIZE,
    },
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      // firstFetchと区別しないとrefetchしたときにrefetchしたときのthen文と競合してしまう
      if (!isDoneFirstFetch) {
        setIsDoneFirstFetch(true);
        setItems(res.getBfNotice.getBfNoticeList.get);
        if (res.getBfNotice.unreadBorderTime) {
          setUnreadBorderTime(new Date(res.getBfNotice.unreadBorderTime));
        }
        const unreadStarItems = res.getBfNotice.getBfNoticeList.get
          .filter(
            (item) =>
              item.__typename === "BfReadLogStarNoticeItem" &&
              (!res.getBfNotice.unreadBorderTime
                ? true
                : new Date(res.getBfNotice.unreadBorderTime) <
                  new Date(item.createdAt))
          )
          .map((item) => item as BfReadLogStarNoticeItem);

        if (unreadStarItems.length > 0) {
          setOpenReceiveStarModal(true);
        }
      }
    },
  });

  React.useEffect(() => {
    if (unread?.getUnreadBfNoticeExist) {
      setHasUnread(true);
    }
  }, [unread]);

  const onEndReached = React.useCallback(
    debounce(async () => {
      if (
        items.length >= (bfNoticeData?.getBfNotice.getBfNoticeList.count || 0)
      ) {
        return;
      }

      const begin = items.length || 0;
      const end = begin + FETCH_LIST_SIZE;

      await bfNoticeRefetch({
        begin,
        end,
      }).then((res) => {
        const newItems = res.data.getBfNotice.getBfNoticeList.get;

        setItems((prevItems) => {
          const existingIds = new Set(prevItems.map((item) => item.id));
          const filteredNewItems = newItems.filter(
            (item) => !existingIds.has(item.id)
          );
          return [...prevItems, ...filteredNewItems];
        });

        if (res.data.getBfNotice.unreadBorderTime) {
          setUnreadBorderTime(new Date(res.data.getBfNotice.unreadBorderTime));
        }
      });
    }, 100),
    [
      bfNoticeRefetch,
      items.length,
      bfNoticeData?.getBfNotice.getBfNoticeList.count,
    ]
  );

  return {
    reactionsButton: (
      <>
        {!isBookFriendLocked && (
          <ReactionsButton
            onClick={() => {
              setOpen(true);
              sendButtonClickEvent("bookFriendReactionsButton");
            }}
            hasUnread={hasUnread}
          />
        )}
      </>
    ),
    reactionsModal: (
      <ReactionsModal
        open={open}
        items={items}
        onClose={onClose}
        onEndReached={onEndReached}
        unreadBorderTime={unreadBorderTime}
        hasUnread={hasUnread}
        openReceiveStarModal={openReceiveStarModal}
        onCloseReceiveStarModal={() => setOpenReceiveStarModal(false)}
      />
    ),
  };
};
