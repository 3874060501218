import { makeVar, useReactiveVar } from "@apollo/client";
import { ActionType, initialState, PostReadLogState, reducer } from "./reducer";

const stateVar = makeVar<PostReadLogState>(initialState);
const dispatch = (action: ActionType) => {
  stateVar(reducer(stateVar(), action));
};

export const usePostReadLogState = (): {
  state: PostReadLogState;
  dispatch: (action: ActionType) => void;
} => {
  const state = useReactiveVar(stateVar);

  return {
    state,
    dispatch,
  };
};
