import React, { VFC } from "react";
import styled from "styled-components";
import { SolidButton } from "~/components/atoms/buttons/SolidButton";
import { Text, TextProps } from "~/components/atoms/texts/Text";
import { ColorTheme } from "~/styles/design_token/color";

export type EmojiButtonProps = TextProps & {
  onClick: (event: React.MouseEvent) => void;
  color: ColorTheme;
  disabled?: boolean;
  text: string;
  emoji: string;
};

export const EmojiButton: VFC<EmojiButtonProps> = ({
  onClick,
  disabled = false,
  color,
  text,
  emoji,
  ...textProps
}) => {
  return (
    <SolidButton
      variant={"Normal"}
      color={color}
      shape={"Normal"}
      onClick={onClick}
      disabled={disabled}
    >
      <Left>
        <Emoji>{emoji}</Emoji>
      </Left>
      <Right>
        <InnerText {...textProps}>{text}</InnerText>
      </Right>
    </SolidButton>
  );
};

const Left = styled.div`
  width: 25%;
  padding-left: ${({ theme }) => theme.size.M};
`;

const Right = styled.div`
  width: 75%;
  padding-right: ${({ theme }) => theme.size.M};
`;

const InnerText = styled(Text)`
  color: ${({ theme }) => theme.colors.base.white};
  text-align: left;
`;

const Emoji = styled.p`
  font-size: ${({ theme }) => theme.fontSize.XXL};
`;
