import React from "react";
import styled, { css } from "styled-components";
import { Text } from "~/components/atoms/texts/Text";
import { Stack } from "~/components/atoms/layout/Stack";
import { useNavigate } from "react-router-dom";
import { LargeButton } from "~/components/atoms/buttons/Button";
import { useTranslation } from "react-i18next";
import { Icon } from "~/components/atoms/Icon";
import { BottomModal } from "~/components/atoms/wrappers/BottomModal";

export type CharacterAssessmentFlowModalProps = {
  sentence: string;
  onClickCloseModal: () => void;
  onClickToCharacterAssessment: () => void;
};

export const CharacterAssessmentFlowModal: React.FC<
  CharacterAssessmentFlowModalProps
> = ({ sentence, onClickCloseModal, onClickToCharacterAssessment }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const onClickCloseModalAndNavigate = () => {
    onClickToCharacterAssessment();
    navigate("/character_assessment/enquete");
  };
  return (
    <BottomModal open={true}>
      <_Wrapper>
        <div className="ballon">
          <_Ballon>
            <Stack rowGap="16px">
              <Text
                lineHeight="MD"
                fontSize="MD"
                style={{ whiteSpace: "pre-wrap" }}
              >
                {sentence}
                {t<TranslateKeys>("\n好みが合わないときは、")}
                <b>{t<TranslateKeys>("ジャンル心理テスト")}</b>
                {t<TranslateKeys>("を受けてみよう👀✨")}
              </Text>
              <LargeButton
                color="primary"
                onClick={onClickCloseModalAndNavigate}
                style={{ margin: "0 auto" }}
              >
                {t<TranslateKeys>("ジャンル心理テストを受ける")}
              </LargeButton>
              <LargeButton
                color="primary"
                variant="blank"
                style={{ margin: "0 auto" }}
                onClick={onClickCloseModal}
              >
                {t<TranslateKeys>("閉じる")}
              </LargeButton>
              <_IconTextWrapper>
                <Icon
                  name="question"
                  color="darkGray"
                  style={{ width: `10px`, height: `10px`, paddingTop: "5px" }}
                />
                <Text fontColor="tex.t300" fontSize="XS" lineHeight="MD">
                  {t<TranslateKeys>(
                    "メニューの「選書を調整」から受けることもできるよ😉"
                  )}
                </Text>
              </_IconTextWrapper>
            </Stack>
          </_Ballon>
          <div className="balloon-tail">
            <div className="balloon-tail-image" />
          </div>
        </div>
        <div className="sensei">
          <div
            className="sensei-image"
            style={{
              backgroundImage: `url(/img/sensei/standup_2.svg)`,
              maxHeight: "12svh",
            }}
          />
        </div>
      </_Wrapper>
    </BottomModal>
  );
};

const _Wrapper = styled.div`
  overflow: scroll;
  max-width: 600px;
  width: calc(100vw - 60px);
  height: fit-content;
  min-height: 100%;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  padding: 50px 0;

  .balloon {
    border-radius: 16px;
    background-color: white;
    padding: 20px;
  }

  .balloon-tail {
    display: flex;
    justify-content: center;

    .balloon-tail-image {
      width: 10px;
      height: 10px;
      transform: translateY(-1px); // 吹き出し本体との間に隙間ができるのを防ぐ
      background-image: url(/img/misc/balloon_tail.svg);
      background-repeat: no-repeat;
      background-position: center top;
      background-size: contain;
    }
  }

  .sensei {
    display: flex;
    justify-content: center;
    padding-top: 20px;

    .sensei-image {
      width: 80px;
      height: 120px;
      background-repeat: no-repeat;
      background-position: center bottom;
      background-size: contain;
    }
  }
`;

const _IconTextWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  gap: 4px;
`;

const _Ballon = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.size.M};
  ${({ theme }) => {
    return css`
      background-color: ${theme.colors.base.white};
      border-radius: ${theme.deprecatedBorderRadius.MD};
      padding: 16px;
    `;
  }}
`;
