import React, { FC } from "react";
import styled from "styled-components";
import { BookshelfType } from "../../useHistoryBookshelf";
import { Text } from "~/components/atoms/texts/Text";
import { useSnd } from "~/utils/hooks/useSnd";

export type BookshelfTabProps = {
  value: BookshelfType;
  onChange: (value: BookshelfType) => void;
};

type TabType = { label: string; value: BookshelfType };

export const BookshelfTab: FC<BookshelfTabProps> = ({ value, onChange }) => {
  const items: TabType[] = [
    { label: "おすすめ", value: "recommend" },
    { label: "よみま書架", value: "yomimashoka" },
    { label: "すべて", value: "all" },
  ];

  const { play } = useSnd();

  return (
    <_TabWrapper>
      {items.map((item) => (
        <_ButtonWrapper key={item.value}>
          {value === item.value ? (
            <_Tab
              onClick={() => {
                onChange(item.value);
              }}
              selected={true}
              tabType={item.value}
            >
              <Text
                fontColor="semantic.primary.main"
                fontSize="MD"
                lineHeight="MD"
                bold
                keepAll
              >
                {item.label}
              </Text>
            </_Tab>
          ) : (
            <_Tab
              onClick={() => {
                onChange(item.value);
                play("SWIPE");
              }}
              selected={false}
              tabType={item.value}
            >
              <Text
                fontColor="semantic.text.unselected"
                fontSize="MD"
                lineHeight="MD"
                bold
                keepAll
              >
                {item.label}
              </Text>
            </_Tab>
          )}
        </_ButtonWrapper>
      ))}
    </_TabWrapper>
  );
};

const _TabWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const _Tab = styled.button<{
  selected: boolean;
  tabType: BookshelfType;
}>`
  border: solid;
  cursor: pointer;
  outline: none;
  appearance: none;
  padding: 16px 0px;
  width: 100%;

  background-color: ${({ theme, selected }) =>
    selected
      ? theme.colors.semantic.layout.backgroundBox
      : theme.colors.primitive.monotone.m40};

  border-radius: ${({ tabType }) =>
    tabType === "recommend"
      ? "16px 0px 0px 0px"
      : tabType === "all"
      ? "0px 16px 0px 0px"
      : "0px"};

  border-width: ${({ tabType }) =>
    tabType !== "recommend" ? "0px 0px 0px 1px" : "0px"};

  border-color: ${({ theme }) => theme.colors.semantic.layout.borderPlus1};

  &:focus {
    outline: none;
  }
`;

const _ButtonWrapper = styled.div`
  flex: 1;
`;
