import { makeVar, useReactiveVar } from "@apollo/client";
import React, { ReactNode, useCallback } from "react";

const LOCAL_STORAGE_KEY = "current_user_id";

type SwitchUserContextValue = {
  switchUser: (userId?: number) => void;
  userId?: number;
  shouldSwitchUser: boolean;
  setShouldSwitchUser: (shouldSwitchUser: boolean) => void;
};

export const SwitchUserContext = React.createContext<SwitchUserContextValue>({
  switchUser: () => {
    throw new Error(
      "You forgot to wrap your component in <SwitchUserContextProvider>."
    );
  },
  setShouldSwitchUser: () => {
    throw new Error(
      "You forgot to wrap your component in <SwitchUserContextProvider>."
    );
  },
  shouldSwitchUser: false,
});

const loadUserId = (): number | undefined => {
  try {
    const value = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (value === null) {
      return undefined;
    } else {
      return parseInt(value, 10);
    }
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

const userIdVar = makeVar<number | undefined>(loadUserId());

export const getSelectedUserId = (): number | undefined => {
  return userIdVar();
};

export const SwitchUserContextProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const selectedUserId = useReactiveVar(userIdVar);

  const [shouldSwitchUser, setShouldSwitchUser] =
    React.useState<boolean>(false);

  const switchUser = useCallback((userId?: number) => {
    if (userId === undefined) {
      localStorage.removeItem(LOCAL_STORAGE_KEY);
    } else {
      try {
        localStorage.setItem(LOCAL_STORAGE_KEY, userId.toString());
      } catch (e) {
        console.error(e);
      }
    }
    setShouldSwitchUser(false);
    userIdVar(userId);
  }, []);

  return (
    <SwitchUserContext.Provider
      value={{
        userId: selectedUserId,
        switchUser,
        shouldSwitchUser,
        setShouldSwitchUser,
      }}
    >
      {children}
    </SwitchUserContext.Provider>
  );
};
