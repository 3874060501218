import React from "react";
import styled from "styled-components";
import { TimeRange, useGetMyRecordQuery } from "~/generated/graphql";
import { Loading } from "~/components/atoms/Loading";
import { ReviewSummary } from "./partial/ReviewSummary";
import { RecordSummaryHeader } from "./partial/RecordSummaryHeader";
import { MiniLessonSummary } from "./partial/MiniLessonSummary";
import { useNavigate } from "react-router-dom";
import { Text } from "~/components/atoms/texts/Text";

type RecordSummaryProps = {
  user: {
    name: string;
    imageUrl: string;
  };
};

export const RecordSummary: React.FC<RecordSummaryProps> = ({ user }) => {
  const navigate = useNavigate();
  const { loading, data, error } = useGetMyRecordQuery({
    variables: { timeRange: TimeRange.All },
  });
  if (loading || !data) {
    return (
      <_LoadingWrapper>
        <Loading />
      </_LoadingWrapper>
    );
  }
  if (error) {
    return <>{error.message}</>;
  }

  return (
    <_Wrapper>
      <_RecordContainer>
        <RecordSummaryHeader user={user} />
        <ReviewSummary continueDay={data.me.general.continueReadLogDays} />
        <MiniLessonSummary
          miniLessonContinueDays={data.me.general.continueMiniLessonDays}
        />
      </_RecordContainer>
      <_RecordBookContainer onClick={() => navigate("/record_book/bookshelf")}>
        <Text fontSize="XXXS" fontColor="tex.t200" lineHeight="MD" bold>
          どくしょかの書
        </Text>
        <_RecordBookImg
          src="/img/record_book/record_book.png"
          alt="どくしょかの書"
        />
      </_RecordBookContainer>
    </_Wrapper>
  );
};

const _Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  column-gap: 12px;
`;

const _LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const _RecordContainer = styled.div`
  width: 100%;
  padding: 12px;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: ${({ theme }) => theme.colors.base.white};
  row-gap: 12px;
`;

const _RecordBookContainer = styled.div`
  max-width: 119px;
  border-radius: 12px;
  background: ${({ theme }) => theme.colors.base.white};
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
`;

const _RecordBookImg = styled.img`
  width: 100%;
  object-fit: contain;
`;
