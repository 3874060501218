import { gql } from "@apollo/client";

export const extendedSchema = gql`
  extend type TalkCache {
    """0: empty, 1: loading, 2: loaded"""
    state: Int!
    count: Int!
    digAll: Boolean!
    oldTalks: [Talk!]!
    unreadTalks: [Talk!]
  }

  extend type Query {
    messageCache: TalkCache!
  }
`;

export const extendedResolver = {};
