import React from "react";
import styled from "styled-components";
import { SubmitReadLogTemplateProps } from "./type";
import { NavContainer, Wrapper } from "../../Common";
import { PostReadLogBodyWrapper } from "../Common/style";
import { BackNavbar } from "~/components/molecules/navbars/BackNavbar";
import { Heading1 } from "~/components/atoms/texts/Heading1";
import { PrimaryButton } from "~/components/molecules/buttons/PrimaryButton";
import { Heading2 } from "~/components/atoms/texts/Heading2";
import { Text } from "~/components/atoms/texts/Text";
import { SmallTag } from "../../../atoms/indicators/SmallTag";
import { SecondaryFlatButton } from "~/components/molecules/buttons/SecondaryFlatButton";
import { difficultyChoices } from "../ConfirmDifficulty";
import { useLengthChoices } from "../ConfirmLength";
import { useLikeChoices } from "../ConfirmLike";
import { emotions } from "../ConfirmEmotions/type";
import { TranslateKeys, useTranslation } from "~/i18n";
import { ReadStatusForPostReadLog } from "~/generated/graphql";
import { Icon } from "~/components/atoms/Icon";
import { theme } from "~/styles/theme";
import { Loading } from "~/components/atoms/Loading";

export const SubmitReadLogTemplate: React.FC<SubmitReadLogTemplateProps> = ({
  handleBack,
  onClickGoNext,
  loading,
  nickname,
  readStatus,
  like,
  difficulty,
  length,
  emotionsObj,
  review,
  // favoriteScene,
  onClickReviewRewrite,
  // onClickFavoriteSceneRewrite,
}) => {
  const { t } = useTranslation();
  const lengthChoices = useLengthChoices(t);
  const likeChoices = useLikeChoices(t);
  const readStatusDictionary: Record<
    ReadStatusForPostReadLog,
    React.ReactNode
  > = React.useMemo(() => {
    return {
      [ReadStatusForPostReadLog.End]: (
        <SmallTag ja={t<TranslateKeys>("最後まで")} emoji="📘" color="pri" />
      ),
      [ReadStatusForPostReadLog.Reading]: (
        <SmallTag
          ja={t<TranslateKeys>("とちゅうまで")}
          emoji="🔖"
          color="pri"
        />
      ),
    };
  }, [t]);

  return (
    <>
      <NavContainer>
        <BackNavbar
          onClick={handleBack}
          titleName={""}
          background={"none"}
          arrowColor={"sec"}
        />
      </NavContainer>
      <Wrapper>
        <PostReadLogBodyWrapper>
          <HeadingCon>
            <AirplaneImg src="/img/read_log/paperAirplane.png" alt="紙飛行機" />
            <SubmitReadLogHeading fontSize={"LG"} lineHeight={"MD"} bold>
              {t<TranslateKeys>("感想を とどけて いいですか？")}
            </SubmitReadLogHeading>
          </HeadingCon>
          <BodyCon>
            <SubHeading fontSize={"SM"} lineHeight={"EQ"}>
              {nickname}さんの感想
            </SubHeading>
            <TableCon>
              <OneRow question={t<TranslateKeys>("どこまで読んだ？")}>
                {readStatusDictionary[readStatus]}
              </OneRow>
              <OneRow question={t<TranslateKeys>("難しかった？")}>
                <SmallTag
                  {...difficultyChoices[difficulty - 1]}
                  color={"pri"}
                />
              </OneRow>
              <OneRow question={t<TranslateKeys>("長さは？")}>
                <SmallTag {...lengthChoices[length - 1]} color={"pri"} />
              </OneRow>
              <OneRow question={t<TranslateKeys>("好きだった？")}>
                <SmallTag {...likeChoices[like - 1]} color={"pri"} />
              </OneRow>
              <OneRow question={t<TranslateKeys>("気持ち")}>
                {Object.keys(emotionsObj)
                  .filter(
                    (key) => emotionsObj[key] === true && key !== "yomikikase"
                  )
                  .map((key, index) => (
                    <SmallTag key={index} {...emotions[key]} color={"pri"} />
                  ))}
              </OneRow>
            </TableCon>
            {review && (
              <WhiteBox>
                <ContentsContainer>
                  <WhiteBoxTitle fontSize={"XS"} lineHeight={"EQ"}>
                    {t<TranslateKeys>("一言感想")}
                  </WhiteBoxTitle>
                  <ContentsText fontSize={"MD"} lineHeight={"MD"}>
                    {review}
                  </ContentsText>
                </ContentsContainer>
                <div style={{ width: "100px" }}>
                  <SecondaryFlatButton
                    onClick={onClickReviewRewrite}
                    disabled={false}
                    color={"pri"}
                    text={t<TranslateKeys>("書きなおす")}
                    fontSize={"XS"}
                    lineHeight={"EQ"}
                    bold
                  />
                </div>
              </WhiteBox>
            )}
            {/* {favoriteScene && (
              <WhiteBox>
                <ContentsContainer>
                  <WhiteBoxTitle fontSize={"XS"} lineHeight={"EQ"}>
                    お気に入りのシーン
                  </WhiteBoxTitle>
                  <ContentsText fontSize={"MD"} lineHeight={"MD"}>
                    {favoriteScene}
                  </ContentsText>
                </ContentsContainer>
                <div style={{ width: "100px" }}>
                  <SecondaryFlatButton
                    onClick={onClickFavoriteSceneRewrite}
                    disabled={false}
                    color={"pri"}
                    text={t<TranslateKeys>("書きなおす")}
                    fontSize={"XS"}
                    lineHeight={"EQ"}
                    bold
                  />
                </div>
              </WhiteBox>
            )} */}
          </BodyCon>
          <ButtonCon>
            {loading ? (
              <Loading />
            ) : (
              <PrimaryButton
                color={"green"}
                text={t<TranslateKeys>("とどける")}
                disabled={loading}
                onClick={onClickGoNext}
                fontSize={"XL"}
                lineHeight={"MD"}
                bold
              />
            )}
          </ButtonCon>
          {readStatus === ReadStatusForPostReadLog.Reading && (
            <AnnotationBox>
              <Icon
                name="infoCircle"
                style={{
                  color: theme.colors.tex.t200,
                }}
              />
              <AnnotationText fontSize={"XS"} lineHeight={"EQ"}>
                {t<TranslateKeys>(
                  "読み切っていない場合、おすすめの本リストからは削除されません"
                )}
              </AnnotationText>
            </AnnotationBox>
          )}
        </PostReadLogBodyWrapper>
      </Wrapper>
    </>
  );
};

const OneRow: React.FC<{ question: string; children: React.ReactNode }> = ({
  question,
  children,
}) => (
  <Row>
    <Col1>
      <Question fontSize={"SM"} lineHeight={"EQ"}>
        {question}
      </Question>
    </Col1>
    <Col2>{children}</Col2>
  </Row>
);

const AirplaneImg = styled.img`
  width: 143px;
  height: 93px;
`;

const HeadingCon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 28px;
  margin-bottom: ${({ theme }) => theme.size.XXL};
`;

const BodyCon = styled.div`
  margin: ${({ theme }) => theme.size.XXL} 0;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.size.M};
`;

const ButtonCon = styled.div`
  margin-top: ${({ theme }) => theme.size.XXL};
  display: flex;
  justify-content: center;
`;

const SubmitReadLogHeading = styled(Heading1)`
  color: ${({ theme }) => theme.colors.tex.t700};
  text-align: left;
`;

const SubHeading = styled(Heading2)`
  color: ${({ theme }) => theme.colors.tex.t700};
`;

const TableCon = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.size.XS};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Question = styled(Text)`
  color: ${({ theme }) => theme.colors.tex.t400};
`;

const Col1 = styled.div`
  width: 40%;
  margin-right: 2%;
`;

const Col2 = styled.div`
  width: 58%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.size.XS};
`;

const WhiteBox = styled.div`
  background-color: ${({ theme }) => theme.colors.base.white};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 10px;
  border-radius: ${({ theme }) => theme.deprecatedBorderRadius.SM};
`;

const WhiteBoxTitle = styled(Text)`
  color: ${({ theme }) => theme.colors.tex.t400};
  margin-bottom: ${({ theme }) => theme.size.XXS};
`;

const AnnotationBox = styled.div`
  background-color: ${({ theme }) => theme.colors.base.paleOrange};
  width: 100%;
  padding: ${({ theme }) => theme.size.S};
  border: 2px solid;
  border-color: ${({ theme }) => theme.colors.base.lightGray};
  border-radius: ${({ theme }) => theme.deprecatedBorderRadius.MD};
  margin-top: ${({ theme }) => theme.size.XS};
  margin-bottom: ${({ theme }) => theme.size.XXL};

  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;
`;

const AnnotationText = styled(Text)`
  color: ${({ theme }) => theme.colors.tex.t300};
  display: flex;
  align-items: flex-end;
`;

const ContentsContainer = styled.div`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.size.XS};
`;

const ContentsText = styled(Text)`
  color: ${({ theme }) => theme.colors.tex.t800};
  width: 100%;
`;
