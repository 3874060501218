import React from "react";
import { UserExperience, UserExperienceEvent } from "~/generated/graphql";
import { useAudio } from "~/utils/hooks/useAudio";

export type UpdatedUserExperience = {
  currentLevel: number;
  previousLevel: number;
  currentProgress: number;
  previousProgress: number;
  achieveExperienceEvents: UserExperienceEvent[];
  expToNextLevelUp: number;
  isMaxLevel: boolean;
};

export type UserExperienceSummary = UserExperience & {
  progress: number;
  isMaxLevel: boolean;
};

export const useExperiencePoint = (): {
  userExperience?: UserExperienceSummary;
  updatedUserExperience?: UpdatedUserExperience;
  updateExperience: (
    userExperience: UserExperience,
    achieveExperienceEvents: UserExperienceEvent[]
  ) => void;
  setCurrentUserExperienceSummary: (userExperience: UserExperience) => void;
} => {
  useAudio({ preload: ["drumRoll", "fanfare"] });
  const [userExperience, setUserExperience] = React.useState<
    UserExperienceSummary | undefined
  >(undefined);

  const [updatedUserExperience, setUpdatedUserExperience] = React.useState<
    UpdatedUserExperience | undefined
  >(undefined);

  const setCurrentUserExperienceSummary = React.useCallback(
    (userExperience: UserExperience) => {
      setUserExperience({
        userLevel: userExperience.userLevel,
        expAfterLastLevelUp: userExperience.expAfterLastLevelUp,
        expToNextLevelUp: userExperience.expToNextLevelUp,
        progress:
          (userExperience.expAfterLastLevelUp * 100) /
          (userExperience.expAfterLastLevelUp +
            userExperience.expToNextLevelUp),
        isMaxLevel: userExperience.expToNextLevelUp === 0,
      });
    },
    [setUserExperience]
  );

  const updateExperience = React.useCallback(
    (
      newUserExperience: UserExperience,
      achieveExperienceEvents: UserExperienceEvent[]
    ) => {
      setUpdatedUserExperience({
        currentLevel: newUserExperience.userLevel,
        currentProgress:
          (newUserExperience.expAfterLastLevelUp * 100) /
          (newUserExperience.expAfterLastLevelUp +
            newUserExperience.expToNextLevelUp),
        previousLevel: userExperience?.userLevel || 0,
        previousProgress:
          (userExperience &&
            (userExperience?.expAfterLastLevelUp * 100) /
              (userExperience?.expAfterLastLevelUp +
                userExperience?.expToNextLevelUp)) ||
          0,
        achieveExperienceEvents: achieveExperienceEvents,
        expToNextLevelUp: newUserExperience.expToNextLevelUp,
        isMaxLevel: newUserExperience.expToNextLevelUp === 0,
      });

      setUserExperience({
        userLevel: newUserExperience.userLevel,
        expAfterLastLevelUp: newUserExperience.expAfterLastLevelUp,
        expToNextLevelUp: newUserExperience.expToNextLevelUp,
        progress:
          (newUserExperience.expAfterLastLevelUp * 100) /
          (newUserExperience.expAfterLastLevelUp +
            newUserExperience.expToNextLevelUp),
        isMaxLevel: newUserExperience.expToNextLevelUp === 0,
      });
    },
    [setUpdatedUserExperience, setUserExperience, userExperience]
  );

  return {
    userExperience: userExperience,
    updatedUserExperience: updatedUserExperience,
    updateExperience: updateExperience,
    setCurrentUserExperienceSummary: setCurrentUserExperienceSummary,
  };
};
