export const INTERVAL_TO_SHOW_QUESTION = 100;

export const ANIMATION_DURATION_TIME = 800;

type MinilessonTimeSettings = {
  INTERVAL_TO_SHOW_QUESTION: number;
  ANIMATION_DURATION_TIME: number;
  TIME_TO_SHOW_SINGLE_CHARACTER: number;
  INTERVAL_AFTER_ALL_CHARACTER_SHOWN: number;
};

export const minilessonSpeedTypeList = [
  "verySlow",
  "slow",
  "default",
  "fast",
  "noAnimation",
] as const;
export type MinilessonSpeedType = (typeof minilessonSpeedTypeList)[number];

export const minilessonTimeSettings: Record<
  MinilessonSpeedType,
  MinilessonTimeSettings
> = {
  verySlow: {
    INTERVAL_TO_SHOW_QUESTION: 100,
    ANIMATION_DURATION_TIME: 800,
    TIME_TO_SHOW_SINGLE_CHARACTER: 200,
    INTERVAL_AFTER_ALL_CHARACTER_SHOWN: 800,
  },
  slow: {
    INTERVAL_TO_SHOW_QUESTION: 100,
    ANIMATION_DURATION_TIME: 800,
    TIME_TO_SHOW_SINGLE_CHARACTER: 150,
    INTERVAL_AFTER_ALL_CHARACTER_SHOWN: 600,
  },
  default: {
    INTERVAL_TO_SHOW_QUESTION: 100,
    ANIMATION_DURATION_TIME: 800,
    TIME_TO_SHOW_SINGLE_CHARACTER: 100,
    INTERVAL_AFTER_ALL_CHARACTER_SHOWN: 400,
  },
  fast: {
    INTERVAL_TO_SHOW_QUESTION: 100,
    ANIMATION_DURATION_TIME: 800,
    TIME_TO_SHOW_SINGLE_CHARACTER: 70,
    INTERVAL_AFTER_ALL_CHARACTER_SHOWN: 300,
  },
  noAnimation: {
    INTERVAL_TO_SHOW_QUESTION: 100,
    ANIMATION_DURATION_TIME: 400,
    TIME_TO_SHOW_SINGLE_CHARACTER: 0,
    INTERVAL_AFTER_ALL_CHARACTER_SHOWN: 1000,
  },
};

export const MinilessonFontSizeTypeList = [
  "small",
  "medium",
  "large",
  "xLarge",
] as const;

export type MinilessonFontSizeType =
  (typeof MinilessonFontSizeTypeList)[number];
