import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { RecommendOnTalkFragment } from "~/generated/graphql";
import { Heading2 } from "~/components/atoms/texts/Heading2";
import { Text } from "~/components/atoms/texts/Text";
import { AdminChatMessage } from "../../../AdminChatMessage";
import { LinkifyText } from "~/components/molecules/texts/LinkifyText";
import { YouTubeIframe } from "~/components/atoms/YouTubeIframe";
import { IMAGE_PATHS } from "~/helpers/constants/imagePath";

export type DeprecatedRecommendMessageProps = {
  item: RecommendOnTalkFragment;
  reasonLabel?: string;
  detailLinkLabel?: string;
  className?: string;
};

export const DeprecatedRecommendMessage: React.FC<
  DeprecatedRecommendMessageProps
> = ({
  item,
  className,
  reasonLabel = "せんしょ理由",
  detailLinkLabel = "くわしく見る",
}) => {
  const bookImageUrl = item.book.imageUrl
    ? item.book.imageUrl
    : IMAGE_PATHS.NO_IMAGE;

  return (
    <AdminChatMessage userType={"admin"} className={className}>
      <BookLink to={`recommend/${item.id}`}>
        <BookTitleContainer>
          <ContentTitle fontSize={"MD"} lineHeight={"LG"} bold>
            {item.book.title}
          </ContentTitle>
          <ContentSubTitle fontSize={"SM"} lineHeight={"LG"}>
            {item.book.author}
          </ContentSubTitle>
          <DetailLink fontSize={"SM"} lineHeight={"LG"}>
            {detailLinkLabel}
          </DetailLink>
        </BookTitleContainer>
        <BookCoverContainer>
          <BookCoverImage src={bookImageUrl} alt={item.book.title} />
        </BookCoverContainer>
      </BookLink>
      <ReasonContainer>
        <ReasonLabel fontSize={"XS"} lineHeight={"LG"}>
          {reasonLabel}
        </ReasonLabel>
        <ReasonBody fontSize={"small"} textType="plain">
          {item.recommendComment}
        </ReasonBody>
        {item.recommendComment.match(/youtu.be/) && (
          <YouTubeContainer>
            <YouTubeIframe
              url={
                "https://www.youtube.com/embed/" +
                item.recommendComment.substr(
                  item.recommendComment.indexOf("youtu.be") + 9,
                  11
                )
              }
            />
          </YouTubeContainer>
        )}
      </ReasonContainer>
    </AdminChatMessage>
  );
};

const BookLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
  text-decoration: none;
  border-radius: 15px;
`;

const ContentTitle = styled(Heading2)`
  color: ${({ theme }) => theme.colors.tex.t700};
`;

const ContentSubTitle = styled(Text)`
  color: ${({ theme }) => theme.colors.tex.t500};
`;

const ReasonBody = styled(LinkifyText)`
  color: ${({ theme }) => theme.colors.tex.t700};
`;

const ReasonLabel = styled(Text)`
  color: ${({ theme }) => theme.colors.tex.t200};
  margin-bottom: 5px;
`;

const BookCoverImage = styled.img`
  width: 100%;
  border-radius: ${({ theme }) => theme.deprecatedBorderRadius.SM};
`;

const BookTitleContainer = styled.div`
  width: 65%;
  margin-right: 5%;
`;

const BookCoverContainer = styled.div`
  width: 30%;
  max-width: 160px;
`;

const ReasonContainer = styled.div`
  width: 100%;
  padding-top: 15px;
  border-top: 2px solid ${({ theme }) => theme.colors.base.lightGray};
`;

const DetailLink = styled(Text)`
  color: ${({ theme }) => theme.colors.tex.t400};
  margin-top: 10px;
  &:hover {
    text-decoration: underline;
  }
`;

const YouTubeContainer = styled.div`
  position: relative;
  margin: 10px 0;
  width: 63vw;
  height: 35.43vw;
  max-width: 451px;
  max-height: 254px;
  overflow: hidden;
  & iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: none;
  }
`;
