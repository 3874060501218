import React from "react";
import styled from "styled-components";
import { Message } from "~/components/molecules/Message";
import { LibraryModalWrapper } from "../../ui/LibraryModalWrapper";
import { useTranslation } from "~/i18n";
import { YLEnqueteBackNavbar } from "../../ui/YLEnqueteBackNavbar";

export type ThanksForAnsweringProps = {
  onNext: () => void;
  nickname: string;
  onQuit: () => void;
};

export const ThanksForAnswering: React.FC<ThanksForAnsweringProps> = ({
  onNext,
  nickname,
  onQuit,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <YLEnqueteBackNavbar withBlur={true} onQuitButton={onQuit} />
      <LibraryModalWrapper
        isBright={true}
        showSensei={true}
        senseiImageType="NORMAL"
      >
        <_MessageWrapper>
          <Message
            onNext={onNext}
            message={t<TranslateKeys>(
              "答えてくれてありがとう！\nふむふむ……{{ nickname }}さんのキャラクタータイプは…？",
              { nickname }
            )}
            speaker={t<TranslateKeys>("ヨンデミー先生")}
          />
        </_MessageWrapper>
      </LibraryModalWrapper>
    </>
  );
};

const _MessageWrapper = styled.div`
  position: absolute;
  bottom: 15%;
  width: 100%;

  display: flex;
  justify-content: center;
`;
