import React, { useCallback, useEffect } from "react";
import { useState, MouseEvent } from "react";
import styled from "styled-components";
import { LargeButton } from "~/components/atoms/buttons/Button";
import { CarouselDots } from "~/components/atoms/CarouselDots";
import { BaseModal } from "~/components/atoms/wrappers/BaseModal";
import { useGetNewBadges } from "./hooks";
import { useAudio } from "~/utils/hooks/useAudio";
import { OldRecords, NewRecords } from "./hooks";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import Confetti from "react-confetti";

const PATH = "/img/badge/3x/";
const PNG = ".png";

export type GetBadgeModalProps = {
  myNewRecords: NewRecords;
  myOldRecords: OldRecords;
  onClose: () => void;
};

export const GetBadgeModal: React.FC<GetBadgeModalProps> = ({
  myNewRecords,
  myOldRecords,
  onClose,
}) => {
  const [badgeIndex, setBadgeIndex] = useState(0);
  const { play } = useAudio({ preload: ["fanfare"] });

  //新しく獲得したバッジと次に獲得するバッジの配列を取得
  const badges = useGetNewBadges(myOldRecords, myNewRecords);

  const badgeLength = badges.length;
  const hasBadges = badgeLength !== 0;
  const [isOpen, setIsOpen] = useState(hasBadges);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const { currentUser } = React.useContext(CurrentUserContext);
  const nickname = currentUser?.general.nickname;

  const closeModal = (): void => {
    setIsOpen(false);
    try {
      localStorage.removeItem("TemporarilyStoredReadingRecords");
    } catch (e) {
      console.error(e);
    }
    onClose();
  };

  const handleClickButton = useCallback(
    (event: MouseEvent): void => {
      event.preventDefault();
      if (badgeIndex < badgeLength - 1) {
        return setBadgeIndex(badgeIndex + 1);
      }
      closeModal();
    },
    [badgeIndex, badgeLength]
  );

  useEffect(() => {
    if (!hasBadges) {
      closeModal();
    } else {
      play("fanfare");
    }
  }, [hasBadges]);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const title =
    badgeLength === 1
      ? "バッジが手に入ったよ🎉"
      : `${badges.length}個のバッジが手に入ったよ🎉`;

  if (!hasBadges) {
    return <></>;
  } else {
    return (
      <>
        <BaseModal open={isOpen}>
          <SubHeading>{nickname}さん、おめでとう👏</SubHeading>
          <Heading>{title}</Heading>
          <Container>
            <CarouselCon>
              <BadgeTitle>{badges[badgeIndex].newBadge.label}</BadgeTitle>
              <ImgCon>
                <Img
                  src={`${PATH}${badges[badgeIndex].newBadge.imageFileName}${PNG}`}
                />
              </ImgCon>
              {badges[badgeIndex].nextBadge ? (
                <NextBadgeText>
                  次のバッジは{badges[badgeIndex].nextBadge?.value}だよ
                </NextBadgeText>
              ) : (
                <NextBadgeText>
                  次のバッジは……まだヒミツだよ！
                  <br />
                  お楽しみに！
                </NextBadgeText>
              )}
            </CarouselCon>
            {badges.length > 1 && (
              <CenteringCon>
                <CarouselDots
                  nowPageIndex={badgeIndex}
                  pageTotal={badges.length}
                />
              </CenteringCon>
            )}
          </Container>
          <CenteringCon>
            <LargeButton color="primary" onClick={handleClickButton}>
              つぎへ
            </LargeButton>
          </CenteringCon>
        </BaseModal>
        <Confetti width={windowSize.width} height={windowSize.height} />
      </>
    );
  }
};

const Heading = styled.h1`
  font-size: 20px;
  line-height: 160%;
  color: ${(props) => props.theme.colors.primitive.tex.t800};
  font-weight: bold;
`;

const SubHeading = styled.h2`
  font-size: 14px;
  line-height: 160%;
  color: ${(props) => props.theme.colors.primitive.tex.t500};
`;

const Container = styled.div`
  padding: 24px 0;
`;

const CenteringCon = styled.div`
  display: flex;
  justify-content: center;
`;

const BadgeTitle = styled.p`
  font-size: 16px;
  color: ${(props) => props.theme.colors.primitive.tex.t200};
  font-weight: bold;
`;

const ImgCon = styled.div`
  display: flex;
  justify-content: center;
  margin: 12px 0;
  align-items: flex-start;
`;

const Img = styled.img`
  width: 60%;
`;

const NextBadgeText = styled.p`
  font-size: 14px;
  text-align: center;
  color: ${(props) => props.theme.colors.primitive.tex.t300};
`;

const CarouselCon = styled.div`
  padding: 20px 0;
`;
