import React, { useState, FC } from "react";
import { useCallback } from "react";
import styled from "styled-components";
import { Text } from "~/components/atoms/texts/Text";
import { PrimaryButton } from "~/components/molecules/buttons/PrimaryButton";
import { SecondaryFlatButton } from "~/components/molecules/buttons/SecondaryFlatButton";
import { useTranslation } from "~/i18n";
import { StandUpImageType } from "~/helpers/constants/imagePath";
import { SelectNextBookImgList } from "../../List/SelectNextBookImgList";
import { SelectNextBookImgListWithoutOnClickProps } from "./types";
import { PromiseDate } from "~/generated/graphql";
import { TextButton } from "~/components/molecules/buttons/TextButton";

type TranslationParams = {
  user: string;
};

type Props = {
  selectNextBookImgListWithoutOnClickProps: SelectNextBookImgListWithoutOnClickProps;
  translationParams: TranslationParams;
  onMakePromise: (
    date: PromiseDate,
    chooseNext: boolean,
    nextISBN: string | undefined
  ) => Promise<boolean>;
  onExit: () => void;
  onChangeImage: (value: StandUpImageType) => void;
};

type Page =
  | "SelectBook"
  | "SelectDay"
  | "Promised"
  | "NoPromise"
  | "ConfirmPromise";

type NextBookSelection = "Unselected" | "Recommended" | "SelfSelected" | "Skip";

export const PromiseMessage: FC<Props> = ({
  selectNextBookImgListWithoutOnClickProps,
  translationParams,
  onMakePromise,
  onExit,
  onChangeImage,
}) => {
  const [page, setPage] = useState<Page>("SelectBook");
  const { t } = useTranslation();
  const [updating, setUpdating] = useState<boolean>(false);
  const [nextBookSelection, setNextBookSelection] =
    useState<NextBookSelection>("Unselected");
  const [chooseNext, setChooseNext] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [nextISBN, setNextISBN] = useState<string | undefined>(undefined);
  const [chooseToday, setChooseToday] = useState<boolean>(false);
  const [dueDateMessage, setDueDateMessage] = useState<TranslateKeys>("明日");
  const dateLabel: Record<PromiseDate, TranslateKeys> = {
    TOMORROW: "明日",
    DAY_AFTER_TOMORROW: "明後日",
    TWO_DAYS_AFTER_TOMORROW: "明々後日",
  };

  const handleClickBookButton = useCallback(
    (event: React.MouseEvent, isbn: string, title: string): void => {
      event.preventDefault();
      setNextBookSelection("Recommended");
      setChooseNext(true);
      setNextISBN(isbn);
      setTitle(title);
      setPage("SelectDay");

      onChangeImage("SMILE");
    },
    []
  );

  const handleClickOtherBookButton = useCallback(
    (event: React.MouseEvent): void => {
      event.preventDefault();
      setNextBookSelection("SelfSelected");
      setChooseNext(true);
      setNextISBN(undefined);
      setTitle("");
      setPage("SelectDay");

      onChangeImage("SMILE");
    },
    []
  );

  const handleClickSkip = useCallback((): void => {
    setNextBookSelection("Skip");
    setChooseNext(false);
    setNextISBN(undefined);
    setPage("SelectDay");
    setTitle("");

    onChangeImage("SMILE");
  }, []);

  return (
    <_Wrapper>
      {page === "SelectBook" && (
        <>
          <_MultilineText fontSize="MD" lineHeight="MD">
            {t<TranslateKeys>("次は どの 本を たすけてくれるかな？✨")}
          </_MultilineText>
          <_BookListAndPagenationWrapper>
            <SelectNextBookImgList
              {...selectNextBookImgListWithoutOnClickProps}
              onClick={handleClickBookButton}
              onClickOtherBooks={handleClickOtherBookButton}
            />
          </_BookListAndPagenationWrapper>
          <TextButtonWrapper>
            <TextButton
              bold={false}
              fontSize="MD"
              lineHeight="MD"
              onClick={handleClickSkip}
              text={t<TranslateKeys>("スキップする")}
            />
          </TextButtonWrapper>
        </>
      )}
      {page === "ConfirmPromise" && (
        <>
          <_MultilineText fontSize="MD" lineHeight="MD">
            {t<TranslateKeys>("本当に約束しなくてもいい？")}
          </_MultilineText>

          <PrimaryButton
            text={t<TranslateKeys>("やっぱり約束する💪")}
            fontSize="MD"
            lineHeight="MD"
            color="pri"
            onClick={() => setPage("SelectDay")}
          />
          <TextButtonWrapper>
            <TextButton
              bold={false}
              fontSize="MD"
              lineHeight="MD"
              onClick={() => setPage("NoPromise")}
              text={t<TranslateKeys>("今回はしない！")}
            />
          </TextButtonWrapper>
        </>
      )}

      {page === "SelectDay" && (
        <>
          <_MultilineText fontSize="MD" lineHeight="MD">
            {nextBookSelection === "Skip" &&
              t<TranslateKeys>(
                "次はいつ感想をとどけてくれるかな👀✨\n約束の時間を一緒に決めよう"
              )}
          </_MultilineText>
          <_MultilineText fontSize="MD" lineHeight="MD">
            {nextBookSelection === "SelfSelected" &&
              t<TranslateKeys>(
                "{{ user }}さんがどんな本を選ぶのか、楽しみです！\nいつ感想をとどけてくれるかな👀✨\n約束の時間を一緒に決めよう",
                translationParams
              )}
          </_MultilineText>
          <_MultilineText fontSize="MD" lineHeight="MD">
            {nextBookSelection === "Recommended" &&
              t<TranslateKeys>(
                "『{{ nextbook }}』を読むんだね！\nいつ感想をとどけてくれるかな👀✨\n約束の時間を一緒に決めよう",
                { nextbook: title }
              )}
          </_MultilineText>
          <_Buttons>
            <SecondaryFlatButton
              disabled={updating}
              color="pri"
              fontSize="MD"
              lineHeight="EQ"
              text={t<TranslateKeys>("今日はもっと読む")}
              onClick={() => {
                setUpdating(true);
                setChooseToday(true);
                setPage("Promised");
              }}
            />
            <SecondaryFlatButton
              disabled={updating}
              color="pri"
              fontSize="MD"
              lineHeight="EQ"
              text={t<TranslateKeys>(dateLabel[PromiseDate.Tomorrow])}
              onClick={() => {
                setUpdating(true);
                onMakePromise(PromiseDate.Tomorrow, chooseNext, nextISBN)
                  .then((res) => {
                    setDueDateMessage(dateLabel[PromiseDate.Tomorrow]);
                    if (res) {
                      setPage("Promised");
                    }
                  })
                  .finally(() => {
                    setUpdating(false);
                  });
              }}
            />
            <SecondaryFlatButton
              disabled={updating}
              color="pri"
              fontSize="MD"
              lineHeight="EQ"
              text={t<TranslateKeys>(dateLabel[PromiseDate.DayAfterTomorrow])}
              onClick={() => {
                setUpdating(true);
                onMakePromise(
                  PromiseDate.DayAfterTomorrow,
                  chooseNext,
                  nextISBN
                )
                  .then((res) => {
                    setDueDateMessage(dateLabel[PromiseDate.DayAfterTomorrow]);
                    if (res) {
                      setPage("Promised");
                    }
                  })
                  .finally(() => {
                    setUpdating(false);
                  });
              }}
            />
            <SecondaryFlatButton
              disabled={updating}
              color="pri"
              fontSize="MD"
              lineHeight="EQ"
              text={t<TranslateKeys>(
                dateLabel[PromiseDate.TwoDaysAfterTomorrow]
              )}
              onClick={() => {
                setUpdating(true);
                onMakePromise(
                  PromiseDate.TwoDaysAfterTomorrow,
                  chooseNext,
                  nextISBN
                )
                  .then((res) => {
                    setDueDateMessage(
                      dateLabel[PromiseDate.TwoDaysAfterTomorrow]
                    );
                    if (res) {
                      setPage("Promised");
                    }
                  })
                  .finally(() => {
                    setUpdating(false);
                  });
              }}
            />
          </_Buttons>
          <TextButtonWrapper>
            <TextButton
              bold={false}
              fontSize="MD"
              lineHeight="MD"
              onClick={() => {
                onChangeImage("CRY");
                setPage("ConfirmPromise");
              }}
              text={t<TranslateKeys>("スキップする")}
            />
          </TextButtonWrapper>
        </>
      )}
      {page === "Promised" && (
        <>
          <_MultilineText fontSize="MD" lineHeight="MD">
            {chooseToday === true
              ? t<TranslateKeys>(
                  "なんと！\n読書家パワー全開だね!\n感想を楽しみに待ってるよ👀✨"
                )
              : t<TranslateKeys>(dueDateMessage) +
                t<TranslateKeys>(
                  "も感想を届けてくれるんだね\n\nヨンデミー先生、楽しみにしているね😊\nまたね〜👋"
                )}
          </_MultilineText>

          <PrimaryButton
            text={"バイバイ！"}
            fontSize="MD"
            lineHeight="MD"
            color="pri"
            onClick={() => onExit()}
          />
        </>
      )}
      {page === "NoPromise" && (
        <>
          <_MultilineText fontSize="MD" lineHeight="MD">
            {t<TranslateKeys>(
              "わかった！\n教えてくれて ありがとう😊\n\n{{ user }}さんの次の感想も 楽しみにしてるよ📚✨",
              translationParams
            )}
          </_MultilineText>
          <PrimaryButton
            text={"バイバイ！"}
            fontSize="MD"
            lineHeight="MD"
            color="pri"
            onClick={() => onExit()}
          />
        </>
      )}
    </_Wrapper>
  );
};

const _Buttons = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

const _Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

const _MultilineText = styled(Text)`
  white-space: pre-wrap;
`;

const TextButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  & button {
    color: ${({ theme }) => theme.colors.primitive.tex.t400};
  }
`;

const _BookListAndPagenationWrapper = styled.div`
  width: 100%;
  column-gap: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
