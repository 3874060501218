import React, { useState, VFC } from "react";
import styled from "styled-components";
import { Text } from "~/components/atoms/texts/Text";
import { Icon } from "~/components/atoms/Icon";
import {
  CloseableDialog,
  CloseableDialogProps,
} from "~/components/molecules/modals/CloseableDialog";
import { BookshelfCurrentRecommendFragment } from "~/generated/graphql";
import { PrimaryButton } from "~/components/molecules/buttons/PrimaryButton";
import { IconTextButton } from "~/components/molecules/buttons/IconTextButton";
import { ColorTheme } from "~/styles/design_token/color";
import { IMAGE_PATHS } from "~/helpers/constants/imagePath";
import { modalStyle } from "~/components/atoms/wrappers/modal";
modalStyle;
import { LargeButton } from "~/components/atoms/buttons/Button";

type Recommend = BookshelfCurrentRecommendFragment;

export type NewRecommendItemModalProps = {
  /**
   * 新しくおすすめに入ったもの
   */
  recommends: Recommend[];
  /**
   * モーダルを閉じるときに発火する関数
   */
  onClose: (event: React.MouseEvent) => void;
  /**
   * モーダルを閉じるときに発火する関数
   */
  onCancel: (event: React.MouseEvent) => void;
  /**
   * あとで見るボタンを表示するかどうかの制御
   */
  showCancelButton?: boolean;
} & Pick<CloseableDialogProps, "isOpen">;

export const NewRecommendItemModal: VFC<NewRecommendItemModalProps> = ({
  isOpen,
  onClose,
  onCancel,
  recommends,
  showCancelButton = true,
}) => {
  const [displayRecommendIndex, setDisplayRecommendIndex] = useState<number>(0);
  const colors: {
    ribbon: ColorTheme;
    text: ColorTheme;
    button: ColorTheme;
  }[] = [
    {
      ribbon: "red",
      text: "red",
      button: "pri",
    },
    {
      ribbon: "green",
      text: "sec",
      button: "sec",
    },
    {
      ribbon: "pri",
      text: "sky",
      button: "sky",
    },
  ];
  const color = colors[displayRecommendIndex % colors.length];
  const recommend = recommends[displayRecommendIndex];
  const isFirst = displayRecommendIndex === 0;
  const isLast = displayRecommendIndex === recommends.length - 1;

  const onNext = (event) => {
    if (displayRecommendIndex === recommends.length - 1) {
      setDisplayRecommendIndex(0);
      onClose(event);
      return;
    }
    setDisplayRecommendIndex((prev) => prev + 1);
  };

  const buttonLabel = isLast ? "次へすすむ" : "次の本へ";

  return (
    <CloseableDialog
      isOpen={isOpen && recommends.length > 0}
      onClose={onCancel}
      width="320px"
    >
      <_Wrapper>
        <ModalContentWrapper>
          <RibbonWrapper>
            <RibbonContent
              imageUrl={recommend?.book?.imageUrl || IMAGE_PATHS.NO_IMAGE}
            >
              <Ribbon color={color.ribbon} />
            </RibbonContent>
          </RibbonWrapper>
          <ImageHeaderWrapper>
            <HeaderImage
              imageUrl={recommend?.book?.imageUrl || IMAGE_PATHS.NO_IMAGE}
            >
              <Image />
            </HeaderImage>
          </ImageHeaderWrapper>

          <ModalContent>
            <Title>
              <ColoredText fontSize="XXL" lineHeight="MD" bold color="pri">
                {displayRecommendIndex + 1}
              </ColoredText>
              <ColoredText fontSize="SM" lineHeight="MD" color="tex">
                さつ目/{recommends.length}
              </ColoredText>
            </Title>
            <TextContent>
              <ColoredText
                fontSize="MD"
                lineHeight="MD"
                bold
                color="gray"
                whiteSpace="pre-wrap"
              >
                {recommend.recommendComment}
              </ColoredText>
              <TextContentFooter>
                <Text fontSize="SM" lineHeight="MD">
                  ヨンデミー先生より
                </Text>
              </TextContentFooter>
              <Icon
                name="ribbon"
                color={color.ribbon}
                style={{
                  marginTop: "12px",
                }}
              />
            </TextContent>

            <Footer>
              <PrimaryButton
                text={buttonLabel}
                color={color.button}
                fontSize="XL"
                lineHeight="MD"
                bold
                onClick={onNext}
              />
              <FooterGrayButton>
                {isFirst ? (
                  showCancelButton && (
                    <LargeButton
                      color="tertiary"
                      variant="blank"
                      disabled={false}
                      onClick={onCancel}
                    >
                      <Text
                        lineHeight="EQ"
                        fontSize="MD"
                        bold={true}
                        fontColor="tex.t500"
                      >
                        {"あとで見る"}
                      </Text>
                    </LargeButton>
                  )
                ) : (
                  <IconTextButton
                    iconPlace="pre"
                    name="circleArrowLeft"
                    text="前にもどる"
                    color="gray"
                    fontSize="SM"
                    lineHeight="MD"
                    bold
                    onClick={() => setDisplayRecommendIndex((prev) => prev - 1)}
                  />
                )}
              </FooterGrayButton>
            </Footer>
          </ModalContent>
        </ModalContentWrapper>
      </_Wrapper>
    </CloseableDialog>
  );
};

const _Wrapper = styled.div`
  max-height: 100svh;
  padding: 40px 0;
  overflow-y: scroll;
`;

const ModalContentWrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.base.white};
  border-radius: 16px 16px 16px 16px;
`;

const RibbonWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;

const ImageHeaderWrapper = styled.div`
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  width: calc(100% + 4px);
  background-repeat: no-repeat;
  overflow: hidden;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
`;

const HeaderImage = styled.div<{ imageUrl: string }>`
  background-image: url(${(props) => props.imageUrl});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 160px;
  z-index: 0;

  &:before {
    position: absolute;
    top: -5px;
    left: -5px;
    bottom: -5px;
    right: -5px;
    background: inherit;
    filter: blur(5px) brightness(85%);
    content: "";
    z-index: -1;
    background-repeat: repeat;
  }
`;

const Image = styled.div`
  padding: 4rem;
  background-size: contain;
  border-radius: 5px;
  background: inherit;
`;

const RibbonContent = styled.div<{ imageUrl: string }>`
  background-image: url(${(props) => props.imageUrl});
  background-repeat: no-repeat;
  overflow: hidden;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
`;

const Ribbon = styled.div<{ color: ColorTheme }>`
  position: relative;
  height: 160px;
  overflow: hidden;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-image: linear-gradient(
    225deg,
    transparent 0 30px,
    ${({ theme, color }) => {
      const size = "30px 45px";
      switch (color) {
        case "red":
          return `${theme.colors.red.r400} ${size}`;
        case "green":
          return `${theme.colors.green.g400} ${size}`;
        case "pri":
          return `${theme.colors.pri.p400} ${size}`;
        default:
          return `${theme.colors.tex.t700} ${size}`;
      }
    }},
    transparent 40px
  );
  z-index: 10;
`;

const ModalContent = styled.div`
  width: 100%;
  background-image: linear-gradient(
      0deg,
      transparent calc(100% - 1px),
      #f0f0f0 calc(100% - 1px)
    ),
    linear-gradient(
      90deg,
      transparent calc(100% - 1px),
      #f0f0f0 calc(100% - 1px)
    );
  background-size: 25px 25px;
  background-repeat: repeat;
  background-position: center center;

  padding: 161px 20px 0 20px;
`;

const TextContentFooter = styled.div`
  text-align: center;
  margin-top: 12px;
`;

const TextContent = styled.div`
  border-radius: 12px;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.base.white};
  position: relative;
  padding: 16px 12px;
  border: 3px solid ${({ theme }) => theme.colors.base.gray};
  margin-bottom: 16px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const FooterGrayButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: 24px;
`;

// TODO: Textコンポーネント内部でColorの指定をできるようにする
const ColoredText = styled(Text)<{
  color: ColorTheme;
}>`
  color: ${({ theme, color }) => {
    switch (color) {
      case "gray":
        return theme.colors.tex.t500;
      case "red":
        return theme.colors.red.r400;
      case "sec":
        return theme.colors.sec.s400;
      case "sky":
        return theme.colors.sky.s600;
      case "pri":
        return theme.colors.pri.p400;
      default:
        return theme.colors.tex.t700;
    }
  }};
`;

const Title = styled.div`
  display: flex;
  align-items: baseline;
  margin: 16px 0;
`;
