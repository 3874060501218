import React, { useMemo, useState } from "react";
import { useGetLastBookSelectReactionQuery } from "~/generated/graphql";
import { NewRecommendOpeningModal } from "~/components/organisms/modals/NewRecommendOpeningModal";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";

export const useNewRecommendOpeningModal = (props: {
  isOpen: boolean
  onClose: () => void;
}): React.ReactNode => {
  const { data } = useGetLastBookSelectReactionQuery();
  const { currentUser } = React.useContext(CurrentUserContext);
  const nickname = currentUser?.general.nickname || "";
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  // dataが取得できなかった場合はデフォルトを表示
  const modal = useMemo(() => <NewRecommendOpeningModal
  isOpen={props.isOpen}
  onClose={props.onClose}
  isFirstRecommend={data?data.getLastBookSelectReaction.hasNoPreviousBookSelect:false}
  readBookRecommends={data?data.getLastBookSelectReaction.readLastBookSelectRecommends:[]}
  nickname={nickname}
  currentIndex={currentIndex}
  setCurrentIndex={setCurrentIndex}
  />, [props, data]);

  return modal;
};
