import React from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { Select } from "~/components/atoms/forms/Select";
import { Slider } from "~/components/atoms/forms/Slider";
import { Text } from "~/components/atoms/texts/Text";
import { BaseModal } from "~/components/atoms/wrappers/modal";
import { KanziLevel } from "~/generated/graphql";
import { convertToWord } from "~/helpers/constants/kanziLevel";
import { useTranslation } from "~/i18n";
import { usePreference } from "~/store/preference/usePreference";
import { theme } from "~/styles/theme";
import { MiniLessonContext } from "~/contexts/MiniLessonContext";

export type MinilessonSettingModalProps = {
  isOpen: boolean;
  onClose: () => void;
  kanziLevel: KanziLevel;
  handleUpdateKanziLevel: (kanziLevel: KanziLevel) => Promise<boolean>;
};

export const MinilessonSettingModal: React.FC<MinilessonSettingModalProps> = ({
  isOpen,
  onClose,
  kanziLevel,
  handleUpdateKanziLevel,
}) => {
  const { savePreference, preference } = usePreference();
  const { t } = useTranslation();
  const { updateFontSize } = React.useContext(MiniLessonContext);

  console.log("fontsize:", preference?.minilessonFontSize);

  return (
    <BaseModal isOpen={isOpen} onRequestClose={onClose} style={modalStyle}>
      <_Wrapper>
        <_KanziLevelContainer>
          <Text fontSize="XS" lineHeight="EQ" color="tex.t400">
            漢字レベル
          </Text>
          <div style={{ width: "100%" }}>
            <Select
              onChange={(event) =>
                handleUpdateKanziLevel(event.target.value as KanziLevel)
              }
              defaultValue={kanziLevel}
              style={{ width: "100%" }}
            >
              {Object.values(KanziLevel).map((kanziLevel) => (
                <option key={kanziLevel} value={kanziLevel}>
                  {t<TranslateKeys>(convertToWord(kanziLevel))}
                </option>
              ))}
            </Select>
          </div>
        </_KanziLevelContainer>
        <_AnimationContainer>
          <Text fontSize="XS" lineHeight="EQ" color="tex.t400">
            文字アニメーション
          </Text>
          <div style={{ width: "100%", padding: "0 32px" }}>
            <Slider
              value={
                typeof preference?.minilessonSpeedType === "number"
                  ? preference.minilessonSpeedType
                  : 2
              }
              type="range"
              max={4}
              min={0}
              step={1}
              onChange={(event) => {
                const v = parseInt(event.target.value);
                savePreference({ minilessonSpeedType: v });
              }}
            />
          </div>

          <_AnimationTextWrapper>
            <_Text fontSize="XXS" lineHeight="EQ" color="tex.t400">
              とても
              <wbr />
              ゆっくり
            </_Text>
            <_Text fontSize="XXS" lineHeight="EQ" color="tex.t400">
              ゆっくり
            </_Text>
            <_Text fontSize="XXS" lineHeight="EQ" color="tex.t400">
              ふつう
            </_Text>
            <_Text fontSize="XXS" lineHeight="EQ" color="tex.t400">
              はやい
            </_Text>
            <_Text fontSize="XXS" lineHeight="EQ" color="tex.t400">
              いっきに
            </_Text>
          </_AnimationTextWrapper>
        </_AnimationContainer>
        <_FontsizeContainer>
          <Text fontSize="XS" lineHeight="EQ" color="tex.t400">
            文字サイズ
          </Text>
          <div style={{ width: "100%", padding: "0 32px" }}>
            <Slider
              value={
                typeof preference?.minilessonFontSize === "number"
                  ? preference.minilessonFontSize
                  : 1
              }
              type="range"
              max={3}
              min={0}
              step={1}
              onChange={(event) => {
                const v = parseInt(event.target.value);
                updateFontSize(v);
              }}
            />
          </div>
          <_FontSizeTextWrapper>
            <_Text fontSize="XXS" lineHeight="EQ" color="tex.t400">
              ちいさい
            </_Text>
            <_Text fontSize="XXS" lineHeight="EQ" color="tex.t400">
              ふつう
            </_Text>
            <_Text fontSize="XXS" lineHeight="EQ" color="tex.t400">
              おおきい
            </_Text>
            <_Text fontSize="XXS" lineHeight="EQ" color="tex.t400">
              とても
              <wbr />
              おおきい
            </_Text>
          </_FontSizeTextWrapper>
        </_FontsizeContainer>
      </_Wrapper>
    </BaseModal>
  );
};

const modalStyle: Modal.Styles = {
  overlay: {
    backgroundColor: theme.colors.base.overlay,
    zIndex: theme.zIndex.modal,
  },
  content: {
    top: "auto",
    left: "0",
    right: "0",
    bottom: "0",
    border: "none",
    background: "none",
    width: "100vw",
    padding: "0",
    zIndex: theme.zIndex.blackBack,
  },
};

const _Wrapper = styled.div`
  min-height: 100px;
  padding: 16px 36px;
  animation: slideUp 0.5s ease-out forwards;
  background-color: ${({ theme }) => theme.colors.base.background};
  border-top: 1px solid ${({ theme }) => theme.colors.base.gray};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  @keyframes slideUp {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
`;

const _KanziLevelContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  justify-content: center;
  align-items: flex-start;
`;

const _AnimationContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  justify-content: center;
  align-items: flex-start;
`;

const _FontsizeContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  justify-content: center;
  align-items: flex-start;
`;

const _AnimationTextWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min-content, 20%));
`;

const _FontSizeTextWrapper = styled.div`
  width: 110%;
  display: grid;
  transform: translateX(-5%);
  grid-template-columns: repeat(auto-fill, minmax(min-content, 25%));
`;

const _Text = styled(Text)`
  word-break: keep-all;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  text-align: center;
`;
