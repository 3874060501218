import React from "react";
import { LinkifyText } from "../LinkifyText";
import styled from "styled-components";
import { AdminMessageTextProps } from "~/components/organisms/adminMessage/AdminMessageText";

export const BoldifyText: React.FC<{
  text: string;
  fontSize: "small" | "medium" | "large" | "xLarge";
  textType: AdminMessageTextProps["textType"];
}> = ({ text, fontSize, textType }) => {
  if (text.includes("**")) {
    const splitText = text.split("**");
    return (
      <>
        {splitText.map((t, i) => {
          if (i % 2 === 0) {
            return (
              <_LinkifyText fontSize={fontSize} textType={textType} key={i}>
                {t}
              </_LinkifyText>
            );
          } else {
            return (
              <_BoldLinkifyText fontSize={fontSize} textType={textType} key={i}>
                {t}
              </_BoldLinkifyText>
            );
          }
        })}
      </>
    );
  }
  return (
    <LinkifyText fontSize={fontSize} textType={textType}>
      {text}
    </LinkifyText>
  );
};

const _BoldLinkifyText = styled(LinkifyText)`
  font-weight: 700;
  display: inline;
`;

const _LinkifyText = styled(LinkifyText)`
  display: inline;
`;
