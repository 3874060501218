import { useCallback, useEffect, useMemo } from "react";
import Snd from "snd-lib";
import { KitKinds } from "snd-lib/dist/constant";
import { SoundKeys } from "snd-lib/dist/types";
import { usePreference } from "~/store/preference/usePreference";

const snd = new Snd();
type SoundKey = keyof SoundKeys;

type LoadingStatus = "loading" | "loaded";
const loadingStatus: Record<string, LoadingStatus> = {};

type PlayOptions = {
  volume?: number;
  forcePlay?: boolean;
};

export const modifyVolume = (volume: number): number =>
  (100 - (10 - volume) ** 2) / 100;

export const useSnd = (
  kit: KitKinds = Snd.KITS.SND01
): {
  play: (key: SoundKey, options?: PlayOptions) => void;
  setVolume: (volume: number) => void;
  volume: number;
  muted: boolean;
  setMuted: (muted: boolean) => void;
} => {
  const { preference, savePreference } = usePreference();

  const [volume, muted] = useMemo(() => {
    return [
      preference?.sndVolume === undefined ? 5 : preference.sndVolume,
      Boolean(preference?.sndMuted),
    ];
  }, [preference]);

  useEffect(() => {
    if (loadingStatus[kit]) {
      return;
    }

    loadingStatus[kit] = "loading";
    snd.load(kit).then(() => {
      loadingStatus[kit] = "loaded";
    });
  }, [kit]);

  const play = useCallback(
    (key: SoundKey, options = {}) => {
      const _options: PlayOptions = {
        forcePlay: false,
        ...options,
      };

      const _v = _options.volume === undefined ? volume : _options.volume;

      if ((muted || _v === 0) && !_options.forcePlay) {
        return;
      }

      try {
        snd.play(Snd.SOUNDS[key], { volume: modifyVolume(_v) });
      } catch (err) {
        console.error(err);
        return;
      }
    },
    [preference, volume, muted]
  );

  const setVolume = useCallback(
    (volume: number) => {
      let _volume = volume;
      if (_volume > 10) {
        _volume = 10;
      } else if (_volume < 0) {
        _volume = 0;
      }
      savePreference({ sndVolume: _volume });
    },
    [savePreference]
  );

  const setMuted = useCallback(
    (_muted: boolean) => {
      savePreference({ sndMuted: _muted });
    },
    [savePreference]
  );

  return { play, setVolume, volume, muted, setMuted };
};
