import React from "react";

export type YouTubeIframeProps = {
  /*
  * YouTubeのURL
  */
  url: string
  /*
  * class
  */
  className?: string
}

export const YouTubeIframe: React.VFC<YouTubeIframeProps> = ({ url, className }) => {
  return (
    <iframe
      className={className}
      src={url}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  );
};
