import styled, { css } from "styled-components";
import { MarginProps, marginStyle } from "~/styles/design_token/margin";

type RowGap =
  | "0px"
  | "2px"
  | "4px"
  | "8px"
  | "10px"
  | "12px"
  | "16px"
  | "20px"
  | "24px"
  | "32px"
  | "40px"
  | "48px";

type StackProps = {
  rowGap?: RowGap;
  alignItems?: "center" | "flex-start" | "flex-end";
} & MarginProps;

export const Stack = styled.div<StackProps>`
  display: flex;
  flex-direction: column;
  row-gap: ${({ rowGap = "8px" }) => rowGap};
  ${marginStyle}
  ${({ alignItems }) =>
    alignItems &&
    css`
      align-items: ${alignItems || "center"};
    `};
`;
