import React, { FC, useContext } from "react";
import { Text } from "~/components/atoms/texts/Text";
import { BaseTutorialModal } from "~/components/molecules/modals/BaseTutorialModal";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import { useTranslation } from "~/i18n";
import { unlockLevel } from "~/store/unlock/useUnlock";

export type StartTutorialProps = {
  setIsOpen: (isOpen: boolean) => void;
  onNext: () => void;
};

export const StartTutorial: FC<StartTutorialProps> = ({
  setIsOpen,
  onNext,
}) => {
  const { userExperience } = useContext(CurrentUserContext);
  const userLevel = userExperience?.userLevel || 0;
  const { t } = useTranslation();

  return (
    <>
      <BaseTutorialModal
        isOpen={true}
        buttons={[
          { text: t<TranslateKeys>("本の友にいく！"), onClick: onNext },
        ]}
        skipText="スキップ"
        isSkipCenter={true}
        onClose={() => setIsOpen(false)}
        imgSrc={"/img/sensei/standup_4.svg"}
      >
        <Text fontSize={"MD"} lineHeight={"MD"} fontColor="semantic.text.body">
          {userLevel === unlockLevel.bookFriend ? (
            <>
              そうだ！
              <br />
              {t<TranslateKeys>(
                "新しく 使えるようになった「本の友」に 行ってみる？"
              )}
            </>
          ) : (
            <>
              {t<TranslateKeys>(
                "なにやら 本の虫が 本の友で 感想を 出しているみたい……"
              )}
              <br />
              {t<TranslateKeys>("本の友に 一緒に 行ってみよう！")}
            </>
          )}
        </Text>
      </BaseTutorialModal>
    </>
  );
};
