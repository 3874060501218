import React from "react";
import { CommonStyle } from "../../Common";
import { Text } from "~/components/atoms/texts/Text";
import { TranslateKeys, useTranslation } from "~/i18n";
import styled from "styled-components";
export type PromisedBookTipsProps = {
  bookTitle: string;
  onClick: () => void;
};

export const PromisedBookTips: React.FC<PromisedBookTipsProps> = ({
  bookTitle,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <CommonStyle
      loginTipsButtonProps={{
        text: t<TranslateKeys>("読む💪"),
        onClick: onClick,
      }}
    >
      {
        <Text fontColor="tex.t700" fontSize="MD" lineHeight="MD">
          {t<TranslateKeys>("今日は『")}
          <_OrangeSpan>{bookTitle}</_OrangeSpan>
          {t<TranslateKeys>("』を 読むって 言っていたね😊")}

          <br />
          {t<TranslateKeys>("感想を 楽しみに 待ってるよ🙌")}
        </Text>
      }
    </CommonStyle>
  );
};


const _OrangeSpan = styled.span`
  font-weight: bold;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.semantic.primary.main};
`;
