import React from "react";
import { LoadingSection } from "~/components/templates/Loading/LoadingSection";
import { BadgeListRecordTemplate } from "~/components/templates/RecordBook/BadgeList";
import { TimeRange, useGetMyRecordQuery } from "~/generated/graphql";

export const BadgeListRecord: React.FC = () => {
  const { loading, error, data } = useGetMyRecordQuery({
    variables: { timeRange: TimeRange.All },
  });

  if (loading || !data) return <LoadingSection />;
  if (error) return <>error!{error.message}</>;
  return <BadgeListRecordTemplate {...data.me.general} />;
};
