import React from "react";
import { useNavigate } from "react-router-dom";
import { LoadingPage } from "~/components/templates/Loading/LoadingPage";
import { SnsSharingTemplate } from "~/components/templates/SnsSharing";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import { useGetDoneRecommendQuery } from "~/generated/graphql";

export type FetchType = "All" | "Latest";

const fetchNumberList: Record<FetchType, number> = {
  All: 10000,
  Latest: 9,
};

export const SnsSharingImage: React.FC = () => {
  const [fetchType, setFetchType] = React.useState<FetchType>("Latest");
  const { data, loading, error, refetch } = useGetDoneRecommendQuery({
    variables: {
      begin: 0,
      end: fetchNumberList.Latest,
    },
    fetchPolicy: "network-only",
  });

  const { currentUser } = React.useContext(CurrentUserContext);

  const navigate = useNavigate();

  const handleChangeFetchType = React.useCallback(
    (fetchType: FetchType) => {
      setFetchType(fetchType);
      refetch({
        begin: 0,
        end: fetchNumberList[fetchType],
      });
    },
    [refetch]
  );

  if (loading || !data) return <LoadingPage />;
  if (error) return <>Error! {error.message}</>;
  return (
    <SnsSharingTemplate
      imgSrcList={
        data
          ? data.getDoneRecommends.get
              .map((recommend) => {
                return recommend.book.imageUrl ? recommend.book.imageUrl : "";
              })
              .filter((v) => v)
          : []
      }
      username={currentUser?.general.nickname}
      userId={currentUser?.general.id}
      handleBack={() => navigate(-1)}
      fetchType={fetchType}
      handleChangeFetchType={handleChangeFetchType}
    />
  );
};
