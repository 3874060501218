import React, { useContext } from "react";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import { UnlockBookFriendModal } from "~/components/organisms/Unlock/UnlockBookFriendModal";
import { UnlockWantReadBookModal } from "~/components/organisms/Unlock/UnlockWantReadBookModal";
import { UnlockEnterFreeReviewModal } from "~/components/organisms/Unlock/UnlockEnterFreeReviewModal";
import { UpdatedUserExperience } from "~/store/experiencePoint/useExperiencePoint";
import {
  lockedFeatureArr,
  LockedFeatures,
  unlockLevel,
} from "~/store/unlock/useUnlock";
import { UnlockBfStarModal } from "~/components/organisms/Unlock/UnlockBfStarModal";

const isUnlockedFeature = (
  updateUserExperience: UpdatedUserExperience
): LockedFeatures | undefined => {
  const unlockedFeature = lockedFeatureArr.find((feature) => {
    const isUnlocked =
      updateUserExperience.currentLevel >= unlockLevel[feature] &&
      unlockLevel[feature] > updateUserExperience.previousLevel;
    return isUnlocked;
  });

  return unlockedFeature;
};

const assertCase = (_: never) => {
  return;
};

type UnlockModalControllerProps = {
  handleNext: () => void;
};

export const UnlockModalController: React.FC<UnlockModalControllerProps> = ({
  handleNext,
}) => {
  const { updatedUserExperience } = useContext(CurrentUserContext);
  const { currentUser } = React.useContext(CurrentUserContext);

  if (!currentUser) {
    return null;
  }

  const nickname = currentUser.general.nickname;

  const unlockedFeature: LockedFeatures | undefined = updatedUserExperience
    ? isUnlockedFeature(updatedUserExperience)
    : undefined;

  switch (unlockedFeature) {
    case "enterFreeReview": {
      return (
        <UnlockEnterFreeReviewModal onClose={handleNext} nickname={nickname} />
      );
    }
    case "bookFriend": {
      return <UnlockBookFriendModal onClose={handleNext} nickname={nickname} />;
    }
    case "bfStar": {
      return <UnlockBfStarModal onClose={handleNext} nickname={nickname} />;
    }
    case "wantReadBook": {
      return (
        <UnlockWantReadBookModal onClose={handleNext} nickname={nickname} />
      );
    }
    case undefined: {
      handleNext();
      return null;
    }
    default: {
      assertCase(unlockedFeature);
      return null;
    }
  }
};
