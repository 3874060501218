import React, { FC, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useAudio } from "./useAudio";
import {
  completeStoryByMissionOrder,
  MissionStorySetting,
  startStoryByMissionOrder,
} from "./MissionStories/type";
import { MissionOrder } from "~/generated/graphql";
import { Page, PreviousPage } from "./page";
import { LoadingPage } from "~/components/templates/Loading/LoadingPage";
import { SkipStoryModal } from "./SkipStoryModal";

export const STORY_PAGE_SESSION_STORAGE_KEY = "STORY_PAGE_SESSION_STORAGE_KEY";

export type StoryType = "Start" | "Complete" | "All";

export type MissionStoryProps = {
  missionOrder: MissionOrder;
  storyType: StoryType;
  onCompleted: () => void;
  startPage?: number;
};

export const MissionStory: FC<MissionStoryProps> = ({
  missionOrder,
  storyType,
  onCompleted,
  startPage = 0,
}) => {
  const [loaded, setLoaded] = useState<Record<string, boolean>>({});
  const [page, setPage] = useState<number>(startPage);
  const [closed, setClosed] = useState<boolean>(false);
  const [isShowSkipStoryModal, setIsShowSkipStoryModal] =
    useState<boolean>(false);

  const missionStories: MissionStorySetting[] = React.useMemo(() => {
    switch (storyType) {
      case "Start":
        return startStoryByMissionOrder[missionOrder];
      case "Complete":
        return completeStoryByMissionOrder[missionOrder];
      case "All":
        return [
          ...startStoryByMissionOrder[missionOrder],
          ...completeStoryByMissionOrder[missionOrder],
        ];
    }
  }, [missionOrder, storyType]);

  const sounds = missionStories.map((story) => story.sound);

  const { play, loaded: audioLoaded } = useAudio({
    preload: ["pageTurn", ...sounds],
  });
  const allImages = missionStories.reduce<string[]>((prev, current) => {
    if (!prev.includes(current.image)) {
      return [...prev, current.image];
    }
    return [...prev];
  }, []);

  const allImagesLoaded =
    Object.keys(loaded).length ===
    Object.values(loaded).filter((v) => v).length;

  console.log("allImagesLoaded", allImagesLoaded);

  const previousPage = missionStories[page - 1];
  const currentPage = missionStories[page];
  const nextPage = missionStories[page + 1];

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    [...allImages].forEach((src) => {
      const img = document.createElement("img");
      img.onload = () => {
        setLoaded((l) => ({ ...l, [src]: true }));
      };
      img.src = src;
      setLoaded((l) => ({ ...l, [src]: false }));
      ref.current && ref.current.appendChild(img);
    });
  }, []);

  useEffect(() => {
    try {
      sessionStorage.setItem(STORY_PAGE_SESSION_STORAGE_KEY, String(page));
    } catch (e) {
      console.error(e);
    }
  }, [page]);

  const endPageAudioStop = useRef<() => void>();

  useEffect(() => {
    return () => {
      endPageAudioStop.current && endPageAudioStop.current();
    };
  }, []);

  useEffect(() => {
    // NOTE: ストーリーをスクロールできないようにする
    const mainElement = document.getElementById("main");
    if (mainElement) {
      mainElement.style.overflow = "hidden";
      mainElement.style.height = "100vh";
      mainElement.style.maxHeight = "100svh";
    }
    return () => {
      if (mainElement) {
        mainElement.style.overflow = "";
        mainElement.style.height = "";
        mainElement.style.maxHeight = "";
      }
    };
  });

  console.log("audioLoaded", audioLoaded);
  // 自動再生を開始するにはオーディオがロードされている必要がある
  if (!audioLoaded) {
    return <LoadingPage />;
  }

  return (
    <_BG>
      <_MissionStoryWrapper>
        <_MissionStoryContainer>
          <_Preload ref={ref} />
          <SkipStoryModal
            isOpen={isShowSkipStoryModal}
            onCancel={() => setIsShowSkipStoryModal(false)}
            onSkip={onCompleted}
          />
          <Page
            key={`p_${page}`}
            stopped={closed}
            story={currentPage}
            onNext={() => {
              if (page >= missionStories.length - 1) {
                setClosed(true);
                onCompleted();
                return;
              }

              setPage((p) => {
                return p + 1;
              });

              if (
                currentPage.image !== nextPage?.image &&
                currentPage.pageTurn !== false
              ) {
                play("pageTurn", 0, undefined, undefined);
              }
            }}
            onSkip={onCompleted}
            totalPageNumber={missionStories.length}
            currentPageNumber={page + 1}
            openSkipModal={() => setIsShowSkipStoryModal(true)}
          />
          {previousPage &&
            currentPage.image !== previousPage.image &&
            previousPage.pageTurn !== false &&
            !closed && (
              <PreviousPage key={`pp_${page}`} image={previousPage.image} />
            )}
        </_MissionStoryContainer>
      </_MissionStoryWrapper>
    </_BG>
  );
};

const _BG = styled.div`
  width: 100vw;
  background-color: white;
  height: 100vh;
  max-height: 100dvh;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  touch-action: pinch-zoom;
`;

const _Preload = styled.div`
  display: none;
`;

const _MissionStoryWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  max-width: 100svw;
  max-height: 100svh;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (orientation: portrait) {
    margin: 0;
    width: 100vh;
    height: 100vw;
    max-width: 100svh;
    max-height: 100svw;
    transform: rotate(90deg);
    transform-origin: center;
  }
`;

const _MissionStoryContainer = styled.div`
  width: 100vw;
  height: 100vh;

  max-width: 100svw;
  max-height: 100svh;

  position: absolute;
  top: 0;
  left: 0;
  @media (orientation: portrait) {
    width: 100vh;
    height: 100vw;
    max-width: 100svh;
    max-height: 100svw;
  }
`;
