import React from "react";
import styled from "styled-components";
import { CommonStyle } from "../../Common";
import {
  SelectNextBookImgList,
  SelectNextBookImgListProps,
} from "~/components/organisms/List/SelectNextBookImgList";
import { Text } from "~/components/atoms/texts/Text";
import { Stack } from "~/components/atoms/layout/Stack";
import { TextButton } from "~/components/molecules/buttons/TextButton";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import { useTranslation } from "~/i18n";

type SelectBookTipsProps = {
  selectNextBookImgListProps: Omit<SelectNextBookImgListProps, "onClick">;
  onNext: () => void;
};

type Flow = "selectBook" | "reaction";

export const SelectBookTips: React.FC<SelectBookTipsProps> = ({
  selectNextBookImgListProps,
  onNext,
}) => {
  const [flow, setFlow] = React.useState<Flow>("selectBook");
  const { currentUser } = React.useContext(CurrentUserContext);
  const { t } = useTranslation();

  return (
    <>
      {flow === "selectBook" && (
        <CommonStyle>
          <Stack rowGap="20px" alignItems="center">
            <_MultiLineText fontSize="MD" lineHeight="MD">
              {t<TranslateKeys>("今日は どの本を 読みたい？")}
              <br />
              {t<TranslateKeys>("好きな 本を 選んで 読んでみよう👀")}
            </_MultiLineText>
            <SelectNextBookImgList
              onClick={() => setFlow("reaction")}
              {...selectNextBookImgListProps}
            />
            <TextButton
              text="スキップする"
              onClick={onNext}
              fontSize="MD"
              lineHeight="MD"
              fontColor="primitive.tex.t500"
            />
          </Stack>
        </CommonStyle>
      )}
      {flow === "reaction" && (
        <CommonStyle
          loginTipsButtonProps={{
            text: t<TranslateKeys>("読む💪"),
            onClick: onNext,
          }}
        >
          <Text lineHeight="MD" fontSize="MD" fontColor="semantic.text.body">
            {t<TranslateKeys>("{{user}}さんが 選んだ本、おもしろそう！", {
              user: currentUser?.general.nickname,
            })}
            <br />
            {t<TranslateKeys>("ぜひ、感想を 聞かせてね😉")}
          </Text>
        </CommonStyle>
      )}
    </>
  );
};

const _MultiLineText = styled(Text)`
  white-space: pre-wrap;
`;
