import React from "react";
import styled from "styled-components";
import { MissionStory } from "~/components/organisms/modals/QuestLevel/MissionStory";
import { PlayMissionStoryModal } from "~/components/organisms/modals/QuestLevel/MissionModal/PlayMissionStoryModal";
import { MissionOrder } from "~/generated/graphql";
import { initAudioContext } from "~/utils/hooks/useAudio";

type StoryReplayModalProps = {
  missionOrder: MissionOrder;
  onCompleted: () => void;
};

type ModalFlowState = "PlayMissionStoryModal" | "MissionStory";

export const StoryReplayModal: React.FC<StoryReplayModalProps> = ({
  missionOrder,
  onCompleted,
}: StoryReplayModalProps) => {
  const [flowState, setFlowState] = React.useState<ModalFlowState>(
    "PlayMissionStoryModal"
  );

  const onClickToStartStory = React.useCallback((e: React.MouseEvent) => {
    try {
      initAudioContext(e);
    } catch {
      console.error("Failed to initialize audio context");
    }
    setFlowState("MissionStory");
  }, []);

  switch (flowState) {
    case "PlayMissionStoryModal":
      return (
        <_Wrapper>
          <PlayMissionStoryModal
            missionOrder={missionOrder}
            storyType="Start"
            onClick={onClickToStartStory}
            isError={false}
          />
        </_Wrapper>
      );
    case "MissionStory":
      return (
        <_Wrapper>
          <MissionStory
            missionOrder={missionOrder}
            storyType="Start"
            onCompleted={onCompleted}
          />
        </_Wrapper>
      );
  }
};
const _Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: ${({ theme }) => theme.colors.base.background};
  z-index: ${({ theme }) => theme.zIndex.modal};
`;
