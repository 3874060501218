import React from "react";
import styled from "styled-components";
import { ExperienceSummary } from "~/components/templates/RecordBook/Archievement/ExperienceSummary";
import { FootprintTemplate, FootprintProps } from "./Footprint";
import { ReadCharGraph } from "./ReadCharGraph";
import { Reviews } from "./Reviews";
import { Stack } from "~/components/atoms/layout/Stack";
import { RecordBookDiffModal } from "./RecordBookDiffModal";
import { GetRecordBookDiffResult } from "~/generated/graphql";
import { NoRecordBookDiffModal } from "./NoRecordBookDiffModal";
import { ReadingHabitCotainer } from "./ReadingHabit/container";
import { ReceivedStar, ReceivedStarProps } from "./ReceivedStar";
import { ReceivedStarLockWrapper } from "~/components/organisms/Unlock/LockWrapper/receivedStarLockWrapper";

export type ArchievementRecordTemplateProps = {
  nickname: string;
  footprintProps: FootprintProps;
  receivedStarProps: ReceivedStarProps;
  recordBookDiff: GetRecordBookDiffResult;
  isModalOpen: boolean;
  handleCloseModal: () => void;
};

export const ArchievementRecordTemplate: React.FC<
  ArchievementRecordTemplateProps
> = ({
  nickname,
  footprintProps,
  receivedStarProps,
  recordBookDiff,
  isModalOpen,
  handleCloseModal,
}) => {
  const hasRecordDiff =
    recordBookDiff.ReadCharSumDiff !== 0 ||
    recordBookDiff.ReadBookSumDiff !== 0 ||
    recordBookDiff.MiniLessonTotalNumDiff !== 0;

  return (
    <>
      <Stack rowGap="20px">
        <_CardWrapper>
          <ExperienceSummary />
        </_CardWrapper>
        <_CardWrapper>
          <FootprintTemplate {...footprintProps} />
        </_CardWrapper>
        <_CardWrapper>
          <ReadingHabitCotainer />
        </_CardWrapper>
        <_CardWrapper>
          <ReadCharGraph />
        </_CardWrapper>
        <_CardWrapper>
          <Reviews />
        </_CardWrapper>
        <ReceivedStarLockWrapper>
          <_CardWrapper>
            <ReceivedStar {...receivedStarProps} />
          </_CardWrapper>
        </ReceivedStarLockWrapper>
      </Stack>
      {hasRecordDiff && (
        <RecordBookDiffModal
          nickname={nickname}
          isModalOpen={isModalOpen}
          termStartDate={recordBookDiff.TermStartDate}
          termEndDate={recordBookDiff.TermEndDate}
          readCharSumDiff={recordBookDiff.ReadCharSumDiff}
          readBookSumDiff={recordBookDiff.ReadBookSumDiff}
          miniLessonTotalNumDiff={recordBookDiff.MiniLessonTotalNumDiff}
          onClose={handleCloseModal}
        />
      )}
      {!hasRecordDiff && (
        <NoRecordBookDiffModal
          isModalOpen={isModalOpen}
          onClose={handleCloseModal}
        />
      )}
    </>
  );
};

const _CardWrapper = styled.div`
  background: ${({ theme }) => theme.colors.semantic.layout.backgroundBox};
  border-radius: 16px;
  padding: 16px;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.semantic.text.label};
`;
