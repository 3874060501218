import React, { ReactNode } from "react";
import styled, { css } from "styled-components";
import { ColorTheme } from "~/styles/design_token/color";
import {
  PaddingProps,
  paddingStyle,
  expandPaddingProps,
} from "~/styles/design_token/padding";
import { SpaceStyle } from "~/styles/design_token/size";
import { useSnd } from "~/utils/hooks/useSnd";
import { useClickTracking } from "../../../../utils/googleAnalytics/useButtonClickTracking";

export type FlatButtonProps = {
  /**
   * ボタンの役割を示す.`Outline`はサブで用いることが多い.
   */
  variant: "Normal" | "Outline";
  /**
   * ボタンの形状
   */
  shape: "Normal" | "RoundedCorner";
  /**
   * 配色
   */
  color: ColorTheme;
  /**
   * moleculesで内側の要素は制御
   */
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  onClick: (event: React.MouseEvent) => void;
  disabled?: boolean;
  borderWidth?: "1px";
  width?: SpaceStyle;
  trackingLabel?: ReactNode;
} & PaddingProps;

/**
 * @deprecated 廃止予定です。Figmaで使用されている場合はデザイナーに問い合わせてください
 */
export const FlatButton: React.FC<FlatButtonProps> = ({
  variant,
  shape,
  color,
  children,
  style,
  className,
  onClick,
  borderWidth,
  width,
  disabled = false,
  trackingLabel,
  ...restProps
}) => {
  const { play } = useSnd();
  const handleClick = (event: React.MouseEvent) => {
    play("TAP");
    onClick(event);
  };

  const { onClickWithTracking } = useClickTracking({
    onClick: handleClick,
    label: trackingLabel || children,
  });

  return (
    <ButtonCon disabled={disabled} width={width}>
      <StyledButton
        variant={variant}
        shape={shape}
        color={color}
        style={style}
        onClick={onClickWithTracking}
        disabled={disabled}
        className={className}
        borderWidth={borderWidth}
        {...expandPaddingProps(restProps)}
      >
        {children}
      </StyledButton>
    </ButtonCon>
  );
};

const ButtonCon = styled.div<Pick<FlatButtonProps, "disabled" | "width">>`
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  width: ${({ width }) => (width ? width : "auto")};
`;

const StyledButton = styled.button<
  Pick<
    FlatButtonProps,
    "variant" | "shape" | "color" | "disabled" | "borderWidth"
  > &
    PaddingProps
>`
  ${({ theme, variant, shape, color, disabled, borderWidth }) => {
    const { colors, deprecatedBorderRadius: borderRadius, lineHeight } = theme;
    const { base, pri, sec, violet, green, red, sky } = colors;
    return css`
      width: 100%;
      border-radius: ${borderRadius.XS};
      ${shape === "RoundedCorner" && RoundedCornerInterpolation}
      padding: 4px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      cursor: ${!disabled && "pointer"};
      font-family: inherit;
      font-weight: bold;
      text-align: center;
      text-decoration: none;
      line-height: ${lineHeight.MD};
      background-color: ${() => {
        if (variant === "Outline") {
          return base.white;
        } else {
          switch (color) {
            case "pri":
              return pri.p400;
            case "sec":
              return sec.s400;
            case "violet":
              return violet.v400;
            case "red":
              return red.r400;
            case "green":
              return green.g400;
            case "sky":
              return sky.s400;
            case "gray":
              return base.lightGray;
            case "white":
              return base.white;
            default:
              return pri.p400;
          }
        }
      }};
      border-width: ${variant === "Outline"
        ? borderWidth
          ? borderWidth
          : "2px"
        : "0"};
      border-style: solid;
      border-color: ${() => {
        switch (color) {
          case "pri":
            return pri.p500;
          case "sec":
            return sec.s500;
          case "violet":
            return violet.v500;
          case "green":
            return green.g500;
          case "red":
            return red.r500;
          case "sky":
            return sky.s500;
          case "gray":
            return base.gray;
          case "white":
            return base.white;
          default:
            return pri.p500;
        }
      }};
      ${paddingStyle}
      &:hover,
      &:focus {
        outline: none;
        background-color: ${() => {
          if (variant === "Outline") {
            return base.white;
          } else {
            switch (color) {
              case "pri":
                return pri.p500;
              case "sec":
                return sec.s500;
              case "violet":
                return violet.v500;
              case "green":
                return green.g500;
              case "red":
                return red.r500;
              case "sky":
                return sky.s500;
              case "gray":
                return base.gray;
              case "white":
                return base.white;
              default:
                return pri.p500;
            }
          }
        }};
      }
      &:active {
        outline: none;
        background-color: ${() => {
          switch (color) {
            case "pri":
              return pri.p500;
            case "sec":
              return sec.s500;
            case "violet":
              return violet.v500;
            case "green":
              return green.g500;
            case "red":
              return red.r500;
            case "sky":
              return sky.s500;
            case "gray":
              return base.gray;
            case "white":
              return base.white;
            default:
              return pri.p500;
          }
        }};
        ${variant === "Outline" && !disabled && OutlineInterpolation}
      }
      &:disabled {
        background-color: ${() => {
          if (variant === "Outline") {
            return base.white;
          } else {
            switch (color) {
              case "pri":
                return pri.p400;
              case "sec":
                return sec.s400;
              case "violet":
                return violet.v400;
              case "green":
                return green.g400;
              case "red":
                return red.r400;
              case "sky":
                return sky.s400;
              case "gray":
                return base.lightGray;
              case "white":
                return base.lightGray;
              default:
                return pri.p400;
            }
          }
        }};
      }
    `;
  }}
`;

const RoundedCornerInterpolation = css`
  height: 36px;
  border-radius: 18px;
`;

const OutlineInterpolation = css`
  & p,
  & svg {
    color: ${({ theme }) =>
      theme.colors.base
        .white} !important; //背景色がついた時に子要素の色が白になるように
  }
`;
