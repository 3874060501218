import React from "react";
import styled from "styled-components";
// TODO: neo componentsの方で作成する
import { BookCoverContainer } from "~/components/molecules/ListItem/Common/style";
import { BookshelfImage } from "~/components/atoms/images/BookshelfImage";
import { BookItemContainerInterpolation } from "~/components/molecules/ListItem/Common/style";

export type WantReadBookImgButtonListItemProps = {
  title: string;
  columnsNumber: number;
  imageUrl: string | undefined;
  onClick: (event: React.MouseEvent) => void;
};

export const WantReadBookImgButtonListItem: React.FC<
  WantReadBookImgButtonListItemProps
> = ({ title, columnsNumber, imageUrl, onClick }) => {
  return (
    <BookItemContainer onClick={onClick} columnsNumber={columnsNumber}>
      <BookCoverContainer>
        <BookshelfImage url={imageUrl} alt={title} />
      </BookCoverContainer>
    </BookItemContainer>
  );
};

const BookItemContainer = styled.button<
  Pick<WantReadBookImgButtonListItemProps, "columnsNumber">
>`
  background: none;
  padding: 0;
  border: none;
  ${BookItemContainerInterpolation}
`;
