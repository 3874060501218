import React from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import { Text } from "~/components/atoms/texts/Text";
import { TranslateKeys } from "~/i18n";
import { TodaysStarLockWrapper } from "../../Unlock/LockWrapper/todaysStarLockWrapper";

export type TodaysStarEnergyIconProps = {
  hasStarEnergy: boolean;
};

export const TodaysStarEnergyIcon: React.FC<TodaysStarEnergyIconProps> = ({
  hasStarEnergy,
}) => {
  const { t } = useTranslation();
  return hasStarEnergy ? (
    <_Wrapper>
      <TodaysStarLockWrapper>
        <Text fontColor="semantic.text.label" fontSize="XXS" lineHeight="MD">
          {t<TranslateKeys>("今日") + "のスター"}
        </Text>
        <img src="/img/icon/star.svg" width={30} />
        <>
          <Text fontColor="semantic.text.label" fontSize="XXS" lineHeight="EQ">
            {t<TranslateKeys>("1日1回")}
          </Text>
          <Text fontColor="semantic.text.label" fontSize="XXS" lineHeight="EQ">
            {t<TranslateKeys>("押せるよ")}
          </Text>
        </>
      </TodaysStarLockWrapper>
    </_Wrapper>
  ) : (
    <_Wrapper>
      <TodaysStarLockWrapper>
        <Text fontColor="semantic.text.label" fontSize="XXS" lineHeight="MD">
          {t<TranslateKeys>("今日") + "のスター"}
        </Text>
        <img src="/img/icon/empty_star.svg" width={30} />
        <>
          <Text fontColor="semantic.text.label" fontSize="XXS" lineHeight="EQ">
            {"また" + t<TranslateKeys>("明日")}
          </Text>
          <Text fontColor="semantic.text.label" fontSize="XXS" lineHeight="EQ">
            {t<TranslateKeys>("来てね") + "！"}
          </Text>
        </>
      </TodaysStarLockWrapper>
    </_Wrapper>
  );
};

const _Wrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.semantic.layout.backgroundBox};
    border: 2px solid ${theme.colors.semantic.layout.border};
    border-right: none;
    border-radius: 16px 0px 0px 16px;
    width: 105px;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    gap: 4px;
  `}
`;
