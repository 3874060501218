import React from "react";
import { Loading } from "~/components/atoms/Loading";
import { RecommendList } from "~/components/organisms/List/RecommendList";
import { ApolloError } from "@apollo/client";
import { GetMyCurrentRecommendQuery } from "~/generated/graphql";
import { BookshelfCurrentRecommendFragment } from "~/generated/graphql";

type RecommendBookshelfProps = {
  loading: boolean;
  error: ApolloError | undefined;
  data: GetMyCurrentRecommendQuery | undefined;
  baseItems: BookshelfCurrentRecommendFragment[];
  latestRecommendId: number;
};

export const RecommendBookshelf: React.FC<RecommendBookshelfProps> = ({
  loading,
  error,
  data,
  baseItems,
  latestRecommendId,
}) => {
  if (loading || !data) return <Loading />;
  if (error) return <>error!{error.message}</>;

  return (
    <RecommendList
      recommends={baseItems}
      recommendFrequency={data.me.recommendFrequency}
      latestRecommendId={latestRecommendId}
    />
  );
};
