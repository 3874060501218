import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { BackNavbar } from "~/components/molecules/navbars/BackNavbar";
import { NavContainer } from "../Common";
import { Stack } from "~/components/atoms/layout/Stack";
import { CharacterIntroductionList } from "./const";
import { Text } from "~/components/atoms/texts/Text";
import { TranslateKeys } from "~/i18n";

type CharacterIntroductionTemplateProps = {
  onClose: () => void;
};

export const CharacterIntroductionTemplate: React.FC<
  CharacterIntroductionTemplateProps
> = ({ onClose }) => {
  const { t } = useTranslation();
  return (
    <_Wrapper>
      <NavContainer>
        <BackNavbar titleName="キャラクター図鑑" onClick={onClose} />
      </NavContainer>
      <_Stack rowGap="20px" alignItems="center">
        {Object.values(CharacterIntroductionList).map((character) => {
          return (
            <_DetailWrapper key={character.name}>
              <Stack rowGap="12px" alignItems="center">
                <img
                  src={character.imageUrl}
                  alt={character.name}
                  width={character.width}
                />
                <Stack rowGap="8px" alignItems="center">
                  <Text
                    fontSize="LG"
                    lineHeight="EQ"
                    bold
                    fontColor="primitive.monotone.m900"
                  >
                    {t<TranslateKeys>(character.name)}
                  </Text>
                  <Text
                    lineHeight="MD"
                    fontSize="SM"
                    fontColor="primitive.monotone.m500"
                  >
                    {t<TranslateKeys>(character.description)}
                  </Text>
                </Stack>
              </Stack>
            </_DetailWrapper>
          );
        })}
      </_Stack>
    </_Wrapper>
  );
};

const _Wrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) =>
    theme.colors.semantic.layout.backgroundMain};
  padding: 56px 0;
`;

const _Stack = styled(Stack)`
  width: 100%;
  max-width: 520px;
  margin: 0 auto;
  padding: 20px;
`;

const _DetailWrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) =>
    theme.colors.semantic.layout.backgroundBox};
  padding: 16px;
  border-radius: 16px;
`;
