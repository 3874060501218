import React from "react";
import { useUnlock } from "~/store/unlock/useUnlock";
import styled from "styled-components";

type TodaysStarLockWrapperProps = {
  children: React.ReactNode;
};

const bookWormKeyUrl = "/img/unlock/book_worm_key.svg";

export const TodaysStarLockWrapper: React.FC<TodaysStarLockWrapperProps> = ({
  children,
}) => {
  const { isLocked } = useUnlock();

  if (isLocked("bfStar")) {
    return (
      <LockTodaysStarWrapper>
        <_BookWormKey src={bookWormKeyUrl} />
        <_Comment>
          レベル21で
          <br />
          あくみたい...
        </_Comment>
      </LockTodaysStarWrapper>
    );
  } else {
    return <>{children}</>;
  }
};

const LockTodaysStarWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: center;
`;

const _BookWormKey = styled.img`
  width: 30px;
  object-fit: contain;
`;

const _Comment = styled.p`
  font-weight: normal;
  font-size: ${({ theme }) => theme.fontSize.XXS};

  line-height: 160%;
  color: ${({ theme }) => theme.colors.semantic.primary.main};
`;
