import { AllEmotionStates } from "~/pages/PostReadLog/reducer";
import { PostReadLogChoiceType } from "../Common/type";

export type ConfirmEmotionsTemplateProps = {
  progress: number;
  handleBack: () => void;
  nickname: string;
  onClickGoNext: (event: React.MouseEvent) => void;
  onClicks: Record<keyof AllEmotionStates, (event: React.MouseEvent) => void>;
  allEmotions: AllEmotionStates;
  isNoneSelected: boolean;
};

export const emotions: Record<keyof AllEmotionStates, PostReadLogChoiceType> = {
  wakuwaku: {
    ja: "わくわく",
    emoji: "🌟",
  },
  sikusiku: {
    ja: "シクシク",
    emoji: "💧",
  },
  dokidoki: {
    ja: "ドキドキ",
    emoji: "💗",
  },
  nikoniko: {
    ja: "にこにこ",
    emoji: "😁",
  },
  nattoku: {
    ja: "なっとく",
    emoji: "💡",
  },
  waraeru: {
    ja: "わらえる",
    emoji: "😂",
  },
  bikkuri: {
    ja: "びっくり",
    emoji: "❗️",
  },
  punpun: {
    ja: "ぷんぷん",
    emoji: "😤",
  },
  kowai: {
    ja: "こわい",
    emoji: "😣",
  },
  dousite: {
    ja: "どうして？",
    emoji: "❓",
  },
};
