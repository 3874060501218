import React, { useState, useCallback, useContext, useMemo } from "react";
import { ReadingHabit } from ".";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import { useGetMonthlyHabitCalendarQuery } from "~/generated/graphql";

export type YearMonth = {
  year: number;
  month: number;
};

const plusMonths = (year: number, month: number, months: number): YearMonth => {
  const date = new Date(year, month - 1 + months);
  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
  };
};

export const ReadingHabitCotainer: React.FC = () => {
  const [yearMonth, setYearMonth] = useState<YearMonth>({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
  });

  const { data, loading, error } = useGetMonthlyHabitCalendarQuery({
    variables: {
      year: yearMonth.year,
      month: yearMonth.month,
    },
  });

  const { currentUser } = useContext(CurrentUserContext);
  const canGoToPrevMonth: boolean = useMemo(() => {
    if (!currentUser) {
      return false;
    }
    const userCreatedAt = new Date(currentUser.createdAt);

    //NOTE: 指定されている月の1日の0:00がユーザー作成時よりも後の場合は、前の月に遷移できる
    return userCreatedAt < new Date(yearMonth.year, yearMonth.month - 1, 1);
  }, [currentUser, yearMonth]);

  //NOTE: 指定されている月の次の月の1日の0:00が現在よりも前の場合は、次の月に遷移できる
  const canGoToNextMonth: boolean =
    new Date(yearMonth.year, yearMonth.month, 1) < new Date();

  const onNextMonth = useCallback(() => {
    setYearMonth(({ year, month }) => plusMonths(year, month, 1));
  }, [yearMonth]);

  const onPrevMonth = useCallback(() => {
    setYearMonth(({ year, month }) => plusMonths(year, month, -1));
  }, [yearMonth]);

  if (error) {
    return <div>error! {error.message}</div>;
  }

  return (
    <ReadingHabit
      year={yearMonth.year}
      month={yearMonth.month}
      canGoToNextMonth={canGoToNextMonth}
      canGoToPrevMonth={canGoToPrevMonth}
      onNextMonth={onNextMonth}
      onPrevMonth={onPrevMonth}
      achievements={data?.getMonthlyHabitCalendar ?? []}
      loading={loading || !data}
    />
  );
};
