import React, { FC, useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { getAudioContext, initAudioContext } from "~/utils/hooks/useAudio";
import { MissionStorySetting } from "./MissionStories/type";
import { AccidentarySkipStoryModal } from "./AccidentarySkipStoryModal";
import { useAudio } from "./useAudio";
import { IconTextButton } from "~/components/molecules/buttons/IconTextButton";
import { Text } from "~/components/atoms/texts/Text";
import { Stack } from "~/components/atoms/layout/Stack";
import runes from "runes2";
import { isSafari } from "~/utils/detectBrowser";

type PageProps = {
  story: MissionStorySetting;
  onNext: () => void;
  onSkip: () => void;
  stopped?: boolean;
  currentPageNumber: number;
  totalPageNumber: number;
  openSkipModal: () => void;
};

export const Page: FC<PageProps> = ({
  story: { image, sound, endDelay, startDelay, text, ruby },
  onNext,
  onSkip,
  stopped = false,
  currentPageNumber,
  totalPageNumber,
  openSkipModal,
}) => {
  const { play, buffers } = useAudio({ preload: [] });
  const [currentIndex, setCurrentIndex] = React.useState<number>(0);

  const [isShowAccidentarySkipModal, setIsShowAccidentarySkipModal] =
    useState<boolean>(false);

  const splittedText = runes(text || "");

  useEffect(() => {
    if (text && currentIndex < splittedText.length) {
      const timeoutId = setTimeout(
        () => {
          setCurrentIndex((prevIndex) => prevIndex + 1);
        },
        currentIndex === 0 ? (startDelay ? startDelay + 2000 : 2000) : 200
      );

      return () => clearTimeout(timeoutId);
    }
  }, [currentIndex, text, startDelay, splittedText]);

  useEffect(() => {
    const firstTimeoutId = setTimeout(() => {
      if (getAudioContext().state === "running") return;
      setIsShowAccidentarySkipModal(true);
    }, 7000);
    // もしstateがrunningであるにも関わらず音声が再生できずに次に進めなかったら怖いので、再生時間分過ぎたらたら必ずモーダルを出す
    const _end = endDelay || 2000;
    const _start = 1000 + (startDelay ? startDelay : 0);
    const margin = 3000;
    const timeLimit = buffers[sound]
      ? buffers[sound].duration * 1000 + _end + _start + margin
      : 10000 + _end + _start + margin;
    const secondTimeoutId = setTimeout(() => {
      setIsShowAccidentarySkipModal(true);
    }, timeLimit);
    return () => {
      clearTimeout(firstTimeoutId);
      clearTimeout(secondTimeoutId);
    };
  }, [buffers, endDelay, startDelay, sound, isShowAccidentarySkipModal]);

  const stopPlaying = useRef<() => void>();

  useEffect(() => {
    if (stopped && stopPlaying.current) {
      stopPlaying.current();
    }
  }, [stopped]);

  useEffect(() => {
    const stop = play(
      sound,
      1 + (startDelay ? startDelay / 1000 : 0),
      onNext,
      endDelay
    );
    stopPlaying.current = stop;

    // unmoutするとき音を止める
    return () => {
      stop && stop();
    };
  }, []);
  const safari = isSafari();
  return (
    <>
      <AccidentarySkipStoryModal
        isOpen={isShowAccidentarySkipModal}
        onClick={onSkip}
        onRetry={(event: React.MouseEvent) => {
          initAudioContext(event);
          setIsShowAccidentarySkipModal(false);
        }}
      />

      {splittedText ? (
        <_PageWithTextContainer>
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 692 389"
            xmlns="http://www.w3.org/2000/svg"
          >
            <foreignObject
              width="100%"
              height="100%"
              requiredExtensions="http://www.w3.org/1999/xhtml"
            >
              <_PageWithTextWrapper>
                <img src={image} width={"80%"} />
                <_PageTextWrapper isSafari={safari}>
                  <Stack
                    rowGap="20px"
                    alignItems="flex-end"
                    style={{ width: "100%" }}
                  >
                    <_SafariMarginWrapper isSafari={safari}>
                      <IconTextButton
                        color="tex"
                        text="スキップ"
                        onClick={openSkipModal}
                        name="forwardFast"
                        fontSize="XS"
                        lineHeight="EQ"
                        iconPlace="sub"
                      />
                    </_SafariMarginWrapper>
                    <_PageText>
                      {splittedText.map((char, index) => {
                        if (char === "\n") {
                          return <br key={index} />;
                        } else if (!ruby) {
                          return (
                            <_RubyTextWrapper key={index} isSafari={safari}>
                              <_RubyText isVisible={index <= currentIndex}>
                                {char}
                              </_RubyText>
                            </_RubyTextWrapper>
                          );
                        } else {
                          return (
                            <_RubyTextWrapper key={index} isSafari={safari}>
                              <_RubyText isVisible={index < currentIndex}>
                                {char}
                                {ruby && (
                                  <_Rt isVisible={index < currentIndex}>
                                    {ruby[index]}
                                  </_Rt>
                                )}
                              </_RubyText>
                            </_RubyTextWrapper>
                          );
                        }
                      })}
                    </_PageText>
                  </Stack>
                  <_SafariMarginWrapper isSafari={safari}>
                    <Text fontSize="XS" lineHeight="MD" fontColor="tex.t500">
                      {currentPageNumber}/{totalPageNumber}
                    </Text>
                  </_SafariMarginWrapper>
                </_PageTextWrapper>
              </_PageWithTextWrapper>
            </foreignObject>
          </svg>
        </_PageWithTextContainer>
      ) : (
        <>
          <_PageContainer image={image} />
          <_SkipButtonWrapper>
            <IconTextButton
              color="tex"
              text="スキップ"
              onClick={openSkipModal}
              name="forwardFast"
              fontSize="XS"
              lineHeight="EQ"
              iconPlace="sub"
            />
          </_SkipButtonWrapper>
        </>
      )}
    </>
  );
};

const _SafariMarginWrapper = styled.div<{ isSafari }>`
  margin-right: ${({ isSafari }) => (isSafari ? "20px" : "0 ")};
`;

const _SkipButtonWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  width: fit-content;
  padding: 8px;
  background-color: ${({ theme }) => theme.colors.base.lightGray};
  border-radius: 16px;
`;

const _PageContainer = styled.div<{
  image: string;
}>`
  width: 100vw;
  height: 100vh;

  max-width: 100svw;
  max-height: 100svh;
  cursor: pointer;
  background-image: url(${({ image }) => image});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  position: absolute;
  top: 0;
  left: 0;
  @media (orientation: portrait) {
    width: 100vh;
    height: 100vw;
    max-width: 100svh;
    max-height: 100svw;
  }
`;

const _PageWithTextWrapper = styled.div`
  display: flex;
`;

const _PageWithTextContainer = styled.div`
  width: 100vw;
  height: 100vh;

  max-width: 100svw;
  max-height: 100svh;

  position: absolute;
  top: 0;
  left: 0;
  @media (orientation: portrait) {
    width: 100vh;
    height: 100vw;
    max-width: 100svh;
    max-height: 100svw;
  }
`;

const _PageTextWrapper = styled.div<{ isSafari: boolean }>`
  width: 141px;
  height: 389px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  padding: ${({ isSafari }) => (isSafari ? "12px 0" : "12px 20px")};
`;

const _PageText = styled.p`
  font-family: "Noto Sans JP", "YuGothic", "Yu Gothic";
  height: 300px;
  width: 100%;
  line-height: 200%;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
`;

const _RubyText = styled.ruby<{ isVisible: boolean }>`
  font-family: "Noto Sans JP", "YuGothic", "Yu Gothic";
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  font-size: ${({ theme }) => theme.fontSize.SM};
  white-space: pre-wrap;
`;

const _RubyTextWrapper = styled.span<{ isSafari: boolean }>`
  width: 14px;
  height: 14px;
  //safariで表示が壊れるのを防ぐため、_PageTextとこのコンポーネント2箇所にline-heightを設定
  line-height: ${({ isSafari }) => (isSafari ? "210%" : "200%")};
`;

const _Rt = styled.rt<{ isVisible: boolean }>`
  font-size: ${({ theme }) => theme.fontSize.XXXS};
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
`;

const _PreviousPage = styled(_PageContainer)`
  position: relative;
`;

type PreviousPageProps = {
  image: string;
};

export const PreviousPage: FC<PreviousPageProps> = ({ image }) => {
  return (
    <_PreviousPageWrapper>
      <_PreviousPage image={image} />
    </_PreviousPageWrapper>
  );
};

const _PreviousPageWrapper = styled.div`
  pointer-events: none;

  animation: wipe 0.5s ease-out;
  animation-fill-mode: forwards;
  transform-origin: center left;
  position: absolute;
  top: 0;
  left: 0;

  @keyframes wipe {
    from {
      transform: perspective(2000px) rotateY(0);
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.9);
      filter: brightness(1);
    }
    to {
      transform: perspective(2000px) rotateY(-90deg);
      box-shadow: 100px 0px 100px 100px rgba(0, 0, 0, 0);
      filter: brightness(0.5);
    }
  }
`;
