import React, { ReactNode } from "react";
import styled from "styled-components";
import { TimeStamp } from "~/components/molecules/texts/TimeStamp";
import { Text } from "~/components/atoms/texts/Text";
import { ChatBubble as BaseChatBubble } from "~/components/atoms/wrappers/ChatBubble";
import {
  DeletedUserOnTalkFragment,
  UserOnTalkFragment,
} from "~/generated/graphql";
import { userIconImage } from "~/components/organisms/adminMessage/AdminChatMessage";

export type UserType = "user" | "admin" | "me" | "deletedUser";

export type BookFriendChatMessageProps = {
  iconImageUrl?: string;
  user?: UserOnTalkFragment | DeletedUserOnTalkFragment;
  children: React.ReactNode;
  userType: UserType;
  variant?: "Normal" | "Reactive";
  date: Date;
  className?: string;
  attachments?: ReactNode[];
  customIconUrl?: string;
};

export const BookFriendChatMessage: React.FC<BookFriendChatMessageProps> = ({
  children,
  user,
  userType,
  date,
  className,
  variant = "Normal",
  attachments = [],
  customIconUrl,
}) => {
  const isMyMessage = userType === "me";
  const chatBubbleColor = isMyMessage ? "orange" : "white";
  const images = {
    user:
      user?.__typename === "User" &&
      user?.userIconType !== undefined &&
      user?.userIconType !== null
        ? userIconImage[user?.userIconType]
        : undefined,
    me: undefined,
    deletedUser: "/img/icon/unknown-person.jpg",
    admin:
      customIconUrl ||
      (variant === "Reactive"
        ? "/img/sensei/icon_point.jpg"
        : "/img/sensei/icon_smile.jpg"),
  };
  const userIconImageUrl = images[userType] || "";

  return (
    <ChatMessageContainer className={className} isMyMessage={isMyMessage}>
      <ChatMessageInner variant={variant}>
        {userIconImageUrl && (
          <UserIcon
            src={userIconImageUrl}
            alt={user?.__typename === "User" ? user?.nickname : ""}
          />
        )}
        <MessageContainer>
          {!isMyMessage && user && (
            <UserName fontSize={"XS"} lineHeight={"MD"}>
              {user.__typename === "User"
                ? user.nickname
                : "おやすみちゅうのともだち"}
            </UserName>
          )}

          <ChatBubble variant={chatBubbleColor}>{children}</ChatBubble>
          {attachments.map((a, i) => {
            return (
              <_AttachmentContainer key={`a_${i}`}>{a}</_AttachmentContainer>
            );
          })}
          <TimeStampCon>
            <TimeStamp time={date} textAlign="right" />
          </TimeStampCon>
        </MessageContainer>
      </ChatMessageInner>
    </ChatMessageContainer>
  );
};

const ChatMessageContainer = styled.div<{
  isMyMessage: boolean;
}>`
  width: 100%;
  display: flex;
  justify-content: ${({ isMyMessage }) =>
    isMyMessage ? "flex-end" : "flex-start"};
`;

const ChatMessageInner = styled.div<{ variant: "Normal" | "Reactive" }>`
  min-width: ${({ variant }) => (variant === "Reactive" ? "296px" : "unset")};
  display: flex;
  max-width: 80vw;
  @media screen and (min-width: 600px) {
    max-width: 480px;
  }
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ChatBubble = styled(BaseChatBubble)`
  width: 100%;
`;

const _AttachmentContainer = styled.div`
  width: 274px;
  border: 2px solid ${({ theme }) => theme.colors.base.lightGray};
  margin-top: 4px;
  border-radius: 8px;
  background-color: white;
`;

const TimeStampCon = styled.div`
  margin: 6px 0;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const UserIcon = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin: 0 10px 0 0;
`;

const UserName = styled(Text)`
  padding-bottom: 3px;
`;
