import React from "react";

import styled from "styled-components";
import { Text } from "~/components/atoms/texts/Text";
import { RoundedBox } from "~/components/atoms/surfaces/RoundedBox";
import { useTranslation } from "~/i18n";

export type NotAssignedProps = {
  nickname: string;
};

export const NotAssigned: React.FC<NotAssignedProps> = ({ nickname }) => {
  const { t } = useTranslation();
  return (
    <_Wrapper>
      <Text fontSize={"MD"} lineHeight={"LG"} bold={true}>
        {t<TranslateKeys>("本の友は準備中です！")}
      </Text>
      <Text fontSize={"SM"} lineHeight={"LG"}>
        {t<TranslateKeys>(
          "明日までお待ちください。\n{{nickname}}さんと気が合いそうな読書仲間の感想を見られるように準備しています。",
          { nickname }
        )}
      </Text>
    </_Wrapper>
  );
};

const _Wrapper = styled(RoundedBox)`
  display: flex;
  flex-direction: column;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
  gap: 12px;
`;
