import React, { CSSProperties, ReactNode, VFC } from "react";
import styled, { css } from "styled-components";
import { TextButton } from "~/components/atoms/buttons/deprecatedTextButton";
import { Icon, IconType } from "~/components/atoms/Icon";
import { Text, TextProps } from "~/components/atoms/texts/Text";
import {
  Anchorize,
  AnchorizeProps,
  Linkerize,
  LinkerizeProps,
} from "~/components/atoms/buttons/tag_changer";
import { ColorTheme } from "~/styles/design_token/color";
import { ColorInterpolation } from "~/styles/interpolation";

export type IconTextButtonProps = TextProps & {
  iconPlace: "pre" | "sub";
  color: ColorTheme;
  text: ReactNode;
  onClick: (event: React.MouseEvent) => void;
  disabled?: boolean;
  name: IconType;
  textStyle?: CSSProperties;
};

export const IconTextButton: VFC<IconTextButtonProps> = ({
  iconPlace,
  color,
  onClick,
  disabled,
  name,
  text,
  textStyle = {},
  ...textProps
}) => {
  const { fontSize } = textProps;
  return (
    <TextButton
      fontSize={fontSize}
      onClick={onClick}
      prefix={
        iconPlace === "pre" && (
          <_IconContainer>
            <Icon
              name={name}
              color={color}
              style={
                disabled ? { width: "auto", opacity: 0.3 } : { width: "auto" }
              }
            />
          </_IconContainer>
        )
      }
      suffix={
        iconPlace === "sub" && (
          <_IconContainer>
            <Icon
              name={name}
              color={color}
              style={
                disabled ? { width: "auto", opacity: 0.3 } : { width: "auto" }
              }
            />
          </_IconContainer>
        )
      }
    >
      <StyledText
        {...textProps}
        disabled={disabled}
        color={color}
        style={textStyle}
      >
        {text}
      </StyledText>
    </TextButton>
  );
};

export type IconTextAnchorProps = Omit<IconTextButtonProps, "onClick"> &
  Omit<AnchorizeProps, keyof IconTextButtonProps>;

export const IconTextAnchor: VFC<IconTextAnchorProps> = ({
  iconPlace,
  color,
  disabled,
  name,
  text,
  fontSize,
  bold,
  lineHeight,
  ...anchorProps
}) => (
  <Anchorize {...anchorProps} disabled={disabled}>
    <IconTextButton
      onClick={() => {
        return;
      }}
      iconPlace={iconPlace}
      color={color}
      disabled={disabled}
      name={name}
      text={text}
      fontSize={fontSize}
      bold={bold}
      lineHeight={lineHeight}
    />
  </Anchorize>
);

type IconTextLinkProps = Omit<IconTextButtonProps, "onClick"> &
  Omit<LinkerizeProps, keyof IconTextButtonProps>;

export const IconTextLink: VFC<IconTextLinkProps> = ({
  iconPlace,
  color,
  disabled,
  name,
  text,
  fontSize,
  bold,
  lineHeight,
  ...linkProps
}) => (
  <Linkerize {...linkProps} disabled={disabled}>
    <IconTextButton
      onClick={() => {
        return;
      }}
      iconPlace={iconPlace}
      color={color}
      disabled={disabled}
      name={name}
      text={text}
      fontSize={fontSize}
      bold={bold}
      lineHeight={lineHeight}
    />
  </Linkerize>
);

const StyledText = styled(Text)<
  Pick<IconTextButtonProps, "color" | "fontSize" | "disabled">
>`
  ${({ disabled }) => {
    return css`
      ${ColorInterpolation.Sub}
      text-align: left;
      /* max-width: 140px; */
      opacity: ${disabled && 0.3};
      &:hover,
      &:active,
      &:focus {
        text-decoration: ${!disabled && "underline"};
      }
    `;
  }}
`;

//テキストに対するアイコンの高さの微調整用。良い方法が見つかったら後で消す
const _IconContainer = styled.div`
  /* transform: translateY(-0.07em); */
  height: 100%;
`;
