import React, { VFC } from "react";
import styled from "styled-components";
import { Heading2 } from "~/components/atoms/texts/Heading2";
import { BackNavbar } from "~/components/molecules/navbars/BackNavbar";
import { Interpolation } from "~/styles/interpolation";
import { NavContainer, Wrapper } from "../Common";
import { EditRecommendSettingTemplateProps } from "./type";
import { Text } from "~/components/atoms/texts/Text";
import { PrimaryButton } from "~/components/molecules/buttons/PrimaryButton";
import { NotifyTextToast } from "~/components/organisms/toasts/NotifyTextToast";
import { Input } from "~/components/atoms/forms/Input";

export const EditSearchKeywordTemplate: VFC<
  EditRecommendSettingTemplateProps
> = ({
  searchWord,
  handleBack,
  onChangeSearchWord,
  onSubmitSearchWord,
  openModal,
  setOpenModal,
  modalMessage,
}) => {
  return (
    <>
      <NavContainer>
        <BackNavbar titleName={"蔵書検索キーワード"} onClick={handleBack} />
      </NavContainer>
      <Wrapper>
        <TopWrapper>
          <Container>
            <div>
              <Heading fontSize={"MD"} lineHeight={"EQ"} bold>
                蔵書検索の設定
              </Heading>
              <P fontSize={"SM"} lineHeight={"MD"}>
                おすすめ本予約画面で検索するときに使用する、利用図書館の市区町村名を登録してください。
              </P>
            </div>
            <Input
              onChange={onChangeSearchWord}
              value={searchWord}
              placeholder={"例：港区、横浜市など"}
            />
            <PrimaryButton
              text={"登録"}
              onClick={onSubmitSearchWord}
              color={"pri"}
              fontSize={"XL"}
              lineHeight={"MD"}
              bold
            />
          </Container>
        </TopWrapper>
      </Wrapper>
      <NotifyTextToast
        isOpen={openModal}
        setIsOpen={setOpenModal}
        text={modalMessage}
      />
    </>
  );
};

const TopWrapper = styled.div`
  padding: 74px 0 24px 0;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.size.L};
`;

const Container = styled.div`
  ${Interpolation.Border}
  width: 100%;
  border-radius: ${({ theme }) => theme.deprecatedBorderRadius.MD};
  background-color: ${({ theme }) => theme.colors.base.white};
  padding: ${({ theme }) => theme.size.XL};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.size.XXXL};
  margin-bottom: 12px;
`;

const Heading = styled(Heading2)`
  color: ${({ theme }) => theme.colors.tex.t800};
  margin-bottom: ${({ theme }) => theme.size.XXS};
`;

const P = styled(Text)`
  color: ${({ theme }) => theme.colors.tex.t700};
`;
