import React from "react";
import { Stack } from "~/components/atoms/layout/Stack";
import { BaseModal } from "~/components/atoms/wrappers/BaseModal";
import { ReadingTechniqueType } from "~/generated/graphql";
import { StarIcon } from "../star";
import { Heading3 } from "~/components/atoms/texts/Heading3";
import { readingTechniqueDescription, readingTechniqueTypeStr } from "../const";
import { Text } from "~/components/atoms/texts/Text";
import { SmallButton } from "~/components/atoms/buttons/Button";
import { LoadingSection } from "~/components/templates/Loading/LoadingSection";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { TranslateKeys } from "~/i18n";

export type ConfirmStarModalProps = {
  onBack: () => void;
  onSubmit: () => Promise<boolean>;
  readingTechniqueType: ReadingTechniqueType;
  isOpen: boolean;
};

export const ConfirmStarModal: React.FC<ConfirmStarModalProps> = ({
  onBack,
  onSubmit,
  readingTechniqueType,
  isOpen,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleSubmit = React.useCallback(() => {
    setLoading(true);
    onSubmit().then(() => setLoading(false));
  }, [onSubmit]);
  return (
    <BaseModal open={isOpen} closeOnOverlayClick={false} onClose={onBack}>
      <_Wrapper>
        <Stack rowGap="16px">
          <Stack alignItems="center" rowGap="8px">
            <StarIcon
              readingTechniqueType={readingTechniqueType}
              size={32}
              checked
            />
            <Stack rowGap="12px">
              <Stack>
                <Heading3
                  lineHeight="MD"
                  fontSize="SM"
                  fontColor="semantic.text.heading"
                  bold
                >
                  {readingTechniqueTypeStr[readingTechniqueType]}
                </Heading3>
                <Text
                  lineHeight="MD"
                  fontSize="XS"
                  fontColor="semantic.text.body"
                >
                  {t<TranslateKeys>(
                    readingTechniqueDescription[readingTechniqueType]
                  )}
                </Text>
              </Stack>
              <Text
                lineHeight="MD"
                fontSize="XS"
                fontColor="semantic.text.body"
              >
                {"このワザのスターを" + t<TranslateKeys>("送る") + "？"}
              </Text>
            </Stack>
          </Stack>
          {loading ? (
            <LoadingSection />
          ) : (
            <_ButtonWrapper>
              <SmallButton color="gray" onClick={onBack}>
                {t<TranslateKeys>("選び直す")}
              </SmallButton>
              <SmallButton color="primary" onClick={handleSubmit}>
                {t<TranslateKeys>("送る")}
              </SmallButton>
            </_ButtonWrapper>
          )}
        </Stack>
      </_Wrapper>
    </BaseModal>
  );
};

const _Wrapper = styled.div`
  min-width: 270px;
`;

const _ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 8px;
  align-items: center;
`;
