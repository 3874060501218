import React, { createContext, ReactNode, useContext, useEffect } from "react";
import {
  useAuthSessionPresenceQuery,
  useCreateAuthSessionMutation,
} from "~/generated/graphql";
import { Auth0Context, getAccessToken } from "../Auth0Context";

type AuthSessionContextValue = {
  isLoading: boolean;
  isAuthenticated: boolean;
};

const AuthSessionContext = createContext<AuthSessionContextValue>({
  isLoading: true,
  isAuthenticated: false,
});

type Props = {
  children: ReactNode;
};

export const AuthSessionProvider: React.FC<Props> = ({ children }) => {
  const { isAuthenticated: isAuth0Authenticated, isLoading: isAuth0Loading } =
    useContext(Auth0Context);
  const { data, loading, refetch } = useAuthSessionPresenceQuery();
  const isAuthenticated = Boolean(data?.sessionPresence);
  const [createAuthSession] = useCreateAuthSessionMutation({
    onCompleted: () => {
      refetch();
    },
  });

  // NOTE: auth0 でログインできているが Cookie にセッションが無い場合にセッションを生成する。ログイン直後に一度だけ実行される想定。
  useEffect(() => {
    if (isAuth0Loading || loading || isAuthenticated || !isAuth0Authenticated) {
      return;
    }

    (async () => {
      const accessToken = await getAccessToken();
      if (!accessToken) {
        return;
      }

      createAuthSession({
        variables: {
          token: accessToken.token,
        },
      });
    })();
  }, [
    createAuthSession,
    isAuth0Authenticated,
    isAuth0Loading,
    isAuthenticated,
    loading,
  ]);

  const isLoading =
    !isAuthenticated &&
    (isAuth0Loading ||
      // NOTE: session は無いが auth0 の認証が完了している場合、
      // createAuthSession とその後の authSessionPresence の取得がなされるため、loading 扱いとしておく
      isAuth0Authenticated ||
      loading);

  return (
    <AuthSessionContext.Provider value={{ isLoading, isAuthenticated }}>
      {children}
    </AuthSessionContext.Provider>
  );
};

export const useAuthSessionContext = (): AuthSessionContextValue =>
  useContext(AuthSessionContext);
