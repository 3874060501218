import { ReadingTechniqueType } from "~/generated/graphql";

export const readingTechniqueTypeStr: Record<ReadingTechniqueType, string> = {
  IMAGINE: "おもいえがく",
  CONNECT: "つなげる",
  QUESTION: "しつもんする",
  PREDICT: "よそうする",
  ACKNOWLEDGE: "みとめる",
  JUDGE: "みきわめる",
  INTERPRET: "かいしゃくする",
};

export const readingTechniqueDescription: Record<
  ReadingTechniqueType,
  TranslateKeys
> = {
  IMAGINE: "体の 感覚を 使って 頭の中に イメージを 描く",
  CONNECT: "自分の 知っている事と つなげて 読む",
  QUESTION: "質問して 本の意味を 深く 理解する",
  PREDICT: "実際には 書かれていない 行間の意味を 読み取る",
  ACKNOWLEDGE: "自分の 正しさや 間違いを 見つけて 考えを深める",
  JUDGE: "本の 大事な所を 見つけながら 読む",
  INTERPRET: "本の内容を まとめたり、自分の考えを 付け加えたりする",
};
