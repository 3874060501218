import { TalkItem } from ".";

export const flattenItems = (items: TalkItem[]): TalkItem[] => {
  return items.reduce<TalkItem[]>((prev, cur) => {
    if (cur.item.__typename === "ReactiveHistory") {
      if (cur.item.message) {
        for (let i = 0; i < cur.item.message.length; i++) {
          prev.push({
            __typename: "Talk",
            id: cur.id,
            item: {
              __typename: "ReactiveHistory",
              message: [cur.item.message[i]],
              question:
                i + 1 === cur.item.message.length
                  ? cur.item.question
                  : undefined,
              state: cur.item.state,
              sentTime: cur.item.sentTime,
              speaker: cur.item.speaker,
            },
            firstUnreadItem: i === 0 ? cur.firstUnreadItem : false,
          });
        }
      }

      if (cur.item.message.length === 0 && cur.item.question) {
        prev.push({
          __typename: "Talk",
          id: cur.id,
          item: {
            __typename: "ReactiveHistory",
            message: [""],
            question: cur.item.question,
            state: cur.item.state,
            sentTime: cur.item.sentTime,
            speaker: cur.item.speaker,
          },
          firstUnreadItem: cur.firstUnreadItem,
        });
      }

      if (cur.item.response) {
        for (let i = 0; i < cur.item.response.length; i++) {
          prev.push({
            __typename: "Talk",
            id: cur.id,
            item: {
              __typename: "ReactiveHistory",
              message: [],
              response: [cur.item.response[i]],
              state: cur.item.state,
              sentTime: cur.item.sentTime,
              responseTime: cur.item.responseTime,
              speaker: cur.item.speaker,
            },
          });
        }
      }

      return prev;
    }

    prev.push(cur);
    return prev;
  }, []);
};
