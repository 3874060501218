import React from "react";
import styled, { useTheme } from "styled-components";
import { SolidButton } from "~/components/atoms/buttons/SolidButton";
import { Icon } from "~/components/atoms/Icon";
import { Text, TextProps } from "~/components/atoms/texts/Text";
import { ColorTheme } from "~/styles/design_token/color";
import {
  Anchorize,
  AnchorizeProps,
  Linkerize,
  LinkerizeProps,
} from "~/components/atoms/buttons/tag_changer";
import {
  PaddingProps,
  expandPaddingProps,
} from "~/styles/design_token/padding";

export type PrimaryButtonProps = TextProps & {
  onClick?: (e: React.MouseEvent) => void;
  disabled?: boolean;
  color: ColorTheme;
  text: string;
  withArrow?: boolean;
  withChevron?: boolean;
  className?: string;
} & PaddingProps;

/**
 * @deprecated src/components/atoms/buttons/Button を color="primary" で使用してください
 */
export const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  onClick,
  disabled = false,
  color,
  text,
  withArrow = false,
  withChevron = false,
  className,
  ...textProps
}) => {
  const theme = useTheme();
  const { fontSize } = textProps;

  return (
    <SolidButton
      className={className}
      variant={"Normal"}
      color={color}
      shape={"Normal"}
      onClick={onClick}
      disabled={disabled}
      {...expandPaddingProps(textProps)}
    >
      <LeftSide></LeftSide>
      <Center>
        <InnerText {...textProps}>{text}</InnerText>
      </Center>
      <RightSide>
        {withArrow && (
          <Icon
            name={"arrowRight"}
            style={{
              color: "inherit",
              height: theme.fontSize[fontSize],
              width: "auto",
            }}
          />
        )}
        {withChevron && (
          <Icon
            name={"chevronRight"}
            style={{
              color: theme.colors.base.white,
              height: theme.fontSize[fontSize],
              width: "auto",
            }}
          />
        )}
      </RightSide>
    </SolidButton>
  );
};

export type PrimaryLinkProps = Omit<PrimaryButtonProps, "onClick"> &
  Omit<LinkerizeProps, keyof PrimaryButtonProps>;

export const PrimaryLink: React.FC<PrimaryLinkProps> = ({
  color,
  text,
  disabled,
  withArrow,
  fontSize,
  bold,
  lineHeight,
  className,
  ...linkProps
}) => (
  <Linkerize {...linkProps} disabled={disabled}>
    <PrimaryButton
      className={className}
      color={color}
      fontSize={fontSize}
      lineHeight={lineHeight}
      bold={bold}
      onClick={() => {
        return;
      }}
      disabled={disabled}
      text={text}
      withArrow={withArrow}
    />
  </Linkerize>
);

export type PrimaryAnchorProps = Omit<PrimaryButtonProps, "onClick"> &
  Partial<Pick<PrimaryButtonProps, "onClick">> &
  Omit<AnchorizeProps, keyof PrimaryButtonProps>;

export const PrimaryAnchor: React.FC<PrimaryAnchorProps> = ({
  color,
  text,
  disabled,
  withArrow,
  fontSize,
  bold,
  lineHeight,
  onClick,
  ...anchorProps
}) => (
  <Anchorize {...anchorProps} disabled={disabled}>
    <PrimaryButton
      color={color}
      fontSize={fontSize}
      lineHeight={lineHeight}
      bold={bold}
      onClick={(e) => {
        onClick && onClick(e);
        return;
      }}
      disabled={disabled}
      text={text}
      withArrow={withArrow}
    />
  </Anchorize>
);

const LeftSide = styled.div`
  width: 10%;
`;

const Center = styled.div`
  width: 80%;
`;

const RightSide = styled.div`
  width: 10%;
`;

const InnerText = styled(Text)`
  color: inherit; //${({ theme }) => theme.colors.base.white};
  text-align: center;
`;
