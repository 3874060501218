import React from "react";
import { usePostReadLogState } from "../usePostReadLogState";
import { FirstDislikeBookModalTemplate } from "~/components/templates/PostReadLog/FirstDislikeBookModal";
import { BOOKSHELF_DISPLAY_TYPE_QUERY_STRING_KEY } from "~/pages/RecordBook/Bookshelf";
import {
  displayTypeImageList,
  displayTypeReviewList,
} from "~/components/templates/RecordBook/Bookshelf";
import { useNavigate } from "react-router-dom";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import { TutorialFlagKey } from "~/generated/graphql";
export const FirstDislikeBook: React.FC = () => {
  const { state, dispatch } = usePostReadLogState();
  const navigate = useNavigate();
  const { getTutorialFlag, saveTutorialFlags } =
    React.useContext(CurrentUserContext);

  const handleNext = React.useCallback(() => {
    dispatch({
      type: "setFlow",
      payload: "selectBook",
    });

    if (!state.postReadLogResult?.readLog.review) {
      navigate(
        `/record_book/bookshelf?${BOOKSHELF_DISPLAY_TYPE_QUERY_STRING_KEY}=${displayTypeImageList}`
      );
    } else {
      navigate(
        `/record_book/bookshelf?${BOOKSHELF_DISPLAY_TYPE_QUERY_STRING_KEY}=${displayTypeReviewList}`
      );
    }
  }, [state.postReadLogResult?.readLog.review, navigate, dispatch]);

  const onCloseModal = () => {
    saveTutorialFlags(TutorialFlagKey.FirstDislikeBook);
    handleNext();
  };

  const openCharacterAssessmentFlag = React.useMemo(() => {
    if (
      getTutorialFlag(TutorialFlagKey.FirstDislikeBook) ||
      (state.postReadLogResult?.readLog.like !== 1 &&
        state.postReadLogResult?.readLog.like !== 2)
    ) {
      return null;
    } else {
      return true;
    }
  }, [getTutorialFlag, state.postReadLogResult?.readLog.like]);

  React.useEffect(() => {
    if (openCharacterAssessmentFlag === null) {
      handleNext();
    }
  }, [openCharacterAssessmentFlag, handleNext]);

  if (openCharacterAssessmentFlag === null) {
    return <></>;
  } else {
    return (
      <FirstDislikeBookModalTemplate
        modalProps={{
          sentence: "あ、それからもう1つ！\n",
          onClickCloseModal: onCloseModal,
          onClickToCharacterAssessment: () => {
            saveTutorialFlags(TutorialFlagKey.FirstDislikeBook);
            navigate("/character_assessment/enquete");
          },
        }}
      />
    );
  }
};
