import React from "react";
import { MultiChoiceMessage, MultiChoiceMessageProps } from "../..";
import {
  ReactiveHistoryState,
  useAnswerReactiveMutation,
} from "~/generated/graphql";
import { lastAnsweredReactiveVar, updateMessageCache } from "~/store/message";
import { RemarkMessage } from "~/components/organisms/adminMessage/AdminMessageListItem/messageListItems/RemarkMessage";
import { Stack } from "~/components/atoms/layout/Stack";
import { useBookshelfExpModal } from "~/components/organisms/Bookshelf/useBookshelfExpModal";

/**
 * 複数選択できるミニレッスン
 */

export type MultiChoiceQuestionProps = {
  state: ReactiveHistoryState;
  showAll?: boolean;
  incrementCurrentIndex: () => void;
} & Omit<
  MultiChoiceMessageProps,
  "onCheck" | "value" | "onSubmit" | "buttonText"
>;

export const MultiChoiceQuestion: React.VFC<MultiChoiceQuestionProps> = ({
  id,
  state,
  item,
  buttonDisabled = false,
  formDisabled = false,
  choices,
  showAll = true,
  incrementCurrentIndex,
  speaker,
}) => {
  const [value, setValue] = React.useState<boolean[]>(() => {
    return new Array(choices.length).fill(false);
  });
  const { setIsOpenGetExpModal, updateExperience } = useBookshelfExpModal();

  const onCheck = (index: number) => {
    setValue((prev) => {
      const newValue = [...prev];
      newValue[index] = !newValue[index];
      return newValue;
    });
  };

  const disabledButton = () => {
    if (buttonDisabled) return buttonDisabled;
    if (state === ReactiveHistoryState.Wait) {
      return Object.values(value).every((v) => !v);
    }
    return true;
  };

  //ここのloadingがtrueの間はボタン押せない
  const [answerReactive, { loading: answeringReactiveMutation }] =
    useAnswerReactiveMutation({
      update: (cache, result) => {
        result.data?.answerReactive &&
          updateMessageCache(cache, result.data.answerReactive.talkUpdate);
      },
      onCompleted: (res) => {
        lastAnsweredReactiveVar(new Date().getTime());
        if (
          res.answerReactive.userExperience &&
          res.answerReactive.userExperienceEvent
        ) {
          setIsOpenGetExpModal(true);
          updateExperience(res.answerReactive.userExperience, [
            res.answerReactive.userExperienceEvent,
          ]);
        }
      },
    });

  const onSubmit = () => {
    answerReactive({
      variables: {
        talkID: id,
        answer: { choices: { selected: value } },
      },
    });
  };

  const answers = choices.reduce((result: string[], choice) => {
    return choice.selected ? [...result, choice.choice] : result;
  }, []);

  return (
    <Stack rowGap="10px">
      <MultiChoiceMessage
        id={id}
        item={item}
        buttonText={"OK"}
        onSubmit={onSubmit}
        onCheck={onCheck}
        value={value}
        buttonDisabled={disabledButton() || answeringReactiveMutation}
        formDisabled={formDisabled || answeringReactiveMutation}
        choices={choices}
        showAll={showAll}
        incrementCurrentIndex={incrementCurrentIndex}
        speaker={speaker}
      />
      {state === ReactiveHistoryState.Completed && (
        <RemarkMessage
          id={id}
          item={{ text: answers.join(", "), time: new Date(item.time) }}
          userType={"me"}
          showAll={true}
          incrementCurrentIndex={() => {
            return;
          }}
          speaker={speaker}
        />
      )}
    </Stack>
  );
};
