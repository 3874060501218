import { MissionStorySetting } from "../type";

const baseDir = "/img/mission/start_story/story_5";

export const StartStory5: MissionStorySetting[] = [
  {
    image: `${baseDir}/scene_1.jpeg`,
    sound: "start5_scene1",
    text: "ペンギンせんぱいは、とっても大事な景色を目にして、「美しい世界が見たい」という夢をあらためて思い出していました。",
    ruby: [
      ...Array(14).fill(""),
      "だい",
      "じ",
      "",
      "け",
      "しき",
      "",
      "め",
      ...Array(5).fill(""),
      "うつく",
      ...Array(2).fill(""),
      "せ",
      "かい",
      "",
      "み",
      ...Array(6).fill(""),
      "ゆめ",
      ...Array(6).fill(""),
      "おも",
      "",
      "だ",
      ...Array(7).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_2.jpeg`,
    sound: "start5_scene2",
    text: "本には「しつもんする」と書いてありました。\n次は「しつもんする」のワザの冒険のようで\nす。",
    ruby: [
      "ほん",
      ...Array(11).fill(""),
      "か",
      ...Array(9).fill(""),
      "つぎ",
      ...Array(13).fill(""),
      "ぼう",
      "けん",
      ...Array(7).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_3.jpeg`,
    sound: "start5_scene3",
    text: "本をゆっくり開いて……。\nさぁ、この先にはどんな冒険が待っているのでしょう！",
    ruby: [
      "ほん",
      ...Array(5).fill(""),
      "ひら",
      ...Array(11).fill(""),
      "さき",
      ...Array(5).fill(""),
      "ぼう",
      "けん",
      "",
      "ま",
      ...Array(10).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_4.jpeg`,
    sound: "start5_scene4",
    text: "そんなとき……「キラーン！」\nもしかして、これは……！",
    ruby: [...Array(27).fill("")],
  },
  {
    image: `${baseDir}/scene_5.jpeg`,
    sound: "start5_scene5",
    text: "星の渦につつまれて、オレンジ色の星が降りてきました。\n「つなげる」のスターです。",
    ruby: [
      "ほし",
      "",
      "うず",
      ...Array(11).fill(""),
      "いろ",
      "",
      "ほし",
      "",
      "お",
      ...Array(21).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_6.jpeg`,
    sound: "start5_scene6",
    text: "「す、すごい……！　きれい……\nみなさんは、これを集めて冒険をしているんですか……？」",
    ruby: [
      ...Array(25).fill(""),
      "あつ",
      ...Array(2).fill(""),
      "ぼう",
      "けん",
      ...Array(13).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_7.jpeg`,
    sound: "start5_scene7",
    text: "「そうさ！　これで図書館を助けるんだ！　ほら、さっそく次のスターを集めに行こうぜ！」\n「あ、あのぉ……うちも一緒に、行っていいんですか……？」",
    ruby: [
      ...Array(9).fill(""),
      "と",
      "しょ",
      "かん",
      "",
      "たす",
      ...Array(13).fill(""),
      "つぎ",
      ...Array(5).fill(""),
      "あつ",
      ...Array(2).fill(""),
      "い",
      ...Array(17).fill(""),
      "いっ",
      "しょ",
      ...Array(2).fill(""),
      "い",
      ...Array(12).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_8.jpeg`,
    sound: "start5_scene8",
    text: "「あったりめぇじゃん！　オイラたち、もう仲間だろ？　さっきだってモコひつじがロープを持ってたから、スターが手に入ったんだぜ！」",
    ruby: [
      ...Array(20).fill(""),
      "なか",
      "ま",
      ...Array(20).fill(""),
      "も",
      ...Array(10).fill(""),
      "て",
      "",
      "はい",
      ...Array(7).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_9.jpeg`,
    sound: "start5_scene9",
    text: "みんなで次のエレベーターの扉を開きます。\nスターの力で、どくしょかのしょは前よりももっと輝いています。",
    ruby: [
      ...Array(4).fill(""),
      "つぎ",
      ...Array(8).fill(""),
      "とびら",
      "",
      "ひら",
      ...Array(9).fill(""),
      "ちから",
      ...Array(11).fill(""),
      "まえ",
      ...Array(6).fill(""),
      "かがや",
      ...Array(6).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_10.jpeg`,
    sound: "start5_scene10_1",
    text: "エレベーターに乗ると、元気になってきている図書館の様子が窓から見えました。",
    ruby: [
      ...Array(7).fill(""),
      "の",
      ...Array(3).fill(""),
      "げん",
      "き",
      ...Array(8).fill(""),
      "と",
      "しょ",
      "かん",
      "",
      "よう",
      "す",
      "",
      "まど",
      ...Array(2).fill(""),
      "み",
      ...Array(5).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_10.jpeg`,
    sound: "start5_scene10_2",
    text: "「うわぁ、オイラたちこんなにたくさんの本たちを蘇らせているんだな！」\n「ペンダフル‼︎」",
    ruby: [
      ...Array(19).fill(""),
      "ほん",
      ...Array(3).fill(""),
      "よみがえ",
      ...Array(20).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_11.jpeg`,
    sound: "start5_scene11",
    text: "さぁ、次の階につきました。\n新しい階にはまた、一冊の本があります。",
    ruby: [
      ...Array(3).fill(""),
      "つぎ",
      "",
      "かい",
      ...Array(8).fill(""),
      "あたら",
      ...Array(2).fill(""),
      "かい",
      ...Array(5).fill(""),
      "いっ",
      "さつ",
      "",
      "ほん",
      ...Array(6).fill(""),
    ],
  },
];
