import React from "react";
import styled from "styled-components";
import { Text, TextProps } from "~/components/atoms/texts/Text";
import { Row } from "~/components/atoms/layout/Row";

type RecordSummaryHeaderProps = {
  user: {
    name: string;
    imageUrl: string;
  };
};

export const RecordSummaryHeader: React.FC<RecordSummaryHeaderProps> = ({
  user,
}) => {
  const baseTextProps: Pick<TextProps, "lineHeight" | "fontColor"> = {
    lineHeight: "MD",
    fontColor: "tex.t700",
  };

  return (
    <Header>
      <Row width="100%">
        <ImageWrap>
          <UserIcon src={user.imageUrl} />
        </ImageWrap>
        <Row alignItems="baseline" columnGap="0px" flexWrap="wrap">
          <Text {...baseTextProps} fontSize="MD" bold>
            {user.name}
          </Text>
          <Text {...baseTextProps} fontSize="XS" bold>
            さん
          </Text>
        </Row>
      </Row>
    </Header>
  );
};

const ImageWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
`;

const UserIcon = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;
