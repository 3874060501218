import { Size, defaultSize } from "./size";

type DeprecatedBorderRadius = "XS" | "S" | "SM" | "MD" | "LG";

export const deprecatedBorderRadius: Readonly<
  Record<DeprecatedBorderRadius, string>
> = {
  //pxで定義
  XS: "6px",
  S: "8px",
  SM: "12px",
  MD: "16px",
  LG: "22px",
};

type BorderRadius = Extract<Size, "half" | "s1" | "s1half" | "s2">;

export const borderRadius: Readonly<Record<BorderRadius, string>> = {
  half: defaultSize.half,
  s1: defaultSize.s1,
  s1half: defaultSize.s1half,
  s2: defaultSize.s2,
};
