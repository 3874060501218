import React from "react";
import styled from "styled-components";
import { Message } from "~/components/molecules/Message";
import { LibraryModalWrapper } from "../../ui/LibraryModalWrapper";
import { TranslateKeys, useTranslation } from "~/i18n";
import { YLEnqueteBackNavbar } from "../../ui/YLEnqueteBackNavbar";

export type WelcomeProps = {
  nickname: string;
  onNext: () => void;
  onQuit: () => void;
  isLastIndex: boolean;
  setIsLastIndex: (isLastIndex: boolean) => void;
};

const messageConfigs: TranslateKeys[] = [
  "こんにちは！\nおすすめジャンル診断へようこそ！",
  "これからヨンデミー先生が{{nickname}}さんに質問をするよ！\n自分に一番近いものを選んで答えてね😉",
  "すると、自分に近いヨンデミーキャラクターがわかるんだ！\nそのキャラクターのおすすめも、おすすめの本に入るようになるよ",
  "誰と近いタイプなのか、ワクワクするね！\nそれじゃあ早速始めるよ！",
];

export const Welcome: React.FC<WelcomeProps> = ({
  onNext,
  nickname,
  onQuit,
  isLastIndex,
  setIsLastIndex,
}) => {
  const { t } = useTranslation();
  const [messageIndex, setMessageIndex] = React.useState<number>(0);

  React.useEffect(() => {
    if (isLastIndex) {
      setMessageIndex(messageConfigs.length - 1);
    }
  }, [isLastIndex]);

  const handleNext = React.useCallback(() => {
    if (messageIndex >= messageConfigs.length - 1) {
      setIsLastIndex(false);
      onNext();
    } else {
      setMessageIndex((i) => i + 1);
    }
  }, [onNext, messageIndex, setIsLastIndex]);

  const handleBack = React.useCallback(() => {
    if (messageIndex === 0) {
      onQuit();
    } else {
      setMessageIndex((i) => i - 1);
    }
  }, [onQuit, messageIndex]);

  return (
    <>
      <YLEnqueteBackNavbar
        withBlur={true}
        onQuitButton={onQuit}
        onBackButton={handleBack}
      />
      <LibraryModalWrapper
        isBright={true}
        showSensei={true}
        senseiImageType={"NORMAL"}
      >
        <_MessageWrapper>
          <Message
            message={t<TranslateKeys>(messageConfigs[messageIndex], {
              nickname,
            })}
            speaker={t<TranslateKeys>("ヨンデミー先生")}
            onNext={handleNext}
          />
        </_MessageWrapper>
      </LibraryModalWrapper>
    </>
  );
};

const _MessageWrapper = styled.div`
  position: absolute;
  bottom: 15%;
  width: 100%;

  display: flex;
  justify-content: center;
`;
