import React, { VFC } from "react";
import styled from "styled-components";
import { BackNavbar } from "~/components/molecules/navbars/BackNavbar";
import { BadgeList } from "~/components/organisms/MyRecord/BadgeList";
import { GetMyRecordQuery } from "~/generated/graphql";
import { NavContainer, Wrapper } from "../Common";

type MyRecordTemplateProps = {
  data: GetMyRecordQuery;
  handleBack: () => void;
};

export const MyRecordTemplate: VFC<MyRecordTemplateProps> = ({
  data,
  handleBack,
}) => {
  return (
    <>
      <NavContainer>
        <BackNavbar titleName={"バッジ"} onClick={handleBack} />
      </NavContainer>
      <Wrapper>
        <TopWrapper>
          <BadgeList data={data} />
        </TopWrapper>
      </Wrapper>
    </>
  );
};

const TopWrapper = styled.div`
  padding: 74px 0 0 0;
`;
