import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { config } from "../../config";
import { getClarityURL } from "./clarity";
import { v4 as uuidv4 } from "uuid";

export const sentryErrorTrackingID = uuidv4();

if (config.SENTRY_DSN) {
  Sentry.init({
    debug: false,
    environment: config.ENV,
    dsn: config.SENTRY_DSN,
    // release: env("SENTRY_RELEASE_VERSION"),
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: config.ENV === "production" ? 0.1 : 1.0,
    ignoreErrors: [
      "ResizeObserver loop completed with undelivered notifications.",
    ],
    beforeSend: (event) => {
      if (event.exception?.values) {
        for (const value of event.exception.values) {
          if (value.value?.includes("No error message")) {
            return null;
          }
        }
      }
      return event;
    },
  });

  Sentry.setTag("errorTrackingID", sentryErrorTrackingID);
  Sentry.setExtra("clarityURL", getClarityURL(sentryErrorTrackingID));
}

const originalConsoleError = console.error;

console.error = (...args) => {
  const error = args.find((arg) => arg instanceof Error);
  if (error) {
    Sentry.captureException(error);
  } else {
    Sentry.captureMessage(args.join(" "));
  }
  originalConsoleError.apply(console, args);
};
