import React, { VFC, Fragment, ReactNode } from "react";
import styled from "styled-components";
import {
  MypageListItemProps,
  MypageListItem,
} from "../../listItems/MypageListItem";
import { Text } from "~/components/atoms/texts/Text";

type NodeItem = { node: ReactNode };
type Item = Omit<MypageListItemProps, "isFirst" | "isLast"> | NodeItem;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isNodeItem(item: any): item is NodeItem {
  return item.node ? true : false;
}

type MypageProps = {
  title?: string;
  items: Item[];
};

export const MypageList: VFC<MypageProps> = ({ title, items }) => {
  return (
    <div>
      {title && (
        <ListIitle fontSize="MD" lineHeight="MD">
          {title}
        </ListIitle>
      )}
      <div>
        {items.map((item, index) => {
          return (
            <Fragment key={`item_${index}`}>
              {isNodeItem(item) ? (
                <_NodeItemWrapper
                  isFirst={index === 0}
                  isLast={index === items.length - 1}
                >
                  {item.node}
                </_NodeItemWrapper>
              ) : (
                <MypageListItem
                  {...item}
                  isFirst={index === 0}
                  isLast={index === items.length - 1}
                />
              )}
              {index !== items.length - 1 && (
                <DividerWrapper>
                  <Divider />
                </DividerWrapper>
              )}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

const ListIitle = styled(Text)`
  color: ${({ theme }) => theme.colors.tex.t300};
  font-size: 12px;
  margin-bottom: 8px;
`;

const DividerWrapper = styled.div`
  height: 2px;
  background-color: ${({ theme }) => theme.colors.base.white};
`;

const Divider = styled.div`
  background-color: ${({ theme }) => theme.colors.base.ashGray};
  width: 98%;
  height: 2px;
  margin: 0 auto;
`;

const _NodeItemWrapper = styled.div<{ isFirst?: boolean; isLast?: boolean }>`
  padding: 1px 6px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.base.white};
  border-radius: ${({
    theme: {
      deprecatedBorderRadius: { MD },
    },
    isFirst,
    isLast,
  }) => {
    if (isFirst && isLast) {
      return `${MD} ${MD} ${MD} ${MD}`;
    } else if (isFirst && !isLast) {
      return `${MD} ${MD} 0 0`;
    } else if (!isFirst && isLast) {
      return `0 0 ${MD} ${MD}`;
    } else {
      return "none";
    }
  }};
`;
