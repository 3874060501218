import * as React from "react";
import { usePostReadLogState } from "../usePostReadLogState";
import { PraiseReadingStreakModal } from "~/components/templates/PostReadLog/PraiseReadingStreakModal";
import { GetMyRecordQuery } from "~/generated/graphql";
export const IndicatePraiseReadingStreak: React.FC = () => {
  const { state, dispatch } = usePostReadLogState();
  const newContinueReadLogDays =
    state.postReadLogResult?.account.general.continueReadLogDays;

  const tmp = React.useMemo(() => {
    try {
      return localStorage.getItem("TemporarilyStoredReadingRecords");
    } catch (e) {
      console.error(e);
      return null;
    }
  }, []);
  const myOldRecords: GetMyRecordQuery | null = React.useMemo(() => {
    return tmp ? JSON.parse(tmp) : null;
  }, [tmp]);
  const oldContinueReadLogDays = myOldRecords?.me.general.continueReadLogDays;

  const handleNext = React.useCallback(() => {
    dispatch({
      type: "setFlow",
      payload: "indicateBadge",
    });
  }, [dispatch]);

  if (newContinueReadLogDays === undefined) {
    handleNext();
    return <></>;
  } else {
    return (
      <PraiseReadingStreakModal
        newContinueReadLogDays={newContinueReadLogDays}
        oldContinueReadLogDays={oldContinueReadLogDays}
        onClose={handleNext}
      ></PraiseReadingStreakModal>
    );
  }
};
