import * as React from "react";
import { useNavigate } from "react-router-dom";
import { config } from "~/../config";
import {
  useGetNowRecommendQuery,
  useGetWantReadBooksQuery,
} from "~/generated/graphql";
import { SelectBookTemplate } from "~/components/templates/PostReadLog/SelectBook";
import { LoadingPage } from "~/components/templates/Loading/LoadingPage";
import { usePostReadLogState } from "../usePostReadLogState";
import { BookImgButtonListItemProps } from "~/components/organisms/PostReadLog/SelectBookImgList/type";

export const SelectBook: React.FC = () => {
  const [begin, setBegin] = React.useState<number>(0);
  const [recommendBegin, setRecommendBegin] = React.useState<number>(0);
  const [wantReadBookBegin, setWantReadBookBegin] = React.useState<number>(0);
  const {
    loading: recommendLoading,
    error: recommendError,
    data: recommendData,
  } = useGetNowRecommendQuery({
    variables: { begin: recommendBegin, end: begin + config.LIST_LENGTH },
    fetchPolicy: "network-only",
  });

  const {
    loading: wantReadBookLoading,
    error: wantReadBookError,
    data: wantReadBookData,
  } = useGetWantReadBooksQuery({
    variables: { begin: wantReadBookBegin, end: begin + config.LIST_LENGTH },
    fetchPolicy: "network-only",
  });

  const { dispatch } = usePostReadLogState();

  const navigate = useNavigate();

  const handleBack = (): void => {
    navigate("/");
  };

  const handleClickRecommendBookButton = (
    event: React.MouseEvent,
    recommendId: number,
    isbn: string,
    title: string
  ): void => {
    event.preventDefault();
    dispatch({ type: "setRecommendID", payload: recommendId });
    dispatch({ type: "setBookISBN", payload: isbn });
    dispatch({ type: "setTitle", payload: title });
    dispatch({ type: "setBookImgItemType", payload: "RECOMMEND" });
    dispatch({ type: "setFlow", payload: "confirmReadStatus" });
  };

  const handleClickWantReadBookButton = (
    event: React.MouseEvent,
    isbn: string,
    title: string
  ): void => {
    event.preventDefault();
    dispatch({ type: "setRecommendID", payload: null });
    dispatch({ type: "setBookISBN", payload: isbn });
    dispatch({ type: "setTitle", payload: title });
    dispatch({ type: "setBookImgItemType", payload: "WANT_READ_BOOK" });
    dispatch({ type: "setFlow", payload: "confirmReadStatus" });
  };

  const handleClickOtherBooks = (event: React.MouseEvent) => {
    event.preventDefault();
    dispatch({ type: "setRecommendID", payload: null });
    dispatch({ type: "setBookISBN", payload: "" });
    dispatch({ type: "setTitle", payload: "" });
    dispatch({ type: "setBookImgItemType", payload: "OTHER" });
    dispatch({
      type: "setFlow",
      payload: "enterUnregisteredBookIsbn",
    });
  };

  const items: BookImgButtonListItemProps[] = React.useMemo(() => {
    const recommendItems: BookImgButtonListItemProps[] = recommendData
      ? recommendData.me.recommends.get.map((recommend) => {
          const { id, book } = recommend;
          return {
            recommendId: id,
            isbn: book.isbn,
            title: book.title || "",
            imageUrl: book.imageUrl,
            isRead: false,
            columnsNumber: 3,
            bookImgItemType: "RECOMMEND",
          };
        })
      : [];

    const wantReadBookItems: BookImgButtonListItemProps[] = wantReadBookData
      ? wantReadBookData.getWantReadBooks.get.map((item) => {
          return {
            recommendId: null as unknown as number,
            isbn: item.book.isbn,
            title: item.book.title || "",
            imageUrl: item.book.imageUrl,
            isRead: false,
            columnsNumber: 3,
            bookImgItemType: "WANT_READ_BOOK",
          };
        })
      : [];

    const otherBookItem: BookImgButtonListItemProps = {
      recommendId: null as unknown as number,
      isbn: null as unknown as string,
      title: null as unknown as string,
      imageUrl: null as unknown as string,
      columnsNumber: 3,
      bookImgItemType: "OTHER",
    };

    return [otherBookItem, ...recommendItems, ...wantReadBookItems].slice(
      0,
      config.LIST_LENGTH + 1
    );
  }, [recommendData, wantReadBookData]);

  const onPageChange = React.useCallback(
    (count: number) => {
      setBegin(count);
      if (recommendData && count > recommendData.me.recommends.count) {
        setRecommendBegin(recommendData.me.recommends.count);
        setWantReadBookBegin(count - recommendData.me.recommends.count);
        console.log(wantReadBookBegin);
      } else {
        setRecommendBegin(count);
        setWantReadBookBegin(0);
      }
    },
    [recommendData, wantReadBookBegin]
  );

  if (
    recommendLoading ||
    wantReadBookLoading ||
    !recommendData ||
    !wantReadBookData
  ) {
    return <LoadingPage />;
  }
  if (recommendError) return <>Error! {recommendError.message}</>;
  if (wantReadBookError) return <>Error! {wantReadBookError.message}</>;
  return (
    <SelectBookTemplate
      handleBack={handleBack}
      currentCount={begin}
      recommendData={recommendData}
      wantReadBookData={wantReadBookData}
      itemTotal={
        recommendData.me.recommends.count +
        wantReadBookData.getWantReadBooks.count
      }
      itemsPerPage={config.LIST_LENGTH}
      onPageChange={onPageChange}
      renderEmptyComponent={EmptyComponent}
      onClickRecommend={handleClickRecommendBookButton}
      onClickWantReadBook={handleClickWantReadBookButton}
      onClickOtherBooks={handleClickOtherBooks}
      items={items}
    />
  );
};

const EmptyComponent = (): JSX.Element => {
  return <></>;
};
