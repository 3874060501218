import styled, { css } from "styled-components";

//z-index, position: fixed, top: 0の設定はTemplateで逐一行う
export const BaseNavbar = styled.div`
  ${({ theme }) => {
    return css`
      background: ${theme.colors.base.white};
      width: 100%;
    `;
  }}
`;