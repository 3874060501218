import styled from "styled-components";
import { Text } from "~/components/atoms/texts/Text";
import { BaseModal } from "~/components/atoms/wrappers/BaseModal";
import { Stack } from "~/components/atoms/layout/Stack";
import { LargeButton } from "~/components/atoms/buttons/Button";
import React from "react";
import { UserIconType } from "~/generated/graphql";
import { userIconProfile } from "~/components/templates/EditProfile/const";
import { userIconImage } from "~/components/organisms/adminMessage/AdminChatMessage";
import { useAudio } from "~/utils/hooks/useAudio";
import { Icon } from "~/components/atoms/Icon";
import Confetti from "react-confetti";
import { useTranslation } from "~/i18n";

type UnlockUserIconModalProps = {
  nickname: string | undefined;
  onNext: () => void;
  unlockedIcons: UserIconType[];
};

export const UnlockUserIconModal: React.FC<UnlockUserIconModalProps> = ({
  nickname,
  onNext,
  unlockedIcons,
}) => {
  const [currentIconIndex, setCurrentIconIndex] = React.useState(0);
  const [showModal, setShowModal] = React.useState(true);

  const { t } = useTranslation();
  const { play } = useAudio({ preload: ["fanfare"] });

  React.useEffect(() => {
    play("fanfare");
  }, [currentIconIndex]);

  const handleButtonClick = () => {
    setShowModal(false);
    if (currentIconIndex < unlockedIcons.length - 1) {
      setTimeout(() => {
        setCurrentIconIndex((prevIndex) => prevIndex + 1);
        setShowModal(true);
      }, 300);
    } else {
      onNext();
    }
  };

  const currentIcon = unlockedIcons[currentIconIndex];

  return (
    <>
      {showModal && (
        <>
          <BaseModal open={true}>
            <_Container>
              <Stack rowGap="8px">
                <Text
                  fontSize="MD"
                  lineHeight="EQ"
                  fontColor="primitive.tex.t400"
                >
                  {nickname}さん、おめでとう👏
                </Text>
                <Text
                  fontSize="LG"
                  lineHeight="MD"
                  fontColor="primitive.tex.t700"
                  bold
                >
                  {t<TranslateKeys>(userIconProfile[currentIcon].name)}
                  {t<TranslateKeys>("のアイコンが使えるようになったよ🎉")}
                </Text>
              </Stack>
              <_UserIcon src={userIconImage[currentIcon]} />
              <LargeButton
                onClick={handleButtonClick}
                rightIcon={<Icon name="arrowRight" color="white" />}
              >
                すすむ
              </LargeButton>
            </_Container>
          </BaseModal>
          <Confetti width={window.innerWidth} height={window.innerHeight} />
        </>
      )}
    </>
  );
};

const _Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  max-width: 335px;
`;

const _UserIcon = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 50%;
`;
