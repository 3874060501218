import React from "react";
import styled, { css } from "styled-components";
import { Text } from "~/components/atoms/texts/Text";
import { writeReadLogImages } from "~/pages/InitialTutorial/constants";
import {
  CONTENT_WIDTH,
  CONTENT_HEIGHT,
  useScale,
} from "../../../../../utils/hooks/useScale";

export type WriteReadLogProps = {
  onNext: (e: React.MouseEvent) => void;
};

export const WriteReadLog: React.FC<WriteReadLogProps> = ({ onNext }) => {
  const scale = useScale();
  return (
    <_Wrapper onClick={onNext}>
      <_Container scale={scale} imageSrc={writeReadLogImages.writeReadLog}>
        <_ContentWrapper>
          <BallonCon imageSrc={writeReadLogImages.balloonTail}>
            <_MessageWrapper>
              <_MessageText
                bold
                fontSize="MD"
                lineHeight="MD"
                fontColor="tex.t700"
                whiteSpace="pre-wrap"
              >
                ほんをよんで、
                <br />
                かんそうをかくと……
              </_MessageText>
              <_Triangle />
            </_MessageWrapper>
            <div className="ballon-tail"></div>
          </BallonCon>
          <img src={writeReadLogImages.standUpNormal} />
        </_ContentWrapper>
      </_Container>
    </_Wrapper>
  );
};

const _Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  max-height: 100svh;
  background-color: #ae6030;
  cursor: pointer;

  display: flex;
  justify-content: center;
`;

const _ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  row-gap: 16px;
  margin-bottom: calc(50vh - 150px);
  padding: 12px;
`;

const _Container = styled.div<{ scale: number; imageSrc: string }>`
  @media screen and (max-width: 800px) {
    width: 100%;
    height: 100%;
    margin-right: auto;
  }
  @media screen and (min-width: 800px) {
    width: ${CONTENT_WIDTH}px;
    height: ${CONTENT_HEIGHT}px;
    margin: 0 auto;
    ${({ scale }) => {
      return css`
        transform: scale(${scale});
      `;
    }}
    transform-origin: top center;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  background-image: url(${({ imageSrc }) => imageSrc});
  background-repeat: no-repeat;
  background-size: contain;
`;

const _MessageWrapper = styled.div`
  display: flex;
  column-gap: 8px;
  justify-content: space-around;
  align-items: center;

  border-radius: ${({ theme }) => theme.deprecatedBorderRadius.MD};
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.base.white};
`;

const _Triangle: React.FC = () => (
  <svg
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.1564 7.28687C16.447 8.06437 16.447 9.93563 15.1564 10.7131L3.78207 17.5656C2.44909 18.3687 0.749997 17.4087 0.749997 15.8525L0.749998 2.14751C0.749998 0.591308 2.44909 -0.368683 3.78208 0.434377L15.1564 7.28687Z"
      fill="#F3AE36"
    />
  </svg>
);

const _MessageText = styled(Text)`
  font-family: "M PLUS 1 Code";
  line-height: 160%;
`;

const BallonCon = styled.div<{ imageSrc: string }>`
  display: flex;
  flex-direction: column;

  .ballon-tail {
    transform: translateY(-1px);
    width: 100%;
    height: 12px;
    background-image: url(${({ imageSrc }) => imageSrc});
    background-position: bottom right 10%;
    background-repeat: no-repeat;
  }
`;
