import { useReactiveVar } from "@apollo/client";
import React from "react";
import { Virtuoso, VirtuosoHandle } from "react-virtuoso";
import styled from "styled-components";
import { Loading } from "~/components/atoms/Loading";
import {
  lastAnsweredReactiveVar,
  lastMessageSentReactiveVar,
} from "~/store/message";
import { ListItemProps, MessageListProps } from "./types";
export type { ListItemProps };

export const MessageList: React.FC<MessageListProps> = ({
  items,
  onPrevLoading,
  onNextLoading,
  renderListItem,
  fetchAll,
  nextFetchAll,
  className,
  firstItemIndex,
  isScrolling,
}) => {
  const renderLoading = (isFetch) =>
    !isFetch ? (
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    ) : (
      <></>
    );

  const _onPrevLoading = () => {
    onPrevLoading(items[0]);
  };

  const _onNextLoading = () => {
    onNextLoading && onNextLoading(items[items.length - 1]);
  };

  React.useEffect(() => {
    return () => {
      // unmountするときにリセット
      lastAnsweredReactiveVar(0);
      lastMessageSentReactiveVar(0);
    };
  });

  const lastAnswered = useReactiveVar(lastAnsweredReactiveVar);
  const lastMessageSent = useReactiveVar(lastMessageSentReactiveVar);

  const ref = React.createRef<VirtuosoHandle>();

  React.useEffect(() => {
    if (lastAnswered) {
      const toIndex = firstItemIndex + items.length - 1;
      ref.current?.scrollToIndex({
        index: toIndex,
        offset: 80,
        align: "end",
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastAnswered]);

  React.useEffect(() => {
    if (lastMessageSent) {
      const toIndex = firstItemIndex + items.length - 1;
      ref.current?.scrollToIndex({
        index: toIndex,
        behavior: "smooth",
        align: "end",
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMessageSent]);

  if (items.length === 0) {
    return null;
  }

  return (
    <Virtuoso
      ref={ref}
      className={className}
      data={items}
      firstItemIndex={firstItemIndex}
      initialTopMostItemIndex={getInitialTopMostItemIndex(items)}
      startReached={_onPrevLoading}
      endReached={_onNextLoading}
      isScrolling={isScrolling}
      itemContent={(index, item) => {
        return renderListItem({ index, item });
      }}
      components={{
        Header: () => renderLoading(fetchAll),
        Footer: () => {
          return onNextLoading ? renderLoading(nextFetchAll) : null;
        },
      }}
    />
  );
};

// 未読アイテムが一番上に来るようにする
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getInitialTopMostItemIndex = (items: any[]) => {
  const unreadIndex = items.findIndex((item) => item.firstUnreadItem);
  if (unreadIndex > 0) {
    return unreadIndex;
  }
  return items.length - 1;
};

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
`;
