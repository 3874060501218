import "styled-components";
import {
  borderRadius,
  deprecatedBorderRadius,
} from "./design_token/border_radius";
import { defaultFontLineHight, defaultFontSize } from "./design_token/font";
import { defaultZIndex } from "./design_token/zindex";
import { defaultColors } from "./design_token/color";
import { defaultSize } from "./design_token/size";
import {
  defaultScreenSize,
  defaultBreakPoints,
} from "./design_token/screen_size";

export const theme = {
  //deprecated
  orange: "#F29C06",
  paleOrange: "#F5B038",
  semiPaleOrange: "#F3A61F",
  disabledPaleOrange: "#F9D088",
  disabledSemiPaleOrange: "#F7C46A",
  lightGray: "#EFEDEB",
  gray: "#D5D2CD",
  darkGray: "#7A7876",
  dark: "#504E4B",
  errorred: "#BC1F1F",
  notification: "#FF4E4E",
  dangerred: "#CE0914",
  paleDangerred: "#EB5757",
  disabledPaleRed: "#F6B3B3",

  //以下を使う
  colors: defaultColors,
  zIndex: defaultZIndex,
  fontSize: defaultFontSize,
  lineHeight: defaultFontLineHight,
  deprecatedBorderRadius: deprecatedBorderRadius,
  borderRadius: borderRadius,
  size: defaultSize,
  screenSize: defaultScreenSize,
  breakPoints: defaultBreakPoints,
} as const;

type AppTheme = typeof theme;

declare module "styled-components" {
  interface DefaultTheme extends AppTheme {}  
}
