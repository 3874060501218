import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import { Text } from "~/components/atoms/texts/Text";
import { BaseModal } from "~/components/atoms/wrappers/BaseModal";
import { Stack } from "~/components/atoms/layout/Stack";
import { PrimaryButton } from "~/components/molecules/buttons/PrimaryButton";
import { getNewPraiseSentence } from "./hook";
import { useTranslation } from "~/i18n";
import { useAudio } from "~/utils/hooks/useAudio";

export type PraiseReadingStreakModalProps = {
  oldContinueReadLogDays: number | undefined;
  newContinueReadLogDays: number;
  onClose: () => void;
};

export const PraiseReadingStreakModal: React.FC<
  PraiseReadingStreakModalProps
> = ({ oldContinueReadLogDays, newContinueReadLogDays, onClose }) => {
  const { t } = useTranslation();
  const { currentUser } = React.useContext(CurrentUserContext);
  const newPraiseSentence = getNewPraiseSentence(
    oldContinueReadLogDays,
    newContinueReadLogDays
  );
  const nickname = currentUser?.general.nickname;
  const hasPraiseSentence = newPraiseSentence !== undefined;
  const [isOpen, setIsOpen] = useState(hasPraiseSentence);
  const { play } = useAudio({ preload: ["fanfare"] });

  const closeModal = (): void => {
    setIsOpen(false);
    onClose();
  };

  useEffect(() => {
    if (!hasPraiseSentence) {
      closeModal();
    } else {
      play("fanfare");
    }
  }, [hasPraiseSentence]);

  return (
    <BaseModal open={isOpen} onClose={onClose}>
      <Stack alignItems="center" rowGap="16px">
        <div
          style={{
            fontFamily: "Roboto",
            fontSize: "80px",
            fontWeight: 700,
            lineHeight: "80px",
            textAlign: "center",
            color: "#EE9F17",
          }}
        >
          {newContinueReadLogDays}
          <Text bold={false} fontSize="LG" lineHeight="MD" fontColor="pri.p500">
            {t<TranslateKeys>("日連続")}
          </Text>
        </div>

        <div style={{ whiteSpace: "pre-wrap" }}>
          {newPraiseSentence && (
            <Text
              bold={false}
              fontSize="MD"
              lineHeight="MD"
              fontColor="tex.t500"
            >
              {t<TranslateKeys>(newPraiseSentence, { user: nickname })}
            </Text>
          )}
        </div>
        <PrimaryButton
          onClick={onClose}
          color={"pri"}
          text={"やったー"}
          fontSize={"XL"}
          lineHeight={"EQ"}
          bold
        />
        <PrimaryButton
          onClick={onClose}
          color={"pri"}
          text={"えっへん"}
          fontSize={"XL"}
          lineHeight={"EQ"}
          bold
        />
      </Stack>
    </BaseModal>
  );
};
