import React, { useLayoutEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "~/i18n";
import styled from "styled-components";
import {
  BookItemContainerInterpolation,
  BookCoverContainer,
} from "../Common/style";

type OtherBookImgButtonListItemProps = {
  /*
    1行に何個表示するかどうか
  */
  columnsNumber: number;
  /**
   * 本のボタンをクリックした時に発火する関数
   */
  onClick: (event: React.MouseEvent) => void;
};

export const OtherBookImgButtonListItem: React.FC<
  OtherBookImgButtonListItemProps
> = ({ columnsNumber, onClick }) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  console.log("width:", width);
  const fontSize: string = useMemo(() => {
    return `${width / 8}px`;
  }, [width]);

  useLayoutEffect(() => {
    let requestId: number;
    const obs = new ResizeObserver(
      (e) =>
        (requestId = requestAnimationFrame(() =>
          setWidth(e[0].contentRect.width)
        ))
    );
    obs.observe(ref.current as HTMLDivElement);
    return () => {
      obs.disconnect();
      cancelAnimationFrame(requestId);
    };
  }, []);

  return (
    <>
      <BookItemContainer onClick={onClick} columnsNumber={columnsNumber}>
        <BookCoverContainer>
          <_OtherBookWrapper>
            <_TextWrapper style={{ fontSize }} ref={ref}>
              {t<TranslateKeys>("自分で\n選んだ本の\n感想を送る")}
            </_TextWrapper>

            <_plusButtonSvg
              viewBox="0 0 72 72"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="3.05176e-05"
                width="72"
                height="72"
                rx="36"
                fill="#FDC561"
              />
              <rect
                x="3.05176e-05"
                width="72"
                height="72"
                rx="36"
                fill="white"
                fillOpacity="0.64"
              />
              <path
                d="M53.3334 33.1845L38.8572 33.1845L38.8572 18.8155C38.8572 17.2621 37.6191 16 36.0953 16C34.5715 16 33.3334 17.2621 33.3334 18.8155L33.3334 33.1845L18.7619 33.1845C17.2381 33.1845 16 34.4466 16 36C16 37.5534 17.2381 38.8155 18.7619 38.8155L33.2381 38.8155L33.2381 53.1845C33.2381 54.7379 34.4762 56 36 56C37.5238 56 38.7619 54.7379 38.7619 53.1845L38.7619 38.9126L53.2381 38.9126C54.7619 38.9126 56 37.6505 56 36.0971C56 34.5437 54.9524 33.1845 53.3334 33.1845Z"
                fill="#F3AE36"
              />
            </_plusButtonSvg>
          </_OtherBookWrapper>
        </BookCoverContainer>
      </BookItemContainer>
    </>
  );
};

const BookItemContainer = styled.button<
  Pick<OtherBookImgButtonListItemProps, "columnsNumber">
>`
  background: none;
  padding: 0;
  border: none;
  ${BookItemContainerInterpolation}
`;

const _OtherBookWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.pri.p400};
  padding: calc(14%) calc(10%);
  border-radius: 10px;
  border: 1px dashed ${({ theme }) => theme.colors.pri.p500};
`;

const _TextWrapper = styled.p`
  width: 100%;
  text-align: left;
  color: ${({ theme }) => theme.colors.base.white};
  font-weight: 700;
  line-height: 160%;
  white-space: pre-line;
`;

const _plusButtonSvg = styled.svg`
  width: 70%;
  height: auto;
  margin: 0 auto;
  object-fit: contain;
`;
