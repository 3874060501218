import { TranslateKeys } from "~/i18n";

const basePath = "/img/character/";

type Characters =
  | "sensei"
  | "bookWarm"
  | "dinosaur"
  | "apple"
  | "penguin"
  | "lion"
  | "mokoSheep"
  | "wolf"
  | "giraffe"
  | "principalTree";

type CharacterDetail = {
  name: TranslateKeys;
  description: TranslateKeys;
  imageUrl: string;
  width: number;
};

export const CharacterIntroductionList: Record<Characters, CharacterDetail> = {
  sensei: {
    name: "ヨンデミー先生",
    description:
      "本が大好きなAIのせんせい。\n図書館の司書をしていて、読書家のみんなに、ぴったりの本をおすすめすることがとくい。\nあたまに本を乗せているので、水がにがて。",
    imageUrl: "/img/sensei/standup_1.svg",
    width: 69,
  },
  bookWarm: {
    name: "本の虫",
    description:
      "さいしょは本を食べてばかりいたが、ヨンデミーで本を読むことをおぼえたようだ。",
    imageUrl: basePath + "book_warm.svg",
    width: 42,
  },
  dinosaur: {
    name: "太古のきょうりゅう",
    description:
      "ながいねむりから目が覚めたあと、ヨンデミーのおすすめ本で自分の仲間がぜつめつしたことを知った。",
    imageUrl: basePath + "dinosaur.svg",
    width: 75,
  },
  apple: {
    name: "りんごちゃん",
    description:
      "その昔、人間にちしきをさずけてくれた、伝説のりんご。\n実は、どくりんごである。",
    imageUrl: basePath + "apple.svg",
    width: 43,
  },
  penguin: {
    name: "ペンギンせんぱい",
    description:
      "ふだんは、なんきょくに住んでいる。冬にはむれで、おんどく会をするらしい。",
    imageUrl: basePath + "penguin.svg",
    width: 61,
  },
  lion: {
    name: "ベジタライオン",
    description:
      "本をよんで、なぜ自分はほかの動物をたべるのかがわからなくなり、草食になった。",
    imageUrl: basePath + "lion.svg",
    width: 81,
  },
  mokoSheep: {
    name: "モコひつじ",
    description:
      "本を読んで想像力を膨らませるとモコモコが膨らむ。眠れないときに数えるひつじの中に混ざっているらしい。",
    imageUrl: basePath + "moko_sheep.svg",
    width: 75,
  },
  wolf: {
    name: "オオカミスミス",
    description:
      "紙職人の家に生まれたが、家出した一匹オオカミ。オオカミがお話の中でワルモノになりがちなことを実は少し気にしている。",
    imageUrl: basePath + "wolf.svg",
    width: 67,
  },
  giraffe: {
    name: "よみキリン",
    description:
      "生まれつきピンクのキリン。長い首を伸ばして長い本を読みきるのが得意なようだ。",
    imageUrl: basePath + "giraffe.svg",
    width: 76,
  },
  principalTree: {
    name: "校長先生",
    description:
      "じゅれいは3000年を超える。世界ではじめてしゅっぱんされた本は、校長先生のえだからつくられたという。",
    imageUrl: basePath + "principal_tree.svg",
    width: 95,
  },
};
