import React from "react";
import { useUnlock } from "~/store/unlock/useUnlock";
import styled from "styled-components";
import { UserType } from "~/components/organisms/bookFriendMessage/BookFriendChatMessage";

type BfLockWrapperProps = {
  children: React.ReactNode;
  userType: UserType;
};

const bookWarmKeyUrl = "/img/unlock/book_worm_key.svg";
const chainUrl = "/img/unlock/bf_chain.svg";

export const BfLockWrapper: React.FC<BfLockWrapperProps> = ({
  children,
  userType,
}) => {
  const { isLocked } = useUnlock();

  if (userType === "me" || !isLocked("bookFriend")) {
    return <>{children}</>;
  } else {
    return (
      <_Container>
        <_ChatBubble>{children}</_ChatBubble>
        <_Lock />
        <_CommentWrapper>
          <_Comment>
            <span>レベル11 </span>になったら
            <br />
            カギがあくみたい......
          </_Comment>
        </_CommentWrapper>
        <_BookWarmKey src={bookWarmKeyUrl} />
      </_Container>
    );
  }
};

const _Container = styled.div`
  position: relative;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const _ChatBubble = styled.div`
  width: 100%;
  opacity: 0.2;
  filter: blur(3px);
`;

//NOTE: 吹き出しのpaddingに対応するため左/上側に10pxずつずらしている
const _Lock = styled.div`
  position: absolute;
  top: -10px;
  left: -10px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);

  background-image: url(${chainUrl});
  background-repeat: no-repeat;
  background-size: 1200px;
  background-position: center center;
`;

const _BookWarmKey = styled.img`
  position: absolute;
  top: calc(50% - 20px);

  width: 30%;
  object-fit: contain;
`;

const _CommentWrapper = styled.div`
  position: absolute;
  padding: 10px;
  top: calc(50% - 100px);

  background-color: ${({ theme }) => theme.colors.green.g400};
  border-radius: ${({ theme }) => theme.deprecatedBorderRadius.S};

  ::after {
    position: absolute;
    content: "";
    left: calc(50% - 8px);
    top: 98%;
    border-top: 12px solid ${({ theme }) => theme.colors.green.g400};
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
  }
`;

const _Comment = styled.p`
  color: ${({ theme }) => theme.colors.base.white};
  line-height: 160%;
  font-size: ${({ theme }) => theme.fontSize.XS};
  font-weight: bold;

  span {
    font-size: ${({ theme }) => theme.fontSize.MD};
  }
`;
