import React, { VFC } from "react";
import { TopNavbar as BaseTopNavbar } from "~/components/molecules/navbars/TopNavbar";
import { useTranslation } from "~/i18n";

export type TopNavbarProps = {
  haveUnreadInBfGroupMessage?: boolean;
  haveUnreadInMessage?: boolean;
  currentTab: number;
  onChangeTab: (page: number) => void;
};

export const TopNavbar: VFC<TopNavbarProps> = ({
  haveUnreadInBfGroupMessage,
  haveUnreadInMessage,
  currentTab,
  onChangeTab,
}) => {
  const { t } = useTranslation();

  return (
    <BaseTopNavbar
      tabItems={[
        {
          page: 0,
          title: t<TranslateKeys>("本の友"),
          hasNotification: haveUnreadInBfGroupMessage || false,
          iconImageSrc: "/img/top_nav_bar/icon/book_friends.svg",
        },
        {
          page: 1,
          title: t<TranslateKeys>("本棚"),
          hasNotification: false,
          iconImageSrc: "/img/top_nav_bar/icon/book_shelf.svg",
        },
        {
          page: 2,
          title: "ミニレッスン",
          hasNotification: haveUnreadInMessage || false,
          iconImageSrc: "/img/top_nav_bar/icon/mini_lesson.svg",
        },
      ]}
      currentTab={currentTab}
      onChangeTab={onChangeTab}
    />
  );
};
