import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Text } from "~/components/atoms/texts/Text";
import { ReadingTechniqueType } from "~/generated/graphql";
import { TranslateKeys } from "~/i18n";
import { readingTechniqueTypeStr } from "../SendStarModal/const";
import { ColorOfReadingTechnique } from "../SendStarModal/star";

export type SendStarMessageProps = {
  readingTechniqueType?: ReadingTechniqueType;
  onCompleted: () => void;
};

export const SendStarMessage: React.FC<SendStarMessageProps> = ({
  readingTechniqueType,
  onCompleted,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    const timeOutId = setTimeout(() => {
      onCompleted();
    }, 5000);

    return () => {
      setTimeout(() => {
        onCompleted();
      }, 5000);
      clearTimeout(timeOutId);
    };
  }, [onCompleted]);

  if (!readingTechniqueType) {
    return <></>;
  }

  return (
    <_Wrapper>
      <Text fontSize="XXS" lineHeight="MD">
        「{readingTechniqueTypeStr[readingTechniqueType]}」を
        <br />
        {t<TranslateKeys>("使って") + t<TranslateKeys>("読んで") + "みよう"}
      </Text>
      <_StarImg readingTechniqueType={readingTechniqueType} />
    </_Wrapper>
  );
};

const _Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.primitive.monotone.m0};
  border: 1px solid ${({ theme }) => theme.colors.semantic.layout.border};
  border-radius: 16px;
  width: 124px;

  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  opacity: 0;

  animation: 5s fadeUpAnime 0s ease-in-out;

  @keyframes fadeUpAnime {
    from {
      transform: translateY(10px);
    }
    10% {
      opacity: 1;
      transform: translateY(0);
    }
    90% {
      opacity: 1;
      transform: translateY(0);
    }

    to {
      opacity: 0;
      transform: translateY(-10px);
    }
  }
`;

type _StarImgProps = {
  readingTechniqueType: ReadingTechniqueType;
};

const _StarImg: React.FC<_StarImgProps> = ({ readingTechniqueType }) => (
  <svg
    width="42"
    height="47"
    viewBox="0 0 42 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.212 11.823L15.1435 20.0722L6.04069 21.3993C4.40829 21.636 3.75409 23.6485 4.93789 24.8011L11.5235 31.2186L9.96592 40.284C9.68554 41.9226 11.4114 43.15 12.8569 42.3837L21.0002 38.1033L29.1435 42.3837C30.5889 43.1438 32.3148 41.9226 32.0344 40.284L30.4768 31.2186L37.0624 24.8011C38.2462 23.6485 37.592 21.636 35.9596 21.3993L26.8569 20.0722L22.7883 11.823C22.0594 10.3526 19.9472 10.3339 19.212 11.823Z"
      fill={ColorOfReadingTechnique[readingTechniqueType]}
    />
    <path
      d="M1.70703 10.625L6.42108 15.339"
      stroke={ColorOfReadingTechnique[readingTechniqueType]}
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M34.9595 15.3391L39.6735 10.6251"
      stroke={ColorOfReadingTechnique[readingTechniqueType]}
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M20.6753 2L20.6668 8.66666"
      stroke={ColorOfReadingTechnique[readingTechniqueType]}
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
);
