import React, { isValidElement, ReactNode, useCallback } from "react";

type Options = {
  onClick: (event: React.MouseEvent) => void;
  label?: ReactNode;
};
export const useClickTracking = ({
  onClick,
  label,
}: Options): { onClickWithTracking: (event: React.MouseEvent) => void } => {
  const onClickWithTracking = useCallback(
    (event: React.MouseEvent) => {
      onClick(event);

      const _label = reactNodeToString(label);
      // console.log("onClickWithTracking", _label);
      sendButtonClickEvent(_label, undefined, event.clientX, event.clientY);
    },
    [onClick, label]
  );
  return { onClickWithTracking };
};

const reactNodeToString = (reactNode: React.ReactNode): string => {
  let string = "";
  if (typeof reactNode === "string") {
    string = reactNode;
  } else if (typeof reactNode === "number") {
    string = reactNode.toString();
  } else if (reactNode instanceof Array) {
    reactNode.forEach(function (child) {
      string += reactNodeToString(child);
    });
  } else if (isValidElement(reactNode)) {
    string += reactNodeToString(reactNode.props.children);
  }
  return string;
};

export const sendButtonClickEvent = (
  event_label?: string,
  userId?: string,
  clientX?: number,
  clientY?: number
): void => {
  if (window.gtag) {
    window.gtag("event", "button_click", {
      event_category: "button",
      event_label: event_label,
      pathname: window.location.pathname,
      userId: userId,
      clientX: clientX,
      clientY: clientY,
    });
  }
};
