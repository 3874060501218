import React from "react";
import styled, { css } from "styled-components";
import { MissionOrder } from "~/generated/graphql";
import { BaseModal } from "~/components/atoms/wrappers/modal";
import { Text } from "~/components/atoms/texts/Text";
import { PrimaryButton } from "~/components/molecules/buttons/PrimaryButton";
import { missionOrderToDisplayMaterial } from "..";
import { theme } from "~/styles/theme";
import { StoryType } from "~/components/organisms/modals/QuestLevel/MissionStory";
import { useTranslation } from "react-i18next";
import { Stack } from "~/components/atoms/layout/Stack";

type PlayMissionStoryModalProps = {
  missionOrder: MissionOrder;
  storyType: StoryType;
  onClick: (e: React.MouseEvent) => void;
  isError: boolean;
};

export const PlayMissionStoryModal: React.FC<PlayMissionStoryModalProps> = ({
  missionOrder,
  storyType,
  onClick,
  isError,
}: PlayMissionStoryModalProps) => {
  const displayMaterial = missionOrderToDisplayMaterial[missionOrder];

  const { t } = useTranslation();

  const imgUrl = displayMaterial.storyImgUrl;

  const buttonText =
    missionOrder === MissionOrder.Mission_1 && storyType === "Start"
      ? "はじめる"
      : "つづきをみる";

  return (
    <BaseModal isOpen={true}>
      <BallonCon>
        <Ballon>
          <_TitleWrapper>
            <Text lineHeight="EQ" fontSize="SM" bold={true}>
              ヨンデミーストーリー その{displayMaterial.number}
            </Text>
            <Text
              lineHeight="EQ"
              fontSize="XL"
              bold={true}
              fontColor="pri.p500"
            >
              {t<TranslateKeys>(displayMaterial.storyTitle)}
            </Text>
          </_TitleWrapper>
          <_Img src={imgUrl} />
        </Ballon>
        <_Stack rowGap="16px">
          <_NoticeBallon>
            <_VolumeIcon />
            <Text
              lineHeight="MD"
              fontSize="SM"
              bold={true}
              fontColor="tex.t300"
            >
              {t<TranslateKeys>("音が流れるから")}
              <br />
              {t<TranslateKeys>("音声をオンにしてね！")}
            </Text>
          </_NoticeBallon>
          <_ButtonWrapper>
            <PrimaryButton
              onClick={(e) => {
                onClick(e);
              }}
              color="pri"
              text={buttonText}
              lineHeight="EQ"
              fontSize="XL"
              bold={true}
              padding="16px"
            />
          </_ButtonWrapper>
        </_Stack>
      </BallonCon>
      {isError && (
        <_ErrorMessage>
          ネットワークエラーが発生しました。
          <br />
          お手数をおかけいたしますが再度お試しください。
        </_ErrorMessage>
      )}
    </BaseModal>
  );
};

const Ballon = styled.div`
  ${({ theme }) => {
    return css`
      display: flex;
      flex-direction: column;
      gap: 16px;

      background-color: ${theme.colors.base.white};
      border-radius: ${theme.deprecatedBorderRadius.MD};

      width: 100%;
      padding-top: 16px;
    `;
  }}
`;

const BallonCon = styled.div`
  ${({ theme }) => {
    return css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: 75px;

      width: 335px;
      height: 100%;
      margin: 0 auto;
      @media screen and ${theme.breakPoints.maxWidthSmallSmartPhone} {
        width: 90vw;
      }
    `;
  }}
`;

const _TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const _Img = styled.img`
  width: 100%;
  border-radius: 0px 0px 16px 16px;
`;

const _ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const _NoticeBallon = styled.div`
  ${({ theme }) => {
    return css`
      display: flex;
      flex-direction: row;
      gap: 10px;

      background-color: ${theme.colors.base.background};
      border-radius: ${theme.deprecatedBorderRadius.S};
      align-items: center;

      width: 70%;
      padding: 8px 16px;
    `;
  }}
`;

const _VolumeIcon: React.FC = () => (
  <svg
    width="20px"
    height="16.7px"
    viewBox="0 0 19 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.093 1.89905L4.15823 4.83316H0.791667C0.354271 4.83316 0 5.18743 0 5.62483V10.3748C0 10.8119 0.354271 11.1665 0.791667 11.1665H4.15823L7.093 14.1006C7.58878 14.5964 8.44444 14.2481 8.44444 13.5408V2.45882C8.44444 1.75094 7.58813 1.40392 7.093 1.89905ZM14.7893 0.214116C14.4209 -0.0276727 13.9257 0.0742545 13.684 0.443369C13.4418 0.811824 13.5448 1.30695 13.9132 1.54873C16.0992 2.9833 17.4038 5.39491 17.4038 8.00016C17.4038 10.6054 16.0992 13.017 13.9132 14.4516C13.5448 14.693 13.4418 15.1885 13.684 15.5566C13.9162 15.9099 14.4073 16.0369 14.7893 15.7859C17.4256 14.0554 19 11.1444 19 7.99983C19 4.85526 17.4256 1.94457 14.7893 0.214116ZM15.8333 7.99983C15.8333 5.90422 14.7758 3.9775 13.0041 2.84608C12.635 2.61056 12.1455 2.72007 11.9116 3.09215C11.6777 3.46424 11.7869 3.95672 12.156 4.19257C13.4672 5.03009 14.25 6.45311 14.25 7.99983C14.25 9.54655 13.4672 10.9696 12.156 11.8071C11.7869 12.0426 11.6777 12.5351 11.9116 12.9075C12.1264 13.2492 12.6083 13.4069 13.0041 13.1536C14.7758 12.0222 15.8333 10.0958 15.8333 7.99983ZM11.1569 5.46418C10.7749 5.25538 10.293 5.39293 10.0812 5.7759C9.87043 6.15887 10.01 6.64014 10.3929 6.85158C10.8188 7.08545 11.0833 7.52582 11.0833 7.99983C11.0833 8.47417 10.8188 8.9142 10.3933 9.14807C10.0103 9.35951 9.87076 9.84078 10.0815 10.2238C10.2936 10.6084 10.7759 10.7449 11.1572 10.5355C12.0884 10.0225 12.667 9.05109 12.667 7.9995C12.667 6.9479 12.0884 5.97679 11.1569 5.46418Z"
      fill={theme.colors.tex.t300}
    />
  </svg>
);

const _ErrorMessage = styled.div`
  position: relative;

  font-weight: 400;
  color: ${({ theme }) => theme.colors.red.r200};
  font-size: ${({ theme }) => theme.fontSize.XS};
  line-height: 160%;

  white-space: pre-wrap;
`;

const _Stack = styled(Stack)`
  width: 100%;
  align-items: center;
`;
