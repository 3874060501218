import React from "react";
import { useUnlock } from "~/store/unlock/useUnlock";

type ReceivedReactionLockWrapperProps = {
  children: React.ReactNode;
};

export const ReceivedReactionLockWrapper: React.FC<
  ReceivedReactionLockWrapperProps
> = ({ children }) => {
  const { isLocked } = useUnlock();

  if (isLocked("bookFriend")) {
    return <></>;
  } else {
    return <>{children}</>;
  }
};
