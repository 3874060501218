import * as React from "react";
import styled from "styled-components";
import { Text } from "~/components/atoms/texts/Text";
import { MissionTaskType } from "~/generated/graphql";
import { ProgressBar } from "~/components/atoms/ProgressBar";
import { TranslateKeys, useTranslation } from "~/i18n";
import { taskTypeToDisplayMaterial } from "./const";
import { Stack } from "~/components/atoms/layout/Stack";

type MissionTaskTableItemProps = {
  missionTaskType: MissionTaskType;
  current: number;
  goal: number;
};

export const MissionTaskTableItem: React.FC<MissionTaskTableItemProps> = ({
  missionTaskType,
  current,
  goal,
}: MissionTaskTableItemProps) => {
  const isCompleted = current >= goal;

  const displayMaterial = taskTypeToDisplayMaterial[missionTaskType];

  const { t } = useTranslation();

  return isCompleted ? (
    <Stack rowGap="8px">
      <Text
        fontSize="SM"
        lineHeight="EQ"
        fontColor="semantic.text.body"
        bold={true}
      >
        {t<TranslateKeys>(displayMaterial.typeName)}
      </Text>
      <_ProgressWrapper>
        <Text
          fontSize="XS"
          lineHeight="EQ"
          fontColor="primitive.green.g400"
          bold={true}
        >
          {goal}
          {t<TranslateKeys>(displayMaterial.unit)}
          {t<TranslateKeys>(displayMaterial.completedVerb)}
        </Text>
        <ProgressBar
          progress={100}
          height="8px"
          progressColor="primitive.green.g400"
          backgroundColor="base.lightGray"
        />
      </_ProgressWrapper>
    </Stack>
  ) : (
    <Stack rowGap="8px">
      <Text
        fontSize="SM"
        lineHeight="EQ"
        fontColor="semantic.text.body"
        bold={true}
      >
        {t<TranslateKeys>(displayMaterial.typeName)}
      </Text>
      <_ProgressWrapper>
        <Text
          fontSize="XS"
          lineHeight="EQ"
          fontColor="semantic.text.body"
          bold={true}
        >
          あと
          <_PrimarySpan>
            {goal - current}
            {t<TranslateKeys>(displayMaterial.unit)}
          </_PrimarySpan>
          {t<TranslateKeys>(displayMaterial.inProgressVerb)}
        </Text>
        <ProgressBar
          progress={(current / goal) * 100}
          height="8px"
          progressColor="semantic.primary.minus1"
          backgroundColor="base.lightGray"
        />
      </_ProgressWrapper>
    </Stack>
  );
};

const _ProgressWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4px;
  align-items: center;
`;

const _PrimarySpan = styled.span`
  color: ${({ theme }) => theme.colors.semantic.primary.main};
`;
