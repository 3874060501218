import React from "react";
import styled from "styled-components";
import {
  CONTENT_WIDTH,
  CONTENT_HEIGHT,
  useScale,
} from "../../../../../utils/hooks/useScale";
import { Message } from "~/components/molecules/Message";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import { waitBookAnimImages } from "~/pages/InitialTutorial/constants";

export const WaitBookAnim: React.FC = () => {
  const { currentUser } = React.useContext(CurrentUserContext);
  const [number, setNumber] = React.useState<number>(0);
  const scale = useScale();

  React.useEffect(() => {
    const TimeoutID = setTimeout(() => setNumber((i) => (i + 1) % 4), 500);
    return () => clearTimeout(TimeoutID);
  }, [number, setNumber]);

  return (
    <_Wrapper imageSrc={waitBookAnimImages.bookShelf}>
      <_Container style={{ transform: `scale(${scale})` }}>
        <_SenseiImgWrapper>
          <img src={waitBookAnimImages.holdBooks} />
          {number > 0 && (
            <_Sweat1Wrapper>
              <_Sweat1 />
            </_Sweat1Wrapper>
          )}
          {number > 1 && (
            <_Sweat2Wrapper>
              <_Sweat2 />
            </_Sweat2Wrapper>
          )}
          {number > 2 && (
            <_Sweat3Wrapper>
              <_Sweat3 />
            </_Sweat3Wrapper>
          )}
        </_SenseiImgWrapper>
        <_MessageWrapper>
          <Message
            speaker="ヨンデミー先生"
            message={`${currentUser?.general.nickname}さんにぴったりの 本を\nもってきているよ！\nもうすこし まっててね`}
            showTriangle={false}
          />
        </_MessageWrapper>
      </_Container>
    </_Wrapper>
  );
};

const _Wrapper = styled.div<{ imageSrc: string }>`
  width: 100vw;
  height: 100vh;
  max-height: 100svh;
  background-image: url(${({ imageSrc }) => imageSrc});
  background-repeat: repeat-x;
  background-size: contain;

  display: flex;
  justify-content: center;
`;

const _Container = styled.div`
  width: ${CONTENT_WIDTH}px;
  height: ${CONTENT_HEIGHT}px;

  margin: 0 auto;

  padding-bottom: 54px;

  transform-origin: top center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`;

const _SenseiImgWrapper = styled.div`
  position: relative;
  overflow: visible;
`;

const _MessageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const _Sweat1Wrapper = styled.div`
  position: absolute;
  right: -30px;
  top: -20px;
`;

const _Sweat2Wrapper = styled.div`
  position: absolute;
  right: -54px;
  top: 16px;
`;

const _Sweat3Wrapper = styled.div`
  position: absolute;
  right: -72px;
  top: 54px;
`;

const _Sweat1: React.FC = () => (
  <svg
    width="34"
    height="32"
    viewBox="0 0 34 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.21416 20.186C7.19609 12.3011 17.555 -1.64835 27.1351 5.63316C36.7153 12.9147 22.0737 23.9291 13.5554 28.5262"
      stroke="white"
      strokeWidth="6"
      strokeLinecap="round"
    />
  </svg>
);

const _Sweat2: React.FC = () => (
  <svg
    width="35"
    height="26"
    viewBox="0 0 35 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 10C10.5 5.33335 26.6 -1.19998 31 10C35.4 21.2 17.1667 23 7.5 22.5"
      stroke="white"
      strokeWidth="6"
      strokeLinecap="round"
    />
  </svg>
);

const _Sweat3: React.FC = () => (
  <svg
    width="35"
    height="26"
    viewBox="0 0 35 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.5 5.4998C12.8333 3.16647 31.4 1.5998 31 13.9998C30.6 26.3998 12.5 22.8333 3.5 19.5"
      stroke="white"
      strokeWidth="6"
      strokeLinecap="round"
    />
  </svg>
);
