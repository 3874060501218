import React from "react";
import styled, { useTheme } from "styled-components";
import { Icon, IconProps } from "~/components/atoms/Icon";
import { Text, TextProps } from "~/components/atoms/texts/Text";
import { ColorTheme } from "~/styles/design_token/color";
import { ColorInterpolation } from "~/styles/interpolation";

export type IconTextProps = TextProps & {
  iconPlace: "pre" | "sub";
  text: string;
  icon: IconProps["name"];
  color: ColorTheme;
};

export const IconText: React.FC<IconTextProps> = ({
  iconPlace,
  color,
  icon,
  text,
  ...textProps
}: IconTextProps) => {
  const theme = useTheme();
  const { fontSize } = textProps;
  return (
    <Wrap>
      {iconPlace === "pre" && (
        <Icon
          name={icon}
          color={color}
          style={{ width: "auto", height: theme.fontSize[fontSize] }}
        />
      )}
      <StyledText {...textProps} color={color}>
        {text}
      </StyledText>
      {iconPlace === "sub" && (
        <Icon
          name={icon}
          color={color}
          style={{ width: "auto", height: theme.fontSize[fontSize] }}
        />
      )}
    </Wrap>
  );
};

const Wrap = styled.div`
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => theme.size.XS};
`;

const StyledText = styled(Text)<{ color: ColorTheme }>`
  ${ColorInterpolation.Sub}
`;
