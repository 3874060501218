import React from "react";
import styled from "styled-components";
import { OneReadLog } from "~/components/organisms/OneReadLog";
import { OneReadLogProps } from "../OneReadLog";
import { useUnlock } from "~/store/unlock/useUnlock";
import { ReadStatus } from "~/generated/graphql";
import { useTranslation } from "~/i18n";
import { Heading4 } from "~/components/atoms/texts/Heading4";
import { LargeButton } from "~/components/atoms/buttons/Button";
import { Stack } from "~/components/atoms/layout/Stack";

export type ReadLogsProps = {
  logs: OneReadLogProps["allInfo"][];
  handleEditReadLog: (logId: number) => void;
};

const Content = styled.div`
  width: 100%;
`;

const ReadLogsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

export const ReadLogs: React.FC<ReadLogsProps> = ({
  logs,
  handleEditReadLog,
}: ReadLogsProps) => {
  // NOTE: data.me.recommends.get[0].readLog.count === 0  のとき data.me.recommends.get[0].readLog.get が空配列になる前提の実装
  const { isLocked } = useUnlock();
  const { t } = useTranslation();

  if (isLocked("enterFreeReview") || !logs.length) return <></>;
  return (
    <Content>
      <ReadLogsContainer>
        {logs.find((log) => log.status === ReadStatus.End) &&
          logs.filter((log) => !log.review).length === logs.length && (
            <_NoReadLogWrapper>
              <Heading4
                fontColor="primitive.tex.t800"
                fontSize="MD"
                lineHeight="EQ"
                bold={true}
              >
                {t<TranslateKeys>("感想")}
              </Heading4>
              <LargeButton
                color="primary"
                variant="blank"
                onClick={() => handleEditReadLog(logs[logs.length - 1].id)}
                style={{ width: "100%", maxWidth: "100%" }}
              >
                {t<TranslateKeys>("感想を追加する")}
              </LargeButton>
            </_NoReadLogWrapper>
          )}
        {logs.map((log, index) => (
          <OneReadLog key={index} allInfo={log} />
        ))}
      </ReadLogsContainer>
    </Content>
  );
};

const _NoReadLogWrapper = styled(Stack)`
  background-color: ${({ theme }) =>
    theme.colors.semantic.layout.backgroundBox};
  border-radius: 16px;
  padding: 16px;
  width: 100%;
  max-width: 632px;
  gap: 8px;
  border: 1px solid ${({ theme }) => theme.colors.semantic.primary.minus1};
`;
