import React from "react";
import styled from "styled-components";
import { Text } from "~/components/atoms/texts/Text";
import { Stack } from "~/components/atoms/layout/Stack";
import { PrimaryButton } from "~/components/molecules/buttons/PrimaryButton";
import { SecondaryButton } from "~/components/molecules/buttons/SecondaryButton";
import { TranslateKeys, useTranslation } from "~/i18n";
import { BaseModal } from "~/components/atoms/wrappers/BaseModal";
export type ConfirmRemoveRecommendModalProps = {
  onClickReturn: (e: React.MouseEvent) => void;
  onClickRemove: (e: React.MouseEvent) => void;
  isOpen: boolean;
  disabled?: boolean;
};

export const ConfirmRemoveRecommendModal: React.FC<
  ConfirmRemoveRecommendModalProps
> = ({ onClickRemove, onClickReturn, isOpen, disabled = false }) => {
  const { t } = useTranslation();
  return (
    <BaseModal open={isOpen}>
      <_ModalWrapper>
        <Stack rowGap="16px">
          <Text fontColor="tex.t700" fontSize="LG" lineHeight="MD">
            {t<TranslateKeys>("本を おすすめ本リストから 削除します")}
          </Text>
          <_ButtonsWrapper>
            <SecondaryButton
              text={t<TranslateKeys>("戻る")}
              fontSize="MD"
              lineHeight="EQ"
              color="lightGray"
              fontColor="tex.t400"
              disabled={disabled}
              onClick={onClickReturn}
              bold
            />
            <PrimaryButton
              text="けす"
              fontSize="MD"
              lineHeight="EQ"
              color="red"
              onClick={onClickRemove}
              disabled={disabled}
              bold
            />
          </_ButtonsWrapper>
        </Stack>
      </_ModalWrapper>
    </BaseModal>
  );
};

const _ModalWrapper = styled.div`
  background-color: ${({ theme }) =>
    theme.colors.semantic.layout.backgroundBox};
  border-radius: ${({ theme }) => theme.deprecatedBorderRadius.MD};
`;

const _ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 14px;
`;
