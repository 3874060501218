import { MissionStorySetting } from "../type";

const baseDir = "/img/mission/start_story/story_1";

export const StartStory1: MissionStorySetting[] = [
  {
    image: `${baseDir}/scene_1.jpg`,
    sound: "start1_scene1",
    text: "大きな図書館の扉があります。",
    ruby: [
      "おお",
      ...Array(2).fill(""),
      "と",
      "しょ",
      "かん",
      "",
      "とびら",
      ...Array(6).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_2.jpg`,
    sound: "start1_scene2",
    text: "ゆっくりと扉を開けると︙︙。",
    ruby: [...Array(5).fill(""), "とびら", "", "あ", ...Array(6).fill("")],
  },
  {
    image: `${baseDir}/scene_3.jpg`,
    sound: "start1_scene3",
    text: "そこには廃墟のような景色が。\nとても薄暗く、たくさんの本が積み重なっています。あれ？奥に、うっすらと灯りが見えます。",
    ruby: [
      ...Array(4).fill(""),
      "はい",
      "きょ",
      ...Array(4).fill(""),
      "け",
      "しき",
      ...Array(6).fill(""),
      "うす",
      "ぐら",
      ...Array(7).fill(""),
      "ほん",
      "",
      "つ",
      "",
      "かさ",
      ...Array(10).fill(""),
      "おく",
      ...Array(7).fill(""),
      "あか",
      ...Array(2).fill(""),
      "み",
      ...Array(7).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_4.jpg`,
    sound: "start1_scene4",
    text: "本をかき分けて進んでいくと︙︙\nあ、ヨンデミー先生！本の整理をしていたんだね。",
    ruby: [
      "ほん",
      ...Array(3).fill(""),
      "わ",
      ...Array(2).fill(""),
      "すす",
      ...Array(15).fill(""),
      "せん",
      "せい",
      "",
      "ほん",
      "",
      "せい",
      "り",
      ...Array(9).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_5.jpg`,
    sound: "start1_scene5_1",
    text: "ヨンデミー先生はとても悲しそうに言いまし\nた。「本たちが読まれなくなって、図書館が元気を無くしちゃったんだ︙︙。",
    ruby: [
      ...Array(5).fill(""),
      "せん",
      "せい",
      ...Array(4).fill(""),
      "かな",
      ...Array(4).fill(""),
      "い",
      ...Array(7).fill(""),
      "ほん",
      ...Array(3).fill(""),
      "よ",
      ...Array(8).fill(""),
      "と",
      "しょ",
      "かん",
      "",
      "げん",
      "き",
      "",
      "な",
      ...Array(11).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_5.jpg`,
    sound: "start1_scene5_2",
    text: "昔はたくさんの人たちが来て、本たちも生き生きとしていたのに︙︙。」",
    ruby: [
      "むかし",
      ...Array(6).fill(""),
      "ひと",
      ...Array(3).fill(""),
      "き",
      ...Array(2).fill(""),
      "ほん",
      ...Array(3).fill(""),
      "い",
      "",
      "い",
      ...Array(11).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_6.jpg`,
    sound: "start1_scene6",
    text: "「︙あれっ本の虫がついてきているね、もしかして君は︙︙！」",
    ruby: [
      ...Array(5).fill(""),
      "ほん",
      "",
      "むし",
      ...Array(15).fill(""),
      "きみ",
      ...Array(5).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_7.jpg`,
    sound: "start1_scene7",
    endDelay: 3000,
    text: "そんなとき、目の前に一冊の輝いた本が飛んできました。",
    ruby: [
      ...Array(6).fill(""),
      "め",
      "",
      "まえ",
      "",
      "いっ",
      "さつ",
      "",
      "かがや",
      ...Array(2).fill(""),
      "ほん",
      "",
      "と",
      ...Array(7).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_8.jpg`,
    sound: "start1_scene8_1",
    text: "「どくしょかのしょだ！」「どくしょかのしょ︙︙？」",
    ruby: [...Array(25).fill("")],
  },
  {
    image: `${baseDir}/scene_8.jpg`,
    sound: "start1_scene8_2",
    text: "「そうか、やっぱり君は︙︙！\nどくしょかのしょに選ばれた君なら、この図書館を救えるかもしれない︙︙！」",
    ruby: [
      ...Array(9).fill(""),
      "きみ",
      ...Array(14).fill(""),
      "えら",
      ...Array(3).fill(""),
      "きみ",
      ...Array(5).fill(""),
      "と",
      "しょ",
      "かん",
      "",
      "すく",
      ...Array(12).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_9.jpg`,
    sound: "start1_scene9",
    text: "元気をなくした本たちをたくさん助けて、読書家はみるみる成長していくんだ。",
    ruby: [
      "げん",
      "き",
      ...Array(5).fill(""),
      "ほん",
      ...Array(7).fill(""),
      "たす",
      ...Array(3).fill(""),
      "どく",
      "しょ",
      "か",
      ...Array(5).fill(""),
      "せい",
      "ちょう",
      ...Array(7).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_10.jpg`,
    sound: "start1_scene10",
    text: "力を蓄えた選ばれし読書家だけが、この図書館を助けることができる！\nそして、伝説の読書家と呼ばれるんだ！",
    ruby: [
      "ちから",
      "",
      "たくわ",
      ...Array(2).fill(""),
      "えら",
      ...Array(3).fill(""),
      "どく",
      "しょ",
      "か",
      ...Array(6).fill(""),
      "と",
      "しょ",
      "かん",
      "",
      "たす",
      ...Array(14).fill(""),
      "でん",
      "せつ",
      "",
      "どく",
      "しょ",
      "か",
      "",
      "よ",
      ...Array(6).fill(""),
    ],
  },
  {
    image: `${baseDir}/scene_11.jpg`,
    sound: "start1_scene11",
    text: "本の虫は、読書家をパートナーにするんだ。君は、その子に選ばれたんだね！\nよぉし、さっそく、本を読んでみよう！",
    ruby: [
      "ほん",
      "",
      "むし",
      ...Array(2).fill(""),
      "どく",
      "しょ",
      "か",
      ...Array(12).fill(""),
      "きみ",
      ...Array(4).fill(""),
      "こ",
      "",
      "えら",
      ...Array(17).fill(""),
      "ほん",
      "",
      "よ",
      ...Array(6).fill(""),
    ],
  },
];
