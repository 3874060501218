import React, { useState, FC } from "react";
import { useNavigate } from "react-router-dom";
import { RecommendTutorialTemplate } from "~/components/templates/RecommendTutorial";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import { TutorialFlagKey } from "~/generated/graphql";
import { config } from "../../../config";

export const RecommendTutorial: FC = () => {
  const navigate = useNavigate();

  const { currentUser, tutorialFlags, getTutorialFlag, saveTutorialFlags } =
    React.useContext(CurrentUserContext);

  const [showFinishingDialog, setShowFinishingDialog] = useState(false);

  const handleOpenFinishingDialog = () => {
    setShowFinishingDialog(true);
    if (tutorialFlags && !getTutorialFlag(TutorialFlagKey.HideRecommendModal)) {
      saveTutorialFlags(TutorialFlagKey.HideRecommendModal);
    }
  };

  const handleClose = () => {
    setShowFinishingDialog(false);
    navigate("/");
  };

  return (
    <RecommendTutorialTemplate
      handleClose={handleClose}
      parentPagePath={
        currentUser
          ? `${config.PARENT_APP}booklist/${currentUser?.general.id}`
          : config.PARENT_APP
      }
      showFinishingDialog={showFinishingDialog}
      handleOpenFinishingDialog={handleOpenFinishingDialog}
      currentUser={currentUser}
    />
  );
};
