import React from "react";
import { GetExperiencePointModal } from "~/components/organisms/modals/ExperiencePoint/GetExperiencePointModal";
import { LevelUpModal } from "~/components/organisms/modals/ExperiencePoint/LevelUpModal";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";

type ModalFlowList = "getExperiencePoint" | "LevelUp" | undefined;

export const useExperiencePointModal = (
  handleNext: () => void
): JSX.Element => {
  const { updatedUserExperience } = React.useContext(CurrentUserContext);

  const [modalFlow, setModalFlow] = React.useState<ModalFlowList>(undefined);

  React.useEffect(() => {
    if (updatedUserExperience) {
      if (updatedUserExperience.achieveExperienceEvents.length === 0) {
        handleNext();
      } else {
        setModalFlow("getExperiencePoint");
      }
    }
  }, [updatedUserExperience]);

  const experiencePointModal = React.useMemo(() => {
    const isLevelUp =
      updatedUserExperience &&
      updatedUserExperience.currentLevel > updatedUserExperience.previousLevel;

    const onCloseExperienceModal = () => {
      if (isLevelUp) {
        setModalFlow("LevelUp");
      } else {
        handleNext();
      }
    };

    return (
      <>
        {modalFlow === "getExperiencePoint" && (
          <GetExperiencePointModal
            currentLevel={updatedUserExperience?.currentLevel || 0}
            previousLevel={updatedUserExperience?.previousLevel || 0}
            currentProgress={updatedUserExperience?.currentProgress || 0}
            previousProgress={updatedUserExperience?.previousProgress || 0}
            achieveEvents={updatedUserExperience?.achieveExperienceEvents || []}
            isMaxLevel={updatedUserExperience?.isMaxLevel}
            expToNextLevelUp={updatedUserExperience?.expToNextLevelUp || 0}
            isOpen={true}
            onClose={onCloseExperienceModal}
          />
        )}
        {modalFlow === "LevelUp" && (
          <LevelUpModal
            currentLevel={updatedUserExperience?.currentLevel || 0}
            previousLevel={updatedUserExperience?.previousLevel || 0}
            isOpen={true}
            onClose={handleNext}
          />
        )}
      </>
    );
  }, [modalFlow, updatedUserExperience, handleNext]);

  return experiencePointModal;
};
