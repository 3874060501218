import * as React from "react";
import styled, { css } from "styled-components";
import { IconButton } from "~/components/molecules/buttons/IconButton";
import { PreferenceKey, usePreference } from "~/store/preference/usePreference";
import { ColorTheme } from "~/styles/design_token/color";
import { ColorInterpolation } from "~/styles/interpolation";

export type PinnedAlertProps = {
  children: React.ReactNode;
  isOpen: boolean;
  setIsOpen: (b: boolean) => void;
  color?: ColorTheme;
  preferenceKey: PreferenceKey;
};

export const PinnedAlert: React.VFC<PinnedAlertProps> = ({
  children,
  isOpen,
  setIsOpen,
  color = "pri",
  preferenceKey,
}) => {
  const { preference, savePreference } = usePreference();
  React.useEffect(() => {
    if (preference && preference[preferenceKey] !== true) {
      setIsOpen(true);
    }
  }, [isOpen, preference]);
  const handleClickCloseButton = (event: React.MouseEvent) => {
    event.preventDefault();
    savePreference({ [preferenceKey]: true });
    setIsOpen(false);
  };
  return (
    <Con isOpen={isOpen} color={color}>
      <CloseButtonCon>
        <IconButton
          name={"close"}
          onClick={handleClickCloseButton}
          color={color}
          fontSize={"MD"}
          disabled={false}
        />
      </CloseButtonCon>
      {children}
    </Con>
  );
};

const Con = styled.div<Pick<PinnedAlertProps, "isOpen" | "color">>`
  ${({ theme, isOpen, color }) => {
    return css`
      background-color: ${theme.colors.base.white};
      border: 2px dashed
        ${() => {
          switch (color) {
            case "pri":
              return theme.colors.pri.p400;
            case "sec":
              return theme.colors.sec.s500;
            case "violet":
              return theme.colors.violet.v500;
            case "green":
              return theme.colors.green.g500;
            case "red":
              return theme.colors.red.r500;
            case "sky":
              return theme.colors.sky.s500;
            case "gray":
              return theme.colors.tex.t200;
            case "white":
              return theme.colors.base.white;
            default:
              return theme.colors.pri.p500;
          }
        }};
      border-radius: ${theme.deprecatedBorderRadius.MD};
      padding: 24px 24px 16px 24px;
      display: ${isOpen ? "block" : "none"};
      position: relative;
      & p {
        ${ColorInterpolation.Sub}
      }
    `;
  }}
`;

const CloseButtonCon = styled.div`
  position: absolute;
  top: 6px;
  right: 8px;
  width: 15px;
`;
