import React, { VFC } from "react";
import styled from "styled-components";
import { Heading1 } from "~/components/atoms/texts/Heading1";
import { BaseModal } from "~/components/atoms/wrappers/modal";

/**
 * Dialogコンポーネント(molecules/modals/Dialog)はフルスクリーンである前提の実装になっているため、
 * Dialog外部をクリックしても閉じることができないようになっている。
 * 影響範囲が広いため、閉じれるDialogコンポーネントを作成。Dialogコンポーネントと統合できそうであれば統合してOK。
 */

export type CloseableDialogProps = {
  /**
   * モーダルが開いているかどうか
   */
  isOpen: boolean;
  /**
   * モーダルのタイトル
   */
  title?: string;
  /**
   * メッセージ本文
   */
  body?: React.ReactNode;
  /**
   * ボタン
   */
  children: React.ReactNode;
  /**
   * モーダルのwidthの設定
   */
  width?: `${number}px`;
  /**
   * モーダル外部をクリックしたときに閉じる
   */
  onClose?: (event: React.MouseEvent) => void;
};

export const CloseableDialog: VFC<CloseableDialogProps> = ({
  isOpen,
  title,
  body,
  children,
  width,
  onClose,
}) => {
  return (
    <BaseModal isOpen={isOpen} onRequestClose={onClose} width={width}>
      <Wrapper>
        {(title || body) && (
          <Upper>
            {title && (
              <ModalHeading fontSize="XL" lineHeight="MD" bold>
                {title}
              </ModalHeading>
            )}
            {body}
          </Upper>
        )}
        {children}
      </Wrapper>
    </BaseModal>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;

const Upper = styled.div`
  width: 100%;
`;

const ModalHeading = styled(Heading1)`
  color: ${({ theme }) => theme.colors.tex.t700};
  margin-bottom: ${({ theme }) => theme.size.M};
`;
