import React from "react";
import styled from "styled-components";
import {
  BookItemContainerInterpolation,
  BookCoverContainer,
} from "../Common/style";

type NextBookImageButtonListOtherItemProps = {
  /**
   * 本のボタンをクリックした時に発火する関数
   */
  onClick: (event: React.MouseEvent) => void;
};

export const NextBookImageButtonListOtherItem: React.VFC<
  NextBookImageButtonListOtherItemProps
> = ({ onClick }) => {
  return (
    <>
      <BookItemContainer onClick={onClick}>
        <BookCoverContainer>
          <SVGImgStyle
            width="100%"
            height="auto"
            viewBox="0 0 96 137"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="1.47168"
              y="0.677612"
              width="93.2056"
              height="135.822"
              rx="7.5"
              stroke="#D5D2CD"
              strokeDasharray="4 4"
            />
            <path
              d="M60.2706 33.9977H52.3851V26.1122C52.3851 25.1446 51.6004 24.3599 50.6328 24.3599H48.8804C47.9128 24.3599 47.1281 25.1446 47.1281 26.1122V33.9977H39.2426C38.275 33.9977 37.4902 34.7824 37.4902 35.7501V37.5024C37.4902 38.47 38.275 39.2547 39.2426 39.2547H47.1281V47.1402C47.1281 48.1079 47.9128 48.8926 48.8804 48.8926H50.6328C51.6004 48.8926 52.3851 48.1079 52.3851 47.1402V39.2547H60.2706C61.2382 39.2547 62.0229 38.47 62.0229 37.5024V35.7501C62.0229 34.7824 61.2382 33.9977 60.2706 33.9977Z"
              fill="#F3AE36"
            />
            <text x="7" y="75" fontSize="12" fill="#F3AE36" fontWeight="bold">
              <tspan x="7" y="75">
                他の本を
              </tspan>
              <tspan x="7" y="93">
                自分で
              </tspan>
              <tspan x="7" y="111">
                選んで読む
              </tspan>
            </text>
          </SVGImgStyle>
        </BookCoverContainer>
      </BookItemContainer>
    </>
  );
};

const BookItemContainer = styled.button`
  background: none;
  padding: 0;
  border: none;
  ${BookItemContainerInterpolation}
`;

const SVGImgStyle = styled.svg`
  max-width: 180px;
  @media screen and ${({ theme }) =>
      theme.breakPoints.maxWidthSmallSmartPhone} {
    max-width: 140px;
  }
  height: 100%;
`;
