import * as React from "react";
import { Route, useLocation, Routes } from "react-router-dom";
import { googleAnalyticsMove } from "./google_analytics";
import { Login } from "./login";
import { CustomerSupportContact } from "./pages/CustomerSupportContact";

export const PublicApp: React.FC = () => {
  const location = useLocation();

  React.useEffect(() => {
    googleAnalyticsMove(location.pathname + location.search);
  }, [location]);

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/cs_contact" element={<CustomerSupportContact />} />
      <Route path="*" element={<Login />} />
    </Routes>
  );
};
