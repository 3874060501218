import React from "react";
import styled from "styled-components";
import { Row } from "~/components/atoms/layout/Row";
import { Stack } from "~/components/atoms/layout/Stack";
import { Text } from "~/components/atoms/texts/Text";
import { MissionOrder } from "~/generated/graphql";
import { missionStoryList } from "./const";

export type VideoArchiveRecordTemplateProps = {
  missionList: MissionOrder[];
  onClickToYondemyStory: () => void;
  onClickToStory: (e: React.MouseEvent, missionOrder: MissionOrder) => void;
  onBack: () => void;
};

export const VideoArchiveRecordTemplate: React.FC<
  VideoArchiveRecordTemplateProps
> = ({ missionList, onClickToYondemyStory, onClickToStory }) => {
  return (
    <_StoryImgListWrapper>
      <_StoryImgListContainer>
        <_StoryImgWrapper onClick={onClickToYondemyStory}>
          <_StoryImg src="/img/record_book/mission_0.png" />
          <_StoryTitleWrapper>
            <Row>
              <_StoryEpisodeNumber>Ep.0</_StoryEpisodeNumber>
              <_BlackLine />
            </Row>
            <Stack rowGap="8px">
              <Text fontSize="MD" lineHeight="MD" fontColor="tex.t700">
                はじまりのものがたり
              </Text>
              <_BlackLine />
            </Stack>
          </_StoryTitleWrapper>
        </_StoryImgWrapper>
        {missionList.map((missionOrder) => (
          <_StoryImgWrapper
            key={missionOrder}
            onClick={(e) => onClickToStory(e, missionOrder)}
          >
            <_StoryImg src={missionStoryList[missionOrder].storyImgSrc} />
            <_StoryTitleWrapper>
              <Row>
                <_StoryEpisodeNumber>
                  Ep.
                  {missionStoryList[missionOrder].storyNumber}
                </_StoryEpisodeNumber>
                <_BlackLine />
              </Row>
              <Stack rowGap="8px">
                <Text fontSize="MD" lineHeight="MD" fontColor="tex.t700">
                  {missionStoryList[missionOrder].storyTitle}
                </Text>
                <_BlackLine />
              </Stack>
            </_StoryTitleWrapper>
          </_StoryImgWrapper>
        ))}
        {Array.from(
          Array(Object.keys(MissionOrder).length + 1 - missionList.length),
          (_, index) => (
            <_UnwatchableStoryWrapper key={index}>
              <div>
                <_QuestionSymbol />
              </div>
            </_UnwatchableStoryWrapper>
          )
        )}
      </_StoryImgListContainer>
    </_StoryImgListWrapper>
  );
};

const _StoryImgListContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

const _StoryImgListWrapper = styled.div`
  width: 100%;
`;

const _StoryImgWrapper = styled.div`
  width: 100%;
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
`;

const _StoryImg = styled.img`
  width: 100%;
  border-radius: 16px;
`;

const _StoryTitleWrapper = styled.div`
  width: 116px;
  position: absolute;
  left: 0;
  bottom: 12px;
  border-radius: 0 4px 4px 0;
  background-color: ${({ theme }) => theme.colors.base.white};

  padding: 10px 18px;
`;

const _StoryEpisodeNumber = styled.p`
  font-family: Send Flowers;
  font-size: ${({ theme }) => theme.fontSize.SM};
  line-height: 160%;
  color: ${({ theme }) => theme.colors.tex.t700};
`;

const _BlackLine = styled.hr`
  border: 0.5px solid ${({ theme }) => theme.colors.tex.t700};
  width: 100%;
  margin: 0;
`;

const _UnwatchableStoryWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: 53.35%;
  background-color: #efd3a9;
  border-radius: 16px;

  div {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const _QuestionSymbol: React.FC = () => (
  <svg
    width="19"
    height="26"
    viewBox="0 0 19 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.91862 0.166748C5.83587 0.166748 3.19146 1.80671 1.11533 4.73144C0.738717 5.26199 0.854827 5.98943 1.38019 6.37997L3.5867 8.02024C4.11728 8.41464 4.8722 8.32242 5.28759 7.81223C6.56885 6.23856 7.51927 5.3325 9.52062 5.3325C11.0942 5.3325 13.0406 6.32537 13.0406 7.82135C13.0406 8.95227 12.0883 9.53308 10.5346 10.3871C8.72268 11.383 6.32497 12.6224 6.32497 15.723V16.2138C6.32497 16.8785 6.87458 17.4174 7.55257 17.4174H11.2595C11.9375 17.4174 12.4871 16.8785 12.4871 16.2138V15.9243C12.4871 13.775 18.8945 13.6855 18.8945 7.86934C18.8946 3.4893 14.2603 0.166748 9.91862 0.166748ZM9.40604 18.8946C7.45231 18.8946 5.86283 20.453 5.86283 22.3684C5.86283 24.2837 7.45231 25.8421 9.40604 25.8421C11.3598 25.8421 12.9493 24.2837 12.9493 22.3683C12.9493 20.4529 11.3598 18.8946 9.40604 18.8946Z"
      fill="#723E21"
    />
  </svg>
);
