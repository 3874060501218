import { MissionTaskType } from "~/generated/graphql";

type DisplayMaterial = {
  typeName: TranslateKeys;
  unit: TranslateKeys;
  verb: TranslateKeys;
};

export const taskTypeToDisplayMaterial: Record<
  MissionTaskType,
  DisplayMaterial
> = {
  [MissionTaskType.MiniLesson]: {
    typeName: "ミニレッスン",
    unit: "回",
    verb: "受けて、",
  },
  [MissionTaskType.ReadLog]: {
    typeName: "感想",
    unit: "回",
    verb: "出して、",
  },
  [MissionTaskType.ReadLogWithReview]: {
    typeName: "一言感想",
    unit: "回",
    verb: "書いて、",
  },
  [MissionTaskType.ReadLogContinueDays]: {
    typeName: "感想",
    unit: "日連続",
    verb: "で出して、",
  },
  [MissionTaskType.SendLike]: {
    typeName: "いいね",
    unit: "回",
    verb: "して、",
  },
  [MissionTaskType.ReceiveLike]: {
    typeName: "いいね",
    unit: "回",
    verb: "もらって、",
  },
};
