import React from "react";
import styled from "styled-components";
import { useUnlock } from "~/store/unlock/useUnlock";
import { useTranslation } from "react-i18next";

const bookWormKeyUrl = "/img/unlock/book_worm_key_2.svg";

type YomimashokaBookshelfLockWrapperProps = {
  children: React.ReactNode;
};

export const YomimashokaBookshelfLockWrapper: React.FC<
  YomimashokaBookshelfLockWrapperProps
> = ({ children }) => {
  const { isLocked } = useUnlock();
  const { t } = useTranslation();

  return isLocked("wantReadBook") ? (
    <_Container>
      <_Comment>
        {t<TranslateKeys>("よみま書架")}は
        <br />
        <span>レベル25</span>になったら
        {t<TranslateKeys>("使えるよ")}
      </_Comment>
      <_BookWarmKey src={bookWormKeyUrl} />
    </_Container>
  ) : (
    <>{children}</>
  );
};

const _Container = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.size.s2};
  background-color: ${({ theme }) => theme.colors.primitive.monotone.m40};
  border-radius: 16px;
  padding: ${({ theme }) => theme.size.s3};
`;

const _BookWarmKey = styled.img`
  height: 117px;
  object-fit: contain;
`;

const _Comment = styled.p`
  line-height: ${({ theme }) => theme.lineHeight.MD};
  font-size: ${({ theme }) => theme.fontSize.MD};
  font-weight: bold;
  text-align: center;

  span {
    color: ${({ theme }) => theme.colors.primitive.orange.o700};
  }
`;
