import React from "react";
import styled from "styled-components";
import { Wrapper } from "../Common";
import { Text } from "~/components/atoms/texts/Text";
import { Icon } from "~/components/atoms/Icon";
import { Stack } from "~/components/atoms/layout/Stack";
import { TextAnchor } from "~/components/molecules/buttons/TextButton";
import { Heading1 } from "~/components/atoms/texts/Heading1";
import { Heading2 } from "~/components/atoms/texts/Heading2";
import { OfficialLineLink } from "../../../../../util/constant";
import { LargeButton } from "~/components/atoms/buttons/Button";

export type CustomerSupportContactTemplateProps = {
  onBack: () => void;
};

export const CustomerSupportContactTemplate: React.FC<
  CustomerSupportContactTemplateProps
> = ({ onBack }) => {
  return (
    <_Wrapper>
      <Stack rowGap="32px" alignItems="center" style={{ width: "100%" }}>
        <Heading1
          fontSize="XL"
          lineHeight="EQ"
          fontColor="semantic.text.heading"
          bold
        >
          窓口からお問い合わせください
        </Heading1>
        <Stack rowGap="24px" style={{ width: "100%" }}>
          <_WhiteBox>
            <Stack rowGap="12px" alignItems="center" style={{ width: "100%" }}>
              <Stack
                rowGap="8px"
                alignItems="flex-start"
                style={{ width: "100%" }}
              >
                <Heading2
                  fontSize="LG"
                  lineHeight="EQ"
                  fontColor="semantic.text.heading"
                  bold
                >
                  なんでも相談窓口
                </Heading2>
                <Text
                  fontSize="SM"
                  lineHeight="MD"
                  fontColor="semantic.text.body"
                >
                  LINEでお気軽にご相談いただけます。
                </Text>
              </Stack>
              {/* FIXME: ボタンのフォントサイズ修正 */}
              <LargeButton
                onClick={() => {
                  window.open(OfficialLineLink);
                }}
                color="line"
              >
                LINEで友だち追加
              </LargeButton>
            </Stack>
          </_WhiteBox>
          <_WhiteBox>
            <Stack rowGap="12px" style={{ width: "100%" }}>
              <Stack rowGap="8px">
                <Heading2
                  fontSize="LG"
                  lineHeight="EQ"
                  fontColor="semantic.text.heading"
                  bold
                >
                  メール
                </Heading2>
                <Text
                  fontSize="SM"
                  lineHeight="MD"
                  fontColor="semantic.text.body"
                >
                  メールでご相談したい方はこちらのアドレスをご利用ください。
                </Text>
              </Stack>
              <TextAnchor
                href="mailto:cs@yondemy.com"
                lineHeight="MD"
                fontSize="SM"
                text="cs@yondemy.com"
                fontColor="semantic.secondary.main"
              />
            </Stack>
          </_WhiteBox>
        </Stack>
        {/* FIXME: ボタンのフォントサイズ修正 */}
        <LargeButton
          onClick={onBack}
          color="primary"
          leftIcon={<Icon name="arrowLeft" color="white" />}
        >
          ログイン画面にもどる
        </LargeButton>
      </Stack>
    </_Wrapper>
  );
};

const _Wrapper = styled(Wrapper)`
  padding-top: ${({ theme }) => theme.size.s4};
`;

const _WhiteBox = styled.div`
  padding: ${({ theme }) => theme.size.s2};
  background-color: ${({ theme }) =>
    theme.colors.semantic.layout.backgroundBox};
  border-radius: ${({ theme }) => theme.borderRadius.s2};
`;
