import * as React from "react";
import styled from "styled-components";
import { SolidButton } from "~/components/atoms/buttons/SolidButton";
import { Text } from "~/components/atoms/texts/Text";
import { BottomModal } from "~/components/atoms/wrappers/BottomModal";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import { useTranslation } from "~/i18n";
import { useAudio } from "~/utils/hooks/useAudio";
import { BirthdayModal } from "../../BirthdayModal";
import { UserIconType } from "~/generated/graphql";

const stampImages: Record<UserIconType, string> = {
  PENGUIN: "penguin_in_ring.png",
  LION: "lion_in_ring.png",
  WORM: "bug_in_ring.png",
  APPLE: "apple_in_ring.png",
  // MEMO: デザインの関係で、スタンプがないキャラクターについては一時的に一律本の虫で対応する
  DINOSAUR: "bug_in_ring.png",
  SHEEP: "bug_in_ring.png",
  GIRAFFE: "bug_in_ring.png",
  WOLF: "bug_in_ring.png",
};

type Flow = "getStamp" | "celebrateBirthday";

type StampPageContent = {
  text: TranslateKeys;
  buttons: { text: TranslateKeys; onClick: () => void }[];
};
export type GetStampModalProps = {
  open?: boolean;
  userIconType: UserIconType;
  onConfirmed: () => void;
};

export const GetStampModal: React.FC<GetStampModalProps> = ({
  open = false,
  onConfirmed,
  userIconType,
}) => {
  const { t } = useTranslation();
  const [stampPage, setStampPage] = React.useState<number>(0);
  const { play } = useAudio({ preload: ["getStamp"] });
  const [flow, setFlow] = React.useState<Flow>("getStamp");
  const { currentUser } = React.useContext(CurrentUserContext);
  const nickname = currentUser?.general.nickname;

  const today = new Date();
  const birthDate = new Date(currentUser?.birthday);
  const isBirthday =
    today.getMonth() === birthDate.getMonth() &&
    today.getDate() === birthDate.getDate();

  React.useEffect(() => {
    if (open) {
      play("getStamp", 0.9);
    } else {
      setStampPage(0);
    }
  }, [open]);

  const stampPageList: Record<number, StampPageContent> = {
    0: {
      text: "スタンプを押すね！",
      buttons: [
        { text: "やった😆", onClick: () => setStampPage(1) },
        { text: "わーい😊", onClick: () => setStampPage(1) },
      ],
    },
    1: {
      text: "明日も ミニレッスンを 受けると スタンプが 貯まっていくよ📚✨",
      buttons: [
        {
          text: "貯めたい！✨",
          onClick: () => setStampPage(2),
        },
        {
          text: "がんばるぞ💪",
          onClick: () => setStampPage(2),
        },
      ],
    },
    2: {
      text: "{{nickname}}さん、このまま本も読んじゃう？",
      buttons: [
        { text: "読む！", onClick: () => setStampPage(3) },
        { text: "後でね！", onClick: () => setStampPage(4) },
      ],
    },
    3: {
      text: "それじゃあ、本の世界へ出発📚✨",
      buttons: [
        {
          text: "出発！",
          onClick: isBirthday
            ? () => setFlow("celebrateBirthday")
            : onConfirmed,
        },
      ],
    },
    4: {
      text: "それじゃあ、本の世界で待ってるよ👋",
      buttons: [
        {
          text: "わかった！",
          onClick: isBirthday
            ? () => setFlow("celebrateBirthday")
            : onConfirmed,
        },
      ],
    },
  };

  return (
    <>
      {flow === "getStamp" && (
        <BottomModal open={open}>
          <_Wrapper>
            <div className="balloon">
              <div className="message">
                <Text fontSize="LG" lineHeight="MD" bold>
                  {t<TranslateKeys>(stampPageList[stampPage].text, {
                    nickname,
                  })}
                </Text>
              </div>
              {stampPage === 0 && (
                <_GetStamp userIconType={userIconType}>
                  <div className="stamp" />
                </_GetStamp>
              )}
              {stampPageList[stampPage].buttons.map((button, index) => (
                <_Button
                  key={index}
                  color="pri"
                  variant="Normal"
                  shape="RoundedCorner"
                  onClick={button.onClick}
                >
                  <_ButtonText fontSize="LG" lineHeight="EQ" bold>
                    {t<TranslateKeys>(button.text)}
                  </_ButtonText>
                </_Button>
              ))}
            </div>
            <div className="balloon-tail" />
            <_Sensei stampPage={stampPage} />
          </_Wrapper>
        </BottomModal>
      )}
      {flow === "celebrateBirthday" && open && (
        <BirthdayModal onClose={onConfirmed} />
      )}
    </>
  );
};

const _Sensei = styled.div<{ stampPage: number }>`
  margin-top: 27px;

  width: 85px;
  height: 120px;

  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom center;
  background-image: ${(props) =>
    props.stampPage === 0
      ? "url(/img/sensei/standup_2.svg)"
      : props.stampPage === 1
      ? "url(/img/sensei/standup_1.svg)"
      : props.stampPage === 2
      ? "url(/img/sensei/standup_1.svg)"
      : props.stampPage === 3
      ? "url(/img/sensei/standup_4.svg)"
      : "url(/img/sensei/standup_2.svg)"};
`;

const _Button = styled(SolidButton)`
  height: 48px;
  color: white;
`;

const _ButtonText = styled(Text)`
  color: white;
`;

const _Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 305px;

  padding-bottom: 50px;

  .message {
    width: 100%;
  }

  .balloon {
    width: 100%;
    box-sizing: border-box;
    padding: 30px 15px;
    border-radius: 16px;

    background-color: white;

    display: flex;
    flex-direction: column;
    row-gap: 15px;
    align-items: center;
  }

  .balloon-tail {
    transform: translateY(-1px);
    width: 17px;
    height: 12px;
    background-image: url(/img/misc/balloon_tail.svg);
    background-position: center;
  }
`;

const _GetStamp = styled.div<{ userIconType: UserIconType }>`
  width: 100px;
  height: 100px;
  position: relative;
  margin: 16px 0;

  .stamp {
    opacity: 0;

    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 124px;
    height: 124px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: ${(props) =>
      `url(/img/stamp_card/${stampImages[props.userIconType]})`};

    animation: 0.4s ease-in 0.5s zoom-in forwards;
  }

  @keyframes zoom-in {
    from {
      opacity: 0;
      transform: translate(-50%, -50%) scale(3);
    }

    to {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
  }
`;
