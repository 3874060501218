import * as React from "react";
import styled, { css } from "styled-components";
import { useSnd } from "~/utils/hooks/useSnd";

export type ToggleSwitchProps = {
  isOnRight: boolean;
  onChange: (event: React.MouseEvent<HTMLLabelElement>) => void;
};

export const ToggleSwitch: React.VFC<ToggleSwitchProps> = ({
  isOnRight,
  onChange,
}) => {
  const { play } = useSnd();
  const handleChange = (event: React.MouseEvent<HTMLLabelElement>) => {
    onChange(event);
    isOnRight ? play("TOGGLE_OFF") : play("TOGGLE_ON");
  };
  return (
    <>
      <label onClick={handleChange}>
        <Input type="checkbox" defaultChecked={isOnRight} />
        <Span isOnRight={isOnRight} />
      </label>
    </>
  );
};

const Span = styled.span<{ isOnRight: boolean }>`
  ${({ theme, isOnRight }) => {
    return css`
      z-index: 0;
      width: 40px;
      height: 20px;
      margin: 0;
      background: ${isOnRight ? theme.colors.pri.p500 : theme.colors.base.gray};
      position: relative;
      display: inline-block;
      border-radius: 20px;
      transition: 0.4s;
      box-sizing: border-box;
      &::after {
        /* つまみ部分のスタイリング */
        content: "";
        position: absolute;
        width: 24px;
        height: 24px;
        border-radius: 8px;
        left: ${isOnRight ? "20px" : "0"};
        top: -2px;
        z-index: ${theme.zIndex.el};
        background: ${theme.colors.base.white};
        border: 3px solid ${theme.colors.pri.p500};
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
        transition: 0.4s;
        cursor: pointer;
      }
    `;
  }}
`;

const Input = styled.input`
  display: none;
`;
