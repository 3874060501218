import React, { VFC } from "react";
import styled from "styled-components";
import { Heading1 } from "~/components/atoms/texts/Heading1";
import { IconTextLink } from "~/components/molecules/buttons/IconTextButton";
import { Wrapper } from "../Common";

export const NotFoundTemplate: VFC = () => (
  <Wrapper>
    <TopWrapper>
      <ImgCon>
        <Img src={"/img/sensei/oops.png"} alt={"ヨンデミー先生より"} />
      </ImgCon>
      <Title fontSize={"XL"} lineHeight={"MD"} bold>
        ごめんなさい！ページが見つかりません。
      </Title>
      <LinkCon>
        <IconTextLink
          fontSize={"MD"}
          lineHeight={"MD"}
          color={"gray"}
          iconPlace={"pre"}
          name={"arrowLeft"}
          text={"本だなのページへ"}
          to={"/"}
        />
      </LinkCon>
    </TopWrapper>
  </Wrapper>
);

const TopWrapper = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Title = styled(Heading1)`
  color: ${({ theme }) => theme.colors.pri.p500};
  text-align: center;
`;

const ImgCon = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Img = styled.img`
  width: 100px;
  @media screen and (min-width: 600px) {
    width: 140px;
  }
`;

const LinkCon = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
