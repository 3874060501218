import React from "react";
import styled from "styled-components";
import { Loading } from "~/components/atoms/Loading";

export const LoadingSection: React.FC = () => {
  return (
    <Wrapper>
      <Loading size="m" type="primary" />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
