import moment from "moment";
import { useContext, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import {
  useGetMyMiniLessonHistoryQuery,
  UserIconType,
} from "~/generated/graphql";
import { usePreference } from "~/store/preference/usePreference";
import { StampCardProps } from ".";

const DATE_FORMAT = "YYYY-MM-DD";

export const useStampCard = (): {
  stampCardProps?: StampCardProps;
  loading: boolean;
} => {
  const startTime = new Date();
  startTime.setDate(startTime.getDate() - 3);
  const startDate = new Date(
    startTime.getFullYear(),
    startTime.getMonth(),
    startTime.getDate()
  );
  const todayTime = new Date();
  const todayDate = new Date(
    todayTime.getFullYear(),
    todayTime.getMonth(),
    todayTime.getDate()
  );
  const todayStr = moment(todayTime).format(DATE_FORMAT);
  const { preference, savePreference } = usePreference();
  const { currentUser } = useContext(CurrentUserContext);
  const navigate = useNavigate();

  const { data, loading } = useGetMyMiniLessonHistoryQuery({
    fetchPolicy: "no-cache",
    variables: {
      from: startDate,
      to: todayDate,
    },
  });

  useEffect(() => {
    if (preference?.stampCardCheckedDate !== todayStr) {
      savePreference({ stampCardCheckedDate: todayStr });
    }
  }, []);

  const stampCardProps: StampCardProps | undefined = useMemo(() => {
    const _dates = data?.getMyMiniLessonHistory
      ? data.getMyMiniLessonHistory.completedMiniLessonEndDates
      : [];
    const completedToday = _dates
      .map((_d) => moment(_d).format(DATE_FORMAT))
      .includes(todayStr);

    const userIconType =
      currentUser?.general.userIconType || UserIconType.Penguin;

    const openModal =
      completedToday && preference?.lastStampObtainedDate !== todayStr;

    return {
      hasTodaysLesson: !!data?.getMyMiniLessonHistory.hasInProgressMiniLesson,
      today: todayTime,
      lessonDates: _dates.map((_d) => new Date(_d)),
      firstTime: preference?.stampCardCheckedDate !== todayStr,
      gotoLesson: () => navigate("/message"),
      userIconType,
      getStampModalProps: {
        userIconType,
        open: openModal,
        onConfirmed: () => {
          savePreference({ lastStampObtainedDate: todayStr });
        },
      },
      loading: loading,
    };
  }, [preference, data, savePreference, loading]);

  return { stampCardProps, loading };
};
