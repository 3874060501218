import React from "react";
import styled from "styled-components";
import { LargeButton } from "~/components/atoms/buttons/Button";
import { Stack } from "~/components/atoms/layout/Stack";
import { Text } from "~/components/atoms/texts/Text";
import { BackNavbar } from "~/components/molecules/navbars/BackNavbar";
import { NavContainer, Wrapper } from "~/components/templates/Common";
import { IMAGE_PATHS } from "~/helpers/constants/imagePath";

export type ConfirmEnterBookProps = {
  title: string;
  imgUrl?: string;
  handleBack: () => void;
  handleNext: () => void;
};

export const ConfirmEnterBook: React.FC<ConfirmEnterBookProps> = ({
  title,
  imgUrl,
  handleBack,
  handleNext,
}) => {
  return (
    <>
      <NavContainer>
        <BackNavbar
          titleName=""
          onClick={handleBack}
          background="none"
          arrowColor="sec"
        />
      </NavContainer>
      <Wrapper>
        <div style={{ paddingTop: "60px" }}>
          <Stack rowGap="24px">
            <Text
              fontSize="LG"
              lineHeight="MD"
              bold
              fontColor="semantic.text.body"
            >
              この本を追加しますか？
            </Text>
            <_ContentWrapper>
              <Text
                fontSize="SM"
                lineHeight="MD"
                fontColor="semantic.text.heading"
                bold
              >
                {title}
              </Text>
              <_ImgWrapper>
                <_Img src={imgUrl || IMAGE_PATHS.NO_IMAGE} alt={title} />
              </_ImgWrapper>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <LargeButton color="primary" onClick={handleNext}>
                  この本の感想を書く
                </LargeButton>
              </div>
            </_ContentWrapper>
          </Stack>
        </div>
      </Wrapper>
    </>
  );
};

const _ContentWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.primitive.monotone.m0};
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  border-radius: 16px;
`;

const _ImgWrapper = styled.div`
  width: 200px;
  height: 200px;
  margin: 0 auto;
`;

const _Img = styled.img`
  max-width: 200px;
  max-height: 200px;
  width: 100%;
  object-fit: contain;
  border-radius: 8px;
`;
