import React from "react";
import { snsSharingImage } from "./SnsSharingImage";
import styled from "styled-components";
import { Text } from "~/components/atoms/texts/Text";
import { Row } from "~/components/atoms/layout/Row";
import { Icon } from "~/components/atoms/Icon";
import { NavContainer } from "../Common";
import { BackNavbar } from "~/components/molecules/navbars/BackNavbar";
import { Loading } from "~/components/atoms/Loading";
import { Select } from "~/components/atoms/forms/Select";
import { FetchType } from "~/pages/SnsSharingImage";
import { sendButtonClickEvent } from "~/utils/googleAnalytics/useButtonClickTracking";

export type SnsSharingTemplateProps = {
  imgSrcList: string[];
  username?: string;
  userId?: number;
  handleBack: () => void;
  fetchType: FetchType;
  handleChangeFetchType: (fetchType: FetchType) => void;
};

const selectValueList: Record<FetchType, string> = {
  Latest: "最新9冊",
  All: "全て",
};

export const SnsSharingTemplate: React.FC<SnsSharingTemplateProps> = ({
  imgSrcList,
  username,
  userId,
  handleBack,
  fetchType,
  handleChangeFetchType,
}) => {
  const [uri, setUri] = React.useState<string>("");
  React.useEffect(() => {
    snsSharingImage(imgSrcList, (uri: string) => setUri(uri));
    setUri("");
  }, [imgSrcList]);

  const handleClickShareButton = React.useCallback(() => {
    if (window.navigator.share && uri) {
      fetch(uri)
        .then((response) => response.blob())
        .then((blob) => {
          const file = new File([blob], "bookshelf.jpg", {
            type: blob.type,
          });
          window.navigator.share({
            text: "#ヨンデミー #ヨンデミーでよんでみた",
            files: [file],
          });
        });
    }
    if (window.gtag) {
      window.gtag("event", "button_click", {
        event_category: "button",
        event_label: "share_image",
        userId: `${userId}`,
        pathname: window.location.pathname,
      });
    }
  }, [uri, userId]);

  const onChangeFetchType = React.useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      event.preventDefault();
      handleChangeFetchType(event.target.value as FetchType);
    },
    [handleChangeFetchType]
  );

  const onPrint = React.useCallback((event: React.MouseEvent) => {
    event.preventDefault();
    window.print();
  }, []);

  return (
    <>
      <_NavContainer>
        <BackNavbar
          onClick={handleBack}
          titleName={`${username}さんのほんだな`}
          background={"white"}
          arrowColor={"pri"}
        />
      </_NavContainer>
      <_Wrapper>
        <_Container>
          <_Message lineHeight="MD" fontColor="tex.t300" fontSize="SM">
            どくしょきろくを画像でみんなに共有できます！
            印刷しておうちに飾る・離れて暮らす家族に送る ・SNSでシェアする
            など、使いかたは自由です！
          </_Message>
          <_ImageWrapper>
            <_SelectWrapper>
              <Select
                value={fetchType}
                onChange={onChangeFetchType}
                style={{ width: "187px" }}
              >
                {Object.keys(selectValueList).map((key) => (
                  <option value={key} key={key}>
                    {selectValueList[key]}
                  </option>
                ))}
              </Select>
            </_SelectWrapper>
            {uri ? <_Image src={uri} /> : <Loading />}
          </_ImageWrapper>
        </_Container>
      </_Wrapper>
      <_ButtonWrapper>
        {typeof window.navigator.share === "function" ? (
          <_IconButton onClick={handleClickShareButton}>
            <Row columnGap="16px">
              <Icon name="share" color="white" />
              <Text fontSize="XL" lineHeight="EQ" fontColor="base.white" bold>
                シェア
              </Text>
            </Row>
          </_IconButton>
        ) : (
          <_IconButton
            download="bookshelf.jpg"
            href={uri}
            onClick={() => {
              sendButtonClickEvent("save_image", `${userId}`);
            }}
          >
            <Row columnGap="16px">
              <Icon name="cloudArrowButton" color="white" />
              保存
            </Row>
          </_IconButton>
        )}
        <_IconButton
          onClick={(e) => {
            onPrint(e);
            sendButtonClickEvent("print_image", `${userId}`);
          }}
        >
          <Row columnGap="16px">
            <Icon name="print" color="white" />
            <Text lineHeight="EQ" fontSize="XL" fontColor="base.white" bold>
              プリント
            </Text>
          </Row>
        </_IconButton>
      </_ButtonWrapper>
    </>
  );
};

const _NavContainer = styled(NavContainer)`
  @media print {
    display: none;
  }
`;

const _Wrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-color: ${({ theme }) =>
    theme.colors.semantic.layout.backgroundMain};
  padding: 72px 0 0 0;
`;

const _Container = styled.div`
  max-width: 860px;
  margin: 0 auto;
  border-radius: 16px;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
`;

const _ImageWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  row-gap: 16px;

  background-color: ${({ theme }) => theme.colors.pri.p300};
  border-radius: 16px 16px 0 0;
  padding: 16px;
`;

const _SelectWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media print {
    display: none;
  }
`;

const _Message = styled(Text)`
  width: 100%;
  max-width: 320px;
  text-align: justify;
  margin: 0 auto;
  @media screen and (min-width: ${({ theme }) =>
      theme.screenSize.smartPhoneMax}) {
    width: 100%;
    max-width: fit-content;
    text-align: center;
  }
  @media print {
    display: none;
  }
`;

const _ButtonWrapper = styled.div`
  position: sticky;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 24px;

  width: calc(100% + 4px);
  transform: translateX(-2px);
  border-radius: 16px 16px 0 0;
  background-color: ${({ theme }) =>
    theme.colors.semantic.layout.backgroundBox};
  border: 2px solid ${({ theme }) => theme.colors.semantic.layout.border};
  border-bottom: none;

  padding: 16px 16px;

  @media screen and (max-width: 350px) {
    column-gap: 8px;
  }

  @media print {
    display: none;
  }
`;

const _IconButton = styled.a`
  width: 45%;
  min-width: 135px;
  max-width: 300px;
  box-sizing: border-box;
  padding: 16px 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 16px;

  font-size: ${({ theme }) => theme.fontSize.XL};
  font-weight: bold;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.semantic.primary.minus1};
  color: ${({ theme }) => theme.colors.semantic.layout.backgroundBox};
  border-bottom: 5px solid;
  border-color: ${({ theme }) => theme.colors.semantic.primary.plus1};
  &:hover,
  &:focus {
    border: none;
    margin-top: 5px;
    color: ${({ theme }) => theme.colors.semantic.layout.backgroundBox};
  }
`;

const _Image = styled.img`
  width: 100%;
`;
