import styled, { css } from "styled-components";
import { PaddingProps, paddingStyle } from "~/styles/design_token/padding";
import { MarginProps, marginStyle } from "~/styles/design_token/margin";
import { Width } from "~/styles/design_token/size";

type ColumnGap =
  | "0px"
  | "4px"
  | "8px"
  | "10px"
  | "12px"
  | "16px"
  | "24px"
  | "32px";

type Props = {
  columnGap?: ColumnGap;
  justifyContent?:
    | "center"
    | "flex-start"
    | "flex-end"
    | "space-between"
    | "space-evenly";
  alignItems?: "baseline" | "center" | "flex-start";
  flexWrap?: "wrap";
  width?: Width;
} & PaddingProps &
  MarginProps;

export const Row = styled.div<Props>`
  display: flex;
  flex-direction: row;
  column-gap: ${({ columnGap = "8px" }) => columnGap};
  ${({ justifyContent }) =>
    justifyContent &&
    css`
      justify-content: ${justifyContent};
    `};
  ${({ alignItems }) =>
    css`
      align-items: ${alignItems || "center"};
    `};

  ${({ flexWrap }) =>
    flexWrap &&
    css`
      flex-wrap: ${flexWrap};
    `};
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `};
  ${paddingStyle}
  ${marginStyle}
`;
