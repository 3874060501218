import React, { VFC } from "react";
import styled, { css } from "styled-components";
import { Heading1 } from "~/components/atoms/texts/Heading1";
import { BaseModal } from "~/components/atoms/wrappers/modal";

export type DialogProps = {
  /**
   * モーダルが開いているかどうか
   */
  isOpen: boolean;
  /**
   * モーダルのタイトル
   */
  title?: string;
  /**
   * メッセージ本文
   */
  body?: React.ReactNode;
  /**
   * ボタン
   */
  children: React.ReactNode;
  /**
   * ボタン
   */
  noPadding?: boolean;
};

export const Dialog: VFC<DialogProps> = ({
  isOpen,
  title,
  body,
  children,
  noPadding,
}) => {
  return (
    <BaseModal isOpen={isOpen}>
      <Wrapper noPadding={noPadding}>
        {(title || body) && (
          <Upper>
            {title && (
              <ModalHeading fontSize={"XL"} lineHeight={"MD"} bold>
                {title}
              </ModalHeading>
            )}
            {body}
          </Upper>
        )}
        {children}
      </Wrapper>
    </BaseModal>
  );
};

const Wrapper = styled.div<{ noPadding?: boolean }>`
  ${({ theme, noPadding }) => {
    const {
      colors,
      deprecatedBorderRadius: borderRadius,
      size,
      zIndex,
    } = theme;
    return css`
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: ${colors.base.white};
      border: 2px solid ${colors.base.lightGray};
      border-radius: ${borderRadius.MD};
      padding: ${!noPadding && size.M};
      z-index: ${zIndex.modal};
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: ${size.L};
    `;
  }}
  width: 90%;
  @media screen and (min-width: 600px) {
    width: 60%;
  }
  @media screen and (min-width: 800px) {
    width: 50%;
  }
  @media screen and (min-width: 1024px) {
    width: 500px;
  }
`;

const Upper = styled.div`
  width: 100%;
`;

const ModalHeading = styled(Heading1)`
  color: ${({ theme }) => theme.colors.tex.t700};
  margin-bottom: ${({ theme }) => theme.size.M};
`;
