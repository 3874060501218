import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Interpolation } from "~/styles/interpolation";
import { IconButton } from "../../buttons/IconButton";

export type PasswordInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  error?: boolean;
  defaultIsPasswordHidden?: boolean;
};

export const PasswordInput: React.FC<PasswordInputProps> = ({
  error,
  disabled,
  defaultIsPasswordHidden = true,
  ...inputProps
}) => {
  const [inputFocused, setInputFocused] = useState(false);
  const [isPasswordHidden, setIsPasswordHidden] = useState(
    defaultIsPasswordHidden
  );

  return (
    <Wrapper error={error} disabled={disabled} inputFocused={inputFocused}>
      <NoBorderInput
        type={isPasswordHidden ? "password" : "text"}
        disabled={disabled}
        onFocus={() => setInputFocused(true)}
        onBlur={() => setInputFocused(false)}
        {...inputProps}
      />
      <IconButton
        type="button"
        name={isPasswordHidden ? "eye" : "eyeSlash"}
        fontSize="MD"
        color="gray"
        onClick={(event) => {
          event.preventDefault();
          setIsPasswordHidden(!isPasswordHidden);
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div<{
  inputFocused: boolean;
  error?: boolean;
  disabled?: boolean;
}>`
  ${({ theme, inputFocused, disabled }) => {
    const {
      colors,
      deprecatedBorderRadius: borderRadius,
      fontSize,
      lineHeight,
    } = theme;
    return css<Pick<PasswordInputProps, "disabled" | "error">>`
      padding: 16px;
      font-size: ${fontSize.MD};
      line-height: ${lineHeight.EQ};
      border-radius: ${borderRadius.MD};
      color: ${colors.tex.t800};
      width: inherit;
      display: flex;
      justify-content: space-between;
      ${Interpolation.Form}
      border: 2px solid ${
        inputFocused && !disabled ? colors.pri.p400 : colors.base.gray
      };
      &:active,
      &:focus {
        outline: none;
        ${
          disabled &&
          css`
            border: 2px solid ${({ theme }) => theme.colors.base.gray};
            transition: none;
          `
        }}
      }
      &::placeholder {
        color: ${colors.tex.t200};
      }
    `;
  }}
`;

const NoBorderInput = styled.input`
  ${({ theme }) => {
    const { colors, fontSize, lineHeight } = theme;
    return css<Pick<PasswordInputProps, "disabled" | "error">>`
      width: 70%;
      border: none;
      background: none;
      padding: 0;
      margin: 0;
      font-size: ${fontSize.MD};
      line-height: ${lineHeight.EQ};
      color: ${colors.tex.t800};
      &:active,
      &:focus {
        outline: none;
      }
      &::placeholder {
        color: ${colors.tex.t200};
      }
      &:disabled {
        background: none;
      }
    `;
  }}
`;
