import React from "react";
import styled from "styled-components";
import { Message } from "~/components/molecules/Message";
import { LibraryModalWrapper } from "../../../ui/LibraryModalWrapper";
import { EmojiButton } from "~/components/molecules/buttons/EmojiButton";
import { ColorTheme } from "~/styles/design_token/color";
import { Stack } from "~/components/atoms/layout/Stack";
import { useTranslation } from "~/i18n";
import { YLEnqueteBackNavbar } from "../../../ui/YLEnqueteBackNavbar";
import { TypeQuestionAndAnswer } from "../const";

export type CharacterEnqueteUIProps = {
  questionAndAnswer: TypeQuestionAndAnswer;
  onNext: (buttonIndex: number) => void;
  onQuit: () => void;
  onBack: () => void;
};

type ButtonProps = {
  text: string;
  emoji: string;
  color: ColorTheme;
  onClick: () => void;
};
export const CharacterEnqueteUI: React.FC<CharacterEnqueteUIProps> = ({
  questionAndAnswer,
  onNext,
  onQuit,
  onBack,
}) => {
  const { t } = useTranslation();
  const buttons: ButtonProps[] = React.useMemo(
    () =>
      questionAndAnswer.answers.map((answer, index) => ({
        color: "pri",
        emoji: answer.emoji,
        text: t<TranslateKeys>(answer.answer),
        onClick: () => {
          onNext(index + 1);
        },
      })),
    [onNext, questionAndAnswer, t]
  );

  return (
    <>
      <YLEnqueteBackNavbar
        withBlur={true}
        onQuitButton={onQuit}
        onBackButton={onBack}
      />
      <LibraryModalWrapper
        isBright={false}
        showSensei={true}
        senseiImageType="NORMAL"
      >
        <_MessageWrapper>
          <Message
            message={t<TranslateKeys>(questionAndAnswer.question)}
            speaker={t<TranslateKeys>("ヨンデミー先生")}
            boxHeight="middle"
          />
        </_MessageWrapper>
        <_ButtonWrapper rowGap="16px">
          {buttons.map((button, index) => (
            <EmojiButton
              key={index}
              {...button}
              fontSize="MD"
              lineHeight="LG"
              bold
            />
          ))}
        </_ButtonWrapper>
      </LibraryModalWrapper>
    </>
  );
};

const _MessageWrapper = styled.div`
  position: absolute;
  bottom: calc(15% + 16px + 288px);
  width: 100%;

  display: flex;
  justify-content: center;
`;

const _ButtonWrapper = styled(Stack)`
  position: absolute;
  bottom: 15%;
  width: 100%;
  max-width: 600px;

  padding: 0px 20px;

  display: flex;
  justify-content: center;
`;
