import { CharacterType } from "~/generated/graphql";
import { TranslateKeys } from "~/i18n";

const basePath = "/img/character/";
const baseIconPath = "/img/icon/";


type CharacterDetail = {
  name: TranslateKeys;
  backgroundColor: string;
  imageUrl: string;
  iconUrl: string;
  remark: TranslateKeys;
  recommendedGenre: TranslateKeys;
  charPersonality: TranslateKeys;
  userPersonality: TranslateKeys;
  bookType: TranslateKeys;
  pronoun: TranslateKeys;
};
type Book = {
  imgUrl: string;
  title: string;
  author: string;
};

export type BookLevel = "beginner" | "intermediate" | "advanced";

type CharacterBooks = Record<BookLevel, Book[]>;

export const CharacterAssessmentList: Record<CharacterType, CharacterDetail> = {
  DINOSAUR: {
    name: "太古のきょうりゅう",
    backgroundColor: "#F3AE36",
    imageUrl: basePath + "dinosaur.svg",
    iconUrl: baseIconPath + "dinosaur.svg",
    remark: "{{nickname}}さんと\nおんなじ！ワクワクするぞー！",
    recommendedGenre: "冒険　ワクワク　勇気",
    charPersonality: "明るくて 元気な ムードメーカー",
    userPersonality: "元気いっぱいの",
    bookType: "ドキドキハラハラする 冒険の本",
    pronoun: "オイラのおすすめの本、ヨンデミー！",
  },
  APPLE: {
    name: "りんごちゃん",
    backgroundColor: "#00B400",
    imageUrl: basePath + "apple.svg",
    iconUrl: baseIconPath + "apple.svg",
    remark: "ワタシと同じなんて\n{{nickname}}さんも なかなかやるわね",
    recommendedGenre: "知識　ノンフィクション　説明文",
    charPersonality: "口が悪いけど 自分に甘えない 努力家",
    userPersonality: "いつも 一生懸命な",
    bookType: "新しいことが 学べる本",
    pronoun: "ワタシのおすすめの本、ヨンデミー！",
  },
  PENGUIN: {
    name: "ペンギンせんぱい",
    backgroundColor: "#8FCD00",
    imageUrl: basePath + "penguin.svg",
    iconUrl: baseIconPath + "penguin.svg",
    remark: "{{nickname}}さんと おそろいだ！\nいっしょに 音読がしたいな",
    recommendedGenre: "ファンタジー　芸術　不思議",
    charPersonality: "不思議なことが 大好きな 芸術家",
    userPersonality: "想像力あふれる",
    bookType: "不思議な魅力が いっぱいの本",
    pronoun: "ぼくのおすすめの本、ヨンデミー！",
  },
  LION: {
    name: "ベジタライオン",
    backgroundColor: "#FA5400",
    imageUrl: basePath + "lion.svg",
    iconUrl: baseIconPath + "lion.svg",
    remark: "おやぁ？{{nickname}}さんと 自分、似てるんだねぇ😊",
    recommendedGenre: "哲学　自然　動物",
    charPersonality: "優しくて 哲学好きの のんびり屋さん",
    userPersonality: "マイペースな",
    bookType: "自然の動物たちに 触れられる本や、哲学の本",
    pronoun: "自分のおすすめの本、ヨンデミー！",
  },
  SHEEP: {
    name: "モコひつじ",
    backgroundColor: "#F04156",
    imageUrl: basePath + "moko_sheep.svg",
    iconUrl: baseIconPath + "moko_sheep.svg",
    remark: "モコォ/// {{nickname}}さんと 同じで 嬉しいです",
    recommendedGenre: "ほっこり　カワイイ　日常モノ",
    charPersonality: "おとなしくて かわいいもの好きの 気遣い屋さん",
    userPersonality: "心優しい",
    bookType: "ほっこりできる 日常の本",
    pronoun: "うちのおすすめの本、ヨンデミー！",
  },
  WOLF: {
    name: "オオカミスミス",
    backgroundColor: "#8AD117",
    imageUrl: basePath + "wolf.svg",
    iconUrl: baseIconPath + "wolf.svg",
    remark: "オレと {{nickname}}さんが 同じ……悪くないな",
    recommendedGenre: "詩・ポエム　愛　孤独",
    charPersonality: "気高くて 優しい 一匹狼",
    userPersonality: "クールな",
    bookType: "外の世界との 関わりについて 考えられる本",
    pronoun: "オレのおすすめの本、ヨンデミー！",
  },
  GIRAFFE: {
    name: "よみキリン",
    backgroundColor: "#2AD4C0",
    imageUrl: basePath + "giraffe.svg",
    iconUrl: baseIconPath + "giraffe.svg",
    remark: "ボクと {{nickname}}さん、気が合いそうじゃん👍",
    recommendedGenre: "友達　エッセイ　多様性",
    charPersonality: "自分も 友達も 大切にする しっかりさん",
    userPersonality: "やりたいことに 真っ直ぐな",
    bookType: "いろんな生き方を えがいた本",
    pronoun: "ぼくのおすすめの本、ヨンデミー！",
  },
  SCHOOL_PRINCIPAL: {
    name: "校長先生",
    backgroundColor: "#8F4FE1",
    imageUrl: basePath + "principal_tree.svg",
    iconUrl: baseIconPath + "principal_tree.svg",
    remark: "ホッホッホッ、\nワシと {{nickname}}さんは ズッ友じゃな！",
    recommendedGenre: "笑える　昔話　ユーモア",
    charPersonality: "面白いことが 大好きな おじいちゃん",
    userPersonality: "お茶目な",
    bookType: "つい笑顔になれちゃう ユーモアたっぷりの本",
    pronoun: "ワシのおすすめの本、ヨンデミー！",
  },
};

export const RecommendedBookList: Record<CharacterType, CharacterBooks> = {
  DINOSAUR: {
    beginner: [
      {
        imgUrl: "https://m.media-amazon.com/images/I/514mkeBppiL._SL500_.jpg",
        title: "かぼちゃひこうせんぷっくらこ",
        author: "レンナート・ヘルシング",
      },
      {
        imgUrl: "https://m.media-amazon.com/images/I/51PFXQR-tlL._SL500_.jpg",
        title: "なぞのたから島",
        author: "寺村 輝夫",
      },
      {
        imgUrl: "https://m.media-amazon.com/images/I/61eiMiA2QjL._SL500_.jpg",
        title: "トマとエマのとどけもの",
        author: "大庭賢哉",
      },
    ],
    intermediate: [
      {
        imgUrl: "https://m.media-amazon.com/images/I/51XR024XA2L._SL500_.jpg",
        title: "チムとゆうかんなせんちょうさん",
        author: "エドワード アーディゾーニ",
      },
      {
        imgUrl: "https://m.media-amazon.com/images/I/51HA0TCEJYL._SL500_.jpg",
        title: "大どろぼうホッツェンプロッツ",
        author: "オトフリート=プロイスラー",
      },
      {
        imgUrl: "https://m.media-amazon.com/images/I/51LIExEDx0L._SL500_.jpg",
        title: "エルマーのぼうけん",
        author: "ルース・スタイルス・ガネット",
      },
    ],
    advanced: [
      {
        imgUrl: "https://m.media-amazon.com/images/I/61sWyocGPAL._SL500_.jpg",
        title: "二年間の休暇",
        author: "ジュール ヴェルヌ",
      },
      {
        imgUrl: "https://m.media-amazon.com/images/I/51yqEjaQcBL._SL500_.jpg",
        title: "サクラ・タイムトラベル",
        author: "加部 鈴子",
      },
      {
        imgUrl: "https://m.media-amazon.com/images/I/61SvQPV8gSL._SL500_.jpg",
        title: "選ばなかった冒険",
        author: "岡田 淳",
      },
    ],
  },
  SCHOOL_PRINCIPAL: {
    beginner: [
      {
        imgUrl: "https://m.media-amazon.com/images/I/51TsQrpH3QL._SL500_.jpg",
        title: "いいからいいから",
        author: "長谷川 義史",
      },
      {
        imgUrl: "https://m.media-amazon.com/images/I/51EAY6W382L._SL500_.jpg",
        title: "うんちしたのはだれよ！",
        author: "ヴェルナー ホルツヴァルト",
      },
      {
        imgUrl: "https://m.media-amazon.com/images/I/515DAB6DC5L._SL500_.jpg",
        title: "さるのオズワルド",
        author: "エゴン マチーセン",
      },
    ],
    intermediate: [
      {
        imgUrl: "https://m.media-amazon.com/images/I/51DM544SJCL._SL500_.jpg",
        title: "なぞなぞライオン",
        author: "佐々木 マキ",
      },
      {
        imgUrl: "https://www.hyoronsha.co.jp/pages/wp-content/uploads/9784566014213-4.jpg",
        title: "へそまがり昔ばなし",
        author: "ロアルド ダール",
      },
      {
        imgUrl: "https://m.media-amazon.com/images/I/61HEWET7K2L._SL500_.jpg",
        title: "はれときどきぶた",
        author: "矢玉 四郎",
      },
    ],
    advanced: [
      {
        imgUrl: "https://m.media-amazon.com/images/I/618C5y6Sp5L._SL500_.jpg",
        title: "くしゃみ くしゃみ 天のめぐみ",
        author: "松岡 享子",
      },
      {
        imgUrl: "https://m.media-amazon.com/images/I/61ZaC5hWfWL._SL500_.jpg",
        title: "大どろぼうブラブラ氏",
        author: "原 ゆたか",
      },
      {
        imgUrl: "https://m.media-amazon.com/images/I/61jv6+LWg5L._SL500_.jpg",
        title: "らくごで笑学校",
        author: "斉藤 洋",
      },
    ],
  },
  SHEEP: {
    beginner: [
      {
        imgUrl: "https://m.media-amazon.com/images/I/51CJS7VXR8L._SL500_.jpg",
        title: "わたしのワンピース",
        author: "にしまき かやこ",
      },
      {
        imgUrl: "https://m.media-amazon.com/images/I/51DE3YFGG5L._SL500_.jpg",
        title: "こぶたくん",
        author: "ジーン・バン ルーワン",
      },
      {
        imgUrl: "https://m.media-amazon.com/images/I/51MH42N52VL._SL500_.jpg",
        title: "みんなでぬくぬく",
        author: "エルザ・ドヴェルノア",
      },
    ],
    intermediate: [
      {
        imgUrl: "https://m.media-amazon.com/images/I/51K6FTTE2ML._SL500_.jpg",
        title: "とっときのとっかえっこ",
        author: "サリー ウィットマン",
      },
      {
        imgUrl: "https://m.media-amazon.com/images/I/51CKGMCBNJL._SL500_.jpg",
        title: "うさぎのセーター",
        author: "茂市 久美子",
      },
      {
        imgUrl: "https://m.media-amazon.com/images/I/51bi0iClSRL._SL500_.jpg",
        title: "ルルとララのカップケーキ",
        author: "あんびる やすこ",
      },
    ],
    advanced: [
      {
        imgUrl: "https://m.media-amazon.com/images/I/512sMAxVlcL._SL500_.jpg",
        title: "さくら村は大さわぎ",
        author: "朽木 祥",
      },
      {
        imgUrl: "https://m.media-amazon.com/images/I/61RVAyogNbL._SL500_.jpg",
        title: "やかまし村の子どもたち",
        author: "アストリッド・リンドグレーン",
      },
      {
        imgUrl: "https://m.media-amazon.com/images/I/5139R9kWB9L._SL500_.jpg",
        title: "神去なあなあ日常 (徳間文庫)",
        author: "三浦 しをん",
      },
    ],
  },
  WOLF: {
    beginner: [
      {
        imgUrl: "https://m.media-amazon.com/images/I/51Z9B4s9mNL._SL500_.jpg",
        title: "ぐるんぱのようちえん",
        author: "西内 ミナミ",
      },
      {
        imgUrl: "https://m.media-amazon.com/images/I/51NKG0RHYJL._SL500_.jpg",
        title: "けんかのきもち",
        author: "柴田 愛子",
      },
      {
        imgUrl: "https://m.media-amazon.com/images/I/51uaLPKPzsL._SL500_.jpg",
        title: "みてても、いい？",
        author: "礒 みゆき",
      },
    ],
    intermediate: [
      {
        imgUrl: "https://m.media-amazon.com/images/I/51tvibUrZqL._SL500_.jpg",
        title: "ないたあかおに",
        author: "野村 たかあき",
      },
      {
        imgUrl: "https://m.media-amazon.com/images/I/41WNw1t4fFL._SL500_.jpg",
        title: "ともだちは海のにおい",
        author: "工藤 直子",
      },
      {
        imgUrl: "https://m.media-amazon.com/images/I/510292JvGGL._SL500_.jpg",
        title: "のろのろひつじとせかせかひつじ",
        author: "蜂飼 耳",
      },
    ],
    advanced: [
      {
        imgUrl: "https://m.media-amazon.com/images/I/515Vmmf0EbL._SL500_.jpg",
        title: "俳句ステップ！",
        author: "イシヤマ アズサ",
      },
      {
        imgUrl: "https://m.media-amazon.com/images/I/71sgplR+GyS._AC_UF1000,1000_QL80_.jpg",
        title: "ホウ博士とロボットのいる町",
        author: "松村 美樹子",
      },
      {
        imgUrl: "https://m.media-amazon.com/images/I/51P2Yx5kyDL._SL500_.jpg",
        title: "雨の日が好きな人",
        author: "佐藤 まどか",
      },
    ],
  },
  LION: {
    beginner: [
      {
        imgUrl: "https://m.media-amazon.com/images/I/61OW0n0Ak3L._SL500_.jpg",
        title: "どうやってねるのかな",
        author: "藪内 正幸",
      },
      {
        imgUrl: "https://m.media-amazon.com/images/I/41z8ZXHfYwL._SL500_.jpg",
        title: "こおりのくにのシロクマおやこ",
        author: "前川 貴行",
      },
      {
        imgUrl: "https://m.media-amazon.com/images/I/41T68YMJ00L._SL500_.jpg",
        title: "ぼくにきづいたひ",
        author: "杉山 亮",
      },
    ],
    intermediate: [
      {
        imgUrl: "https://m.media-amazon.com/images/I/51RWKHGKWSL._SL500_.jpg",
        title: "かぜは どこへいくの",
        author: "シャーロット・ゾロトウ",
      },
      {
        imgUrl: "https://m.media-amazon.com/images/I/61LOnzbjNfL._SL500_.jpg",
        title: "マングローブの木の下で",
        author: "横塚 眞己人",
      },
      {
        imgUrl: "https://m.media-amazon.com/images/I/51I-KNM-y6L._SL500_.jpg",
        title: "オオサンショウウオの夏",
        author: "阿部 夏丸",
      },
    ],
    advanced: [
      {
        imgUrl: "https://m.media-amazon.com/images/I/41R5vPMSu5L._SL500_.jpg",
        title: "せかいのはてってどこですか？",
        author: "アルビン トゥレッセルト",
      },
      {
        imgUrl: "https://m.media-amazon.com/images/I/61RMMdyIAuL._SL500_.jpg",
        title: "オランウータンに会いたい",
        author: "久世 濃子",
      },
      {
        imgUrl: "https://m.media-amazon.com/images/I/51QrwqYzp3L._SL500_.jpg",
        title: "白いイルカの浜辺",
        author: "ジル・ルイス",
      },
    ],
  },
  PENGUIN: {
    beginner: [
      {
        imgUrl: "https://m.media-amazon.com/images/I/51O8a+g0DYL._SL500_.jpg",
        title: "ばけバケツ",
        author: "軽部 武宏",
      },
      {
        imgUrl: "https://m.media-amazon.com/images/I/51sBm4XDb-S._SL500_.jpg",
        title: "アナベルとふしぎなけいと",
        author: "マック・バーネット",
      },
      {
        imgUrl: "https://m.media-amazon.com/images/I/51C66H0ZBOL._SL500_.jpg",
        title: "たんぽぽ色のリボン",
        author: "安房 直子",
      },
    ],
    intermediate: [
      {
        imgUrl: "https://m.media-amazon.com/images/I/51BRyxumxHL._SL500_.jpg",
        title: "クレヨンマジック",
        author: "舟崎 克彦",
      },
      {
        imgUrl: "https://m.media-amazon.com/images/I/518Jic4N0BL._SL500_.jpg",
        title: "ふしぎなエレベーター",
        author: "わたり むつこ",
      },
      {
        imgUrl: "https://m.media-amazon.com/images/I/41KwijiMg0L._SL500_.jpg",
        title: "つみきのいえ",
        author: "平田 研也",
      },
    ],
    advanced: [
      {
        imgUrl: "https://m.media-amazon.com/images/I/51M-1N-QzlL._SL500_.jpg",
        title: "ゆうれい回転ずし 本日オープン！",
        author: "やぎ たみこ",
      },
      {
        imgUrl: "https://m.media-amazon.com/images/I/51CX+sx1RVL._SL500_.jpg",
        title: "風の館の物語 1",
        author: "あさの あつこ",
      },
      {
        imgUrl: "https://m.media-amazon.com/images/I/61hmSo6C-jL._SL500_.jpg",
        title: "岸辺のヤービ",
        author: "梨木 香歩",
      },
    ],
  },
  APPLE: {
    beginner: [
      {
        imgUrl: "https://m.media-amazon.com/images/I/516TVJT2X3L._SL500_.jpg",
        title: "やさいのおなか",
        author: "きうち かつ",
      },
      {
        imgUrl: "https://m.media-amazon.com/images/I/41EYFTPT48L._SL500_.jpg",
        title: "ぼくはまほうつかい",
        author: "マヤ アンジェロウ",
      },
      {
        imgUrl: "https://m.media-amazon.com/images/I/41xzMDrn++L._SL500_.jpg",
        title: "とうふができるまで",
        author: "宮崎 祥子",
      },
    ],
    intermediate: [
      {
        imgUrl: "https://m.media-amazon.com/images/I/518nMawgafL._SL500_.jpg",
        title: "わたしは反対！",
        author: "デビ―・リヴィ",
      },
      {
        imgUrl: "https://m.media-amazon.com/images/I/51QecgDxxVL._SL500_.jpg",
        title: "みずとは なんじゃ？",
        author: "かこさとし",
      },
      {
        imgUrl: "https://m.media-amazon.com/images/I/614gdSb+mdL._SL500_.jpg",
        title: "これが鳥獣戯画でござる",
        author: "結城 昌子",
      },
    ],
    advanced: [
      {
        imgUrl: "https://m.media-amazon.com/images/I/51aOQIoXmPL._SL500_.jpg",
        title: "エンザロ村のかまど",
        author: "さくま ゆみこ",
      },
      {
        imgUrl: "https://m.media-amazon.com/images/I/51t2wGv0rtL._SL500_.jpg",
        title: "月へ アポロ11号のはるかなる旅",
        author: "ブライアン・フロッカ",
      },
      {
        imgUrl: "https://m.media-amazon.com/images/I/51esId776kL._SL500_.jpg",
        title: "盲導犬サーブ",
        author: "手島 悠介",
      },
    ],
  },
  GIRAFFE: {
    beginner: [
      {
        imgUrl: "https://m.media-amazon.com/images/I/41THn4-SedL._SL500_.jpg",
        title: "そらをとびたい",
        author: "ちかぞう",
      },
      {
        imgUrl: "https://m.media-amazon.com/images/I/51FXMFZ94VL._SL500_.jpg",
        title: "まっくろネリノ",
        author: "ヘルガ=ガルラー",
      },
      {
        imgUrl: "https://m.media-amazon.com/images/I/51Os+9KdHQL._SL500_.jpg",
        title: "すきなこと にがてなこと",
        author: "新井洋行",
      },
    ],
    intermediate: [
      {
        imgUrl: "https://m.media-amazon.com/images/I/61CzlD6QgrL._SL500_.jpg",
        title: "ふたりはとっても本がすき！",
        author: "如月 かずさ",
      },
      {
        imgUrl: "https://m.media-amazon.com/images/I/4108MBVDX5L._SL500_.jpg",
        title: "口で歩く",
        author: "丘 修三",
      },
      {
        imgUrl: "https://m.media-amazon.com/images/I/51k9IJU5z2L._SL500_.jpg",
        title: "タンタンタンゴはパパふたり",
        author: "ピーター パーネル",
      },
    ],
    advanced: [
      {
        imgUrl: "https://m.media-amazon.com/images/I/51H9jIOk0cL._SL500_.jpg",
        title: "がらくた学級の奇跡",
        author: "パトリシア・ポラッコ",
      },
      {
        imgUrl: "https://m.media-amazon.com/images/I/51aXYHoPiUL._SL500_.jpg",
        title: "園芸少年",
        author: "魚住 直子",
      },
      {
        imgUrl: "https://m.media-amazon.com/images/I/41OxO1VdamL._SL500_.jpg",
        title: "PIHOTEK 北極を風と歩く",
        author: "井上 奈奈",
      },
    ],
  },
};

