import React, { ReactNode } from "react";
import styled from "styled-components";
import { Text } from "~/components/atoms/texts/Text";
import { ChatBubble as BaseChatBubble } from "~/components/atoms/wrappers/ChatBubble";
import {
  DeletedUserOnTalkFragment,
  TalkItemSpeaker,
  UserIconType,
  UserOnTalkFragment,
} from "~/generated/graphql";

export type UserType = "user" | "admin" | "me" | "deletedUser";

export const speakerImgList: Record<
  Exclude<TalkItemSpeaker, "YONDEMY_SENSEI">,
  string
> = {
  YONDEMY_SENSEI_CRY: "/img/sensei/icon_cry.jpg",
  YONDEMY_SENSEI_FIRE: "/img/sensei/icon_fire.jpg",
  YONDEMY_SENSEI_GOOD: "/img/sensei/icon_good.jpg",
  YONDEMY_SENSEI_HEART: "/img/sensei/icon_heart.jpg",
  YONDEMY_SENSEI_SURPRISED: "/img/sensei/icon_surprised.jpg",
  APPLE: "/img/icon/apple.svg",
  BOOK_WORM: "/img/icon/book_worm.svg",
  DINOSAUR: "/img/icon/dinosaur.svg",
  LION: "/img/icon/lion.svg",
  PENGUIN: "/img/icon/penguin.svg",
  WOLF: "/img/icon/wolf.svg",
  GIRAFFE: "/img/icon/giraffe.svg",
  PRINCIPAL: "/img/icon/principal_tree.svg",
  SHEEP: "/img/icon/moko_sheep.svg",
};

export type AdminChatMessageProps = {
  iconImageUrl?: string;
  user?: UserOnTalkFragment | DeletedUserOnTalkFragment;
  children: React.ReactNode;
  userType: UserType;
  variant?: "Normal" | "Reactive";
  className?: string;
  attachments?: ReactNode[];
  speaker?: TalkItemSpeaker;
};

export const userIconImage: Record<UserIconType, string> = {
  WORM: "/img/icon/book_worm.svg",
  DINOSAUR: "/img/icon/dinosaur.svg",
  APPLE: "/img/icon/apple.svg",
  LION: "/img/icon/lion.svg",
  PENGUIN: "/img/icon/penguin.svg",
  SHEEP: "/img/icon/moko_sheep.svg",
  GIRAFFE: "/img/icon/giraffe.svg",
  WOLF: "/img/icon/wolf.svg",
};

export const AdminChatMessage: React.FC<AdminChatMessageProps> = ({
  children,
  user,
  userType,
  className,
  variant = "Normal",
  attachments = [],
  speaker,
}) => {
  const isMyMessage = userType === "me";
  const chatBubbleColor = isMyMessage ? "orange" : "white";
  const images = {
    user:
      user?.__typename === "User" &&
      user?.userIconType !== undefined &&
      user?.userIconType !== null
        ? userIconImage[user?.userIconType]
        : undefined,
    me: undefined,
    deletedUser: undefined,
    admin:
      speaker === TalkItemSpeaker.YondemySensei || !speaker
        ? variant === "Reactive"
          ? "/img/sensei/icon_point.jpg"
          : "/img/sensei/icon_smile.jpg"
        : speakerImgList[speaker],
  };
  const userIconImageUrl = images[userType] || "";

  return (
    <ChatMessageContainer className={className} isMyMessage={isMyMessage}>
      <ChatMessageInner variant={variant}>
        {userIconImageUrl && (
          <UserIcon
            src={userIconImageUrl}
            alt={user?.__typename === "User" ? user?.nickname : ""}
          />
        )}
        <MessageContainer>
          {!isMyMessage && user && (
            <UserName fontSize={"XS"} lineHeight={"MD"}>
              {user.__typename === "User" ? user.nickname : ""}
            </UserName>
          )}

          <ChatBubble variant={chatBubbleColor}>{children}</ChatBubble>
          {attachments.map((a, i) => {
            return (
              <_AttachmentContainer key={`a_${i}`}>{a}</_AttachmentContainer>
            );
          })}
        </MessageContainer>
      </ChatMessageInner>
    </ChatMessageContainer>
  );
};

const ChatMessageContainer = styled.div<{
  isMyMessage: boolean;
}>`
  width: 100%;
  display: flex;
  justify-content: ${({ isMyMessage }) =>
    isMyMessage ? "flex-end" : "flex-start"};
`;

const ChatMessageInner = styled.div<{ variant: "Normal" | "Reactive" }>`
  min-width: ${({ variant }) => (variant === "Reactive" ? "296px" : "unset")};
  display: flex;
  max-width: 80vw;
  @media screen and (min-width: 600px) {
    max-width: 480px;
  }
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ChatBubble = styled(BaseChatBubble)`
  width: 100%;
`;

const _AttachmentContainer = styled.div`
  width: 274px;
  border: 2px solid ${({ theme }) => theme.colors.base.lightGray};
  margin-top: 4px;
  border-radius: 8px;
  background-color: white;
`;

const UserIcon = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin: 0 10px 0 0;
`;

const UserName = styled(Text)`
  padding-bottom: 3px;
`;
