import React from "react";
import styled, { css } from "styled-components";
import { Icon, IconType } from "~/components/atoms/Icon";
import { ColorTheme } from "~/styles/design_token/color";
import { Row } from "~/components/atoms/layout/Row";
import { IconTextButton } from "~/components/molecules/buttons/IconTextButton";

type EdgeOffset = "104px";

export type SnackbarProps = {
  icon: IconType;
  children: React.ReactNode;
  stickPlace: "top" | "bottom";
  edgeOffset?: EdgeOffset;
  onClick?: (event: React.MouseEvent) => void;
  buttonLabel?: string;
  color?: ColorTheme;
};

export const Snackbar: React.FC<SnackbarProps> = ({
  icon,
  children,
  stickPlace,
  edgeOffset,
  onClick,
  buttonLabel = "とじる",
  color = "pri",
}) => {
  return (
    <_Container stickPlace={stickPlace} color={color} edgeOffset={edgeOffset}>
      <Row columnGap="16px">
        <Icon
          name={icon}
          style={{ height: "24px", width: "24px" }}
          color={"tex"}
        />
        {children}
      </Row>
      {onClick && (
        <_Box>
          <IconTextButton
            iconPlace={"pre"}
            color={color === "gray" ? "pri" : color}
            text={buttonLabel}
            onClick={onClick}
            disabled={false}
            name={"close"}
            fontSize={"XS"}
            lineHeight={"EQ"}
            bold={true}
          />
        </_Box>
      )}
    </_Container>
  );
};

type _ContainerProps = {
  stickPlace: "top" | "bottom";
  edgeOffset?: EdgeOffset;
  color?: ColorTheme;
};

const _Container = styled.div<_ContainerProps>`
  width: 90%;
  border-radius: ${({ theme }) => theme.borderRadius.s1half};
  background-color: ${({ theme }) =>
    theme.colors.semantic.layout.backgroundBox};
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
  border: 2px solid;
  border-color: ${({ color, theme }) => {
    const { primitive, semantic } = theme.colors;
    switch (color) {
      case "pri":
        return semantic.primary.main;
      case "red":
        return semantic.alert.main;
      case "sec":
        return semantic.secondary.main;
      case "gray":
        return semantic.layout.borderPlus1;
      case "green":
        return primitive.green.g400;
      default:
        return semantic.primary.main;
    }
  }};
  padding: 12px 24px;
  max-width: 500px;
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.popup};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.size.s1};
  ${({ stickPlace, edgeOffset }) => {
    if (stickPlace === "top") {
      return css`
        top: ${edgeOffset ? `${edgeOffset}` : "5%"};
        left: 50%;
        transform: translate(-50%, 0);
        -webkit-transform: translate(-50%, 0);
      `;
    } else {
      return css`
        bottom: ${edgeOffset ? `${edgeOffset}` : "2%"};
        left: 50%;
        transform: translate(-50%, 0);
        -webkit-transform: translate(-50%, 0);
      `;
    }
  }}
`;

const _Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
