/**
 * @deprecated
 **/
type DeprecatedSize =
  | "BUTTON_MOTION_RANGE"
  | "BUTTON_MOTION_RANGE_SUB"
  | "XXS"
  | "XS"
  | "S"
  | "M"
  | "L"
  | "XL"
  | "XXL"
  | "XXXL";

export type Size =
  | "quarter"
  | "half"
  | "s1"
  | "s1half"
  | "s2"
  | "s3"
  | "s4"
  | "s5"
  | "s6"
  | "s8"
  | "s16"
  | "sidePadding"
  | "sideMargin";

//px単位
export const defaultSize: Readonly<Record<DeprecatedSize | Size, string>> = {
  quarter: "2px",
  half: "4px",
  s1: "8px",
  s1half: "12px",
  s2: "16px",
  s3: "24px",
  s4: "32px",
  s5: "40px",
  s6: "48px",
  s8: "64px",
  s16: "128px",
  sidePadding: "20px",
  sideMargin: "20px",

  /**
   * @deprecated
   **/
  BUTTON_MOTION_RANGE: "5px",
  BUTTON_MOTION_RANGE_SUB: "2px",
  XXS: "4px",
  XS: "8px",
  S: "12px",
  M: "16px",
  L: "20px",
  XL: "24px",
  XXL: "28px",
  XXXL: "32px",
};

type SizeUnitSuffix = "px" | "%" | "";
export type SpaceStyle = `${number}${SizeUnitSuffix}`;

type FourDirectionsStyle =
  `${SpaceStyle} ${SpaceStyle} ${SpaceStyle} ${SpaceStyle}`;
type ThreeDirectionsStyle = `${SpaceStyle} ${SpaceStyle} ${SpaceStyle}`;
type TwoDirectionsStyle = `${SpaceStyle} ${SpaceStyle}`;
type CalcStyle = `calc(${SpaceStyle} - ${SpaceStyle})`;

export type Space =
  | FourDirectionsStyle
  | ThreeDirectionsStyle
  | TwoDirectionsStyle
  | SpaceStyle;

export type Width = `${SpaceStyle}` | CalcStyle;
