import React from "react";
import { CustomerSupportContactTemplate } from "~/components/templates/CustomerSupportContact";
import { useNavigate } from "react-router-dom";

export const CustomerSupportContact: React.FC = () => {
  const navigate = useNavigate();
  return (
    <CustomerSupportContactTemplate
      onBack={() => {
        navigate(-1);
      }}
    />
  );
};
