import React, { VFC } from "react";
import styled from "styled-components";
import {
  FloatingButton,
  FloatingButtonProps,
} from "~/components/atoms/buttons/FloatingButton";
import { Icon, IconType } from "~/components/atoms/Icon";
import { Text } from "~/components/atoms/texts/Text";
import { FontSize } from "~/styles/design_token/font";

type FloatingIconButtonProps = Omit<FloatingButtonProps, "children"> & {
  /**
   * 文言
   */
  text: string;
  /**
   * フォントサイズ
   */
  fontSize: FontSize;
  /**
   * アイコン
   */
  name: IconType;
  /**
   * アイコンの位置
   */
  iconPlace: "pre" | "sub";
};

export const FloatingIconButton: VFC<FloatingIconButtonProps> = ({
  text,
  fontSize,
  name,
  iconPlace,
  ...floatingButtonProps
}) => {
  return (
    <FloatingButton {...floatingButtonProps}>
      {iconPlace === "pre" && (
        <_PreCon>
          <Icon name={name} color={"white"} style={{ height: "100%" }} />
        </_PreCon>
      )}
      <StyledText fontSize={fontSize} bold lineHeight={"EQ"}>
        {text}
      </StyledText>
      {iconPlace === "sub" && (
        <_SubCon>
          <Icon name={name} color={"white"} style={{ height: "100%" }} />
        </_SubCon>
      )}
    </FloatingButton>
  );
};

const _IconContainer = styled.div`
  height: 100%;
`;

const _PreCon = styled(_IconContainer)`
  margin-right: ${({ theme }) => theme.size.S};
`;

const _SubCon = styled(_IconContainer)`
  margin-left: ${({ theme }) => theme.size.S};
`;

const StyledText = styled(Text)`
  color: ${({ theme }) => theme.colors.base.white};
`;
