import React from "react";
import { ReadingTechniqueType } from "~/generated/graphql";
import { ColorOfReadingTechnique } from "~/components/organisms/bookFriendMessage/BookFriendMessageListItem/SendStarModal/const";

type ShiningStarIconProps = {
  readingTechniqueType: ReadingTechniqueType;
};

export const ShiningStarIcon: React.FC<ShiningStarIconProps> = ({
  readingTechniqueType,
}) => {
  return (
    <>
      <svg
        width="150"
        height="169"
        viewBox="0 0 150 169"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M69.4145 39.9306L54.4308 70.311L20.9067 75.1985C14.8948 76.0705 12.4855 83.482 16.8453 87.727L41.0991 111.361L35.3627 144.748C34.3301 150.783 40.6861 155.303 46.0096 152.481L76 136.717L105.99 152.481C111.314 155.28 117.67 150.783 116.637 144.748L110.901 111.361L135.155 87.727C139.514 83.482 137.105 76.0705 131.093 75.1985L97.5692 70.311L82.5855 39.9306C79.9008 34.5153 72.1221 34.4465 69.4145 39.9306Z"
          fill={ColorOfReadingTechnique[readingTechniqueType]}
          stroke={ColorOfReadingTechnique[readingTechniqueType]}
          strokeLinejoin="round"
        />
        <path
          d="M3.40381 37.0615L20.7649 54.4226"
          stroke={ColorOfReadingTechnique[readingTechniqueType]}
          strokeWidth="6"
          strokeLinecap="round"
        />
        <path
          d="M128.954 54.4226L146.315 37.0616"
          stroke={ColorOfReadingTechnique[readingTechniqueType]}
          strokeWidth="6"
          strokeLinecap="round"
        />
        <path
          d="M75.6206 3.75098L75.5892 28.3032"
          stroke={ColorOfReadingTechnique[readingTechniqueType]}
          strokeWidth="6"
          strokeLinecap="round"
        />
      </svg>
    </>
  );
};
