import React from "react";
import { AllRecommendListTemplate } from ".";
import {
  RecommendStatus,
  useGetAllUnDoneRecommendQuery,
  useGetWantReadBooksQuery,
} from "~/generated/graphql";
import { BookInfoCardProps } from "~/components/molecules/BookInfoCard";
import { useNavigate } from "react-router-dom";
import { LoadingSection } from "~/components/templates/Loading/LoadingSection";

type ExtendedBookInfoCardProps = BookInfoCardProps & {
  whenRecommend: Date;
  recommendId?: number;
  isbn: string;
};

const ITEMS_PER_PAGE = 10;

export const AllRecommendBookshelf: React.FC = () => {
  const [items, setItems] = React.useState<ExtendedBookInfoCardProps[]>([]);
  const [begin, setBegin] = React.useState<number>(0);
  const navigate = useNavigate();

  const updateItems = React.useCallback(
    (newItems: ExtendedBookInfoCardProps[]) => {
      const _items = [...items, ...newItems]
        .sort((a, b) => {
          if (a.whenRecommend === b.whenRecommend) {
            return 0;
          }
          return a.whenRecommend > b.whenRecommend ? 1 : -1;
        })
        // NOTE: よみま書架から途中まで感想を出した時の重複を削除
        .filter((elem, index, self) => {
          if (!elem.recommendId) {
            return true;
          }
          return (
            self.findIndex(
              (compareElem) => compareElem.recommendId === elem.recommendId
            ) === index
          );
        })
        .reverse();
      setItems(_items);
    },
    [items]
  );

  const {
    data: recommendData,
    loading: recommendLoading,
    error: recommendError,
  } = useGetAllUnDoneRecommendQuery({
    fetchPolicy: "no-cache",
    variables: {
      begin: begin,
      end: begin + ITEMS_PER_PAGE,
    },
    onCompleted: (result) => {
      updateItems(
        result.me.recommends.get.map((recommend) => {
          return {
            recommendId: recommend.id,
            title: recommend.book.title || "",
            isbn: recommend.book.isbn,
            imageUrl: recommend.book.imageUrl,
            recommendStatus: recommend.recommendStatus,
            isRecommend: !recommend.self,
            isInYomimashoka: false,
            yl: recommend.book.yl,
            onClick: () => {
              navigate(`/recommend/${recommend.id}`);
            },
            whenRecommend: new Date(recommend.whenRecommend),
          };
        })
      );
    },
  });
  const {
    data: wantReadBookData,
    loading: wantReadBookLoading,
    error: wantReadBookError,
  } = useGetWantReadBooksQuery({
    fetchPolicy: "no-cache",
    variables: {
      begin: begin,
      end: begin + ITEMS_PER_PAGE,
    },
    onCompleted: (result) => {
      updateItems(
        result.getWantReadBooks.get.map((wantReadBook) => {
          return {
            recommendId: wantReadBook.readRecommendID,
            title: wantReadBook.book.title || "",
            isbn: wantReadBook.book.isbn,
            imageUrl: wantReadBook.book.imageUrl,
            recommendStatus:
              wantReadBook.recommendStatus || RecommendStatus.Default,
            isRecommend: false,
            isInYomimashoka: true,
            yl: wantReadBook.book.yl,
            onClick: () => {
              if (wantReadBook.readRecommendID) {
                navigate(`/recommend/${wantReadBook.readRecommendID}`);
              } else {
                navigate(`/yomimashoka/${wantReadBook.id}`);
              }
            },
            whenRecommend: new Date(wantReadBook.createdAt),
          };
        })
      );
    },
  });

  if (recommendLoading || wantReadBookLoading) {
    return <LoadingSection />;
  }
  if (recommendError || wantReadBookError) {
    return <>エラーが発生しました</>;
  }
  return (
    <AllRecommendListTemplate
      items={items}
      paginationButtonProps={{
        currentCount: begin,
        onClick: (begin: number) => {
          setBegin(begin);
          setItems([]);
        },
        itemsPerPage: ITEMS_PER_PAGE,
        totalItemsCount: Math.max(
          recommendData?.me.recommends.count || 0,
          wantReadBookData?.getWantReadBooks.count || 0
        ),
      }}
    />
  );
};
