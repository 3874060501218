export const omitUrl = (
  _text: string
): { text: string; imageUrl: string; youtubeUrl: string } => {
  const { text: _tmpText, imageUrl } = omitImageUrl(_text);
  const { text, youtubeUrl } = omitYoutubeUrl(_tmpText);
  return { text, imageUrl, youtubeUrl };
};

const omitImageUrl = (_text: string): { text: string; imageUrl: string } => {
  if (_text.includes("!img[")) {
    const _imageUrl = _text.substring(
      _text.indexOf("!img[") + 5,
      _text.indexOf("]", _text.indexOf("!img["))
    );
    return {
      text: _text.replace("!img[" + _imageUrl + "]", ""),
      imageUrl: _imageUrl,
    };
  } else {
    return { text: _text, imageUrl: "" };
  }
};

const omitYoutubeUrl = (
  _text: string
): { text: string; youtubeUrl: string } => {
  if (!_text.includes("https://youtu.be")) {
    return { text: _text, youtubeUrl: "" };
  }

  const beginIndex = _text.indexOf("https://youtu.be");
  let endIndex = _text.slice(beginIndex).search(/\s/);
  if (endIndex === -1) {
    endIndex = _text.length;
  } else {
    endIndex += beginIndex;
  }
  const _youtubeUrl = _text.substring(beginIndex, endIndex);
  return {
    text: _text.replace(_youtubeUrl, ""),
    youtubeUrl: _youtubeUrl,
  };
};
