import React from "react";
import styled from "styled-components";
import { Text, TextProps } from "~/components/atoms/texts/Text";
import { useTranslation } from "~/i18n";
import { Row } from "~/components/atoms/layout/Row";
import { Stack } from "~/components/atoms/layout/Stack";

type ReviewSummaryProps = {
  continueDay: number;
};

export const ReviewSummary: React.FC<ReviewSummaryProps> = ({
  continueDay,
}) => {
  const { t } = useTranslation();
  const baseTextProps: Pick<TextProps, "lineHeight" | "fontColor"> = {
    lineHeight: "MD",
    fontColor: "sec.s400",
  };

  return (
    <ReviewContent>
      <ImageWrap>
        <IconImage
          src="/img/icon/fire.svg"
          alt="fire"
          width="15px"
          height="22px"
        />
      </ImageWrap>
      <Stack rowGap="0px">
        <Text {...baseTextProps} fontSize="XXXS" fontColor="tex.t200" bold>
          {t<TranslateKeys>("感想提出")}
        </Text>
        <Row columnGap="0px" alignItems="baseline">
          <Text
            {...baseTextProps}
            fontSize="LG"
            fontColor="tex.t700"
            bold
            keepAll
          >
            {continueDay}
          </Text>
          <Text
            {...baseTextProps}
            fontSize="XS"
            fontColor="tex.t700"
            bold
            keepAll
          >
            {t<TranslateKeys>("日") + t<TranslateKeys>("連続")}
          </Text>
        </Row>
      </Stack>
    </ReviewContent>
  );
};

const ImageWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
`;

const IconImage = styled.img``;

const ReviewContent = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;
