import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { LargeButton } from "~/components/atoms/buttons/Button";
import { Stack } from "~/components/atoms/layout/Stack";
import { Text } from "~/components/atoms/texts/Text";
import { BaseModal } from "~/components/atoms/wrappers/BaseModal";
import { techniqueLabel } from "~/components/organisms/bookFriendMessage/BookFriendMessageListItem/SendStarModal/const";
import { TranslateKeys } from "~/i18n";
import { BfReadLogStarNoticeItem } from "../types";
import { ShiningStarIcon } from "./shining_star";

export type ReceiveStarModalProps = {
  isOpen: boolean;
  items: BfReadLogStarNoticeItem[];
  onClickCloseModal: () => void;
};

export const ReceiveStarModal: React.FC<ReceiveStarModalProps> = ({
  isOpen,
  items,
  onClickCloseModal,
}) => {
  const { t } = useTranslation();
  if (items.length === 0) return null;

  const receiveMessaage =
    items.length === 1
      ? `「${
          techniqueLabel[items[0].readingTechniqueType]
        }」のスターを${t<TranslateKeys>("受け取った")}よ！`
      : `「${
          techniqueLabel[items[0].readingTechniqueType]
        }」と ${t<TranslateKeys>("他")}${String(
          items.length - 1
        )}つのスターを${t<TranslateKeys>("受け取った")}よ！`;

  return (
    <BaseModal open={isOpen} onClose={onClickCloseModal}>
      <Stack rowGap="16px">
        <_TextIconWrapper>
          <Text
            fontColor="semantic.text.body"
            fontSize="MD"
            lineHeight="MD"
            bold={false}
          >
            {receiveMessaage}
          </Text>
          <_IconWrapper>
            <ShiningStarIcon
              readingTechniqueType={items[0].readingTechniqueType}
            />
          </_IconWrapper>
          <Text
            fontColor="semantic.text.body"
            fontSize="MD"
            lineHeight="MD"
            bold={false}
          >
            ともだちにスターをおかえししよう
          </Text>
        </_TextIconWrapper>
        <_ButtonWrapper>
          <LargeButton color="primary" onClick={onClickCloseModal}>
            {t<TranslateKeys>("閉じる")}
          </LargeButton>
        </_ButtonWrapper>
      </Stack>
    </BaseModal>
  );
};

const _TextIconWrapper = styled(Stack)`
  width: 100%;
  gap: 8px;
`;

const _IconWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const _ButtonWrapper = styled(Stack)`
  width: 100%;
  gap: 12px;
`;
