import React, { FC } from "react";
import styled, { useTheme } from "styled-components";
import { Text } from "~/components/atoms/texts/Text";
import { ProfileBookmarkPinnedAlert } from "~/components/organisms/pinnedAlerts/ProfileBookmarkPinnedAlert";
import { FontLineHeight, FontSize } from "~/styles/design_token/font";
import { Interpolation } from "~/styles/interpolation";
import { GetMyProfileQuery } from "~/generated/graphql";
import { TranslateKeys, useTranslation } from "~/i18n";
import { convertToWord } from "../../../helpers/constants/kanziLevel";
import { MypageList } from "~/components/organisms/List/MypageList";
import { MypageExternalList } from "~/components/organisms/List/MypageExternalList";
import frequencyList from "./const";
import {
  PrivacyPolicyLink,
  TermsOfServiceLink,
  NotificationLineLink,
  QuitGuideLink,
  FaqLink,
  SubscriptionGuideLink,
} from "../../../../../util/constant";
import { Linkerize } from "~/components/atoms/buttons/tag_changer";
import { PrimaryButton } from "~/components/molecules/buttons/PrimaryButton";
import { Icon } from "~/components/atoms/Icon";
import { usePreference } from "~/store/preference/usePreference";
import { SoundSetting } from "./SoundSetting";
import { userIconProfile } from "~/components/templates/EditProfile/const";
import { userIconImage } from "~/components/organisms/adminMessage/AdminChatMessage";

type ProfileTemplateProps = {
  data: GetMyProfileQuery;
  handleLogout: (event: React.MouseEvent) => void;
};

export const ProfileTemplate: FC<ProfileTemplateProps> = ({
  data,
  handleLogout,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { preference } = usePreference();

  const valStyle = {
    fontSize: "MD" as FontSize,
    lineHeight: "MD" as FontLineHeight,
  };
  return (
    <>
      <Section id="profile">
        <ProfileBookmarkPinnedAlert />
        <Linkerize to="/mypage/edit">
          <Container className={"border"}>
            <Wrapper>
              <img
                style={{
                  width: "44px",
                  height: "44px",
                  borderRadius: "50%",
                  marginRight: "8px",
                }}
                src={userIconImage[data.me.general.userIconType]}
                alt={t<TranslateKeys>(userIconProfile[data.me.general.userIconType].name)}
              />
              <UsernameWrapper>
                <UserName {...valStyle}>{data.me.general.nickname}</UserName>
              </UsernameWrapper>
              <Icon
                name={"chevronRight"}
                style={{
                  color: theme.colors.sec.s400,
                  height: theme.fontSize["MD"],
                  width: "auto",
                  alignSelf: "center",
                }}
              />
            </Wrapper>
          </Container>
        </Linkerize>
        <LogoutButtonContainer>
          <PrimaryButton
            onClick={handleLogout}
            fontSize="LG"
            lineHeight="MD"
            text="ログアウト"
            color="red"
          />
        </LogoutButtonContainer>
      </Section>
      <Section id="setting">
        <MypageList
          title="アカウントの設定"
          items={[
            {
              title: "漢字レベル",
              subtitle: t<TranslateKeys>(convertToWord(data.me.kanziLevel)),
              description:
                "ミニレッスンのヨンデミー先生のセリフや、アプリ上の言葉などを、お子さんの漢字レベルに合わせて変更できます。",
              to: "/mypage/kanziLevel/edit",
            },
          ]}
        />
        <MypageList
          title="効果音設定"
          items={[
            {
              node: <SoundSetting />,
            },
          ]}
        />
        <MypageList
          title="選書の設定"
          items={[
            {
              title: "おすすめ冊数",
              subtitle: frequencyList[data.me.recommendFrequency].name,
              subtitleDescription: `おすすめ冊数 ${
                frequencyList[data.me.recommendFrequency].bookListMax
              }冊`,
              to: "/editRecommendFrequency",
            },
            {
              title: "蔵書検索ワード",
              subtitle: preference?.calilSearchWord ?? "",
              to: "/editSearchKeyword",
            },
          ]}
        />
        <MypageList
          title="チュートリアル・ガイド"
          items={[
            {
              title: "選書の手引き",
              description: "「次の選書をもらうには？」選書の仕組みガイドです。",
              to: "/recommend_tutorial",
            },
          ]}
        />
      </Section>
      <Section id="help">
        <MypageExternalList
          title="保護者様向けの機能"
          items={[
            {
              title: "LINE通知",
              description: "通知とオリジナルお役立ち記事をお送りします。",
              href: NotificationLineLink,
            },
            {
              title: "なんでも相談窓口",
              href: NotificationLineLink,
            },
            {
              title: "継続手続きについて",
              href: SubscriptionGuideLink,
            },
            {
              title: "休会・退会について",
              href: QuitGuideLink,
            },
            {
              title: "ヘルプ",
              description: `アプリに関するお悩みだけでなく、\nお子さんの読書や教育に関するお悩みなどを\n集めています。`,
              href: FaqLink,
            },
          ]}
        />
        <MypageExternalList
          items={[
            {
              title: "プライバシーポリシー",
              href: PrivacyPolicyLink,
            },
            {
              title: "利用規約",
              href: TermsOfServiceLink,
            },
          ]}
        />
      </Section>
    </>
  );
};

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.size.M};
  padding-bottom: 16px;
`;

const Container = styled.div`
  width: 100%;
  border-radius: ${({ theme }) => theme.deprecatedBorderRadius.MD};
  background-color: ${({ theme }) => theme.colors.base.white};
  padding: ${({ theme }) => theme.size.M};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.size.L};
  &.border {
    ${Interpolation.Border}
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

const UsernameWrapper = styled.div`
  width: 80%;
`;

const UserName = styled(Text)`
  color: ${({ theme }) => theme.colors.tex.t800};
  font-size: 20px;
  font-weight: bold;
`;

const LogoutButtonContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  @media screen and (min-width: 768px) {
    width: 60%;
  }
`;
