import React from "react";
import styled from "styled-components";
import { ReceivedReactionLockWrapper } from "~/components/organisms/Unlock/LockWrapper/receivedReactionLockWrapper";
import { ReceivedStarLockWrapper } from "~/components/organisms/Unlock/LockWrapper/receivedStarLockWrapper";
import { TranslateKeys, useTranslation } from "~/i18n";

export type FootprintProps = {
  readLogTotalNum: number;
  readCharSum: number;
  readBookSum: number;
  minilessonTotalNum: number;
  totalBadgeNum: number;
  receivedTotalBfReadLogReactionNum: number;
  receivedTotalBfReadLogStarNum: number;
};

export const FootprintTemplate: React.FC<FootprintProps> = ({
  readLogTotalNum,
  readCharSum,
  readBookSum,
  minilessonTotalNum,
  totalBadgeNum,
  receivedTotalBfReadLogReactionNum,
  receivedTotalBfReadLogStarNum,
}) => {
  const { t } = useTranslation();
  return (
    <RecordWrapper>
      <ReadCharSumWrapper>
        <RecordItemTitle>{t<TranslateKeys>("読んだ文字数")}</RecordItemTitle>
        <span>
          <RecordItemValue>{readCharSum.toLocaleString()}</RecordItemValue>
          {t<TranslateKeys>("字")}
        </span>
      </ReadCharSumWrapper>
      <RecordItemWrapper>
        <RecordItemTitle>{t<TranslateKeys>("読んだ本")}</RecordItemTitle>
        <span>
          <RecordItemValue>{readBookSum.toLocaleString()}</RecordItemValue>
          {t<TranslateKeys>("冊")}
        </span>
      </RecordItemWrapper>
      <RecordItemWrapper>
        <RecordItemTitle>バッジ</RecordItemTitle>
        <span>
          <RecordItemValue>{totalBadgeNum.toLocaleString()}</RecordItemValue>こ
        </span>
      </RecordItemWrapper>
      <RecordItemWrapper>
        <RecordItemTitle>ミニレッスン</RecordItemTitle>
        <span>
          <RecordItemValue>
            {minilessonTotalNum.toLocaleString()}
          </RecordItemValue>
          かい
        </span>
      </RecordItemWrapper>
      <RecordItemWrapper>
        <RecordItemTitle>{t<TranslateKeys>("感想提出")}</RecordItemTitle>
        <span>
          <RecordItemValue>{readLogTotalNum.toLocaleString()}</RecordItemValue>
          かい
        </span>
      </RecordItemWrapper>
      <ReceivedReactionLockWrapper>
        <RecordItemWrapper>
          <RecordItemTitle>
            {t<TranslateKeys>("受け取った")}いいね
          </RecordItemTitle>
          <span>
            <RecordItemValue>
              {receivedTotalBfReadLogReactionNum.toLocaleString()}
            </RecordItemValue>
            {t<TranslateKeys>("個")}
          </span>
        </RecordItemWrapper>
      </ReceivedReactionLockWrapper>
      <ReceivedStarLockWrapper>
        <RecordItemWrapper>
          <RecordItemTitle>
            {t<TranslateKeys>("受け取った")}スター
          </RecordItemTitle>
          <span>
            <RecordItemValue>
              {receivedTotalBfReadLogStarNum.toLocaleString()}
            </RecordItemValue>
            {t<TranslateKeys>("個")}
          </span>
        </RecordItemWrapper>
      </ReceivedStarLockWrapper>
    </RecordWrapper>
  );
};

const RecordWrapper = styled.div`
  display: grid;
  gap: 16px;
`;

const RecordItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ReadCharSumWrapper = styled(RecordItemWrapper)`
  grid-column: 1 / 3;
`;

const RecordItemTitle = styled.span`
  color: ${({ theme }) => theme.colors.semantic.text.placeholder};
  font-size: 13px;
`;

const RecordItemValue = styled.span`
  color: ${({ theme }) => theme.colors.semantic.secondary.plus1};
  font-size: 40px;
  font-weight: 500;
`;
