import React, { useEffect, useCallback, useState, useContext } from "react";
import { makeVar, useApolloClient } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import debounce from "lodash/debounce";
import { config } from "../../../config";
import {
  useSendToMessageMutation,
  GetOlderMessageLogQuery,
  GetOlderMessageLogQueryVariables,
  GetOlderMessageLogDocument,
  useMessageCacheQuery,
  useGetMessageLogMutation,
  ReactiveHistoryState,
  TalkAllFragment,
  useShouldReturnSomeReactiveQuery,
  useRestartMessageReactiveMutation,
  useUpdateKanziLevelMutation,
  KanziLevel,
} from "~/generated/graphql";
import {
  initMessageCache,
  updateMessageCache,
  setLoadingMessageCache,
  appendOlderToMessageCache,
  lastMessageSentReactiveVar,
  lastAnsweredReactiveVar,
} from "~/store/message";
import { AdminMessageTemplate } from "~/components/templates/AdminMessage";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import { flattenItems } from "~/components/organisms/adminMessage/AdminMessageList/flattenItems";
import { MinilessonSettingModal } from "~/components/organisms/modals/MinilessonSettingModal";
import { MiniLessonContextProvider } from "~/contexts/MiniLessonContext";
import { LoadingPage } from "~/components/templates/Loading/LoadingPage";

export const INITIAL_FIRST_ITEM_INDEX = 1000000;

export const isAnimationRunning = makeVar<boolean>(true);

export const AdminMessageListPage: React.FC = () => {
  const client = useApolloClient();
  const navigate = useNavigate();
  const {
    currentUser,
    loading: currentUserLoading,
    refetch: refetchCurrentUser,
  } = useContext(CurrentUserContext);
  const userID = currentUser?.general.id || 0;
  const [message, setMessage] = useState("");
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [firstItemIndex, setFirstItemIndex] = useState<number>(
    INITIAL_FIRST_ITEM_INDEX
  );
  const [isScrolling, setIsScrolling] = React.useState(false);
  const [isOpenSettingModal, setIsOpenSettingModal] =
    React.useState<boolean>(false);
  const [sendToMessage] = useSendToMessageMutation({
    update(cache, result) {
      result.data?.sendToMessage &&
        updateMessageCache(cache, result.data.sendToMessage);
    },
    onCompleted: () => {
      setCurrentIndex((i) => i + 1);
      //NOTE: 処理をずらさないとスクロールの追従が効かない
      setTimeout(() => {
        lastMessageSentReactiveVar(new Date().getTime());
      }, 0);
    },
  });

  const send = (event: React.MouseEvent) => {
    event.preventDefault();
    sendToMessage({ variables: { message } });
    setMessage("");
  };

  const { data } = useMessageCacheQuery();
  const shouldReturnSomeReactiveQuery = useShouldReturnSomeReactiveQuery({
    fetchPolicy: "network-only",
  });
  const [getMessageLog] = useGetMessageLogMutation({
    variables: { oldCount: config.TALK_FETCH_NUMBER },
    update(cache, result) {
      result.data?.getMessageLog &&
        initMessageCache(
          cache,
          config.TALK_FETCH_NUMBER,
          result.data.getMessageLog
        );
      setCurrentIndex(
        INITIAL_FIRST_ITEM_INDEX +
          flattenItems(result.data?.getMessageLog.old || []).length
      );
    },
  });
  const [restartReactiveMutation] = useRestartMessageReactiveMutation({
    onCompleted: () => {
      setLoadingMessageCache(client, true);
      getMessageLog();
      shouldReturnSomeReactiveQuery.refetch();
    },
  });

  const [updateKanziLevelMutation] = useUpdateKanziLevelMutation({
    onCompleted: () => window.location.reload(),
  });

  const handleUpdateKanziLevel = React.useCallback(
    (kanziLevel: KanziLevel): Promise<boolean> => {
      return new Promise((resolve) => {
        updateKanziLevelMutation({
          variables: {
            accountInfo: {
              kanziLevel: kanziLevel,
            },
          },
        })
          .then((res) => {
            if (res.errors || !res.data) {
              resolve(false);
            }
            resolve(true);
          })
          .catch(() => {
            resolve(false);
          });
      });
    },
    [updateKanziLevelMutation]
  );

  const onChangeTab = (page: number) => {
    // タブ切り替え時に未読インジケーターを更新する
    refetchCurrentUser();
    switch (page) {
      case 0:
        navigate("/bf");
        break;
      case 1:
        navigate("/");
        break;
      case 2:
        navigate("/message");
        break;
      default:
        return;
    }
  };

  // MEMO: スクロールするたびにリクエストがかかってしまうのでdebounceで遅延させる
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onPrevLoading = useCallback(
    debounce(async (item) => {
      // 全て読み込んだらリクエストをかけない
      if (data?.messageCache.digAll) return;

      setLoadingMessageCache(client, false);
      const { data: olderData } = await client.query<
        GetOlderMessageLogQuery,
        GetOlderMessageLogQueryVariables
      >({
        query: GetOlderMessageLogDocument,
        variables: {
          current: item.id,
          count: config.TALK_FETCH_NUMBER,
        },
      });

      appendOlderToMessageCache(
        client.cache,
        config.TALK_FETCH_NUMBER,
        olderData.getOlderMessageLog
      );
      setFirstItemIndex(
        (firstItemIndex) =>
          firstItemIndex -
          flattenItems(olderData.getOlderMessageLog || []).length
      );
    }, 1000),
    [data?.messageCache.digAll]
  );

  useEffect(() => {
    setLoadingMessageCache(client, true);
    getMessageLog().catch((error) => console.error(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //ミニレッスン再開処理
  const restartReactive = () => {
    restartReactiveMutation();
  };

  const isInLesson = () => {
    const talkData = data && data.messageCache;
    const unreadTalks = (talkData && talkData.unreadTalks) || [];
    const oldTalks = (talkData && talkData.oldTalks) || [];
    const talks = [...oldTalks, ...unreadTalks];
    if (talks.length === 0) return false;

    const latestTalk = talks[talks.length - 1];
    return (
      latestTalk.item.__typename === "ReactiveHistory" &&
      latestTalk.item.state === ReactiveHistoryState.Wait
    );
  };

  const placeholder = () => {
    if (isInLesson()) {
      return "ミニレッスン中はメッセージを送れません";
    }
    return "";
  };

  if (
    currentUserLoading ||
    !currentUser ||
    shouldReturnSomeReactiveQuery.loading
  )
    return <LoadingPage />;
  if (!data || !userID || !currentUser || !shouldReturnSomeReactiveQuery.data)
    return null;

  return (
    <MiniLessonContextProvider>
      <AdminMessageTemplate
        adminListProps={{
          data: data && data.messageCache,
          fetchAll: (data && data.messageCache?.digAll) || false,
          myId: userID,
          onPrevLoading: onPrevLoading as (
            item: TalkAllFragment
          ) => Promise<void>,
          currentIndex,
          incrementCurrentIndex: () => {
            setCurrentIndex((currentIndex) => currentIndex + 1);
            //NOTE: 処理をずらさないとスクロールの追従が効かない
            setTimeout(() => {
              lastAnsweredReactiveVar(new Date().getTime());
            }, 0);
          },
          firstItemIndex,
          setIsScrolling: setIsScrolling,
        }}
        footerMessageProps={{
          value: message,
          disabledSubmit: !message,
          disabledInput: isInLesson(),
          placeholder: placeholder(),
          onChange: (value) => setMessage(value),
          onSubmit: send,
        }}
        currentUser={currentUser}
        onChangeTab={onChangeTab}
        shouldReturnSomeReactive={
          shouldReturnSomeReactiveQuery.data?.me.shouldReturnSomeReactive
        }
        restartReactive={restartReactive}
        handleOpenSettingModal={() => {
          setIsOpenSettingModal(true);
          isAnimationRunning(false);
        }}
        isScrolling={isScrolling}
      />
      <MinilessonSettingModal
        isOpen={isOpenSettingModal}
        onClose={() => {
          setIsOpenSettingModal(false);
          isAnimationRunning(true);
        }}
        kanziLevel={currentUser.kanziLevel}
        handleUpdateKanziLevel={handleUpdateKanziLevel}
      />
    </MiniLessonContextProvider>
  );
};
